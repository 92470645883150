import {decryptPayload} from '@pleo-io/web-crypto'

import * as apiDeimosCard from '@product-web/shared--api-deimos/card'
import type {DecryptedCardDetails} from '@product-web/shared--api-types/card-details'
import type {KeyPair} from '@product-web/shared--auth--keys/get-key-pair'

export const decryptCard = async (
    cardId: string,
    keypair: KeyPair,
    token?: string,
): Promise<DecryptedCardDetails> => {
    const encrypted = await apiDeimosCard.getDetails(cardId, keypair.publicKey, token)
    const decrypted = [
        decryptPayload(keypair.privateKey, encrypted.encryptedPan),
        decryptPayload(keypair.privateKey, encrypted.encryptedCvv || ''),
    ]
    const [pan, cvv] = await Promise.all(decrypted)
    return {
        pan,
        cvv,
        expirationDate: encrypted.expiration,
    }
}
