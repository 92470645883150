import {t, Trans} from '@lingui/macro'
import {useNavigate} from 'react-router-dom'

import {Button, Text} from '@pleo-io/telescope'
import {ArrowRight, UserPlus} from '@pleo-io/telescope-icons'

import * as tracking from '@product-web/shared--analytics'
import {reportError} from '@product-web/shared--error/report'
import {isNonNullable} from '@product-web/shared--utils'

import {MilestoneAccordion} from './milestone-accordion'

import {bff} from '../bff-hooks'
import {getMilestoneProgress} from '../lib/get-milestone-progress'
import type {MilestoneName, MilestoneStatus, Task} from '../universal/types'

type InviteTeamMilestoneAccordionItemProps = {
    status: MilestoneStatus
    tasks: Task[]
    milestoneName: MilestoneName
}

export const InviteTeamMilestoneAccordionItem = ({
    status,
    tasks,
    milestoneName,
}: InviteTeamMilestoneAccordionItemProps) => {
    const navigate = useNavigate()
    const {mutate: startTask} =
        bff.companyOnboarding.getStarted.startCompanyOnboardingTask.useMutation()

    const inviteTeamTasks: React.ComponentProps<typeof MilestoneAccordion.Tasks>['tasks'] = tasks
        .map((task) => {
            switch (task.name) {
                case 'INVITE_TEAM':
                    return {
                        heading: t`Ready for the next step?`,
                        children: (
                            <Text>
                                <Trans>Invite up to 2 people for free.</Trans>
                            </Text>
                        ),
                        status: task.status,
                        cta: (
                            <Button
                                variant="primary"
                                IconRight={ArrowRight}
                                onClick={() => {
                                    startTask(task.name)
                                    tracking.companyOnboardingMilestoneActioned({
                                        action: 'started',
                                        milestone: milestoneName,
                                        task: task.name,
                                    })
                                    navigate('/onboarding/onboard-team')
                                }}
                            >
                                <Trans>Add people</Trans>
                            </Button>
                        ),
                    }

                default: {
                    reportError('Invalid milestone task name', null, {
                        milestoneName,
                        taskName: task.name,
                    })

                    return null
                }
            }
        })
        .filter(isNonNullable)

    return (
        <MilestoneAccordion.Item value={milestoneName} status={status}>
            <MilestoneAccordion.Header
                headingLevel="h2"
                heading={t`Invite your team`}
                Icon={UserPlus}
                progress={getMilestoneProgress(inviteTeamTasks)}
            />

            <MilestoneAccordion.Content>
                <MilestoneAccordion.Tasks tasks={inviteTeamTasks} />
            </MilestoneAccordion.Content>
        </MilestoneAccordion.Item>
    )
}
