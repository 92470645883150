import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'

import {ReactComponent as SentIcon} from '@product-web/shared--images/sent.svg'

export const Sent = styled(SentIcon)`
    display: block;
    width: 165px;
    margin: ${tokens.spacing32} auto;
`
