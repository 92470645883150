import {t, Trans} from '@lingui/macro'

import {Badge, Box, Button, Card, Inline, Stack, Text, tokens} from '@pleo-io/telescope'
import {CheckCircleFilled} from '@pleo-io/telescope-icons'

import type {Amount} from '@product-web/shared--bff-moons/generated/beyond'
import type {
    CpqBillingPeriodType,
    CpqRatePlanType,
} from '@product-web/shared--bff-moons/generated/beyond'
import {SupportedLanguage} from '@product-web/shared--i18n'
import {formatMinorNumberToCurrency} from '@product-web/shared--plan-presentation/currency-formatter'
import {useUser} from '@product-web/shared--user'
import {exhaustiveCheck} from '@product-web/shared--utils'

import type {PageOrigin, PageSection} from '../../index.bff'
import type {MigrationFlow} from '../../lib/legacy-plans.bff'
import {PlanTypeName} from '../../types/plans'
import {trackPricingPlansModalActioned} from '../tracking'

export interface RecommendedPlanInfoProps {
    migrationType: MigrationFlow
    annualDiscountEssential?: Amount
    annualDiscountAdvanced?: Amount
    billingPeriodType: CpqBillingPeriodType
    recommendedPlan: CpqRatePlanType
    onClickComparePlans: () => void
    pageOrigin: PageOrigin
    pageSection?: PageSection
}

export const RecommendedPlanInfo = ({
    migrationType,
    annualDiscountEssential,
    annualDiscountAdvanced,
    billingPeriodType,
    recommendedPlan,
    onClickComparePlans,
    pageOrigin,
    pageSection,
}: RecommendedPlanInfoProps) => {
    const user = useUser()
    const locale = user?.language ?? SupportedLanguage.EN

    // flag for annual billing text if recommended plan is not STARTER.
    const shouldDisplayBillingPeriodText = recommendedPlan !== 'STARTER'
    const benefits = getBenefitsByMigrationType({
        migrationType,
        locale,
        annualDiscountEssential,
        annualDiscountAdvanced,
    })
    return (
        <Card padding={32} space={24}>
            <Inline justifyContent="space-between">
                <Stack space={24}>
                    <Badge variant="discover">
                        <Trans>Recommendation</Trans>
                    </Badge>
                    <Stack space={8}>
                        <Text weight="regular" variant="4xlarge-accent">
                            {PlanTypeName[recommendedPlan]}
                        </Text>
                        {shouldDisplayBillingPeriodText && (
                            <Text weight="regular" variant="large-accent">
                                {billingPeriodType === 'YEAR' ? (
                                    <Trans>Annual billing</Trans>
                                ) : (
                                    <Trans>Monthly billing</Trans>
                                )}
                            </Text>
                        )}
                    </Stack>
                </Stack>
                <Box>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            trackPricingPlansModalActioned({
                                action: 'compare_all_plans_clicked',
                                step: 'recommended_plan',
                                origin: pageOrigin,
                                section: pageSection,
                                plan: recommendedPlan,
                            })
                            onClickComparePlans()
                        }}
                    >
                        <Trans>Compare all plans</Trans>
                    </Button>
                </Box>
            </Inline>
            <Card.Divider />
            <Stack>
                {benefits?.map((benefit) => (
                    <Inline key={benefit} space={8}>
                        <CheckCircleFilled color={tokens.colorContentPositive} />
                        <Text weight="medium" color="colorContentPositive">
                            {benefit}
                        </Text>
                    </Inline>
                ))}
            </Stack>
        </Card>
    )
}

type GetBenefitContentArgs = {
    migrationType: MigrationFlow
    locale: SupportedLanguage
    annualDiscountEssential?: Amount
    annualDiscountAdvanced?: Amount
}

const getBenefitsByMigrationType = ({
    migrationType,
    locale,
    annualDiscountEssential,
    annualDiscountAdvanced,
}: GetBenefitContentArgs) => {
    switch (migrationType) {
        case 'LEGACY_STARTER_I':
        case 'LEGACY_STARTER_II':
            return [t`Get 1 vendor card for free`]
        case 'LEGACY_STARTER_III':
            return [t`Best value for teams of 3 or more`]
        case 'LEGACY_ESSENTIAL_I':
        case 'LEGACY_ESSENTIAL_II': {
            const saveAmount = formatMinorNumberToCurrency({
                value: annualDiscountEssential?.value ?? 0,
                locale,
                options: {
                    currency: annualDiscountEssential?.currency ?? 'EUR',
                },
            })
            return [t`Keep the same plan and save ${saveAmount} a year`]
        }
        case 'LEGACY_ESSENTIAL_CASHBACK_I':
            return [t`0.5% cashback included with annual billing`]
        case 'LEGACY_ADVANCED_I':
        case 'LEGACY_ADVANCED_II': {
            const saveAmount = formatMinorNumberToCurrency({
                value: annualDiscountAdvanced?.value ?? 0,
                locale,
                options: {
                    currency: annualDiscountAdvanced?.currency ?? 'EUR',
                },
            })
            return [t`Keep the same plan and save ${saveAmount} a year`]
        }
        default:
            return exhaustiveCheck(migrationType)
    }
}
