import React from 'react'
import {createContext} from 'react'

import {invariant} from '@product-web/shared--utils'

export type SamlEntitlementContext = {
    isEntitled: boolean
    isLoading: boolean
}

export const SamlEntitlementContext = createContext<SamlEntitlementContext>({
    isEntitled: false,
    isLoading: false,
})

export const useSamlEntitlementContext = () => {
    const context = React.useContext(SamlEntitlementContext)
    invariant(
        context,
        '`useSamlEntitlementContext` can only be used within `SamlEntitlementContext`',
    )
    return context
}
