import * as tracking from '@product-web/shared--analytics'

export const trackHelpWidgetLinkClick = (
    action: 'contact_us' | 'help_centre' | 'perks' | 'referral' | 'status' | 'terms' | 'updates',
) => {
    tracking.helpWidgetActioned({
        action,
        source: 'adoption_essentials',
    })
}

export const trackAdoptionPrompt = (
    action: 'abandoned' | 'completed' | 'viewed',
    guide: string,
) => {
    tracking.adoptionEssentialsPromptActioned({action, guide})
}
