import React from 'react'

import {getTokenPayload} from '@product-web/shared--auth--jwt/payload'

import type {getMutations} from './get-mutations'
import {forceSessionRefresh, SESSION_TYPE_KEY, SessionType, setTrusted} from './operations'

export enum SessionStatus {
    Unknown = 'unknown',
    LoggedIn = 'logged-in',
    LoggedOut = 'logged-out',
}

export type SessionContextAPI = {
    accessToken: null | string
    accountAccessTokens: string[] | null
    email: null | string
    trustedEmails: string[] | null
    status: SessionStatus
    register: ReturnType<typeof getMutations>['register']
    login: ReturnType<typeof getMutations>['login']
    verifiedLogin: ReturnType<typeof getMutations>['verifiedLogin']
    loginWithPasscode: ReturnType<typeof getMutations>['loginWithPasscode']
    loginWithToken: ReturnType<typeof getMutations>['loginWithToken']
    logout: ReturnType<typeof getMutations>['logout']
}

export const SessionContext = React.createContext<SessionContextAPI>({
    accessToken: null,
    accountAccessTokens: null,
    email: null,
    trustedEmails: null,
    status: SessionStatus.Unknown,
    register: async () => Promise.resolve(),
    login: async () => Promise.resolve(),
    verifiedLogin: async () =>
        Promise.resolve({result: 'email_challenge_required', authenticationData: null}),

    loginWithPasscode: async () => Promise.resolve(),
    loginWithToken: async () => Promise.resolve({}),
    logout: async () => Promise.resolve(),
})

/**
 * Custom React hook retrieving the access token for the currently
 * logged in user from the context
 * @returns The basic access token, if available
 */
export function useAccessToken(): string | null {
    const {accessToken} = React.useContext(SessionContext)
    return accessToken
}

/**
 * A custom hook that returns the data from the payload of the
 * currently used JWT token
 *
 * @returns The JWT payload data or null if there is no access token
 *          or it cannot be parsed
 */
export function useTokenData() {
    const accessToken = useAccessToken()
    if (!accessToken) {
        return null
    }
    return getTokenPayload(accessToken)?.data ?? null
}

/**
 * Custom React hook that allows to execute passed callback
 * only when entity (or account) is switched.
 * This might be useful to handle multi-entity side effects.
 * @param callback a function that will be executed only on entity/account switch.
 */
export function useEntitySwitchEffect(callback: () => void) {
    const tokenData = useTokenData()
    const key = `${tokenData?.user?.id}-${tokenData?.user?.cmp}`

    React.useEffect(() => {
        callback()
    }, [key])
}

/**
 * Custom React hook retrieving current email and list of trusted emails for a user on a trusted device
 * @returns email and list of trusted emails if available
 */
export function useSessionRefresh(): {
    email: string
    trustedEmails?: string[] | null
} | null {
    const {email, trustedEmails} = React.useContext(SessionContext)
    return email && trustedEmails?.length ? {email, trustedEmails} : null
}

/**
 * Custom React hook to get list of trusted emails
 * @returns an array of trusted emails
 */
export function useTrustedEmails() {
    const {trustedEmails} = React.useContext(SessionContext)

    return trustedEmails ?? []
}

/**
 * Custom React hook to get/set trusted status of the user's session
 * @param email - user's email to get/set trusted session status
 * @returns
 *  result.isTrusted - whether session is trusted for a given email,
 *  result.setTrusted - makes account session to be trusted for a given email
 */
export function useTrustedSession(email?: string) {
    const {trustedEmails} = React.useContext(SessionContext)

    return {
        isTrusted: email && trustedEmails?.includes(email),
        setTrusted: async () => {
            if (email) {
                await setTrusted(email)
                await forceSessionRefresh()
            }
        },
    }
}

/**
 * Custom React hook retrieving the status of the session from the context
 * @returns The status of the session (unknown/logged in/logged out)
 */
export function useSessionStatus(): SessionStatus {
    const {status} = React.useContext(SessionContext)
    return status
}

/**
 * Custom React hook for checking if the user is currently logged in
 * @returns Boolean indicating whether the user is logged in
 */
export function useIsLoggedIn() {
    const {status} = React.useContext(SessionContext)
    return status === SessionStatus.LoggedIn
}

/**
 * Custom React hook for checking if the user is currently logged out
 * @returns Boolean indicating whether the user is logged out
 */
export function useIsLoggedOut() {
    const {status} = React.useContext(SessionContext)
    return status === SessionStatus.LoggedOut
}

/**
 * Custom hook to check if we can exchange a trusted session cookie for a new accessToken
 * @returns true/false to see if the user can refresh the session
 */
export function useShouldRefreshSession(): boolean {
    const sessionType = sessionStorage.getItem(SESSION_TYPE_KEY)
    const sessionStatus = useSessionStatus()
    const sessionRefresh = useSessionRefresh()

    return Boolean(
        sessionStatus === SessionStatus.Unknown &&
            sessionType === SessionType.Otp &&
            sessionRefresh,
    )
}

/**
 * Custom hook to login during register flow. It returns the register method
 * This requires a getToken function which return a promise with accessToken or undefined
 * @returns login method
 */
export function useRegister() {
    const {register} = React.useContext(SessionContext)
    return {register}
}

/**
 * Custom hook to login. It returns the login method
 * This requires a passcode and a One-Time-Password (OTP)
 * @returns login method
 */
export function useLogin() {
    const {login} = React.useContext(SessionContext)
    return login
}

/**
 * Custom hook to login. It returns the login method
 * This requires a passcode, a One-Time-Password (OTP)
 * @returns login method
 */
export function useVerifiedLogin() {
    const {verifiedLogin} = React.useContext(SessionContext)
    return verifiedLogin
}

/**
 * Custom hook to login with passcode when previously logged in on a trusted device.
 * When a user is logging in, we ask if they want to trust the device. When their session expires after a few hours, they can request login again with their passcode.
 * **Trusting the device means that a trusted session is kept alive even when the session is expired.**
 *
 * @returns loginWithPasscode method
 */
export function useLoginWithPasscode() {
    const {loginWithPasscode} = React.useContext(SessionContext)

    return loginWithPasscode
}

/**
 * Custom hook to login with token
 * @returns loginWithPasscode method
 */
export function useLoginWithToken() {
    const {loginWithToken} = React.useContext(SessionContext)
    return loginWithToken
}

/**
 * Custom hook that returns the logout method
 * @returns logout method
 */
export function useLogout() {
    const {logout} = React.useContext(SessionContext)
    return logout
}
