import {useEffect} from 'react'
import {useLocation} from 'react-router'

import {ADOPTION_ESSENTIALS_PATHS} from '@product-web/feature--help-centre/get-is-adoption-centre-visible'
import {useSessionUpdatedEventListener} from '@product-web/shared--auth--session/store'
import {useHasAllowedRole} from '@product-web/shared--user'
import {createPersistedState} from '@product-web/shared--web-platform/persisted-state'
import {localStorage} from '@product-web/shared--web-platform/storage/local'

import {bff} from '../bff-hooks'

type PocketAdoptionPromptState = 'hidden' | 'visible' | 'viewed'

const [usePersistedState, clearPersistedState] = createPersistedState(
    'pocket-adoption-prompt',
    localStorage,
)

export const usePocketAdoption = () => {
    const isOwnerOrBookkeeper = useHasAllowedRole([
        'owner',
        'bookkeeper-extended',
        'bookkeeper-basic',
    ])

    const {data: pocketAdoptionPromptStatus} =
        bff.adoption.pocketAdoptionPrompt.getPocketAdoptionPromptStatus.useQuery(undefined, {
            enabled: isOwnerOrBookkeeper,
        })

    const {mutate: setPocketAdoptionPromptViewCount} =
        bff.adoption.pocketAdoptionPrompt.setPocketAdoptionPromptViewCount.useMutation()

    const location = useLocation()

    useSessionUpdatedEventListener(() => {
        clearPersistedState()
    })

    const [pocketAdoptionPromptState, setPocketAdoptionPromptState] =
        usePersistedState<PocketAdoptionPromptState>('pocketAdoptionPromptState', 'hidden')

    const isVisibleLocation = ADOPTION_ESSENTIALS_PATHS.some((p) => location.pathname.startsWith(p))

    useEffect(() => {
        if (
            isVisibleLocation &&
            pocketAdoptionPromptStatus?.isVisible &&
            pocketAdoptionPromptState === 'hidden'
        ) {
            setPocketAdoptionPromptState('visible')
            setPocketAdoptionPromptViewCount(
                `${pocketAdoptionPromptStatus.pocketAdoptionPromptViewCount + 1}`,
            )
        }
    }, [
        pocketAdoptionPromptStatus?.isVisible,
        pocketAdoptionPromptState,
        pocketAdoptionPromptStatus?.pocketAdoptionPromptViewCount,
        location.pathname,
    ])

    const setPocketAdoptionPromptViewed = () => {
        setPocketAdoptionPromptState('viewed')
    }

    return {
        shouldShowPocketAdoptionPrompt:
            pocketAdoptionPromptState === 'visible' && isVisibleLocation,
        setPocketAdoptionPromptViewed,
        clearPersistedState,
    }
}
