import {t, Trans} from '@lingui/macro'
import type {ReactNode} from 'react'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'

import {Box, Button, Card, Stack, Text, tokens} from '@pleo-io/telescope'
import {ArrowRight, Pocket} from '@pleo-io/telescope-icons'
import {Globe, VisualCard} from '@pleo-io/telescope-pictograms'

import * as tracking from '@product-web/shared--analytics'
import {reportError} from '@product-web/shared--error/report'
import type {PageSection} from '@product-web/shared--paywall/index.bff'
import {pxBreakpoints} from '@product-web/shared--styles/theme'
import {useContainerQuery} from '@product-web/shared--telescope-lab/container-queries/container'
import {useToaster} from '@product-web/shared--toaster'

import {DownloadMobileAppCard} from './download-mobile-app-card'
import {MilestoneAccordion} from './milestone-accordion'

import {bff} from '../bff-hooks'
import {getMilestoneProgress} from '../lib/get-milestone-progress'
import type {MilestoneName, MilestoneStatus, Task} from '../universal/types'

interface ReimbursementsMilestoneAccordionItemProps {
    status: MilestoneStatus
    tasks: Task[]
    milestoneName: MilestoneName
    isOnTrial: boolean
    requiresUpgrade?: boolean
    openPricingModal: ({pageSection}: {pageSection: PageSection}) => void
}

export const ReimbursementsMilestoneAccordionItem = ({
    status,
    tasks,
    milestoneName,
    isOnTrial,
    requiresUpgrade,
    openPricingModal,
}: ReimbursementsMilestoneAccordionItemProps) => {
    const {showToast} = useToaster()
    const navigate = useNavigate()
    const isLargeScreen = useContainerQuery({minWidth: pxBreakpoints.mediumTabletUp, name: 'main'})
    const {mutate: startTask} =
        bff.companyOnboarding.getStarted.startCompanyOnboardingTask.useMutation()
    const {mutate: skipMilestone, isLoading: isSkipping} =
        bff.companyOnboarding.getStarted.skipCompanyOnboardingMilestone.useMutation({
            onError: () => {
                showToast(t`Check your internet connection and try again.`, {
                    title: t`Couldn't mark the step as skipped`,
                    level: 'error',
                })
            },
        })

    const reimbursementsTasks = []
    for (let i = 0; i < tasks.length; i++) {
        if (tasks[i].name === 'DOWNLOAD_APP') {
            reimbursementsTasks.push({
                heading: t`Get the mobile app`,
                children: (
                    <Box mt={12}>
                        <DownloadMobileAppCard />
                    </Box>
                ),
                status: tasks[i].status,
                stretch: true,
            })
        } else if (tasks[i].name === 'ADD_EXPENSE') {
            reimbursementsTasks.push({
                heading: t`Add a work expense`,
                children: (
                    <Stack space={16} mt={12}>
                        <AddExpenseCard isLargeScreen={isLargeScreen}>
                            <VisualCard size={isLargeScreen ? '64' : '40'} css={{flexShrink: 0}} />
                            <Stack space={4}>
                                <Text variant="large-accent" weight="medium">
                                    <Trans>Mobile app</Trans>
                                </Text>
                                <Text>
                                    <Trans>Tap the + button to add a new expense.</Trans>
                                </Text>
                            </Stack>
                        </AddExpenseCard>
                        <AddExpenseCard isLargeScreen={isLargeScreen}>
                            <Globe size={isLargeScreen ? '64' : '40'} css={{flexShrink: 0}} />
                            <WebCardFlex $isLargeScreen={isLargeScreen}>
                                <Text variant="large-accent" weight="medium">
                                    <Trans>Continue on web</Trans>
                                </Text>
                                <Button
                                    variant="primary"
                                    IconRight={ArrowRight}
                                    onClick={() => {
                                        startTask(tasks[i].name)
                                        tracking.companyOnboardingMilestoneActioned({
                                            action: 'started',
                                            milestone: milestoneName,
                                            task: tasks[i].name,
                                        })
                                        navigate('/expenses/add-expense')
                                    }}
                                >
                                    <Trans>Add expense</Trans>
                                </Button>
                            </WebCardFlex>
                        </AddExpenseCard>
                    </Stack>
                ),
                status: tasks[i].status,
                stretch: true,
            })
        } else if (tasks[i].name === 'REIMBURSE_EXPENSE') {
            reimbursementsTasks.push({
                heading: t`Get reimbursed`,
                status: tasks[i].status,
                cta: (
                    <Button
                        variant="primary"
                        IconRight={ArrowRight}
                        onClick={() => {
                            startTask(tasks[i].name)
                            tracking.companyOnboardingMilestoneActioned({
                                action: 'started',
                                milestone: milestoneName,
                                task: tasks[i].name,
                            })
                            navigate('/reimbursements')
                        }}
                    >
                        <Trans>Continue</Trans>
                    </Button>
                ),
            })
        } else {
            reportError('Invalid milestone task name', null, {
                milestoneName,
                taskName: tasks[i].name,
            })
        }
    }

    return (
        <MilestoneAccordion.Item value={milestoneName} status={status}>
            <MilestoneAccordion.Header
                headingLevel="h2"
                heading={t`Try reimbursements`}
                Icon={Pocket}
                progress={getMilestoneProgress(reimbursementsTasks)}
                badgeText={getBadgeText({isOnTrial, requiresUpgrade})}
            />

            <MilestoneAccordion.Content>
                <MilestoneAccordion.Tasks
                    tasks={reimbursementsTasks}
                    blocked={requiresUpgrade}
                    banner={
                        requiresUpgrade ? (
                            <MilestoneAccordion.Banner
                                heading={t`Your free trial has ended`}
                                description={t`Upgrade to keep using reimbursements.`}
                                cta={
                                    <Button
                                        variant="primary"
                                        onClick={() =>
                                            openPricingModal({
                                                pageSection: 'reimbursements-milestone',
                                            })
                                        }
                                    >
                                        <Trans>Select plan</Trans>
                                    </Button>
                                }
                            />
                        ) : undefined
                    }
                />

                {requiresUpgrade && (
                    <MilestoneAccordion.SkipButton
                        onClick={() => skipMilestone(milestoneName)}
                        loading={isSkipping}
                    />
                )}
            </MilestoneAccordion.Content>
        </MilestoneAccordion.Item>
    )
}

const AddExpenseCard = ({
    children,
    isLargeScreen,
}: {
    children: ReactNode
    isLargeScreen: boolean
}) => {
    return (
        <Card>
            <Card.Body>
                <AddExpenseCardFlex $isLargeScreen={isLargeScreen}>{children}</AddExpenseCardFlex>
            </Card.Body>
        </Card>
    )
}

type GetBadgeTextArgs = {
    isOnTrial: boolean
    requiresUpgrade?: boolean
}

const getBadgeText = ({isOnTrial, requiresUpgrade}: GetBadgeTextArgs) => {
    if (isOnTrial) {
        return t`Trial`
    }

    if (requiresUpgrade) {
        return t`Essential plan`
    }

    return undefined
}

const AddExpenseCardFlex = styled.div<{$isLargeScreen: boolean}>`
    align-items: ${({$isLargeScreen}) => ($isLargeScreen ? 'center' : 'flex-start')};
    display: flex;
    flex-direction: ${({$isLargeScreen}) => ($isLargeScreen ? 'row' : 'column')};
    gap: ${tokens.spacing16};
`

const WebCardFlex = styled.div<{$isLargeScreen: boolean}>`
    align-items: ${({$isLargeScreen}) => ($isLargeScreen ? 'center' : 'flex-start')};
    display: flex;
    flex-direction: ${({$isLargeScreen}) => ($isLargeScreen ? 'row' : 'column')};
    gap: ${tokens.spacing12};
    justify-content: space-between;
    width: 100%;
`
