import {Navigate, useSearchParams} from 'react-router-dom'

import {useRegisterState} from '@product-web/shared--auth--session/register-state'
import {useUser} from '@product-web/shared--user'

export const RegisterRedirect = () => {
    const user = useUser()
    const [params] = useRegisterState()

    if (user?.status?.isSignUpUser) {
        return <NavigatePersistSearch to="status" />
    }

    if (user?.role?.startsWith('member') || params.activeRegisterType === 'employee') {
        return <NavigatePersistSearch to="employee" replace />
    }

    if (user?.role?.startsWith('bookkeeper') || params.activeRegisterType === 'bookkeeper') {
        return <NavigatePersistSearch to="bookkeeper" replace />
    }

    if (user?.role?.startsWith('partner') || params.activeRegisterType === 'partner') {
        return <NavigatePersistSearch to="partner" replace />
    }

    return <NavigatePersistSearch to="new-company" replace />
}

const NavigatePersistSearch = (props: React.ComponentProps<typeof Navigate>) => {
    const [searchParams] = useSearchParams()

    if (typeof props.to === 'string') {
        return <Navigate {...props} to={{pathname: props.to, search: searchParams.toString()}} />
    }

    return (
        <Navigate {...props} to={{pathname: props.to.pathname, search: searchParams.toString()}} />
    )
}
