import type {CSSProp} from 'styled-components'
import {StyleSheetManager, ThemeProvider} from 'styled-components'

import {shouldForwardProp} from '@pleo-io/telescope'

import {theme} from '@product-web/shared--styles/theme'

export const StyledComponentsProviders = ({children}: {children: React.ReactNode}) => {
    return (
        // Once we use transient props (those begin with $) for all custom props we can remove this
        // https://linear.app/pleo/issue/DO-2328
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </StyleSheetManager>
    )
}

declare module 'react' {
    // Add type support for the css prop.
    // Note that `styled-components/cssprop` no longer exists in SC >= 6 now that it provides its own types.
    interface Attributes {
        css?: CSSProp
    }
}
