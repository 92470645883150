import React from 'react'

import {loadScript} from '@product-web/shared--web-platform/load-script'

/**
 * Load the Birdie widget script asynchronously
 */
export const useLoadAsyncBirdieScript = () => {
    React.useEffect(() => {
        loadScript({
            id: 'birdie-script-loader',
            src: 'https://app.birdie.so/widget/embed/cxtgsxki',
            asynchronous: true,
        })
    }, [])
}
