import styled, {css} from 'styled-components'

import {tokens} from '@pleo-io/telescope'

import {navigationOffset} from '@product-web/shared--navigation/constants'
import {breakpoints} from '@product-web/shared--styles/theme'
import {withContainer} from '@product-web/shared--telescope-lab/container-queries/container'

// Sizes used primarily to handle changes in screen size relating to Navigation component

const StyledMain = styled.main<{$isNavigationVisible: boolean; $navigationWidth: number}>`
    display: flex;
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
    flex: 1;
    position: relative;
    transition: ${`all ${tokens.smoothInOut}`};

    ${({$isNavigationVisible, $navigationWidth}) =>
        $isNavigationVisible &&
        css`
            max-width: calc(100% - ${$navigationWidth}px);

            @media (max-width: ${breakpoints.tabletMedUp}) {
                margin-top: ${navigationOffset};
                min-height: 0;
                max-width: unset;
            }
        `}
`

export const Main = withContainer(StyledMain, 'main')

interface ImageProps {
    $imagePath?: string
}

export const ColumnImage = styled.div<ImageProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: illustration;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${({$imagePath}: ImageProps) => ($imagePath ? $imagePath : '')});

    @media (max-width: ${breakpoints.tabletUp}) {
        display: none;
    }

    svg {
        width: 100%;
    }
`

export const ColumnImageSmall = styled(ColumnImage)`
    background-size: 80%;
    background-position: center bottom calc(50% + 20px);
`

export const ContentCentered = styled.section`
    max-width: 660px;
    min-height: 646px;
    width: 100%;
    margin: auto;
    text-align: center;
`

export const MaxWidth = styled.div`
    @media (min-width: ${breakpoints.tabletUp}) {
        max-width: 670px;
    }
`
