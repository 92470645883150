import {clamp} from 'ramda'
import type {FC, ReactElement} from 'react'
import React from 'react'
import CSSTransitionGroup from 'react-addons-css-transition-group'

import StepIndicators from './step-indicators/step-indicators'
import styles from './wizard.module.css'

export {Back} from '../back/back'

export interface StepProps {
    children: ReactElement<any>
    label?: string
    step?: number | string
    key?: string
}

export const Step: FC<StepProps> = ({children}) => children

export interface WizardProps {
    step: number | string
    className?: string
    hideIndicator?: boolean
    isCurrentStepDone?: boolean
    noAnimation?: boolean
    noRelative?: boolean
    stepSize?: number
}

const isStepElement = (element: any): element is ReactElement<StepProps> =>
    ['string', 'number'].includes(typeof element?.props?.step)

export const Wizard: FC<React.PropsWithChildren<WizardProps>> = ({
    step,
    children,
    className,
    hideIndicator,
    isCurrentStepDone,
    noAnimation,
    noRelative,
    stepSize,
}) => {
    const childrenArray = React.Children.toArray(children) as ReactElement<StepProps>[]

    let stepIndex: number | string
    if (typeof step === 'number') {
        stepIndex = step
    } else {
        stepIndex = childrenArray.findIndex((i) => isStepElement(i) && i.props.step === step)
    }

    stepIndex = clamp(0, Math.max(childrenArray.length - 1, 0), stepIndex)

    return (
        <div className={className} data-generic-ui="wizard">
            {!hideIndicator && (
                <StepIndicators
                    steps={childrenArray}
                    currentIndex={stepIndex}
                    isCurrentStepDone={isCurrentStepDone}
                    stepSize={stepSize}
                />
            )}
            <div className={noRelative ? '' : styles.animationWrapper}>
                {noAnimation ? (
                    <div key={stepIndex} className={styles.overflow}>
                        {childrenArray[stepIndex]}
                    </div>
                ) : (
                    <CSSTransitionGroup
                        transitionName={{
                            enter: styles.slideEnter,
                            enterActive: styles.slideEnterActive,
                            leave: styles.slideLeave,
                            leaveActive: styles.slideLeaveActive,
                        }}
                        transitionEnterTimeout={300}
                        transitionLeaveTimeout={300}
                    >
                        <div key={stepIndex} className={styles.overflow}>
                            {childrenArray[stepIndex]}
                        </div>
                    </CSSTransitionGroup>
                )}
            </div>
        </div>
    )
}

export default Wizard
