import * as R from 'ramda'

import {RequestScope} from '@product-web/shared--api'
import * as apiAuthPin from '@product-web/shared--api-auth/pin'
import {getClientSessionId} from '@product-web/shared--auth--client-session'
import {getTokenPayload} from '@product-web/shared--auth--jwt/payload'
import {getJwtExpiryMs} from '@product-web/shared--auth--jwt/utils'
import {getKeyPair} from '@product-web/shared--auth--keys/get-key-pair'
import {PIN_CANCELLED_BY_USER_ERROR_STATUS} from '@product-web/shared--auth--pin-modal/constants'
import ModalPinLogin from '@product-web/shared--auth--pin-modal/pin-modal'
import {
    SCOPED_TOKEN_LIFE_MAX_MS,
    SCOPED_TOKEN_LIFE_MS,
    scopedTokens,
    scopedTokenTimeouts,
} from '@product-web/shared--auth--session/scoped-tokens'
import {reportError} from '@product-web/shared--error/report'
import {invariant} from '@product-web/shared--utils'

// Manages fetching, storing, retrieving scoped tokens
// Scoped tokens are required for certain actions instead of the regular access token
// and require a pin number to retrieve. They are not auto-refreshed,
// and are cached for SCOPED_TOKEN_LIFE_MS. Scoped tokens wouldn't benefit from being
// fetched with SWR so instead we're managing our own cache here

// Sets the token in cache and set a timeout to clear it after SCOPED_TOKEN_LIFE_MS
// If the token is already cached, resets the timeout
function cacheScopedToken(scope: RequestScope, token: string) {
    // We are not caching the card details scoped token, so that the user
    // needs to enter the pin every time to show card details
    if (scope === RequestScope.CARD_DETAILS) {
        return
    }

    if (scopedTokenTimeouts.has(scope)) {
        window.clearTimeout(scopedTokenTimeouts.get(scope))
    }

    // In case of JWT we should read expiration time right from the token
    const tokenPayload = getTokenPayload(token)
    invariant(tokenPayload, 'Expected JWT token')
    const jwtExpiryMs = getJwtExpiryMs(tokenPayload)

    // We limit the scoped token life to SCOPED_TOKEN_LIFE_MAX_MS, so ensure we never overflow
    // the setTimeout max value (24.8 days).
    const scopedTokenLifeMs =
        jwtExpiryMs <= SCOPED_TOKEN_LIFE_MAX_MS ? jwtExpiryMs : SCOPED_TOKEN_LIFE_MS

    if (jwtExpiryMs > SCOPED_TOKEN_LIFE_MAX_MS) {
        // This is not the end of the world, but this should never happen.
        // We do see this happening in some Cypress test with mocked dates though.
        reportError(new Error('JWT expiry too long'), null, {
            jwtExpiryMs,
        })
    }

    scopedTokens.set(scope, token)
    const timeoutId = window.setTimeout(() => scopedTokens.delete(scope), scopedTokenLifeMs)
    scopedTokenTimeouts.set(scope, timeoutId)
}

// Fetches the token via a Pin Modal if the pin is not provided,
// or directly via API call if the pin is provided. Caches the returned token.
async function fetchScopedToken(
    scope: RequestScope,
    pin?: string,
    message?: string,
    description?: string,
    elevatedResourceIds?: string[],
) {
    let response: {
        accessToken: string
        pin?: string
    }
    if (!pin) {
        let publicKey: string | null = null
        if (scope === RequestScope.CARD_DETAILS) {
            const keypair = await getKeyPair()
            publicKey = keypair.publicKey
        }
        const result = await ModalPinLogin.showScoped(
            scope,
            publicKey,
            true,
            message,
            description,
            elevatedResourceIds,
        )
        if (result.status === 'cancelled') {
            return {}
        }
        response = result.value
    } else {
        const companyId = getClientSessionId()
        response = await apiAuthPin.login(pin, companyId, scope, null, elevatedResourceIds)
    }
    // Do not cache token when requested for specific resourceIds
    if (!elevatedResourceIds?.length) {
        cacheScopedToken(scope, response.accessToken)
    }

    return {
        accessToken: response.accessToken,
        pin: pin ?? response.pin,
    }
}

/**
 * Fetches a single scoped token. If the token is already cached, it will return the cached value
 * unless a `force` option is provided. Only caches non-card scopes.
 * @param scope The scopes for which to fetch token
 * @param options.force If set, the user will be asked for pin and the token will be refreshed
 * @returns A record mapping scopes to scoped access token
 */
export async function getScopedToken(
    scope: RequestScope,
    options?: {force?: boolean; elevatedResourceIds?: string[]},
) {
    // use the cached scoped token is available, unless a force refresh requested
    if (!options?.force && scopedTokens.has(scope) && !options?.elevatedResourceIds?.length) {
        return scopedTokens.get(scope)
    }

    const {accessToken} = await fetchScopedToken(
        scope,
        undefined,
        undefined,
        undefined,
        options?.elevatedResourceIds,
    )
    return accessToken
}

/**
 * Returns multiple scope tokens in one go, only asks for the pin once
 * It will fetch the tokens even if they are already cached (i.e. force by default)
 * @param scopes The list of scopes for which to fetch tokens
 * @returns true if token was fetched and false if it was already present
 */
export async function ensureScopedTokens(
    scopes: RequestScope[],
    options?: {force?: boolean; message?: string; description?: string},
) {
    // short-circuit if all requested scoped token are available, unless a force refresh requested
    if (!options?.force && scopes.every((scope) => scopedTokens.has(scope))) {
        return false
    }

    // We are fetching the token for the first scope using the pin modal
    // and using the pin entered by the user to fetch the rest of scoped tokens
    const pinModalResponse = await fetchScopedToken(
        scopes[0],
        undefined,
        options?.message,
        options?.description,
    )
    // An empty object is returned by fetchScopedToken if the user
    // cancel instead of typing a code in Pin code the modal
    if (R.isEmpty(pinModalResponse)) {
        throw Error(PIN_CANCELLED_BY_USER_ERROR_STATUS)
    }
    for (let i = 1; i < scopes.length; i++) {
        await fetchScopedToken(scopes[i], pinModalResponse.pin, options?.message)
    }
    return true
}
