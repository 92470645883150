import {Trans} from '@lingui/macro'
import type {Dispatch, SetStateAction} from 'react'

import {NakedButton, Stack, Text} from '@pleo-io/telescope'
import {ArrowLeft} from '@pleo-io/telescope-icons'

import * as tracking from '@product-web/shared--analytics'

import videoThumbnail from './export-essentials-thumbnail.png'

import {AdoptionAccordionView} from '../adoption-accordion'
import {BackAction, BackButtonWrapper, Container, StyledWistiaVideo} from '../components'

interface Props {
    guideName: string
    setView: Dispatch<SetStateAction<AdoptionAccordionView>>
}

const EXPORT_HOW_TO_WISTIA_ID = '8z91nqtue0'

const HowExportWorks = ({guideName, setView}: Props) => {
    const handleVideoTracking = () => {
        tracking.adoptionEssentialsCentreActioned({
            action: 'link_clicked',
            guide: guideName,
            page: 'how_export_works',
            target_url: 'video',
        })
    }

    return (
        <Container m={24} data-testid="how-export-works">
            <BackButtonWrapper paddingTop={20} paddingBottom={20}>
                <BackAction
                    data-testid="how-export-works-back"
                    onClick={() => {
                        setView(AdoptionAccordionView.INITIAL)
                    }}
                >
                    <ArrowLeft marginRight={8} size={16} />
                    <Trans>Back</Trans>
                </BackAction>
            </BackButtonWrapper>
            <Stack space={8} marginTop={8} marginBottom={24}>
                <Text variant="3xlarge-accent">
                    <Trans>How Export works</Trans>
                </Text>
                <Text>
                    <Trans>
                        Exporting lets you move expenses out of Pleo and into your accounting
                        system. Exporting is what you do when Pleo expenses have been approved and
                        you move them over so that they are locked (no longer editable).
                    </Trans>
                    <br />
                    <br />
                    <Trans>
                        Exporting expenses is different from downloading them. For example, if
                        someone needs a spreadsheet of their team's Pleo expenses, then you can
                        download the requested file. This doesn't move anything or change anything
                        in Pleo.
                    </Trans>
                </Text>
            </Stack>
            <NakedButton onClick={handleVideoTracking}>
                <StyledWistiaVideo video={EXPORT_HOW_TO_WISTIA_ID}>
                    <img src={videoThumbnail} alt="" />
                </StyledWistiaVideo>
            </NakedButton>
        </Container>
    )
}

export default HowExportWorks
