import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu'

import {Keys} from '@product-web/shared--web-platform/keyboard'

import {
    NakedDropdownMenuItem,
    StyledDropdownMenuContent,
    StyledDropdownMenuItem,
    StyledDropdownMenuTrigger,
} from './styles'

const DropdownMenuTrigger = ({children, ...props}: RadixDropdownMenu.DropdownMenuTriggerProps) => (
    <StyledDropdownMenuTrigger {...props} asChild>
        {children}
    </StyledDropdownMenuTrigger>
)

interface DropdownMenuContentProps extends RadixDropdownMenu.DropdownMenuContentProps {
    stopPropagationOnKeyDown?: boolean
}

const DropdownMenuContent = ({
    children,
    onKeyDown: _onKeyDown,
    onEscapeKeyDown: _onEscapeKeyDown,
    stopPropagationOnKeyDown,
    ...props
}: DropdownMenuContentProps) => {
    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (stopPropagationOnKeyDown && event.key !== Keys.ESCAPE) {
            event.stopPropagation()
        }
        _onKeyDown?.(event)
    }

    const onEscapeKeyDown = (event: KeyboardEvent) => {
        if (stopPropagationOnKeyDown) {
            event.stopPropagation()
        }
        _onEscapeKeyDown?.(event)
    }

    return (
        <StyledDropdownMenuContent
            {...props}
            onKeyDown={onKeyDown}
            onEscapeKeyDown={onEscapeKeyDown}
        >
            {children}
        </StyledDropdownMenuContent>
    )
}

const DropdownMenuItem = ({children, ...props}: RadixDropdownMenu.DropdownMenuItemProps) => (
    <StyledDropdownMenuItem {...props}>{children}</StyledDropdownMenuItem>
)

/**
 * @deprecated Use '@pleo-io/telescope' ActionMenu
 */
const DropdownMenu = ({children, modal = false}: RadixDropdownMenu.DropdownMenuProps) => (
    <RadixDropdownMenu.Root modal={modal} data-generic-ui="dropdown-menu">
        {children}
    </RadixDropdownMenu.Root>
)

DropdownMenu.Trigger = DropdownMenuTrigger
DropdownMenu.Content = DropdownMenuContent
DropdownMenu.Item = DropdownMenuItem
DropdownMenu.ItemNaked = NakedDropdownMenuItem

export default DropdownMenu
