import {Trans} from '@lingui/macro'

import {Box, Button, Card, Inline, Stack, Text} from '@pleo-io/telescope'
import {ArrowRight} from '@pleo-io/telescope-icons'

import {getHelpCentreArticleLink} from '@product-web/shared--locale/helpers'

import {useAcademyContext} from '../../components/academy-context/academy-context'
import eBook from '../../images/ebook.svg'
import headset from '../../images/headset.svg'

export const OtherResources = () => {
    const {country} = useAcademyContext()
    const getSeismicLink = () => {
        switch (country) {
            case 'DE':
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/pT4-eOzWoFyLUCvU'
            case 'DK':
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/x4stBu6d_7KE-2Uq'
            case 'SE':
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/x4stBu6d_7KE-2Uq'
            // GB link is the default
            default:
                return 'https://eng9e.seismic.com/ls/7fa51320-2b5e-497a-ae29-d8efea57641a/VrNiy3RHUlDWoLsF#/'
        }
    }

    return (
        <Box mt={32}>
            <Text as="h2" variant="2xlarge-accent">
                <Trans>Other resources</Trans>
            </Text>
            <Inline mt={24} space={24}>
                <Card>
                    <Card.Body>
                        <Inline>
                            <img src={eBook} alt="" />
                            <Stack space={16} paddingLeft={24}>
                                <Card.Title headingLevel="h3">
                                    <Trans>Resources hub</Trans>
                                </Card.Title>
                                <Text color="colorContentStaticQuiet">
                                    <Trans>
                                        Got questions about Pleo? We may have the answers in our
                                        resources hub.
                                    </Trans>
                                </Text>
                            </Stack>
                        </Inline>
                        <Box pl={56} mt={24}>
                            <Button
                                variant="secondary"
                                IconRight={ArrowRight}
                                href={getSeismicLink()}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Trans>Resources hub</Trans>
                            </Button>
                        </Box>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Inline>
                            <img src={headset} alt="" />
                            <Stack space={16} paddingLeft={24}>
                                <Card.Title headingLevel="h3">
                                    <Trans>Help centre</Trans>
                                </Card.Title>
                                <Text color="colorContentStaticQuiet">
                                    <Trans>
                                        Got questions about Pleo? We may have the answers in our
                                        help centre.
                                    </Trans>
                                </Text>
                            </Stack>
                        </Inline>
                        <Box pl={56} mt={24}>
                            <Button
                                variant="secondary"
                                IconRight={ArrowRight}
                                href={getHelpCentreArticleLink()}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Trans>Help centre</Trans>
                            </Button>
                        </Box>
                    </Card.Body>
                </Card>
            </Inline>
        </Box>
    )
}
