import {t, Trans} from '@lingui/macro'

import {
    Box,
    Button,
    Inline,
    Modal,
    ModalActions,
    ModalClose,
    ModalContent,
    ModalTitle,
} from '@pleo-io/telescope'

import {useToaster} from '@product-web/shared--toaster'
import {useCompanyUser} from '@product-web/shared--user'

import type {TagBudgetActionDetails, TagBudgetEditSchemaType} from '../bff'
import {bff} from '../bff'
import {getEarlierEndDateUTC} from '../lib/tag-budget-form-helpers'
import {useTagBudgetTracking} from '../lib/use-tag-budget-tracking'

export const TagBudgetCloseEarlyModal: React.FC<
    React.PropsWithChildren<{
        budgetId: string
        source: 'edit' | 'overview'
        isOpen: boolean
        onCancel: () => void
        onConfirm: () => void
    }>
> = ({budgetId, source, isOpen, onCancel, onConfirm}) => {
    const user = useCompanyUser()
    const tracking = useTagBudgetTracking()
    const {showToast} = useToaster()

    const {data: tagBudget, isLoading} = bff.tagBudgetModals.getTagBudgetById.useQuery(
        {budgetId},
        {enabled: isOpen},
    )

    const budget = (tagBudget as TagBudgetActionDetails) ?? {}

    const {mutateAsync: mutateCloseBudgetEarly} = bff.tagBudgetModals.closeBudgetEarly.useMutation({
        onSuccess() {
            showToast(t`Budget updated successfully`, {
                title: t`Success`,
                level: 'success',
            })
        },
        onError() {
            showToast(t`Please try again, or contact support.`, {
                title: t`Something went wrong`,
                level: 'error',
            })
        },
    })

    const prepareCloseBudgetEarlyPayload = () => {
        const {budgetRules = []} = budget

        const hasTagsFromOtherEntities = budgetRules.some(({tagRule}) => {
            return tagRule.companyId !== user.companyId
        })

        const shouldUseOrganizationId = hasTagsFromOtherEntities && Boolean(user.organization?.id)
        const companyOrOrganizationIdPayload = {
            ...(shouldUseOrganizationId
                ? {organizationId: user.organization?.id}
                : {companyId: user.companyId}),
        }

        return {
            ...budget,
            ...companyOrOrganizationIdPayload,
            endDate: getEarlierEndDateUTC(),
        } satisfies TagBudgetEditSchemaType['payload']
    }

    const closeBudgetEarly = async () => {
        onConfirm()

        const payload = prepareCloseBudgetEarlyPayload()
        await mutateCloseBudgetEarly({budgetId, payload})

        tracking.closedEarly({
            budget_id: budgetId,
            end_date: payload.endDate,
            source,
        })
    }

    return (
        <Modal aria-label={t`Confirm closing budget early`} isOpen={isOpen} onDismiss={onCancel}>
            <ModalClose onClick={onCancel} />
            <ModalTitle>
                <Trans>Close this budget before its end date</Trans>
            </ModalTitle>
            <ModalContent>
                <Box as="p" pb={12}>
                    <Trans>
                        Employees will no longer be able to assign expenses to the budget once it's
                        been closed
                    </Trans>
                </Box>
            </ModalContent>
            <ModalActions>
                <Inline space={16}>
                    <Button variant="secondary" onClick={onCancel}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button
                        variant="primary"
                        onClick={closeBudgetEarly}
                        disabled={isLoading}
                        loading={isLoading}
                    >
                        <Trans>Close budget</Trans>
                    </Button>
                </Inline>
            </ModalActions>
        </Modal>
    )
}
