import {t, Trans} from '@lingui/macro'
import {Form, Formik} from 'formik'
import {useNavigate} from 'react-router'

import {Button, FormikSelect, LoadingPage, Stack, Text} from '@pleo-io/telescope'

import {Page} from '@product-web/feature--ui-page/page'
import {useToaster} from '@product-web/shared--toaster'
import yup from '@product-web/shared--validation/yup'

import {bff} from '../../../../bff-hooks'
import {GenericError} from '../../../../components/generic-error'
import type {PartnerSizeValue} from '../../../../lib/partner-size'
import {partnerSizeOptions, partnerSizeValidator} from '../../../../lib/partner-size'
import {Layout} from '../../components/layout'
import illustration from '../../images/hands.svg'

const validationSchema = yup.object().shape({partnerSize: partnerSizeValidator})

export const PartnerSize = () => {
    const {showToast} = useToaster()
    const navigate = useNavigate()
    const {data, isLoading, isError} = bff.onboardingPartnerSize.getPartnerSizeInfo.useQuery()
    const {mutateAsync: updatePartnerSize, isLoading: isUpdatingPartnerSize} =
        bff.onboardingPartnerSize.updatePartnerSize.useMutation({
            onSuccess: () => navigate('../onboarding-type'),
            onError: () => {
                showToast(t`Please try again, or contact support.`, {
                    title: t`Something went wrong`,
                    level: 'error',
                })
            },
        })

    if (isLoading) {
        return <LoadingPage />
    }

    if (isError) {
        return <GenericError />
    }

    const partnerName = data.partnerName

    return (
        <Layout>
            <Page.Header>
                <Page.SpaceBetween>
                    <Page.Title>
                        <Trans>Let's get you onboarded, {partnerName}</Trans>
                    </Page.Title>
                </Page.SpaceBetween>
            </Page.Header>
            <Layout.Content mt={60}>
                <Layout.Left>
                    <Formik<{partnerSize?: {value: PartnerSizeValue | ''}}>
                        initialValues={{partnerSize: undefined}}
                        validationSchema={validationSchema}
                        onSubmit={async ({partnerSize}) => {
                            partnerSize?.value &&
                                (await updatePartnerSize({size: partnerSize?.value}))
                        }}
                    >
                        <Form>
                            <Stack mt={24} mb={40} space={40}>
                                <Text>
                                    <Trans>
                                        Please select the total number of employees in your
                                        accountancy. This will help us provide the best experiences
                                        for you and your team.
                                    </Trans>
                                </Text>
                                <FormikSelect
                                    name="partnerSize"
                                    aria-label={t`Number of employees`}
                                    options={partnerSizeOptions()}
                                />
                            </Stack>
                            <Layout.Actions>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    loading={isUpdatingPartnerSize}
                                >
                                    <Trans>Next</Trans>
                                </Button>
                            </Layout.Actions>
                        </Form>
                    </Formik>
                </Layout.Left>
                <Layout.Right>
                    <Layout.Illustration src={illustration} />
                </Layout.Right>
            </Layout.Content>
        </Layout>
    )
}
