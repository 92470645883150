import * as React from 'react'
import {Route} from 'react-router-dom'

import {roleSets} from '@product-web/shared--user'

const LandingPage = React.lazy(async () => import('./screens/landing/landing'))

export const routes = (
    <Route index element={<LandingPage />} handle={{auth: true, allowedRoles: roleSets.company}} />
)
