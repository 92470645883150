import {t, Trans} from '@lingui/macro'
import {Helmet} from 'react-helmet'
import styled from 'styled-components'

import {Link, Text, tokens} from '@pleo-io/telescope'

import {Page} from '@product-web/feature--ui-page-legacy'
import {ContentCentered} from '@product-web/feature--ui-page-legacy/composition.styles'

import detective from './detective.png'

type PageNotFoundProps = {
    showNav?: boolean
}

export function PageNotFound({showNav = true}: PageNotFoundProps) {
    return (
        <Page flex showNav={showNav}>
            <Helmet>
                <title>{t`Not found`}</title>
            </Helmet>
            <ContentCentered>
                <Image src={detective} alt="" />
                <Text variant="large-accent" space={4}>
                    <Trans>Oops! Looks like we've hit a dead end..</Trans>
                </Text>
                <Text variant="medium-default">
                    <Trans>
                        Not the first time? <Link href="mailto:support@pleo.io">Let us know</Link>
                    </Trans>
                </Text>
            </ContentCentered>
        </Page>
    )
}

const Image = styled.img`
    max-width: 320px;
    width: 100%;
    margin-bottom: ${tokens.spacing20};
`

export default PageNotFound
