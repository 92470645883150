import {t, Trans} from '@lingui/macro'
import {Form, Formik} from 'formik'
import type {FC} from 'react'
import React, {useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import {ValidationError} from 'yup'

import {
    Box,
    Button,
    FormikInput as Input,
    Inline,
    Link,
    Modal,
    ModalActions,
    ModalClose,
    ModalContent,
    ModalSubtitle,
    ModalTitle,
    Stack,
    Text,
} from '@pleo-io/telescope'

import tracking from '@product-web/shared--analytics'
import {OtpType} from '@product-web/shared--api-types/otp'
import type {LoggedInAccount} from '@product-web/shared--auth--accounts'
import {useLoggedInAccounts} from '@product-web/shared--auth--accounts'
import {ExternalProvider} from '@product-web/shared--auth--oauth/context'
import {useOauthUrl} from '@product-web/shared--auth--oauth/context'
import {setOauthAddAccount} from '@product-web/shared--auth--oauth/operations'
import {checkEmail} from '@product-web/shared--auth--otp/api'
import {useTrustedSession} from '@product-web/shared--auth--session/context'
import config from '@product-web/shared--config'
import {reportError} from '@product-web/shared--error/report'
import googleLogo from '@product-web/shared--images/google-logo.svg'
import microsoftLogo from '@product-web/shared--images/microsoft-logo.svg'
import {getHelpCentreArticleLinkIntercom} from '@product-web/shared--locale/helpers'
import {useActionWithFeedback} from '@product-web/shared--use-action-with-feedback/use-action-with-feedback'
import {getIsCompanyOwner, getIsOrganizationAdmin, useUser} from '@product-web/shared--user'
import {invariant} from '@product-web/shared--utils'
import {email as validateEmail} from '@product-web/shared--validation'
import yup from '@product-web/shared--validation/yup'

import * as s from './add-account.styles'
import {useEnforceTrustedSessionEntitled} from './enforce-trusted-session'

import {AddAccountScreenName} from '../add-account-screen-name'
import {RequestEntitiesModal} from '../self-initiated-expansion/request-entities-modal'

const isLocalEnvironment = config.version === '__LOCAL__'

const validationSchema = yup.object().shape({
    email: yup.string(),
})

type FormValues = yup.InferType<typeof validationSchema>

function EmailInput({
    initialValue,
    isLoading,
    onSubmit,
    errorMessage,
    onReset,
    buttonLabel,
    spacing,
}: {
    initialValue: string | null
    isLoading?: boolean
    onSubmit: (value: string) => void
    errorMessage?: React.ReactNode | null
    onReset?: () => void
    buttonLabel: string
    spacing?: React.ComponentProps<typeof Stack>['space']
}) {
    return (
        <Formik<FormValues>
            initialValues={{email: initialValue ?? ''}}
            isInitialValid
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
            onSubmit={({email}, {setSubmitting}) => {
                onSubmit(email.trim())
                setSubmitting(false)
            }}
        >
            {({isSubmitting, handleChange, errors}) => (
                <Form>
                    <Stack space={spacing} stretch>
                        <Stack space={2} stretch>
                            <Input
                                label={t`Work email`}
                                autoFocus
                                autoComplete="on"
                                name="email"
                                placeholder={t`ie. name@email.com`}
                                variant="underlined"
                                onChange={(e) => {
                                    onReset?.()
                                    handleChange(e)
                                }}
                                isInvalid={!!errorMessage || !!errors?.email}
                                data-testid="e2e-add-account-email-input"
                            />
                            <ErrorMessageWrapper>{errorMessage ?? ' '}</ErrorMessageWrapper>
                        </Stack>
                        <Button
                            loading={isSubmitting || isLoading}
                            type="submit"
                            variant="secondary"
                            disabled={isSubmitting}
                            data-testid="e2e-add-account-button"
                        >
                            {buttonLabel}
                        </Button>
                    </Stack>
                </Form>
            )}
        </Formik>
    )
}

export type AccountSwitchLocationState = {
    showSwitchAccountModal: boolean
    showCreateCompanyModal: boolean
    email: string
    passcode: string | null
    password: string | null
    otp: string | null
    loginToken: string | null
    otpType: OtpType | null
    phoneLastDigits: string | null
    googleAuth: boolean | null
    currentScreen: AddAccountScreenName | null
    redirect: string | null
    errorMessage?: string | null
}

export function useAccountSwitchModal() {
    const location = useLocation()

    const getCurrentRelativeURL = () => {
        const url = new URL(window.location.href)

        return url.pathname + url.search + url.hash
    }

    const locationState = (location.state || {
        showSwitchAccountModal: false,
        email: '',
    }) as AccountSwitchLocationState

    const navigate = useNavigate()

    const dismiss = () => {
        navigate(getCurrentRelativeURL(), {state: {showSwitchAccountModal: false}, replace: true})
    }

    const open = (stateOverrides?: Partial<AccountSwitchLocationState>) => {
        navigate(getCurrentRelativeURL(), {
            state: {
                showSwitchAccountModal: true,
                redirect: getCurrentRelativeURL(),
                ...stateOverrides,
            },
        })
    }

    return {isOpen: locationState.showSwitchAccountModal, dismiss, open}
}

export enum EmailErrorMessageType {
    LEGACY = 'legacy',
    EXISTS = 'existing-account',
    THROTTLED = 'throttled',
    SSO_CONFIGURED = 'sso-configured',
    GENERIC_ERROR = 'generic-error',
    REQUIRED = 'required',
    INVALID = 'invalid',
}
interface SwitchAccountModalProps {
    onDismiss: () => void
    accounts?: LoggedInAccount[]
}

export const SwitchAccountModalWrapper = ({onDismiss, accounts}: SwitchAccountModalProps) => {
    const user = useUser()
    const email = user?.email
    const {isTrusted} = useTrustedSession(email)
    const isEnforceTrustedSessionEntitled = useEnforceTrustedSessionEntitled()

    if (!isTrusted && isEnforceTrustedSessionEntitled) {
        return <TrustSessionModal onDismiss={onDismiss} />
    }

    return <SwitchAccountModal accounts={accounts} onDismiss={onDismiss} />
}

export const SwitchAccountModal = ({onDismiss, accounts}: SwitchAccountModalProps) => {
    const [isOpen, setIsOpen] = React.useState(true)
    const user = useUser()
    const isOrganzationAdmin = getIsOrganizationAdmin(user)
    const isAdmin = getIsCompanyOwner(user)
    const navigate = useNavigate()
    const location = useLocation()
    const isEnforceTrustedSessionEntitled = useEnforceTrustedSessionEntitled()
    const locationState = location.state as AccountSwitchLocationState
    const [errorMessageType, setErrorMessageType] = React.useState<EmailErrorMessageType | null>(
        null,
    )
    const [isRequestEntitiesModalOpen, setIsRequestEntitiesModalOpen] = useState(false)

    const closeModal = () => {
        tracking.accountSwitchModalActioned({
            action: 'cancel',
            actor_role: user?.role,
        })
        setIsOpen(false)
        onDismiss()
    }

    const messages = {
        flagged: {
            subtitle: t`Easily switch between Pleo accounts. Accounts will be remembered on this browser.`,
            oauthGoogleButton: t`Log in with Google`,
            oauthMicrosoftButton: t`Log in with Microsoft`,
            emailButtonLabel: t`Log in with email`,
        },
        nonFlagged: {
            subtitle: t`Log in to easily switch between different accounts. You will remain logged in with your current one.`,
            oauthGoogleButton: t`Continue with Google`,
            oauthMicrosoftButton: t`Continue with Microsoft`,
            emailButtonLabel: t`Continue with email`,
        },
    }[isEnforceTrustedSessionEntitled ? 'flagged' : 'nonFlagged']

    const {protocol, host, pathname} = window.location
    // On development we can use any redirectUri
    // However in production, only /login is configured in the oauth callback for Google
    const redirectUri = `${protocol}//${host}${isLocalEnvironment ? pathname : '/login'}`

    const googleUrl = useOauthUrl({
        action: 'external',
        provider: ExternalProvider.GOOGLE,
        scope: ['resource:basic'],
        register: false,
        redirectUri,
    })

    const microsoftUrl = useOauthUrl({
        action: 'external',
        provider: ExternalProvider.MICROSOFT,
        scope: ['resource:basic'],
        register: false,
        redirectUri,
    })

    const errorMessage = () => {
        if (errorMessageType === EmailErrorMessageType.LEGACY) {
            return (
                <Text variant="small-subtle" color="colorContentNegative">
                    <Trans>Can't find the email. Try another one.</Trans>
                </Text>
            )
        }

        if (errorMessageType === EmailErrorMessageType.EXISTS) {
            return (
                <Text variant="small-subtle" color="colorContentNegative">
                    <Trans>You've already added this account</Trans>
                </Text>
            )
        }

        if (errorMessageType === EmailErrorMessageType.SSO_CONFIGURED) {
            return (
                <Text variant="small-subtle" color="colorContentNegative">
                    <Trans>
                        Account is configured with SSO. Logout from all accounts to use this
                        account.
                    </Trans>
                </Text>
            )
        }

        if (errorMessageType === EmailErrorMessageType.GENERIC_ERROR) {
            return (
                <Text variant="small-subtle" color="colorContentNegative">
                    <Trans>
                        Can't add account. Please try again, and if the problem continues, contact
                        support.
                    </Trans>
                </Text>
            )
        }

        if (errorMessageType === EmailErrorMessageType.REQUIRED) {
            return (
                <Text variant="small-subtle" color="colorContentNegative">
                    <Trans>Email is required</Trans>
                </Text>
            )
        }

        if (errorMessageType === EmailErrorMessageType.INVALID) {
            return (
                <Text variant="small-subtle" color="colorContentNegative">
                    <Trans>Enter a valid email address</Trans>
                </Text>
            )
        }

        if (errorMessageType === EmailErrorMessageType.THROTTLED) {
            return (
                <Text variant="small-subtle" color="colorContentNegative">
                    <Trans>
                        Too many verification code requests. Your account has been locked for 24
                        hours for security reasons. Please contact support@pleo.io to get access to
                        your account.
                    </Trans>
                </Text>
            )
        }

        return null
    }

    const isValidEmail = (input: string) =>
        !!(input && !(validateEmail(input) instanceof ValidationError))

    const onEmailSubmit = async (value: string) => {
        if (!value) {
            setErrorMessageType(EmailErrorMessageType.REQUIRED)
            return
        }

        if (value) {
            if (!isValidEmail(value)) {
                setErrorMessageType(EmailErrorMessageType.INVALID)
                return
            }
        }

        if (accounts?.find((account: LoggedInAccount) => account.email === value)) {
            setErrorMessageType(EmailErrorMessageType.EXISTS)
            return
        }

        tracking.accountSwitchModalActioned({
            action: 'continue_with_email',
            actor_role: user?.role,
            status: 'started',
        })

        try {
            const response = await checkEmail(value)
            const otpType = response.googleAuth ? OtpType.GOOGLE_AUTH : OtpType.PHONE

            if (response.legacy) {
                setErrorMessageType(EmailErrorMessageType.LEGACY)
                return
            }
            if (response.ssoAuthUrl) {
                setErrorMessageType(EmailErrorMessageType.SSO_CONFIGURED)
                return
            }
            tracking.accountSwitchModalActioned({
                action: 'continue_with_email',
                actor_role: user?.role,
                status: 'completed',
            })
            setIsOpen(false)
            navigate('/add-account', {
                state: {
                    ...locationState,
                    showSwitchAccountModal: false,
                    email: value,
                    otpType,
                    currentScreen: AddAccountScreenName.PASSCODE,
                },
            })
        } catch (error) {
            tracking.accountSwitchModalActioned({
                action: 'continue_with_email',
                actor_role: user?.role,
                status: 'cancelled',
            })
            reportError(error)
        }
    }

    React.useEffect(() => {
        tracking.accountSwitchModalViewed({
            view: 'email',
            actor_role: user?.role,
        })
    }, [])

    React.useEffect(() => {
        if (!locationState.errorMessage || !locationState.email) {
            return
        }

        setErrorMessageType(locationState.errorMessage as unknown as EmailErrorMessageType)
    }, [locationState.email, locationState.errorMessage, locationState.loginToken])

    const handleGoogleOauthClick = () => {
        tracking.accountSwitchModalActioned({
            action: 'continue_with_google',
            actor_role: user?.role,
            status: 'started',
        })
        setOauthAddAccount(true)
    }

    const handleMicrosoftOauthClick = () => {
        tracking.accountSwitchModalActioned({
            action: 'continue_with_microsoft',
            actor_role: user?.role,
            status: 'started',
        })
        setOauthAddAccount(true)
    }

    return (
        <s.AddAccountModal
            isOpen={isOpen}
            onDismiss={closeModal}
            enforceTrustedSession={isEnforceTrustedSessionEntitled}
        >
            <ModalClose onClick={closeModal} />
            <ModalTitle>
                <Trans>Add account</Trans>
            </ModalTitle>
            <ModalSubtitle>
                <Text as="p">
                    {messages.subtitle}{' '}
                    <Link
                        href={getHelpCentreArticleLinkIntercom(
                            '5963262-switching-between-multiple-pleo-accounts',
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Trans>Learn more</Trans>
                    </Link>
                </Text>
            </ModalSubtitle>
            <ModalContent>
                <s.OauthButton href={googleUrl} onClick={handleGoogleOauthClick}>
                    <img src={googleLogo} alt={t`Google logo`} />
                    {messages.oauthGoogleButton}
                </s.OauthButton>
                <s.OauthButton href={microsoftUrl} onClick={handleMicrosoftOauthClick}>
                    <img src={microsoftLogo} alt={t`Microsoft logo`} />
                    {messages.oauthMicrosoftButton}
                </s.OauthButton>
                <s.Divider>
                    <Trans>or</Trans>
                </s.Divider>
                <s.EmailInput>
                    <EmailInput
                        errorMessage={errorMessage()}
                        initialValue={locationState?.email ?? ''}
                        onSubmit={onEmailSubmit}
                        onReset={() => setErrorMessageType(null)}
                        buttonLabel={messages.emailButtonLabel}
                        spacing={isEnforceTrustedSessionEntitled ? 24 : 40}
                    />
                </s.EmailInput>
                {isAdmin ? (
                    <s.AddMoreEntities>
                        <Trans>More companies?</Trans>{' '}
                        {/* @temp-button-migrations: May look off, due to inline use, when tertiary button styling is updated */}
                        <Button
                            variant="tertiary"
                            onClick={() => {
                                tracking.selfExpansionModalOpened({
                                    source: 'add-account-modal',
                                    hasOrgAccess: user?.organization ? true : false,
                                })
                                setIsRequestEntitiesModalOpen(true)
                            }}
                        >
                            <Trans> Add new entity to Pleo</Trans>
                        </Button>
                    </s.AddMoreEntities>
                ) : (
                    <Box paddingY={12} />
                )}
                <RequestEntitiesModal
                    isOrganizationAdmin={isOrganzationAdmin}
                    isOpen={isRequestEntitiesModalOpen}
                    setIsOpen={setIsRequestEntitiesModalOpen}
                />
            </ModalContent>
        </s.AddAccountModal>
    )
}

interface TrustSessionModalProps {
    onDismiss: () => void
}

export const TrustSessionModal: FC<TrustSessionModalProps> = ({onDismiss}) => {
    const [isOpen, setIsOpen] = React.useState(true)
    const [isLoading, setIsLoading] = React.useState(false)
    const user = useUser()
    const email = user?.email
    const {setTrusted} = useTrustedSession(email)

    const handleTrustSession = useActionWithFeedback(
        async () => {
            tracking.accountSwitchModalActioned({
                action: 'trust',
                actor_role: user?.role,
                status: 'started',
            })

            invariant(email, 'Email is required to set trusted account')

            setIsLoading(true)
            try {
                await setTrusted()
                setIsOpen(false)
                tracking.accountSwitchModalActioned({
                    action: 'trust',
                    actor_role: user?.role,
                    status: 'completed',
                })
            } catch (e) {
                tracking.accountSwitchModalActioned({
                    action: 'trust',
                    actor_role: user?.role,
                    status: 'cancelled',
                })
                throw e
            } finally {
                setIsLoading(false)
            }
        },
        () => ({
            errorMessage: t`Unable to trust this device`,
        }),
    )

    const closeModal = () => {
        tracking.accountSwitchModalActioned({
            action: 'cancel',
            actor_role: user?.role,
        })
        setIsOpen(false)
        onDismiss()
    }

    React.useEffect(() => {
        tracking.accountSwitchModalViewed({
            view: 'trust_this_browser',
            actor_role: user?.role,
        })
    }, [])

    return (
        <Modal isOpen={isOpen} onDismiss={closeModal}>
            <ModalClose onClick={closeModal} />
            <ModalTitle>
                <Trans>Trust this browser?</Trans>
            </ModalTitle>
            <ModalContent>
                <Text as="p">
                    <Trans>
                        Before you add a new account, you need to confirm that you trust this
                        browser. We don't recommend doing this on a public or shared device.{' '}
                        <Link
                            href={getHelpCentreArticleLinkIntercom(
                                '5963262-switching-between-multiple-pleo-accounts',
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Learn more
                        </Link>
                    </Trans>
                </Text>
            </ModalContent>
            <ModalActions>
                <Inline space={16}>
                    <Button variant="secondary" onClick={closeModal}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleTrustSession}
                        disabled={isLoading}
                        loading={isLoading}
                    >
                        <Trans>Trust</Trans>
                    </Button>
                </Inline>
            </ModalActions>
        </Modal>
    )
}

export const AddAccount = () => {
    const {isOpen: showSwitchAccountModal, dismiss: closeSwitchAccountModal} =
        useAccountSwitchModal()
    const {accounts} = useLoggedInAccounts()

    return (
        <>
            {showSwitchAccountModal && (
                <SwitchAccountModalWrapper
                    accounts={accounts}
                    onDismiss={closeSwitchAccountModal}
                />
            )}
        </>
    )
}

const ErrorMessageWrapper = styled.div`
    min-height: 20px;
`
