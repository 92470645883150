export interface LoadScriptProps {
    src: string
    id?: string
    asynchronous: boolean
    prependScript?: boolean
    onload?: () => void
    attributes?: {
        [key: string]: string
    }
}

export function loadScript({
    src,
    id,
    asynchronous = false,
    prependScript = false,
    onload = () => {},
    attributes,
}: LoadScriptProps) {
    if (document.querySelectorAll(`[src="${src}"]`).length > 0) {
        return
    }

    const script = document.createElement('script')
    script.src = src
    script.async = asynchronous
    script.onload = onload

    if (attributes) {
        Object.entries(attributes).forEach(([key, value]) => {
            script.setAttribute(key, value)
        })
    }

    if (id) {
        script.id = id
    }

    const firstScript = document.getElementsByTagName('script')[0]
    const firstParentNode = firstScript?.parentNode

    if (prependScript && firstParentNode) {
        firstParentNode.insertBefore(script, firstScript)
    } else {
        document.body.appendChild(script)
    }
}
