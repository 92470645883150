import queryString from 'qs'
import {useMemo} from 'react'
import useSWR from 'swr'

import {callAuth, RequestAuth} from '@product-web/shared--api'
import type {Invite} from '@product-web/shared--api-types/invite'
import {useCompanyUser} from '@product-web/shared--user'
import {sort} from '@product-web/shared--utils'

async function getKerberos(route: string) {
    return callAuth(route, {
        auth: RequestAuth.USER,
        method: 'GET',
    })
}

const sortInvites = (a: Invite, b: Invite) =>
    sort.caseInsensitive(
        a.firstName ? a.firstName + a.lastName : '',
        b.firstName ? b.firstName + b.lastName : '',
    )

function useInvites() {
    const {companyId: resourceId} = useCompanyUser()

    const requestQuery = queryString.stringify({resource: 'company', resourceId})
    return useSWR<Invite[]>(`/api/v1/invites?${requestQuery}`, getKerberos)
}

export function useSortedInvites() {
    const result = useInvites()
    const {data, ...rest} = result

    const add = (newInvite?: Invite) => {
        if (!data) {
            return
        }
        if (!newInvite) {
            result.mutate()
        } else {
            result.mutate([...data, newInvite].sort(sortInvites))
        }
    }

    return {
        data: useMemo(() => data?.sort(sortInvites), [data?.length]),
        mutations: {add},
        ...rest,
    }
}
