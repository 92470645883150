import {getIsFdd, useHasAllowedRole, useLoggedInUser} from '@product-web/shared--user'

import {AutoTopUpAdoptionPrompts} from './auto-top-up-adoption-prompts/auto-top-up-adoption-prompts'
import {usePromptsPersistedState} from './auto-top-up-adoption-prompts/auto-top-up-adoption-prompts-state'

import {featureFundsManagementBff} from '../bff-hooks'
import {OverdraftAdoptionPrompts} from '../credit/overdraft-adoption-prompts'

const useAutoTopUpAdoptionPrompts = ({
    isAutoTopUpPromotionDisabled,
}: {
    isAutoTopUpPromotionDisabled: boolean
}) => {
    const user = useLoggedInUser()
    const isFdd = getIsFdd(user)

    const isOwnerOrBookkeeper = useHasAllowedRole([
        'owner',
        'bookkeeper-extended',
        'bookkeeper-basic',
    ])

    return featureFundsManagementBff.adoption.getAutoTopUpAdoptionPrompts.useQuery(undefined, {
        enabled: !isAutoTopUpPromotionDisabled && isFdd && isOwnerOrBookkeeper,
        staleTime: Infinity,
    })
}

export const WalletFeatureAdoptionPrompts = () => {
    // In WALLE-4735 we temporarily hid the Auto Top-up promotion prompts,
    // but it was required to be able to easily start showing them again.
    // To do so, simply revert the changes of the PR or remove the "isAutoTopUpPromotionDisabled" variable.
    // p.s. don't forget to clean up the tests and all the related places.
    const isAutoTopUpPromotionDisabled = true

    const {data} = useAutoTopUpAdoptionPrompts({isAutoTopUpPromotionDisabled})
    const canBeActivated = Boolean(data?.canBeActivated)
    const hasLowBalance = Boolean(data?.hasLowBalance)
    const hasDeclinedTransactions = Boolean(data?.hasDeclinedTransactions)

    const {dismissedPrompts} = usePromptsPersistedState()
    const isAutoTopUpPromptsVisible =
        canBeActivated && !dismissedPrompts?.dismissedDate && !isAutoTopUpPromotionDisabled

    if (!isAutoTopUpPromptsVisible) {
        // The Auto Top-up (ATU) prompts have a priority over Overdraft
        // If we can't show ATU prompts, return Overdraft prompts with their own conditions inside
        return <OverdraftAdoptionPrompts />
    }

    return (
        <AutoTopUpAdoptionPrompts
            canBeActivated={canBeActivated}
            hasLowBalance={hasLowBalance}
            hasDeclinedTransactions={hasDeclinedTransactions}
            declinedTransactionDetails={data?.declinedTransactionDetails}
        />
    )
}
