import {Plural, t, Trans} from '@lingui/macro'

import {Box, Inline, ModalSplit, Stack, Tag, Text, tokens} from '@pleo-io/telescope'

import tracking from '@product-web/shared--analytics'

import {
    Boldon,
    CheckCircleIcon,
    DaysBreakdownPanel,
    EmptyCircleIcon,
} from './how-trial-works.styles'
import MagicianIllustration from './images/magician.svg'

import type {TrialActivationBannerActionedExtended} from '../trial/types'

interface HowTrialWorksGuideProps {
    onDone: () => void
    onDismiss: () => void
    trialLength: number
}
export const HowTrialWorksGuide = ({onDone, onDismiss, trialLength}: HowTrialWorksGuideProps) => {
    const lastTrialDay = trialLength > 0 ? trialLength - 1 : 0

    return (
        <ModalSplit
            isOpen={true}
            onDismiss={() => {
                tracking.trialActivationBannerActioned({
                    action: 'closed',
                } as TrialActivationBannerActionedExtended)
                onDismiss()
            }}
            aria-label={t`Modal explaining how the trial works`}
        >
            <ModalSplit.Content>
                <ModalSplit.Body>
                    <ModalSplit.Title>
                        <Trans>Experience the best of Pleo for free</Trans>
                    </ModalSplit.Title>
                    <Stack space={20}>
                        <p>
                            <Trans>
                                Try all features free for{' '}
                                <Boldon>
                                    <Plural value={trialLength} one="# day" other="# days" />{' '}
                                </Boldon>
                                after you add funds to your Wallet.
                            </Trans>
                        </p>
                        <p>
                            <Trans>
                                <Tag variant="pink" css={{marginRight: tokens.spacing4}}>
                                    Trial
                                </Tag>
                                sticker will help you to identify essential features as you explore.
                            </Trans>
                        </p>
                        <section>
                            <DaysBreakdownPanel>
                                <Box paddingBottom={16}>
                                    <Text variant="large-accent" weight="semibold">
                                        <Trans>How your trial works</Trans>
                                    </Text>
                                </Box>
                                <Stack space={20}>
                                    <Inline space={8} alignItems="start">
                                        <div>
                                            <CheckCircleIcon />
                                        </div>
                                        <div>
                                            <Text weight="semibold">
                                                <Trans>Day 1</Trans>
                                            </Text>
                                            <Text variant="small-subtle" color="colorContentStatic">
                                                <Trans>
                                                    Top up your wallet to start your trial
                                                </Trans>
                                            </Text>
                                        </div>
                                    </Inline>
                                    <Inline space={8} alignItems="start">
                                        <div>
                                            <EmptyCircleIcon />
                                        </div>
                                        <div>
                                            <Text weight="semibold">
                                                <Trans>Day 2-{lastTrialDay}</Trans>
                                            </Text>
                                            <Text variant="small-subtle" color="colorContentStatic">
                                                <Trans>
                                                    See how Pleo can help you revolutionise spend
                                                    management
                                                </Trans>
                                            </Text>
                                        </div>
                                    </Inline>
                                    <Inline space={8} alignItems="start">
                                        <div>
                                            <EmptyCircleIcon />
                                        </div>
                                        <div>
                                            <Text weight="semibold">
                                                <Trans>Day {trialLength}</Trans>
                                            </Text>
                                            <Text variant="small-subtle" color="colorContentStatic">
                                                <Trans>
                                                    Automatically switch back to the free version -
                                                    unless you decide to upgrade
                                                </Trans>
                                            </Text>
                                        </div>
                                    </Inline>
                                </Stack>
                            </DaysBreakdownPanel>
                        </section>
                    </Stack>
                </ModalSplit.Body>
                <ModalSplit.Actions>
                    <ModalSplit.NextButton
                        onClick={() => {
                            tracking.trialActivationBannerActioned({
                                action: 'wallet',
                            } as TrialActivationBannerActionedExtended)
                            onDone()
                        }}
                    >
                        <Trans>Go to Wallet</Trans>
                    </ModalSplit.NextButton>
                </ModalSplit.Actions>
            </ModalSplit.Content>
            <ModalSplit.IllustrationContainer>
                <ModalSplit.Illustration>
                    <img src={MagicianIllustration} alt="" aria-hidden width="300" height="300" />
                </ModalSplit.Illustration>
            </ModalSplit.IllustrationContainer>
        </ModalSplit>
    )
}
