import type {ReactNode} from 'react'
import {createContext, useContext, useEffect, useState} from 'react'

import {useMobileNavigationVisibility} from '@product-web/shared--navigation/navigation-context'
import {breakpoints} from '@product-web/shared--styles/theme'
import {useUser} from '@product-web/shared--user'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

export interface HelpCentreContextValue {
    isHelpCentreVisible: boolean
    setIsHelpCentreVisible: React.Dispatch<React.SetStateAction<boolean>>
    isHelpWidgetOpen: boolean
    setIsHelpWidgetOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const HelpCentreContext = createContext<HelpCentreContextValue>({
    isHelpCentreVisible: false,
    setIsHelpCentreVisible: () => {},
    isHelpWidgetOpen: false,
    setIsHelpWidgetOpen: () => {},
})

export const HelpCentreProvider = ({children}: {children: ReactNode}) => {
    const user = useUser()
    const acceptedTerms = user?.status?.acceptedTerms ?? false
    const isTablet = useMediaQuery(`(max-width: ${breakpoints.tabletMedUp})`)
    const [isHelpCentreVisible, setIsHelpCentreVisible] = useState(true)
    const {isMobileNavigationVisible} = useMobileNavigationVisibility()
    const [isHelpWidgetOpen, setIsHelpWidgetOpen] = useState(false)

    useEffect(() => {
        /**
         * If the user is on a tablet, we want to show the help centre if the mobile navigation is visible.
         * If the user has not accepted the terms though, we want to show the help centre regardless of the mobile navigation.
         * TODO: look for references about this decision and add a link here.
         */
        if (isTablet) {
            setIsHelpCentreVisible(isMobileNavigationVisible || !acceptedTerms)
        }
    }, [isTablet, isMobileNavigationVisible, acceptedTerms, isHelpCentreVisible])

    const contextValue = {
        isHelpCentreVisible,
        setIsHelpCentreVisible,
        isHelpWidgetOpen,
        setIsHelpWidgetOpen,
    }

    return <HelpCentreContext.Provider value={contextValue}>{children}</HelpCentreContext.Provider>
}

export const useHelpCentre = () => useContext(HelpCentreContext)

export const useHideHelpCentre = () => {
    const {setIsHelpCentreVisible} = useHelpCentre()
    return useEffect(() => {
        setIsHelpCentreVisible(false)
        return () => setIsHelpCentreVisible(true)
    }, [setIsHelpCentreVisible])
}
