import {t} from '@lingui/macro'

import {SupportedLanguage} from '@product-web/shared--i18n'
import type {PageOrigin} from '@product-web/shared--paywall/index.bff'
import {useUser} from '@product-web/shared--user'

import ActivateInvoicesEn from './assets/activate-invoices-en@2x.png'
import LandingAnimationBudgetsEn from './assets/landing-animation-budgets-en.gif'
import LandingAnimationPopularVendorCardsEn from './assets/landing-animation-popular-vendor-cards-en.gif'
import LandingAnimationSpendGuidelinesEn from './assets/landing-animation-spend-guidelines-en.gif'
import LandingAnimationVendorCardsEn from './assets/landing-animation-vendor-cards-en.gif'
import TagReviewOnboardingLeft from './assets/tag-review-onboarding-left@2x.png'
import type {MediaWrapperProps} from './components/landing-page-media'

export const useLocalisedAnimation = ({type}: {type: PageOrigin}) => {
    const user = useUser()

    // Get only the generic language code without locales
    const userLanguage = (user?.language?.slice(0, 2) as SupportedLanguage) || SupportedLanguage.EN

    // Make sure there is always a type set, this should not happen
    const AnimationSourceType =
        AnimationSourceConfig[type] ?? AnimationSourceConfig['vendor-cards']!

    // Default to English if language not found
    const animationSrc = AnimationSourceType[userLanguage] ?? AnimationSourceType.en!

    // Add the respective alt text if exists
    const animationAlt = AnimationAltTextConfig[type]?.() ?? ''

    const animationStyle = AnimationStyleOverride[type] ?? DefaultAnimationStyle

    return {animationSrc, animationAlt, userLanguage, animationStyle}
}

const DefaultAnimationStyle: MediaWrapperProps = {
    padding: '0',
}

const AnimationStyleOverride: Partial<Record<PageOrigin, MediaWrapperProps>> = {
    guidelines: {
        ...DefaultAnimationStyle,
        flex: 3,
    },
    'tag-review': {
        ...DefaultAnimationStyle,
        flex: 3,
        alignItems: 'flex-end',
    },
}

const AnimationSourceConfig: Partial<
    Record<PageOrigin, Partial<Record<SupportedLanguage, string>>>
> = {
    guidelines: {
        [SupportedLanguage.EN]: LandingAnimationSpendGuidelinesEn,
    },
    'vendor-cards': {
        [SupportedLanguage.EN]: LandingAnimationVendorCardsEn,
    },
    'popular-vendor-cards': {
        [SupportedLanguage.EN]: LandingAnimationPopularVendorCardsEn,
    },
    budgets: {
        [SupportedLanguage.EN]: LandingAnimationBudgetsEn,
    },
    'recurring-vendors': {
        [SupportedLanguage.EN]: LandingAnimationVendorCardsEn,
    },
    'tag-review': {
        [SupportedLanguage.EN]: TagReviewOnboardingLeft,
    },
    'activate-invoices': {
        [SupportedLanguage.EN]: ActivateInvoicesEn,
    },
}

const AnimationAltTextConfig: Partial<Record<PageOrigin, () => string>> = {
    guidelines: () => t`Example overview of spending guidelines`,
    'vendor-cards': () =>
        t`Example overview with vendor cards for Linkedin Ads, Loom, Zoom, and Slack. The animation shows a new card being created, assigned to an employee, and then reassigned.`,
    'recurring-vendors': () =>
        t`Example overview with subscriptions for Linkedin Ads, Loom, Zoom, and Slack. The animation shows a new vendor card being created, assigned to an employee, and then reassigned.`,
    budgets: () =>
        t`An example budget is created and attached to the tag 'Project Apollo'. Fifteen thousand pounds are allocated. An expiry date is set and a reviewer is selected.`,
    'popular-vendor-cards': () => AnimationAltTextConfig['vendor-cards']!(),
}
