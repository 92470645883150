import {t, Trans} from '@lingui/macro'
import React from 'react'

import {Button} from '@pleo-io/telescope'
import {Email} from '@pleo-io/telescope-icons'

import tracking from '@product-web/shared--analytics'

import {bff} from '../../../../bff'

export const SendButton = ({
    isOrganizationAdmin,
    onNextStep,
    onChangeErrorStatus,
}: {
    isOrganizationAdmin: boolean
    onNextStep: () => void
    onChangeErrorStatus: (hasError: boolean) => void
}) => {
    const {mutateAsync: requestEntities, isLoading} =
        bff.featureMultiEntity.groupStepForm.requestGroupEntities.useMutation()

    React.useEffect(() => {
        onChangeErrorStatus(false)
    }, [])

    const handleSubmit = async () => {
        onChangeErrorStatus(false)
        try {
            await requestEntities()
            tracking.selfExpansionRequestSent({
                addNewEntities: false,
                groupExistingEntities: true,
                hasOrgAccess: isOrganizationAdmin,
            })
            onNextStep()
        } catch (e) {
            onChangeErrorStatus(true)
        }
    }

    return (
        <Button
            IconLeft={isLoading ? undefined : Email}
            loading={isLoading}
            variant="primary"
            onClick={handleSubmit}
            loadingText={t`Sending...`}
        >
            <Trans>Send request</Trans>
        </Button>
    )
}
