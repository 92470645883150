import {SupportedLanguage} from '@product-web/shared--i18n'
import type {PageOrigin} from '@product-web/shared--paywall/index.bff'
import {useUser} from '@product-web/shared--user'

import LandingPagePocketThumbnail from './assets/landing-thumbnail-pocket.png'

export const useLocalisedVideo = ({type}: {type: PageOrigin}) => {
    const user = useUser()
    const userLanguage = (user?.language?.slice(0, 2) as SupportedLanguage) || SupportedLanguage.EN

    const videoConfig =
        VideosConfig[type]?.[userLanguage] || VideosConfig[type]?.[SupportedLanguage.EN]

    return {
        videoId: videoConfig?.videoId || '',
        thumbnail: videoConfig?.thumbnail || '',
    }
}

type VideoConfig = Partial<Record<SupportedLanguage, {videoId: string; thumbnail: string}>>

export const VideosConfig: Partial<Record<PageOrigin, VideoConfig>> = {
    pocket: {
        [SupportedLanguage.EN]: {
            videoId: '8wyh3zqk5f',
            thumbnail: LandingPagePocketThumbnail,
        },
    },
    reimbursements: {
        [SupportedLanguage.EN]: {
            videoId: '8wyh3zqk5f',
            thumbnail: LandingPagePocketThumbnail,
        },
    },
}
