/* eslint-disable string-to-lingui/text-restrictions */

import {t, Trans} from '@lingui/macro'
import qs from 'qs'
import type React from 'react'
import {useEffect, useState} from 'react'
import Helmet from 'react-helmet'
import {useNavigate} from 'react-router-dom'
import {useGate} from 'statsig-react'
import styled, {css} from 'styled-components'

import {Button, LoadingPage as TelescopeLoadingPage, Stack, Text, tokens} from '@pleo-io/telescope'

import {useOpenBankingRedirectParams} from '@product-web/feature--funds-management/use-open-banking-redirect-params'
import {TrialBannerFlagContainer} from '@product-web/feature--onboarding/trial/banner.container'
import {Container, MainSectionWrapper, Page} from '@product-web/feature--ui-page/page'
import * as tracking from '@product-web/shared--analytics'
import config from '@product-web/shared--config'
import {reportError} from '@product-web/shared--error/report'
import {getHelpCentreArticleLink} from '@product-web/shared--locale/helpers'
import type {PageSection} from '@product-web/shared--paywall/index.bff'
import {PricingPlanModalWrapper} from '@product-web/shared--paywall/pricing-plan-modal/pricing-plan-modal-wrapper'
import {PlanUpgradeSource} from '@product-web/shared--paywall/types/plans'
import PageNotFound from '@product-web/shared--routes/page-not-found/page-not-found'
import {breakpoints, pxBreakpoints} from '@product-web/shared--styles/theme'
import {
    containerQuery,
    useContainerQuery,
} from '@product-web/shared--telescope-lab/container-queries/container'
import {useToaster} from '@product-web/shared--toaster'
import {getFirstName, useCompanyUser} from '@product-web/shared--user'
import {exhaustiveCheck} from '@product-web/shared--utils'
import {createPersistedState} from '@product-web/shared--web-platform/persisted-state'

import {Error} from './error'
import {Loading} from './loading'

import {bff} from '../../bff-hooks'
import {BookkeepingMilestoneAccordionItem} from '../../components/bookkeeping-milestone-accordion-item'
import {DownloadAppModal} from '../../components/download-app-modal/download-app-modal'
import {FooterCard} from '../../components/footer-card'
import {InviteTeamMilestoneAccordionItem} from '../../components/invite-team-milestone-accordion-item'
import {MilestoneAccordion} from '../../components/milestone-accordion'
import {MilestoneCard} from '../../components/milestone-card'
import {ReimbursementsMilestoneAccordionItem} from '../../components/reimbursements-milestone-accordion-item'
import {SchedulePaymentsMilestoneAccordionItem} from '../../components/schedule-payments-milestone-accordion-item'
import {SpendingLimitsMilestoneAccordionItem} from '../../components/spending-limits-milestone-accordion-item'
import {TrialStartedModal} from '../../components/trial-started-modal/trial-started-modal'
import {TryCardsMilestoneAccordionItem} from '../../components/try-cards-milestone-accordion-item'
import illustrationExploreMilestoneGroup from '../../images/checklist.svg'
import illustrationComplete from '../../images/person-with-laptop.svg'
import illustrationTrialMilestoneGroup from '../../images/welcome.svg'
import type {GetGetStartedScreenData} from '../../index.bff'
import {useDownloadMobileAppModal} from '../../lib/use-download-mobile-app-modal'

const MOBILE_BREAKPOINT = pxBreakpoints.tabletMedUp
const CONTAINER_QUERY_NAME = 'main'
export const GET_STARTED_STORAGE_KEY = 'get-started-page'

//#region Components

export const GetStartedScreen = () => {
    const {isLoading: isOpenBankingRedirectHandlerLoading} = useOpenBankingRedirectParams()
    const {data: footerData, isLoading: isFooterDataLoading} =
        bff.companyOnboarding.getStarted.getFooterData.useQuery()
    const user = useCompanyUser()
    const gate = useGate('company_onboarding_-_has_get_started_screen')

    if (gate.isLoading || isOpenBankingRedirectHandlerLoading) {
        return <TelescopeLoadingPage />
    }

    const companyOnboardingVersion = gate.value ? 'get-started' : 'pleo-guide'
    if (companyOnboardingVersion !== 'get-started') {
        return <PageNotFound />
    }

    const openLinkInNewTab = (url: string) => {
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        window.open(url, '_blank', 'noopener, noreferrer')
    }

    // Fall back to the DEMO_PLAYGROUND variant when there is no data available so the user sees a demo card
    const demoCardVariant = footerData?.demoVariant ?? 'DEMO_PLAYGROUND'

    const demoCardDestinationUrl = getFooterCardDestinationUrl({
        cardVariant: demoCardVariant,
        email: user.email,
        firstName: user.employee?.firstName,
        lastName: user.employee?.lastName,
    })
    const helpCentreCardDestinationUrl = getFooterCardDestinationUrl({
        cardVariant: 'HELP_CENTRE',
    })

    return (
        <GridContainer>
            <Helmet>
                <title>{t`Get Started`}</title>
            </Helmet>

            <div>
                <TrialBannerFlagContainer hidePreTrialBanners />

                <Page layout="wide" showNav>
                    <MaxWidthWrapper>
                        <Page.Header>
                            <Page.Title>
                                <Trans>Get Started</Trans>
                            </Page.Title>
                        </Page.Header>

                        <GetStartedScreenPageContent />
                    </MaxWidthWrapper>
                </Page>
            </div>

            <FooterSection>
                <MaxWidthWrapper>
                    <InnerFooter>
                        <FooterCard
                            variant="HELP_CENTRE"
                            onClick={() => openLinkInNewTab(helpCentreCardDestinationUrl)}
                            headingLevel="h3"
                            isLoading={false}
                        />

                        <FooterCard
                            variant={demoCardVariant}
                            onClick={() => openLinkInNewTab(demoCardDestinationUrl)}
                            headingLevel="h3"
                            isLoading={isFooterDataLoading}
                        />
                    </InnerFooter>
                </MaxWidthWrapper>
            </FooterSection>
        </GridContainer>
    )
}

const [usePersistedState] = createPersistedState(GET_STARTED_STORAGE_KEY, localStorage)

const GetStartedScreenPageContent = () => {
    const navigate = useNavigate()
    const {showToast} = useToaster()
    const {isDownloadAppModalOpen, onCloseDownloadAppModal} = useDownloadMobileAppModal()
    const [trialStartedModalOpen, setTrialStartedModalOpen] = useState(false)
    const [pricingModalProps, setPricingModalProps] = useState<
        Pick<
            React.ComponentProps<typeof PricingPlanModalWrapper>,
            'isOpen' | 'planUpgradeSource' | 'pageOrigin' | 'pageSection'
        >
    >({
        isOpen: false,
        planUpgradeSource: PlanUpgradeSource.GET_STARTED_PAGE,
        pageOrigin: 'get-started',
    })
    const user = useCompanyUser()
    const firstName = getFirstName(user)
    const {
        data,
        error: dataError,
        refetch: refreshData,
        isInitialLoading,
    } = bff.companyOnboarding.getStarted.getData.useQuery(undefined, {
        // We need to always refetch on mount to get the latest data. It isn't always refreshed automatically
        // because we aren't using BFF mutations everywhere yet
        refetchOnMount: 'always',
    })
    const {mutateAsync: hideCompanyOnboarding, isLoading: isLoadingHideCompanyOnboarding} =
        bff.companyOnboarding.getStarted.hideCompanyOnboarding.useMutation({
            onSuccess: () => {
                navigate('/expenses', {
                    replace: true,
                })
            },
            onError: () => {
                showToast(t`Check your internet connection and try again.`, {
                    title: t`Couldn't complete the Get started setup`,
                    level: 'error',
                })
            },
        })
    const isBigScreen = useContainerQuery({
        name: CONTAINER_QUERY_NAME,
        minWidth: MOBILE_BREAKPOINT,
    })

    const [buttonClicked, setButtonClicked] = usePersistedState('continue-button-clicked', false)

    useEffect(() => {
        if (!isInitialLoading && data?.milestoneGroups) {
            const activeGroup = getActiveGroup(data.milestoneGroups, buttonClicked)

            const milestoneNames = activeGroup.milestones?.map((milestone) => milestone.name)
            const taskNames = activeGroup.milestones?.flatMap((milestone) =>
                milestone.tasks?.map((task) => task.name),
            )

            tracking.getStartedActioned({
                action: 'viewed',
                milestones: milestoneNames,
                tasks: taskNames,
            })
        }
    }, [isInitialLoading, data?.milestoneGroups, buttonClicked])

    const openPricingModal = ({pageSection}: {pageSection: PageSection}) =>
        setPricingModalProps((prev) => ({
            ...prev,
            isOpen: true,
            pageSection,
        }))
    const closePricingModal = () => setPricingModalProps((prev) => ({...prev, isOpen: false}))

    if (dataError) {
        return <Error />
    }

    if (!data) {
        return <Loading />
    }

    const {
        milestoneGroups,
        bookkeeperEmails,
        reserveData,
        hasDynamicMilestones,
        isFromMultiEntitySignupFlow,
    } = data

    const allMilestonesCompleteOrSkipped = isAllMilestonesCompleteOrSkipped(milestoneGroups)

    const activeGroup = getActiveGroup(milestoneGroups, buttonClicked)

    if (activeGroup.milestones.length === 0) {
        reportError('Active milestone group has no milestones', null, {
            activeGroupName: activeGroup.name,
        })

        return <Error />
    }

    const {illustration, heading, description} = getPageContent({
        groupName: activeGroup.name,
        firstName,
        companyName: user.company.name,
        isFromMultiEntitySignupFlow: !!isFromMultiEntitySignupFlow,
        allMilestonesCompleteOrSkipped,
    })

    const sortedMilestones = [...activeGroup.milestones]
        // Sort completed milestones at the bottom
        .sort((a, b) => {
            if (a.status === 'COMPLETE' && b.status !== 'COMPLETE') {
                return 1
            }

            if (a.status !== 'COMPLETE' && b.status === 'COMPLETE') {
                return -1
            }

            return 0
        })

    const firstNotCompleteMilestone = sortedMilestones.find(
        (milestone) => milestone.status !== 'COMPLETE',
    )

    return (
        <PageContent>
            {hasDynamicMilestones ? null : (
                <DownloadAppModal
                    isOpen={isDownloadAppModalOpen}
                    closeModal={onCloseDownloadAppModal}
                />
            )}

            {((data.isOnTrial &&
                activeGroup.name === 'TRIAL' &&
                activeGroup.status === 'COMPLETE') ||
                trialStartedModalOpen) && (
                <TrialStartedModal
                    isOpen={trialStartedModalOpen}
                    closeModal={() => setTrialStartedModalOpen(false)}
                />
            )}

            <PricingPlanModalWrapper {...pricingModalProps} onDismiss={closePricingModal} />

            <IllustrationSection>
                {isBigScreen && <Illustration src={illustration} alt="" />}

                <Stack space={16}>
                    <Stack space={4}>
                        <Text as="h2" variant="2xlarge-accent" weight="medium">
                            {heading}
                        </Text>

                        <Text as="p">{description}</Text>
                    </Stack>

                    {allMilestonesCompleteOrSkipped && (
                        <Button
                            variant="primary"
                            loading={isLoadingHideCompanyOnboarding}
                            onClick={async () => {
                                await hideCompanyOnboarding()
                            }}
                        >
                            <Trans>All done</Trans>
                        </Button>
                    )}
                </Stack>
            </IllustrationSection>

            {activeGroup.name === 'TRIAL' && (
                <Stack space={24}>
                    <Stack space={16} as="section" stretch>
                        {activeGroup.milestones.map((milestone) => {
                            const companyVerificationMilestone = activeGroup.milestones.find(
                                (m) => m.name === 'GET_VERIFIED',
                            )
                            const companyVerificationMilestoneStatus =
                                companyVerificationMilestone?.status
                            const isCompanyVerificationSubmitted =
                                companyVerificationMilestoneStatus === 'AWAITING_REVIEW' ||
                                companyVerificationMilestoneStatus === 'COMPLETE'

                            return (
                                <MilestoneCard
                                    key={milestone.name}
                                    headingLevel="h4"
                                    milestone={milestone.name}
                                    status={milestone.status}
                                    shouldShowReserve={
                                        reserveData.isInReserveOnboardingTest &&
                                        isCompanyVerificationSubmitted
                                    }
                                    shouldShowOverdraftApplication={
                                        data.overdraftApplicationData?.isEligible &&
                                        !data.overdraftActivationData
                                    }
                                    shouldShowOverdraftActivation={Boolean(
                                        data.overdraftActivationData,
                                    )}
                                    reserveLimit={reserveData.reserveLimit}
                                    overdraftLimit={data.overdraftApplicationData?.limit}
                                    overdraftApplicationState={
                                        data.overdraftApplicationData?.applicationState
                                    }
                                    overdraftActivationData={data.overdraftActivationData}
                                    isFromMultiEntitySignupFlow={!!isFromMultiEntitySignupFlow}
                                />
                            )
                        })}
                    </Stack>

                    <Button
                        variant="primary"
                        disabled={activeGroup.status !== 'COMPLETE'}
                        onClick={() => {
                            tracking.companyOnboardingNextStepsButtonActioned()

                            if (data.isOnTrial) {
                                setTrialStartedModalOpen(true)
                            }

                            setButtonClicked(true)
                        }}
                    >
                        <Trans>Next steps</Trans>
                    </Button>
                </Stack>
            )}

            {activeGroup.name === 'EXPLORE' && (
                <MilestoneAccordion type="single" defaultValue={firstNotCompleteMilestone?.name}>
                    {sortedMilestones.map((milestone, i) => {
                        const key = milestone.name + i

                        switch (milestone.name) {
                            case 'CONNECT_ACCOUNTING':
                                return (
                                    <BookkeepingMilestoneAccordionItem
                                        key={key}
                                        status={milestone.status}
                                        tasks={milestone.tasks}
                                        milestoneName={milestone.name}
                                        bookkeeperEmails={bookkeeperEmails}
                                        refreshData={async () => {
                                            await refreshData()
                                        }}
                                        isOnTrial={data.isOnTrial}
                                    />
                                )
                            case 'INVITE_TEAM':
                                return (
                                    <InviteTeamMilestoneAccordionItem
                                        key={key}
                                        status={milestone.status}
                                        tasks={milestone.tasks}
                                        milestoneName={milestone.name}
                                    />
                                )
                            case 'SET_CONTROLS':
                                return (
                                    <SpendingLimitsMilestoneAccordionItem
                                        key={key}
                                        status={milestone.status}
                                        tasks={milestone.tasks}
                                        milestoneName={milestone.name}
                                        isOnTrial={data.isOnTrial}
                                        requiresUpgrade={milestone.requiresUpgrade}
                                        openPricingModal={openPricingModal}
                                    />
                                )
                            case 'REIMBURSE_EXPENSES':
                                return (
                                    <ReimbursementsMilestoneAccordionItem
                                        key={key}
                                        status={milestone.status}
                                        tasks={milestone.tasks}
                                        milestoneName={milestone.name}
                                        isOnTrial={data.isOnTrial}
                                        requiresUpgrade={milestone.requiresUpgrade}
                                        openPricingModal={openPricingModal}
                                    />
                                )
                            case 'TRY_CARDS':
                                return (
                                    <TryCardsMilestoneAccordionItem
                                        key={key}
                                        status={milestone.status}
                                        tasks={milestone.tasks}
                                        milestoneName={milestone.name}
                                    />
                                )
                            case 'SCHEDULE_PAYMENTS':
                                return (
                                    <SchedulePaymentsMilestoneAccordionItem
                                        key={key}
                                        status={milestone.status}
                                        tasks={milestone.tasks}
                                        milestoneName={milestone.name}
                                    />
                                )
                            default:
                                return null
                        }
                    })}
                </MilestoneAccordion>
            )}
        </PageContent>
    )
}

//#endregion Components

//#region Helpers

type MilestoneGroupName = 'TRIAL' | 'EXPLORE'

type GetPageContentArgs = {
    groupName: MilestoneGroupName
    firstName: string
    companyName: string
    isFromMultiEntitySignupFlow: boolean
    allMilestonesCompleteOrSkipped: boolean
}

const getPageContent = ({
    groupName,
    firstName,
    companyName,
    isFromMultiEntitySignupFlow,
    allMilestonesCompleteOrSkipped,
}: GetPageContentArgs) => {
    let heading = ''
    let description = ''

    if (allMilestonesCompleteOrSkipped) {
        return {
            illustration: illustrationComplete,
            heading: t`Nice work!`,
            description: isFromMultiEntitySignupFlow
                ? t`You've finished all the setup steps for your entity's Pleo account.`
                : t`You've finished all the setup steps for your company's Pleo account.`,
        }
    }

    switch (groupName) {
        case 'TRIAL':
            if (isFromMultiEntitySignupFlow) {
                heading = firstName ? t`Hello again, ${firstName}` : t`Hello again`
                description = t`Let's set up a Pleo account for ${companyName}.`
            } else {
                heading = firstName ? t`Welcome to Pleo, ${firstName}` : t`Welcome to Pleo`
                description = t`Activate your account to try Pleo for free.`
            }

            return {
                illustration: illustrationTrialMilestoneGroup,
                heading,
                description,
            }
        case 'EXPLORE':
            return {
                illustration: illustrationExploreMilestoneGroup,
                heading: t`You're in! What's next?`,
                description: t`Here's what we recommend based on your interests.`,
            }
        default:
            return exhaustiveCheck(groupName)
    }
}

type GetFooterCardDestinationUrlArgs = {
    cardVariant: React.ComponentProps<typeof FooterCard>['variant']
    email?: string
    firstName?: string
    lastName?: string
}

export const getFooterCardDestinationUrl = ({
    cardVariant,
    email = '',
    firstName = '',
    lastName = '',
}: GetFooterCardDestinationUrlArgs) => {
    switch (cardVariant) {
        case 'HELP_CENTRE':
            return getHelpCentreArticleLink()
        case 'DEMO_PLAYGROUND':
            return `${config.endpoints.commercial}/explore-product`
        case 'DEMO_SALES': {
            // Hubspot booking tool accepts email, firstName and lastName, and prop names must be exactly these
            const params = qs.stringify({email, firstName, lastName}, {addQueryPrefix: true})

            return config.endpoints.onboardingAppSalesDemoBookingUrl + params
        }
        default:
            return exhaustiveCheck(cardVariant)
    }
}

type MilestoneGroups = NonNullable<GetGetStartedScreenData['milestoneGroups']>

function isAllMilestonesCompleteOrSkipped(milestoneGroups: MilestoneGroups) {
    return milestoneGroups
        .flatMap((group) => group.milestones)
        .every((milestone) => milestone.status === 'COMPLETE' || milestone.status === 'SKIPPED')
}

function getActiveGroup(milestoneGroups: MilestoneGroups, buttonClicked: boolean) {
    const allMilestonesCompleteOrSkipped = isAllMilestonesCompleteOrSkipped(milestoneGroups)
    const firstIncompleteGroup = milestoneGroups.find((group) => group.status !== 'COMPLETE')
    const lastGroup = milestoneGroups.slice(-1)[0]
    const activeGroup = allMilestonesCompleteOrSkipped
        ? lastGroup
        : buttonClicked
          ? firstIncompleteGroup ?? lastGroup
          : milestoneGroups[0]

    return activeGroup
}

//#endregion Helpers

//#region Styles

const GridContainer = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 1fr auto;
    width: 100%;
    height: 100vh;
    overflow: auto;

    * {
        box-sizing: border-box;
    }

    ${Container}, ${MainSectionWrapper} {
        height: auto;
    }

    /* Using media queries instead of container queries to match padding and behavior of the telescope-lab page */
    @media (width >= ${breakpoints.desktopUp}) {
        /* Not sure why, but on bigger sizes, the generic-ui page doesn't have a bottom spacing */
        row-gap: ${tokens.spacing56};
    }
`

const MaxWidthWrapper = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
`

const PageContent = styled.div`
    display: grid;
    row-gap: ${tokens.spacing24};
    column-gap: ${tokens.spacing56};
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;

    ${containerQuery(
        {name: CONTAINER_QUERY_NAME, minWidth: MOBILE_BREAKPOINT},
        css`
            grid-template-columns: minmax(10px, 1fr) 2fr;
            grid-template-rows: 1fr;
            gap: ${tokens.spacing56};
        `,
    )}
`

const IllustrationSection = styled.section`
    max-width: 360px;
`

const Illustration = styled.img`
    display: block;
    width: 100%;
    max-width: 320px;
`

// Using media queries instead of container queries to match padding and behavior of the telescope-lab page
const FooterSection = styled.section`
    display: flex;
    justify-content: center;
    padding: ${tokens.spacing24};
    background-color: ${tokens.colorBackgroundStaticLouder};

    @media (width >= ${breakpoints.smallTabletUp}) {
        padding: ${tokens.spacing40};
    }

    @media (width >= ${breakpoints.desktopUp}) {
        padding: ${tokens.spacing56};
    }
`

// Using media queries instead of container queries to match padding and behavior of the telescope-lab page
const InnerFooter = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: ${tokens.spacing24};
    row-gap: ${tokens.spacing24};
    width: 100%;

    @media (width >= ${breakpoints.smallTabletUp}) {
        column-gap: ${tokens.spacing40};
    }

    @media (width >= ${breakpoints.desktopUp}) {
        column-gap: ${tokens.spacing56};
    }

    > * {
        flex: 1;
    }
`

//#endregion Styles
