import type {LoadScriptProps} from './load-script'
import {loadScript} from './load-script'

type AsyncLoadScriptProps = Omit<LoadScriptProps, 'onload'>

export const asyncLoadScript = (properties: AsyncLoadScriptProps): Promise<void> => {
    return new Promise((resolve) => {
        loadScript({
            ...properties,
            onload: resolve,
        })
    })
}
