import {t} from '@lingui/macro'

import {tokens} from '@pleo-io/telescope'

import {SupportedLanguage} from '@product-web/shared--i18n'
import type {PageOrigin} from '@product-web/shared--paywall/index.bff'
import {useUser} from '@product-web/shared--user'

import EmptyStateActivateInvoicesIllustrationDa from './images/empty-state-activate-invoices-da.svg'
import EmptyStateActivateInvoicesIllustrationDe from './images/empty-state-activate-invoices-de.svg'
import EmptyStateActivateInvoicesIllustrationEn from './images/empty-state-activate-invoices-en.svg'
import EmptyStateActivateInvoicesIllustrationEs from './images/empty-state-activate-invoices-es.svg'
import EmptyStateActivateInvoicesIllustrationFi from './images/empty-state-activate-invoices-fi.svg'
import EmptyStateActivateInvoicesIllustrationFr from './images/empty-state-activate-invoices-fr.svg'
import EmptyStateActivateInvoicesIllustrationNl from './images/empty-state-activate-invoices-nl.svg'
import EmptyStateActivateInvoicesIllustrationSv from './images/empty-state-activate-invoices-se.svg'
import EmptyStatesAnalyticsIllustrationDa from './images/empty-state-analytics-da.svg'
import EmptyStatesAnalyticsIllustrationDe from './images/empty-state-analytics-de.svg'
import EmptyStatesAnalyticsIllustrationEn from './images/empty-state-analytics-en.svg'
import EmptyStatesAnalyticsIllustrationEs from './images/empty-state-analytics-es.svg'
import EmptyStatesAnalyticsIllustrationFi from './images/empty-state-analytics-fi.svg'
import EmptyStatesAnalyticsIllustrationFr from './images/empty-state-analytics-fr.svg'
import EmptyStatesAnalyticsIllustrationIt from './images/empty-state-analytics-it.svg'
import EmptyStatesAnalyticsIllustrationNl from './images/empty-state-analytics-nl.svg'
import EmptyStatesAnalyticsIllustrationNo from './images/empty-state-analytics-no.svg'
import EmptyStatesAnalyticsIllustrationPt from './images/empty-state-analytics-pt.svg'
import EmptyStatesAnalyticsIllustrationSv from './images/empty-state-analytics-sv.svg'
import EmptyStatesBudgetsIllustrationDa from './images/empty-state-budgets-da.svg'
import EmptyStatesBudgetsIllustrationDe from './images/empty-state-budgets-de.svg'
import EmptyStatesBudgetsIllustrationEn from './images/empty-state-budgets-en.svg'
import EmptyStatesBudgetsIllustrationEs from './images/empty-state-budgets-es.svg'
import EmptyStatesBudgetsIllustrationFi from './images/empty-state-budgets-fi.svg'
import EmptyStatesBudgetsIllustrationFr from './images/empty-state-budgets-fr.svg'
import EmptyStatesBudgetsIllustrationIt from './images/empty-state-budgets-it.svg'
import EmptyStatesBudgetsIllustrationNl from './images/empty-state-budgets-nl.svg'
import EmptyStatesBudgetsIllustrationNo from './images/empty-state-budgets-no.svg'
import EmptyStatesBudgetsIllustrationPt from './images/empty-state-budgets-pt.svg'
import EmptyStatesBudgetsIllustrationSv from './images/empty-state-budgets-sv.svg'
import EmptyStatePeopleIntegrationsIllustrationEn from './images/empty-state-people-integrations-en.png'
import PocketVendorCardsIllustrationDa from './images/empty-state-pocket-da.svg'
import PocketVendorCardsIllustrationDe from './images/empty-state-pocket-de.svg'
import PocketVendorCardsIllustrationEn from './images/empty-state-pocket-en.svg'
import PocketVendorCardsIllustrationEs from './images/empty-state-pocket-es.svg'
import PocketVendorCardsIllustrationFi from './images/empty-state-pocket-fi.svg'
import PocketVendorCardsIllustrationFr from './images/empty-state-pocket-fr.svg'
import PocketVendorCardsIllustrationIt from './images/empty-state-pocket-it.svg'
import PocketVendorCardsIllustrationNl from './images/empty-state-pocket-nl.svg'
import PocketVendorCardsIllustrationNo from './images/empty-state-pocket-no.svg'
import PocketVendorCardsIllustrationPt from './images/empty-state-pocket-pt.svg'
import PocketVendorCardsIllustrationSv from './images/empty-state-pocket-sv.svg'
import EmptyStatesRecurringExpensesIllustrationDa from './images/empty-state-recurring-expenses-da.svg'
import EmptyStatesRecurringExpensesIllustrationDe from './images/empty-state-recurring-expenses-de.svg'
import EmptyStatesRecurringExpensesIllustrationEn from './images/empty-state-recurring-expenses-en.svg'
import EmptyStatesRecurringExpensesIllustrationEs from './images/empty-state-recurring-expenses-es.svg'
import EmptyStatesRecurringExpensesIllustrationFi from './images/empty-state-recurring-expenses-fi.svg'
import EmptyStatesRecurringExpensesIllustrationFr from './images/empty-state-recurring-expenses-fr.svg'
import EmptyStatesRecurringExpensesIllustrationIt from './images/empty-state-recurring-expenses-it.svg'
import EmptyStatesRecurringExpensesIllustrationNl from './images/empty-state-recurring-expenses-nl.svg'
import EmptyStatesRecurringExpensesIllustrationNo from './images/empty-state-recurring-expenses-no.svg'
import EmptyStatesRecurringExpensesIllustrationPt from './images/empty-state-recurring-expenses-pt.svg'
import EmptyStatesRecurringExpensesIllustrationSv from './images/empty-state-recurring-expenses-sv.svg'
import EmptyStatesRecurringVendorsIllustrationEn from './images/empty-state-recurring-vendors-en.svg'
import EmptyStateSpendGuidelinesIllustrationEn from './images/empty-state-spend-guidelines-en.svg'
import EmptyStateTeamsIllustrationDa from './images/empty-state-teams-da.svg'
import EmptyStateTeamsIllustrationDe from './images/empty-state-teams-de.svg'
import EmptyStateTeamsIllustrationEn from './images/empty-state-teams-en.svg'
import EmptyStateTeamsIllustrationEs from './images/empty-state-teams-es.svg'
import EmptyStateTeamsIllustrationFi from './images/empty-state-teams-fi.svg'
import EmptyStateTeamsIllustrationFr from './images/empty-state-teams-fr.svg'
import EmptyStateTeamsIllustrationIt from './images/empty-state-teams-it.svg'
import EmptyStateTeamsIllustrationNl from './images/empty-state-teams-nl.svg'
import EmptyStateTeamsIllustrationNo from './images/empty-state-teams-no.svg'
import EmptyStateTeamsIllustrationPt from './images/empty-state-teams-pt.svg'
import EmptyStateTeamsIllustrationSv from './images/empty-state-teams-sv.svg'
import EmptyStateVendorCardsIllustrationDa from './images/empty-state-vendor-cards-da.svg'
import EmptyStateVendorCardsIllustrationDe from './images/empty-state-vendor-cards-de.svg'
import EmptyStateVendorCardsIllustrationEn from './images/empty-state-vendor-cards-en.svg'
import EmptyStateVendorCardsIllustrationEs from './images/empty-state-vendor-cards-es.svg'
import EmptyStateVendorCardsIllustrationFi from './images/empty-state-vendor-cards-fi.svg'
import EmptyStateVendorCardsIllustrationFr from './images/empty-state-vendor-cards-fr.svg'
import EmptyStateVendorCardsIllustrationIt from './images/empty-state-vendor-cards-it.svg'
import EmptyStateVendorCardsIllustrationNl from './images/empty-state-vendor-cards-nl.svg'
import EmptyStateVendorCardsIllustrationNo from './images/empty-state-vendor-cards-no.svg'
import EmptyStateVendorCardsIllustrationPt from './images/empty-state-vendor-cards-pt.svg'
import EmptyStateVendorCardsIllustrationSv from './images/empty-state-vendor-cards-sv.svg'
import TagReviewOnboardingRight from './images/tag-review-onboarding-right-2x.png'

import type {MediaWrapperProps} from '../landing-pages/components/landing-page-media'

export const useLocalisedIllustration = ({type}: {type: PageOrigin}) => {
    const user = useUser()
    // Get only the generic language code without locales
    const userLanguage = (user?.language?.slice(0, 2) as SupportedLanguage) || SupportedLanguage.EN

    // Make sure there is always a type set, this should not happen
    const IllustrationType = IllustrationsConfig[type] ?? IllustrationsConfig['recurring-vendors']

    // Default to English if language not found
    const illustrationSrc = IllustrationType[userLanguage] ?? IllustrationType.en

    const illustrationAlt = IllustrationAltConfig[type]?.() ?? ''

    const illustrationStyle = IllustrationStyleOverride[type] ?? DefaultIllustrationStyle

    return {
        illustrationSrc,
        userLanguage,
        illustrationAlt,
        illustrationStyle,
    }
}

const DefaultIllustrationStyle: MediaWrapperProps = {
    padding: `${tokens.spacing40} 0 0 ${tokens.spacing32}`,
    imageBorderRadius: `${tokens.arc8} 0 0 0`,
    imageBackgroundColor: 'transparent',
}

const IllustrationStyleOverride: Partial<Record<PageOrigin, MediaWrapperProps>> = {
    guidelines: {
        ...DefaultIllustrationStyle,
        flex: 2,
        padding: `${tokens.spacing60} ${tokens.spacing28} 0 ${tokens.spacing28}`,
    },
    'vendor-cards': {
        ...DefaultIllustrationStyle,
        imageBackgroundColor: tokens.colorBackgroundStaticPage,
    },
    budgets: {
        ...DefaultIllustrationStyle,
        imageBackgroundColor: tokens.colorBackgroundStaticPage,
    },
    'tag-review': {
        ...DefaultIllustrationStyle,
        flex: 2,
        alignItems: 'flex-end',
        imageBackgroundColor: tokens.colorBackgroundStaticPage,
        padding: '0',
    },
}

const IllustrationAltConfig: Partial<Record<PageOrigin, () => string>> = {
    guidelines: () => t`Example of how guidelines are presented on mobile`,
    'vendor-cards': () =>
        t`Example overview showing how much has been spent this month on various vendor cards: Linear, Iterable, TikTok Ads, Linkedin Marketing Manager, and more.`,
    'recurring-vendors': () =>
        t`Example overview showing how much has been spent this month on AWS, Google Ads, HubSpot, Zoom, and Intercom. The Google Ads subscription is selected, revealing details of recent transactions.`,
    budgets: () =>
        t`An overview showing two example budgets. The first shows that 10% of the marketing budget has been spent. The second shows that 80% of a client's budget has been spent.`,
    pocket: () =>
        t`An employee receives a 90 euro reimbursement. The money is paid back into to the employee's saved bank account.`,
}

const IllustrationsConfig: Record<string, Record<string, string>> = {
    guidelines: {
        [SupportedLanguage.EN]: EmptyStateSpendGuidelinesIllustrationEn,
    },
    budgets: {
        [SupportedLanguage.EN]: EmptyStatesBudgetsIllustrationEn,
        [SupportedLanguage.DE]: EmptyStatesBudgetsIllustrationDe,
        [SupportedLanguage.FR]: EmptyStatesBudgetsIllustrationFr,
        [SupportedLanguage.NL]: EmptyStatesBudgetsIllustrationNl,
        [SupportedLanguage.ES]: EmptyStatesBudgetsIllustrationEs,
        [SupportedLanguage.DA]: EmptyStatesBudgetsIllustrationDa,
        [SupportedLanguage.SV]: EmptyStatesBudgetsIllustrationSv,
        [SupportedLanguage.FI]: EmptyStatesBudgetsIllustrationFi,
        [SupportedLanguage.IT]: EmptyStatesBudgetsIllustrationIt,
        [SupportedLanguage.PT]: EmptyStatesBudgetsIllustrationPt,
        [SupportedLanguage.NO]: EmptyStatesBudgetsIllustrationNo,
    },
    analytics: {
        [SupportedLanguage.EN]: EmptyStatesAnalyticsIllustrationEn,
        [SupportedLanguage.DE]: EmptyStatesAnalyticsIllustrationDe,
        [SupportedLanguage.FR]: EmptyStatesAnalyticsIllustrationFr,
        [SupportedLanguage.NL]: EmptyStatesAnalyticsIllustrationNl,
        [SupportedLanguage.ES]: EmptyStatesAnalyticsIllustrationEs,
        [SupportedLanguage.DA]: EmptyStatesAnalyticsIllustrationDa,
        [SupportedLanguage.SV]: EmptyStatesAnalyticsIllustrationSv,
        [SupportedLanguage.FI]: EmptyStatesAnalyticsIllustrationFi,
        [SupportedLanguage.IT]: EmptyStatesAnalyticsIllustrationIt,
        [SupportedLanguage.PT]: EmptyStatesAnalyticsIllustrationPt,
        [SupportedLanguage.NO]: EmptyStatesAnalyticsIllustrationNo,
    },
    ['recurring-expenses']: {
        [SupportedLanguage.EN]: EmptyStatesRecurringExpensesIllustrationEn,
        [SupportedLanguage.DE]: EmptyStatesRecurringExpensesIllustrationDe,
        [SupportedLanguage.FR]: EmptyStatesRecurringExpensesIllustrationFr,
        [SupportedLanguage.NL]: EmptyStatesRecurringExpensesIllustrationNl,
        [SupportedLanguage.ES]: EmptyStatesRecurringExpensesIllustrationEs,
        [SupportedLanguage.DA]: EmptyStatesRecurringExpensesIllustrationDa,
        [SupportedLanguage.SV]: EmptyStatesRecurringExpensesIllustrationSv,
        [SupportedLanguage.FI]: EmptyStatesRecurringExpensesIllustrationFi,
        [SupportedLanguage.IT]: EmptyStatesRecurringExpensesIllustrationIt,
        [SupportedLanguage.PT]: EmptyStatesRecurringExpensesIllustrationPt,
        [SupportedLanguage.NO]: EmptyStatesRecurringExpensesIllustrationNo,
    },
    ['recurring-vendors']: {
        [SupportedLanguage.EN]: EmptyStatesRecurringVendorsIllustrationEn,
        [SupportedLanguage.DE]: EmptyStatesRecurringVendorsIllustrationEn,
        [SupportedLanguage.FR]: EmptyStatesRecurringVendorsIllustrationEn,
        [SupportedLanguage.NL]: EmptyStatesRecurringVendorsIllustrationEn,
        [SupportedLanguage.ES]: EmptyStatesRecurringVendorsIllustrationEn,
        [SupportedLanguage.DA]: EmptyStatesRecurringVendorsIllustrationEn,
        [SupportedLanguage.SV]: EmptyStatesRecurringVendorsIllustrationEn,
        [SupportedLanguage.FI]: EmptyStatesRecurringVendorsIllustrationEn,
        [SupportedLanguage.IT]: EmptyStatesRecurringVendorsIllustrationEn,
        [SupportedLanguage.PT]: EmptyStatesRecurringVendorsIllustrationEn,
        [SupportedLanguage.NO]: EmptyStatesRecurringVendorsIllustrationEn,
    },
    teams: {
        [SupportedLanguage.EN]: EmptyStateTeamsIllustrationEn,
        [SupportedLanguage.DE]: EmptyStateTeamsIllustrationDe,
        [SupportedLanguage.FR]: EmptyStateTeamsIllustrationFr,
        [SupportedLanguage.NL]: EmptyStateTeamsIllustrationNl,
        [SupportedLanguage.ES]: EmptyStateTeamsIllustrationEs,
        [SupportedLanguage.DA]: EmptyStateTeamsIllustrationDa,
        [SupportedLanguage.SV]: EmptyStateTeamsIllustrationSv,
        [SupportedLanguage.FI]: EmptyStateTeamsIllustrationFi,
        [SupportedLanguage.IT]: EmptyStateTeamsIllustrationIt,
        [SupportedLanguage.PT]: EmptyStateTeamsIllustrationPt,
        [SupportedLanguage.NO]: EmptyStateTeamsIllustrationNo,
    },
    ['vendor-cards']: {
        [SupportedLanguage.EN]: EmptyStateVendorCardsIllustrationEn,
        [SupportedLanguage.DE]: EmptyStateVendorCardsIllustrationDe,
        [SupportedLanguage.FR]: EmptyStateVendorCardsIllustrationFr,
        [SupportedLanguage.NL]: EmptyStateVendorCardsIllustrationNl,
        [SupportedLanguage.ES]: EmptyStateVendorCardsIllustrationEs,
        [SupportedLanguage.DA]: EmptyStateVendorCardsIllustrationDa,
        [SupportedLanguage.SV]: EmptyStateVendorCardsIllustrationSv,
        [SupportedLanguage.FI]: EmptyStateVendorCardsIllustrationFi,
        [SupportedLanguage.IT]: EmptyStateVendorCardsIllustrationIt,
        [SupportedLanguage.PT]: EmptyStateVendorCardsIllustrationPt,
        [SupportedLanguage.NO]: EmptyStateVendorCardsIllustrationNo,
    },
    ['add-people-integrations-promo']: {
        [SupportedLanguage.EN]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.DE]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.FR]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.NL]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.ES]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.DA]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.SV]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.FI]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.IT]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.PT]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.NO]: EmptyStatePeopleIntegrationsIllustrationEn,
    },
    ['company-integrations-people-management-promo']: {
        [SupportedLanguage.EN]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.DE]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.FR]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.NL]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.ES]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.DA]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.SV]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.FI]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.IT]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.PT]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.NO]: EmptyStatePeopleIntegrationsIllustrationEn,
    },
    ['people-management-app-details']: {
        [SupportedLanguage.EN]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.DE]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.FR]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.NL]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.ES]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.DA]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.SV]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.FI]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.IT]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.PT]: EmptyStatePeopleIntegrationsIllustrationEn,
        [SupportedLanguage.NO]: EmptyStatePeopleIntegrationsIllustrationEn,
    },
    pocket: {
        [SupportedLanguage.EN]: PocketVendorCardsIllustrationEn,
        [SupportedLanguage.DE]: PocketVendorCardsIllustrationDe,
        [SupportedLanguage.FR]: PocketVendorCardsIllustrationFr,
        [SupportedLanguage.NL]: PocketVendorCardsIllustrationNl,
        [SupportedLanguage.ES]: PocketVendorCardsIllustrationEs,
        [SupportedLanguage.DA]: PocketVendorCardsIllustrationDa,
        [SupportedLanguage.SV]: PocketVendorCardsIllustrationSv,
        [SupportedLanguage.FI]: PocketVendorCardsIllustrationFi,
        [SupportedLanguage.IT]: PocketVendorCardsIllustrationIt,
        [SupportedLanguage.PT]: PocketVendorCardsIllustrationPt,
        [SupportedLanguage.NO]: PocketVendorCardsIllustrationNo,
    },
    reimbursements: {
        [SupportedLanguage.EN]: PocketVendorCardsIllustrationEn,
        [SupportedLanguage.DE]: PocketVendorCardsIllustrationDe,
        [SupportedLanguage.FR]: PocketVendorCardsIllustrationFr,
        [SupportedLanguage.NL]: PocketVendorCardsIllustrationNl,
        [SupportedLanguage.ES]: PocketVendorCardsIllustrationEs,
        [SupportedLanguage.DA]: PocketVendorCardsIllustrationDa,
        [SupportedLanguage.SV]: PocketVendorCardsIllustrationSv,
        [SupportedLanguage.FI]: PocketVendorCardsIllustrationFi,
        [SupportedLanguage.IT]: PocketVendorCardsIllustrationIt,
        [SupportedLanguage.PT]: PocketVendorCardsIllustrationPt,
        [SupportedLanguage.NO]: PocketVendorCardsIllustrationNo,
    },
    'tag-review': {
        [SupportedLanguage.EN]: TagReviewOnboardingRight,
    },
    'activate-invoices': {
        [SupportedLanguage.DA]: EmptyStateActivateInvoicesIllustrationDa,
        [SupportedLanguage.DE]: EmptyStateActivateInvoicesIllustrationDe,
        [SupportedLanguage.EN]: EmptyStateActivateInvoicesIllustrationEn,
        [SupportedLanguage.ES]: EmptyStateActivateInvoicesIllustrationEs,
        [SupportedLanguage.FI]: EmptyStateActivateInvoicesIllustrationFi,
        [SupportedLanguage.FR]: EmptyStateActivateInvoicesIllustrationFr,
        [SupportedLanguage.NL]: EmptyStateActivateInvoicesIllustrationNl,
        [SupportedLanguage.SV]: EmptyStateActivateInvoicesIllustrationSv,
    },
}
