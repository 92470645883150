/**
 * @overview Select dropdown
 * @stage Proposed
 * @author Maciek (@pekala), Nejc (@nejcjelovcan)
 * @designer Annika
 * @spec https://www.figma.com/file/w3PQGgCdm7wr9pOT3EdQa2/Bills-web-components?node-id=1029%3A561
 * @todo
 *  - Handle longer list (ideally via server pagination or virtual scrolling)
 *  - Support for multi-select
 *  - Search query highlighting in filtered options
 *  - Trigger button works on Enter key, maybe we want trigger on Down press as well (native select behavior)
 *  - Text ellipsis of Options (inside the Popover) does work consistently in Safari
 *  - Autofocus does not work in FF/Safari when Select trigger is clicked (works with keyboard)
 */
import {useRect} from '@reach/rect'
import matchSorter from 'match-sorter'
import React from 'react'
import FocusLock from 'react-focus-lock'
import styled, {css, keyframes} from 'styled-components'
import type {SpaceProps} from 'styled-system'

import {focusRing, NakedButton, tokens, Tooltip} from '@pleo-io/telescope'
import {Check, ChevronDown, ChevronUp, Close, Search, Warning} from '@pleo-io/telescope-icons'

import {Popover, usePopover} from '../overlays/popover/popover'
import {SpinnerIcon} from '../spinner/spinner'
import type {SaveState} from '../spinner/types'

////////////////////////////////////////////////////////////////////////////////
type OptionDetails = {
    [key: string]: any
}

//#region Main Component
export type SelectOption = {
    value: string | null
    label?: string
    icon?: JSX.Element
    isDisabled?: boolean
    details?: OptionDetails
}

export type SelectOptionGroup = {
    label: string
    icon?: JSX.Element
    options: SelectOption[]
}

export type SelectOptions = Array<SelectOptionGroup | SelectOption>

export interface SelectProps {
    /**
     * HTML id passed to outer most element
     */
    id?: string

    /**
     * HTML class name passed to outer most element
     */
    className?: string

    /**
     * An array of options and/or option groups represented as objects
     */
    options?: SelectOptions

    /**
     * Currently selected value
     *
     * We differentiate between three possible values:
     * - string, which should be one of provided SelectOptions' values
     * - null, which means that the NULL option is selected (only enabled when nullValueOptionLabel is provided)
     */
    value: string | null

    /**
     * Label shown when no value is selected
     */
    placeholder?: string

    /**
     * Callback called with the next selected value
     * No value will be returned as null
     * @default noop
     */
    onChange?: (
        newValue: string | null,
        selectBag: {setTouched: (isTouched: boolean) => void; selectedOption: SelectOption | null},
    ) => void

    /**
     * Determines the state icon displayed
     * @default idle
     */
    saveState?: SaveState

    /**
     * Should it render the input as disabled
     * @default false
     */
    disabled?: boolean

    /**
     * Should it render the component as holding an invalid value
     * @default false
     */
    isInvalid?: boolean

    /**
     * Disable the select button & options native tooltip
     * @default false
     */
    noTooltip?: boolean

    /**
     * Label shown for the first option, which allows to clear the selection
     * If omitted, that option is not rendered (i.e. non-nullable select)
     */
    noValueOptionLabel?: string

    /**
     * Sticky option is always visible and ordered at the top
     *
     * Comes after noValueOption, if provided.
     * This is useful when consumer is fetching options based on searchInput.
     * Provide stickyOption for the current select value to always have the label.
     */
    stickyOption?: SelectOption | null

    /**
     * Enable filtering options with a search input field
     *
     * This will show the searchQuery input field
     * (but internal options filtering can be turned off with disableFiltering)
     * @default false
     */
    searchable?: boolean

    /**
     * Disable Select's internal options filtering
     *
     * This is useful if you want custom options filtering
     * (like doing requests for options with searchQuery)
     * @default false
     */
    disableFiltering?: boolean

    /**
     * Input placeholder for the search input field
     */
    searchInputPlaceholder?: string

    /**
     * Label for the search input field
     */
    searchInputLabel?: string

    /**
     * Clear label for the search input field
     */
    searchInputClearLabel?: string

    /**
     * Display save state icon for search input
     *
     * This will replace the search icon / clear button
     * Use 'saving' to display a spinner or 'error' to display error icon
     */
    searchInputSaveState?: Extract<SaveState, 'idle' | 'saving' | 'error'>

    /**
     * Input value for the search input field
     *
     * Providing a non-undefined value will make the search input controlled!
     * (so, combine this with onSearchInputChanged to control the value)
     */
    searchInputValue?: string

    /**
     * Callback for search input changes
     *
     * You might want to debounce if you're doing requests
     */
    onSearchInputChanged?: (searchQuery: string) => void

    /**
     * Override the width of the popover
     * By default it will span the width of the trigger
     * (minimum width being 250px)
     */
    popoverWidthOverride?: string

    /**
     * Override max-height of the popover
     * (default max-height being 175px)
     */
    popoverMaxHeightOverride?: string

    /**
     * Render action area function
     * If provided, this will get called with SelectContextProps and whatever is returned will be sticky-rendered
     * at the bottom of the Popover.
     * Useful for New Button or Cancel/Apply patterns (see stories)
     */
    renderActionArea?: (context: SelectContextProps) => React.ReactNode

    /**
     * Show a tooltip on trigger button
     * Displays a tooltip with full label string
     * Useful when select trigger button is narrow
     */
    showTooltip?: boolean

    /**
     * Option to close the popover on change
     * By default when you select an option, it will close the options popover
     * Useful when you need to perform some action on change without closing the popover
     */
    closeOnChange?: boolean

    /**
     * Popover vertical spacing
     * Set a custom spacing between trigger and popover
     */
    popoverVerticalSpacing?: SpaceProps['marginTop']

    /**
     * Allows to render custom option labels in the dropdown. Replaces the default option label,
     * which is the icon and label text for each `SelectOption`.
     */
    renderCustomOptionLabel?: (option: SelectOption) => JSX.Element

    /**
     * String used as a data attribute to identify a DOM node for testing purposes
     */
    testId?: string
}

const randomString = (length?: number) => Math.random().toString(16).substr(2, length)

/**
 * Select component. Works like similar to the native <select> element,
 * but is styled and supports icons and groups
 *
 * Option filtering can be enabled with 'searchable' prop
 * We also offer displaying a 'Create new' button at the bottom of the dropdown via 'newButtonLabel' prop
 *
 * @param props Component props
 * @param props.options An array of options and/or option groups represented as objects
 * @param props.value Currently selected value
 * @param props.onChange Callback called with the next selected value
 * @param props.saveState Determines the state icon displayed
 * @param props.disabled Should it render the input as disabled
 * @param props.isInvalid Should it render the component as holding an invalid value
 * @param props.placeholder Label shown when no value is selected
 * @param props.noTooltip Disable the select button & options native tooltip
 * @param props.noValueOptionLabel Label shown for the first option, which allows to clear the selection
 * @param props.id HTML id passed to outer most element
 * @param props.className HTML class name to outer most element
 * @param props.stickyOption Special option at the top of the list, never filtered
 * @param props.searchable Enable filtering options with a search input field
 * @param props.disableFiltering Do not filter options internally
 * @param props.searchInputPlaceholder Input placeholder for the search input field
 * @param props.searchInputLabel Label for the search input field (used for a11y)
 * @param props.searchInputClearLabel Label for the search input clear button (used for a11y)
 * @param props.searchInputSaveState Show icon on search input
 * @param props.searchInputValue Set value of search input (makes it controlled)
 * @param props.onSearchInputChanged Callback when search input changes
 * @param props.popoverWidthOverride Override the width of the popover
 * @param props.popoverMaxHeightOverride Override the max height of the popover
 * @param props.renderActionArea Render action area callback
 * @param props.showTooltip Show tooltip on hover
 * @param props.closeOnChange Option to close the popover on change
 * @param props.popoverVerticalSpacing Vertical spacing between trigger and popover
 * @param props.renderCustomOptionLabel Allows to render custom option labels in the dropdown
 * @param props.testId Data attribute used to identify a DOM node for testing purposes
 */

/**
 * @deprecated Use '@pleo-io/telescope' Select
 */
export const Select: React.FC<React.PropsWithChildren<SelectProps>> = ({
    id: initialId,
    value,
    placeholder,
    options,
    className,
    onChange = () => {},
    saveState = 'idle',
    disabled = false,
    isInvalid = false,
    closeOnChange = true,
    noTooltip,
    noValueOptionLabel,
    stickyOption,
    searchable = false,
    disableFiltering = false,
    searchInputPlaceholder,
    searchInputLabel,
    searchInputClearLabel,
    searchInputSaveState,
    searchInputValue,
    onSearchInputChanged,
    popoverWidthOverride,
    popoverMaxHeightOverride,
    renderActionArea,
    showTooltip,
    popoverVerticalSpacing,
    children,
    renderCustomOptionLabel,
    testId,
}) => {
    const [id] = React.useState(initialId ?? randomString(15))
    const [searchQuery, setSearchQuery] = React.useState(searchInputValue ?? '')
    const [touched, setTouched] = React.useState(false)
    const [activeValue, setActiveValue] = React.useState<string | null>(value)
    const [listElement, setListElement] = React.useState<HTMLUListElement | null>(null)
    const [isLabelClipped, setIsLabelClipped] = React.useState(false)
    const listRefCallback = React.useCallback((node: HTMLUListElement) => {
        if (node !== null) {
            setListElement(node)
        }
    }, [])
    const [labelSpanElement, setLabelSpanElement] = React.useState<HTMLSpanElement | null>(null)
    const labelSpanRefCallback = React.useCallback((node: HTMLSpanElement) => {
        if (node !== null) {
            setLabelSpanElement(node)
        }
    }, [])
    const popover = usePopover()

    const nonFilterableOptions: SelectOptions = noValueOptionLabel
        ? [{value: null, label: noValueOptionLabel}]
        : []
    if (stickyOption) {
        nonFilterableOptions.push(stickyOption)
    }

    const filteredOptions = React.useMemo<SelectOptions>(
        () => (disableFiltering ? options ?? [] : filterSelectOptions(searchQuery, options)),
        [searchQuery, options, disableFiltering],
    )

    const allOptions = concatOptions(nonFilterableOptions, filteredOptions)
    const selectedOption = getSelectedOption(value, options)

    const flatOptions = allOptions.reduce<SelectOption[]>(
        (list, option) => (isGroup(option) ? list.concat(option.options) : list.concat([option])),
        [],
    )

    const handleChange = (newValue: string | null) => {
        onChange(newValue, {setTouched, selectedOption: getSelectedOption(newValue, options)})
        setTouched(true)

        if (closeOnChange) {
            popover.close()
        }
    }

    const scrollToActiveValue = () => {
        if (popover.isOpen) {
            // needs setTimeout otherwise it scrolls to soon which causes the body to scroll
            setTimeout(() => {
                listElement
                    ?.querySelector<HTMLElement>('[aria-selected="true"]')
                    ?.scrollIntoView?.({block: 'nearest'})
            }, 0)
        }
    }

    // reset search query when closed
    React.useEffect(() => {
        setSearchQuery(searchInputValue ?? '')
    }, [popover.isOpen])

    // call onSearchInputChanged when query changes
    React.useEffect(() => {
        onSearchInputChanged?.(searchQuery)
    }, [searchQuery])

    // scroll currently selected option into view when popover opens
    React.useEffect(() => {
        scrollToActiveValue()
    }, [popover.isOpen, listElement])

    // when value changes (or popover opens/closes), set the activeValue to current value
    // we really need that popover.isOpen so that the active item is reset upon reopening
    // (otherwise it can stay on hovered/keyboard-activated item from before)
    React.useEffect(() => {
        setActiveValue(value)
    }, [value, popover.isOpen])

    React.useEffect(() => {
        if (labelSpanElement) {
            setIsLabelClipped(labelSpanElement.offsetWidth < labelSpanElement.scrollWidth)
        }
    }, [labelSpanElement, value])

    const handleListKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        switch (e.key) {
            case 'Escape':
                e.stopPropagation()
                popover.close()
                return
            // pressing enter when focused on the focusable item in the list selects that item
            case 'Enter':
                // if we don't prevent default, enter will retrigger opening the Select Popover
                e.preventDefault()
                handleChange(activeValue ?? null)
                return
            // using up/down arrow keys when focused on the focusable item in the list
            // cycles through all visible items
            case 'ArrowDown':
            case 'ArrowUp': {
                e.preventDefault()
                const currentIndex = flatOptions.findIndex(({value: val}) => val === activeValue)
                const offset = e.key === 'ArrowDown' ? 1 : -1
                const nextActiveValue =
                    flatOptions[(currentIndex + offset + flatOptions.length) % flatOptions.length]
                if (nextActiveValue !== undefined) {
                    setActiveValue(nextActiveValue?.value)
                    setTimeout(scrollToActiveValue, 0)
                }
                return
            }
            default:
                return
        }
    }

    const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case 'Escape':
                e.stopPropagation()
                popover.close()
                return
            // pressing arrow down when focused on the input field
            // takes you to the first item in the results list
            case 'ArrowDown':
                e.preventDefault()
                if (flatOptions.length) {
                    setActiveValue(flatOptions[0].value)
                    setTimeout(scrollToActiveValue, 0)
                }
                listElement?.focus()
                return
            default:
                return
        }
    }

    const {label, labelString} = renderOptionLabel(
        selectedOption && selectedOption.value !== null
            ? selectedOption
            : {
                  value: value ?? null,
                  label:
                      value === null && touched && noValueOptionLabel
                          ? noValueOptionLabel
                          : placeholder,
              },
        renderCustomOptionLabel,
        labelSpanRefCallback,
    )

    let trigger = children
    if (React.isValidElement(trigger)) {
        trigger = React.cloneElement(trigger, {
            // @ts-ignore React typings are wrong here and do not recognize ref as a valid prop
            ref: popover.popoverProps.triggerRef,
            ...popover.triggerProps,
        })
    } else {
        trigger = <DefaultSelectButton data-value={value} {...popover.triggerProps} />
    }

    const triggerRect = useRect(popover.popoverProps.triggerRef)

    const contextProps: SelectContextProps = {
        id,
        label,
        labelString,
        searchQuery,
        isOpen: popover.isOpen,
        disabled,
        isInvalid,
        selectedOption,
        activeValue,
        touched,
        filteredOptionsLength: filteredOptions.length,
        noTooltip,
        noValueOptionLabel,
        saveState,
        handleChange,
        close: popover.close,
        renderCustomOptionLabel,
        testId,
    }

    const actionArea = renderActionArea && renderActionArea(contextProps)
    const isListEmpty = allOptions.length === 0

    const renderOption = (option: SelectOption) => (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <SelectOptionElement
            key={option.value ?? `${id}-empty`}
            option={option}
            onMouseOver={() => setActiveValue(option.value)}
        />
    )

    return (
        <SelectWrapper id={id} className={className} data-testid={testId} data-generic-ui="select">
            <SelectContext.Provider value={contextProps}>
                {showTooltip && value !== null && value !== undefined && isLabelClipped ? (
                    <Tooltip content={labelString}>
                        <div>{trigger}</div>
                    </Tooltip>
                ) : (
                    trigger
                )}

                <SelectPopover
                    {...popover.popoverProps}
                    $width={triggerRect?.width}
                    $widthOverride={popoverWidthOverride}
                    showTriangle={false}
                    marginTop={popoverVerticalSpacing}
                    marginBottom={popoverVerticalSpacing}
                    renderContent={() => (
                        <FocusLock autoFocus returnFocus>
                            {searchable && (
                                <SelectSearch
                                    placeholder={searchInputPlaceholder ?? ''}
                                    activeDescendant={
                                        activeValue !== undefined
                                            ? `${id}-select-${activeValue}`
                                            : undefined
                                    }
                                    onChange={(newQuery) => setSearchQuery(newQuery)}
                                    onKeyDown={handleInputKeyPress}
                                    label={searchInputLabel}
                                    clearSearchTermLabel={searchInputClearLabel}
                                    value={searchQuery}
                                    hasActionArea={!!actionArea}
                                    saveState={searchInputSaveState}
                                />
                            )}
                            <SelectList
                                tabIndex={isListEmpty ? -1 : 0}
                                role="select"
                                id={`${id}-select`}
                                ref={listRefCallback}
                                onKeyDown={handleListKeyDown}
                                $isSearchable={searchable}
                                $hasActionArea={!!actionArea}
                                $popoverMaxHeightOverride={popoverMaxHeightOverride}
                                data-testid={testId && `${testId}-select`}
                            >
                                {allOptions?.map((optionOrGroup) => {
                                    if (isGroup(optionOrGroup)) {
                                        const optionGroup = optionOrGroup
                                        return (
                                            <SelectGroupElement
                                                group={optionGroup}
                                                key={optionGroup.label}
                                            >
                                                {optionGroup.options.map(renderOption)}
                                            </SelectGroupElement>
                                        )
                                    }
                                    return renderOption(optionOrGroup)
                                })}
                            </SelectList>
                            {actionArea && (
                                <SelectActionAreaWrapper $isListEmpty={isListEmpty}>
                                    {actionArea}
                                </SelectActionAreaWrapper>
                            )}
                        </FocusLock>
                    )}
                />
            </SelectContext.Provider>
        </SelectWrapper>
    )
}

const getSelectedOption = (value: string | null, options?: SelectOptions): SelectOption | null => {
    if (value === null) {
        return value
    }
    return (
        options
            ?.map((optionOrGroup) =>
                isGroup(optionOrGroup) ? optionOrGroup.options : optionOrGroup,
            )
            .flat()
            .find((option) => option?.value === value) ?? null
    )
}

const renderOptionLabel = (
    option: SelectOption | SelectOptionGroup,
    renderCustomOptionLabel?: (option: SelectOption) => JSX.Element,
    labelSpanRef?: React.Ref<HTMLSpanElement>,
) => {
    const labelString = option.label ?? (isGroup(option) ? undefined : option.value) ?? ''
    return {
        labelString,
        label:
            renderCustomOptionLabel && !isGroup(option) ? (
                renderCustomOptionLabel(option)
            ) : (
                <>
                    {option.icon} <span ref={labelSpanRef}>{labelString}</span>
                </>
            ),
    }
}

// concat with dedupe by value
export const concatOptions = (options1: SelectOptions, options2: SelectOptions): SelectOptions => {
    const values1 = options1
        .map((option) =>
            isGroup(option) ? option.options.map(({value}) => value) : [option.value],
        )
        .reduce((a, b) => a.concat(b), [])
    const notInValues1 = (opt: SelectOption) => !values1.includes(opt.value)

    return options2.reduce<SelectOptions>((list, option) => {
        if (isGroup(option)) {
            return list.concat([{...option, options: option.options.filter(notInValues1)}])
        }
        if (notInValues1(option)) {
            return list.concat([option])
        }
        return list
    }, options1)
}

const isGroup = (
    optionOrGroup: SelectOption | SelectOptionGroup,
): optionOrGroup is SelectOptionGroup => 'options' in optionOrGroup

const areGroups = (options: SelectOptions): options is SelectOptionGroup[] =>
    !!options.length && isGroup(options[0])

const matchSorterOptions = {keys: ['label']}

export const filterSelectOptions = (searchQuery: string, options?: SelectOptions) => {
    if (!options || !options.length) {
        return []
    }
    if (searchQuery.trim() === '') {
        return options
    }
    if (areGroups(options)) {
        return options
            .map((group) => {
                const groupLabelMatches =
                    matchSorter([group], searchQuery, matchSorterOptions).length > 0
                return {
                    ...group,
                    options: groupLabelMatches
                        ? group.options
                        : matchSorter(group.options, searchQuery, matchSorterOptions),
                }
            })
            .filter((group) => !!group.options.length)
    }
    return matchSorter(options, searchQuery, matchSorterOptions) ?? []
}

//#endregion Main Component
////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////
//#region Main Component Styles

const SelectWrapper = styled.div`
    position: relative;
    width: 100%;
`

const fadeInFromBottom = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const SelectPopover = styled(Popover)<{
    $width?: number
    $widthOverride?: string
}>`
    animation: ${fadeInFromBottom} ${tokens.fastIn};
    ${(props) => (props.$widthOverride ? undefined : 'min-width: 250px;')}
    box-sizing: border-box;
    width: ${(props) =>
        props.$widthOverride ?? (props.$width === undefined ? 'auto' : `${props.$width}px`)};

    &[hidden] {
        display: none;
    }
`

const SelectList = styled.ul<{
    $isSearchable: boolean
    $hasActionArea: boolean
    $popoverMaxHeightOverride?: string
}>`
    max-height: ${(props) => props.$popoverMaxHeightOverride ?? '175px'};
    overflow-y: auto;
    border-top-left-radius: ${({$isSearchable}) => ($isSearchable ? 0 : tokens.arc8)};
    border-top-right-radius: ${({$isSearchable}) => ($isSearchable ? 0 : tokens.arc8)};
    border-bottom-left-radius: ${({$hasActionArea}) => ($hasActionArea ? 0 : tokens.arc8)};
    border-bottom-right-radius: ${({$hasActionArea}) => ($hasActionArea ? 0 : tokens.arc8)};

    &:focus {
        position: relative;
    }

    ${focusRing('regular')}
`

const SelectActionAreaWrapper = styled.div<{$isListEmpty?: boolean}>`
    font-size: 14px;
    background-color: ${tokens.shade100};
    border-top: ${({$isListEmpty}) => ($isListEmpty ? '0' : '1px')} ${tokens.shade300} solid;
    padding: ${tokens.spacing12} ${tokens.spacing12} ${tokens.spacing12} ${tokens.spacing12};
    border-bottom-left-radius: ${tokens.arc8};
    border-bottom-right-radius: ${tokens.arc8};
    word-break: break-word;
`

//#endregion Main Component Styles
////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////
//#region Select Context
interface SelectContextProps {
    id: string
    labelString: string
    label: string | JSX.Element
    searchQuery: string
    isOpen: boolean
    disabled: boolean
    isInvalid: boolean
    selectedOption: SelectOption | null | undefined
    activeValue: string | null
    touched: boolean
    filteredOptionsLength: number
    noTooltip?: boolean
    noValueOptionLabel?: string | undefined
    saveState: SaveState
    handleChange: (newValue: string | null) => void
    close: () => void
    renderCustomOptionLabel?: (option: SelectOption) => JSX.Element
    testId?: string
}

export const SelectContext = React.createContext<SelectContextProps>({
    id: 'id',
    labelString: '',
    label: '',
    searchQuery: '',
    isOpen: false,
    disabled: false,
    isInvalid: false,
    selectedOption: null,
    activeValue: null,
    touched: false,
    filteredOptionsLength: 0,
    noTooltip: false,
    noValueOptionLabel: undefined,
    saveState: 'idle',
    handleChange: () => {},
    close: () => {},
})

export const useSelectContext = () => React.useContext(SelectContext)

//#endregion Select Context
////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////
//#region Select Button

/**
 * The default trigger button component, exposed for easier customization.
 */
export const SelectButton: React.FC<
    React.PropsWithChildren<
        SelectContextProps & {
            onClick?: () => void
            innerRef: React.Ref<HTMLButtonElement>
            title?: string
        }
    >
> = (props) => {
    const {
        id,
        isInvalid,
        isOpen,
        label,
        labelString,
        disabled,
        noTooltip,
        selectedOption,
        touched,
        innerRef,
        saveState,
        testId,
        ...restProps
    } = props

    return (
        <StyledSelectButton
            type="button"
            aria-haspopup="true"
            aria-label={labelString}
            aria-labelledby={`button-${id}`}
            aria-disabled={disabled ? 'true' : 'false'}
            aria-expanded={isOpen ? 'true' : 'false'}
            id={`button-${id}`}
            tabIndex={0}
            $isInvalid={isInvalid}
            disabled={disabled}
            ref={innerRef}
            title={noTooltip ? '' : selectedOption?.label}
            data-testid={testId && `${testId}-button`}
            {...restProps}
        >
            <StyledButtonLabel
                $isPlaceholder={
                    (selectedOption === null || selectedOption?.value === null) && !touched
                }
                $isDisabled={disabled}
            >
                {label}
            </StyledButtonLabel>
            <ArrowWrapper>{iconForState[saveState] ?? iconForState.idle}</ArrowWrapper>
        </StyledSelectButton>
    )
}

const StyledSelectButton = styled.button<{$isInvalid: boolean}>`
    display: flex;
    height: 40px;
    justify-content: space-between;
    background-color: ${tokens.shade000};
    border: ${tokens.borderPrimary};
    box-sizing: border-box;
    color: ${tokens.shade700};
    font-size: 14px;
    user-select: none;
    border-radius: ${tokens.arc8};
    padding: ${tokens.spacing8} ${tokens.spacing12} ${tokens.spacing8} ${tokens.spacing12};
    width: 100%;
    transition: ${tokens.fastInOut};
    text-align: left;
    align-items: stretch;
    font-family: inherit;

    &:not([aria-disabled='true']) {
        cursor: pointer;
    }

    &:hover:not([aria-disabled='true'], :focus),
    &[aria-expanded='true'] {
        background-color: ${tokens.shade100};
    }

    &:hover:not([aria-disabled='true'], :focus) {
        border-color: ${tokens.shade800};
    }

    &[aria-disabled='true'] {
        border-color: ${tokens.shade200};
    }

    ${(props) =>
        props.$isInvalid &&
        css`
            border-color: ${tokens.red700};
        `}

    ${focusRing('regular')}
`

const StyledButtonLabel = styled.span<{
    $isPlaceholder?: boolean
    $isDisabled?: boolean
}>`
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
    color: ${(props) =>
        props.$isPlaceholder || props.$isDisabled ? tokens.shade600 : tokens.shade800};
    margin-right: ${tokens.spacing10};

    [aria-disabled='true'] & {
        color: ${tokens.shade500};
    }

    & > * {
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    & > :first-child {
        display: block;
        flex-shrink: 0;
        margin-right: ${tokens.spacing6};
    }

    & > :only-child {
        width: 100%;
    }
`

const ArrowWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${tokens.spacing20};
`

const WarningIcon = styled(Warning).attrs({
    size: 16,
    color: tokens.red700,
})``

const SuccessIcon = styled(Check).attrs({
    size: 16,
    color: tokens.green700,
})``

const DotIcon = () => (
    <svg viewBox="0 0 6 6" width="6px" height="6px">
        <circle cx="3" cy="3" r="3" fill={tokens.shade500} />
    </svg>
)

const caretStyles = css`
    display: block;
    margin-top: ${tokens.spacing2};
    font-size: 10px;
    transition: ${tokens.fastInOut};
    color: ${tokens.shade500};

    [aria-disabled='true'] & {
        color: ${tokens.shade400};
    }
`

const StyledChevronDown = styled(ChevronDown)`
    ${caretStyles}
`

const StyledChevronUp = styled(ChevronUp)`
    ${caretStyles}
`
const CaretIcon = () => {
    const {isOpen} = useSelectContext()

    return isOpen ? (
        <StyledChevronUp size={16} aria-hidden="true" />
    ) : (
        <StyledChevronDown size={16} aria-hidden="true" />
    )
}

const iconForState: Record<SaveState, React.ReactNode> = {
    idle: <CaretIcon />,
    dirty: <DotIcon />,
    saving: <SpinnerIcon />,
    error: <WarningIcon />,
    success: <SuccessIcon />,
}

export const DefaultSelectButton: React.FC<
    React.PropsWithChildren<{
        onClick?: () => void
    }>
> = React.forwardRef((props, ref: React.Ref<HTMLButtonElement>) => {
    const selectContext = useSelectContext()
    return <SelectButton innerRef={ref} {...selectContext} {...props}></SelectButton>
})
// eslint-disable-next-line string-to-lingui/missing-lingui-transformation
DefaultSelectButton.displayName = 'DefaultSelectButton'

//#endregion Select Button
////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////
//#region Select Search

const SelectSearch: React.FC<
    React.PropsWithChildren<{
        placeholder: string
        activeDescendant?: string
        onKeyDown?: React.KeyboardEventHandler<HTMLElement>
        onChange: (newQuery: string) => void
        value?: string
        label?: string
        clearSearchTermLabel?: string
        hasActionArea: boolean
        saveState?: SaveState
    }>
> = ({
    placeholder,
    activeDescendant,
    onChange,
    onKeyDown,
    value,
    label,
    clearSearchTermLabel,
    hasActionArea,
    saveState,
}) => {
    const {id, noValueOptionLabel, filteredOptionsLength} = useSelectContext()

    return (
        <SelectInputWrapper>
            <SelectTextInput
                type="search"
                aria-autocomplete="list"
                aria-controls={`${id}-select-search-input`}
                aria-label={label}
                placeholder={placeholder}
                aria-activedescendant={activeDescendant}
                maxLength={363}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onKeyDown={onKeyDown}
                $displayBottomRadius={
                    !noValueOptionLabel && filteredOptionsLength === 0 && !hasActionArea
                }
            />
            <SelectTextInputIcon>
                {saveState && saveState !== 'idle' ? (
                    <IconWrapper>{iconForState[saveState]}</IconWrapper>
                ) : value ? (
                    <IconWrapper>
                        <NakedButton
                            aria-label={clearSearchTermLabel}
                            title={clearSearchTermLabel}
                            type="button"
                            onClick={() => onChange('')}
                        >
                            <Close size={16} color={tokens.shade700} />
                        </NakedButton>
                    </IconWrapper>
                ) : (
                    <Search size={16} color={tokens.shade700} />
                )}
            </SelectTextInputIcon>
        </SelectInputWrapper>
    )
}

const SelectInputWrapper = styled.div`
    position: relative;
    border-bottom: 1px ${tokens.shade300} solid;
`

const SelectTextInput = styled.input<{$displayBottomRadius?: boolean}>`
    border: none;
    font-family: inherit;
    width: 100%;
    padding: ${tokens.spacing12};
    padding-right: ${tokens.spacing28};
    font-size: 14px;
    border-top-left-radius: ${tokens.arc8};
    border-top-right-radius: ${tokens.arc8};
    appearance: textfield;
    ${(props) =>
        props.$displayBottomRadius &&
        css`
            border-bottom-left-radius: ${tokens.arc8};
            border-bottom-right-radius: ${tokens.arc8};
        `}
    ${focusRing('inset')};

    &::-webkit-search-cancel-button {
        appearance: none !important;
    }

    &::-webkit-search-decoration {
        appearance: none;
    }

    &::placeholder {
        color: ${tokens.shade600};
    }
`

const SelectTextInputIcon = styled.div`
    position: absolute;
    right: ${tokens.spacing14};
    top: 50%;
    color: ${tokens.shade700};
    transform: translateY(-50%);
    height: 18px;
    font-size: 0;
`

const IconWrapper = styled.div`
    position: relative;
    top: 3px;
`

//#endregion Select Search
////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////
//#region Select Option

const SelectOptionElement: React.FC<
    React.PropsWithChildren<{
        option: SelectOption
        onMouseOver?: React.MouseEventHandler<HTMLDivElement>
    }>
> = ({option, onMouseOver}) => {
    const {
        id,
        selectedOption,
        touched,
        activeValue,
        handleChange,
        noTooltip,
        renderCustomOptionLabel,
        testId,
    } = useSelectContext()
    const isSelected =
        option.value === null && !touched
            ? false
            : option.value === (selectedOption?.value ?? selectedOption)
    const isActive = option.value === activeValue
    const {label, labelString} = renderOptionLabel(option, renderCustomOptionLabel)

    return (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <StyledSelectOptionElement
            id={`${id}-select-option-${option.value}`}
            role="option"
            aria-selected={isActive}
            aria-label={labelString}
            data-value={option.value}
            title={noTooltip ? '' : labelString}
            onClick={() => handleChange(option.value)}
            $isActive={isActive}
            $isSelected={isSelected}
            onMouseOver={onMouseOver}
            data-testid={testId && `${testId}-select-option`}
        >
            <SelectLabelWithIcon>{label}</SelectLabelWithIcon>
            {isSelected && <CheckIcon />}
        </StyledSelectOptionElement>
    )
}

const SelectGroupElement: React.FC<React.PropsWithChildren<{group: SelectOptionGroup}>> = ({
    group,
    children,
}) => {
    const {label} = renderOptionLabel(group)

    return (
        <StyledSelectGroupElement>
            <SelectGroupLabel>{label}</SelectGroupLabel>
            <ul>{children}</ul>
        </StyledSelectGroupElement>
    )
}

const CheckIcon = styled(Check).attrs({
    size: 16,
    color: tokens.shade900,
})`
    margin-left: ${tokens.spacing4};
`

const StyledSelectOptionElement = styled.div<{$isActive: boolean; $isSelected: boolean}>`
    user-select: none;
    cursor: pointer;
    font-size: 14px;
    padding: ${tokens.spacing10} ${tokens.spacing12};
    color: ${(props) => (props.$isSelected ? tokens.shade700 : tokens.shade600)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${focusRing('inset')};

    &[aria-selected='true'] {
        background-color: ${tokens.shade200};
    }
`

const SelectLabelWithIcon = styled.span`
    display: inline-flex;
    align-items: center;

    & > * {
        word-break: break-all;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    & > :first-child:not(:only-child) {
        display: block;
        flex-shrink: 0;
        margin-right: ${tokens.spacing6};
    }
`

const StyledSelectGroupElement = styled.li`
    margin-top: ${tokens.spacing18};

    &:first-of-type {
        margin-top: ${tokens.spacing10};
    }
`

const SelectGroupLabel = styled.span`
    display: block;
    padding: ${tokens.spacing6} ${tokens.spacing12};
    white-space: nowrap;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: ${tokens.fontWeightMedium};
    font-size: 12px;
`

//#endregion Select Option
////////////////////////////////////////////////////////////////////////////////
