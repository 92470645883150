import {Trans} from '@lingui/macro'
import styled, {css} from 'styled-components'

import {focusRing, Inline, NakedButton, Stack, Text, tokens} from '@pleo-io/telescope'
import {Plus} from '@pleo-io/telescope-icons'

import {customColorSchemeTokens} from '@product-web/shared--styles/custom-tokens'

export const SelfExpansionEntityCard = ({
    onClick,
    disabled = false,
}: {
    onClick?: () => void
    disabled?: boolean
}) => {
    return (
        <StyledButton onClick={onClick} disabled={disabled}>
            <Stack stretch space={8}>
                <Inline alignY="center" alignX="space-between">
                    <Text className="heading" variant="small-subtle" weight="medium">
                        <Trans>More companies?</Trans>
                    </Text>
                    <Plus className="icon" size={16} color={tokens.colorContentInteractiveQuiet} />
                </Inline>
                <Text className="content" align="left" variant="small-subtle">
                    <Trans>Group them in Pleo and control all finances from one place. </Trans>
                </Text>
            </Stack>
        </StyledButton>
    )
}

const StyledButton = styled(NakedButton)`
    cursor: pointer;
    min-height: 108px;
    padding: ${tokens.spacing20} ${tokens.spacing20} ${tokens.spacing16} ${tokens.spacing20};
    margin-bottom: ${tokens.spacing4};
    border: ${tokens.sizeBorderDefault} dashed ${tokens.colorBorderInteractive};
    transition: ${tokens.smooth};
    transition-property: color, border-color, background-color, fill;
    background-color: ${tokens.colorBackgroundInteractiveLoud};
    border-radius: ${tokens.arc8};
    ${focusRing('regular')};

    &:disabled {
        color: ${tokens.colorContentInteractiveDisabled};
        border-color: ${tokens.colorBorderInteractiveDisabled};
        background-color: ${tokens.colorBackgroundInteractiveDisabled};

        .heading,
        .content {
            color: ${tokens.colorContentInteractiveDisabled};
        }

        .icon {
            fill: ${tokens.colorContentInteractiveDisabled};
        }
    }

    &:hover {
        ${({disabled}) =>
            !disabled &&
            css`
                background-color: ${customColorSchemeTokens.colorBackgroundSelfExpansionEntityCardHover};

                .heading {
                    color: ${tokens.colorContentInteractiveHover};
                }

                .icon {
                    fill: ${tokens.colorContentInteractiveHover};
                }
            `};
    }
`
