import React from 'react'

import type {IntegrationData} from '@product-web/feature--integrations/types'
import type {ApplicationWithInstallationResponse} from '@product-web/shared--bff-moons/generated/endymion'
import {invariant} from '@product-web/shared--utils'

//#region InstalledApplicationContext

export type InstalledApplicationContext = {
    readonly installedApplication: ApplicationWithInstallationResponse | null
    readonly isLoading: boolean
    readonly isError: boolean
    readonly isConnecting: boolean
    readonly isDisconnecting: boolean
}

/** Provides the currently active application, with its installation data. */
export const InstalledApplicationContext = React.createContext<InstalledApplicationContext>({
    installedApplication: null,
    isLoading: false,
    isError: false,
    isConnecting: false,
    isDisconnecting: false,
})

export const useInstalledApplicationContext = () => {
    const context = React.useContext(InstalledApplicationContext)
    invariant(
        context,
        '`useInstalledApplicationContext` can only be used within `InstalledApplicationContext`',
    )
    return context
}

//#endregion

//#region InstalledIntegrationAPIContext

export type InstalledApplicationAPIContext = {
    connect(integration: IntegrationData): Promise<void>
    disconnect(): Promise<void>
}

/** Provides the API for connecting and disconnecting the integration */
export const InstalledApplicationAPIContext = React.createContext<InstalledApplicationAPIContext>(
    undefined as any,
)

/** Returns the API to connect and disconnect an integration */
export const useInstalledApplicationAPIContext = () => {
    const context = React.useContext(InstalledApplicationAPIContext)
    invariant(
        context,
        '`useInstalledApplicationAPIContext` must be used within a `InstalledApplicationAPIContext`',
    )
    return context
}

// #endregion
