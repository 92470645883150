import {t} from '@lingui/macro'
import type {FC} from 'react'

import {useIsLoggedIn} from '@product-web/shared--auth--session/context'
import {
    useIsNavigationVisible,
    useNavigationWidth,
} from '@product-web/shared--navigation/navigation-context'
import {useUser} from '@product-web/shared--user'

import {InternalNavigationProvider} from './navigation-internal-provider'
import {NavigationSidebar} from './navigation-sidebar'
import {NavigationTypeChooser} from './navigation-type-chooser'

export const NavigationWrapper: FC = () => {
    const isNavigationVisible = useIsNavigationVisible()
    const isLoggedIn = useIsLoggedIn()
    const user = useUser()
    const {width} = useNavigationWidth()

    // We hide the navigation here when it's explicitly hidden
    // Or when the user is not logged in
    if (!isNavigationVisible || !isLoggedIn || !user) {
        return null
    }

    return (
        <InternalNavigationProvider>
            <NavigationSidebar $width={width} aria-label={t`Sidebar for navigation and settings`}>
                <NavigationTypeChooser />
            </NavigationSidebar>
        </InternalNavigationProvider>
    )
}
export default NavigationWrapper
