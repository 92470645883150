/* eslint-disable string-to-lingui/missing-lingui-transformation */
import * as Sentry from '@sentry/react'
import type {AxiosError} from 'axios'
import * as React from 'react'
import {validate as uuidValidate} from 'uuid'

import {isRequestError} from '@product-web/shared--api-error'
import {PIN_CANCELLED_BY_USER_ERROR_STATUS} from '@product-web/shared--auth--pin-modal/constants'
import {useTokenData} from '@product-web/shared--auth--session/context'
import {BffErrorMessages} from '@product-web/shared--bff-client'
import config from '@product-web/shared--config'
import {redactUrlParams, SEARCH_PARAMS_TO_REDACT} from '@product-web/shared--redact-url-params'
import {useCurrentSubApp} from '@product-web/shared--routes/use-current-sub-app'

/**
 * A list of tRPC client error value prefixes to ignore.
 */
const TRPC_CLIENT_ERROR_VALUE_PREFIXES_TO_IGNORE = [
    'Failed to fetch',
    'Request failed with status code',
    'Network Error',
    'Request timed out',
    'Internal server error',
]

/**
 * Initialize Sentry.
 *
 * The configuration options should only be used for unit testing.
 */
export function initSentry({
    forceEnabled,
    transport,
}: {forceEnabled?: boolean; transport?: Sentry.BrowserOptions['transport']} = {}) {
    Sentry.init({
        // Only report errors from our own domain (except for when running tests)
        allowUrls: config.environment === 'test' ? undefined : [/pleo\.io/],
        attachStacktrace: true,
        beforeBreadcrumb(breadcrumb) {
            return sanitizeBreadcrumbs(breadcrumb)
        },
        beforeSend(event, hint) {
            return beforeSend(event, hint)
        },
        dsn: config.sentry.dsn,
        enabled: forceEnabled || config.environment === 'production',
        environment: config.environment,
        ignoreErrors: [
            // Unactionable Safari network errors (i.e. connection errors):
            'A network error occurred.',
            'Importing a module script failed.',
            /^Load failed$/, // NOTE: more specific to not filter out other error containing "Load failed"

            // Unactionable Firefox network errors (i.e. connection errors):
            'NetworkError when attempting to fetch resource.',
            'error loading dynamically imported module',

            // Unactionable Chrome network errors (i.e. connection errors):
            'Failed to fetch dynamically imported module',

            // The author of the ResizeObserver specification assures that it can be safely ignored: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120
            'ResizeObserver loop completed with undelivered notifications',
            'ResizeObserver loop limit exceeded',

            // Our own crazy modal implementation
            PIN_CANCELLED_BY_USER_ERROR_STATUS,
            BffErrorMessages.PIN_MODAL_CANCELLED,
        ],
        integrations: [],
        release: config.version,
        transport,
    })
}

/**
 * Hook to enhance Sentry with user data.
 */
export function useEnhanceSentry() {
    const subApp = useCurrentSubApp()
    const tokenData = useTokenData()
    const userId = tokenData?.user.id
    const companyId = tokenData?.user.cmp

    // Set Sentry user whenever companyId/userId changes
    // @see https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
    React.useEffect(() => {
        if (userId || companyId) {
            Sentry.setUser({id: userId, companyId})
        }
    }, [userId, companyId])

    // Set a custom tag every time the user navigates between sub apps
    // @see https://docs.sentry.io/platforms/javascript/enriching-events/tags/
    React.useEffect(() => {
        Sentry.setTag('subApp', subApp)
    }, [subApp])
    // Fix filtering Sentry events by companyId.
    // It looks like Sentry doesn't index custom properties on the user object.
    React.useEffect(() => {
        Sentry.setTag('companyId', companyId)
    }, [companyId])
}

/**
 * Sanitize breadcrumbs by:
 * - remove all the noise related to DataDog and LaunchDarkly network activity
 * - remove query strings from URLs to avoid leaking PII
 */
function sanitizeBreadcrumbs(breadcrumb: Sentry.Breadcrumb) {
    const category = breadcrumb.category

    if (breadcrumb?.data?.url) {
        const {url} = breadcrumb.data

        if (
            (category === 'fetch' || category === 'xhr') &&
            (url.includes('datadoghq.eu') || url.includes('launchdarkly.com'))
        ) {
            return null
        }

        breadcrumb.data.url = redactUrlParams(breadcrumb.data.url)
    }

    if (category === 'navigation' && breadcrumb.data) {
        breadcrumb.data = {
            ...breadcrumb.data,
            from: redactUrlParams(breadcrumb.data.from),
            to: redactUrlParams(breadcrumb.data.to),
        }
    }

    return breadcrumb
}

/**
 * Before sending an event to Sentry, enhance it by:
 * - apply enhanceNetworkExceptionEvent
 * - redact query strings from request to avoid leaking PII
 * - filters out unhandled network errors (for now)
 * - filters out tRPC client errors that we don't want to report
 */
function beforeSend(event: Sentry.Event, hint: Sentry.EventHint): Sentry.Event | null {
    const exceptions = event?.exception?.values

    const trpcClientError = exceptions?.find((exception) => exception?.type === 'TRPCClientError')
    if (trpcClientError) {
        const shouldIgnoreException = Boolean(
            TRPC_CLIENT_ERROR_VALUE_PREFIXES_TO_IGNORE.find((code) =>
                trpcClientError.value?.startsWith(code),
            ),
        )
        if (shouldIgnoreException) {
            return null
        }
    }

    const networkError = getNetworkError(hint)
    if (networkError) {
        enhanceNetworkExceptionEvent(event, networkError)
        const isUnhandled = exceptions?.some(
            (exception) => exception?.mechanism?.type === 'onunhandledrejection',
        )
        if (isUnhandled) {
            // NOTE: until we have Sentry under control, we don't want to send any unhandled network errors
            return null
        }
    }

    // Some error have a special `request` object that we want to redact
    const queryString = event.request?.query_string
    if (Array.isArray(queryString)) {
        const newQueryString: [string, string][] = queryString.map(([key, value]) => {
            return [key, SEARCH_PARAMS_TO_REDACT.has(key) ? '<redacted>' : value]
        })
        event.request!.query_string = newQueryString
    }

    return event
}

/**
 * Find if there is an error thrown by Axios, either directly caught, or linked via `cause`
 */
function getNetworkError(hint: Sentry.EventHint) {
    if (hint.originalException instanceof Error && isRequestError(hint.originalException.cause)) {
        return hint.originalException.cause
    } else if (isRequestError(hint.originalException)) {
        return hint.originalException
    }
    return null
}

/**
 * Enhance network exception events by:
 * - group network error together by endpoint/method/response code
 * - remove query strings from URLs to avoid leaking PII
 */
function enhanceNetworkExceptionEvent(event: Sentry.Event, networkError: AxiosError) {
    // If there is an Axios error, extract method, path and response code and add it to the message
    // Enhance the grouping by adding a more targeted fingerprinting
    if (networkError.config?.url) {
        const url = new URL(networkError.config.url)
        const sanitizedPath = removeUUIDs(url.pathname)
        const code = String(networkError.response?.status)
        const method = networkError.config.method?.toUpperCase() ?? 'UNKNOWN'
        event.fingerprint = ['{{ default }}', method, sanitizedPath, code]
        if (event.exception?.values) {
            event.exception.values[0].value += ` - ${method} ${sanitizedPath} [${code}]`
        }
    }
}

function removeUUIDs(path: string) {
    return path
        .split('/')
        .map((segment) => (uuidValidate(segment) ? '<id>' : segment))
        .join('/')
}
