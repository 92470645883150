import {useEffect, useState} from 'react'

import {EmailProvider} from '@pleo-io/deimos'

import {request} from '@product-web/shared--api'
import {getTokenPayload} from '@product-web/shared--auth--jwt/payload'
import {useAccessToken} from '@product-web/shared--auth--session/context'
import config from '@product-web/shared--config'
import {reportError} from '@product-web/shared--error/report'
import {useCompanyUser} from '@product-web/shared--user'

const baseUrl = config.endpoints.api

const getGoogleHandshakeUri = (stateToken: string) => {
    const clientId = config.google.id
    const redirectUri = getGoogleRedirectUri()

    return `https://accounts.google.com/o/oauth2/auth?access_type=offline&approval_prompt=force&client_id=${clientId}&state=${stateToken}&redirect_uri=${redirectUri}&response_type=code&scope=https://www.googleapis.com/auth/gmail.readonly`
}
const getGoogleRedirectUri = () => {
    const {protocol, host} = window.location
    return `${protocol}//${host}/oauth/email/google`
}

const getMicrosoftHandshakeUri = (stateToken: string) => {
    const clientId = config.azure.clientId
    const redirectUri = getMicrosoftRedirectUri()
    return `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&state=${stateToken}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=Mail.Read%20offline_access%20email%20openid%20User.Read`
}

const getMicrosoftRedirectUri = () => {
    const {protocol, host} = window.location
    return `${protocol}//${host}/oauth/email/office365`
}

/**
 * Hashes a string to SHA-256 representation.
 * Code from a very reputable resource - https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
 * @param token string to hash
 * @returns Promise that resolves to the hashed string
 */
const hashToken = async (token: string) => {
    const encoder = new TextEncoder()
    const data = encoder.encode(token ?? '')
    const hashBuffer = await crypto.subtle.digest('SHA-256', data)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
    return hashHex
}

export const useOAuthStateToken = () => {
    const [stateToken, setStateToken] = useState<string | null>(null)
    const accessToken = useAccessToken()

    useEffect(() => {
        const getToken = async () => {
            if (accessToken) {
                const tokenPayload = getTokenPayload(accessToken)
                const sessionId = tokenPayload?.data.sessionId
                const token = sessionId ?? accessToken
                setStateToken(await hashToken(token))
            }
        }

        getToken().catch(reportError)
    }, [accessToken])

    return stateToken
}

export const useOAuthUrls = () => {
    const stateToken = useOAuthStateToken()
    if (!stateToken) {
        return null
    }

    return {
        [EmailProvider.GMAIL]: getGoogleHandshakeUri(stateToken),
        [EmailProvider.OFFICE365]: getMicrosoftHandshakeUri(stateToken),
    }
}

const getReceiptFinderUrl = (employeeId: string) => `rest/v1/callisto/employees/${employeeId}`

async function scheduleSearchForEmployee(employeeId: string): Promise<{success: boolean}> {
    return request(`${baseUrl}/${getReceiptFinderUrl(employeeId)}/schedule-search`, {
        auth: 'user',
        method: 'POST',
    })
}

export function useReceiptFinder() {
    const user = useCompanyUser()
    const employeeId = user.employee?.id

    async function scheduleSearch() {
        if (!employeeId) {
            return false
        }
        const {success} = await scheduleSearchForEmployee(employeeId)
        return success
    }

    return {
        mutations: {scheduleSearch},
    }
}
