import {SupportedLanguage} from '@product-web/shared--i18n'

const SELF_INITIATED_EXPANSION_WISTIA_ID: {[key in SupportedLanguage]?: string} = {
    [SupportedLanguage.EN]: 'ay3tybefub',
    [SupportedLanguage.DA]: 'cmp6epuylj',
    [SupportedLanguage.DE]: 'vohrc7r06t',
    [SupportedLanguage.DE_AT]: 'vohrc7r06t',
    [SupportedLanguage.ES]: '4esjjd7mty',
    [SupportedLanguage.SV]: 'l54dmesvq5',
    [SupportedLanguage.FR]: 'd4900ldx5d',
    [SupportedLanguage.FR_BE]: 'd4900ldx5d',
    [SupportedLanguage.NL]: 'je6uuumi8w',
    [SupportedLanguage.NL_BE]: 'je6uuumi8w',
}
export const getVideoIdByLanguage = (language: SupportedLanguage | undefined | null) => {
    return (SELF_INITIATED_EXPANSION_WISTIA_ID[language ?? SupportedLanguage.EN] ??
        SELF_INITIATED_EXPANSION_WISTIA_ID[SupportedLanguage.EN]) as string
}
