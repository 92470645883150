import * as React from 'react'
import {Navigate, Outlet, Route} from 'react-router-dom'

import {Page} from '@product-web/feature--ui-page/page'

import {PartnershipTab} from './screens/partnership'

const Partnership = React.lazy(async () => import('./screens/partnership'))

const Wrapper = () => (
    <Page layout="wide" showNav>
        <Outlet />
    </Page>
)

export const routes = (
    <>
        <Route index element={<Navigate to={`./${PartnershipTab.CURRENT_YEAR}`} replace />} />
        <Route element={<Wrapper />}>
            <Route
                path={`${PartnershipTab.CURRENT_YEAR}`}
                element={<Partnership tab={PartnershipTab.CURRENT_YEAR} />}
            />
            <Route
                path={`${PartnershipTab.PREVIOUS_YEARS}`}
                element={<Partnership tab={PartnershipTab.PREVIOUS_YEARS} />}
            />
        </Route>
    </>
)
