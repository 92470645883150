/**
 * @overview Modal step
 * @stage Proposed
 * @author William (@wfortin)
 * @designer
 * @spec
 */

import {AnimatePresence, motion} from 'framer-motion'
import type {ReactElement, ReactNode} from 'react'
import React, {useState} from 'react'
import styled from 'styled-components'

import {breakpoints} from '@product-web/shared--styles/theme'

interface StepsHook {
    step: number
    setStep: (step: number) => void
    goToNextStep: () => void
    goToPreviousStep: () => void
}

export const useSteps = (): StepsHook => {
    const [step, setStep] = useState(0)

    const goToNextStep = () => {
        setStep(step + 1)
    }

    const goToPreviousStep = () => {
        setStep(step - 1)
    }

    return {
        step,
        setStep,
        goToNextStep,
        goToPreviousStep,
    }
}

interface StepProps {
    children: any
    index?: number
    step?: number
}

export const Step = ({index, step, children}: StepProps) => {
    return step === index ? children : null
}

interface StepsProps extends StepsHook {
    children: ReactNode
}

const variants = {
    enter: {
        opacity: 0,
    },
    center: {
        opacity: 1,
        scale: 1,
    },
    exit: {
        opacity: 0,
    },
}

export const Steps = ({children, step}: StepsProps) => {
    return (
        <StepsWrapper data-generic-ui="modal-steps">
            <AnimatePresence initial={false} exitBeforeEnter>
                <motion.div
                    key={step}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{duration: 0.2}}
                >
                    {React.Children.map(children as ReactElement, (child, index) =>
                        React.cloneElement(child, {index, step}),
                    )}
                </motion.div>
            </AnimatePresence>
        </StepsWrapper>
    )
}

const StepsWrapper = styled.div`
    overflow: hidden;

    @media (max-width: ${breakpoints.tabletUp}) {
        width: auto;
    }
`
