import {useMemo} from 'react'

import {useVendorCard} from '@product-web/feature--vendor-cards/lib/use-vendor-card'
import {dayjs} from '@product-web/shared--dates/dayjs'
import {useFlags} from '@product-web/shared--flags'

import {bffHooks} from '../bff-hooks'

const oneYearBefore = dayjs().subtract(1, 'year').format('YYYY-MM-DD')
const today = dayjs().startOf('day').format('YYYY-MM-DD')

/**
 *
 * @returns {object}:
 *  @property {boolean} isEnabled - Used to determine when the Spend Activation Templates should be visible to the Admin
 *  @property {boolean} hasVendorCards - For tracking purposes, used to determine if the user has vendor cards
 *  @property {boolean} hasRecurringSpend - For tracking purposes, used to determine if the user has recurring spend
 *
 * V1: Spend Activation Templates
 *  - return true if all of the following conditions are met:
 *      - if the feature-gate is enabled
 *      - if the user can use vendor cards
 *      - if the user has no recurring spend
 *      - if the user is on the Advanced or Beyond plan
 */

type UseSpendActivationTemplatesType = {
    isEnabled: boolean
    hasVendorCards: boolean
    hasRecurringSpend: boolean
}

export const useSpendActivationTemplates = (): UseSpendActivationTemplatesType => {
    const {popularVendorCardsLandingPage} = useFlags()

    const {canUseVendorCards} = useVendorCard()

    const {data: showSpendActivationTemplates = false} =
        bffHooks.vendorCards.spendActivationTemplates.getShouldShowSpendActivationTemplates.useQuery()

    const canCheckForVendorCardsAndRecurringSpend =
        canUseVendorCards && showSpendActivationTemplates && popularVendorCardsLandingPage

    const {data: {hasVendorCards = false, hasRecurringSpend = false} = {}} =
        bffHooks.vendorCards.spendActivationTemplates.getVendorCardsAndRecurringSpend.useQuery(
            {from: [oneYearBefore], to: [today]},
            {enabled: canCheckForVendorCardsAndRecurringSpend},
        )

    const isEnabled = showSpendActivationTemplates && popularVendorCardsLandingPage

    return useMemo(
        () => ({
            isEnabled,
            hasVendorCards,
            hasRecurringSpend,
        }),
        [hasVendorCards, hasRecurringSpend, isEnabled],
    )
}
