import {Trans} from '@lingui/macro'
import type {FC} from 'react'
import ContentLoader from 'react-content-loader'
import {Link as RouterLink, useLocation} from 'react-router-dom'
import styled, {css} from 'styled-components'

import {Box, focusRing, tokens, VisuallyHidden} from '@pleo-io/telescope'

import {breakpoints} from '@product-web/shared--styles/theme'
import {PleoLogo} from '@product-web/shared--telescope-lab/logo-pleo/logo-pleo'
import {getIsPartnerUser, useUser} from '@product-web/shared--user'

export const NavigationLogo: FC = () => {
    const user = useUser()
    const location = useLocation()
    const pathname = location.pathname
    const isPartnerEmployee = getIsPartnerUser(user)

    const isWorkingAsPartner = isPartnerEmployee && pathname.startsWith('/partner')

    return (
        <GlobalNavigationLogo to="/" data-testid="pleo-logo">
            <VisuallyHidden>
                <Trans>Navigate to home</Trans>
            </VisuallyHidden>
            <Box marginLeft={32}>
                <PleoLogo />
                {isWorkingAsPartner && (
                    <PartnerBadge>
                        <Trans>Partner</Trans>
                    </PartnerBadge>
                )}
            </Box>
        </GlobalNavigationLogo>
    )
}

export const NavigationLogoLoader: FC = () => {
    return (
        <ContentLoader width="100%" height={64 + 40 + 42}>
            <rect x="40" y="40" width="64" height="64" rx="8" />
        </ContentLoader>
    )
}

const LogoBase = css`
    position: relative;
    display: none;
    height: 2.5em;
    cursor: pointer;

    @media (max-width: ${breakpoints.tabletMedUp}) {
        display: block;
        height: 2.22em;
        margin: 0;
    }

    @media (max-width: ${breakpoints.tabletUp}) {
        height: 1.66em;
    }
`

const GlobalNavigationLogo = styled(RouterLink)`
    ${focusRing('regular')};
    ${LogoBase};
    border-radius: ${tokens.arc4};
    display: block;
    height: 77px;
    margin-top: ${tokens.spacing48};
    margin-bottom: ${tokens.spacing40};
    margin-inline: ${tokens.spacing16};

    @media (max-height: ${breakpoints.smallTabletUp}) {
        display: none;
    }

    @media (max-width: ${breakpoints.tabletMedUp}) {
        display: none;
    }
`

const PartnerBadge = styled.div`
    margin-top: ${tokens.spacing6};
    color: ${tokens.colorContentStatic};
`
