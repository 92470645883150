import {getTokenPayload} from '@product-web/shared--auth--jwt/payload'

export function findPrimaryAccountToken(primaryAccountEmail: string, accessTokens: string[]) {
    return accessTokens.find((token) => {
        const tokenData = getTokenPayload(token)
        return tokenData?.data.user.email === primaryAccountEmail
    })
}

// This key is to identify the primary account for a user with multiple logged in sessions
export const PRIMARY_ACCOUNT_EMAIL = 'primaryAccountEmail'

// Allows managing active account email for account-switch feature

export function getPrimaryAccountEmail() {
    return localStorage.getItem(PRIMARY_ACCOUNT_EMAIL)
}

export function setPrimaryAccountEmail(email: string | null | undefined) {
    if (email) {
        localStorage.setItem(PRIMARY_ACCOUNT_EMAIL, email)
    } else {
        localStorage.removeItem(PRIMARY_ACCOUNT_EMAIL)
    }
}
