import {Trans} from '@lingui/macro'
import type {FC} from 'react'
import {useEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import styled from 'styled-components'

import {
    Button,
    Modal,
    ModalActions,
    ModalClose,
    ModalContent,
    ModalIllustration,
    ModalTitle,
    Stack,
    Text,
} from '@pleo-io/telescope'

import {EmployeeStatus} from '@product-web/shared--shell/employee-status'
import {
    getEmployeeStatus,
    useHasOrganisationAccess,
    useUser,
    useUserMutations,
} from '@product-web/shared--user'

import handshakeImg from './handshake-multi-entity.svg'

const MULTI_ENTITY_PARAM_VALUE = 'newOrganizationAdmin'

interface MultiEntityInfoModalProps {
    showModal: boolean
    companyName: string | undefined
    handleModalClick: () => void
}

export const MultiEntityInfoModal: FC<MultiEntityInfoModalProps> = ({
    showModal = false,
    companyName,
    handleModalClick,
}) => {
    return (
        <Modal isOpen={showModal} onDismiss={handleModalClick}>
            <ModalClose onClick={handleModalClick} />
            <ModalIllustration>
                <StyledImage src={handshakeImg} alt="" />
            </ModalIllustration>
            <ModalTitle>
                <StyledTitle>
                    {/* eslint-disable-next-line string-to-lingui/text-restrictions */}
                    <Trans>You've been upgraded to multi-entity admin!</Trans>
                </StyledTitle>
            </ModalTitle>
            <ModalContent>
                <Stack space={24}>
                    <Text as="p">
                        <Trans>
                            From now on, you'll be able to view and manage all entities in your
                            organisation in Pleo.
                        </Trans>
                    </Text>
                    <Text as="p">
                        <Trans>
                            You're still assigned to {companyName ?? 'your'} entity, and you can
                            make expenses only on this company account.
                        </Trans>
                    </Text>
                </Stack>
            </ModalContent>
            <ModalActions>
                <Button variant="primary" onClick={handleModalClick}>
                    <Trans>Continue</Trans>
                </Button>
            </ModalActions>
        </Modal>
    )
}

export const MultiEntityInfoModalController = () => {
    const user = useUser()
    const {setEmployeeStateKey} = useUserMutations()
    const hasSeenMultiEntityInfoModal =
        getEmployeeStatus(user)[EmployeeStatus.hasSeenMultiEntityInfoModal]
    const hasOrganisationAccess = useHasOrganisationAccess()
    const [searchParams] = useSearchParams()
    const params = Object.fromEntries(searchParams.entries())
    const hasMultiEntityAdminParam = params?.onboardingType === MULTI_ENTITY_PARAM_VALUE

    const [showModal, setShowModal] = useState(false)
    useEffect(() => {
        setShowModal(
            hasOrganisationAccess &&
                hasMultiEntityAdminParam &&
                hasSeenMultiEntityInfoModal !== true,
        )
    }, [hasMultiEntityAdminParam, hasOrganisationAccess, hasSeenMultiEntityInfoModal])

    const handleModalClick = async () => {
        await setEmployeeStateKey(EmployeeStatus.hasSeenMultiEntityInfoModal, true)
        setShowModal(false)
    }

    return (
        <MultiEntityInfoModal
            showModal={showModal}
            companyName={user?.company?.name}
            handleModalClick={handleModalClick}
        />
    )
}

const StyledTitle = styled.span`
    display: inline-flex;
    max-width: 220px;
`

const StyledImage = styled.img`
    max-width: 100px;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    margin-top: 27px;
`
