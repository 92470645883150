import {useGate} from 'statsig-react'

import {useUser} from '@product-web/shared--user'

import {bff} from './bff-hooks'

export const useCompanyOnboardingStatus = () => {
    const user = useUser()
    const gate = useGate('company_onboarding_-_has_get_started_screen')
    const companyOnboardingVersion = gate.value ? 'get-started' : 'pleo-guide'

    const isOwner = user?.role === 'owner'

    const {data} = bff.featureOnboarding.companyOnboarding.getCompanyOnboardingStatus.useQuery(
        {
            companyOnboardingVersion,
        },
        {
            enabled: isOwner && !gate.isLoading,
        },
    )

    if (!isOwner) {
        return {
            awaitingCompanyOnboardingStatus: false,
            hasFinishedCompanyOnboarding: true,
            shouldHideCompanyOnboarding: true,
        }
    }

    return {
        awaitingCompanyOnboardingStatus: !data,
        hasFinishedCompanyOnboarding: data?.hasFinishedCompanyOnboarding,
        shouldHideCompanyOnboarding: data?.shouldHideCompanyOnboarding,
    }
}
