import mitt from 'mitt'
import React from 'react'

import {getOAuthCallbackParams} from '@product-web/shared--auth--oauth/operations'
import {isSameArray} from '@product-web/shared--utils'

export type SessionData = {
    accessToken?: string | null
    accessTokens?: string[] | null // Active tokens on a trusted session or device
    trustedEmails?: string[] | null // Lets us know the emails on a trusted session or device
    email?: string | null // Email associated with active session or account
    companyId?: string | null // Identifier for current active company a user is viewing. This enables users like bookkeepers to access accounts they manage
    partnerId?: string | null // Identifier for user with partner role at login
    location?: string // Identifier for location when switch account is invoked
}

function getAccessTokenFromURL() {
    const oauthParams = getOAuthCallbackParams(window.location.hash)

    if (!oauthParams) {
        return undefined
    }

    if (oauthParams.error) {
        return {accessToken: null}
    }

    return {accessToken: oauthParams.accessToken || null}
}

// Creates Event emitter that helps to track session inside & outside of react context
// main reason for such implementation is `request` method used for PUT/POST/etc. requests
// executed outside of context availability - expected to be changed after redux refactors
const getSessionStore = () => {
    type Events = {
        sessionUpdated: SessionData
    }
    let sessionData: SessionData | undefined = getAccessTokenFromURL()
    const emitter = mitt<Events>()
    const get = () => {
        return sessionData
    }

    const set = (nextSessionData: SessionData) => {
        const hasAccessTokensChanged = !isSameArray(
            sessionData?.accessTokens ?? [],
            nextSessionData?.accessTokens ?? [],
        )
        const hasTrustedEmailsChanged = !isSameArray(
            sessionData?.trustedEmails ?? [],
            nextSessionData?.trustedEmails ?? [],
        )

        if (
            hasAccessTokensChanged ||
            hasTrustedEmailsChanged ||
            sessionData?.accessToken !== nextSessionData.accessToken ||
            sessionData?.email !== nextSessionData.email
        ) {
            sessionData = nextSessionData
            emitter.emit('sessionUpdated', sessionData)
        }
    }

    return {get, set, emitter}
}

export const sessionStore = getSessionStore()

// Listener for changes in sessionStore to keep SessionProvider up-to-date with latest values
export const useSessionUpdatedEventListener = (
    onSessionUpdated: (data: SessionData | undefined) => void,
) => {
    React.useEffect(() => {
        sessionStore.emitter.on('sessionUpdated', onSessionUpdated)
        return () => sessionStore.emitter.off('sessionUpdated', onSessionUpdated)
    }, [])
}
