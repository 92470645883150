/* eslint-disable string-to-lingui/missing-lingui-transformation */
import {ErrorBoundary as SentryErrorBoundary} from '@sentry/react'
import type React from 'react'
import type {PropsWithChildren} from 'react'
import {Helmet} from 'react-helmet'
import styled from 'styled-components'

import {Button, Text, tokens} from '@pleo-io/telescope'

export const ErrorBoundary: React.FC<PropsWithChildren> = ({children}) => {
    return (
        <SentryErrorBoundary
            beforeCapture={(scope) => {
                // Errors that happen in the React render tree are fatal
                // as they prevent the user from using the app or parts of it.
                scope.setLevel('fatal')
            }}
            fallback={() => <ErrorPage />}
        >
            {children}
        </SentryErrorBoundary>
    )
}
export const ErrorPage = () => {
    return (
        <Page>
            <Helmet>
                <title>Something went wrong</title>
            </Helmet>
            <Text variant="3xlarge-accent" as="h1" space={10}>
                Something went wrong
            </Text>
            <Text as="p">
                Please{' '}
                {/* @temp-button-migrations: May look off, due to inline use, when tertiary button styling is updated */}
                <Button variant="tertiary" onClick={() => window.location.reload()}>
                    reload the page
                </Button>{' '}
                to try again or contact our support.
            </Text>
        </Page>
    )
}

const Page = styled.div`
    box-sizing: border-box;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${tokens.spacing12};
`
