import React from 'react'
import {Route} from 'react-router-dom'

import {roleSets} from '@product-web/shared--user'

const ExpensesPage = React.lazy(async () => import('./screens/expenses/expenses'))

export const routes = (
    <Route handle={{auth: true, allowedRoles: roleSets.companyAndReviewer}}>
        <Route index element={<ExpensesPage />} />
        <Route path="review/*" element={<ExpensesPage />} />
        <Route path="employee-balance" element={<ExpensesPage />} />
        <Route path="add-expense" element={<ExpensesPage />} />
        <Route path="add-per-diem" element={<ExpensesPage />} />
        <Route path="add-mileage" element={<ExpensesPage />} />
    </Route>
)
