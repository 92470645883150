import {t} from '@lingui/macro'

import {Link} from '@pleo-io/telescope'

import {SupportedLanguage, useAppLanguage} from '@product-web/shared--i18n'
import appStoreBadgeDa from '@product-web/shared--images/app-store-badge-da.png'
import appStoreBadgeDe from '@product-web/shared--images/app-store-badge-de.png'
import appStoreBadgeEn from '@product-web/shared--images/app-store-badge-en.png'
import appStoreBadgeEs from '@product-web/shared--images/app-store-badge-es.png'
import appStoreBadgeFi from '@product-web/shared--images/app-store-badge-fi.png'
import appStoreBadgeFr from '@product-web/shared--images/app-store-badge-fr.png'
import appStoreBadgeNl from '@product-web/shared--images/app-store-badge-nl.png'
import appStoreBadgeSv from '@product-web/shared--images/app-store-badge-sv.png'

const appStoreBadges = {
    [SupportedLanguage.EN]: appStoreBadgeEn,
    [SupportedLanguage.DE]: appStoreBadgeDe,
    [SupportedLanguage.DE_AT]: appStoreBadgeDe,
    [SupportedLanguage.ES]: appStoreBadgeEs,
    [SupportedLanguage.SV]: appStoreBadgeSv,
    [SupportedLanguage.DA]: appStoreBadgeDa,
    [SupportedLanguage.FR]: appStoreBadgeFr,
    [SupportedLanguage.FR_BE]: appStoreBadgeFr,
    [SupportedLanguage.FI]: appStoreBadgeSv,
    [SupportedLanguage.NL]: appStoreBadgeNl,
    [SupportedLanguage.NL_BE]: appStoreBadgeNl,
    [SupportedLanguage.FI]: appStoreBadgeFi,
    [SupportedLanguage.PT]: appStoreBadgeEn,
    [SupportedLanguage.IT]: appStoreBadgeEn,
    [SupportedLanguage.NO]: appStoreBadgeEn,
}

const getAppStoreBadge = (language: SupportedLanguage) =>
    appStoreBadges[language] || appStoreBadgeEn

interface AppStoreBadgeProps {
    onClick?: () => void
    height?: number
    width?: number
}

export const AppStoreBadge = ({onClick, height, width}: AppStoreBadgeProps) => {
    const language = useAppLanguage()

    return (
        <Link
            href="https://apps.apple.com/app/pleo/id1110743278"
            onClick={onClick}
            css={{fontSize: 0}}
        >
            <img
                alt={t`Download on the App Store`}
                src={getAppStoreBadge(language)}
                height={height ?? 'auto'}
                width={width ?? 'auto'}
            />
        </Link>
    )
}
