import type {FC} from 'react'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components'

import {Inline, List, Stack, tokens} from '@pleo-io/telescope'

import PromoCardContainer from '@product-web/feature--ui-promo-card/promo-card.container'
import useShouldPromoCardBeVisible from '@product-web/feature--ui-promo-card/use-should-promo-card-be-visible'
import {useIsAppOutdated} from '@product-web/shared--shell/is-app-outdated'
import {breakpoints} from '@product-web/shared--styles/theme'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'
import {ProgressCard} from '@product-web/sub-app--recurring-vendors/components/spend-activation-guide/progress-card'

import {NavigationLink} from './navigation-link'
import {useNavigationItems} from './use-navigation-items'

interface NavigationItemsProps {
    onClickItem: () => void
    showTopSpacing?: boolean
}

export const NavigationItems: FC<NavigationItemsProps> = ({onClickItem, showTopSpacing}) => {
    const isTablet = useMediaQuery(`(max-width: ${breakpoints.tabletMedUp})`)
    const {items: navigationItems, isLoading} = useNavigationItems()

    // If the app is outdated, we change the behaviour of the navigation links to perform a full
    // page reload instead of the client-side SPA navigation
    const isAppOutdated = useIsAppOutdated()

    const showPromoCard = useShouldPromoCardBeVisible()

    return (
        <NavWrapperStack $compact={showPromoCard || (isTablet && !showTopSpacing)}>
            <nav>
                {isLoading ? (
                    <NavigationItemsLoader />
                ) : (
                    <List>
                        {navigationItems.map((item) => (
                            <NavigationListItem key={item.to} alignItems="center">
                                <NavigationLink
                                    item={item}
                                    onClickItem={onClickItem}
                                    reloadDocument={isAppOutdated}
                                />
                            </NavigationListItem>
                        ))}
                    </List>
                )}
            </nav>

            {showPromoCard && (
                <PromoCardWrapper>
                    <PromoCardContainer />
                </PromoCardWrapper>
            )}

            <ProgressCard isTablet={isTablet} />
        </NavWrapperStack>
    )
}

const NAV_ITEMS_COUNT = 7
const NAV_ITEMS_HEIGHT = 20
const NAV_ITEMS_SPACING = 22

interface NavigationItemsLoaderProps {
    count?: number
}

export const NavigationItemsLoader: FC<NavigationItemsLoaderProps> = ({
    count = NAV_ITEMS_COUNT,
}) => {
    return (
        <StyledNavigationItemsLoader
            width="100%"
            height={(NAV_ITEMS_HEIGHT + NAV_ITEMS_SPACING) * NAV_ITEMS_COUNT}
        >
            {Array.from(new Array(count)).map((_, index) => (
                <rect
                    key={index}
                    x="0"
                    y={index * (NAV_ITEMS_HEIGHT + NAV_ITEMS_SPACING)}
                    width={`calc(100% - ${tokens.spacing40} * 2)`}
                    height={NAV_ITEMS_HEIGHT}
                    rx="8"
                />
            ))}
        </StyledNavigationItemsLoader>
    )
}

const NavWrapperStack = styled(Stack)<{$compact?: boolean}>`
    overflow: auto;
    align-content: stretch;
    overflow-x: hidden;

    > nav {
        margin-top: ${({$compact}) => ($compact ? tokens.spacing4 : tokens.spacing40)};
    }

    @media (max-width: ${breakpoints.tabletMedUp}) {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
`

const NavigationListItem = styled(Inline).attrs({as: 'li'})`
    padding-left: ${tokens.spacing32};

    &:last-child {
        margin-bottom: ${tokens.spacing10};
    }
`

const PromoCardWrapper = styled.div`
    padding: ${tokens.spacing16} ${tokens.spacing20};
    justify-self: stretch;
`

const StyledNavigationItemsLoader = styled(ContentLoader)`
    margin: ${tokens.spacing20} ${tokens.spacing40} 0 ${tokens.spacing40};
`
