import {Trans} from '@lingui/macro'
import type {FC} from 'react'
import React, {useState} from 'react'
import {useBreakpoint} from 'react-laag'
import {useNavigate} from 'react-router-dom'
import type {DataAttributes} from 'styled-components'
import styled, {css} from 'styled-components'

import type {Person} from '@pleo-io/deimos'
import {
    Box,
    Button,
    Drawer,
    focusRing,
    Inline,
    NakedButton,
    Stack,
    Text,
    tokens,
} from '@pleo-io/telescope'

import {useAccountSwitchModal} from '@product-web/feature--multi-entity/add-account/add-account'
import {AvatarEmployee} from '@product-web/feature--ui-avatar-employee/avatar-employee'
import type {Company} from '@product-web/shared--api-types/company'
import type {LoggedInAccount, SwitchAccountPayload} from '@product-web/shared--auth--accounts'
import {useNavigationWidth} from '@product-web/shared--navigation/navigation-context'
import {breakpoints} from '@product-web/shared--styles/theme'
import {useUser} from '@product-web/shared--user'

import {NavigationAccounts, NavigationItemDesktop} from './navigation-accounts'
import {Dropdown} from './navigation-dropdown'
import {MOBILE_NAVIGATION_DRAWER_ID, NavigationAvatarMobile} from './navigation-mobile'

import {useHasAccounts} from '../helpers'

const breakpoint = breakpoints.tabletMedUp
const mobileBreakPoint = breakpoints.mobileLrgUp

interface NavigationAvatarProps {
    accounts?: LoggedInAccount[]
    switchAccount: (payload: SwitchAccountPayload) => void
    employee?: Partial<Person>
    firstName: string
    onLogout: () => void
    company?: Company
    isOwnerOrBookkeeper: boolean
    bookkeeperAccess: null | 'extended' | 'basic'
}

const LabelWrapperActive = css`
    background-color: ${tokens.colorBackgroundInteractive};
`

interface LabelWrapperProps {
    open: boolean
}

const LabelWrapper = styled(NakedButton).attrs<DataAttributes>({
    'data-testid': 'nav-avatar',
})<LabelWrapperProps>`
    text-align: left;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 ${tokens.spacing12};
    margin: 0;
    box-sizing: border-box;
    border-radius: ${tokens.arc4};
    background-color: transparent;
    transition: ${tokens.smoothInOut};
    transition-property: background-color;
    ${focusRing('regular')}

    &:hover {
        ${LabelWrapperActive}
    }

    ${(props) => (props.open ? LabelWrapperActive : '')};

    @media (min-width: ${breakpoint}) {
        align-items: flex-start;
        padding: ${tokens.spacing6} ${tokens.spacing12};
    }
`

const TextWrapper = styled.div`
    width: auto;
    overflow: hidden;
`

const NavAvatar = styled(AvatarEmployee)`
    margin-left: ${tokens.spacing8};
    order: 1;

    @media (min-width: ${breakpoint}) {
        margin-left: 0;
        margin-right: ${tokens.spacing8};
        order: 0;
    }
`

export const NoWrapParagraph = styled(Text).attrs({
    variant: 'medium-default',
    as: 'p',
    truncate: true,
    color: 'colorContentStatic',
})`
    width: inherit;
`

export const NavigationAvatar: FC<NavigationAvatarProps> = ({
    accounts,
    switchAccount,
    employee,
    firstName,
    onLogout,
    company,
}) => {
    const user = useUser()
    const hasAccounts = useHasAccounts(user, accounts)

    const [open, setOpen] = useState(false)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const {open: openSwitchAccountModal} = useAccountSwitchModal()
    const navigate = useNavigate()
    const isTablet = useBreakpoint(parseInt(breakpoint, 10))
    const isMobile = useBreakpoint(parseInt(mobileBreakPoint, 10))
    const {width: navigationWidth} = useNavigationWidth()

    const navigateAndClosePopover = (path: string): void => {
        navigate(path)
        setOpen(false)
    }

    const arrowFunction = (event: KeyboardEvent): void => {
        if (
            document.activeElement?.getAttribute('role') === 'menuitem' &&
            (event.key === 'ArrowDown' || event.key === 'ArrowUp')
        ) {
            event.stopImmediatePropagation()
        }
    }

    React.useEffect(() => {
        document.addEventListener('keydown', arrowFunction, false)

        return () => {
            document.removeEventListener('keydown', arrowFunction, false)
        }
    }, [])

    const label = (
        <LabelWrapper tabIndex={0} open={open}>
            <NavAvatar size={40} employee={employee} onClick={() => setIsDrawerOpen(true)} />
            {!isTablet && (
                <TextWrapper>
                    <NoWrapParagraph>{firstName}</NoWrapParagraph>
                    <Stack>
                        <Text variant="small-subtle">{company?.name ?? ''}</Text>
                    </Stack>
                </TextWrapper>
            )}
        </LabelWrapper>
    )

    if (isMobile) {
        return (
            <>
                <Drawer.Trigger drawerId={MOBILE_NAVIGATION_DRAWER_ID}>
                    <NakedButton onClick={() => setIsDrawerOpen(true)}>
                        <NavAvatar size={40} employee={employee} />
                    </NakedButton>
                </Drawer.Trigger>
                <NavigationAvatarMobile
                    isOpen={isDrawerOpen}
                    onDismiss={() => setIsDrawerOpen(false)}
                    employee={employee}
                    company={company}
                    accounts={accounts}
                    hasAccounts={hasAccounts}
                    switchAccount={switchAccount}
                    onLogout={onLogout}
                />
            </>
        )
    }

    return (
        <Dropdown open={open} onOpenChange={setOpen} isTablet={isTablet}>
            <Dropdown.Trigger asChild>{label}</Dropdown.Trigger>
            <Dropdown.Content
                $isWide={hasAccounts || isTablet}
                side={isTablet ? 'bottom' : 'top'}
                $navigationWidth={navigationWidth}
                data-testid="nav-avatar-dropdown-legacy"
            >
                <MenuWrapper>
                    {isTablet && (
                        <TabletAvatarWrapper>
                            <Stack space={20} align="center" stretch>
                                <Inline alignY="center" space={8}>
                                    <AvatarEmployee employee={employee} size={40} />
                                    <Stack>
                                        <NoWrapParagraph>
                                            {employee?.firstName} {employee?.lastName}
                                        </NoWrapParagraph>
                                        <Text variant="small-subtle">{company?.name}</Text>
                                    </Stack>
                                </Inline>

                                <Button
                                    variant="secondary"
                                    onClick={() => navigateAndClosePopover('/account/profile')}
                                >
                                    <Trans>My account</Trans>
                                </Button>
                            </Stack>
                        </TabletAvatarWrapper>
                    )}
                    {hasAccounts && (
                        <NavigationAccounts
                            items={accounts}
                            switchAccount={switchAccount}
                            navigationItem={NavigationItemDesktop}
                        />
                    )}

                    <BoxWithBorder py={4} />
                    <MenuItem onClick={() => openSwitchAccountModal()}>
                        <Trans>Add account</Trans>
                    </MenuItem>

                    {hasAccounts ? (
                        <>
                            <MenuItem data-testid="logout-link" onClick={onLogout}>
                                <Trans>Logout of all</Trans>
                            </MenuItem>
                            <Box py={4} />
                        </>
                    ) : (
                        <>
                            <MenuItem data-testid="logout-link" onClick={onLogout}>
                                <Trans>Logout</Trans>
                            </MenuItem>
                            <Box py={4} />
                        </>
                    )}

                    {!isTablet && (
                        <>
                            <MenuItem
                                $topBorder
                                onClick={() => navigateAndClosePopover('/account/profile')}
                            >
                                <Trans>My account</Trans>
                            </MenuItem>
                            <Box py={4} />
                        </>
                    )}
                </MenuWrapper>
            </Dropdown.Content>
        </Dropdown>
    )
}

const BoxWithBorder = styled(Box)`
    border-top: ${tokens.borderStatic};
`

interface MenuItemProps {
    focus?: boolean
    $topBorder?: boolean
    $topPadding?: boolean
    $bottomPadding?: boolean
}

const MenuItem = styled(Dropdown.Item)<MenuItemProps>`
    ${focusRing('inset')}
    width: 100%;
    font-size: 14px;
    line-height: ${tokens.lineHeight3};
    padding: ${tokens.spacing8} ${tokens.spacing20};
    padding-top: ${(props) => (props.$topPadding ? tokens.spacing16 : '')};
    padding-bottom: ${(props) => (props.$bottomPadding ? tokens.spacing16 : '')};
    background: ${(props) =>
        props.focus ? tokens.colorBackgroundInteractiveHover : tokens.colorBackgroundInteractive};
    border-top: ${(props) => (props.$topBorder ? tokens.borderInteractiveQuiet : '')};
    color: ${(props) =>
        props.focus ? tokens.colorContentInteractiveHover : tokens.colorContentInteractive};
    text-align: left;
    box-sizing: border-box;
    background-color: transparent;
    transition: ${tokens.smoothInOut};
    transition-property: background-color;

    &:hover {
        background: ${tokens.colorBackgroundStaticLouder};
        color: ${tokens.colorContentInteractiveHover};
    }

    cursor: pointer;
    outline: none;
`

const TabletAvatarWrapper = styled.div`
    padding: ${tokens.spacing20};
    width: inherit;
    border-bottom: ${tokens.borderStatic};
`

interface MenuWrapperProps {
    autoWidthOnMobile?: boolean
}

const MenuWrapper = styled.div<MenuWrapperProps>`
    display: flex;
    flex-direction: column;
    border-radius: ${tokens.arc4};
`
