import type {OperationContext} from '@trpc/client'

import type {RequestScope} from '@product-web/shared--api'

export type ClientContext = {
    /**
     * If true, the request will be not be batched.
     */
    skipBatch?: boolean

    /**
     * The authentication context for the request.
     * Default to passing the current accessToken
     */
    auth?:
        | {
              type: 'elevated'

              /**
               * The request scope.
               */
              scope: RequestScope

              /**
               * Optional: list of elevated resource ids. If provided, the user will be asked for pin.
               */
              elevatedResourceIds?: string[]

              /**
               * Optional: the user will always be asked for pin and the token will be refreshed.
               */
              forcePIN?: boolean
          }
        | {
              type: 'token'

              /**
               * A custom access token to use for this request.
               */
              accessToken: string
          }
}

/**
 * The client context is used to pass additional information in how the request should be handled.
 * This helper function is used to help with type inference.
 *
 * Usage:
 * @example
 * ```ts
 * import {RequestScope} from '@product-web/shared--api'
 * import {BffErrorMessages, ClientContext} from '@product-web/shared--bff-client'
 *
 * const {data, error} = bff.banana.useMutation({
 *      trpc: {
 *          context: defineClientContext({
 *              auth: {
 *                  scope: RequestScope.PERMISSION,
 *                  forcePIN: false,
 *                  elevatedResourceIds: [],
 *              },
 *          })
 *      }
 * })
 *
 * if (error?.message === BffErrorMessages.PIN_MODAL_CANCELLED) {
 *      console.log('handle this')
 * }
 * ```
 */
export function defineClientContext(context: ClientContext): OperationContext {
    return context
}
