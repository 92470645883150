import {t, Trans} from '@lingui/macro'
import type React from 'react'
import {useNavigate} from 'react-router-dom'

import {Button, Text} from '@pleo-io/telescope'
import {ArrowRight, Shield} from '@pleo-io/telescope-icons'

import * as tracking from '@product-web/shared--analytics'
import {reportError} from '@product-web/shared--error/report'
import type {PageSection} from '@product-web/shared--paywall/index.bff'
import {useToaster} from '@product-web/shared--toaster'
import {useLoggedInUser} from '@product-web/shared--user'
import {isNonNullable} from '@product-web/shared--utils'

import {MilestoneAccordion} from './milestone-accordion'

import {bff} from '../bff-hooks'
import {getMilestoneProgress} from '../lib/get-milestone-progress'
import type {MilestoneName, MilestoneStatus, Task} from '../universal/types'

type SpendingLimitsMilestoneAccordionItemProps = {
    status: MilestoneStatus
    tasks: Task[]
    milestoneName: MilestoneName
    isOnTrial: boolean
    requiresUpgrade?: boolean
    openPricingModal: ({pageSection}: {pageSection: PageSection}) => void
}

export const SpendingLimitsMilestoneAccordionItem = ({
    status,
    tasks,
    milestoneName,
    isOnTrial,
    requiresUpgrade,
    openPricingModal,
}: SpendingLimitsMilestoneAccordionItemProps) => {
    const {showToast} = useToaster()
    const navigate = useNavigate()
    const user = useLoggedInUser()
    const {mutate: startTask} =
        bff.companyOnboarding.getStarted.startCompanyOnboardingTask.useMutation()
    const {mutate: skipMilestone, isLoading: isSkipping} =
        bff.companyOnboarding.getStarted.skipCompanyOnboardingMilestone.useMutation({
            onError: () => {
                showToast(t`Check your internet connection and try again.`, {
                    title: t`Couldn't mark the step as skipped`,
                    level: 'error',
                })
            },
        })

    const spendingLimitsTasks: React.ComponentProps<typeof MilestoneAccordion.Tasks>['tasks'] =
        tasks
            .map((task) => {
                switch (task.name) {
                    case 'ADD_CARD_LIMITS':
                        return {
                            heading: t`Set a card limit`,
                            children: (
                                <Text>
                                    <Trans>Try setting a limit on your own card.</Trans>
                                </Text>
                            ),
                            status: task.status,
                            cta: (
                                <Button
                                    variant="primary"
                                    IconRight={ArrowRight}
                                    onClick={() => {
                                        startTask(task.name)
                                        tracking.companyOnboardingMilestoneActioned({
                                            action: 'started',
                                            milestone: milestoneName,
                                            task: task.name,
                                        })
                                        navigate(`/people/everyone/${user.employee?.id}#cards`)
                                    }}
                                >
                                    <Trans>Continue</Trans>
                                </Button>
                            ),
                        }

                    case 'ADD_REVIEWS':
                        return {
                            heading: t`Turn on reviews`,
                            children: (
                                <Text>
                                    <Trans>Create an approval process for expenses.</Trans>
                                </Text>
                            ),
                            status: task.status,
                            cta: (
                                <Button
                                    variant="primary"
                                    IconRight={ArrowRight}
                                    onClick={() => {
                                        startTask(task.name)
                                        tracking.companyOnboardingMilestoneActioned({
                                            action: 'started',
                                            milestone: milestoneName,
                                            task: task.name,
                                        })
                                        navigate('/settings/review-overview')
                                    }}
                                >
                                    <Trans>Continue</Trans>
                                </Button>
                            ),
                        }

                    default: {
                        reportError('Invalid milestone task name', null, {
                            milestoneName,
                            taskName: task.name,
                        })

                        return null
                    }
                }
            })
            .filter(isNonNullable)

    return (
        <MilestoneAccordion.Item value={milestoneName} status={status}>
            <MilestoneAccordion.Header
                headingLevel="h2"
                heading={t`Add spending limits`}
                Icon={Shield}
                progress={getMilestoneProgress(spendingLimitsTasks)}
                badgeText={getBadgeText({isOnTrial, requiresUpgrade})}
            />

            <MilestoneAccordion.Content>
                <MilestoneAccordion.Tasks
                    tasks={spendingLimitsTasks}
                    blocked={requiresUpgrade}
                    banner={
                        requiresUpgrade ? (
                            <MilestoneAccordion.Banner
                                heading={t`Your free trial has ended`}
                                description={t`Upgrade to keep using spending controls.`}
                                cta={
                                    <Button
                                        variant="primary"
                                        onClick={() =>
                                            openPricingModal({
                                                pageSection: 'spending-limits-milestone',
                                            })
                                        }
                                    >
                                        <Trans>Select plan</Trans>
                                    </Button>
                                }
                            />
                        ) : undefined
                    }
                />

                {requiresUpgrade && (
                    <MilestoneAccordion.SkipButton
                        onClick={() => skipMilestone(milestoneName)}
                        loading={isSkipping}
                    />
                )}
            </MilestoneAccordion.Content>
        </MilestoneAccordion.Item>
    )
}

type GetBadgeTextArgs = {
    isOnTrial: boolean
    requiresUpgrade?: boolean
}

const getBadgeText = ({isOnTrial, requiresUpgrade}: GetBadgeTextArgs) => {
    if (isOnTrial) {
        return t`Trial`
    }

    if (requiresUpgrade) {
        return t`Essential plan`
    }

    return undefined
}
