import type {LDUser} from 'launchdarkly-js-client-sdk'
// eslint-disable-next-line deprecate/import
import {LDProvider, useFlags as useLDFlags, useLDClient} from 'launchdarkly-react-client-sdk'
import React, {useEffect} from 'react'

import * as tracking from '@product-web/shared--analytics'
import {
    useEntitySwitchEffect,
    useIsLoggedIn,
    useIsLoggedOut,
} from '@product-web/shared--auth--session/context'
import config from '@product-web/shared--config'

import {bff} from './bff-hooks'
import type {LaunchDarklyFlags} from './flags'

/**
 * Wraps the app in a pre-configured LaunchDarkly provider (https://github.com/launchdarkly/react-client-sdk)
 * Passes all the information necessary to calculate the value of all the flags to LD when initializing
 */
export function LaunchDarklyProvider({children}: {children: React.ReactNode}) {
    const ldUser = useLaunchDarklyUser()

    return (
        <LDProvider
            clientSideID={config.launchdarkly.key}
            // We're deferring the init of the LD JS client until after we've figured out what user object
            // we need to calculate the flags for (anonymous or logged in with various custom attributes).
            // @see https://docs.launchdarkly.com/sdk/client-side/react/react-web/?q=deferinitialization#initializing-using-withldprovider
            deferInitialization
            user={ldUser}
            options={{
                // Saves the flags for the current user in LocalStorage, so that on the next full
                // page load the flags are available even before the request to LD is made, in a
                // stale-while-revalidate fashion.
                // @see https://docs.launchdarkly.com/sdk/features/bootstrapping?q=deferinitialization#bootstrapping-using-local-storage
                bootstrap: 'localStorage',
                // To avoid the app changing suddenly while the user interacts with it, we don't
                // use flag streaming. The user will get the fresh set of flags when the re-load the app.
                streaming: false,
                // Since all our anonymous users share the same user key (to save on MAU count), we don't
                // want to associate the anonymous user with the logged in users in any way.
                autoAliasingOptOut: true,
                // PII attributes that won't be sent to LaunchDarkly in analytics events. They can still be used
                // for flags targeting, but their values won't be stored or displayed on the LaunchDarkly side
                // @see https://docs.launchdarkly.com/sdk/concepts/client-side-server-side/?q=private#private-user-attributes
                privateAttributeNames: ['email', 'firstName', 'lastName'],
                // Some options that aim at limiting the number of requests we need to make to LaunchDarkly
                // @see https://launchdarkly.github.io/js-client-sdk/interfaces/LDOptions.html
                diagnosticOptOut: true,
                fetchGoals: false,
                sendLDHeaders: false,
                sendEvents: window.Cypress ? false : true,
            }}
        >
            <FlagsLoadedStatusProvider>
                <ExposeLDClientExternally />
                <HandleLDContextChanges ldUser={ldUser} />
                {children}
            </FlagsLoadedStatusProvider>
        </LDProvider>
    )
}

export const FlagsLoadedStatusContext = React.createContext<{
    loaded: boolean
    setLoaded: (loaded: boolean) => void
}>({
    loaded: false,
    setLoaded: () => {},
})

/**
 * Provides LD flags loading status for a given LD context.
 */
function FlagsLoadedStatusProvider({children}: {children: React.ReactNode}) {
    const [loaded, setLoaded] = React.useState(false)

    useEntitySwitchEffect(() => {
        // When account or entity is changed we are setting loaded to false as soon as possible
        // to notify all the consumers that new flags are not loaded yet
        setLoaded(false)
    })

    return (
        <FlagsLoadedStatusContext.Provider
            value={{
                loaded,
                setLoaded,
            }}
        >
            {children}
        </FlagsLoadedStatusContext.Provider>
    )
}

const IDENTIFY_USER_TIMEOUT = 5000

/**
 * If/when the context (user) changes after initialization we identify with
 * launch darkly to re-evaluate flags whose targeting might depend on the
 * changed properties.
 * Also, here we are tracking flags loaded status.
 * We consider flags being loaded only if LDClient is ready AND if context (user) is set
 */
function HandleLDContextChanges({ldUser}: {ldUser?: LDUser}) {
    const ldClient = useLDClient()
    const {setLoaded} = React.useContext(FlagsLoadedStatusContext)

    useEffect(() => {
        // If launch darkly user identification doesn't respond within 5 seconds,
        // we mark the flags as loaded anyway to not block UI from being rendered.
        // This is in particular needed to show navigation items.
        // If user identification will be successfully finished later -
        // UI still will be updated with the correct LD data.
        const identifyUserTimeout = setTimeout(async () => {
            setLoaded(true)
            tracking.evaluateFeatureFlags({status: 'timeout'})
        }, IDENTIFY_USER_TIMEOUT)

        const updateLDContext = async () => {
            setLoaded(false)

            if (ldClient && ldUser) {
                try {
                    await ldClient.identify(ldUser)
                    tracking.evaluateFeatureFlags({status: 'success'})
                } finally {
                    clearTimeout(identifyUserTimeout)
                    setLoaded(true)
                }
            } else {
                // ldClient & ldUser will be available shortly,
                // no need to track timeouts yet
                clearTimeout(identifyUserTimeout)
            }
        }

        updateLDContext()

        return () => {
            clearTimeout(identifyUserTimeout)
        }
    }, [ldClient, ldUser, setLoaded])

    return null
}

/**
 * We're exposing the native JS LaunchDarkly client here, so that it's available
 * outside of the React context. This is a hack useful in a few scenarios, you likely
 * don't need this!
 */
function ExposeLDClientExternally() {
    const ldClient = useLDClient()
    React.useEffect(() => {
        window.__launchDarklyClient = ldClient
    }, [ldClient])
    return null
}

/**
 * Get the user object sent to LaunchDarkly when requesting the values for all flags.
 */
function useLaunchDarklyUser(): LDUser | undefined {
    const isLoggedIn = useIsLoggedIn()
    const isLoggedOut = useIsLoggedOut()
    const searchParams = new URLSearchParams(window.location.search)

    const webLoginRedesign = searchParams.get('webLoginRedesign')
    const isFlaggedForWebLoginRedesign =
        localStorage.getItem('webLoginRedesign') === 'true' ? true : false

    React.useEffect(() => {
        if (webLoginRedesign) {
            localStorage.setItem('webLoginRedesign', 'true')
        }
    })

    const {data, error} = bff.launchDarklyUserInfo.useQuery(undefined, {
        enabled: isLoggedIn,
        refetchOnWindowFocus: false,
        retryOnMount: false,
    })

    if (isLoggedIn && data) {
        return data
    }

    if (isLoggedOut || error || !data) {
        return {
            // All anonymous users share the same key. This means we can't vary the flag values before the users
            // log in, but at the same time allows us to save on the monthly active users count.
            // See https://docs.launchdarkly.com/home/users/anonymous-users#tracking-anonymous-users-with-a-shared-key
            key: 'anonymous_user_id',
            anonymous: true,
            custom: {
                webLoginRedesign: isFlaggedForWebLoginRedesign,
            },
        }
    }

    return undefined
}

/**
 * Check if LaunchDarkly flags are available. We render the app without waiting for LaunchDarkly to
 * init, so if you need to use a value of a feature flag during the initial render or during context (user) change,
 * you need to first check if the values are already available.
 * You can show some loading indicator or other intermittent
 * UI while LD is loading.
 * @returns Boolean indicating if the flag values for a given context are available using `useFlags`
 */
export function useFlagsLoaded() {
    const ldUser = useLaunchDarklyUser()
    const {loaded} = React.useContext(FlagsLoadedStatusContext)

    return !!ldUser && loaded
}

export function useFlags() {
    return useLDFlags<LaunchDarklyFlags>()
}
