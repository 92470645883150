// This provider wraps the entire app. It's responsible for fetching session info from Kerberos,
// setting this data to sessionStore, registering event handler for sessionStore changes
// and exposing sessionData to all pages via context. It's also re-fetching the session info when the tab
// gains focus, to e.g. account for long lived tabs where the session expires, or user's logging
// out in a different tab.

import type {PropsWithChildren} from 'react'
import React from 'react'
import type {SWRConfiguration} from 'swr'
import useSWR from 'swr'

import {LoadingPage} from '@pleo-io/telescope'

import type {SessionContextAPI} from '@product-web/shared--auth--session/context'
import {SessionContext, SessionStatus} from '@product-web/shared--auth--session/context'
import {getMutations} from '@product-web/shared--auth--session/get-mutations'
import {
    __logout,
    cleanupSession,
    fetchSession,
    SESSION_ROUTE,
} from '@product-web/shared--auth--session/operations'
import type {SessionData} from '@product-web/shared--auth--session/store'
import {
    sessionStore,
    useSessionUpdatedEventListener,
} from '@product-web/shared--auth--session/store'
import {reportError} from '@product-web/shared--error/report'
import {usePrevious} from '@product-web/shared--react-utils'
import cookie from '@product-web/shared--web-platform/cookie'

// Cookie name of a cookie used to store the session status of a user when logging in or out.
// This is a read-only value that is used by the commercial website to
const SESSION_STATUS_KEY = 'isLoggedIn'

// out in a different tab.
export const SessionProvider: React.FC<PropsWithChildren> = ({children}) => {
    useForceSessionCleanup()

    const [isLoggingOut, setIsLoggingOut] = React.useState(false)
    const [sessionData, setSessionData] = React.useState<SessionData | undefined>(
        sessionStore.get(),
    )

    // SWR is used for data fetching & revalidation, however fetcher sets received data in sessionStore,
    // so that request methods have access to sessionData outside React.Context,
    // this is why we do not return data from the hook, but handling events from sessionStore event emitter
    useSWR<SessionData | undefined, Error>(SESSION_ROUTE, fetchSession, {
        onErrorRetry,
        revalidateOnFocus: true,
    })

    // Listener for session update to set latest value from sessionStorage to local state
    // (see getSessionStore description below)
    useSessionUpdatedEventListener(setSessionData)

    const accessToken = sessionData?.accessToken
    const accessTokens = sessionData?.accessTokens
    const trustedEmails = sessionData?.trustedEmails
    const email = sessionData?.email

    const sessionStatus = deriveSessionStatusFromSessionData(sessionData ?? {}, isLoggingOut)

    // Whenever we go from logged in state to logged out state,
    // we want to perform a full clean-up of session data.
    // This will usually happen when the user's session expires,
    // or they log out in another tab.
    const previousSessionStatus = usePrevious(sessionStatus)

    React.useEffect(() => {
        if (
            sessionStatus === SessionStatus.LoggedOut &&
            previousSessionStatus === SessionStatus.LoggedIn
        ) {
            cleanupSession()
        }
    }, [sessionStatus])

    // Any time the session status changes, we create/update a cookie that
    // indicates whether the user is logged in or not. This is read e.g. in the
    // website to show an alternative UI for logged-in users.
    React.useEffect(() => {
        if (sessionStatus === SessionStatus.LoggedIn) {
            cookie.set(SESSION_STATUS_KEY, true, 1000 * 60 * 60 * 6)
        } else {
            cookie.remove(SESSION_STATUS_KEY)
        }
    }, [sessionStatus])

    const mutations = getMutations(() => setIsLoggingOut(true))

    const sessionContextValue: SessionContextAPI = {
        status: sessionStatus,
        email: email ?? null,
        accessToken: accessToken ?? null,
        accountAccessTokens: accessTokens ?? null,
        trustedEmails: trustedEmails ?? null,
        logout: mutations.logout,
        login: mutations.login,
        verifiedLogin: mutations.verifiedLogin,
        register: mutations.register,
        loginWithPasscode: mutations.loginWithPasscode,
        loginWithToken: mutations.loginWithToken,
    }

    if (sessionStatus === SessionStatus.Unknown && !trustedEmails?.length) {
        // We do not yet know whether we can refresh session or no
        return <LoadingPage data-testid="loading-indicator" />
    }

    return <SessionContext.Provider value={sessionContextValue}>{children}</SessionContext.Provider>
}

function useForceSessionCleanup() {
    const STORAGE_SESSION_STATUS_KEY = 'sessionStatus'

    function handleForeignSessionStatusChange(e: StorageEvent) {
        if (e.key !== STORAGE_SESSION_STATUS_KEY) {
            return
        }
        try {
            const status = localStorage.getItem(STORAGE_SESSION_STATUS_KEY)
            if (status === SessionStatus.LoggedOut) {
                __logout()
            }
        } catch (error) {
            reportError(error, 'Failed to get session status from local storage')
        }
    }

    function handleLocalSessionStatusChange(data: SessionData | undefined) {
        const status = deriveSessionStatusFromSessionData(data ?? {})
        try {
            localStorage.setItem(STORAGE_SESSION_STATUS_KEY, status)
        } catch (error) {
            reportError(error, 'Failed to set session status in local storage')
        }
    }

    useSessionUpdatedEventListener(handleLocalSessionStatusChange)

    React.useEffect(() => {
        window.addEventListener('storage', handleForeignSessionStatusChange)

        return () => window.removeEventListener('storage', handleForeignSessionStatusChange)
    }, [])
}

/**
 * A method to derive the session status from the session data.
 * Translate the vague null/undefined/string type of the access token
 * to an explicitly defined session status. If the user is in the process
 * of logging out or if they are can refresh their session on a trusted device, we treat this as an unknown status
 */
function deriveSessionStatusFromSessionData({accessToken}: SessionData, isLoggingOut?: boolean) {
    if (accessToken) {
        return SessionStatus.LoggedIn
    } else if (accessToken === null && !isLoggingOut) {
        return SessionStatus.LoggedOut
    }

    return SessionStatus.Unknown
}

// Adjusts the behavior when for some reason we can't fetch the access token
// Retry and if still failing, redirect to the error page
const onErrorRetry: SWRConfiguration['onErrorRetry'] = (
    _error,
    _key,
    _option,
    revalidate,
    revalidateOptions,
) => {
    const retryCount = revalidateOptions.retryCount ?? 1
    if (retryCount >= 5) {
        throw new Error('Cannot fetch token')
    }

    setTimeout(async () => revalidate({retryCount: retryCount + 1}), retryCount * 2000)
}
