import Locale from '@product-web/shared--locale/locale'

export enum Terms {
    Documents = 'documents',
}

export const defaultLocale = Locale.en_GB
export const nonDefaultLocales = Object.values(Locale).filter((locale) => locale !== defaultLocale)

type LocalisedDocument = Partial<{[key in Locale]: string}>

export type LocalisedDocuments = {
    masterServiceAgreement: LocalisedDocument
    masterServiceAgreementB4B: LocalisedDocument
    masterServiceAgreementPathward: LocalisedDocument
    dataProcessingAgreement: LocalisedDocument
    privacyPolicy: LocalisedDocument
    privacyPolicyPathward: LocalisedDocument
    purchaseTerms: LocalisedDocument
    cardholderAgreement: LocalisedDocument
    cardholderAgreementPathward: LocalisedDocument
    authorisedUserTerms: LocalisedDocument
    authorisedUserTermsPathward: LocalisedDocument
    overdraftTerms: LocalisedDocument
}

export type TermsConfig = {
    [Terms.Documents]: LocalisedDocuments
}
