import {Trans} from '@lingui/macro'
import type {FC, MouseEventHandler} from 'react'

import {Button} from '@pleo-io/telescope'

import * as apiDeimosExpense from '@product-web/shared--api-deimos/expense'

const Push: FC<{id: string}> = ({id}) => {
    if (window && (window as any).showPush) {
        const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
            e.stopPropagation()
            apiDeimosExpense
                .push(id)
                .then(() => {})
                .catch(() => {})
        }

        return (
            <Button variant="tertiary" onClick={onClick}>
                <Trans>Push</Trans>
            </Button>
        )
    }
    return null
}

export default Push
