import {t} from '@lingui/macro'
import React, {useState} from 'react'

import {AdoptionPrompt} from '@product-web/feature--help-centre/adoption-prompt'
import {useCompanyGuides} from '@product-web/shared--api-deimos/company-guides'
import {localStorage} from '@product-web/shared--web-platform/storage/local'

import paperplaneImage from './accordion/heading-image/paperplane-accountant.png'
import {useAdoptionCentre} from './adoption-centre-context'
import {trackAdoptionPrompt} from './tracking'

type PromptAction = 'completed' | 'dismissed'

export const getStorageKey = (guideName: string) => `GUIDE_PROMPT_${guideName}`

const useCompanyGuide = () => {
    const {data: companyGuides} = useCompanyGuides()
    // for prototype we're using only first guide
    return companyGuides[0]
}

export const AdoptionCentrePrompt = () => {
    const {setDrawerOpen} = useAdoptionCentre()

    const guide = useCompanyGuide()
    const guideName = guide?.adoptionGuide

    const [isVisible, setVisible] = useState(false)

    React.useEffect(() => {
        if (guide) {
            const promptLocalStorageValue = localStorage.getItem(getStorageKey(guideName))
            const promptOpen = Boolean(guide.showPrompt && !promptLocalStorageValue)
            if (promptOpen) {
                trackAdoptionPrompt('viewed', guideName)
                setVisible(true)
            }
        }
    }, [guide, guideName])

    const hidePrompt = (action: PromptAction) => {
        setVisible(false)
        localStorage.setItem(getStorageKey(guideName), action)
    }
    const onDismiss = () => {
        hidePrompt('dismissed')
        trackAdoptionPrompt('abandoned', guideName)
    }
    const onComplete = () => {
        hidePrompt('completed')
        trackAdoptionPrompt('completed', guideName)
        setDrawerOpen(true)
    }

    if (!isVisible) {
        return null
    }

    return (
        <AdoptionPrompt
            name={guideName}
            size={'medium'}
            completeText={t`Let's do it`}
            dismissText={t`Not now`}
            imageSrc={paperplaneImage}
            onComplete={onComplete}
            onDismiss={onDismiss}
            title={t`It's time to Export`}
            text={t`Out of Pleo and into your accounting systems, get your expenses ready for reconciliation`}
        />
    )
}
