import styled, {css} from 'styled-components'

import type {ModalProps} from '@pleo-io/telescope'
import {
    Button as ButtonBase,
    Modal as ModalBase,
    ModalClose,
    ModalContent,
    Stack,
    tokens,
} from '@pleo-io/telescope'

import {breakpoints} from '@product-web/shared--styles/theme'

const ScreenActionWrapperHeight = tokens.spacing44
const ScreenActionWrapperPaddingTop = tokens.spacing6

const IllustrationWrapperHeight = 287

export const Button = styled(ButtonBase)`
    margin-left: auto;
`

export const LinkButton = styled(ButtonBase)`
    display: flex;
    align-items: center;
`

export const FlexRow = styled.div`
    display: flex;
    align-items: center;
`

export const Illustration = styled.div<{
    $span?: number
    $scale?: number
    $alignX?: 'left' | 'right'
    $marginTop?: number
    $marginRight?: number
    $marginBottom?: number
}>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;

    img,
    svg {
        width: ${({$span}) => $span ?? 100}%;
        transform: scale(${({$scale}) => $scale ?? 1});
        ${({$marginTop}) =>
            $marginTop &&
            css`
                margin-top: ${$marginTop}px;
            `}
        ${({$marginRight}) =>
            $marginRight &&
            css`
                margin-right: ${$marginRight}px;
            `}
        ${({$marginBottom}) =>
            $marginBottom &&
            css`
                margin-bottom: ${$marginBottom}px;
            `}
    }

    ${({$alignX}) =>
        $alignX === 'left' &&
        css`
            & {
                justify-content: flex-start;
            }
        `}
    ${({$alignX}) =>
        $alignX === 'right' &&
        css`
            justify-content: flex-end;
        `}
`

const ModalWithClose = ({children, ...props}: ModalProps) => (
    <ModalBase {...props}>
        {props.onDismiss && <ModalClose onClick={props.onDismiss} />}
        {children}
    </ModalBase>
)

export const Modal = styled(ModalWithClose)`
    padding-top: 0;
    width: 900px;

    & button {
        z-index: ${tokens.zIndexSurface};
        cursor: pointer;
    }

    ${ModalContent} {
        margin-bottom: 0;
        padding: 0;
        border-radius: ${tokens.arc8};
        height: 100%;
    }

    @media (max-width: ${breakpoints.desktopUp}) {
        position: relative;
        width: 100%;
        height: calc(100vh - 20px);
    }
`

export const ScreenWrapper = styled.div`
    position: relative;
    width: 100%;
    min-height: 600px;
    height: 100%;
    overflow: hidden;

    @media (max-width: ${breakpoints.tabletUp}) {
        position: static;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
`

export const ScreenContent = styled.div`
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding: ${tokens.spacing40} 0 ${tokens.spacing20} ${tokens.spacing40};
    height: 100%;
    overflow: auto;

    @media (max-width: ${breakpoints.tabletUp}) {
        position: static;
        max-height: calc(100vh - 65%);
        overflow: unset;
        -ms-overflow-style: none; /* force remove scrollbar for Internet Explorer, Edge */
        scrollbar-width: none; /* force remove scrollbar for Firefox */

        &::-webkit-scrollbar {
            display: none; /* force remove scrollbar for Chrome, Safari, and Opera */
        }
    }
`

export const ContentWrapper = styled(Stack).attrs({
    space: 20,
})`
    height: 100%;
    grid-template-rows: 12px minmax(0, 1fr) auto;
`

export const Content = styled.div`
    overflow-y: auto;
    padding-right: ${tokens.spacing32};

    @media (max-width: ${breakpoints.tabletUp}) {
        width: 100%;
        height: auto;
        box-sizing: border-box;
    }
`

export const ScreenContentWrapper = styled.section<{isImageOnTop?: boolean}>`
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 600px;
    height: 100%;
    overflow: hidden;
    border-radius: ${tokens.arc8};

    @media (max-width: ${breakpoints.tabletUp}) {
        grid-template-columns: unset;
        grid-template-rows: ${({isImageOnTop}) =>
            isImageOnTop ? `${IllustrationWrapperHeight}px 1fr` : '1fr 100%'};
        border-radius: 0;
        height: 100%;
        overflow: unset;
    }
`
export const ScreenSectionWrapper = styled.article``

export const ScreenSectionsWrapper = styled.div`
    padding-top: ${tokens.spacing24};

    ${ScreenSectionWrapper} + ${ScreenSectionWrapper} {
        margin-top: ${tokens.spacing24};
    }
`

export const ScreenActionWrapper = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${ScreenActionWrapperPaddingTop};
    bottom: ${tokens.spacing20};
    left: ${tokens.spacing40};
    width: calc(50% - ${tokens.spacing32} - ${tokens.spacing48});
    min-height: ${ScreenActionWrapperHeight};

    @media (max-width: ${breakpoints.tabletUp}) {
        padding-top: 0;
        width: calc(100% - ${tokens.spacing32} - ${tokens.spacing48});
        bottom: ${tokens.spacing24};
    }
`
export const IllustrationWrapper = styled.div<{$color?: string}>`
    ${(props) => props.$color && `background-color: ${props.$color};`}
    border-top-right-radius: ${tokens.arc8};
    height: 100%;

    @media (max-width: ${breakpoints.tabletUp}) {
        height: ${IllustrationWrapperHeight}px;
        border-top-left-radius: ${tokens.arc8};
    }
`
