import {t, Trans} from '@lingui/macro'
import {useNavigate} from 'react-router-dom'

import {
    Box,
    Button,
    Inline,
    Modal,
    ModalActions,
    ModalClose,
    ModalContent,
    ModalTitle,
} from '@pleo-io/telescope'

import {useToaster} from '@product-web/shared--toaster'

import {bff} from '../bff'

export const TagBudgetDeleteModal: React.FC<
    React.PropsWithChildren<{
        budgetId: string
        isOpen: boolean
        onCancel: () => void
        onConfirm: () => void
    }>
> = ({budgetId, isOpen, onCancel, onConfirm}) => {
    const {showToast} = useToaster()
    const navigate = useNavigate()

    const {mutateAsync: mutateDeleteBudget, isLoading: isMutating} =
        bff.tagBudgetModals.deleteBudget.useMutation()

    const handleDeleteBudget = async () => {
        try {
            await mutateDeleteBudget({budgetId})
            onConfirm()
            navigate('/budgets')
            showToast(t`Budget deleted successfully`, {
                title: t`Success`,
                level: 'success',
            })
        } catch (error) {
            showToast(t`Please try again, or contact support.`, {
                title: t`Something went wrong`,
                level: 'error',
            })
        }
    }

    return (
        <Modal aria-label={t`Confirm deleting budget`} isOpen={isOpen} onDismiss={onCancel}>
            <ModalClose onClick={onCancel} />
            <ModalTitle>
                <Trans>Delete budget?</Trans>
            </ModalTitle>
            <ModalContent>
                <Box as="p" pb={12}>
                    <Trans>The budget will be permanently deleted</Trans>
                </Box>
            </ModalContent>
            <ModalActions>
                <Inline space={16}>
                    <Button variant="secondary" onClick={onCancel}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button
                        data-testid={'confirm-delete-button'}
                        variant="primary"
                        onClick={handleDeleteBudget}
                        disabled={isMutating}
                        loading={isMutating}
                    >
                        <Trans>Delete</Trans>
                    </Button>
                </Inline>
            </ModalActions>
        </Modal>
    )
}
