import {createContext} from 'react'
import React from 'react'

import type {BookkeeperPermission, EmployeePermission} from '@pleo-io/deimos'

import type {Company} from '@product-web/shared--api-types/company'
import {useLogout} from '@product-web/shared--auth--session/context'
import type Country from '@product-web/shared--locale/country'
import {isCountry} from '@product-web/shared--locale/country'
import {countryToLocaleMap, getLocaleByLanguage} from '@product-web/shared--locale/helpers'
import Locale from '@product-web/shared--locale/locale'
import {useAllowedRoles} from '@product-web/shared--routes/allowed-roles'
import {EmployeeStatus} from '@product-web/shared--shell/employee-status'
import {
    type CompanyUserData,
    type PartnerUserData,
    type ReviewingEntry,
    ReviewingParentType,
    type UserData,
} from '@product-web/shared--user-types'

import {roleSets} from './user-roles'

import type {CpqRatePlanType} from '../bff-moons/generated/beyond'

type UserContextAPI = {
    user?: Readonly<UserData>
    isValidating: boolean
}

export const UserContext = createContext<UserContextAPI>({
    user: undefined,
    isValidating: true,
})

/**
 * Custom hook used by the pages where the user can be logged in or not,
 * to access the user's data (if available) from the context
 * @returns The user object, if available
 */
export function useUser() {
    const {user} = React.useContext(UserContext)
    return user
}

// We use this custom error class to be able to recognize errors thrown by custom hooks from this provider
// and ignore them instead of treating as unhandled exceptions and reporting to bug tracker.
// We throw the errors to refine the types returned (so that they are non-nullable) and handle the errors by
// logging users out.
export class UserProviderError extends Error {
    constructor(message?: string) {
        super(message)
        // restore prototype chain
        // see https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, new.target.prototype)

        this.name = UserProviderErrorName
    }
}

// eslint-disable-next-line string-to-lingui/missing-lingui-transformation
export const UserProviderErrorName = 'UserProviderError'

/**
 * Custom hook used by the behind-the-login-screen pages to access the user's data
 * from the context. Note that we are throwing here if the user is not found,
 * so that the returned value is always of non-nullable type.
 * @returns The user object
 */
export function useLoggedInUser() {
    const logout = useLogout()
    const {user} = React.useContext(UserContext)

    if (!user) {
        logout()
        throw new UserProviderError('Not logged in')
    }
    return user
}

/**
 * Custom hook used by the pages accessible to partner employees to access the user's
 * data from the context. Note that we are throwing here if the user is not found, or
 * the partner info is not available, so that the returned value has the type expected
 * of a partner user
 * @returns The user object (always a partner user)
 */
export function usePartnerUser(): PartnerUserData {
    const {user} = React.useContext(UserContext)

    useAllowedRoles(roleSets.partner)

    if (!getIsPartnerUser(user)) {
        window.location.href = '/'
        throw new Error('Not a partner user')
    }
    return user
}

/**
 * Custom hook used by the pages accessible to company employees **only** to access the user's
 * data from the context. Note that we are throwing here if the user is not found, or
 * the company info is not available, so that the returned value has the type expected
 * of a company user. Be careful to not use this hook in pages that are accesible by non-company
 * users, e.g. in 'account/*', where you can favour useLoggedInUser hook instead
 * @returns The user object (always a company user)
 */
export function useCompanyUser(): CompanyUserData {
    const {user} = React.useContext(UserContext)

    useAllowedRoles(roleSets.companyAndReviewer)

    if (!getIsCompanyUser(user)) {
        window.location.href = '/'
        throw new Error('Not a company user')
    }
    return user
}

/**
 * Custom hook used to retrieve if user is the partner user.
 * @returns boolean whether user is a partner user
 */
export function useIsPartnerUser(): boolean {
    const {user} = React.useContext(UserContext)
    return getIsPartnerUser(user)
}

/**
 * Custom hook used to retrieve if user is the company owner. Uses useCompanyUser() so may throw if user is not found.
 * @returns boolean whether user is the company owner
 */
export function useIsCompanyOwner(): boolean {
    const user = useCompanyUser()
    return getIsCompanyOwner(user)
}

/**
 * Custom hook used to retrieve if user is the company bookkeeper. Uses useCompanyUser() so may throw if user is not found.
 * @returns boolean whether user is the company bookkeeper
 */
export function useIsCompanyBookkeeper(): boolean {
    const user = useCompanyUser()
    return getIsBookkeeper(user)
}

/**
 * Custom hook used to retrieve if user is the company extended bookkeeper. Uses useCompanyUser() so may throw if user is not found.
 * @returns boolean whether user is the company extended bookkeeper
 */
export function useIsCompanyExtendedBookkeeper(): boolean {
    const user = useCompanyUser()
    return getIsExtendedBookkeeper(user)
}

/**
 * Custom hook used to retrieve if user is the company basic bookkeeper. Uses useCompanyUser() so may throw if user is not found.
 * @returns boolean whether user is the company basic bookkeeper
 */
export function useIsCompanyBasicBookkeeper(): boolean {
    const user = useCompanyUser()
    return getIsBasicBookkeeper(user)
}

/**
 * Custom hook used to retrieve if user is the team reviewer. Uses useCompanyUser() so may throw if user is not found.
 * @returns boolean whether user is the team reviewer
 */
export function useIsTeamReviewer(): boolean {
    const user = useCompanyUser()
    return getIsReviewer(user)
}

/**
 * Custom hook used to retrieve if user is a regular member. Uses useCompanyUser() so may throw if user is not found.
 * @returns boolean whether user is the company member
 */
export function useIsCompanyMember(): boolean {
    const user = useCompanyUser()
    return getIsMember(user)
}

/**
 * Custom hook used to retrieve if user is assigned to organisations.
 * Uses useCompanyUser() so may throw if user is not found.
 * @returns boolean whether user is an organisation user
 */
function useIsOrganisationUser(): boolean {
    const user = useCompanyUser()
    return getIsOrganizationUser(user)
}

/**
 * Custom hook used to retrieve if user is a reviewer of any organization-teams.
 * Uses useCompanyUser() so may throw if user is not found.
 * @returns the ReviewingEntry if applicable
 */
export function useOrganizationReviewer(): ReviewingEntry | undefined {
    const user = useCompanyUser()

    return getOrganizationReviewer(user)
}

/**
 * Custom hook used to retrieve if user has organisation access.
 * @returns boolean whether user has organisation access
 */
export const useHasOrganisationAccess = () => {
    const hasOrganisationAccess = useIsOrganisationUser()
    return hasOrganisationAccess
}

/**
 * Custom hook used to retrieve if user is in an organisation context.
 * @returns boolean whether user is in an organisation context
 */
export const useIsOrganizationContext = () => {
    const hasOrganisationAccess = useHasOrganisationAccess()
    const isOrganizationReviewer = !!useOrganizationReviewer()

    return hasOrganisationAccess || isOrganizationReviewer
}

export function useIsCompanyOnboardedViaB4B(): boolean {
    const user = useUser()
    const isOnboardedViaB4B = getIsOnboardedViaUKB4B(user)
    return isOnboardedViaB4B
}

// These functions allow for an easy access to information commonly derived from
// the user information

export const getIsPartnerOwner = (user?: UserData) => {
    if (user?.partnerEmployee) {
        return user.partnerEmployee.role === 'owner'
    }
    return false
}

export const getHomeCompany = (user: UserData) => {
    // If the user is currently working as a company employee
    // their home company will be the current working company
    if (user.role === 'owner' || user.role === 'member') {
        return user.company
    }

    // Else the user is a bookkeeper or a partner employee
    // their home company is available as `homeCompany`
    // if they have it, else it's just undefined
    return user.homeCompany
}

export const getIsExtendedBookkeeper = (user?: UserData) => {
    return user?.role === 'bookkeeper-extended'
}

export const getIsBasicBookkeeper = (user?: UserData) => {
    return user?.role === 'bookkeeper-basic'
}

export const getIsBookkeeper = (user?: UserData) => {
    return !!user?.role?.startsWith('bookkeeper')
}

export const getIsMember = (user: UserData) => {
    return user.role === 'member'
}

export const getFirstName = (user?: UserData): string => {
    return user?.employee?.firstName ?? user?.partnerEmployee?.firstName ?? ''
}

export const getLastName = (user: UserData): string => {
    return user.employee?.lastName ?? user.partnerEmployee?.lastName ?? ''
}

export const getCountry = (user?: UserData): Country | undefined => {
    const country = user?.company?.address?.country ?? user?.partner?.address?.country
    return country && isCountry(country) ? country : undefined
}

export const getCompanyName = (user: UserData): string | undefined => {
    return user.company?.name
}

export function getCompany(user: CompanyUserData): Company
export function getCompany(user?: UserData): Company | undefined
export function getCompany(user?: UserData): Company | undefined {
    return user?.company
}

export const getLocaleByCountry = (country?: string) => {
    return country ? countryToLocaleMap[country] : undefined
}

/**
 * Get supported browser locale
 */
const getSupportedBrowserLocale = (): Locale | undefined => {
    const browserLocales = window.navigator.languages as Locale[]
    const supportedLocales = Object.values(Locale)
    const browserLocale: Locale | undefined = browserLocales.find((locale) =>
        supportedLocales.includes(locale),
    )
    return browserLocale
}

/**
 * If provided with logged user, it returns language based on user's preferences or their country.
 * With no user, it is defined by supported browser locale or fallbacked to Danish.
 */
export const getLocale = (
    user?: UserData,
    defaultLocale = getSupportedBrowserLocale() || Locale.da_DK,
) => {
    if (user) {
        return (
            getLocaleByLanguage(user.language) ||
            getLocaleByCountry(getCountry(user)) ||
            defaultLocale
        )
    }

    return defaultLocale
}

export const getEmployeeStatus = (user?: UserData) => {
    return user?.employee?.status?.state || {}
}

export const isUserAskedToSwitchTheLanguage = (user?: UserData) => {
    const employeeStatus = getEmployeeStatus(user)
    return Boolean(employeeStatus[EmployeeStatus.askedToSwitchLanguage])
}

export const getFullName = (user: UserData): string => {
    return [getFirstName(user), getLastName(user)].filter((name) => !!name).join(' ')
}

export const hasFeatureFlag = (user: UserData, feature: string) => {
    return !!user?.features?.[feature]
}

export function getIsPartnerUser(user?: UserData): user is PartnerUserData {
    return !!user && !!user.partnerEmployee && !!user.partnerId
}

export function getIsCompanyUser(user?: UserData): user is CompanyUserData {
    return !!user && !!user.employee && !!user.company && !!user.companyId
}

export const getIsCompanyOwner = (user?: UserData): boolean => {
    return user?.role === 'owner'
}

export const getIsReviewer = (user?: UserData): boolean => {
    return !!user?.reviewer?.length
}

export const getHasOwnerAccess = (user: UserData): boolean => {
    return getIsCompanyOwner(user) || getIsBookkeeper(user)
}

export const getIsFdd = (user?: UserData): boolean => {
    return Boolean(user?.company?.status?.fdd)
}

export const getIsOnboardedViaUKB4B = (user?: UserData): boolean => {
    return Boolean(user?.company?.onboardedVia === 'B4B')
}

export function hasGetStarted(user?: UserData) {
    if (!user || user.role === 'owner') {
        return false
    }

    return user?.employee?.status?.state?.getstarted !== 'hide'
}

export function getCompanyPlan(user?: UserData): CpqRatePlanType | undefined {
    return user?.company?.plan
}

export function getIsEmployeePermitted(permission: EmployeePermission, user?: UserData) {
    if (!user || !user.employee?.permissions?.includes(permission)) {
        return false
    }
    return user.employee.permissions.includes(permission)
}

export function getIsUserPermitted(user: UserData, permission: BookkeeperPermission) {
    return user.permissions && user.permissions[user.companyId || '']?.includes(permission)
}

/**
 * Make properties of type `K` required in an object type `T`.
 */
type MakeRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>

export const getIsOrganizationUser = (
    user?: UserData,
): user is MakeRequired<UserData, 'organization'> => {
    return !!user?.organization
}

export const getOrganizationReviewer = (user?: UserData): ReviewingEntry | undefined => {
    return user?.reviewing?.teams.find(
        (it: ReviewingEntry) => it.parent === ReviewingParentType.ORGANIZATION,
    )
}

export const getIsOrganizationAdmin = (user?: UserData) => {
    if (user?.role !== 'owner') {
        return false
    }
    return !!user?.organization
}
