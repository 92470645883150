import {useFlags} from '@product-web/shared--flags'

export const useIsMultiEntityBudgetsEnabled = () => {
    const {multiEntityTagBudgets: multiEntityTagBudgetsEnvironment} = useFlags()

    const userHasLaunchDarklyFlag = ['beta', 'release', 'post-release'].includes(
        multiEntityTagBudgetsEnvironment,
    )

    const isEnabled = userHasLaunchDarklyFlag ?? false
    const isLoading = multiEntityTagBudgetsEnvironment === undefined

    return {
        isEnabled,
        isLoading,
    }
}

export const useIsDownloadEnabled = () => {
    const {multiEntityTagBudgets: multiEntityTagBudgetsEnvironment} = useFlags()

    const userHasLaunchDarklyFlag = ['download', 'release', 'post-release'].includes(
        multiEntityTagBudgetsEnvironment,
    )

    const isEnabled = userHasLaunchDarklyFlag ?? false
    const isLoading = multiEntityTagBudgetsEnvironment === undefined

    return {
        isEnabled,
        isLoading,
    }
}
