import {Trans} from '@lingui/macro'
import styled from 'styled-components'

import {Button, tokens} from '@pleo-io/telescope'
import {ArrowLeft} from '@pleo-io/telescope-icons'

import {StaticTransferDetailsModalContent} from '@product-web/feature--funds-management/static-transfer-details-modal/static-transfer-details'

const Content = styled.div`
    padding-top: ${tokens.spacing32};
    min-height: 300px;
`

const BackButton = styled(Button)`
    position: absolute;
    top: ${tokens.spacing40};
    left: ${tokens.spacing40};
`

interface ModalViewTransferProps {
    onClose: () => void
    onBack: () => void
    onProceed: () => void
}

const ModalViewTransfer = ({onClose, onBack, onProceed}: ModalViewTransferProps) => {
    return (
        <Content>
            <BackButton IconLeft={ArrowLeft} variant="tertiary" onClick={onBack}>
                <Trans>Back</Trans>
            </BackButton>

            <StaticTransferDetailsModalContent closeModal={onClose} hideFirstTransferHelp={true}>
                <Button variant="primary" onClick={onProceed}>
                    <Trans>I've transferred it</Trans>
                </Button>
            </StaticTransferDetailsModalContent>
        </Content>
    )
}

export default ModalViewTransfer
