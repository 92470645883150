import {createBffHooks} from '@product-web/shared--bff-client'

import type {
    AccountingEntryFees,
    AddAttendeePayload,
    AsyncExportStatus,
    BulkEditOptions,
    CategoryPickerOptions,
    DirectReimbursementType,
    ExportAccountCategory,
    ExportRouter,
    ExportTableExpense as Expense,
    ExportTableExpenseType as ExpenseType,
    ExportTagGroup,
    FilterQueryParams,
    InitiatedBy,
    NoResultsContent,
    ReimbursementAmount,
    ReimbursementDetailsResponse,
    ReimbursementEmployee,
    ReportTableExpense as ReportExpense,
    RepresentationAccounts,
    RepresentationLines,
    TriggerAsyncExportInput,
    UpdateExpensePayload,
} from './index.bff'

export type {
    Expense,
    ExpenseType,
    ReportExpense,
    NoResultsContent,
    FilterQueryParams,
    AsyncExportStatus,
    TriggerAsyncExportInput,
    UpdateExpensePayload,
    BulkEditOptions,
    CategoryPickerOptions,
    ExportAccountCategory,
    ExportTagGroup,
    ReimbursementEmployee,
    ReimbursementAmount,
    DirectReimbursementType,
    InitiatedBy,
    ReimbursementDetailsResponse,
    RepresentationAccounts,
    RepresentationLines,
    AddAttendeePayload,
    AccountingEntryFees,
}

export const bffHooks = createBffHooks<ExportRouter>()
export const bff = bffHooks.exportV2
