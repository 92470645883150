import {t, Trans} from '@lingui/macro'
import {Formik} from 'formik'
import type React from 'react'

import {
    Button,
    FormikInput as Input,
    Inline,
    Link,
    ModalActions,
    ModalContent,
    ModalFooter,
    ModalTitle,
    Text,
} from '@pleo-io/telescope'
import {ArrowRight} from '@pleo-io/telescope-icons'

import {getHelpCentreArticleLinkIntercom} from '@product-web/shared--locale/helpers'

import * as s from '../add-member-modal/add-member-modal.styles'

interface FormValues {
    firstName: string
    email: string
    reimbursementOnly: boolean
}

interface AddInternalBookkeeperProps {
    validate: (values: FormValues) => any
    onSubmit: (values: FormValues) => Promise<void>
    onCancel: () => void
    onAddExternalBookkeeper: () => void
}

export const AddInternalBookkeeper: React.FC<
    React.PropsWithChildren<AddInternalBookkeeperProps>
> = (props) => {
    const initialValues: FormValues = {
        firstName: '',
        email: '',
        reimbursementOnly: false,
    }

    return (
        <Formik
            initialValues={initialValues}
            validate={props.validate}
            onSubmit={async (values) => {
                await props.onSubmit(values)
            }}
        >
            {(formik) => (
                <>
                    <ModalTitle>
                        <Trans>Invite member to setup accounting</Trans>
                    </ModalTitle>
                    <form onSubmit={formik.handleSubmit}>
                        <ModalContent>
                            <s.FormDescription>
                                <Trans>
                                    They will have administrator access to your Pleo account.{' '}
                                    <Link
                                        href={getHelpCentreArticleLinkIntercom(
                                            '412748-set-up-more-pleo-administrators',
                                        )}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        Read more
                                    </Link>
                                </Trans>
                            </s.FormDescription>
                            <Inline space={16}>
                                <Input
                                    name="firstName"
                                    placeholder={t`First name`}
                                    aria-label={t`First name`}
                                    variant="bordered"
                                />
                                <Input
                                    name="email"
                                    placeholder={t`Email`}
                                    aria-label={t`Email`}
                                    variant="bordered"
                                    onChange={(event) =>
                                        formik.setFieldValue('email', event.target.value.trim())
                                    }
                                />
                            </Inline>
                        </ModalContent>
                        <ModalActions>
                            <Inline space={16}>
                                <Button
                                    id="submit"
                                    type="submit"
                                    variant="primary"
                                    loading={formik.isSubmitting}
                                    data-testid="invite-button"
                                    disabled={
                                        !(formik.isValid && formik.dirty) && !formik.isSubmitting
                                    }
                                >
                                    <Trans>Invite</Trans>
                                </Button>
                                <Button
                                    id="cancel"
                                    type="button"
                                    variant="secondary"
                                    disabled={formik.isSubmitting}
                                    onClick={props.onCancel}
                                >
                                    <Trans>Cancel</Trans>
                                </Button>
                            </Inline>
                        </ModalActions>
                    </form>
                    <ModalFooter align="center">
                        <Inline align="center center" space={4}>
                            <Text>
                                <Trans>External bookkeeper?</Trans>
                            </Text>
                            &nbsp;
                            <Button
                                variant="tertiary"
                                id="bookkeeper-link"
                                onClick={props.onAddExternalBookkeeper}
                                IconRight={ArrowRight}
                            >
                                <Trans>Give them access</Trans>
                            </Button>
                        </Inline>
                    </ModalFooter>
                </>
            )}
        </Formik>
    )
}

export default AddInternalBookkeeper
