import {Trans} from '@lingui/macro'
import type {ReactNode} from 'react'
import {styled} from 'styled-components'

import {Card, Stack, Text, tokens} from '@pleo-io/telescope'
import {VisualCard} from '@pleo-io/telescope-pictograms'

import {AppStoreBadge, PlayStoreBadge} from '@product-web/feature--ui-store-badges'
import {pxBreakpoints} from '@product-web/shared--styles/theme'
import {useContainerQuery} from '@product-web/shared--telescope-lab/container-queries/container'
import {
    getMobilePlatform,
    MobilePlatform,
} from '@product-web/shared--web-platform/platform-detection'

import qrAndroidDownload from '../images/qr-android-download.svg'
import qrAppleDownload from '../images/qr-apple-download.svg'

export const DownloadMobileAppCard = () => {
    const isLargeScreen = useContainerQuery({minWidth: pxBreakpoints.mobileLrgUp, name: 'main'})
    const mobilePlatform = getMobilePlatform()
    const isAndroidOrIos =
        mobilePlatform === MobilePlatform.ANDROID || mobilePlatform === MobilePlatform.IOS

    if (!isAndroidOrIos) {
        return (
            <QrCardsContainer>
                <QrCardWrapper isLargeScreen={isLargeScreen}>
                    <PlatformTitle>
                        <Trans>Android</Trans>
                    </PlatformTitle>
                    <QrImg src={qrAndroidDownload} width={isLargeScreen ? undefined : '100%'} />

                    <PlayStoreBadge width={120} />
                </QrCardWrapper>

                <QrCardWrapper isLargeScreen={isLargeScreen}>
                    <PlatformTitle>
                        <Trans>Apple iOS</Trans>
                    </PlatformTitle>
                    <QrImg src={qrAppleDownload} width={isLargeScreen ? undefined : '100%'} />
                    <AppStoreBadge width={120} />
                </QrCardWrapper>
            </QrCardsContainer>
        )
    }

    return (
        <Card>
            <Card.Body>
                <Stack space={16}>
                    <VisualCard size="40" />
                    <Text>
                        <Trans>Pay contactless and add receipts on the go.</Trans>
                    </Text>
                    {mobilePlatform === MobilePlatform.ANDROID ? (
                        <PlayStoreBadge height={36} />
                    ) : null}
                    {mobilePlatform === MobilePlatform.IOS ? <AppStoreBadge height={36} /> : null}
                </Stack>
            </Card.Body>
        </Card>
    )
}

const QrCardWrapper = ({
    isLargeScreen,
    children,
}: {
    isLargeScreen: boolean
    children: ReactNode
}) => {
    return (
        <Card css={{width: isLargeScreen ? 'auto' : '100%'}}>
            <Card.Body>
                <Stack alignItems="center">{children}</Stack>
            </Card.Body>
        </Card>
    )
}

const PlatformTitle = styled(Text).attrs({color: 'colorContentStatic', weight: 'medium'})`
    margin-bottom: ${tokens.spacing12};
`

const QrImg = styled.img.attrs(({height, width}) => ({
    alt: '',
    height: !height && !width ? '120' : height,
    width: !height && !width ? '120' : width,
}))`
    margin-bottom: ${tokens.spacing16};
`

const QrCardsContainer = styled.div`
    display: flex;
    gap: ${tokens.spacing24};
    flex-wrap: wrap;
`
