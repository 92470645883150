import {t} from '@lingui/macro'
import {useFormikContext} from 'formik'
import {useCallback, useEffect, useState} from 'react'

import {
    type AccountCategory,
    type AccountCategoryTypeKey,
    NO_SUITABLE_ACCOUNT_CATEGORY_ID,
    NO_SUITABLE_ACCOUNT_ID,
} from '@pleo-io/deimos'
import {Stack} from '@pleo-io/telescope'

import {CategoryIcon} from '@product-web/feature--ui-account-categories/category-icon'
import {useAccountCategories} from '@product-web/shared--api-deimos/account-categories'
import {getLabelForAccount} from '@product-web/shared--api-deimos/account-categories/helpers'
import type {SelectOptions} from '@product-web/shared--telescope-lab/select'
import type {SaveState} from '@product-web/shared--telescope-lab/spinner/types'
import {useIsCompanyBookkeeper, useIsCompanyOwner} from '@product-web/shared--user'

import ExpensePropertyEditor from './expense-property-editor/expense-property-editor'
import {CategoryAccessType, getCategoryAccessLevel} from './helpers'
import type {Track} from './types'

import type {Expense} from '../index.bff'

const mapCategoriesToOptions = (
    accountCategories: AccountCategory[],
    isPrivilegedUser: boolean,
    expenseAccountId?: string,
    selectedAccount?: string | null,
): SelectOptions => {
    const mappedOptions: SelectOptions = accountCategories.flatMap((accountCategory) => {
        const categoryAccessType = getCategoryAccessLevel(
            accountCategory,
            isPrivilegedUser,
            expenseAccountId ?? '',
        )
        const isSelectedCategory =
            !!selectedAccount &&
            !!accountCategory?.accounts?.find((account) => account.id === selectedAccount)
        const isCategoryRestricted =
            categoryAccessType === CategoryAccessType.NO_ACCESS ||
            (accountCategory.hidden && !isSelectedCategory) ||
            !accountCategory?.accounts?.length

        if (isCategoryRestricted) {
            return []
        }

        return {
            label: accountCategory.name?.trim() === '' ? t`Untitled` : accountCategory.name,
            options: accountCategory.accounts!.flatMap((account) => {
                const accountRestrictedToUser =
                    categoryAccessType === CategoryAccessType.LIMITED_ACCESS &&
                    account.id !== expenseAccountId

                if (
                    accountRestrictedToUser ||
                    ((account.hidden || accountCategory.hidden) &&
                        selectedAccount !== account.id) ||
                    account.name.trim() === ''
                ) {
                    return []
                }

                return {
                    value: account.id,
                    label: getLabelForAccount(account),
                    icon: <CategoryIcon imgkey={accountCategory.typeKey} />,
                }
            }),
        }
    })

    return mappedOptions || []
}

export const getImgKey = (value: string | null, accountCategories: AccountCategory[]) => {
    const newImgKey = accountCategories.find((accountCategory) =>
        accountCategory?.accounts?.find((account) => account.id === value),
    )?.typeKey
    return newImgKey ?? 'empty'
}

interface CategoryEditorProps {
    isLocked?: boolean
    expense?: Expense
    onChange: (newVal: string | null) => void
    value: string | null
    track?: Track<string>
    saveState?: SaveState
    showRequiredBadge?: boolean
}

export function CategoryEditor({
    isLocked,
    expense,
    onChange,
    value,
    track,
    saveState,
    showRequiredBadge,
}: CategoryEditorProps) {
    const isOwner = useIsCompanyOwner()
    const isBookkeeper = useIsCompanyBookkeeper()
    const isPrivilegedUser = isOwner || isBookkeeper
    const [previousCategory, setPreviousCategory] = useState<string | null>(value)

    const expenseCompanyId = expense?.companyId
    const {data: accountCategoriesData = []} = useAccountCategories({expenseCompanyId})
    const [imgKey, setImgKey] = useState<AccountCategoryTypeKey | 'empty'>('empty')

    const isNoSuitableAccountSelected = value === NO_SUITABLE_ACCOUNT_ID
    const accountCategories = isNoSuitableAccountSelected
        ? accountCategoriesData
        : accountCategoriesData.filter(
              (category) => category.id !== NO_SUITABLE_ACCOUNT_CATEGORY_ID,
          )
    const options = mapCategoriesToOptions(
        accountCategories,
        isPrivilegedUser,
        expense?.accountId,
        value,
    )
    useEffect(() => {
        const defaultImgKey = getImgKey(value, accountCategories)
        setImgKey(defaultImgKey)
    }, [accountCategories.length])

    const categoryListViewedTracking = useCallback(() => {
        track?.({
            action: 'viewed',
            field: 'account',
        })
    }, [track])

    const onChangeCategory = (newValue: string | null) => {
        const newImgKey = getImgKey(newValue, accountCategories)
        setImgKey(newImgKey)
        track?.({
            action: 'changed',
            field: 'account',
            eventProps: {new_value: newValue, previous_value: previousCategory},
        })
        setPreviousCategory(newValue)
        onChange(newValue)
    }

    return (
        <Stack space={12}>
            <ExpensePropertyEditor
                value={value}
                onChange={onChangeCategory}
                icon={
                    <CategoryIcon
                        imgkey={imgKey}
                        color={imgKey === 'empty' ? 'inherit' : undefined}
                    />
                }
                options={options}
                placeholder={t`Category`}
                data-testid="category-editor"
                onOpen={categoryListViewedTracking}
                disabled={isLocked}
                saveState={saveState}
                showRequiredLabel={!value && showRequiredBadge}
            />
        </Stack>
    )
}

interface FormikCategoryEditorProps<T> {
    isLocked?: boolean
    expense?: Expense
    track?: Track<string>
    fieldName: T
}

export function FormikCategoryEditor<
    FieldName extends string,
    U extends {[T in FieldName]: string},
>({isLocked, expense, track, fieldName}: FormikCategoryEditorProps<FieldName>) {
    const {values, setFieldValue} = useFormikContext<U>()

    return (
        <CategoryEditor
            isLocked={isLocked}
            expense={expense}
            track={track}
            value={values[fieldName]}
            onChange={(newValue: string | null) => {
                setFieldValue(fieldName, newValue)
            }}
        />
    )
}
