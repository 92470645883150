import React from 'react'

import tracking from '@product-web/shared--analytics'
import {reportError} from '@product-web/shared--error/report'

import {bffHooks} from './bff-hooks'
import {useWalletModalContext} from './wallet-modal-context'

export function useBankTransferModal() {
    const {openWalletModal} = useWalletModalContext()
    const {
        data,
        refetch: refreshData,
        isFetching,
    } = bffHooks.featureFundsManagement.bankTransferModal.getData.useQuery()
    const isBankVerificationDetailsRequired = Boolean(data?.isBankVerificationDetailsRequired)

    const [bankTransferModalOptions, setBankTransferModalOptions] = React.useState<{
        onBackClick?: () => void
    }>({})

    const openBankTransferModal = React.useCallback(
        (options?: {onBackClick?: () => void}) => {
            tracking.walletBankTransferModalViewed()
            setBankTransferModalOptions(options || {})

            if (isBankVerificationDetailsRequired) {
                openWalletModal('bankVerification')
            } else {
                openWalletModal('staticTransferDetails')
            }
        },
        [isBankVerificationDetailsRequired, openWalletModal],
    )

    const onBankVerificationDone = React.useCallback(async () => {
        try {
            await refreshData()
            openWalletModal('staticTransferDetails')
        } catch (err) {
            reportError(err)
        }
    }, [openWalletModal, refreshData])

    return {
        openBankTransferModal,
        bankTransferModalOptions,
        onBankVerificationDone,
        isFetching,
    }
}
