import {t, Trans} from '@lingui/macro'
import React from 'react'
import styled from 'styled-components'

import type {BannerVariant} from '@pleo-io/telescope'
import {Banner, Inline, Link, tokens} from '@pleo-io/telescope'

import {breakpoints} from '@product-web/shared--styles/theme'

import type {ComponentWithOutage} from './index.bff'

export type StatusPageComponentName = ComponentWithOutage['name']
export type ComponentOutageStatus = ComponentWithOutage['status']

type OutageComponentInfo = {
    isReported: boolean
    name: StatusPageComponentName
    status: ComponentOutageStatus
}
type OutageBannerProps = {components: OutageComponentInfo[]}
export function OutageBanner(props: OutageBannerProps) {
    const [isClosed, close] = React.useReducer(() => true, false)

    if (isClosed || !props.components.length) {
        return null
    }

    const bannerVariant = getOutageBannerVariant(props.components)

    return (
        <OutageBannerContainer data-testId="status-page-info">
            <Banner variant={bannerVariant} loud>
                <Banner.Content>
                    <Inline alignItems="center" space={10}>
                        <Inline css={{flex: 1}} space={10} alignItems="center">
                            <OutageMessageText components={props.components} />
                        </Inline>
                        <Link
                            target="_blank"
                            href="https://status.pleo.io"
                            rel="noopener noreferrer"
                            css={{whiteSpace: 'nowrap'}}
                        >
                            <Trans>See status</Trans>
                        </Link>
                    </Inline>
                </Banner.Content>
                <Banner.CloseButton onClick={close} aria-label={t`Close`} />
            </Banner>
        </OutageBannerContainer>
    )
}

// We take the most severe status in all outage components and base the banner accent color
// on it - i.e. if there is at least one component with a major outage, the message is red.
function getOutageBannerVariant(components: OutageComponentInfo[]) {
    const outageStatusBannerVariant: Record<ComponentOutageStatus, BannerVariant> = {
        degraded_performance: 'warning',
        partial_outage: 'warning',
        major_outage: 'negative',
        under_maintenance: 'warning',
    }
    const statuses = components.map((component) => component.status)
    if (statuses.includes('major_outage')) {
        return outageStatusBannerVariant.major_outage
    }
    if (statuses.includes('partial_outage')) {
        return outageStatusBannerVariant.partial_outage
    }
    if (statuses.includes('under_maintenance')) {
        return outageStatusBannerVariant.under_maintenance
    }
    return outageStatusBannerVariant.degraded_performance
}

// The message text view logic is:
// - Show a detailed message (component name, description, status) for every component registered
//   to be reported that is currently experiencing issues
// - Show a single generic message for any number of non-reported components that currently
//   experience issues. If there are also reported components, show both.
function OutageMessageText(props: {components: OutageComponentInfo[]}) {
    const componentsInfo = getComponentsInfo()
    const outageStatusMessages = getOutageStatusMessages()
    const reportedComponentsInfo = props.components
        .filter((component) => component.isReported)
        .map((component) => {
            const statusMessage = outageStatusMessages[component.status]
            const [componentTitle, componentDescription] = componentsInfo[component.name]
            return {
                componentName: component.name,
                statusMessage,
                componentTitle,
                componentDescription,
            }
        })

    return (
        <Banner.Text>
            {reportedComponentsInfo.map((info) => (
                <div key={info.componentName}>
                    {info.componentTitle} ({info.componentDescription}):{' '}
                    <strong>{info.statusMessage}</strong>
                </div>
            ))}
            {reportedComponentsInfo.length < props.components.length ? (
                <div>
                    {reportedComponentsInfo.length ? (
                        <Trans>
                            Some other functionalities are currently unavailable due to a technical
                            issue.
                        </Trans>
                    ) : (
                        <Trans>
                            Some functionalities are currently unavailable due to a technical issue.
                        </Trans>
                    )}
                </div>
            ) : null}
        </Banner.Text>
    )
}

const OutageBannerContainer = styled(Inline)`
    font-size: ${tokens.fontMedium};

    @media (max-width: ${breakpoints.tabletMedUp}) {
        position: fixed;
        bottom: 0;
        font-size: ${tokens.fontSmall};
        background: ${tokens.shade000};
        z-index: ${tokens.zIndexSurface};
        width: 100%;
    }
`

const getOutageStatusMessages: () => Record<ComponentOutageStatus, string> = () => ({
    degraded_performance: t`Degraded performance`,
    partial_outage: t`Partial outage`,
    major_outage: t`Major outage`,
    under_maintenance: t`Under maintenance`,
})

const getComponentsInfo: () => Record<StatusPageComponentName, [string, string]> = () => ({
    analytics: [t`Analytics`, t`Ability to report on company spendings`],
    api: [t`Pleo API`, t`Pleo's API used by integration partners`],
    card: [t`Cards`, t`Ability to manage cards or create new cards`],
    datev: [t`DATEV`, t`Ability to export expenses to DATEV`],
    dinero: [t`Dinero`, t`Ability to export expenses to Dinero`],
    economic: [t`e-conomic`, t`Ability to export expenses to e-conomic`],
    expenseCreation: [t`Expense Creation`, t`Ability to see new transactions in the app`],
    integrations: [t`Integrations`, t`Ability to export expenses to third-party services`],
    invoices: [
        // This string refers to the Pleo Invoices brand and should not be translated
        // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
        'Invoices',
        t`Invoices payments`,
    ],
    mobileApp: [t`Mobile App`, t`Pleo mobile application`],
    partnerPortal: [t`Partner Portal`, t`Ability to access Partner Portal features`],
    pocket: [
        t`Reimbursements`,
        t`Adding and editing Pleo out-of-pocket expenses. Employee balance calculation`,
    ],
    push: [t`Push notifications`, t`Ability to send push notifications to mobile apps`],
    quickbooks: [t`Quickbooks`, t`Ability to Export expenses to Quickbooks Online`],
    reimbursements: [
        t`Reimbursements`,
        t`Reimbursements to personal cards and employee balance settlement by admins/bookkeepers`,
    ],
    signup: [t`Sign up`, t`Ability to on-board new companies`],
    transactions: [t`Transactions`, t`Ability to complete transactions with a Pleo card`],
    wallet: [t`Wallet`, t`Ability to add funds to your Pleo wallet and general card management`],
    webApp: [t`Web App`, t`Pleo web application`],
    xero: [t`Xero`, t`Ability to export expenses to Xero`],
})
