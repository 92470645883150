import {t} from '@lingui/macro'
import type React from 'react'
import {useNavigate} from 'react-router'

import {Inline, NakedButton, Text, tokens, Tooltip} from '@pleo-io/telescope'
import {Check, Download, Options, Pen, Trash} from '@pleo-io/telescope-icons'

import {useIsDownloadEnabled} from '@product-web/feature--budgets/budget-feature-flags'
import DropdownMenu from '@product-web/shared--telescope-lab/dropdown-menu/dropdown-menu'

import type {TagBudgetDetails} from '../../index.bff'
import {useTagBudgetModals} from '../../lib/use-tag-budget-modals'
import {useTagBudgetTracking} from '../../lib/use-tag-budget-tracking'
import {TagBudgetCloseEarlyModal} from '../tag-budget-close-early-modal'
import {TagBudgetDeleteModal} from '../tag-budget-delete-modal'
import {TagBudgetDownloadExpensesModal} from '../tag-budget-download-expenses-modal'

const MenuItem: React.FC<
    React.PropsWithChildren<{label: string; icon: JSX.Element; onSelect: () => void}>
> = ({label, icon, onSelect}) => (
    <DropdownMenu.Item onSelect={onSelect}>
        <Inline alignY="center" space={8}>
            {icon}
            <Text variant="medium-default" weight="regular" color="shade800">
                {label}
            </Text>
        </Inline>
    </DropdownMenu.Item>
)

export const TagBudgetMenu: React.FC<
    React.PropsWithChildren<{
        budget: TagBudgetDetails
        isDemoBudget: boolean
    }>
> = ({budget, isDemoBudget}) => {
    const navigate = useNavigate()
    const tracking = useTagBudgetTracking()
    const {modals} = useTagBudgetModals()
    const {isEnabled: hasDownloadsFlag, isLoading: isLoadingFeatureFlag} = useIsDownloadEnabled()
    const isLoadingData = isLoadingFeatureFlag
    const shouldShowDownloadExpenses = hasDownloadsFlag && budget.sumSpent > 0

    const navigateToBudget = () => {
        navigate(`/budgets/${budget.id}/edit`)
    }

    const onDownloadExpenses = () => {
        modals.downloadExpenses.open()
    }

    const openCloseEarlyModal = () => {
        modals.closeEarly.open()
    }

    const openDeleteModal = () => {
        modals.deleteBudget.open()
    }

    if (isLoadingData) {
        return null
    }

    if (isDemoBudget) {
        return (
            <Tooltip
                content={t`After you create a budget, you will be able to edit it or download its expenses`}
            >
                <NakedButton
                    data-testid="tag-budget-menu-button"
                    onMouseEnter={() => {
                        tracking.demoBudgetActioned({
                            action: 'hovered',
                            source: 'budget_actions_menu',
                        })
                    }}
                >
                    <Options />
                </NakedButton>
            </Tooltip>
        )
    }

    return (
        <>
            <DropdownMenu>
                <DropdownMenu.Trigger>
                    <NakedButton data-testid="tag-budget-menu-button">
                        <Options />
                    </NakedButton>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content
                    data-testid="tag-budget-menu-content"
                    sideOffset={10}
                    align="end"
                >
                    <MenuItem
                        label={t`Edit`}
                        icon={<Pen size={16} color={tokens.shade800} />}
                        onSelect={navigateToBudget}
                    />
                    {!budget.isClosed && (
                        <MenuItem
                            label={t`Close budget early`}
                            icon={<Check size={16} color={tokens.shade800} />}
                            onSelect={openCloseEarlyModal}
                        />
                    )}
                    {shouldShowDownloadExpenses && (
                        <MenuItem
                            label={t`Download expenses`}
                            icon={<Download size={16} color={tokens.shade800} />}
                            onSelect={onDownloadExpenses}
                        />
                    )}
                    <MenuItem
                        label={t`Delete`}
                        icon={<Trash size={16} color={tokens.shade800} />}
                        onSelect={openDeleteModal}
                    />
                </DropdownMenu.Content>
            </DropdownMenu>

            <TagBudgetCloseEarlyModal
                budgetId={budget.id!}
                source="overview"
                isOpen={modals.closeEarly.isOpen}
                onConfirm={modals.closeEarly.onConfirm}
                onCancel={modals.closeEarly.onCancel}
            />
            {shouldShowDownloadExpenses && (
                <TagBudgetDownloadExpensesModal
                    budgetId={budget.id!}
                    budgetName={budget.name!}
                    isOpen={modals.downloadExpenses.isOpen}
                    onCancel={modals.downloadExpenses.onCancel}
                    onClose={modals.downloadExpenses.onConfirm}
                />
            )}
            <TagBudgetDeleteModal
                budgetId={budget.id!}
                isOpen={modals.deleteBudget.isOpen}
                onConfirm={modals.deleteBudget.onConfirm}
                onCancel={modals.deleteBudget.onCancel}
            />
        </>
    )
}
