import React from 'react'
import {useSearchParams} from 'react-router-dom'

const WALLET_MODAL_NAMES = [
    'openBanking',
    'vrpAutoTopUp',
    'pleoReserve',
    'topUpMethod',
    'createBankAccountViaPlaid',
    'topUpUsaWallet',
    'bankVerification',
    'staticTransferDetails',
    'balanceWarning',
    'balanceStatement',
    'balanceMismatch',
    'overdraftConfirmation',
    'overdraftLimitUpdated',
    'overdraftRenewed',
    'subWalletCreated',
    'moveMoney',
    'overdraftOnboardingActivationConfirmation',
] as const

type WalletModalName = (typeof WALLET_MODAL_NAMES)[number]

const isWalletModalName = (name: string | null): name is WalletModalName =>
    WALLET_MODAL_NAMES.includes(name as WalletModalName)

interface WalletModalContextSchema {
    openedWalletModal: WalletModalName | null
    openWalletModal: (modalType: WalletModalName) => void
    closeWalletModal: () => void
    isWalletModalOpen: boolean
}

const WalletModalContext = React.createContext<WalletModalContextSchema>({
    openedWalletModal: null,
    isWalletModalOpen: false,
    openWalletModal: () => {},
    closeWalletModal: () => {},
})

export const WalletModalContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
    children,
}) => {
    const [openedWalletModal, setOpenedWalletModal] = React.useState<WalletModalName | null>(null)

    const openWalletModal = React.useCallback((modalType: WalletModalName) => {
        setOpenedWalletModal(modalType)
    }, [])
    const closeWalletModal = React.useCallback(() => {
        setOpenedWalletModal(null)
    }, [])

    const [searchParams, setSearchParams] = useSearchParams()
    React.useEffect(() => {
        const modalName = searchParams.get('openModal')
        if (isWalletModalName(modalName)) {
            openWalletModal(modalName)
            // remove search param from URL
            searchParams.delete('openModal')
            setSearchParams(searchParams, {replace: true})
        }
    }, [openWalletModal, searchParams, setSearchParams])

    const contextValue: WalletModalContextSchema = React.useMemo(
        () => ({
            openedWalletModal,
            openWalletModal,
            closeWalletModal,
            isWalletModalOpen: Boolean(openedWalletModal),
        }),
        [closeWalletModal, openWalletModal, openedWalletModal],
    )

    return (
        <WalletModalContext.Provider value={contextValue}>{children}</WalletModalContext.Provider>
    )
}

export function useWalletModalContext() {
    return React.useContext(WalletModalContext)
}
