import {useCallback} from 'react'

import {useFlags, useFlagsLoaded} from '@product-web/shared--flags'
import {isValuePresentInCommaSeparatedString} from '@product-web/shared--utils'

type TagBasedReviewFlag = ReturnType<typeof useFlags>['tagBasedReview']

export type ReviewManagementMigrationFlag = ReturnType<typeof useFlags>['reviewManagementMigration']

export function useIsTagReviewFlagEnabled(flag: TagBasedReviewFlag & string) {
    const hasLoadedFeatureFlags = useFlagsLoaded()

    const {tagBasedReview: enabledMigrationFlags} = useFlags()

    const isFlagEnabled = useCallback(
        (targetFlag: TagBasedReviewFlag) =>
            isValuePresentInCommaSeparatedString<TagBasedReviewFlag>({
                value: targetFlag,
                commaSeparatedString: enabledMigrationFlags,
            }),
        [enabledMigrationFlags],
    )

    return {
        isLoading: !hasLoadedFeatureFlags,
        isEnabled: isFlagEnabled(flag),
    }
}

/**
 * This hook is used to check if a specific review management migration flag is enabled.
 *
 * @example
   ```typescript
   import {useReviewManagementMigrationFlag} from './review-feature-flags/review-feature-flags';

   // Use the hook & read `isEnabled` to determine whether or not it is enabled.
   const {isEnabled: isTeamMigrationFlagEnabled} = useReviewManagementMigrationFlag('TEAM_REVIEW');
 * ```
 */

type UseReviewManagementMigrationFlag = (flag?: string) => {
    isLoading: boolean
    isEnabled: boolean
}

export const useReviewManagementMigrationFlag: UseReviewManagementMigrationFlag = () => {
    return {
        isLoading: false,
        isEnabled: true,
    }
}

/**
 * This hook is used to check if a specific variant is returned for the "Sequential review" feature flag.
 *
 * @see https://app.launchdarkly.com/projects/default/flags/sequential-review
 * @example
   ```typescript
   import {useReviewManagementMigrationFlag} from './review-feature-flags/review-feature-flags';

   // Use the hook & read `isEnabled` to determine whether or not it is enabled.
   const {isEnabled: isTeamMigrationFlagEnabled} = useReviewManagementMigrationFlag('TEAM_REVIEW');
 * ```
 */
export function useSequentialReviewEnabled() {
    const isLoadingFeatureFlags = useFlagsLoaded() === false

    const {sequentialReview} = useFlags()

    if (isLoadingFeatureFlags) {
        return {
            isLoading: true,
            enabledAndToggledOn: false,
            availableAndCanBeEnabledByCompany: false,
        }
    }

    return {
        isLoading: false,
        availableAndCanBeEnabledByCompany:
            sequentialReview === 'AVAILABLE_AND_CAN_BE_ENABLED_BY_COMPANY',
        enabledAndToggledOn: sequentialReview === 'ENABLED_AND_TOGGLED_ON',
    } as const
}

export function useIsInFlightAdjustmentsEnabledFeatureFlag() {
    const isLoadingFeatureFlags = useFlagsLoaded() === false

    const {inFlightAdjustmentsFeatures} = useFlags()

    if (isLoadingFeatureFlags) {
        return {
            isLoading: true,
            addEnabled: false,
            removeEnabled: false,
        }
    }

    return {
        isLoading: false,
        addEnabled:
            inFlightAdjustmentsFeatures === 'ADD' || inFlightAdjustmentsFeatures === 'ADD_REMOVE',
        removeEnabled:
            inFlightAdjustmentsFeatures === 'REMOVE' ||
            inFlightAdjustmentsFeatures === 'ADD_REMOVE',
    }
}

export function useGjollToFetchReceiptsInExpenseDetailsFeatureFlag() {
    const isLoadingFeatureFlags = useFlagsLoaded() === false

    const {useGjollToFetchReceiptsInExpenseDetails} = useFlags()

    if (isLoadingFeatureFlags) {
        return {
            isLoading: true,
            isEnabled: false,
        }
    }

    return {
        isLoading: false,
        isEnabled: useGjollToFetchReceiptsInExpenseDetails,
    }
}
