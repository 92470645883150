import {t} from '@lingui/macro'

import {Inline, Stack, Text, tokens} from '@pleo-io/telescope'
import {Check, Close} from '@pleo-io/telescope-icons'

import type {
    BillingInfoCurrency,
    CpqRatePlanType,
} from '@product-web/shared--bff-moons/generated/beyond'
import {SupportedLanguage} from '@product-web/shared--i18n'
import type {PlanConfiguration} from '@product-web/shared--plan-presentation/pricing-plans.helper'
import {
    getArrayDifference,
    getPlanContentConfiguration,
    renderUpToCashbackLine,
    renderUpToCreditLine,
} from '@product-web/shared--plan-presentation/pricing-plans.helper'
import {breakpoints} from '@product-web/shared--styles/theme'
import {useUser} from '@product-web/shared--user'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

// Types
export type BaseEntitlementsSectionProps = {
    targetPlan: {planType: CpqRatePlanType; isUpgrade: boolean}
    currentPlan: CpqRatePlanType
    companyCurrency: BillingInfoCurrency
    isBillingAnnual: boolean
    hasCurrentSubscriptionCashback?: boolean
    additionalEntitlements?: string[]
}

type GetListOfEntitlementsArgs = {
    targetPlan: {planType: CpqRatePlanType; isUpgrade: boolean}
    currentPlan: CpqRatePlanType
    companyCurrency: BillingInfoCurrency
    locale: SupportedLanguage
    hasCurrentSubscriptionCashback?: boolean
    isBillingAnnual: boolean
    additionalEntitlements?: string[]
}

type GetEntitlementLinesArgs = {
    planConfiguration: PlanConfiguration
    currency: BillingInfoCurrency
    locale: SupportedLanguage
}

export const BaseEntitlementsSection = ({
    targetPlan,
    currentPlan,
    companyCurrency,
    isBillingAnnual,
    hasCurrentSubscriptionCashback,
    additionalEntitlements,
}: BaseEntitlementsSectionProps) => {
    const user = useUser()
    const locale = user?.language ?? SupportedLanguage.EN
    const baseEntitlements = getListOfEntitlements({
        targetPlan,
        currentPlan,
        companyCurrency,
        locale,
        hasCurrentSubscriptionCashback,
        isBillingAnnual,
        additionalEntitlements,
    })

    const isMobile = useMediaQuery(`(max-width: ${breakpoints.tabletUp})`)
    const isUpgrade = targetPlan.isUpgrade

    const baseEntitlementsLeft = baseEntitlements.slice(0, Math.ceil(baseEntitlements.length / 2))
    const baseEntitlementsRight = baseEntitlements.slice(Math.ceil(baseEntitlements.length / 2))

    if (isMobile) {
        return (
            <>
                <Text variant="xlarge-accent" weight="medium">
                    {isUpgrade ? t`By upgrading, you'll get` : t`By downgrading, you'll lose`}
                </Text>
                <Inline paddingTop={16} paddingBottom={24}>
                    <Stack space={12}>
                        {baseEntitlements.map((entitlement, index) => (
                            <EntitlementLine
                                key={`${index}-${entitlement}`}
                                entitlement={entitlement}
                                isUpgrade={isUpgrade}
                            />
                        ))}
                    </Stack>
                </Inline>
            </>
        )
    }

    return (
        <>
            <Text variant="xlarge-accent" weight="medium">
                {isUpgrade ? t`By upgrading, you'll get` : t`By downgrading, you'll lose`}
            </Text>
            <Inline paddingTop={16} paddingBottom={24}>
                <Stack space={12}>
                    {baseEntitlementsLeft.map((entitlement, index) => (
                        <EntitlementLine
                            key={`${index}-${entitlement}`}
                            entitlement={entitlement}
                            isUpgrade={isUpgrade}
                        />
                    ))}
                </Stack>
                <Stack space={12}>
                    {baseEntitlementsRight.map((entitlement, index) => (
                        <EntitlementLine
                            key={`${index}-${entitlement}`}
                            entitlement={entitlement}
                            isUpgrade={isUpgrade}
                        />
                    ))}
                </Stack>
            </Inline>
        </>
    )
}

const EntitlementLine = ({entitlement, isUpgrade}: {entitlement: string; isUpgrade: boolean}) => (
    <Inline space={4} alignItems="center" css={{width: '280px'}}>
        {isUpgrade ? (
            <Check
                data-testid="entitlement-positive-icon"
                size={16}
                color={tokens.colorContentPositive}
            />
        ) : (
            <Close
                data-testid="entitlement-negative-icon"
                size={16}
                color={tokens.colorContentNegative}
            />
        )}
        <Text data-testid="entitlement-text">{entitlement}</Text>
    </Inline>
)

export const getListOfEntitlements = ({
    targetPlan,
    currentPlan,
    companyCurrency,
    locale,
    hasCurrentSubscriptionCashback,
    isBillingAnnual,
    additionalEntitlements,
}: GetListOfEntitlementsArgs) => {
    const currentPlanConf = getPlanContentConfiguration(currentPlan)
    const targetPlanConf = getPlanContentConfiguration(targetPlan.planType)
    const isUpgrade = targetPlan.isUpgrade
    const isSamePlan = currentPlan === targetPlan.planType

    const baseEntitlements = isSamePlan
        ? currentPlanConf.allEntitlements
        : getArrayDifference(targetPlanConf.allEntitlements, currentPlanConf.allEntitlements)

    if (additionalEntitlements) {
        baseEntitlements.unshift(...additionalEntitlements)
    }
    if (isUpgrade) {
        const {cashbackLine} = getEntitlementLines({
            planConfiguration: targetPlanConf,
            currency: companyCurrency,
            locale,
        })

        // Put cashback line at the top of the list if the billing is annual and the current subscription has cashback
        if (cashbackLine && isBillingAnnual && hasCurrentSubscriptionCashback) {
            baseEntitlements.unshift(cashbackLine)
        }
    } else {
        const {creditLine, cashbackLine} = getEntitlementLines({
            planConfiguration: currentPlanConf,
            currency: companyCurrency,
            locale,
        })

        // Credit line entitlements are shown with the rest of entitlements when downgrading
        if (creditLine) {
            baseEntitlements.unshift(creditLine)
        }

        // Put cashback line at the top of the list if the current subscription has cashback when downgrading
        if (cashbackLine && hasCurrentSubscriptionCashback) {
            baseEntitlements.unshift(cashbackLine)
        }
    }

    return baseEntitlements
}

const getEntitlementLines = ({planConfiguration, currency, locale}: GetEntitlementLinesArgs) => {
    const cashbackLine = renderUpToCashbackLine(planConfiguration.cashback ?? 0)
    const creditLine = renderUpToCreditLine({
        planConfiguration,
        currency,
        locale,
    })

    return {
        cashbackLine,
        creditLine,
    }
}
