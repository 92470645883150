import {t, Trans} from '@lingui/macro'
import {useState} from 'react'

import {Inline, Stack, Text, tokens} from '@pleo-io/telescope'

import type {CpqRatePlanType} from '@product-web/shared--bff-moons/generated/beyond'
import type {CpqBillingPeriodType} from '@product-web/shared--bff-moons/generated/beyond'
import {breakpoints} from '@product-web/shared--styles/theme'
import Wizard, {Step} from '@product-web/shared--telescope-lab/wizard/wizard'
import {useToaster} from '@product-web/shared--toaster'
import {exhaustiveCheck} from '@product-web/shared--utils'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

import {PlanChangeSummary} from './plan-change-summary'
import {DoneStep} from './pricing-modal-done-step'
import {RecommendedPlanInfo} from './recommended-plan-info'

import type {PlansData} from '../../index.bff'
import type {PageOrigin, PageSection} from '../../index.bff'
import type {PlanUpgradeSource} from '../../types/plans'
import {PaymentConfirmationBreakdown} from '../payment-confirmation-breakdown'
import type {LatestTrackingPropsType} from '../pricing-plan-modal'
import {trackPricingPlansModalActioned} from '../tracking'

type RecommendedFlowWizardProps = {
    onDismiss: () => void
    onClickComparePlans: () => void
    planUpgradeSource: PlanUpgradeSource
    pageOrigin: PageOrigin
    pageSection?: PageSection
    plansData?: PlansData
    setLatestTrackingProps?: (trackingData: LatestTrackingPropsType) => void
}

enum STEP {
    Review = 'review',
    Done = 'done',
}

type WizardStepValues = `${STEP}`

export const RecommendedFlowWizard = ({
    onDismiss,
    onClickComparePlans,
    pageOrigin,
    planUpgradeSource,
    pageSection,
    plansData,
    setLatestTrackingProps,
}: RecommendedFlowWizardProps) => {
    const isSmallScreen = useMediaQuery(`(max-width: ${breakpoints.desktopMedUp})`)
    const {showToast} = useToaster()

    const [step, setStep] = useState<WizardStepValues>('review')
    const [isWalletBalanceSufficient, setIsWalletBalanceSufficient] = useState(false)

    const currency = plansData?.currency ?? 'EUR'
    const currentPlan = plansData?.currentPlan
    const isLegacyPlan = !!plansData?.isLegacyPlan
    const hasCashback = plansData?.hasCashback

    const recommendedPlan = (plansData?.migrationRecommendation?.recommendedPlan ??
        'STARTER') as CpqRatePlanType
    const recommendedBillingPeriod: CpqBillingPeriodType =
        plansData?.migrationRecommendation?.recommendedBillingPeriod ?? 'YEAR'
    const freeUserLimit =
        plansData?.plansPricing.find((plan) => plan.planName === recommendedPlan)
            ?.additionalUserBilling[recommendedBillingPeriod]?.freeUserLimit ?? 0
    const migrationType = plansData?.migrationRecommendation?.migrationFlowType

    const handleOnDimissWizard = () => {
        onDismiss()
    }

    const handleOnPaymentError = () => {
        showToast(t`An error occurred. Please try again later or contact support.`, {
            level: 'error',
        })
    }

    const handleContactUs = (planName: CpqRatePlanType) => {
        trackPricingPlansModalActioned({
            action: 'contact_us_clicked',
            step: getTrackingStep(step),
            origin: pageOrigin,
            section: pageSection,
            plan: planName,
        })
    }

    const handleOnConfirmation = ({isWalletSufficient}: {isWalletSufficient: boolean}) => {
        setIsWalletBalanceSufficient(isWalletSufficient)
        setStep('done')
        setLatestTrackingProps?.({
            step: getTrackingStep('done'),
            plan: recommendedPlan,
        })
    }

    return (
        <Wizard step={step} isCurrentStepDone={step === 'done'}>
            <Step step={STEP.Review} label={t`Review`}>
                <Stack space={32}>
                    <Stack align="center" space={8}>
                        <Text variant="3xlarge-accent" align="center" as="h2">
                            <Trans>Update your plan</Trans>
                        </Text>
                        <Text
                            variant="medium-default"
                            align="center"
                            color="colorContentStaticQuiet"
                        >
                            <Trans>
                                Here's the plan we think would be the best for you and your needs
                            </Trans>
                        </Text>
                    </Stack>
                    <Inline
                        alignItems="flex-start"
                        flexDirection={isSmallScreen ? 'column' : 'row'}
                        css={{width: '100%', gap: tokens.spacing32}}
                    >
                        <Stack space={16}>
                            <RecommendedPlanInfo
                                billingPeriodType={recommendedBillingPeriod}
                                recommendedPlan={recommendedPlan}
                                onClickComparePlans={onClickComparePlans}
                                migrationType={migrationType!}
                                annualDiscountEssential={plansData?.annualDiscountEssential}
                                annualDiscountAdvanced={plansData?.annualDiscountAdvanced}
                                pageOrigin={pageOrigin}
                                pageSection={pageSection}
                            />
                            <PlanChangeSummary
                                currentPlan={currentPlan!}
                                targetPlan={{
                                    planType: recommendedPlan ?? 'STARTER',
                                    isUpgrade: true,
                                }}
                                companyCurrency={currency}
                                onChatWithUs={handleContactUs}
                                hasCashback={hasCashback}
                                isBillingAnnual={recommendedBillingPeriod === 'YEAR'}
                            />
                        </Stack>
                        <PaymentConfirmationBreakdown
                            targetBillingType={recommendedBillingPeriod}
                            targetPlan={{
                                planType: recommendedPlan ?? 'STARTER',
                                isUpgrade: true,
                                freeUserLimit,
                            }}
                            onError={handleOnPaymentError}
                            onConfirmation={handleOnConfirmation}
                            currentPlan={currentPlan!}
                            currentBillingType={
                                plansData?.currentBillingType as CpqBillingPeriodType
                            }
                            currentPlanEstimatedTotal={plansData?.currentPlanEstimatedTotal!}
                            isLegacyPlan={isLegacyPlan}
                            planUpgradeSource={planUpgradeSource}
                            pageOrigin={pageOrigin}
                            pageSection={pageSection}
                            trackingStep="recommended_plan"
                        />
                    </Inline>
                </Stack>
            </Step>
            <Step step={STEP.Done} label={t`Done`}>
                <DoneStep
                    onClose={handleOnDimissWizard}
                    newPlan={recommendedPlan}
                    isUpgrade={true}
                    pageOrigin={pageOrigin}
                    pageSection={pageSection}
                    shouldTopUpWallet={!isWalletBalanceSufficient}
                />
            </Step>
        </Wizard>
    )
}

const getTrackingStep = (step: WizardStepValues) => {
    switch (step) {
        case 'review':
            return 'recommended_plan'
        case 'done':
            return 'completed_plan_changes'
        default:
            return exhaustiveCheck(step)
    }
}
