import {t, Trans} from '@lingui/macro'
import {useEffect, useState} from 'react'
import Helmet from 'react-helmet'
import {Link as RouterLink} from 'react-router-dom'

import {Accordion, Box, Inline, Link, Skeleton} from '@pleo-io/telescope'
import {NewTab} from '@pleo-io/telescope-icons'

import {Page} from '@product-web/feature--ui-page/page'
import {useFlags} from '@product-web/shared--flags'
import PageNotFound from '@product-web/shared--routes/page-not-found/page-not-found'

import {GiveFeedback} from './give-feedback/give-feedback'
import {PricingDetailsForm} from './pricing-details-form'
import {PricingDetailsFormSkeleton} from './pricing-details-form-skeleton'
import {PricingResults} from './pricing-results'
import {PricingResultsSkeleton} from './pricing-results-skeleton'

import {bff} from '../../bff-hooks'
import {SplitContent, useSplitContentMediaQuery} from '../../components/split-content'
import pricingCalculator from '../../images/pricing-calculator.svg'
import type {CalculatePricingOutput} from '../../index.bff'

export const PricingCalculator = () => {
    const {partnerPricingCalculator, partnerPricingCalculatorFeedback} = useFlags()
    const isNarrowViewport = useSplitContentMediaQuery()
    const [priceQuote, setPriceQuote] = useState<CalculatePricingOutput | undefined>(undefined)
    const [isDirty, setIsDirty] = useState(false)

    const {data, isInitialLoading, isLoading} = bff.pricingCalculator.getInitialLoad.useQuery()

    useEffect(() => {
        data && !priceQuote && setPriceQuote(data)
    }, [data, priceQuote])

    if (!partnerPricingCalculator) {
        return <PageNotFound />
    }

    const showLoading = isLoading && !priceQuote

    return (
        <Page layout="wide" showNav>
            <Helmet>
                <title>{t`Pleo Pricing Calculator`}</title>
            </Helmet>
            <Page.Header>
                <Page.SpaceBetween>
                    <Page.Title>
                        <Trans>Pleo Pricing Calculator</Trans>
                    </Page.Title>
                    <Page.Actions>
                        <RouterLink
                            to="/partner/onboard"
                            state={{fromPath: '/partner/pricing-calculator'}}
                        >
                            <Trans>Onboard client</Trans> &rarr;
                        </RouterLink>
                    </Page.Actions>
                </Page.SpaceBetween>
            </Page.Header>
            <SplitContent>
                <SplitContent.Main flex={4}>
                    <img src={pricingCalculator} alt="" />
                    {showLoading && <PricingResultsSkeleton />}
                    {priceQuote && <PricingResults isDirty={isDirty} pricingQuote={priceQuote} />}
                    <Inline justifyContent="center" my={24}>
                        <Skeleton loading={showLoading}>
                            <Link
                                IconRight={NewTab}
                                href="https://pleo.io/pricing"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Trans>Pricing plans comparison</Trans>
                            </Link>
                        </Skeleton>
                    </Inline>
                    {!isNarrowViewport && partnerPricingCalculatorFeedback && (
                        <GiveFeedback isLoading={showLoading} />
                    )}
                </SplitContent.Main>
                <SplitContent.Right>
                    <Accordion css={{'margin-top': '28px'}} defaultValue={['pricing-details']}>
                        <Accordion.Item value="pricing-details">
                            <Accordion.Header headingLevel="h2" heading={t`Pricing details`} />
                            <Accordion.Content>
                                {isInitialLoading && !priceQuote ? (
                                    <PricingDetailsFormSkeleton />
                                ) : (
                                    <PricingDetailsForm
                                        pricingQuote={priceQuote}
                                        setIsDirty={setIsDirty}
                                        setPriceQuote={setPriceQuote}
                                    />
                                )}
                            </Accordion.Content>
                        </Accordion.Item>
                    </Accordion>
                    {isNarrowViewport && partnerPricingCalculatorFeedback && (
                        <GiveFeedback isLoading={showLoading} />
                    )}
                </SplitContent.Right>
            </SplitContent>
            <Box pb={56} />
        </Page>
    )
}
