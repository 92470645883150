import {Plural, t, Trans} from '@lingui/macro'
import type {ReactNode} from 'react'
import {createContext, useContext, useState} from 'react'
import {Link as RouterLink, useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import styled from 'styled-components'

import {
    Box,
    Button,
    Callout,
    Inline,
    Link,
    List,
    ListItem,
    Modal,
    ModalActions,
    ModalClose,
    ModalContent,
    ModalTitle,
    Text,
    tokens,
} from '@pleo-io/telescope'
import {Download} from '@pleo-io/telescope-icons'

import {
    generateSystems,
    isExportDispatcherIntegration,
} from '@product-web/shared--accounting-systems'
import tracking from '@product-web/shared--analytics'
import type {AccountingSystemDetails} from '@product-web/shared--api-types/accounting'
import {isTRPCClientError} from '@product-web/shared--bff-client/errors'
import {dayjs} from '@product-web/shared--dates/dayjs'
import {useFlags} from '@product-web/shared--flags'
import type {SupportedLanguage} from '@product-web/shared--i18n'
import {useAppLanguage} from '@product-web/shared--i18n'
import {
    getHelpCentreArticleLinkIntercom,
    getHelpCentreFolderLink,
} from '@product-web/shared--locale/helpers'
import {useToaster} from '@product-web/shared--toaster'
import {useHasAllowedRole} from '@product-web/shared--user'
import {invariant} from '@product-web/shared--utils'

import type {AsyncExportStatus, TriggerAsyncExportInput} from '../../bff-hooks'
import {bff, bffHooks} from '../../bff-hooks'
import {useCompanyLocalStorage} from '../../lib/use-company-local-storage'

/**
 * The efficient interval to poll for status will be investigated as part of
 * this ticket: https://linear.app/pleo/issue/RFE-261/deduce-efficient-polling-interval-to-status-endpoint
 */
const ASYNC_EXPORT_REFRESH_INTERVAL = 3000

export type AsyncExportAPI = {
    isExporting: boolean
    triggerAsyncExport?: (params: TriggerAsyncExportInput) => void
    isExpenseExporting?: (expenseId: string) => boolean
    statusComponent?: ReactNode
}

export const AsyncExportContext = createContext<AsyncExportAPI>({
    isExporting: false,
    triggerAsyncExport: undefined,
    isExpenseExporting: undefined,
    statusComponent: undefined,
})

export const AsyncExportProvider = ({children}: {children: ReactNode}) => {
    const isAllowedToExport = useHasAllowedRole([
        'bookkeeper-basic',
        'bookkeeper-extended',
        'owner',
    ])
    const ctx = bffHooks.useContext()
    const {showToast} = useToaster()
    const {integrationsUnsupportedByExportDispatcher} = useFlags()
    const [shouldShowResultToast, setShouldShowResultToast] = useState<boolean>(false)
    const [shouldShowFileDownloadModal, setShouldShowFileDownloadModal] = useState<boolean>(false)
    const [exportingExpenseIds, setExportingExpenseIds] = useState<string[]>([])
    const {data: asyncExport} = bff.asyncExport.asyncExportContext.useQuery(undefined, {
        enabled: isAllowedToExport,
    })
    const system = generateSystems()[asyncExport?.accountingSystem ?? 'none']
    const unsupportedSystemsList = integrationsUnsupportedByExportDispatcher?.split(',') || []
    const isDirect = asyncExport?.isAccountingDirect && system?.direct
    const appLanguage = useAppLanguage()
    const helpCentreLink = getHelpCentreLink(system, appLanguage)
    const shouldUseExportDispatcher = isExportDispatcherIntegration(system, unsupportedSystemsList)
    const {data: asyncExportStatus, refetch} = bff.asyncExport.asyncExportStatus.useQuery(
        {shouldUseExportDispatcher},
        {
            enabled: !!asyncExport,
            refetchInterval: (lastAsyncExportStatus) => {
                return lastAsyncExportStatus?.status === 'IN_PROGRESS'
                    ? ASYNC_EXPORT_REFRESH_INTERVAL
                    : 0
            },
            onSuccess: async (lastAsyncExportStatus) => {
                if (lastAsyncExportStatus?.status === 'IN_PROGRESS') {
                    // Display result toast only if there is any ongoing direct export
                    if (!shouldShowResultToast && isDirect) {
                        setShouldShowResultToast(true)
                    }
                    if (!exportingExpenseIds.length) {
                        setExportingExpenseIds(lastAsyncExportStatus?.exportingEntries ?? [])
                    }
                    return
                }
                // Reset to default state once the export is completed (success or failed)
                setShouldShowResultToast(false)
                setExportingExpenseIds([])
                // Display success toast only if the export is direct and successful
                if (lastAsyncExportStatus?.status === 'SUCCESSFUL') {
                    await Promise.allSettled([
                        ctx.exportV2.exportTableData.refetch(),
                        ctx.exportV2.exportQueueStats.refetch(),
                    ])
                    if (shouldShowResultToast) {
                        showToast(t`Your expenses were successfully exported to ${system?.name}`, {
                            title: t`Export complete`,
                            level: 'success',
                        })
                    }
                }
            },
        },
    )
    const {mutate: asyncExportMutate, isLoading: isLoadingToExport} =
        bff.asyncExport.triggerAsyncExport.useMutation({
            onSuccess: async () => {
                await refetch()
                await ctx.exportV2.selectedExpenses.selectedExpensesInLane.refetch()
                if (!isDirect) {
                    setShouldShowFileDownloadModal(true)
                }
            },
            onError: (error) => {
                const errorMessages = getTriggerExportErrorFromType()
                const errorType = (isTRPCClientError(error) && error.shape?.message) || ''
                const errorMessage =
                    errorMessages[errorType] ?? t`Something went wrong, please try again`
                setExportingExpenseIds([])

                showToast(errorMessage, {
                    level: 'error',
                })
            },
        })

    const triggerAsyncExport = (params: TriggerAsyncExportInput) => {
        setExportingExpenseIds(params.expenseIds)
        asyncExportMutate({...params, shouldUseExportDispatcher})
    }

    const isExpenseExporting = (expenseId: string) => {
        return exportingExpenseIds.includes(expenseId)
    }

    return (
        <AsyncExportContext.Provider
            value={{
                isExporting: isLoadingToExport || asyncExportStatus?.status === 'IN_PROGRESS',
                triggerAsyncExport,
                isExpenseExporting,
                statusComponent: (
                    <ExportStatusCallout
                        asyncExportStatus={asyncExportStatus}
                        shouldUseExportDispatcher={shouldUseExportDispatcher}
                        accountingSystemName={isDirect ? system.name : undefined}
                        helpCentreLink={helpCentreLink}
                        numberOfExpensesExporting={exportingExpenseIds.length}
                    />
                ),
            }}
        >
            {asyncExportStatus?.url && shouldShowFileDownloadModal && (
                <FileDownloadModal
                    url={asyncExportStatus.url}
                    expensesCount={asyncExportStatus?.exportedEntriesCount}
                    onDismiss={() => setShouldShowFileDownloadModal(false)}
                />
            )}
            {children}
        </AsyncExportContext.Provider>
    )
}

export const useAsyncExportContext = () => {
    const context = useContext(AsyncExportContext)

    invariant(context, 'This hook can only be used within AsyncExportContext')

    return context
}

export const ExportStatusCallout = ({
    asyncExportStatus,
    accountingSystemName,
    helpCentreLink,
    numberOfExpensesExporting,
    shouldUseExportDispatcher,
}: {
    asyncExportStatus?: AsyncExportStatus
    accountingSystemName?: string
    helpCentreLink?: string
    numberOfExpensesExporting: number
    shouldUseExportDispatcher: boolean
}) => {
    const useLocalStorage = useCompanyLocalStorage('export-status-dismiss')
    const [lastUpdatedAt, setLastUpdatedAt] = useLocalStorage<string>(`last_updated_at`, '')
    const isStatusDismissed = lastUpdatedAt === asyncExportStatus?.lastUpdatedAt
    const [showCompletedWithErrorModal, setShowCompletedWithErrorModal] = useState(false)
    const lastUpdatedDate = dayjs(asyncExportStatus?.lastUpdatedAt).format('MMMM Do')
    const lastUpdatedTime = dayjs(asyncExportStatus?.lastUpdatedAt).format('H:mm')
    const failureReasons = getExportJobFailedReasonFromType(accountingSystemName ?? '')
    const failureReason =
        asyncExportStatus?.failureReasonType && failureReasons[asyncExportStatus?.failureReasonType]
    const onStatusDismiss = () => {
        setLastUpdatedAt(asyncExportStatus?.lastUpdatedAt ?? '')
    }

    if (!asyncExportStatus?.status || isStatusDismissed) {
        return null
    }

    switch (asyncExportStatus?.status) {
        case 'SUCCESSFUL':
            if (
                asyncExportStatus.url &&
                dayjs().diff(dayjs(asyncExportStatus?.lastUpdatedAt), 'day') > 7
            ) {
                return null // Do not show success status if it is non-direct and older than 7 days
            }
            return (
                <Callout variant="positive">
                    <Callout.Text>
                        <Trans>
                            Your expenses were successfully exported on {lastUpdatedDate} at{' '}
                            {lastUpdatedTime}.
                        </Trans>{' '}
                        {asyncExportStatus.url && (
                            <Link
                                href={asyncExportStatus.url}
                                onClick={() => {
                                    tracking.download({
                                        type: 'export_callout',
                                        expense_count: asyncExportStatus?.exportedEntriesCount,
                                    })
                                }}
                            >
                                <Trans>Download the file here</Trans>
                            </Link>
                        )}
                    </Callout.Text>
                    <Callout.CloseButton onClick={onStatusDismiss} />
                </Callout>
            )
        case 'FAILED':
            if (dayjs().diff(dayjs(asyncExportStatus?.lastUpdatedAt), 'day') > 1) {
                return null // Do not show failure status if it is older a day
            }
            return (
                <Callout variant="negative">
                    <Callout.Text>
                        <Trans>An error occurred during your latest export.</Trans>{' '}
                        {asyncExportStatus?.failureReason ??
                            failureReason ??
                            t`Please review your settings and expenses and try again.`}{' '}
                        {shouldUseExportDispatcher && !!asyncExportStatus?.failedEntries.length && (
                            <Link onClick={() => setShowCompletedWithErrorModal(true)}>
                                <Trans>Review errors</Trans>
                            </Link>
                        )}
                    </Callout.Text>
                    {showCompletedWithErrorModal && (
                        <CompletedWithErrorModal
                            accountingSystemName={accountingSystemName}
                            helpCentreLink={helpCentreLink}
                            failedEntries={asyncExportStatus?.failedEntries}
                            onDismiss={() => setShowCompletedWithErrorModal(false)}
                        />
                    )}
                    <Callout.CloseButton onClick={onStatusDismiss} />
                </Callout>
            )
        case 'COMPLETED_WITH_ERRORS':
            if (dayjs().diff(dayjs(asyncExportStatus?.lastUpdatedAt), 'day') > 3) {
                return null // Do not show partially failed status if it is older than 3 days
            }
            return (
                <Callout variant="warning">
                    <Callout.Text>
                        <Trans>
                            Exported{' '}
                            <Plural
                                value={asyncExportStatus?.exportedEntriesCount}
                                one="# expense"
                                other="# expenses"
                            />{' '}
                            on {lastUpdatedDate} at {lastUpdatedTime}, encountered{' '}
                            <Plural
                                value={asyncExportStatus?.failedEntries?.length}
                                one="# expense"
                                other="# expenses"
                            />{' '}
                            we couldn't export.{' '}
                        </Trans>{' '}
                        {!!asyncExportStatus.failedEntries.length && (
                            <Link onClick={() => setShowCompletedWithErrorModal(true)}>
                                <Trans>Review errors</Trans>
                            </Link>
                        )}
                    </Callout.Text>
                    {showCompletedWithErrorModal && (
                        <CompletedWithErrorModal
                            accountingSystemName={accountingSystemName}
                            helpCentreLink={helpCentreLink}
                            failedEntries={asyncExportStatus?.failedEntries}
                            onDismiss={() => setShowCompletedWithErrorModal(false)}
                        />
                    )}
                    <Callout.CloseButton onClick={onStatusDismiss} />
                </Callout>
            )
        default:
            return (
                <Callout variant="info">
                    <Callout.Text>
                        <Trans>
                            Exporting{' '}
                            <Plural
                                value={numberOfExpensesExporting}
                                one="# expense"
                                other="# expenses"
                            />
                            . You can safely close this tab or move to other pages without losing
                            your progress.
                        </Trans>
                    </Callout.Text>
                </Callout>
            )
    }
}

const FileDownloadModal = ({
    url,
    onDismiss,
    expensesCount,
}: {
    url: string
    onDismiss(): void
    expensesCount?: number
}) => {
    return (
        <Modal aria-label={t`Export file download modal`} onDismiss={onDismiss}>
            <ModalClose onClick={onDismiss} />
            <ModalTitle>
                <Trans>Your export file is ready</Trans>
            </ModalTitle>
            <ModalContent>
                <Trans>The download link expires in 24 hours.</Trans>
            </ModalContent>
            <ModalActions>
                <Button
                    href={url}
                    variant="primary"
                    onClick={() => {
                        tracking.download({type: 'export', expense_count: expensesCount})
                        onDismiss()
                    }}
                    IconLeft={Download}
                >
                    <Trans>Download file</Trans>
                </Button>
            </ModalActions>
        </Modal>
    )
}

const CompletedWithErrorModal = ({
    accountingSystemName,
    helpCentreLink,
    failedEntries,
    onDismiss,
}: {
    accountingSystemName?: string
    helpCentreLink?: string
    failedEntries: NonNullable<AsyncExportStatus>['failedEntries']
    onDismiss(): void
}) => {
    const {search} = useLocation()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const failureReasons = getExportJobItemFailedReasonFromType(accountingSystemName ?? '')

    // Remove the logics for old page once the old export page is killed
    const isOldPage = !!searchParams.get('exportLane')

    const handleViewExpenseClick = (exportItemId = '') => {
        tracking.viewExpenseDetailsActioned({
            integration: accountingSystemName,
            source: 'export_error_modal',
        })
        onDismiss()
        if (isOldPage) {
            navigate('/export', {state: {exportItemId}})
        }
    }

    return (
        <Modal aria-label={t`Export partially failed modal`} onDismiss={onDismiss}>
            <ModalClose onClick={onDismiss} />
            <ModalTitle>
                <Trans>
                    Export completed with{' '}
                    <Plural value={failedEntries.length} one="# error" other="# errors" />
                </Trans>
            </ModalTitle>
            <ModalContent>
                <Text variant="small-subtle">
                    <Trans>
                        Some expenses may not have been exported, or exported with missing data.
                        Please review the following expenses.
                    </Trans>
                </Text>
                <List css={{marginTop: 32}}>
                    {failedEntries.map(({exportItemId, message, failureReasonType}) => (
                        <ListItem key={exportItemId}>
                            <StyledInline alignY="center" alignX="space-between">
                                <Text variant="small-subtle" align="left">
                                    {message ??
                                        (failureReasonType && failureReasons[failureReasonType])}
                                </Text>
                                {exportItemId && !isOldPage && (
                                    <Link
                                        to={`../export-item/${exportItemId}${search}`}
                                        as={RouterLink}
                                        onClick={() => handleViewExpenseClick(exportItemId)}
                                        css={{whiteSpace: 'nowrap'}}
                                    >
                                        <Trans>View expense</Trans>
                                    </Link>
                                )}
                                {exportItemId && isOldPage && (
                                    <Button
                                        variant="link"
                                        onClick={() => handleViewExpenseClick(exportItemId)}
                                        css={{whiteSpace: 'nowrap'}}
                                    >
                                        <Trans>View expense</Trans>
                                    </Button>
                                )}
                            </StyledInline>
                        </ListItem>
                    ))}
                </List>
                {!!helpCentreLink && (
                    <Box py={16}>
                        <Text>
                            <Trans>Unsure about what the above means?</Trans>{' '}
                            <Link
                                href={helpCentreLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => {
                                    tracking.helpCentreLinkActioned({
                                        link: helpCentreLink,
                                        source: 'export_error_modal',
                                    })
                                }}
                            >
                                <Trans>See our Help Center</Trans>
                            </Link>
                        </Text>
                    </Box>
                )}
            </ModalContent>
            <ModalActions>
                <Button variant="primary" onClick={onDismiss}>
                    <Trans>Close</Trans>
                </Button>
            </ModalActions>
        </Modal>
    )
}

type FailureReasonType = NonNullable<NonNullable<AsyncExportStatus>['failureReasonType']>

const getExportJobFailedReasonFromType = (
    accountingSystemName: string,
): {[key in FailureReasonType]?: string} => {
    return {
        invalid_configuration: t`Please review and adjust the integration settings.`,
        missing_configuration: t`Please provide mandatory details.`,
        authentication_failure: t`Unable to access due to login issue. Please verify your Pleo credentials and try again.`,
        accounting_system_authentication_failure: t`Unable to access due to login issue. Please verify your ${accountingSystemName} credentials and access permissions.`,
        pleo_rate_limit: t`Unable to proceed due to usage limits, please wait a few minutes.`,
        accounting_system_rate_limit: t`Unable to proceed due to usage limits, please wait for a few minutes.`,
        service_unreachable: t`Oops, something went wrong on our end. Please try again later.`,
        accounting_system_unreachable: t`Oops, something went wrong on ${accountingSystemName}. Please try again later.`,
        validation_failure: t`Invalid data detected, please update the values.`,
        authorization_failure: t`You don't have permission to perform this action. Please obtain additional permissions.`,
        integration_unusable: t`Integration currently unusable, may be due to a service blockage or technical issues.`,
        job_expired: t`Action couldn't be completed due to a timeout.`,
        service_timeout: t`Action couldn't be completed due to a timeout.`,
        accounting_system_timeout: t`Action couldn't be completed due to a timeout.`,
    }
}

type FailedEntryFailureReasonType = NonNullable<
    NonNullable<AsyncExportStatus>['failedEntries'][number]['failureReasonType']
>

const getExportJobItemFailedReasonFromType = (
    accountingSystemName: string,
): {[key in FailedEntryFailureReasonType]?: string} => {
    return {
        receipt_upload_failure: t`Receipt upload to accounting system encountered an issue.`,
        receipt_download_failure: t`Accessing receipt from Pleo encountered an issue.`,
        receipt_file_size_limit_exceeded: t`Receipt file size exceeds accounting system limit.`,
        receipt_conversion_failure: t`Conversion of receipt to desired format failed.`,
        unexpected_failure: t`An unexpected error occurred. Please retry later.`,
        invalid_configuration: t`Export error due to an invalid configuration.`,
        missing_configuration: t`Export issue due to missing configuration.`,
        accounting_system_authentication_failure: t`Unable to access due to login issue.`,
        accounting_system_rate_limit: t`Unable to proceed due to usage limits.`,
        accounting_system_unreachable: t`Oops, something went wrong on ${accountingSystemName}. Please try again later.`,
        validation_failure: t`Invalid data detected.`,
        accounting_system_validation_failure: t`Accounting system encountered a validation error during export.`,
        authorization_failure: t`You don't have sufficient permissions to perform this action.`,
        accounting_system_timeout: t`Timeout while communicating with accounting system.`,
    }
}

const getTriggerExportErrorFromType = (): {[key in string]: string} => {
    return {
        COMPANY_NOT_FOUND: t`Invalid Company ID`,
        EXPORT_ENTRY_NOT_FOUND: t`Requested expense couldn't be found`,
        EXPORT_ALREADY_IN_PROGRESS: t`An export is underway, kindly await completion`,
        EXPORT_JOB_ITEMS_UPDATE_LIMIT: t`Failed to process large number of updates. Please update 100 at a time`,
        EXPORT_JOB_STATUS_UNPROCESSABLE: t`Export is already complete and can't be processed further`,
        EXPORT_NOT_FOUND: t`Invalid export job ID`,
        EXPORT_PROCESSING_ERROR: t`An unknown error occurred while processing the export job`,
        INVALID_EXPORT_JOB_STATUS: t`Incorrect export job status`,
        INVALID_EXPORT_JOB_STATUS_CHANGE: t`Requested change to export job status isn't supported. Please choose a valid status`,
        UNSUPPORTED_ACCOUNTING_ENTRY_TYPE: t`Exporting the requested accounting entry type is not supported`,
        UNSUPPORTED_INVOICE_STATUS: t`Exporting an invoice with current status isn't supported. Please select a valid invoice status for export`,
        UNSUPPORTED_EXPORT_SYSTEM: t`Selected export system is not supported. Please choose a supported export system for the export`,
    }
}

const getHelpCentreLink = (system: AccountingSystemDetails, appLanguage: SupportedLanguage) => {
    const languageToDirect = system?.helpCentre?.supportedLanguages?.includes(appLanguage)
        ? appLanguage
        : 'en'

    if (system?.helpCentre?.folderId) {
        return getHelpCentreFolderLink(system?.helpCentre?.folderId, languageToDirect)
    }

    if (system?.helpCentre?.articleId) {
        return getHelpCentreArticleLinkIntercom(system?.helpCentre?.articleId, languageToDirect)
    }

    return undefined
}

const StyledInline = styled(Inline)`
    border-bottom: ${tokens.borderPrimary};
    padding-bottom: ${tokens.spacing16};
    box-sizing: border-box;
`
