import * as React from 'react'
import {Route} from 'react-router-dom'

import {roleSets} from '@product-web/shared--user'

import BudgetsAuthAndRoutes from './components/budget-auth'

const BudgetPage = React.lazy(async () => import('./components/budget-page'))

const CreateTagBudget = React.lazy(
    async () => import('./screens/create-tag-budget/create-tag-budget'),
)
const EditTagBudget = React.lazy(async () => import('./screens/edit-tag-budget/edit-tag-budget'))
const TagBudgetsList = React.lazy(async () => import('./screens/tag-budgets-list/tag-budgets-list'))

export const routes = (
    <Route handle={{auth: true, allowedRoles: roleSets.company}}>
        <Route element={<BudgetPage />}>
            <Route element={<BudgetsAuthAndRoutes />}>
                <Route index element={<TagBudgetsList />} />
                <Route handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeperExtended}}>
                    <Route path="create" element={<CreateTagBudget />} />
                    <Route path=":tagBudgetId/edit" element={<EditTagBudget />} />
                </Route>
            </Route>
        </Route>
    </Route>
)
