import {t, Trans} from '@lingui/macro'
import {useEffect} from 'react'

import {Button, Inline, Modal} from '@pleo-io/telescope'

import * as tracking from '@product-web/shared--analytics'

const trackCashbackModal = ({action}: {action: 'viewed' | 'closed' | 'continue'}) => {
    tracking.cashbackModal({action})
}

type CashbackModalProps = {
    isOpen: boolean
    onDismiss: () => void
    onContinue: () => void
    dangerouslySetZIndexValue?: number
}

export const CashbackModal = ({
    isOpen,
    onDismiss,
    onContinue,
    dangerouslySetZIndexValue,
}: CashbackModalProps) => {
    useEffect(() => {
        if (isOpen) {
            trackCashbackModal({action: 'viewed'})
        }
    }, [isOpen])

    const onDismissWithTracking = () => {
        trackCashbackModal({action: 'closed'})
        onDismiss()
    }

    return (
        <Modal
            aria-label={t`Lose cashback with monthly billing`}
            isOpen={isOpen}
            onDismiss={onDismissWithTracking}
            dangerouslySetZIndexValue={dangerouslySetZIndexValue}
        >
            <Modal.Close onClick={onDismissWithTracking} />

            <Modal.Title>
                <Trans>Continue and lose cashback?</Trans>
            </Modal.Title>

            <Modal.Content>
                <Trans>
                    Since cashback is only available with annual billing, upgrading to a monthly
                    Advanced plan means losing your cashback rate. Are you sure you want to go
                    ahead?
                </Trans>
            </Modal.Content>

            <Modal.Actions>
                <Inline space={16}>
                    <Button variant="secondary" onClick={onDismissWithTracking}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            trackCashbackModal({action: 'continue'})
                            onContinue()
                        }}
                    >
                        <Trans>Yes, continue</Trans>
                    </Button>
                </Inline>
            </Modal.Actions>
        </Modal>
    )
}
