import {t, Trans} from '@lingui/macro'
import {useEffect} from 'react'
import {useNavigate} from 'react-router'

import type {PromptSize} from '@product-web/feature--help-centre/adoption-prompt'
import {AdoptionPrompt} from '@product-web/feature--help-centre/adoption-prompt'
import {dayjs} from '@product-web/shared--dates/dayjs'
import {useFlags} from '@product-web/shared--flags'
import {formatCurrency} from '@product-web/shared--locale/currency'
import {getIsCompanyOwner, getIsFdd, useLoggedInUser} from '@product-web/shared--user'

import {bffHooks} from './bff-hooks'
import forkliftMoneyImage from './images/forklift-money.png'
import {
    PromptName,
    usePromptsPersistedState,
    useResetPromptsPersistedState,
} from './overdraft-adoption-prompts-state'

import {useCompanyUserLocalStorage} from '../use-company-user-local-storage'

const DATE_FORMAT = 'YYYY-MM-DD'

export const OverdraftAdoptionPrompts = () => {
    const navigate = useNavigate()
    const user = useLoggedInUser()
    const isOwner = getIsCompanyOwner(user)
    const isFdd = getIsFdd(user)

    useResetPromptsPersistedState()
    const {dismissPrompt, dismissedPrompts} = usePromptsPersistedState()
    const useCompanyUserStorage = useCompanyUserLocalStorage('overdraftHighUtilizationPrompt')
    const [highUtilizationPrompt, setHighUtilizationPrompt] = useCompanyUserStorage<{
        triggerDate: string | null
    }>('promptTriggered', {
        triggerDate: null,
    })

    const {data} = bffHooks.credit.getOverdraftAdoptionPrompts.useQuery(undefined, {
        enabled: isOwner && isFdd,
        staleTime: Infinity,
    })
    const {overdraftHigherLimit} = useFlags()

    const {
        canBeActivated,
        hasDeclinedTransactions,
        hasLowBalance,
        terms,
        hasHighOverdraftUtilization,
        currentOverdraftLimit,
        isHigherLimitApplicationAvailable,
        maxOverdraftLimit,
    } = data ?? {}

    const canApplyForHigherLimit = isHigherLimitApplicationAvailable && overdraftHigherLimit

    const highUtilizationTriggeredRecently = highUtilizationPrompt?.triggerDate
        ? dayjs().diff(dayjs(highUtilizationPrompt.triggerDate), 'days') <= 7
        : false

    useEffect(() => {
        if (hasHighOverdraftUtilization && !highUtilizationTriggeredRecently) {
            setHighUtilizationPrompt({triggerDate: dayjs().format(DATE_FORMAT)})
        }
    }, [hasHighOverdraftUtilization, setHighUtilizationPrompt, highUtilizationTriggeredRecently])

    const creditLimit = formatCurrency(terms?.limit, terms?.currency, {
        format: {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        },
    })

    const onDismiss = () => {
        dismissPrompt()
    }

    const isVisible = isOwner && !dismissedPrompts?.dismissedAt

    const onComplete = () => {
        dismissPrompt()
        navigate('/wallet/overdraft/activate')
    }

    const onHighUtilizationComplete = () => {
        dismissPrompt()
        if (canApplyForHigherLimit) {
            navigate('/wallet/overdraft/higher-limit-application')
        } else {
            navigate('/wallet/manage/overdraft/change-limit')
        }
    }

    const commonProps = {
        size: 'medium' as PromptSize,
        completeText: t`Start activation`,
        dismissText: t`Not now`,
        imageSrc: forkliftMoneyImage,
        onDismiss,
        onComplete,
    }

    if (!isVisible) {
        return null
    }

    if (hasDeclinedTransactions && canBeActivated) {
        return (
            <AdoptionPrompt
                {...commonProps}
                name={`${PromptName.DECLINED_TRANSACTIONS}-overdraft`}
                title={<Trans>Declined transactions? Not with Overdraft of {creditLimit}</Trans>}
                text={
                    <Trans>
                        Activate Overdraft limit of up to {creditLimit} and enjoy it interest-free
                        with a 30-day repayment.
                    </Trans>
                }
            />
        )
    }
    if (hasLowBalance && canBeActivated) {
        return (
            <AdoptionPrompt
                {...commonProps}
                name={`${PromptName.LOW_BALANCE}-overdraft`}
                title={<Trans>Low Wallet balance? Don't worry about it with Overdraft</Trans>}
                text={
                    <Trans>
                        Activate Overdraft limit of up to {creditLimit} and enjoy it interest-free
                        with a 30-day repayment.
                    </Trans>
                }
            />
        )
    }

    const canIncreaseLimit =
        terms?.limit && currentOverdraftLimit && terms.limit > currentOverdraftLimit

    if (
        (hasHighOverdraftUtilization || highUtilizationTriggeredRecently) &&
        (canIncreaseLimit || canApplyForHigherLimit)
    ) {
        const currentLimit = formatCurrency(currentOverdraftLimit, terms?.currency, {
            format: {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            },
        })

        const maxLimit = formatCurrency(maxOverdraftLimit, terms?.currency, {
            format: {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            },
        })

        return (
            <AdoptionPrompt
                {...commonProps}
                name={
                    canApplyForHigherLimit
                        ? `${PromptName.HIGHER_OVERDRAFT_LIMIT}-overdraft`
                        : `${PromptName.HIGH_OVERDRAFT_UTILIZATION}-overdraft`
                }
                title={<Trans>Need a higher Overdraft limit?</Trans>}
                text={
                    canApplyForHigherLimit ? (
                        <Trans>
                            You've used 80% of your {currentLimit} Overdraft limit. Unlock more
                            flexibility – you can apply for up to {maxLimit}
                        </Trans>
                    ) : (
                        <Trans>
                            You've used 80% of your activated {currentLimit} Overdraft limit. Unlock
                            more flexibility - you can increase up to {creditLimit}.
                        </Trans>
                    )
                }
                onComplete={onHighUtilizationComplete}
                completeText={canApplyForHigherLimit ? t`Apply` : t`Change`}
            />
        )
    }
    return null
}
