import React from 'react'
import {useNavigate} from 'react-router-dom'

import {clearClientSession, openClientSession} from '@product-web/shared--auth--client-session'
import {getTokenPayload} from '@product-web/shared--auth--jwt/payload'
import {isJwt} from '@product-web/shared--auth--jwt/utils'
import {SessionContext} from '@product-web/shared--auth--session/context'
import {fetchSession} from '@product-web/shared--auth--session/operations'
import {setPrimaryAccountEmail} from '@product-web/shared--auth--session/primary-account'
import {clearScopedTokens} from '@product-web/shared--auth--session/scoped-tokens'
import type {SessionData} from '@product-web/shared--auth--session/store'
import {useUserMutations} from '@product-web/shared--user'
import {notEmpty} from '@product-web/shared--utils'

export interface LoggedInAccount {
    accessToken: string
    email: string
    connectedAccountEmails?: {email: string; trusted?: boolean}[]
}

export type AddAccountPayload = Partial<
    Pick<SessionData, 'email' | 'accessToken' | 'accessTokens' | 'companyId'>
> & {
    skipRedirect?: boolean
    location?: string
}

export type AddAccount = (payload: AddAccountPayload) => Promise<void>

export type SwitchAccountPayload = {
    email: string
    companyId?: string | null
    location?: string
    skipRedirect?: boolean
}

export type SwitchAccount = (payload: SwitchAccountPayload) => Promise<void>

/**
 * Custom hook to get the current logged in accounts (decoded access token)
 * @returns the current logged in accounts and a method to switch to another account
 */
export function useLoggedInAccounts(): {
    accounts: LoggedInAccount[]
    switchAccount: SwitchAccount
    addAccount: AddAccount
} {
    const userMutation = useUserMutations()
    const navigate = useNavigate()
    const {accountAccessTokens} = React.useContext(SessionContext)

    // It may make sense to use currentAccessToken here also as it has been suggested that it might be possible for it not
    // to be included in accountAccessTokens, but doing so currently breaks tests. Further investigation is needed to determine
    // if it should be added and if so, why do current tests break when doing this?
    const accounts: LoggedInAccount[] = [...new Set(accountAccessTokens ?? [])]
        .map((accessToken: string) => {
            const decodedData = getTokenPayload(accessToken)?.data
            const email = decodedData?.user.email
            const connectedAccountEmails = decodedData?.meta?.subSessionEmails

            if (!email) {
                return null
            }

            return {
                email,
                accessToken,
                connectedAccountEmails,
            }
        })
        .filter(notEmpty)

    const updateSession: AddAccount = async ({
        email,
        companyId,
        location = '/expenses',
        skipRedirect,
    }) => {
        // If a companyId, we start a new bookkeeper session
        // If we have a bookkeeper session and no companyId pass we close the bookkeeper session
        if (companyId) {
            openClientSession(companyId)
        } else {
            clearClientSession()
        }

        setPrimaryAccountEmail(email)

        // We store all available accessTokens in the SessionStore and ideally when we want to switch to another account
        // we should just set proper account data to the session store
        // like sessionStore.set({email, accessToken, accessTokens}).
        // But /user endpoint requires full session refresh to fetch new data.
        await fetchSession({force: true})

        clearScopedTokens()

        const options = companyId ? {companyId} : {}

        await userMutation.forceRefetchUser(options)

        if (!skipRedirect) {
            navigate(location)
        }
    }

    const switchAccount: SwitchAccount = async ({email, companyId, location, skipRedirect}) => {
        const associateAccount = accounts.find((account) => account.email === email)

        const token = associateAccount?.accessToken
        if (!isJwt(token ?? '') || !token) {
            return Promise.reject()
        }
        return updateSession({
            accessToken: token,
            accessTokens: accountAccessTokens,
            email,
            companyId,
            location,
            skipRedirect,
        })
    }

    return {
        accounts,
        switchAccount,
        addAccount: updateSession,
    }
}
