import type {RequestScope} from '@product-web/shared--api'

export const scopedTokens = new Map<RequestScope, string>()
export const scopedTokenTimeouts = new Map<RequestScope, number>()

export const SCOPED_TOKEN_LIFE_MS = 1000 * 60 * 10 // 10 min
export const SCOPED_TOKEN_LIFE_MAX_MS = 1000 * 60 * 60 * 24 // 24 hours

/**
 * Clears all cached scoped tokens regardless of their age
 */
export function clearScopedTokens() {
    scopedTokens.clear()
    scopedTokenTimeouts.forEach((timeoutId) => {
        window.clearTimeout(timeoutId)
    })
    scopedTokenTimeouts.clear()
}
