import * as React from 'react'
import {Navigate, Route} from 'react-router-dom'

import {roleSets} from '@product-web/shared--user'

import {routes as academyRoutes} from './screens/academy/routes'
import {routes as clientRoutes} from './screens/clients/routes'
import {routes as onboardingRoutes} from './screens/onboarding/routes'
import {routes as companyRoutes} from './screens/partner-company/routes'
import {routes as inviteRoutes} from './screens/partner-invite/routes'
import {routes as partnershipRoutes} from './screens/partnership/routes'
import {routes as peopleRoutes} from './screens/people/routes'
import {PricingCalculator} from './screens/pricing-calculator/pricing-calculator'

const SignupRoutes = React.lazy(async () => import('./screens/signup/routes'))
const IndexRoute = React.lazy(async () => import('./screens/index-route/index-route'))
const OnboardClient = React.lazy(
    async () => import('./screens/manual-onboarding/manual-onboarding'),
)
const PartnerExperienceWrapper = React.lazy(
    async () => import('./components/partner-experience-wrapper'),
)
const Resources = React.lazy(async () => import('./screens/resources/resources'))

export const routes = (
    <>
        <Route handle={{auth: false}} path="signup/*" element={<SignupRoutes />} />
        <Route handle={{auth: false}} path="invite/:token">
            {inviteRoutes}
        </Route>
        <Route
            element={<PartnerExperienceWrapper />}
            handle={{auth: true, allowedRoles: roleSets.partner}}
        >
            <Route index element={<IndexRoute />} />
            <Route path="academy">{academyRoutes}</Route>
            <Route path="pricing-calculator" element={<PricingCalculator />} />
            <Route path="onboarding">{onboardingRoutes}</Route>
            <Route path="clients">{clientRoutes}</Route>
            <Route path="company">{companyRoutes}</Route>
            <Route path="partnership">{partnershipRoutes}</Route>
            {/* Backwards compatibility */}
            <Route path="team/*" element={<Navigate to="../people" />} />
            <Route path="people">{peopleRoutes}</Route>
            <Route path="onboard" element={<OnboardClient />} />
            <Route path="resources" element={<Resources />} />
        </Route>
    </>
)
