import {t, Trans} from '@lingui/macro'
import {useEffect} from 'react'
import {useNavigate} from 'react-router'

import {AdoptionPrompt} from '@product-web/feature--help-centre/adoption-prompt'

import InvoiceAdoptionPromptIllustration from './invoice-adoption-prompt.png'
import {useInvoiceAdoption} from './use-invoice-adoption'

import {trackInvoiceAdoptionPromptActioned} from '../lib/tracking'

export const InvoiceAdoptionPrompt = () => {
    const {shouldShowInvoiceAdoptionPrompt, setInvoiceAdoptionPromptViewed} = useInvoiceAdoption()

    if (!shouldShowInvoiceAdoptionPrompt) {
        return null
    }

    return (
        <InvoiceAdoptionPromptPresentation
            setInvoiceAdoptionPromptViewed={setInvoiceAdoptionPromptViewed}
        />
    )
}

interface InvoiceAdoptionPromptPresentationProps {
    setInvoiceAdoptionPromptViewed: () => void
}

export const InvoiceAdoptionPromptPresentation = ({
    setInvoiceAdoptionPromptViewed,
}: InvoiceAdoptionPromptPresentationProps) => {
    const navigate = useNavigate()

    useEffect(() => {
        trackInvoiceAdoptionPromptActioned({action: 'viewed'})
    }, [])

    return (
        <AdoptionPrompt
            title={<Trans>Settle all your invoices ten times faster with Pleo</Trans>}
            text={
                <Trans>
                    Capture, process, approve, pay and bookkeep your invoices, all in one place.
                </Trans>
            }
            imageSrc={InvoiceAdoptionPromptIllustration}
            completeText={t`Try Invoices`}
            dismissText={t`Not now`}
            name="invoice-adoption-prompt"
            onComplete={() => {
                trackInvoiceAdoptionPromptActioned({action: 'try_invoices'})
                setInvoiceAdoptionPromptViewed()
                navigate('/invoices')
            }}
            onDismiss={() => {
                trackInvoiceAdoptionPromptActioned({action: 'not_now'})
                setInvoiceAdoptionPromptViewed()
            }}
            size="medium"
        />
    )
}
