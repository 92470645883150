import {i18nStorage, useSupportedLanguageList} from '@product-web/shared--i18n'
import {stringToSupportedLanguage} from '@product-web/shared--i18n'
import {SupportedLanguage} from '@product-web/shared--i18n'
import {getLanguageByLocale} from '@product-web/shared--locale/helpers'
import type Locale from '@product-web/shared--locale/locale'
import {useUser} from '@product-web/shared--user'

const getQueryStringParam = (key: 'locale' | 'lang'): string => {
    const url = new URL(window.location.href)
    const params = url.searchParams
    return String(params.get(key))
}

const getBrowserLanguage = () => {
    const topLanguage = window.navigator.languages?.[0] as Locale
    if (!topLanguage) {
        return null
    }

    const detectedLanguageByLocale = getLanguageByLocale(topLanguage)

    // extract only language
    return detectedLanguageByLocale ?? topLanguage.slice(0, 2)
}

export const useBrowserLanguage = () => {
    const supportedLanguages = useSupportedLanguageList()
    const browserLanguage = stringToSupportedLanguage(getBrowserLanguage(), supportedLanguages)

    return browserLanguage || SupportedLanguage.EN
}

export const useAppLanguage = (): SupportedLanguage => {
    const supportedLanguageList = useSupportedLanguageList()
    const user = useUser()

    const queryStringLocale = stringToSupportedLanguage(
        getQueryStringParam('locale'),
        supportedLanguageList,
    )

    const queryStringLanguage = stringToSupportedLanguage(
        getQueryStringParam('lang'),
        supportedLanguageList,
    )

    const userLanguage = user?.language
        ? stringToSupportedLanguage(user?.language, supportedLanguageList)
        : null

    const storageLanguage = stringToSupportedLanguage(i18nStorage.get(), supportedLanguageList)

    const language =
        queryStringLocale ||
        queryStringLanguage ||
        userLanguage ||
        storageLanguage ||
        SupportedLanguage.EN

    return language
}
