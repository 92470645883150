import {useEffect, useRef, useState} from 'react'

import {useEnableStorebox} from '@product-web/shared--api-deimos/storebox'
import {EmployeeStatus} from '@product-web/shared--shell/employee-status'
import {getEmployeeStatus, useCompanyUser, useUserMutations} from '@product-web/shared--user'

import {bff} from './bff-hooks'

export interface CardDetails {
    year: string
    month: string
    pan: string
}

export function useCompanyStoreboxIntegration() {
    const {
        result: {isEnabled, mutate},
        mutations: {enable: enableStorebox},
    } = useEnableStorebox()

    const user = useCompanyUser()
    const employeeId = user?.employee?.id ?? ''
    const {data: unlinkedCards = []} = bff.storebox.getUnlinkedCards.useQuery(
        {employeeId},
        {enabled: Boolean(employeeId) && isEnabled},
    )
    return {
        result: {
            isEnabled,
            unlinkedCards,
            mutate,
        },
        mutations: {
            enable: () => enableStorebox(unlinkedCards),
        },
    }
}

export function useEmployeeStoreboxIntegration() {
    const isFirstMount = useRef(true)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const {
        result: {isEnabled, unlinkedCards},
    } = useCompanyStoreboxIntegration()
    const user = useCompanyUser()
    const employeeId = user.employee.id

    const hasDismissedModal = getEmployeeStatus(user)[EmployeeStatus.storeboxHasDismissedModal]
    const {setEmployeeStateKey} = useUserMutations()

    const closeModal = (dismiss = true) => {
        if (dismiss) {
            setEmployeeStateKey(EmployeeStatus.storeboxHasDismissedModal, true)
        }
        setIsModalOpen(false)
    }

    useEffect(() => {
        if (
            !isEnabled ||
            !employeeId ||
            !unlinkedCards.length ||
            hasDismissedModal ||
            !isFirstMount.current
        ) {
            return
        }

        setIsModalOpen(true)

        isFirstMount.current = false
    }, [employeeId, unlinkedCards, hasDismissedModal, isEnabled])

    return {isModalOpen, closeModal}
}
