import {useMemo} from 'react'
import useSWR from 'swr'

import type {CompanyAdoptionGuidesResponse} from '@pleo-io/auxo-ts-models'

import {useHasAllowedRole, useUser} from '@product-web/shared--user'

import {getDeimos} from './helpers'

export const useCompanyGuides = () => {
    const user = useUser()
    const companyId = user?.companyId
    const isOwnerOrBookkeeper = useHasAllowedRole(['owner', 'bookkeeper-extended'])
    const isEnabled = isOwnerOrBookkeeper && companyId
    const result = useSWR<CompanyAdoptionGuidesResponse, Error>(
        isEnabled ? `/rest/adoption/v1/companies/${companyId}/guides` : null,
        getDeimos,
    )

    return useMemo(
        () => ({
            ...result,
            data: result.data?.data ?? [],
            isLoading: isEnabled && !result.data && !result.error,
        }),
        [result, isEnabled],
    )
}
