import {Plural, Trans} from '@lingui/macro'
import {useState} from 'react'

import {Badge, Button, Inline, Stack, Text} from '@pleo-io/telescope'

import {bff} from '../../../../bff-hooks'
import {GenericError} from '../../../../components/generic-error'
import TruncateText from '../../../../components/truncate-text/truncate-text'

const PAGE_SIZE = 5

type ReferredUsersProps = {
    title: string
    description?: string
    year: 'current' | 'previous'
}
export const ReferredUsers = ({title, description, year}: ReferredUsersProps) => {
    const [size, setSize] = useState(PAGE_SIZE)
    const {data, isLoading, isError} = bff.referredUsers.getReferredUsersDetails.useQuery({year})

    if (isLoading) {
        return null
    }

    if (isError) {
        return <GenericError />
    }

    const currentReferredUsersList = data.referredUsersByCompany.slice(0, size)
    const hasLoadedMoreReferredUsers = size > PAGE_SIZE
    const hasMoreReferredUsers = data.referredUsersByCompany.length > size

    const noReferredUsers = currentReferredUsersList.length === 0

    if (noReferredUsers) {
        return null
    }

    return (
        <Stack stretch>
            <Inline alignItems="center" space={12}>
                <Text variant="large-accent" weight="semibold" as="h2">
                    {title}
                </Text>
                <Badge variant="info">{data.totalFreeUsersCount + data.totalPaidUsersCount}</Badge>
            </Inline>
            {description && <Text color="colorContentStaticQuiet">{description}</Text>}
            <Stack space={24} mt={24} mb={60} stretch>
                {currentReferredUsersList.map((company) => (
                    <ReferredUsersByCompany
                        key={company.id}
                        companyName={company.name}
                        freeUsersCount={company.freeUsersCount}
                        paidUsersCount={company.paidUsersCount}
                    />
                ))}
                <Inline>
                    {hasMoreReferredUsers ? (
                        <Button
                            variant="tertiary"
                            onClick={() => {
                                setSize(size + PAGE_SIZE)
                            }}
                        >
                            <Trans>See more</Trans>
                        </Button>
                    ) : (
                        hasLoadedMoreReferredUsers && (
                            <Button
                                variant="tertiary"
                                onClick={() => {
                                    setSize(PAGE_SIZE)
                                }}
                            >
                                <Trans>See less</Trans>
                            </Button>
                        )
                    )}
                </Inline>
            </Stack>
        </Stack>
    )
}

function ReferredUsersByCompany({
    companyName,
    freeUsersCount,
    paidUsersCount,
}: {
    companyName: string
    freeUsersCount: number
    paidUsersCount: number
}) {
    return (
        <Inline as="tr" justifyContent="space-between" space={24}>
            <Stack as="td" flex={1}>
                <TruncateText text={companyName} tooltip />
            </Stack>
            <Inline as="td">
                {paidUsersCount ? (
                    <Text>
                        <Text as="span" weight="bold">
                            {paidUsersCount}
                        </Text>{' '}
                        <Plural value={paidUsersCount} one="paid user" other="paid users" />
                    </Text>
                ) : (
                    <Text color="colorContentStaticQuiet">
                        <Text as="span" weight="bold" color="colorContentStaticQuiet">
                            {freeUsersCount}
                        </Text>{' '}
                        <Plural value={freeUsersCount} one="free user" other="free users" />
                    </Text>
                )}
            </Inline>
        </Inline>
    )
}
