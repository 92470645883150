import {t, Trans} from '@lingui/macro'
import {ErrorBoundary} from '@sentry/react'
import type {ReactNode} from 'react'
import {useEffect} from 'react'
import {Link as RouterLink, Outlet} from 'react-router-dom'
import styled from 'styled-components'

import {Button, Inline, Link, Stack, Text} from '@pleo-io/telescope'
import {ArrowLeft} from '@pleo-io/telescope-icons'

import {ContactSupport} from '@product-web/feature--ui-contact-support'
import tracking from '@product-web/shared--analytics'

import blackCatImage from '../images/black-cat.png'
import crackedEggImage from '../images/cracked-egg.svg'

export const WalletRouteErrorBoundary = () => (
    <ErrorBoundary
        beforeCapture={(scope) => scope.setLevel('fatal')}
        fallback={() => <WalletError />}
    >
        <Outlet />
    </ErrorBoundary>
)

export const SubWalletErrorBoundary = ({children}: {children: ReactNode}) => (
    <ErrorBoundary
        beforeCapture={(scope) => scope.setLevel('error')}
        fallback={() => <WalletError variant="SUB_WALLET" />}
    >
        {children}
    </ErrorBoundary>
)

export const WalletError = ({variant}: {variant?: 'SUB_WALLET'}) => {
    const isSubWalletVariant = variant === 'SUB_WALLET'

    const backToWalletLabel = <Trans>Back to Wallet</Trans>

    useEffect(() => {
        tracking.walletErrorViewed()
    }, [])

    return (
        <Inline
            flexWrap="wrap"
            space={32}
            align="center"
            alignItems="center"
            css={{width: '100%', minHeight: '100%'}}
        >
            <Stack space={32} css={{maxWidth: 368}}>
                <Stack space={16}>
                    <Text variant="2xlarge-accent">
                        <Trans>Something is not quite right...</Trans>
                    </Text>
                    <Text>
                        {isSubWalletVariant ? (
                            <Trans>Try again later or contact our support team for help.</Trans>
                        ) : (
                            <Trans>Contact our support team for help or try again later.</Trans>
                        )}
                    </Text>
                </Stack>
                <ContactSupport buttonVariant="secondary" />

                {isSubWalletVariant ? (
                    <Button variant="primary" as={RouterLink} to="/wallet">
                        {backToWalletLabel}
                    </Button>
                ) : (
                    <Link as={RouterLink} to="/wallet" IconLeft={ArrowLeft}>
                        {backToWalletLabel}
                    </Link>
                )}
            </Stack>
            <Image src={isSubWalletVariant ? crackedEggImage : blackCatImage} alt={t`Black cat`} />
        </Inline>
    )
}

const Image = styled.img`
    width: 300px;
`
