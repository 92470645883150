import type {Company, Organization} from '@pleo-io/deimos'

import {useCompanyUser, useIsOrganizationContext} from '@product-web/shared--user'
import {absurd} from '@product-web/shared--utils'

const switchSubject: <R>(cases: {
    company: (company: Company, organization?: Organization) => R
    organization: (organization: Organization) => R
}) => (subject: Subject) => R =
    ({company, organization}) =>
    (subject) => {
        switch (subject._tag) {
            case 'company':
                return company(subject.company)
            case 'organization':
                return organization(subject.organization)
            default:
                return absurd(subject)
        }
    }

type Subject = CompanySubject | OrganizationSubject

type CompanySubject = {
    _tag: 'company'
    company: Company
}

type OrganizationSubject = {
    _tag: 'organization'
    organization: Organization
}

export const companySubject = (company: Company): Subject => ({
    _tag: 'company',
    company,
})

const organizationSubject = (organization: Organization): Subject => ({
    _tag: 'organization',
    organization,
})

export const subjectURN = switchSubject({
    company: (company) => `urn:pleo:company:${company.id}`,
    organization: (organization) => `urn:pleo:organization:${organization.id}`,
})

/**
 * @returns the URN of the currently active company or organization
 */
export const useSubjectURN = () => {
    const companyUser = useCompanyUser()
    const isOrganizationUser = useIsOrganizationContext()

    return subjectURN(
        isOrganizationUser
            ? organizationSubject(companyUser.organization!)
            : companySubject(companyUser.company),
    )
}
