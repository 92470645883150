import {Trans} from '@lingui/macro'
import {AnimatePresence, motion} from 'framer-motion'
import {type ReactNode, useState} from 'react'
import styled from 'styled-components'

import {Button, Inline, Modal, Stack, Text, tokens} from '@pleo-io/telescope'

import type {Direction, Step} from '@product-web/feature--ui-guides/guide'
import {useGuide, variants} from '@product-web/feature--ui-guides/guide'
import {Indicator} from '@product-web/feature--ui-guides/indicator'

import betterControlIllustration from '../../images/better-control.svg'
import easierReconciliationIllustration from '../../images/easier-reconciliation.svg'
import invoiceProcessingIllustration from '../../images/invoice-processing.svg'
import predictablePaymentsIllustration from '../../images/predictable-payments.svg'
import settleInvoicesIllustration from '../../images/settle-invoices.svg'

interface LearnMoreModalProps {
    isOpen: boolean
    close: () => void
}

export const InvoicesModal = ({isOpen, close}: LearnMoreModalProps) => {
    const [direction, setDirection] = useState<Direction>('left')

    const onClose = () => {
        close()
        onSetActiveStep(0)
    }

    const {active, onNextStep, onPreviousStep, onCompleted, onSetActiveStep} = useGuide({
        steps,
        onClose,
    })
    const activeStepData = steps[active]

    return (
        <StyledModal isOpen={isOpen} aria-labelledby="invoices-learn-more-modal-title">
            <Modal.Close onClick={onClose} data-testid="invoices-learn-more-modal-close" />
            <AnimatePresence initial={false} exitBeforeEnter>
                <motion.div
                    key={`${active}`}
                    variants={variants}
                    custom={direction}
                    initial="enter"
                    animate="center"
                    exit="exit"
                >
                    <Modal.Title id="invoices-learn-more-modal-title">
                        {activeStepData.title}
                    </Modal.Title>
                    <Modal.Content>
                        <Stack space={20} align="center">
                            <IllustrationWrapper>
                                <img
                                    src={activeStepData.illustrationPath}
                                    alt=""
                                    width="auto"
                                    height="220"
                                />
                            </IllustrationWrapper>
                            <Text>{activeStepData.content}</Text>
                            <Inline>
                                <Indicator
                                    steps={steps}
                                    active={active}
                                    onClickStepIndicator={(stepIndex) => onSetActiveStep(stepIndex)}
                                />
                            </Inline>
                        </Stack>
                    </Modal.Content>
                    <Modal.Actions>
                        <Inline space={16}>
                            {active === 0 ? (
                                <Button variant="secondary" onClick={onClose}>
                                    <Trans>Not now</Trans>
                                </Button>
                            ) : (
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        setDirection('right')
                                        onPreviousStep()
                                    }}
                                >
                                    <Trans>Back</Trans>
                                </Button>
                            )}
                            {active === steps.length - 1 ? (
                                <Button variant="primary" onClick={onCompleted}>
                                    <Trans>Done</Trans>
                                </Button>
                            ) : (
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        setDirection('left')
                                        onNextStep()
                                    }}
                                >
                                    <Trans>Next</Trans>
                                </Button>
                            )}
                        </Inline>
                    </Modal.Actions>
                </motion.div>
            </AnimatePresence>
        </StyledModal>
    )
}

const StyledModal = styled(Modal)`
    width: 100%;
    max-width: 500px;
    height: auto;
`

const IllustrationWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: ${tokens.colorBackgroundInteractiveQuietHover};
`

const steps: Array<Step & {content: ReactNode; illustrationPath: string}> = [
    {
        key: 'step1',
        title: <Trans>Settle invoices 10x faster</Trans>,
        illustrationPath: settleInvoicesIllustration,
        content: (
            <Trans>
                Free yourself from manual invoice processing while keeping control of cashflow
            </Trans>
        ),
        color: tokens.colorContentInteractiveQuiet,
    },
    {
        key: 'step2',
        title: <Trans>Automatic invoice processing</Trans>,
        illustrationPath: invoiceProcessingIllustration,
        content: (
            <Trans>
                When an invoice is uploaded, our OCR technology automatically populates data for
                you. That's hours saved
            </Trans>
        ),
        color: tokens.colorContentInteractiveQuiet,
    },
    {
        key: 'step3',
        title: <Trans>Better control with approvals</Trans>,
        illustrationPath: betterControlIllustration,
        content: (
            <Trans>
                Assign anyone in your team to make sure that the right people review the right
                invoices
            </Trans>
        ),
        color: tokens.colorContentInteractiveQuiet,
    },
    {
        key: 'step4',
        title: <Trans>Predictable payments and cashflow</Trans>,
        illustrationPath: predictablePaymentsIllustration,
        content: (
            <Trans>
                With free local transfers, 2.5% FX, and more than 50+ supported currencies, you can
                pay your suppliers using Pleo
            </Trans>
        ),
        color: tokens.colorContentInteractiveQuiet,
    },
    {
        key: 'step5',
        title: <Trans>Easier reconciliation</Trans>,
        illustrationPath: easierReconciliationIllustration,
        content: (
            <Trans>
                Download data in the format you need or export directly to QuickBooks, Xero or DATEV
            </Trans>
        ),
        color: tokens.colorContentInteractiveQuiet,
    },
]
