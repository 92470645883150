/**
 * Hubspot helper methods for interacting with the Hubspot API.
 *
 * Currently supports:
 * - `addPrivacyConsentListener`
 *
 * API commands not implemented:
 * - `setHubSpotConsent`
 * - `showBanner`
 * - `setDoNotTrack`
 * - `revokeCookieConsent`
 *
 * Hubspot API Reference:
 * https://developers.hubspot.com/beta-docs/reference/api/analytics-and-events/cookie-banner/cookie-banner-api
 */

import type {HubspotConsentListenerResponse} from './api.types'

/**
 * Access or initialize the global Hubspot API command queue.
 */
const _globalHubspotAPI = () => {
    window._hsp = window._hsp || []
    return window._hsp
}

/**
 * Register a callback to listen for changes in Hubspot consent preferences.
 *
 * @param callback - Function to invoke when consent preferences change.
 */
const addPrivacyConsentListener = (
    callback: (currentConsent: HubspotConsentListenerResponse) => void,
) => {
    _globalHubspotAPI().push(['addPrivacyConsentListener', callback])
}

/**
 * Show the Hubspot cookie banner.
 *
 * Use this method to programmatically display the Hubspot cookie banner.
 */
const showBanner = () => {
    _globalHubspotAPI().push(['showBanner'])
}

/**
 * Exported Hubspot API methods.
 */
export const hubspotAPI = {
    addPrivacyConsentListener,
    showBanner,
}
