import {tokens} from '@pleo-io/telescope'

import {PricingPlanModalWrapper} from '@product-web/shared--paywall/pricing-plan-modal/pricing-plan-modal-wrapper'
import {PlanUpgradeSource} from '@product-web/shared--paywall/types/plans'

export const PricingInfoModal = ({onDismiss}: {onDismiss: () => void}) => {
    return (
        <PricingPlanModalWrapper
            isOpen={true}
            onDismiss={onDismiss}
            pageOrigin="multi-entity"
            planUpgradeSource={PlanUpgradeSource.MULTI_ENTITY}
            dangerouslySetZIndexValue={tokens.zIndexPopover}
        />
    )
}
