import {useCompanyEntitlements} from '@product-web/shared--api-deimos/entitlement'
import type {FeatureType} from '@product-web/shared--bff-moons/generated/janus'
import {useUser} from '@product-web/shared--user'

import {entitlementBff} from './bff-hooks'

/**
 * @deprecated use useCompanyFeatureEntitlement instead
 */
export function useFeatureEntitlement(feature?: FeatureType) {
    const user = useUser()
    const {data: entitlements, error} = useCompanyEntitlements(feature ? user?.companyId : null)

    const isLoading = !entitlements && !error
    const isEntitled = !!entitlements?.includedFeatures
        ?.concat(entitlements?.addons)
        ?.find(({type}) => type === feature)
    return {
        isEntitled,
        isLoading,
    }
}

export function useCompanyFeatureEntitlement(feature?: FeatureType) {
    const {data: entitlements, isLoading} =
        entitlementBff.companyEntitlements.getEntitlements.useQuery()
    const isEntitled = !!entitlements?.find(({type}) => type === feature)
    return {
        isEntitled,
        isLoading,
    }
}
