import {createBffHooks} from '@product-web/shared--bff-client'

import type {
    ResultsStatus,
    TagBudget,
    TagBudgetActionDetails,
    TagBudgetEditResponse,
    TagBudgetReviewer,
    TagBudgetTagsFormEntity,
    UserPermissionsData,
} from './index.bff'
import type {BudgetsRouter} from './index.bff'
import type {TagBudgetEditSchemaType} from './lib/tag-budget-api.bff'
import type {TagBudgetCreateSchemaType} from './screens/create-tag-budget/create-tag-budget.bff'

export type {
    TagBudgetEditResponse,
    UserPermissionsData,
    ResultsStatus,
    TagBudget,
    TagBudgetReviewer,
    TagBudgetTagsFormEntity,
    TagBudgetActionDetails,
    TagBudgetCreateSchemaType,
    TagBudgetEditSchemaType,
}

export const bffHooks = createBffHooks<BudgetsRouter>()

export const bff = bffHooks.budgets
