import type {HubspotConsentPreferences} from './api.types'

/**
 * Default consent state.
 *
 * All consent categories are set to false by default, to align with GDPR.
 */
export const defaultConsentState: HubspotConsentPreferences = {
    allowed: false,
    categories: {
        analytics: false,
        advertisement: false,
        functionality: false,
        necessary: false,
    },
}

/**
 * Consent granted state.
 *
 * All consent categories are set to true. Used for when the feature flag is disabled.
 */
export const consentGrantedState: HubspotConsentPreferences = {
    allowed: true,
    categories: {
        analytics: true,
        advertisement: true,
        functionality: true,
        necessary: true,
    },
}
