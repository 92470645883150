import {useLoadAsyncBirdieScript} from './load-birdie-script'

/**
 * Load all 3rd party scripts that are needed for the app
 *
 * This is a wrapper around all the individual scripts that need to be loaded.
 */
export const useLoadAsyncScripts = () => {
    useLoadAsyncBirdieScript()
}
