import {t, Trans} from '@lingui/macro'
import {useEffect} from 'react'
import {useNavigate} from 'react-router'

import {Text} from '@pleo-io/telescope'

import {AdoptionPrompt} from '@product-web/feature--help-centre/adoption-prompt'

import PocketAdoptionPromptIllustration from './pocket-adoption-prompt.png'
import {usePocketAdoption} from './use-pocket-adoption'

import {trackPocketAdoptionPromptActioned} from '../lib/tracking'

export const PocketAdoptionPrompt = () => {
    const {shouldShowPocketAdoptionPrompt, setPocketAdoptionPromptViewed} = usePocketAdoption()

    if (!shouldShowPocketAdoptionPrompt) {
        return null
    }

    return (
        <PocketAdoptionPromptPresentation
            setPocketAdoptionPromptViewed={setPocketAdoptionPromptViewed}
        />
    )
}

interface PocketAdoptionPromptPresentationProps {
    setPocketAdoptionPromptViewed: () => void
}

export const PocketAdoptionPromptPresentation = ({
    setPocketAdoptionPromptViewed,
}: PocketAdoptionPromptPresentationProps) => {
    const navigate = useNavigate()

    useEffect(() => {
        trackPocketAdoptionPromptActioned({action: 'viewed'})
    }, [])

    return (
        <AdoptionPrompt
            title={<Trans>Need to reimburse out-of-pocket expenses?</Trans>}
            text={
                <Trans>
                    Sometimes employees use their own money for company expenses. You can manage
                    these transactions with{' '}
                    <Text as="span" variant="small-subtle" weight="bold">
                        Reimbursements
                    </Text>
                    .
                </Trans>
            }
            imageSrc={PocketAdoptionPromptIllustration}
            completeText={t`Explore`}
            dismissText={t`Not now`}
            name="pocket-adoption-prompt"
            onComplete={() => {
                trackPocketAdoptionPromptActioned({action: 'explore_pocket'})
                setPocketAdoptionPromptViewed()
                navigate('/reimbursements')
            }}
            onDismiss={() => {
                trackPocketAdoptionPromptActioned({action: 'not_now'})
                setPocketAdoptionPromptViewed()
            }}
            size="medium"
        />
    )
}
