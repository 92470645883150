import {t} from '@lingui/macro'
import useSWR from 'swr'

import {request} from '@product-web/shared--api'
import {getDeimos} from '@product-web/shared--api-deimos/helpers'
import {getRequestErrorStatus} from '@product-web/shared--api-error'
import type {AutoTopUpSettings, CompanyTopUpSettings} from '@product-web/shared--api-types/wallet'
import config from '@product-web/shared--config'
import {reportError} from '@product-web/shared--error/report'
import {useToaster} from '@product-web/shared--toaster'
import {useLoggedInUser, useUser} from '@product-web/shared--user'
import {invariant} from '@product-web/shared--utils'

import type {AutoTopUpSettingsParams, ManageAutoTopUpParams} from './tracking-manage-auto-top-up'
import {ManageAutoTopUpAction, trackManageAutoTopUp} from './tracking-manage-auto-top-up'

const baseUrl = config.endpoints.api

async function updateCompanyTopUpSettings({
    companyId,
    payload,
}: {
    companyId: string
    payload: Partial<CompanyTopUpSettings>
}): Promise<CompanyTopUpSettings> {
    return request(`${baseUrl}/wallet-topup/rest/v1/companies/${companyId}/settings`, {
        auth: 'user',
        method: 'PUT',
        body: payload,
    })
}

function buildCompanyTopUpSettingsPayload(
    originalSettings: CompanyTopUpSettings | undefined,
    patchSettings: Partial<CompanyTopUpSettings>,
): CompanyTopUpSettings {
    return {
        ...originalSettings,
        ...patchSettings,
        // this line is necessary to avoid TS error
        autoTopupStatus:
            patchSettings.autoTopupStatus ??
            originalSettings?.autoTopupStatus ??
            'DISABLED_BY_USER',
    }
}

// Todo: will be removed, once we fully switched to the BFF rails
function useSafeUpdateCompanyTopUpSettings() {
    const user = useUser()
    const companyId = user?.companyId

    const originalCompanyTopUpSettings = useSWR<CompanyTopUpSettings>(
        companyId ? `/wallet-topup/rest/v1/companies/${companyId}/settings` : null,
        getDeimos,
        {revalidateIfStale: false},
    )

    return async function (companyTopUpSettingsPatch: Partial<CompanyTopUpSettings>) {
        invariant(companyId, 'Company ID needs to be present to update Company Top-up settings')

        let payload = buildCompanyTopUpSettingsPayload(
            originalCompanyTopUpSettings.data,
            companyTopUpSettingsPatch,
        )

        return await updateCompanyTopUpSettings({companyId, payload}).catch(async (error) => {
            const errorStatus = getRequestErrorStatus(error)
            if (errorStatus !== 409) {
                throw error
            }

            // we handle "409 - Conflict" error:
            // fetching the latest settings and apply patch on it -> call BE again (only once)
            const latestSettings = await originalCompanyTopUpSettings.mutate()
            payload = buildCompanyTopUpSettingsPayload(latestSettings, companyTopUpSettingsPatch)

            return updateCompanyTopUpSettings({companyId, payload})
        })
    }
}

function trackTopUpSettingsUpdate(
    companyTopUpSettings: Partial<CompanyTopUpSettings>,
    prevTopUpSettings?: CompanyTopUpSettings,
) {
    if (companyTopUpSettings.autoTopupType && companyTopUpSettings.autoTopupStatus) {
        const commonTrackingParams: AutoTopUpSettingsParams = {
            atu_type: companyTopUpSettings.autoTopupType,
            atu_status: companyTopUpSettings.autoTopupStatus,
            atu_amount_low_balance: companyTopUpSettings.lowBalanceTopup?.amount,
            atu_threshold_low_balance: companyTopUpSettings.lowBalanceTopup?.threshold,
            atu_amount_scheduled: companyTopUpSettings.scheduledTopup?.amount,
            atu_frequency_scheduled: companyTopUpSettings.scheduledTopup?.frequency,
        }

        let analyticsParams: ManageAutoTopUpParams
        if (
            !prevTopUpSettings?.autoTopupType ||
            (prevTopUpSettings?.autoTopupStatus !== 'ENABLED' &&
                prevTopUpSettings?.autoTopupStatus !== 'PENDING')
        ) {
            // if prev ATU is not active
            analyticsParams = {
                action: ManageAutoTopUpAction.ACTIVATE,
                ...commonTrackingParams,
            }
        } else if (
            companyTopUpSettings.autoTopupStatus === 'DISABLED' ||
            companyTopUpSettings.autoTopupStatus === 'DISABLED_BY_USER'
        ) {
            // if user wants to disable ATU
            analyticsParams = {
                action: ManageAutoTopUpAction.DISABLE,
                ...commonTrackingParams,
            }
        } else if (prevTopUpSettings.autoTopupType === companyTopUpSettings.autoTopupType) {
            // if something was changed, but prev ATU type is the same
            analyticsParams = {
                action: ManageAutoTopUpAction.CHANGE_SETTINGS,
                ...commonTrackingParams,
            }
        } else {
            // if prev ATU type is NOT the same
            analyticsParams = {
                action: ManageAutoTopUpAction.SWITCH_STRATEGY,
                prev_auto_top_up_type: prevTopUpSettings.autoTopupType,
                ...commonTrackingParams,
            }
        }

        trackManageAutoTopUp(analyticsParams)
    }
}

export function useCompanyTopUpSettings() {
    const {showToast} = useToaster()
    const user = useUser()
    const companyId = user?.companyId

    const result = useSWR<CompanyTopUpSettings>(
        companyId ? `/wallet-topup/rest/v1/companies/${companyId}/settings` : null,
        getDeimos,
        {revalidateIfStale: false},
    )

    const isLoading = !result.data && !result.error

    const safeUpdateCompanyTopUpSettings = useSafeUpdateCompanyTopUpSettings()

    async function updateTopUpSettings(companyTopUpSettings: Partial<CompanyTopUpSettings>) {
        if (!companyId || !result) {
            showToast(
                t`We could not save the details of your Auto Top-Up settings correctly. Please reach out to support.`,
                {level: 'error'},
            )
            return
        }

        trackTopUpSettingsUpdate(companyTopUpSettings, result.data)

        await result.mutate(safeUpdateCompanyTopUpSettings(companyTopUpSettings), false)
    }

    async function disableAutoTopUp() {
        if (!companyId || !result) {
            return
        }

        const payload: CompanyTopUpSettings = {
            ...result.data,
            autoTopupStatus: 'DISABLED_BY_USER',
        }

        await updateTopUpSettings(payload)
    }

    return {
        ...result,
        isLoading,
        mutations: {updateTopUpSettings, disableAutoTopUp},
    }
}

export function useAutoTopUpSettings() {
    const {data: companyTopUpSettings, isLoading} = useCompanyTopUpSettings()
    const {
        autoTopupStatus = 'DISABLED_BY_USER',
        autoTopupType,
        paymentRail,
        lowBalanceTopup,
        scheduledTopup,
    } = companyTopUpSettings ?? {}

    const autoTopUp: AutoTopUpSettings = {
        autoTopupStatus,
        autoTopupType,
        paymentRail,
        lowBalanceTopup,
        scheduledTopup,
    }

    return {
        autoTopUp,
        isLoading,
    }
}

export function useAutoTopUpEnabled() {
    const {autoTopUp} = useAutoTopUpSettings()
    const {autoTopupStatus, autoTopupType} = autoTopUp ?? {}
    const isAutoTopUpEnabled = autoTopupStatus === 'ENABLED' && autoTopupType === 'LOW_BALANCE'
    const isScheduledTopUpEnabled = autoTopupStatus === 'ENABLED' && autoTopupType === 'SCHEDULED'

    return {
        autoTopupStatus,
        autoTopupType,
        isAutoTopUpEnabled,
        isScheduledTopUpEnabled,
    }
}

export function useWalletDryThreshold() {
    const {showToast} = useToaster()
    const user = useLoggedInUser()
    const companyId = user?.companyId

    const {
        data: companyTopUpSettings,
        isLoading,
        mutations: {updateTopUpSettings},
    } = useCompanyTopUpSettings()
    const {dryThreshold: walletDryThreshold} = companyTopUpSettings ?? {}

    // todo: move to BFF - https://linear.app/pleo/issue/WALLE-4887
    async function updateWalletDryThreshold(dryThreshold: number | undefined) {
        if (!companyId) {
            return
        }

        try {
            await updateTopUpSettings({
                dryThreshold,
            })
        } catch (error) {
            reportError(error)
            showToast(t`Failed to update company settings`, {level: 'error'})
        }
    }

    return {
        walletDryThreshold: walletDryThreshold ?? null,
        isLoading,
        mutations: {updateWalletDryThreshold},
    }
}
