import type {Dispatch, SetStateAction} from 'react'

import type {CompanyAdoptionGuideResponse} from '@pleo-io/auxo-ts-models'
import {Link, Stack, Text} from '@pleo-io/telescope'

import tracking from '@product-web/shared--analytics'

import {AdoptionTasksList} from './adoption-tasks-list'

import type {AdoptionAccordionView} from '../adoption-accordion'

interface AdoptionGuideProps {
    title: string
    description: string
    setView: Dispatch<SetStateAction<AdoptionAccordionView>>
    guide: CompanyAdoptionGuideResponse
    firstTask: string
    children: React.ReactNode
    linkText?: string
    helperPage?: AdoptionAccordionView
}

const AdoptionGuide = ({
    title,
    description,
    setView,
    guide,
    linkText,
    helperPage,
    firstTask,
    children,
}: AdoptionGuideProps) => {
    const onShowHelperPage = () => {
        setView(helperPage!)

        tracking.adoptionEssentialsCentreActioned({
            action: 'viewed_guide',
            guide: guide.adoptionGuide,
            page: helperPage?.toLowerCase(),
        })
    }

    return (
        <Stack align="left">
            <Stack marginBottom={28} space={8}>
                <Text variant="3xlarge-accent">{title}</Text>
                <Text variant="medium-default" align="left">
                    {description}
                </Text>
                {helperPage && (
                    <Link onClick={onShowHelperPage} data-testid="adoption-guide-link">
                        {linkText}
                    </Link>
                )}
            </Stack>
            <AdoptionTasksList firstTask={firstTask}>{children}</AdoptionTasksList>
        </Stack>
    )
}

export default AdoptionGuide
