import {t} from '@lingui/macro'

import {Link} from '@pleo-io/telescope'

import {useAppLanguage} from '@product-web/shared--i18n'
import {SupportedLanguage} from '@product-web/shared--i18n'
import googlePlayBadgeDa from '@product-web/shared--images/google-play-badge-da.png'
import googlePlayBadgeDe from '@product-web/shared--images/google-play-badge-de.png'
import googlePlayBadgeEn from '@product-web/shared--images/google-play-badge-en.png'
import googlePlayBadgeEs from '@product-web/shared--images/google-play-badge-es.png'
import googlePlayBadgeFr from '@product-web/shared--images/google-play-badge-fr.png'
import googlePlayBadgeNl from '@product-web/shared--images/google-play-badge-nl.png'
import googlePlayBadgeSv from '@product-web/shared--images/google-play-badge-sv.png'

const playStoreBadges = {
    [SupportedLanguage.EN]: googlePlayBadgeEn,
    [SupportedLanguage.DE]: googlePlayBadgeDe,
    [SupportedLanguage.DE_AT]: googlePlayBadgeDe,
    [SupportedLanguage.ES]: googlePlayBadgeEs,
    [SupportedLanguage.SV]: googlePlayBadgeSv,
    [SupportedLanguage.DA]: googlePlayBadgeDa,
    [SupportedLanguage.FR]: googlePlayBadgeFr,
    [SupportedLanguage.FR_BE]: googlePlayBadgeFr,
    [SupportedLanguage.FI]: googlePlayBadgeSv,
    [SupportedLanguage.NL]: googlePlayBadgeNl,
    [SupportedLanguage.NL_BE]: googlePlayBadgeNl,
    [SupportedLanguage.FI]: googlePlayBadgeEn,
    [SupportedLanguage.PT]: googlePlayBadgeEn,
    [SupportedLanguage.IT]: googlePlayBadgeEn,
    [SupportedLanguage.NO]: googlePlayBadgeEn,
}

const getPlayStoreBadge = (language: SupportedLanguage) =>
    playStoreBadges[language] || googlePlayBadgeEn

interface PlayStoreBadgeProps {
    onClick?: () => void
    height?: number
    width?: number
}

export const PlayStoreBadge = ({onClick, height, width}: PlayStoreBadgeProps) => {
    const language = useAppLanguage()

    return (
        <Link
            href="https://play.google.com/store/apps/details?id=io.pleo.android"
            onClick={onClick}
            css={{fontSize: 0}}
        >
            <img
                alt={t`Get it on Google Play`}
                src={getPlayStoreBadge(language)}
                height={height ?? 'auto'}
                width={width ?? 'auto'}
            />
        </Link>
    )
}
