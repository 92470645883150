import Alert from '@reach/alert'
import Portal from '@reach/portal'
import {AnimatePresence, motion} from 'framer-motion'
import React from 'react'
import styled, {css} from 'styled-components'

import {NakedButton, tokens} from '@pleo-io/telescope'
import {Close} from '@pleo-io/telescope-icons'

import {getBreakpoint} from '@product-web/shared--styles/theme'

const DEFAULT_TOAST_DURATION_MS = 5000

////////////////////////////////////////////////////////////////////////////////
//#region Types

type ToastLevel = 'info' | 'success' | 'warning' | 'error'
type ToastData = {
    id: string
    message: string
    level: ToastLevel
    isDismissable?: boolean
    title?: string
}
export type ToastConfig = {
    durationMs?: number
    isDismissable?: boolean
    title?: string
    level?: ToastLevel
}
type ShowToast = (message: string, toastConfig?: ToastConfig) => string
type HideToast = (toastId: string) => void
export type ToasterAPI = {
    toasts: ToastData[]
    showToast: ShowToast
    hideToast: HideToast
}

//#endregion Types
////////////////////////////////////////////////////////////////////////////////

const randomString = (length?: number) => Math.random().toString(16).substr(2, length)

export const ToasterProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
    const [toasts, dispatch] = React.useReducer(toasterReducer, [])
    const hideToast = React.useCallback((id: string) => dispatch({type: 'remove', payload: id}), [])
    const showToast = React.useCallback((message: string, toastConfig?: ToastConfig) => {
        const toast = prepareToast(message, toastConfig)
        dispatch({type: 'add', payload: toast})
        setTimeout(() => hideToast(toast.id), toastConfig?.durationMs ?? DEFAULT_TOAST_DURATION_MS)
        return toast.id
    }, [])
    const contextValue = React.useMemo(() => ({showToast, hideToast, toasts}), [toasts])
    return <ToasterContext.Provider value={contextValue}>{children}</ToasterContext.Provider>
}

export const Toaster = ({
    isLoggedIn,
    isNavigationVisible,
    navigationWidth,
}: {
    isLoggedIn: boolean
    isNavigationVisible: boolean
    navigationWidth: number
}) => {
    const {toasts, hideToast} = useToaster()
    const offsetNavigation = isLoggedIn && isNavigationVisible

    return (
        <Portal>
            <ToasterWrapper $offsetNavigation={offsetNavigation} $navigationWidth={navigationWidth}>
                <AnimatePresence initial={false}>
                    {toasts.map((toast) => (
                        <motion.div
                            key={toast.id}
                            layout
                            initial={{opacity: 0, y: 50, scale: 0.3}}
                            style={{maxWidth: 280}}
                            animate={{opacity: 1, y: 0, scale: 1}}
                            exit={{opacity: 0, scale: 0.5, transition: {duration: 0.23}}}
                            drag={toast.isDismissable ? 'x' : undefined}
                            dragConstraints={{left: 0, right: 0}}
                            onDrag={(_, info) => {
                                if (Math.abs(info.offset.x) > 200) {
                                    hideToast(toast.id)
                                }
                            }}
                        >
                            <Toast toast={toast} onHide={hideToast} />
                        </motion.div>
                    ))}
                </AnimatePresence>
            </ToasterWrapper>
        </Portal>
    )
}

export const Toast: React.FC<React.PropsWithChildren<{toast: ToastData; onHide: HideToast}>> = ({
    toast,
    onHide,
}) => {
    return (
        <ToastWrapper level={toast.level}>
            {toast.isDismissable && (
                <CloseButton onClick={() => onHide(toast.id)}>
                    <Close />
                </CloseButton>
            )}
            {toast.title?.trim() && <ToastTitle>{toast.title}</ToastTitle>}
            {toast.message}
        </ToastWrapper>
    )
}

export function useToaster() {
    return React.useContext(ToasterContext)
}

export const ToasterContext = React.createContext<ToasterAPI>({
    showToast: () => '',
    hideToast: () => {},
    toasts: [],
})

function prepareToast(message: string, toastConfig?: ToastConfig): ToastData {
    const id = randomString(15)

    return {
        id,
        message,
        isDismissable: toastConfig?.isDismissable ?? true,
        level: toastConfig?.level ?? 'info',
        title: toastConfig?.title,
    }
}

type Action = {type: 'add'; payload: ToastData} | {type: 'remove'; payload: string}

function toasterReducer(toasts: ToastData[], action: Action) {
    switch (action.type) {
        case 'remove':
            return toasts.filter((toast) => toast.id !== action.payload)
        case 'add':
            return [...toasts, action.payload]
        default:
            throw new Error('Unexpected action in toaster reducer')
    }
}

////////////////////////////////////////////////////////////////////////////////
//#region Styled Components

const ToasterWrapper = styled.div<{$offsetNavigation: boolean; $navigationWidth: number}>`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    pointer-events: none;
    justify-content: flex-end;
    z-index: calc(${tokens.zIndexModal} + 1);
    padding: ${tokens.spacing24};

    ${(props) =>
        props.$offsetNavigation &&
        css`
            @media (min-width: ${getBreakpoint('tabletUp')}) {
                padding-left: calc(${props.$navigationWidth}px + ${tokens.spacing24});
            }
        `}
`
const getColorName = (level: ToastLevel) => {
    if (level === 'error') {
        return tokens.red500
    }
    if (level === 'success') {
        return tokens.green500
    }
    if (level === 'warning') {
        return tokens.yellow500
    }
    return tokens.blue500
}
const ToastWrapper = styled(Alert)<{level: ToastLevel}>`
    padding: ${tokens.spacing20};
    max-width: 280px;
    min-width: 200px;

    @media (min-width: ${getBreakpoint('tabletUp')}) {
        min-width: 280px;
    }

    margin-top: ${tokens.spacing16};
    font-size: 14px;
    line-height: ${tokens.lineHeight3};
    pointer-events: all;
    position: relative;
    color: ${tokens.shade600};
    text-align: left;
    background-color: ${tokens.shade000};
    border: ${tokens.borderPrimary};
    border-top: 5px solid ${(props) => getColorName(props.level)}; /* stylelint-disable-line */
    border-radius: ${tokens.arc8};
    box-shadow: ${tokens.shadowElevateQuiet};
    overflow-wrap: break-word;
`
const ToastTitle = styled.h6`
    margin-right: ${tokens.spacing20};
    font-weight: ${tokens.fontWeightSemibold};
`
const CloseButton = styled(NakedButton)`
    position: absolute;
    top: 10px;
    right: 10px;
`

//#endregion Styled Components
////////////////////////////////////////////////////////////////////////////////
