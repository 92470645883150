import {datadogRum} from '@datadog/browser-rum-slim'
import React from 'react'

import {useTokenData} from '@product-web/shared--auth--session/context'
import config from '@product-web/shared--config'
import {useCurrentSubApp} from '@product-web/shared--routes/use-current-sub-app'

const DEFAULT_SERVICE = 'browser-product-web'
export function initDataDog() {
    // Initializes Datadog's static interface.
    datadogRum.init({
        service: DEFAULT_SERVICE,
        allowedTracingUrls: [
            /^https:\/\/api(?!\.insights)[^/?#]*\.pleo\.io\//,
            /^https:\/\/auth[^/?#]*\.pleo\.io\//,
            /^https:\/\/[^/?#]*\.lambda-url\.[^/?#]*\.on\.aws\//,
        ],
        clientToken: config.datadog.token,
        applicationId: config.datadog.rumAppId,
        site: 'datadoghq.eu',
        env: config.environment,
        version: config.version,
        trackResources: true,
        telemetrySampleRate: 0,
        // This is necessary to assign services (sub apps) to views
        // See https://app.datadoghq.eu/release-notes/split-your-rum-applications-into-services-to-make-engineers-more-productive-with-rum
        trackViewsManually: true,
        // We disable attempting to send requests when running inside Cypress to get rid of noise
        sessionSampleRate: window.Cypress ? 0 : 50,
        // Never enable trackUserInteractions! It can leak sensitive data.
        // See https://www.notion.so/pleo/Exposed-customer-card-details-in-Datadog-logs-c73a959fed5c428b9a4dd387b5a6ee07 (trackUserInteractions was previously trackInteractions)
        trackUserInteractions: false,
        // See privacy options https://docs.datadoghq.com/real_user_monitoring/session_replay/privacy_options/
        // And related issue that required this change https://linear.app/pleo/issue/AX-1888/datadog-rums-synthetic-test-is-recording-and-exposing-card-details-on
        defaultPrivacyLevel: 'mask',
    })

    // datadog rum currently does not support setting tags on RUM https://github.com/DataDog/browser-sdk/issues/462
    // this will at least set steward and cost_allocation as global context
    // we could use a remapper to to copy these attributes to tags https://github.com/DataDog/browser-sdk/issues/462#issuecomment-893350296
    datadogRum.setGlobalContextProperty('steward', 'team-web-core')
    datadogRum.setGlobalContextProperty('cost_allocation', 'team-web-core')
}

export function useEnhancedDataDog() {
    const subApp = useCurrentSubApp()
    const tokenData = useTokenData()
    const userId = tokenData?.user.id
    const companyId = tokenData?.user.cmp

    React.useEffect(() => {
        if (userId) {
            datadogRum.setUser({id: userId, companyId})
        } else {
            datadogRum.clearUser()
        }
    }, [userId, companyId])

    React.useEffect(() => {
        datadogRum.startView({service: subApp ? `${DEFAULT_SERVICE}-${subApp}` : DEFAULT_SERVICE})
    }, [subApp])
}
