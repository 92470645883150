import {t} from '@lingui/macro'
import {type PropsWithChildren, useMemo} from 'react'
import styled from 'styled-components'

import type {Person} from '@pleo-io/deimos'
import {Avatar, type AvatarProps, focusRing, NakedButton, tokens} from '@pleo-io/telescope'
import {TrashClock, Wallet} from '@pleo-io/telescope-icons'

import type {UserData} from '@product-web/shared--user-types'

export const getName = (employee?: Partial<Person>) => {
    if (!employee) {
        return '?'
    }

    const initials = [(employee.firstName || '')[0], (employee.lastName || '')[0]]
        .join('')
        .toUpperCase()

    if (initials) {
        return `${employee?.firstName || ''} ${employee?.lastName || ''}`
    }

    return employee.email || '?'
}

const employeeStatusToAvatarState: Record<AvatarStatus, AvatarProps['state']> = {
    invited: 'placeholder',
    deactivated: 'disabled',
    active: 'default',
}

export type AvatarStatus = 'invited' | 'deactivated' | 'active'
export type AvatarWithStatusProps = AvatarProps & {status?: AvatarStatus}
export const AvatarWithStatus = ({
    status = 'active',
    children,
    ...props
}: PropsWithChildren<AvatarWithStatusProps>) => {
    const isDeactivated = status === 'deactivated'

    return (
        <Avatar state={employeeStatusToAvatarState[status]} {...props}>
            {isDeactivated ? (
                <Avatar.Badge visuallyHiddenText={t`Deactivated user`} icon={<TrashClock />} />
            ) : (
                children
            )}
        </Avatar>
    )
}

type AvatarEmployeeProps = AvatarWithStatusProps &
    Partial<Pick<UserData, 'role'>> & {
        employee?: Partial<Person>
    }

export const AvatarEmployee = ({
    employee,
    role,
    status = 'active',
    children,
    ...props
}: PropsWithChildren<AvatarEmployeeProps>) => {
    const isBookkeeper = useMemo(() => role && role.startsWith('bookkeeper'), [role])

    const icon = isBookkeeper ? <Wallet /> : undefined

    return (
        <AvatarWithStatus
            status={status}
            name={getName(employee)}
            src={employee?.avatar?.url}
            icon={icon}
            {...props}
        >
            {children}
        </AvatarWithStatus>
    )
}

// Create a button that is focusable and has a hover effect and makes the tooltip work over an Avatar
export const AvatarButtonTooltipWrapper = styled(NakedButton)`
    display: flex;
    cursor: inherit;
    border-radius: ${tokens.arc99999};
    position: relative; /** Needed for z-index change on hover */
    &:hover,
    &:focus {
        z-index: ${tokens.zIndexOverlay};
        box-shadow:
            0 0 0 2px #ffffff,
            ${tokens.shadowRaise};
    }

    transition: box-shadow ${tokens.smoothIn};
    ${focusRing('offset')};
`
