import {Trans} from '@lingui/macro'
import styled from 'styled-components'

import {
    Inline,
    Link,
    Modal,
    ModalClose,
    ModalContent,
    ModalFooter,
    ModalTitle,
    Stack,
    Tag,
    Text,
    tokens,
} from '@pleo-io/telescope'
import {
    CategoryMealsAndDrinks,
    CategorySoftware,
    CategoryTravel,
    Filter,
} from '@pleo-io/telescope-icons'

import tracking from '@product-web/shared--analytics'
import {getHelpCentreArticleLinkIntercom} from '@product-web/shared--locale/helpers'
import {breakpoints} from '@product-web/shared--styles/theme'

import PersonalCards from './images/personal_cards.svg'
import TemporaryCard from './images/temporary_card.svg'
import VendorCard from './images/vendor_card.svg'

export interface CardComparisonModalProps {
    isOpen: boolean
    onDismiss: () => void
}

export const CardComparisonModal = ({isOpen, onDismiss}: CardComparisonModalProps) => {
    return (
        <StyledModal
            isOpen={isOpen}
            aria-labelledby="plan-selection-modal-title"
            onDismiss={onDismiss}
        >
            <ModalClose onClick={onDismiss} />
            <StyledModalTitle id="plan-selection-modal-title">
                <Trans>Pleo cards</Trans>
            </StyledModalTitle>
            <StyledModalContent>
                <CardColumn>
                    <CardImage src={PersonalCards} alt="" />
                    <Stack space={12}>
                        <CardColumnTitle>
                            <Text variant="medium-default" weight="medium">
                                <Trans>Personal cards</Trans>
                            </Text>
                        </CardColumnTitle>

                        <CardSpecs>
                            <CategoryMealsAndDrinks size={16} />
                            <CardDescription variant="small-subtle">
                                <Trans>
                                    The standard Pleo cards for most types of spending. Employees
                                    have access to both a virtual and optional physical card which
                                    can be added to Apple Pay and Google Pay.
                                </Trans>
                            </CardDescription>
                        </CardSpecs>
                        <CardSpecs>
                            <Filter size={16} />
                            <CardDescription variant="small-subtle">
                                <Trans>Cards share the same limits.</Trans>
                            </CardDescription>
                        </CardSpecs>
                    </Stack>
                </CardColumn>
                <CardColumn>
                    <CardImage src={VendorCard} alt="" />
                    <Stack space={12}>
                        <Inline space={8}>
                            <Text variant="medium-default" weight="medium">
                                <Trans>Vendor cards</Trans>
                            </Text>
                            <Tag variant="gray">
                                <Trans>Advanced plan</Trans>
                            </Tag>
                        </Inline>

                        <CardSpecs>
                            <CategorySoftware size={16} />
                            <CardDescription variant="small-subtle">
                                <Trans>
                                    Virtual cards for recurring spend such as Software and
                                    Marketing. Employees can have multiple Vendor cards, and
                                    ownership can be transferred to other employees.
                                </Trans>
                            </CardDescription>
                        </CardSpecs>

                        <CardSpecs>
                            <Filter size={16} />
                            <CardDescription variant="small-subtle">
                                <Trans>Individual limits and extended settings.</Trans>
                            </CardDescription>
                        </CardSpecs>
                    </Stack>
                </CardColumn>
                <CardColumn>
                    <CardImage src={TemporaryCard} alt="" />
                    <Stack space={12}>
                        <Inline space={8}>
                            <Text variant="medium-default" weight="medium">
                                <Trans>Temporary cards</Trans>
                            </Text>
                            <Tag variant="gray">
                                <Trans>Advanced plan</Trans>
                            </Tag>
                        </Inline>

                        <CardSpecs>
                            <CategoryTravel size={16} />
                            <CardDescription variant="small-subtle">
                                <Trans>
                                    Temporary virtual cards for Reimbursement-only users that don't
                                    normally have access to cards. Great for infrequent spending
                                    needs such as trips and events.
                                </Trans>
                            </CardDescription>
                        </CardSpecs>

                        <CardSpecs>
                            <Filter size={16} />
                            <CardDescription variant="small-subtle">
                                <Trans>Defined activation period and limits.</Trans>
                            </CardDescription>
                        </CardSpecs>
                    </Stack>
                </CardColumn>
            </StyledModalContent>
            <StyledModalFooter>
                <Trans>Learn more about our </Trans>{' '}
                <Link
                    href={getHelpCentreArticleLinkIntercom('8340050-virtual-vendor-cards')}
                    target="_blank"
                    onClick={() => {
                        tracking.cardComparisonActioned({
                            action: 'vendor_cards_details_clicked',
                        })
                    }}
                >
                    <Trans>Vendor cards </Trans>{' '}
                </Link>
                <Trans> and </Trans>{' '}
                <Link
                    href={getHelpCentreArticleLinkIntercom('8556582-temporary-virtual-cards')}
                    target="_blank"
                    onClick={() => {
                        tracking.cardComparisonActioned({
                            action: 'temporary_cards_details_clicked',
                        })
                    }}
                >
                    <Trans>Temporary cards</Trans>
                </Link>
            </StyledModalFooter>
        </StyledModal>
    )
}

const StyledModal = styled(Modal)`
    width: 100%;
    max-width: 1040px;
    height: auto;
`
const StyledModalTitle = styled(ModalTitle)`
    margin-top: ${tokens.spacing16};
`
const StyledModalContent = styled(ModalContent)`
    display: flex;
    justify-content: center;
    gap: ${tokens.spacing40};
    margin-top: ${tokens.spacing40};
    padding-left: ${tokens.spacing48};
    padding-right: ${tokens.spacing48};

    @media (max-width: ${breakpoints.tabletMedUp}) {
        flex-direction: column;
    }
`
const CardColumn = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: ${tokens.spacing24};

    @media (max-width: ${breakpoints.tabletMedUp}) {
        flex-direction: row;
        align-items: center;
        column-gap: ${tokens.spacing24};
    }

    @media (max-width: ${breakpoints.mediumTabletUp}) {
        flex-direction: column;
    }
`
const CardColumnTitle = styled(Text)`
    display: flex;
    column-gap: ${tokens.spacing8};
`
const CardImage = styled.img`
    width: '100%';

    @media (max-width: ${breakpoints.tabletMedUp}) {
        width: '288px';
    }
`
const CardSpecs = styled.div`
    display: flex;
    column-gap: 12px;

    svg {
        margin-top: ${tokens.spacing2};
    }
`
const CardDescription = styled(Text)`
    color: ${tokens.colorContentStatic};
    text-align: left;
`
const StyledModalFooter = styled(ModalFooter)`
    background-color: ${tokens.colorBackgroundStaticLouder};
    margin-top: ${tokens.spacing48};
`
