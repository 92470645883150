import type {FC} from 'react'
import type {IContentLoaderProps} from 'react-content-loader'
import Loader from 'react-content-loader'

import {theme} from '@product-web/shared--styles/theme'

type ContentLoaderPreset = 'text' | 'line'

interface Props {
    height?: number | string
    width?: number | string
    preset?: ContentLoaderPreset
    // Number of times loader pattern repeats, defaults to 1
    repeatPattern?: number
}

/**
 * ContentLoader component displays animated skeleton-screen wireframe of SVG placeholder boxes
 * for content and images.
 * Can be used via preset or custom loaders.
 **/
/**
 *
 * @deprecated Use the '@pleo-io/telescope' Skeleton component
 */
export const SkeletonLoader: FC<React.PropsWithChildren<Props>> = ({
    preset,
    width = '100%',
    height = '100%',
    repeatPattern = 1,
    children,
    ...rest
}) => {
    const props: IContentLoaderProps = {
        width,
        height,
        viewBox: `0 0 ${width} ${height}`,
        speed: 2,
        backgroundColor: theme.colors.shade200,
        foregroundColor: theme.colors.shade300,
    }
    return (
        <>
            {Array.from({length: repeatPattern}, (_, i) => (
                <Loader data-generic-ui="content-loader" {...props} {...rest} key={i}>
                    {getPresetComponent(preset) ?? children}
                </Loader>
            ))}
        </>
    )
}

// Size is fixed 339 x 82
const TextPreset = () => (
    <>
        <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
        <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
        <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
        <rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
        <rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
        <rect x="0" y="71" rx="3" ry="3" width="37" height="11" />
        <rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
        <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
    </>
)

// Size is responsive (full width and height) based on the parent component
const LinePreset = () => <rect x="0" y="0" rx="8" ry="8" width="100%" height="100%" />

function getPresetComponent(preset?: ContentLoaderPreset) {
    switch (preset) {
        case 'text':
            return <TextPreset />
        case 'line':
            return <LinePreset />
        default:
            return null
    }
}
