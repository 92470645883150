import type {Step} from '@product-web/feature--ui-guides/guide'

import {useIntroStep} from './screens/intro-step'
import {useSuccessStep} from './screens/success-step'
import {useAddOrGroupStep} from './screens/v2/add-or-group-step'
import {useGroupStep} from './screens/v2/group-step'

export const useSteps = ({isOrganizationAdmin}: {isOrganizationAdmin: boolean}): Step[] => {
    const successStep = useSuccessStep()
    const singleEntityAdminIntroStep = useIntroStep()
    const addOrGroupStep = useAddOrGroupStep({isOrganizationAdmin})
    const groupStep = useGroupStep({isOrganizationAdmin})

    const singleEntityAdminSteps = [
        singleEntityAdminIntroStep,
        addOrGroupStep,
        groupStep,
        successStep,
    ]
    const organizationAdminSteps = [addOrGroupStep, groupStep, successStep]

    return isOrganizationAdmin ? organizationAdminSteps : singleEntityAdminSteps
}
