import * as React from 'react'
import {Route} from 'react-router-dom'

import GeneralSettingsWrapper from '@product-web/feature--settings/general-settings-wrapper'
import {roleSets} from '@product-web/shared--user'

const BillingPage = React.lazy(async () => import('./screens/billing'))

export const settingsRoutes = (
    <Route
        element={<GeneralSettingsWrapper />}
        handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}
    >
        <Route index element={<BillingPage />} />
    </Route>
)
