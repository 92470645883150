export type ActivePlanType =
    | PlanType.STARTER
    | PlanType.ESSENTIAL
    | PlanType.ADVANCED
    | PlanType.BEYOND

export enum PlanType {
    LEGACY = 'LEGACY',
    EXTRA = 'EXTRA',
    YEARLY_EXTRA = 'YEARLY_EXTRA',
    PRO = 'PRO',
    PREMIUM = 'PREMIUM',
    YEARLY = 'YEARLY',
    ESSENTIAL = 'ESSENTIAL',
    PARTNERSHIP = 'PARTNERSHIP',
    MANAGING_PARTNER = 'MANAGING_PARTNER',
    MANAGING_PARTNER_5 = 'MANAGING_PARTNER_5',
    MANAGING_PARTNER_10 = 'MANAGING_PARTNER_10',
    MANAGING_PARTNER_15 = 'MANAGING_PARTNER_15',
    MANAGING_PARTNER_20 = 'MANAGING_PARTNER_20',
    MANAGING_PARTNER_25 = 'MANAGING_PARTNER_25',
    PARTNER_CLIENT_FREE = 'PARTNER_CLIENT_FREE',
    PARTNER_CLIENT_PRO = 'PARTNER_CLIENT_PRO',
    UNKNOWN = 'UNKNOWN',
    FREE = 'FREE',
    STARTER = 'STARTER',
    FIXED_RATE = 'FIXED_RATE',
    TEAM = 'TEAM',
    BUSINESS = 'BUSINESS',
    ADVANCED = 'ADVANCED',
    BEYOND = 'BEYOND',
    INSURANCE = 'INSURANCE',
}

// Pricing plan names should not be translated
/* eslint-disable string-to-lingui/missing-lingui-transformation */
export const PlanTypeName: Record<ActivePlanType, string> = {
    [PlanType.STARTER]: 'Starter',
    [PlanType.ESSENTIAL]: 'Essential',
    [PlanType.ADVANCED]: 'Advanced',
    [PlanType.BEYOND]: 'Beyond',
}
/* eslint-enable string-to-lingui/missing-lingui-transformation */

export enum PlanUpgradeSource {
    USER_ADDING_FLOW = 'USER_ADDING_FLOW',
    TEAMS_EMPTYSTATE_FLOW = 'TEAMS_EMPTYSTATE_FLOW',
    LIMITS_EMPTYSTATE_FLOW = 'LIMITS_EMPTYSTATE_FLOW',
    BUDGETS_EMPTYSTATE_FLOW = 'BUDGETS_EMPTYSTATE_FLOW',
    ANALYTICS_EMPTYSTATE_FLOW = 'ANALYTICS_EMPTYSTATE_FLOW',
    POCKET_EMPTYSTATE_FLOW = 'POCKET_EMPTYSTATE_FLOW',
    ACTIVATE_INVOICES_FLOW = 'ACTIVATE_INVOICES_FLOW',
    SUBSCRIPTIONS_EMPTYSTATE_FLOW = 'SUBSCRIPTIONS_EMPTYSTATE_FLOW',
    VENDOR_CARDS_EMPTYSTATE_FLOW = 'VENDOR_CARDS_EMPTYSTATE_FLOW',
    VENDOR_CARDS_LIMIT_REACHED_BANNER_FLOW = 'VENDOR_CARDS_LIMIT_REACHED_BANNER_FLOW',
    POCKET_UPGRADE_BANNER = 'POCKET_UPGRADE_BANNER',
    POCKET_GUIDE = 'POCKET_GUIDE',
    MILEAGE_UPGRADE_BANNER = 'MILEAGE_UPGRADE_BANNER',
    BILLING_FLOW = 'BILLING_FLOW',
    TRIAL_BANNER = 'TRIAL_BANNER',
    REQUEST_ACCESS_ON_BEHALF_OF_EMPLOYEE = 'REQUEST_ACCESS_ON_BEHALF_OF_EMPLOYEE',
    TEAMS_REVIEW_OVERVIEW_FLOW = 'TEAMS_REVIEW_OVERVIEW_FLOW',
    TAG_REVIEW_OVERVIEW_FLOW = 'TAG_REVIEW_OVERVIEW_FLOW',
    COMPANY_REVIEW_OVERVIEW_FLOW = 'COMPANY_REVIEW_OVERVIEW_FLOW',
    PROMO_CARD_FLOW = 'PROMO_CARD_FLOW',
    OFFBOARDING_FLOW = 'OFFBOARDING_FLOW',
    PEOPLE_MANAGEMENT_INTEGRATIONS = 'PEOPLE_MANAGEMENT_INTEGRATIONS',
    AUTO_VAT_SPLIT_CONTROL = 'AUTO_VAT_SPLIT_CONTROL',
    END_TRIAL_MODAL = 'END_TRIAL_MODAL',
    GET_STARTED_PAGE = 'GET_STARTED_PAGE',
    MULTI_ENTITY = 'MULTI_ENTITY',
    SAML_SSO = 'SAML_SSO',
    SEQUENTIAL_REVIEW_FLOW = 'SEQUENTIAL_REVIEW_FLOW',
    SPENDING_GUIDELINES = 'SPENDING_GUIDELINES',
    TAG_REVIEW_ONBOARDING_FLOW = 'TAG_REVIEW_ONBOARDING_FLOW',
    CARD_DEFAULTS = 'CARD_DEFAULTS',
    CARD_LIMITS_UPGRADE = 'CARD_LIMITS_UPGRADE',
    CARD_ADVANCED_SETTINGS = 'CARD_ADVANCED_SETTINGS',
    SUB_WALLET = 'SUB_WALLET',
}

export type SubscriptionUpdateSource = `${PlanUpgradeSource}`
