import {t} from '@lingui/macro'
import type {ComponentType, CSSProperties, FC} from 'react'

import type {AccountCategoryTypeKey} from '@pleo-io/deimos'
import {ExpenseViewType} from '@pleo-io/deimos'
import {tokens} from '@pleo-io/telescope'
import * as Icons from '@pleo-io/telescope-icons'

import {CashIcon} from '@product-web/feature--expenses/cash-icon/cash-icon'
import type {CategoryTypeModel} from '@product-web/shared--bff-moons/generated/gjoll'

export const Categories: Record<
    AccountCategoryTypeKey | 'empty',
    {
        icon: ComponentType<React.PropsWithChildren<any>>
        color: string
        background: string
        label?: () => string
    }
> = {
    entertainment: {
        icon: Icons.CategoryEntertainment,
        color: tokens.colorContentCategoryEntertainment,
        background: tokens.colorBackgroundCategoryEntertainment,
        label: () => t`Entertainment`,
    },
    equipment_and_hardware: {
        icon: Icons.CategoryEquipment,
        color: tokens.colorContentCategoryEquipment,
        background: tokens.colorBackgroundCategoryEquipment,
        label: () => t`Equipment and hardware`,
    },
    marketing_and_advertising: {
        icon: Icons.CategoryMarketing,
        color: tokens.colorContentCategoryMarketing,
        background: tokens.colorBackgroundCategoryMarketing,
        label: () => t`Marketing and advertising`,
    },
    meals_and_drinks: {
        icon: Icons.CategoryMealsAndDrinks,
        color: tokens.colorContentCategoryMealsAndDrinks,
        background: tokens.colorBackgroundCategoryMealsAndDrinks,
        label: () => t`Meals and drinks`,
    },
    office_expenses: {
        icon: Icons.CategoryOffice,
        color: tokens.colorContentCategoryOffice,
        background: tokens.colorBackgroundCategoryOffice,
        label: () => t`Office expenses`,
    },
    phone_and_internet: {
        icon: Icons.CategoryPhoneInternet,
        color: tokens.colorContentCategoryPhoneAndInternet,
        background: tokens.colorBackgroundCategoryPhoneAndInternet,
        label: () => t`Phone and internet`,
    },
    software: {
        icon: Icons.CategorySoftware,
        color: tokens.colorContentCategorySoftware,
        background: tokens.colorBackgroundCategorySoftware,
        label: () => t`Software`,
    },
    travel: {
        icon: Icons.CategoryTravel,
        color: tokens.colorContentCategoryTravel,
        background: tokens.colorBackgroundCategoryTravel,
        label: () => t`Travel`,
    },
    other: {
        icon: Icons.CategoryOther,
        color: tokens.colorContentCategoryOther,
        background: tokens.colorBackgroundCategoryOther,
        label: () => t`Other`,
    },
    services_and_consultancy: {
        icon: Icons.CategoryService,
        color: tokens.colorContentCategoryService,
        background: tokens.colorBackgroundCategoryService,
        label: () => t`Services and consultancy`,
    },
    supplies: {
        icon: Icons.CategorySupplies,
        color: tokens.colorContentCategorySupplies,
        background: tokens.colorBackgroundCategorySupplies,
        label: () => t`Supplies`,
    },
    no_suitable_category: {
        icon: Icons.CategoryIDonTKnow,
        color: tokens.colorContentCategoryUnknown,
        background: tokens.colorBackgroundCategoryUnknown,
        label: () => t`No suitable category`,
    },
    empty: {
        icon: Icons.CategoryNone,
        color: tokens.colorContentCategoryNone,
        background: tokens.colorBackgroundCategoryNone,
    },
}

export const CategoryIcon: FC<
    React.PropsWithChildren<{
        imgkey?: AccountCategoryTypeKey | CategoryTypeModel | 'empty'
        testID?: string
        style?: CSSProperties
        className?: string
        expenseViewType?: ExpenseViewType | null
        color?: string
        size?: number
    }>
> = ({imgkey, style, color, className, expenseViewType, testID, size}) => {
    if (expenseViewType && expenseViewType !== ExpenseViewType.EXPENSE) {
        return <CashIcon type={expenseViewType} />
    }

    if (!imgkey) {
        return null
    }
    const {icon: Icon, color: defaultColor} = Categories[imgkey ?? 'empty']
    const mergedStyles = {
        color: color || defaultColor /* stylelint-disable-line */,
        ...style,
    }

    return <Icon style={mergedStyles} className={className} data-testid={testID} size={size} />
}
