import {t, Trans} from '@lingui/macro'
import {useEffect} from 'react'

import {Loading, Modal, ModalClose, ModalContent, ModalTitle} from '@pleo-io/telescope'

import {reportError} from '@product-web/shared--error/report'
import {useToaster} from '@product-web/shared--toaster'
import download, {getDownloadFileName} from '@product-web/shared--web-platform/download'

import {getFileNamePrefix} from './helpers'

import {bff} from '../bff'

const POLLING_TIMEOUT_MS = 500

export const TagBudgetDownloadExpensesModal: React.FC<
    React.PropsWithChildren<{
        budgetId: string
        budgetName: string
        isOpen: boolean
        onCancel: () => void
        onClose: () => void
    }>
> = ({budgetId, budgetName, isOpen, onCancel, onClose: closeModal}) => {
    const {showToast} = useToaster()

    const hasActiveBudgetId = !!budgetId

    /**
     * First we fetch the download job ID. This is used to poll the server for the download result.
     */
    const {data: jobIdResponse, remove: removeJobId} =
        bff.tagBudgetDownloadExpenses.getDownloadJobId.useQuery(
            {budgetId},
            {enabled: hasActiveBudgetId && isOpen},
        )

    const hasActiveJobId = !!jobIdResponse?.downloadJobId
    const downloadJobId = `${jobIdResponse?.downloadJobId}`

    /**
     * Then we fetch the active download status. This is also used to get the download URL.
     */
    const {data: jobResultResponse, remove: removeJobResult} =
        bff.tagBudgetDownloadExpenses.getDownloadJobResult.useQuery(
            {
                budgetId,
                downloadJobId,
            },
            {
                enabled: hasActiveJobId && isOpen,
                /**
                 * The `refetchInterval` option is used to poll the server for the download result.
                 *
                 * If the `documentUrl` is not yet available, the query will be refetched after a pre-defined timeout in milliseconds.
                 * Once the `documentUrl` is available, the query will no longer be refetched.
                 */
                refetchInterval(data) {
                    const documentUrl = data?.documentUrl ?? ''
                    if (documentUrl === '') {
                        return POLLING_TIMEOUT_MS
                    }

                    return false
                },
            },
        )

    const documentUrl = jobResultResponse?.documentUrl ?? ''
    const isDownloadReady = documentUrl !== ''

    /**
     * Downloads the document as a CSV file with a formatted name.
     *
     * @example
     * budget_my-budget-name_19_09_2023_21_28_42.csv
     *
     * @returns A Promise that resolves when the download is complete.
     * @throws An error if the download fails.
     */
    async function downloadDocument(): Promise<void> {
        if (!isOpen || !isDownloadReady) {
            return
        }

        const prefix = getFileNamePrefix(budgetName)
        const filename = getDownloadFileName(prefix, 'csv')

        await download(documentUrl, filename)

        showToast(t`Budget ${budgetName} downloaded successfully`, {
            title: t`Success`,
            level: 'success',
        })

        closeModal()
    }

    /**
     * Resets the active job ID and result when the modal is closed.
     * Invalidates the download queries and removes the local BFF cache.
     * This is necessary to prevent the modal from reopening with the same job ID.
     *
     * @returns A Promise that resolves when the queries are invalidated.
     */
    async function resetOnClose(): Promise<void> {
        if (isOpen || !hasActiveJobId) {
            return
        }

        removeJobId()
        removeJobResult()
    }

    /**
     * Handles errors that occur during the download.
     *
     * @param error The error that occurred.
     */
    function handleOnError(error: Error) {
        reportError(error)

        showToast(t`Please try again, or contact support.`, {
            title: t`Something went wrong`,
            level: 'error',
        })

        closeModal()
    }

    /**
     * Resets the active job ID and result when the modal is closed.
     */
    useEffect(() => {
        resetOnClose().catch((error) => {
            reportError(error)
        })
    }, [isOpen])

    /**
     * Downloads the document when the download is ready.
     */
    useEffect(() => {
        downloadDocument().catch((error) => handleOnError(error))
    }, [isDownloadReady])

    return (
        <Modal
            aria-label={t`Download budget expenses`}
            isOpen={isOpen}
            onDismiss={onCancel}
            data-testid="download-budget-expenses-modal"
        >
            <ModalClose onClick={onCancel} />
            <ModalTitle>
                <Trans>Preparing budget expenses...</Trans>
            </ModalTitle>
            <ModalContent>
                <Loading />
            </ModalContent>
        </Modal>
    )
}
