import {t, Trans} from '@lingui/macro'
import type {FC} from 'react'
import {useState} from 'react'
import styled from 'styled-components'

import {Checkbox, ModalContent, Stack, Text, Textarea, tokens} from '@pleo-io/telescope'

import * as s from '@product-web/feature--ui-guides/guide.styles'
import tracking from '@product-web/shared--analytics'
import FetchGratefulDogIllustration from '@product-web/shared--images/fetch-grateful-dog.svg'
import {breakpoints} from '@product-web/shared--styles/theme'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

const Content = styled(s.Content)`
    overflow: unset;
`

const SubmitButton = styled(s.Button)`
    @media (max-width: ${breakpoints.mobileLrgUp}) {
        width: 100%;
        margin-left: unset;
    }
`

const FlexRow = styled(s.FlexRow)`
    width: 100%;

    > label {
        justify-content: left;
        text-align: left;
        width: 100%;
    }
`

interface FetchDisableModalProps {
    isOpen: boolean
    onDisable: () => void
    onDismiss: () => void
}

export const FetchDisableModal: FC<FetchDisableModalProps> = ({isOpen, onDisable, onDismiss}) => {
    const isLargeScreen = useMediaQuery(`(min-width: ${breakpoints.tabletMedUp})`)
    const isMobileScreen = useMediaQuery(`(max-width: ${breakpoints.mobileLrgUp})`)
    const [selectedReasons, setSelectedReasons] = useState<string[]>([])
    const [customReason, setCustomReason] = useState<string | null>(null)

    const fetchDisableReasons = [
        {
            key: 'fetch-did-not-work-well',
            value: t`Fetch didn't work well enough`,
        },
        {
            key: 'fetch-added-wrong-receipts',
            value: t`Fetch added the wrong receipts to my expenses`,
        },
        {
            key: 'privacy-concerns',
            value: t`I have privacy concerns regarding Fetch`,
        },
        {
            key: 'restart-fetch',
            value: t`I wanted to restart Fetch`,
        },
        {
            key: 'employer-asked-to-disable-fetch',
            value: t`My employer asked me to disable Fetch`,
        },
    ]

    const otherReason = {
        key: 'something-else',
        value: t`Something else`,
    }

    const onReasonChange = (reasonKey: string) => {
        const reasonIndex = selectedReasons.indexOf(reasonKey)
        if (reasonIndex < 0) {
            setSelectedReasons([...selectedReasons, reasonKey])
        } else {
            const updatedReasons = selectedReasons.filter((reason) => reason !== reasonKey)
            setSelectedReasons(updatedReasons)
        }
    }

    const onDisableConfirmClick = () => {
        const selectedReasonsValue = fetchDisableReasons
            .filter(({key}) => selectedReasons.includes(key))
            .map(({value}) => value)

        if (selectedReasons.includes(otherReason.key)) {
            selectedReasonsValue.push(otherReason.value)
        }

        setSelectedReasons([])
        tracking.fetchOffboardingActioned({
            action: 'completed',
            reasons: selectedReasonsValue,
            comment: customReason,
        })
        onDisable()
    }

    const onCustomReasonChange = (textAreaElem: React.ChangeEvent<HTMLTextAreaElement>) => {
        // Select "Something else.." option if there is an input in the text area
        if (textAreaElem.target.value && selectedReasons.indexOf(otherReason.key) < 0) {
            setSelectedReasons([...selectedReasons, otherReason.key])
        }

        setCustomReason(textAreaElem.target.value)
    }

    return (
        <s.Modal
            data-testid="disable-fetch-modal"
            isOpen={isOpen}
            onDismiss={onDismiss}
            aria-label={t`Reimbursements Setup Guide`}
        >
            <ModalContent as="section">
                <s.ScreenWrapper>
                    <s.ScreenContentWrapper>
                        <s.ScreenContent>
                            <s.ContentWrapper>
                                <Content>
                                    <Text
                                        variant={
                                            isMobileScreen ? '2xlarge-accent' : '4xlarge-accent'
                                        }
                                        as="h3"
                                        align="left"
                                    >
                                        <Trans>Help us improve Fetch</Trans>
                                    </Text>
                                    <s.ScreenSectionsWrapper>
                                        <s.ScreenSectionWrapper>
                                            <Stack space={16}>
                                                <s.FlexRow>
                                                    <Text as="p" align="left">
                                                        <Trans>
                                                            We're sorry to see you stop using Fetch.
                                                            Please let us know if there are any
                                                            issues so we can improve Fetch for
                                                            future customers.
                                                        </Trans>
                                                    </Text>
                                                </s.FlexRow>
                                                {fetchDisableReasons.map((reason) => (
                                                    <FlexRow key={reason.key}>
                                                        <Checkbox
                                                            checked={selectedReasons.includes(
                                                                reason.key,
                                                            )}
                                                            onChange={() =>
                                                                onReasonChange(reason.key)
                                                            }
                                                        >
                                                            <Text>{reason.value}</Text>
                                                        </Checkbox>
                                                    </FlexRow>
                                                ))}
                                                <FlexRow>
                                                    <Checkbox
                                                        data-testid={otherReason.key}
                                                        checked={selectedReasons.includes(
                                                            otherReason.key,
                                                        )}
                                                        onChange={() =>
                                                            onReasonChange(otherReason.key)
                                                        }
                                                    >
                                                        <Stack space={12}>
                                                            <Text>{otherReason.value}</Text>
                                                            <Textarea
                                                                name={t`Reason to disable fetch`}
                                                                placeholder={t`Start writing`}
                                                                data-testid="disable-fetch-other-reason"
                                                                showCounter
                                                                maxLength={100}
                                                                value={customReason}
                                                                onChange={onCustomReasonChange}
                                                                fixedHeight="60px"
                                                            />
                                                        </Stack>
                                                    </Checkbox>
                                                </FlexRow>
                                            </Stack>
                                        </s.ScreenSectionWrapper>
                                    </s.ScreenSectionsWrapper>
                                </Content>
                            </s.ContentWrapper>
                        </s.ScreenContent>
                        {isLargeScreen && (
                            <s.IllustrationWrapper $color={tokens.green500}>
                                <s.Illustration $alignX="right">
                                    <img
                                        src={FetchGratefulDogIllustration}
                                        alt={t`Fetch grateful dog illustration`}
                                    />
                                </s.Illustration>
                            </s.IllustrationWrapper>
                        )}
                    </s.ScreenContentWrapper>
                    <s.ScreenActionWrapper>
                        <SubmitButton
                            data-testid="disable-fetch-confirm-btn"
                            variant="primary"
                            onClick={onDisableConfirmClick}
                        >
                            <Trans>Submit & disable Fetch</Trans>
                        </SubmitButton>
                    </s.ScreenActionWrapper>
                </s.ScreenWrapper>
            </ModalContent>
        </s.Modal>
    )
}
