/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

import type * as Segment from './segment'

export interface AidrActioned {
	action?: string
	amount?: number
	currency?: string
	failure_reason?: string
	reimbursement_id?: string | null
	reimbursement_target?: string | null
}
export interface AisLinkedAccountActioned {
	action: string
	/**
	 * The ID of the institution (bank) which a user is working with
	 */
	institutionId?: string
}
export interface AccountSwitchModalActioned {
	/**
	 * When a user interacts with the account switch modal, there are steps taken before a user successfully switches an account
	 */
	action?: string
	/**
	 * What is the role of the user performing the action
	 */
	actor_role?: string
	/**
	 * Was the action performed started, completed, or cancelled
	 */
	status?: string
}
export interface AccountSwitchModalViewed {
	/**
	 * What is the role of the user
	 */
	actor_role?: string
	/**
	 * When a user is switching accounts we track what step of the switch account process they view
	 */
	view?: string
}
export interface AccountSwitchToggleActioned {
	/**
	 * What is the role of the user performing the action
	 */
	actor_role?: string
}
export interface Requested {
	/**
	 * The company id of the employee who is being manually added or removed as a reviewer
	 */
	companyId: string
	/**
	 * The employee id of the employee who is being manually added or removed as a reviewer
	 */
	employeeId: string
	/**
	 * The user id of the employee who is being manually added or removed as a reviewer
	 */
	userId: string
}
export interface Requestor {
	/**
	 * The company id of the employee who is manually adding or removing reviewers
	 */
	companyId: string
	/**
	 * The employee id of the employee who is adding or removing reviewers
	 */
	employeeId: string
	/**
	 * The user id of the employee who is manually adding or removing reviewers
	 */
	userId: string
}
export interface AccountingEntryReviewersManuallyAddedOrRemoved {
	/**
	 * The accounting entry id of the expense that is being edited
	 */
	accountingEntryId: string
	/**
	 * The action being performed by the employee
	 */
	action: string
	requested: Requested
	requestor: Requestor
}
export interface AccountingSetupCompleted {
	/**
	 * Indicated the step (ACCOUNTING, CATEGORIES, TAGS)
	 */
	step?: string
}
export interface AccountingSetupStepViewed {
	/**
	 * Name of the specific step which the user is viewing (e.g. 'ACCOUNTING', 'CATEGORIES', 'TAGS', 'FINAL')
	 */
	step?: string
}
export interface AccountingSystemClicked {
	/**
	 * User company id
	 */
	company_id?: string
	/**
	 * Time of event
	 */
	event_timestamp?: string
	/**
	 * Search query string
	 */
	search_string?: string
	/**
	 * Name of selected accounting system
	 */
	system_name?: string
	/**
	 * User Id
	 */
	user_id?: string
}
export interface AccountingSystemConnected {
	/**
	 * The type of accounting system connected
	 */
	type?: string
}
export interface AccountingSystemDisconnected {
	/**
	 * Type of the accounting system connected
	 */
	type?: string
}
export interface AccountingSystemUpdated {
	/**
	 * Is true if the user has filled in a contra account number
	 */
	contra_account_no?: boolean
	/**
	 * Name of the accounting system that's been chosen or updated
	 */
	system?: string
	/**
	 * Is true if the user has filled in a tax code
	 */
	tax_code?: boolean
}
export interface ActiveOverdraftActioned {
	action: string
}
export interface ActivityTabActioned {
	/**
	 * User visited activity tab in the expense tab of the export page
	 */
	action?: string
	/**
	 * used to determine which company performed the action
	 */
	company_id?: string
	export_page_version?: number | null
	/**
	 * used to determine if admins or bookkeepers are more likely to change the settings
	 */
	role?: string
}
export interface AddAccountClicked {
	/**
	 * Where the event was triggered from
	 */
	context: string
	/**
	 * Number of active accounts on the device
	 */
	numberOfActiveAccounts: number
}
export interface AddEntity {
	/**
	 * The role of the user
	 */
	actor: string
	/**
	 * The category a connected people app
	 */
	connectedPeopleAppCategory?: string
	/**
	 * The ID of the connected people app
	 */
	connectedPeopleAppId?: string
	/**
	 * The name of the people app
	 */
	connectedPeopleAppName?: string
	/**
	 * The type of a connected people app
	 */
	connectedPeopleAppType?: string
	/**
	 * The name of screen where this action lives
	 */
	screen: string
}
export interface AddExpensesActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
	 */
	action?: string
	/**
	 * Element that triggered the action
	 */
	field?: string
	/**
	 * States if a user has manually entered a field rather than using the autocomplete
	 */
	is_manual?: boolean
	/**
	 * Previous value of the field updated by a user
	 */
	previous_value?: any | null
	/**
	 * Type of expense actioned: "purchase", "mileage", "bill, perdiem"
	 */
	type?: string
	/**
	 * Field value added by a user
	 */
	value?: any | null
}
export interface AddFundsActioned {
	action: string
	/**
	 * User clicks the CTA either on the product card or the milestone card of the Get Started Guide
	 */
	cta?: string
	type?: string
}
export interface AddFundsAmountActioned {
	action?: string
	amount?: number
	currency?: string
	'suggested amount'?: boolean
}
export interface AddMemberActioned {
	/**
	 * The specific action
	 */
	action: string
	/**
	 * The number of email addresses added
	 */
	n_invites?: string
	/**
	 * Where this action was taken within the app
	 */
	source: string
}
export interface AddMemberAddManyActioned {
	/**
	 * What is the role of the user performing the action
	 */
	actor_role?: string
	/**
	 * Number of cardAccess members invites
	 */
	n_invites_card?: number
	/**
	 * Number of cardless members invites
	 */
	n_invites_cardless?: number
}
export interface AddMemberAddManyCompleted {
	/**
	 * What is the role of the user performing the action
	 */
	actor_role?: string
	/**
	 * Number of cardAccess members invites
	 */
	n_invites_card?: number
	/**
	 * Number of cardless members invites
	 */
	n_invites_cardless?: number
}
export interface AddMemberCompleted {
	/**
	 * What is the role of the user performing the action
	 */
	actor_role?: string
	/**
	 * Number of cardAccess members invited
	 */
	n_invites_card?: number
	/**
	 * Number of cardless members invited
	 */
	n_invites_cardless?: number
	/**
	 * Number of members which could not be invited because the email is already in use in a different company
	 */
	no_of_existing_users?: number
	/**
	 * Number of members invited
	 */
	no_of_users?: number
	/**
	 * Type of user invite (e.g. member, admin, external-bookkeeper)
	 */
	type?: string
}
export interface AddMemberStarted {
	/**
	 * What is the role of the user performing the action
	 */
	actor_role?: string
}
export interface AddMemberWarningViewed {
	/**
	 * The warning message identifier
	 */
	name?: string
	/**
	 * Where the message was seen
	 */
	source?: string
}
export interface AddPerDiemActioned {
	/**
	 * States the action which was performed when the events were triggered
	 */
	action: string
	/**
	 * The amount of the expense
	 */
	amount?: number
	/**
	 * The button that triggered the event
	 */
	button?: string
	/**
	 * The currency of the expense
	 */
	currency?: string
	/**
	 * The id of the expense for which the event was triggered
	 */
	expense_id?: string
	/**
	 * The form field that triggered the action
	 */
	field?: string
	/**
	 * The number of destination/stop that triggered the event
	 */
	n_destination?: number
	/**
	 * The number of tag that triggered the event
	 */
	n_tag?: number
	/**
	 * Metadata related to the action trigger. Can be the type of field, or action, or trigger, etc.
	 */
	type?: string
	/**
	 * Field value added by a user
	 */
	value?: any | null
}
export interface Edits {
	/**
	 * The expense access type
	 */
	access?: string
}
export interface AdminRequestApprovalActioned {
	/**
	 * The action taken by the user
	 */
	action?: string
	/**
	 * For what period will the requested limit be active
	 */
	active_for?: string
	/**
	 * The spend categories sent
	 */
	categories?: any[]
	/**
	 * User's limit currency
	 */
	currency?: string
	/**
	 * Current limit the user is requesting to change
	 */
	current_value?: number
	/**
	 * Details of changes when request is edited
	 */
	edits?: Edits
	/**
	 * Source of taking admin to flow
	 */
	lead?: string
	/**
	 * Number of pending requests or requests actioned
	 */
	n_member_requests?: number
	/**
	 * Email address on the request
	 */
	new_member_email?: string
	/**
	 * New limit value the user is requesting
	 */
	new_value?: number
	/**
	 * Reason for declined_request
	 */
	reason_text?: string
	/**
	 * The request Ids
	 */
	request_ids?: any[]
	/**
	 * Source of every request
	 */
	source?: any[]
	/**
	 * The type of request
	 */
	type?: string
	/**
	 * Whether approving the request require a plan change
	 */
	upgrade_needed?: boolean
}
export interface AdminRequestApprovalDeclines {
	/**
	 * The action taken by the user
	 */
	action?: string
	/**
	 * Email address on the request
	 */
	new_member_email?: string
	/**
	 * The predefined reason selected
	 */
	reason_list?: string
	/**
	 * Supplied when reason_list is 'other'
	 */
	reason_text?: string
	/**
	 * The ID of the request
	 */
	request_id?: string
	/**
	 * The source of the request
	 */
	source?: string
}
export interface AdoptionEssentialsCentreActioned {
	action: string
	guide?: string
	guide_task?: string
	page?: string
	target_url?: string
}
export interface AdoptionEssentialsFeedbackSubmitted {
	guide: string
	helpful: string
	option?: string
	other_text?: string
}
export interface AdoptionEssentialsPromptActioned {
	action: string
	guide: string
}
export interface AdoptionPrompt {
	/**
	 * Adoption prompt viewed
	 */
	action: string
}
export interface AdoptionPromptActioned {
	/**
	 * Prompt action
	 */
	action?: string
	/**
	 * Unique name of the prompt
	 */
	prompt_name?: string
}
export interface AdvancedSpendControlsActioned {
	/**
	 * The action taken by the user
	 */
	action?: string
	/**
	 * company id of the user
	 */
	company_id?: string
	new_value?: any[]
	previous_value?: any[]
	/**
	 * Advanced spend control level (company/employee)
	 */
	source?: string
	/**
	 * Type of advanced spend control
	 */
	type?: string
	/**
	 * User id
	 */
	user_id?: string
}
export interface AllExpensesInExportActioned {
	/**
	 * User has visited the "All expenses" page for pocket or for wallet
	 */
	action?: string
	/**
	 * Which company is performing the action
	 */
	company_id?: string
	export_page_version?: number | null
	/**
	 * used to determine if admins or bookkeepers are more likely to change the settings
	 */
	role?: string
}
export interface AmlQuestionActioned {
	action: string
	type: string
}
export interface AmlSectionActioned {
	action: string
}
export interface AnalyticsPageActioned {
	/**
	 * type of action
	 */
	action: string
	/**
	 * company id of the user making the action
	 */
	company_id: string
	/**
	 * the filter being actioned
	 */
	filter_name: string
	/**
	 * employee role filtering the analytic dashboard
	 */
	user_role: string
}
export interface ApplyFilter {
	/**
	 * The role of the user
	 */
	actor: string
	/**
	 * The category a connected people app
	 */
	connectedPeopleAppCategory?: string
	/**
	 * The ID of the connected people app
	 */
	connectedPeopleAppId?: string
	/**
	 * The name of a connected people app
	 */
	connectedPeopleAppName?: string
	/**
	 * The type of a connected people app
	 */
	connectedPeopleAppType?: string
	/**
	 * The name of the screen using this filter
	 */
	screen: string
	/**
	 * The type of the filter
	 */
	type: string
}
export interface ApplySort {
	/**
	 * The user role applying the sort
	 */
	actor: string
	/**
	 * The category a connected people app
	 */
	connectedPeopleAppCategory?: string
	/**
	 * The ID of the connected people app
	 */
	connectedPeopleAppId?: string
	/**
	 * The name of the connected people app
	 */
	connectedPeopleAppName?: string
	/**
	 * The type of a connected people app
	 */
	connectedPeopleAppType?: string
	/**
	 * The name of the screen using this sort
	 */
	screen: string
	/**
	 * The type of sort
	 */
	type: string
}
export interface ArchiveTeamCompleted {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Name of the archived team
	 */
	value?: string
}
export interface AutoTopUp {
	action: string
	amount?: number
	auto_top_up_flow: string
	frequency?: string
	prev_amount?: number
	prev_frequency?: string
	prev_threshold?: number
	/**
	 * Previous Auto Top-up type
	 */
	prev_type?: string
	/**
	 * Is used, when the auto_top_up_flow is equal to SUB_WALLET
	 */
	sub_wallet_id?: string
	threshold?: number
	/**
	 * Auto Top-up type
	 */
	type?: string
}
export interface AutoTopupAccordionItemActioned {
	action: string
}
export interface AutoTopupSettingsFormChanged {
	action: string
	creating_agreement?: boolean
	eligible_for_pleo_reserve?: boolean
	new_value_boolean?: boolean
	new_value_number?: number
}
export interface AutoTopupSettingsFormValidationViewed {
	action: string
	creating_agreement?: boolean
	eligible_for_pleo_reserve?: boolean
	/**
	 * Whether user sees this validation with/out touching any input field
	 */
	input_touched?: boolean
}
export interface AutoTopupSettingsSaveChangesButtonClicked {
	auto_top_up_amount?: number
	auto_top_up_enabled?: boolean
	auto_top_up_threshold?: number
	eligible_for_pleo_reserve?: boolean
	pleo_reserve_enabled?: boolean
}
export interface AutoVatSplitControl {
	/**
	 * if the checkbox is enabled or disabled
	 */
	action?: string | null
}
export interface BackClicked {
	/**
	 * Web language
	 */
	locale?: string
	/**
	 * Full URL
	 */
	origin?: string
	/**
	 * Page name (partial URL)
	 */
	path?: string
	/**
	 * Name of the page where the user clicked "back"
	 */
	step?: string
}
export interface BackToPleoGuideClicked {
	/**
	 * Web language
	 */
	locale?: string
	/**
	 * Full URL
	 */
	origin?: string
	/**
	 * Page name (partial URL)
	 */
	path?: string
	/**
	 * Name of the page where the user clicked "back to Guide"
	 */
	step?: string
}
export interface Bill {
	/**
	 * Bill invoice with international payment
	 */
	international_payment?: boolean
	/**
	 * Bill invoice status
	 */
	status?: string
}
export interface BillDetailsApprovalsButtonClicked {
	/**
	 * Button action (add or edit approvers)
	 */
	action: string
	/**
	 * Bill invoice information
	 */
	bill: Bill
}
export interface BillDetailsApprovalsEmployeeListChanged {
	/**
	 * Action related to the employee list change (loaded, filtered, error)
	 */
	action: string
	/**
	 * Number of employees displayed in the list after the action
	 */
	number_of_employees: number
}
export interface Bill1 {
	/**
	 * Bill invoice with international payment
	 */
	international_payment?: boolean
	/**
	 * Bill invoice status
	 */
	status?: string
}
export interface BillDetailsButtonActioned {
	/**
	 * Action that was performed
	 */
	action: string
	/**
	 * Bill invoice information
	 */
	bill: Bill1
	/**
	 * Button name
	 */
	button: string
	/**
	 * Descriptive error
	 */
	error?: string
}
export interface Bill2 {
	/**
	 * Bill invoice with international payment
	 */
	international_payment?: boolean
	/**
	 * Bill invoice status
	 */
	status?: string
}
export interface BillDetailsDatePickerActioned {
	/**
	 * Specifying whether date picker was applied or dismissed
	 */
	action: string
	/**
	 * Bill invoice information
	 */
	bill: Bill2
	/**
	 * Date picker name
	 */
	picker: string
}
export interface Bill3 {
	/**
	 * Bill invoice with international payment
	 */
	international_payment?: boolean
	/**
	 * Bill invoice status
	 */
	status?: string
}
export interface BillDetailsEntitlementMissingActioned {
	action: string
	/**
	 * Bill invoice information
	 */
	bill: Bill3
}
export interface ApproverChanges {
	/**
	 * approvers added
	 */
	addedApprovers?: any[]
	/**
	 * initial approvers before changes
	 */
	initialApprovers?: any[]
	/**
	 * approvers removed
	 */
	removedApprovers?: any[]
}
export interface Bill4 {
	/**
	 * Bill invoice with international payment
	 */
	international_payment?: boolean
	/**
	 * Bill invoice status
	 */
	status?: string
}
export interface BillDetailsModalActioned {
	/**
	 * Action that was performed (confirm or dismiss)
	 */
	action: string
	/**
	 * changes to approvers
	 */
	approverChanges?: ApproverChanges
	/**
	 * Bill invoice information
	 */
	bill: Bill4
	/**
	 * Modal name
	 */
	modal: string
}
export interface Bill5 {
	/**
	 * Bill invoice with international payment
	 */
	international_payment?: boolean
	/**
	 * Bill invoice status
	 */
	status?: string
}
export interface BillDetailsNavigationActioned {
	/**
	 * Name of the action performed
	 */
	action: string
	/**
	 * Bill invoice information
	 */
	bill: Bill5
	/**
	 * Destination of the navigation
	 */
	destination: string
}
export interface Bill6 {
	/**
	 * Bill invoice with international payment
	 */
	international_payment?: boolean
	/**
	 * Bill invoice status
	 */
	status?: string
}
export interface BillDetailsOptionsMenuActioned {
	/**
	 * Either open_menu (when options menu was opened) or name of the button if one of the menu buttons was clicked
	 */
	action: string
	/**
	 * Bill invoice information
	 */
	bill: Bill6
}
export interface Bill7 {
	/**
	 * Bill invoice with international payment
	 */
	international_payment?: boolean
	/**
	 * Bill invoice status
	 */
	status?: string
}
export interface BillDetailsSplitActioned {
	/**
	 * Action that was performed (click on split, undo, edit amount)
	 */
	action: string
	/**
	 * Bill invoice information
	 */
	bill: Bill7
}
export interface Bill8 {
	/**
	 * Bill invoice with international payment
	 */
	international_payment?: boolean
	/**
	 * Bill invoice status
	 */
	status?: string
}
export interface BillDetailsUpdated {
	/**
	 * Bill invoice information
	 */
	bill: Bill8
	/**
	 * Name of the updated field
	 */
	field: string
	/**
	 * Section of the Bill details form (payment/export)
	 */
	section: string
	/**
	 * Status of the supplier match field (only for supplier updates)
	 */
	supplier_match_status?: string
}
export interface Bill9 {
	/**
	 * Bill invoice status
	 */
	status?: string
}
export interface BillDetailsWarningBannerInformation {
	/**
	 * Action that was performed
	 */
	action: string
	/**
	 * Type of banner message
	 */
	banner_type: string
	/**
	 * Bill invoice information
	 */
	bill: Bill9
}
export interface BillsDueDateSuggestionActioned {
	/**
	 * Action taken by the user related to the Due date Suggestions
	 */
	action: string
	/**
	 * The user selection from the suggestions (including dismissing suggestions)
	 */
	selection?: string
}
export interface BillsEmptyStateActioned {
	action?: string
	source?: string
}
export interface BillsFreePaymentBalance {
	/**
	 * The action or interaction taken by the user. Note that the action could be a passive action like "Viewed"
	 */
	action: string
	/**
	 * Number of free payment remaining in the current period
	 */
	free_payments_left?: number
	/**
	 * User can click on the pricing link from different places. The source will inform where the click happened
	 */
	source?: string
}
export interface BillsInboxBulkActions {
	action?: string
	bills?: any[]
}
export interface BillsLearnMoreActioned {
	action?: string
	step?: string
}
export interface BillsOnboardingBannerActioned {
	/**
	 * Action from the banner. At this stage possible values are "viewed", "dismissed" or "clicked"
	 */
	action: string
	/**
	 * If action is "clicked", this indicate the UI element clicked on the banner
	 */
	clicked?: string
	/**
	 * The user have 3 steps to go through, this tracking will indicate in which order he took them
	 */
	step_order?: number
}
export interface BillsOnboardingDatevSetup {
	/**
	 * Action taken by the user. Can be "viewed", "dismissed", "clicked"
	 */
	action: string
	/**
	 * If action is clicked, this indicate the UI element clicked in the current step
	 */
	clicked?: string
	/**
	 * Indicate the status of the DATEV setup, it can be "Checked" (Completed) or "Unchecked" (Not completed)
	 */
	status?: string
	/**
	 * The current step in the DATEV modal flow
	 */
	step: string
}
export interface BillsOnboardingEmailSetup {
	/**
	 * Action taken by the user. Can be "viewed", "dismissed", "clicked" or "starter"
	 */
	action: string
	/**
	 * If action is clicked, this indicate the UI element clicked in the current step
	 */
	clicked?: string
	/**
	 * Indicate the status of the email setup, it can be "updated", "completed" (not customised), or "anbandoned"
	 */
	status?: string
	/**
	 * The current step in the email setup flow
	 */
	step: string
}
export interface BillsOnboardingModalActioned {
	/**
	 * Action taken by the user. Can be "viewed", "dismissed", or "clicked". In the video player context, it will log the actions related to the video player (video_played, video_paused, video_ended)
	 */
	action: string
	/**
	 * If action is clicked, this indicate the UI element clicked in the current step
	 */
	clicked?: string
	/**
	 * The current step in the onboarding flow
	 */
	step: string
	/**
	 * Specific to the wistia video player. It indicates the percentage of the video viewed by the user.
	 */
	'video-percent-watched'?: number
	/**
	 * Specific to the wistia video player. It indicates the video's total duration in seconds.
	 */
	video_duration?: number
	/**
	 * Specific to the wistia video player. It indicates the number of seconds of the video viewed by the user.
	 */
	video_seconds_watched?: number
	/**
	 * Specific to the wistia video player. It indicates the timestamp location in seconds.
	 */
	video_timestamp?: number
}
export interface BillsOnboardingQuestionnaireUpdated {
	/**
	 * Name of the field
	 */
	field: string
}
export interface BillsPermission {
	/**
	 * Action(s) taken by the user in the permission flow (Invited, Accepted, Declined, Requested, Granted, Rejected)
	 */
	action: string
	/**
	 * The role of the initiator of the permission flow
	 */
	initiator: string
}
export interface BillsUploadActioned {
	/**
	 * Action that was performed
	 */
	action: string
	/**
	 * The source (CTA) of the upload action taken by the user
	 */
	action_source?: string
	/**
	 * Is first time a user uploads an invoice
	 */
	is_user_first_upload?: boolean
	/**
	 * Number of files selected or dropped
	 */
	number_of_files?: number
}
export interface BulkAction {
	/**
	 * The role of the user
	 */
	actor: string
	/**
	 * The category a connected people app
	 */
	connectedPeopleAppCategory?: string
	/**
	 * The ID of the people app
	 */
	connectedPeopleAppId?: string
	/**
	 * The name of the connected people app
	 */
	connectedPeopleAppName?: string
	/**
	 * The type of a connected people app
	 */
	connectedPeopleAppType?: string
	/**
	 * The name of the action
	 */
	name: string
	/**
	 * The name of the screen with the action
	 */
	screen: string
}
export interface BulkEditActionedInExport {
	/**
	 * User clicks bulk edit after selecting multiple items and changes category
	 */
	action?: string
	/**
	 * Which company is committing an action
	 */
	company_id?: string
	export_page_version?: number | null
	/**
	 * Value the user updated in the bulk edit
	 */
	new_value?: string | null
	/**
	 * used to determine if admins or bookkeepers are more likely to change the settings
	 */
	role?: string
}
export interface BulkEditApplied {
	export_page_version?: number | null
	/**
	 * number of expenses in the selection
	 */
	number_of_items?: number
}
export interface BulkEditOpened {
	export_page_version?: number | null
	number_of_items?: number | null
}
export interface BulkReviewCompleted {
	/**
	 * Role of the user who engaged with the review (e.g.  "bookkeeper", "owner")
	 */
	actor_role?: string
	/**
	 * Number of selected expenses
	 */
	expenses?: number
	/**
	 * Filters applied to the list of expenses
	 */
	filtered_by?: string
	/**
	 * Status that the reviewer marked the expense at (e.g. WAITING_FOR_EXPENSE_OWNER, OK, NOT_OK, WAITING_FOR_REVIEWER)
	 */
	review_status?: string
}
export interface CacpStepActioned {
	/**
	 * The action that happened when the CACP step was actioned
	 */
	action: string
}
export interface CaptchaTest {
	/**
	 * action name helping to understand where it was fired
	 */
	action?: string
	/**
	 * shows the result score
	 */
	result_score?: number
	/**
	 * shows if the result was successful
	 */
	result_success?: boolean
}
export interface CardDefaultSettingsActioned {
	/**
	 * Specific editing action which triggered the event
	 */
	action?: string
	/**
	 * Which company is committing an action
	 */
	company_id?: string
	/**
	 * Currency
	 */
	currency?: string
	/**
	 * New value
	 */
	new_value?: any | null
	/**
	 * Previous value
	 */
	previous_value?: any | null
}
export interface CardLimitPermissionsToggled {
	companyId: string
	/**
	 * enabled === true means the card limit permissions is set to "REVIEWERS_AND_ADMINS" meaning both reviewers and admins can set card limits. On the other hand, only admins can change card limits when "ADMINS" is set
	 */
	isEnabled: boolean
	organizationId?: string
	userId: string
}
export interface CardReadyPopoverActioned {
	action: string
}
export interface CardComparisonActioned {
	/**
	 * What action was taken on the modal
	 */
	action?: string
	/**
	 * From where was the card comparison modal triggered
	 */
	source?: string
}
export interface CardsPageActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned"
	 */
	action?: string
	/**
	 * True if the user has card access
	 */
	card_access?: boolean
	/**
	 * Element that triggered the action
	 */
	input?: string
}
export interface CashbackModal {
	action?: string
}
export interface CategoriesLimitationActioned {
	/**
	 * Action performed
	 */
	action: string
	/**
	 * Team Names added
	 */
	team_names?: any[]
}
export interface CategoryCreated {
	/**
	 * Name of the category
	 */
	category_name?: string
}
export interface CategoryGroupActioned {
	/**
	 * Action performed by user
	 */
	action: string
}
export interface CategoryGroupExportActioned {
	/**
	 * Export action type
	 */
	type: string
	/**
	 * Location where action is taken
	 */
	view: string
}
export interface CategoryGroupImportActioned {
	/**
	 * Location where action is taken
	 */
	view: string
}
export interface CategoryGroupImportModalActioned {
	/**
	 * Method used to upload a file
	 */
	method?: string
	/**
	 * File upload steps
	 */
	step: string
	/**
	 * Location where action is taken
	 */
	view: string
}
export interface CategoryGroupImportModalViewed {
	/**
	 * Location where action is taken
	 */
	view: string
}
export interface CategoryGroupTypeActioned {
	/**
	 * Action performed on category group type
	 */
	action: string
	/**
	 * Category group type selected
	 */
	type?: string
}
export interface CategoryImportExportButtonActioned {
	/**
	 * Location where action is taken
	 */
	view: string
}
export interface CategoryPrefillActioned {
	/**
	 * Started, completed, aborted, dismissed
	 */
	action?: string
	/**
	 * SKR 03 or SKR 04
	 */
	chartOfAccounts?: string
	/**
	 * Callout or Onboarding modal
	 */
	field?: string
}
export interface ChangeOverdraftLimitActioned {
	action: string
	changeDirection?: string
}
export interface CheckProofOfFundsBankAccounts {
	action?: string
	hasBanksWithDetails?: boolean
	/**
	 * Number of bank accounts with missed info (IBAN & BIC)
	 */
	missedInfoBanksCount?: number
}
export interface ChoosePlanModalActioned {
	/**
	 * The action taken by the user
	 */
	action: string
	/**
	 * The length of the commitment in months (1 or 12)
	 */
	commitment?: number
	/**
	 * Whether the company is updating from a legacy plan or not
	 */
	fromLegacyPlan?: boolean
	/**
	 * The billing interval (paid monthly or yearly)
	 */
	interval?: string
	/**
	 * Place within the app where the modal originated (opened)
	 */
	origin?: string
	/**
	 * The type of plan
	 */
	plan?: string
	/**
	 * The plan the company is on before upgrading
	 */
	previousPlan?: string
	/**
	 * Section of the page where the modal was opened from
	 */
	section?: string
	/**
	 * Step for the new plan update modal
	 */
	step?: string
	/**
	 * Whether there is enough balance in the wallet to cover the new plan
	 */
	sufficientBalance?: boolean
	/**
	 * userEmail for better Hubspot support
	 */
	userEmail?: string
}
export interface ClearFilterClicked {
	company_id?: string
	/**
	 * the page in which the filter are cleared
	 */
	page: string
}
export interface CompanyAddReviewerCompleted {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * ID of the company having their review settings updated
	 */
	company_id?: string
	/**
	 * Unique identifier of the user that was added
	 */
	user_id?: string
}
export interface CompanyAddReviewerStarted {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * ID of the company having their review settings updated
	 */
	company_id?: string
}
export interface CompanyCountrySelected {
	company_country?: string
	/**
	 * The flow from which the event was triggered
	 */
	flow?: string
	isRecommended?: boolean
}
export interface CompanyInfoViewed {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface CompanyLanguageChanged {
	previous_value: string
	value: string
}
export interface CompanyOnboardingBookkeepingChoiceActioned {
	choice?: string
}
export interface CompanyOnboardingFooterCardActioned {
	card?: string
}
export interface CompanyOnboardingMilestoneActioned {
	action?: string
	milestone?: string
	task?: string
}
export interface CompanyOnboardingNextStepsModalActioned {
	cta?: string
}
export interface CompanyReviewDisabledCompleted {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * ID of the company having their review settings updated
	 */
	company_id?: string
}
export interface CompanyReviewDisabledStarted {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * ID of the company having their review settings updated
	 */
	company_id?: string
}
export interface CompanyReviewEnabled {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * ID of the company having their review settings updated
	 */
	company_id?: string
}
export interface CompanyReviewOnboardingModalActioned {
	/**
	 * Action taken by the user.
	 */
	action: string
	/**
	 * The current step in the onboarding flow
	 */
	step?: number
}
export interface CompanyReviewPageViewed {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * ID of the company having their review settings updated
	 */
	company_id?: string
}
export interface CompanyReviewThresholdUpdated {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * ID of the company having their review settings updated
	 */
	company_id?: string
	/**
	 * Previous threshold value
	 */
	previous_value?: number | null
	/**
	 * Threshold range type
	 */
	threshold_type?: string
	/**
	 * Threshold value
	 */
	value?: number | null
}
export interface CompanyReviewerRemoved {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * ID of the company having their review settings updated
	 */
	company_id?: string
	/**
	 * Unique identifier of the user that was removed
	 */
	user_id?: string
}
export interface CompanyVerifyCompleted {
	/**
	 * Indicates the step that was completed (Address, ManagementVerify, AMLQuestions)
	 */
	step?: string
}
export interface ConfirmTransferCheckboxActioned {
	action?: string
	type?: string
}
export interface ConnectApp {
	/**
	 * The user role
	 */
	actor: string
	/**
	 * The ID of the people app
	 */
	applicationId?: string
	/**
	 * The category of the app
	 */
	category: string
	/**
	 * The name of the app
	 */
	name: string
	/**
	 * The status of the app connection
	 */
	status?: string
	/**
	 * The type of the app
	 */
	type: string
}
export interface CreateAccountActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
	 */
	action?: string
	/**
	 * Web language
	 */
	locale?: string
	/**
	 * Full URL
	 */
	origin?: string
	/**
	 * Page name (partial URL)
	 */
	path?: string
	/**
	 * States if the user signed in via google SSO or a manual email entering
	 */
	signin?: string
}
export interface CreateAccountUserActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
	 */
	action?: string
	/**
	 * The flow from which the event was triggered
	 */
	flow?: string
	/**
	 * Web language
	 */
	locale?: string
	/**
	 * Full URL
	 */
	origin?: string
	/**
	 * Page name (partial URL)
	 */
	path?: string
}
export interface CreateAccountVerificationActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
	 */
	action?: string
	/**
	 * We pass a captcha upon entering number
	 */
	captcha_passed?: boolean
	/**
	 * The flow from which the event was triggered
	 */
	flow?: string
	/**
	 * Web language
	 */
	locale?: string
	/**
	 * Full URL
	 */
	origin?: string
	/**
	 * Page name (partial URL)
	 */
	path?: string
	/**
	 * Type of verification performed (sms or authentication app)
	 */
	type?: string
}
export interface CreateBudgetBanner {
	action: string
}
export interface CreateCategoryActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
	 */
	action?: string
	/**
	 * Web language
	 */
	locale?: string
	/**
	 * Number of categories added
	 */
	n_categories?: number
	/**
	 * Full URL
	 */
	origin?: string
	/**
	 * Page name (partial URL)
	 */
	path?: string
}
export interface CreateCompanyActioned {
	/**
	 * The action which was performed
	 */
	action?: string
	/**
	 * True if company passes auto_sdd on creation
	 */
	auto_sdd?: boolean
	/**
	 * Business type of the company
	 */
	business_type?: string
	/**
	 * Country of the company
	 */
	country?: string
	/**
	 * The flow from which the event was triggered
	 */
	flow?: string
	/**
	 * Web language
	 */
	locale?: string
	/**
	 * Boolean, true if the company was manually found and completed
	 */
	manual?: boolean
	/**
	 * Full URL
	 */
	origin?: string
	/**
	 * Page name (partial URL)
	 */
	path?: string
	/**
	 * Size of the company
	 */
	size?: string
	/**
	 * Describes what triggered the event
	 */
	trigger?: string
}
export interface CreateCompanyModalActioned {
	/**
	 * Was the modal cancelled or continued by the user
	 */
	action?: string
	/**
	 * What is the role of the user performing the action
	 */
	actor_role?: string
}
export interface CreateCompanyModalViewed {
	/**
	 * What is the role of the user performing the action
	 */
	actor_role?: string
}
export interface CreatePasscodeActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
	 */
	action?: string
	/**
	 * The flow from which the event was triggered
	 */
	flow?: string
	/**
	 * Web language
	 */
	locale?: string
	/**
	 * Full URL
	 */
	origin?: string
	/**
	 * Page name (partial URL)
	 */
	path?: string
}
export interface CreateTagGroupCompleted {
	/**
	 * ERP system
	 */
	system?: string | null
	/**
	 * Tag group name
	 */
	tag_group_name?: string
}
export interface CreateTeamCompleted {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface CreateTeamStarted {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface CustomFormatSettingsActioned {
	/**
	 * The type of delimiter used
	 */
	delimiter?: string
	/**
	 * The type of the file expenses will be exported to
	 */
	file_type?: string
	/**
	 * Single or multi-line format selected
	 */
	type?: string
}
export interface DatevAccountExampleModalActioned {
	account?: string
	action?: string
}
export interface DatevAccountingSetupGuidedTour {
	action?: string
	step?: string
}
export interface DatevOnboardingModuleViewed {
	action?: string
	source?: string
}
export interface DefaultLanguageUpdateCompleted {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Previous default language
	 */
	previous_value?: string
	/**
	 * New default language
	 */
	value?: string
}
export interface DefaultLanguageUpdateStarted {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface DefaultPerPurchaseLimitUpdated {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Previous limit value
	 */
	previous_value?: string | null
	/**
	 *  Limit value
	 */
	value?: string | null
}
export interface DefaultSpendingLimitUpdated {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Previous limit value
	 */
	previous_value?: string | null
	/**
	 * Type of limit
	 */
	type?: string | null
	/**
	 * Limit value
	 */
	value?: number | null
}
export interface DeleteReimbursementModalViewed {
	expense_id?: string
	expense_type?: string
	export_page_version?: number | null
	export_status?: string
	page?: string
	role?: string
}
export interface DemoBudgetActioned {
	/**
	 * States the action which was performed when the events were triggered
	 */
	action: string
	/**
	 * States the name of the element that triggered the action
	 */
	source: string
}
export interface DeselectAllClicked {
	/**
	 * Where this action was taken within the app

	 */
	source: string
	/**
	 * The step within the user adding wizard
	 */
	step: string
}
export interface DirectDebitAgreementCreationActioned {
	action: string
	action_status?: string
	amount?: number
	back_to_page?: string
	field_name?: string
	field_value?: string
	pleo_reserve_enabled?: boolean
	step_name?: string
	threshold?: number
}
export interface DirectReimbursementEnabled {
	company_id: string
	source: string
}
export interface DisconnectApp {
	/**
	 * The user role
	 */
	actor: string
	/**
	 * The ID of the people app
	 */
	applicationId?: string
	/**
	 * The category of the app
	 */
	category: string
	/**
	 * The number of entities in Pleo
	 */
	countOfEntitiesInPleo?: number
	/**
	 * The number of provisioned users
	 */
	countOfProvisionedUsers?: number
	/**
	 * The name of the app
	 */
	name: string
	/**
	 * The name of the screen the action is performed
	 */
	screen: string
	/**
	 * The type of the app
	 */
	type: string
}
export interface DiscoverFeaturesModalActioned {
	action?: string
	isOnTrial?: boolean
	plan?: string
}
export interface DowngradeBlockedModalActioned {
	action?: string
	origin?: string
	section?: string
	variant?: string
}
export interface Download {
	/**
	 * Count of downloaded expenses
	 */
	expense_count?: number
	export_page_version?: number | null
	/**
	 * Tab that the download was actioned in ("wallet", "pocket", "all")
	 */
	tab?: string
	/**
	 * Type of download ("spreadsheet", "receipts", "export")
	 */
	type?: string
}
export interface DownloadAppModalActioned {
	action: string
}
export interface DownloadPeopleClicked {
	/**
	 * Whether user has access to organization
	 */
	hasOrgAccess: boolean
	/**
	 * User role
	 */
	role: string
	/**
	 * Type of the file to be downloaded
	 */
	type: string
}
export interface EmployeeCardSpendingLimitReset {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Previous limit value
	 */
	previous_value?: string | null
	/**
	 * New limit value
	 */
	value?: string | null
}
export interface EmployeeCardSpendingLimitUpdated {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Previous limit value
	 */
	previous_value?: string | null
	/**
	 * Limit type
	 */
	type?: string | null
	/**
	 * Limit value
	 */
	value?: string | null
}
export interface EmployeeCodeUpdated {
	/**
	 * Role of actor
	 */
	actor_role: string
	/**
	 * Previous code assigned to the employee
	 */
	previous_value?: string | null
	/**
	 * New code assigned to the employee
	 */
	value?: string | null
}
export interface EmployeeDeleted {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * The selected option for the dedicated cards owned by the employee (reassign cards to a different employee or delete the cards)
	 */
	dedicated_cards_action?: string
	/**
	 * The id of the deleted employee
	 */
	deleted_employee_id?: string
	/**
	 * The id of the employee the dedicated cards have been reassigned to
	 */
	reassigned_employee_id?: string
}
export interface EmployeeEmailChangeRequested {
	/**
	 * Role of actor
	 */
	actor_role: string
}
export interface EmployeeOnboardingActioned {
	/**
	 * What action has the user taken, i.e. "viewed", "completed" or "app_store_clicked"
	 */
	action?: string
	/**
	 * Indicates the step in the flow where the action was taken
	 */
	step?: string
	/**
	 * Indicates if the Google Play Store or the App store was chosen in the download app step
	 */
	store?: string
}
export interface EmployeePerPurchaseSpendingLimitUpdated {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Previous limit value
	 */
	previous_value?: string | null
	/**
	 * Limit value
	 */
	value?: string | null
}
export interface EmployeeRedirectActioned {
	action?: string
	method?: string
}
export interface EmployeeRoleUpdated {
	/**
	 * Role of actor
	 */
	actor_role: string
	/**
	 * Previous role assigned to the employee
	 */
	previous_value: string
	/**
	 * New role assigned to the employee
	 */
	value: string
}
export interface EmployeeSearchCompleted {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface EmployeesListActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned"
	 */
	action?: string
	/**
	 * Number of employees in the list
	 */
	employeesCount?: number
	/**
	 * Section from which the action was fired
	 */
	location?: string
	/**
	 * Roles of the employees displayed in the list
	 */
	role?: string | null
}
export interface EnableCardsActioned {
	/**
	 * Action taken
	 */
	action: string
	/**
	 * Where in the app was the action taken
	 */
	source: string
	/**
	 * The ID of the user/employee for whom the card will be enabled
	 */
	user_id?: string
}
export interface EvaluateFeatureFlags {
	/**
	 * The status of the loading the FeatureFlag
	 */
	status: string
}
export interface ExpenseAttendeesEditClicked {
	/**
	 * Id of the expense
	 */
	expense_id?: string
	/**
	 * Viewer role
	 */
	viewer?: string
}
export interface ExpenseDeleted {
	/**
	 * Id of the expense
	 */
	expense_id?: string
	/**
	 * Type of expense deleted: "purchase", "mileage", "bill, perdiem"
	 */
	type?: string
}
export interface ExpenseDetailCategoryUpdated {
	expense_id?: string | null
	/**
	 * selected account ID
	 */
	new_account_id?: string | null
	old_account_id?: string | null
	/**
	 * Position of the suggestion pressed
	 */
	selected_suggestion_index?: number | null
	/**
	 * dropdown or suggestion chip or prefill
	 */
	source?: string | null
	/**
	 * number of suggestions showed to user
	 */
	suggestions_count?: number | null
	/**
	 * control or ai or data

	 */
	variation?: string | null
}
export interface ExpenseDetailsNavigationActioned {
	/**
	 * action can be either: up_button_clicked, down_button_clicked or closed
	 */
	action: string
	company_id?: string
	/**
	 * the id of the expense the user will navigate to
	 */
	expense_id: string | null
	/**
	 * The page in which the action is triggered
	 */
	page: string
}
export interface ExpenseDetailsUpdated {
	/**
	 * Expense Id
	 */
	expense_id: string
	/**
	 * Field that was updated
	 */
	field: string
	/**
	 * New value of the expense's property
	 */
	new_value?: any | null
	/**
	 * Previous value of the expense's property
	 */
	previous_value?: any | null
	/**
	 * Type of the purchase
	 */
	purchase_type: string
	/**
	 * Expense review status
	 */
	review_status: string
	/**
	 * Viewer role
	 */
	viewer: string
}
export interface ExpenseDetailsViewed {
	/**
	 * Expense Id
	 */
	expense_id: string
	/**
	 * Is expense locked
	 */
	locked: boolean
	/**
	 * Page on which the user is seeing the expense
	 */
	page?: string
	/**
	 * Type of the purchase
	 */
	purchase_type: string
	/**
	 * Expense review status
	 */
	review_status?: string
	/**
	 * Type of the list that has this expense
	 */
	source: string
	/**
	 * Viewer role
	 */
	viewer: string
}
export interface ExpenseInExportSelected {
	/**
	 * Whether the user selected or deselected the expense
	 */
	action?: string
	/**
	 * How many expenses in the lane at the time the expense was selected/deselected
	 */
	all_expenses_count?: number
	/**
	 * Whether the expense was already selected when the user loaded the page (i.e. was selected in a previous session)
	 */
	already_selected_on_page_load?: boolean
	/**
	 * How many expenses were selected when this expense was selected/deselected
	 */
	current_selected_count?: number
	expense_id?: string
	export_page_version?: number | null
	/**
	 * How many expenses were already selected on page load
	 */
	selected_on_page_load_count?: number
	/**
	 * Which tab of the export page the select was actioned on
	 */
	tab?: string
}
export interface ExpenseNoteUpdated {
	/**
	 * id of the updated expense
	 */
	expense_id?: string
	/**
	 * Relation of the modifier of the expense (e.g. expense_owner, member, reviewer, bookkeeper, bookkeeper-extended, bookkeeper-basic)
	 */
	modifier?: string
	/**
	 * The expense has gotten a request for more details
	 */
	needs_details?: boolean
	/**
	 * Previous note text
	 */
	prev_value?: any | null
	/**
	 * type of purchase (ATM, ONLINE, IN_STORE, MANUAL). This maps the definition used in deimos_transactions
	 */
	purchase_type?: string
	/**
	 * New note text applied
	 */
	value?: string
}
export interface ExpenseReceiptUpdated {
	/**
	 * Determines the type of the action
	 */
	action: string
	/**
	 * Expense Id
	 */
	expense_id: string
	/**
	 * Type of the purchase
	 */
	purchase_type: string
	/**
	 * Expense review status
	 */
	review_status: string
	/**
	 * Viewer role
	 */
	viewer: string
}
export interface ExpenseSplitStarted {
	/**
	 * User role
	 */
	actor_role: string
	/**
	 * Expense ID
	 */
	expense_id: string
}
export interface ExpenseTabInExportActioned {
	/**
	 * Expense details edited in the expense tab of the  Export page
	 */
	action?: string
	/**
	 * which company is performing the action
	 */
	company_id?: string
	/**
	 * under which context the action happened like split_expense etc
	 */
	context?: string | null
	/**
	 * Expense ID
	 */
	expense_id?: string
	/**
	 * Type of expense where the action happened
	 */
	expense_type?: string | null
	export_page_version?: number | null
	/**
	 * only on the split expense context
	 */
	is_split_automated?: boolean | null
	/**
	 * New Value
	 */
	new_value?: string | null
	/**
	 * Previous Value
	 */
	previous_value?: string | null
	/**
	 * used to determine if admins or bookkeepers are more likely to change the settings
	 */
	role?: string
}
export interface ExpenseTagSplitUpdated {
	/**
	 * User role
	 */
	actor_role: string
	/**
	 * Expense ID
	 */
	expense_id: string
}
export interface ExpenseTagUpdated {
	/**
	 * id of the updated expense
	 */
	expense_id?: string
	/**
	 * The expense has a suggested tag
	 */
	has_suggestions?: boolean
	/**
	 * Relation of the modifier to the expense (e.g. expense_owner, member, reviewer, bookkeeper, bookkeeper-extended, bookkeeper-basic)
	 */
	modifier?: string
	/**
	 * The expense has gotten a request for more details
	 */
	needs_details?: boolean
	/**
	 * Name of the previous tag
	 */
	prev_tag?: string
	/**
	 * id of the previous tag
	 */
	prev_tag_id?: any | null
	/**
	 * type of purchase (ATM, ONLINE, IN_STORE, MANUAL). This maps the definition used in deimos_transactions
	 */
	purchase_type?: string
	/**
	 * indicates if the chosen tag was a suggested tag or not
	 */
	suggested?: boolean
	/**
	 * Name of the tag chosen
	 */
	tag?: string
	/**
	 * id of the tag chosen
	 */
	tag_id?: string | null
}
export interface ExpenseTaxSuggestionSaved {
	/**
	 * ID of the expense
	 */
	expense_id: string
	/**
	 * The selected tax rate matches with suggested tax rate
	 */
	matches_suggested_tax_rate?: boolean
	/**
	 * The changes is done via the suggestion pill or the dropdown
	 */
	source: string
	/**
	 * Boolean that says if the suggestion is accepted or not
	 */
	suggestion_used: boolean
}
export interface ExpensesEmptyStateActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned"
	 */
	action?: string
	/**
	 * States the element that triggered the action
	 */
	input?: string
}
export interface ExpensesInExportSelected {
	/**
	 * Whether the user selected or deselected all
	 */
	action?: string
	export_page_version?: number | null
	/**
	 * Which tab of the export page the select was actioned on
	 */
	tab?: string
}
export interface ExpensesListFiltered {
	/**
	 * Kind of filter applied
	 */
	filtered_by: string
	/**
	 * Role of the user
	 */
	viewer: string
}
export interface ExplorePlansClicked {
	featureName?: string
	isOnTrial: boolean
	origin: string
	plan: string
}
export interface ExportAgainModalActioned {
	/**
	 * Whether they continue or cancel on warning modal
	 */
	action?: string
}
export interface ExportBetaBannerActioned {
	/**
	 * Whether the user enabled or disabled the beta
	 */
	action: string
	/**
	 * Whether the user was in the optional (old page by default) or default (new page by default) beta group
	 */
	beta_group: string
}
export interface ExportDatesBeyondRestrictedRangeSelected {
	/**
	 * End date of the range selected by user
	 */
	end_date?: string
	/**
	 * Length of the selected range in days
	 */
	range_length?: number
	/**
	 * Start date of the range selected by user
	 */
	start_date?: string
}
export interface ExportFiltersActioned {
	/**
	 * Filters actioned in the Export page to give a more filtered overview
	 */
	action?: string
	/**
	 * which company is performing the action
	 */
	company_id?: string
	/**
	 * Quick filter ID
	 */
	date_range_id?: string | null
	export_page_version?: number | null
	/**
	 * If user selects date in future
	 */
	is_future_date_selected?: boolean
	/**
	 * Number of days filters
	 */
	num_of_days_queried?: number | null
	/**
	 * used to determine if admins or bookkeepers are more likely to change the settings
	 */
	role?: string
	/**
	 * Which tab of the export page the filter was actioned on
	 */
	tab?: string
}
export interface ExportHistoryActioned {
	/**
	 * Which action was taken on the filter
	 */
	action?: string
	export_page_version?: number | null
}
export interface ExportLaneActioned {
	/**
	 * The type of action taken in the lane "queued", "removed", "exported", "reexported"
	 */
	action?: string
	/**
	 * Number of expenses which the expense_count deviates from the suggested "export-ready" expenses.
	 */
	delta?: number
	/**
	 * Number of expenses moved in the lane
	 */
	expense_count?: number
	export_page_version?: number | null
}
export interface ExportNavigationActioned {
	export_page_version?: number | null
	/**
	 * The export view which the user navigated from
	 */
	previous_view?: string
	/**
	 * The current export view; "not_exported", "queued", "exported", "settled" (All or Expenses), "pending"
	 */
	view?: string
}
export interface ExportPageActioned {
	action?: string
	expense_id?: string
	expense_type?: string
	export_status?: string
	page?: string
	role?: string
}
export interface ExportRepresentationAttendeesActioned {
	/**
	 * Type of attendee change action: "add", "create", "delete" or "edit"
	 */
	action?: string
	/**
	 * Id of the expense
	 */
	expense_id?: string
}
export interface ExportRepresentationCalculateClicked {
	/**
	 * Id of the expense
	 */
	expense_id?: string
}
export interface ExportRepresentationDoneClicked {
	/**
	 * Id of the expense
	 */
	expense_id?: string
}
export interface ExportRepresentationTaxAmountUpdated {
	/**
	 * Tax amount
	 */
	amount?: number
	/**
	 * Id of the expense
	 */
	expense_id?: string
}
export interface ExportRepresentationTipsAmountUpdated {
	/**
	 * Tips amount
	 */
	amount?: number
	/**
	 * Id of the expense
	 */
	expense_id?: string
}
export interface ExportRepresentationTypeSelected {
	/**
	 * Id of the expense
	 */
	expense_id?: string
	/**
	 * Type of representation
	 */
	type?: string
}
export interface ExportSuggestionSelected {
	/**
	 * Which company is performing the action
	 */
	company_id?: string
	/**
	 * Suggested value field name
	 */
	name?: string
	/**
	 * Used to determine if admins or bookkeepers are more likely to select suggestion
	 */
	role?: string
}
export interface ExportTableError {
	/**
	 * The error code
	 */
	error_code?: string
	/**
	 * The error message
	 */
	error_message?: string
	/**
	 * Export page version
	 */
	export_page_version?: number
	/**
	 * The lane the user was on when the error occurred
	 */
	lane?: string
	/**
	 * Export or reports page
	 */
	page?: string
}
export interface ExportValidationActioned {
	/**
	 * Started, Failed, Passed etc.,
	 */
	action?: string
	/**
	 * integration system name
	 */
	system?: string
	/**
	 * Modal types like confirmation, noAccountingSystem etc.,
	 */
	type?: string
}
export interface FailedToLoadReceipt {
	/**
	 * The ID of the PDF document to display
	 */
	receiptID?: string | null
}
export interface FailedToSourceReceipt {
	/**
	 * The ID of the PDF document to display
	 */
	receiptID?: string | null
}
export interface FeatureClicked {
	action: string
	featureName: string | null
	isOnTrial: boolean
	plan: string
}
export interface FetchOffboardingActioned {
	/**
	 * User action
	 */
	action?: string
	/**
	 * Custom input from the user
	 */
	comment?: string | null
	/**
	 * Reason to disable fetch
	 */
	reasons?: any[]
}
export interface FetchOnboardingActioned {
	/**
	 * Action taken in the onboarding flow
	 */
	action?: string
	/**
	 * Indicates whether the user signed up with Gmail or Outlook
	 */
	email_provider?: string
	/**
	 * indicates at which step the event was fired
	 */
	step?: string
}
export interface FetchPromotionBanner {
	/**
	 * Action that led to firing of this event
	 */
	action: string
	/**
	 * Result of the action
	 */
	result?: string
}
export interface FindCompany {
	/**
	 * Company country
	 */
	country?: string
	/**
	 * Web language
	 */
	locale?: string
	/**
	 * Boolean, true if the company was manually entered
	 */
	manual?: boolean
	/**
	 * Full URL
	 */
	origin?: string
	/**
	 * Page name (partial URL)
	 */
	path?: string
}
export interface FirstReimburseYourselfFromWeb {
	action?: string
}
export interface FlowCompletedAppcues {
	flowId?: string
	flowName?: string
	flowType?: string
}
export interface FlowStartedAppcues {
	flowId?: string
	flowName?: string
	flowType?: string
}
export interface ForgotPasscodeLinkActioned {
	/**
	 * Define whether the action is performed on the initial login flow or the add account flow
	 */
	action?: string
	/**
	 * What is the role of the user performing the action
	 */
	actorRole?: string
}
export interface Interaction {
	formId?: any | null
	value?: string
}
export interface User {
	email?: string
}
export interface FormFieldSubmittedAppcues {
	email?: string
	flowId?: string
	flowName?: string
	flowType?: string
	flowVersion?: number
	interaction?: Interaction
	interactionType?: string
	stepId?: string
	stepNumber?: number
	stepType?: string
	user?: User
}
export interface ResponseItem {
	fieldId?: string
	fieldRequired?: boolean
	fieldType?: any | null
	label?: string
	value?: string
}
export interface Interaction1 {
	formId?: any | null
	response?: ResponseItem[]
}
export interface User1 {
	email?: string
}
export interface FormSubmittedAppcues {
	flowId?: string
	flowName?: string
	flowType?: string
	flowVersion?: number
	interaction?: Interaction1
	interactionType?: string
	stepId?: string
	stepNumber?: number
	stepType?: string
	user?: User1
}
export interface FortnoxAccountingSetupGuidedTour {
	action?: string
	step?: string
}
export interface FortnoxOnboardingModuleViewed {
	/**
	 * Action the user took on the modal
	 */
	action?: string
	/**
	 * Source of the accounting system setup
	 */
	source?: string
}
export interface FreezeCardModalActioned {
	/**
	 * The action that triggered the event
	 */
	action: string
	/**
	 * The id of the dedicated card
	 */
	card_id: string
	/**
	 * The limit amount set on the card
	 */
	card_limit_amount: number
	/**
	 * The currency of the company using the card
	 */
	card_limit_currency?: string
	/**
	 * The limit type set on the card
	 */
	card_limit_type: string
	/**
	 * The name of the cards
	 */
	card_name: string
	/**
	 * The id of the employee owning the card
	 */
	card_owner?: string | null
	/**
	 * The id of the company the card owner is part of
	 */
	company_id: string
	/**
	 * The source/page from where the event was triggered
	 */
	source: string
}
export interface GetStartedActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned"
	 */
	action?: string
	/**
	 * States the name of the element that triggered the action
	 */
	input?: string
	/**
	 * Visible milestones when the action is viewed.
	 */
	milestones?: any[]
	/**
	 * Visible tasks when the action is viewed.
	 */
	tasks?: any[]
}
export interface GettingStartedGuideCtaActioned {
	/**
	 * Step in which the CTA was clicked
	 */
	step: string
}
export interface GoogleAdsOptimisationSignal {
	/**
	 * Size of the user company
	 */
	company_size: string
	/**
	 * Currency of the Google optimisation event
	 */
	currency: string
	/**
	 * Google click identifier
	 */
	gclid?: string
	/**
	 * Value given by Pleo to the conversion
	 */
	value: number
}
export interface HrisEarlyAccessBannerActioned {
	/**
	 * The name of the active entity when the link was actioned
	 */
	companyName?: string
	/**
	 * The page where the banner was shown
	 */
	pageSource?: string
}
export interface HrisEarlyAccessBannerDismissed {
	/**
	 * The name of the active entity when the link was actioned
	 */
	companyName?: string
	/**
	 * The page where the banner was shown
	 */
	pageSource?: string
}
export interface HrisEarlyAccessModalDismissed {
	/**
	 * The name of the active entity when the link was actioned
	 */
	companyName?: string
	/**
	 * The page where the banner was shown
	 */
	pageSource?: string
}
export interface HrisEarlyAccessOptInSubmitted {
	/**
	 * The name of the active entity when the link was actioned
	 */
	companyName?: string
	/**
	 * The HR system the user selected to request early access to
	 */
	hrSystem?: string
	/**
	 * The page where the banner was shown
	 */
	pageSource?: string
}
export interface HelpCentreLinkActioned {
	/**
	 * Link to the help centre article/collection
	 */
	link?: string
	/**
	 * Where in the app, the link is actioned
	 */
	source?: string
}
export interface HelpWidgetActioned {
	/**
	 * Widget action
	 */
	action: string
	/**
	 * What is the company id of the user performing the action
	 */
	company_id?: string
	/**
	 * If the company is multi-entity, what is the organization id of the user
	 */
	organization_id?: string
	/**
	 * What is the role of the user performing the action
	 */
	role?: string
	/**
	 * The help centre links are being redesigned into the new adoption centre
	 */
	source?: string
}
export interface HelpWidgetViewed {
	/**
	 * Adoption prompt viewed
	 */
	action?: string
}
export interface IntroActioned {
	action: string
}
export interface IntroViewed {
	screen?: string
}
export interface InvitationEmailResent {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface InviteBookkeeperActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
	 */
	action?: string
	/**
	 * Web language
	 */
	locale?: string
	/**
	 * Full URL
	 */
	origin?: string
	/**
	 * Page name (partial URL)
	 */
	path?: string
}
export interface InviteFlowBulkAssignTeamActioned {
	/**
	 * How many teams already exist at the company
	 */
	current_number_of_teams: number
	/**
	 * Is the admin inviting users from an integration
	 */
	is_using_hris_integration: boolean
	/**
	 * how many users were checked when a bulk action was completed
	 */
	number_of_users_selected: number
	/**
	 * role of the user performing the action
	 */
	role: string
}
export interface InviteFlowCreateTeamActioned {
	/**
	 * the number of teams currently at the company
	 */
	current_number_of_teams: number
	is_organization: boolean
	/**
	 * Is the admin inviting users from an integration
	 */
	is_using_hris_integration: boolean
	/**
	 * How many reviewers were added to the new team
	 */
	number_of_added_reviewers: number
	/**
	 * role of the user performing the action
	 */
	role: string
	/**
	 * From which area of the product did the invite flow start
	 */
	tracking_source?: string
}
export interface InviteFlowCreateTeamDrawerClosed {
	current_number_of_teams: number
	is_organization: boolean
	/**
	 * Is the admin inviting users from an integration
	 */
	is_using_hris_integration: boolean
	role: string
	/**
	 * From which area of the product did the invite flow start
	 */
	tracking_source?: string
}
export interface InviteFlowCreateTeamDrawerOpened {
	/**
	 * How many teams currently exist for the company
	 */
	current_number_of_teams: number
	is_organization: boolean
	/**
	 * Is the admin inviting users from an integration
	 */
	is_using_hris_integration?: boolean
	/**
	 * role of the user performing the action
	 */
	role: string
	/**
	 * From which area of the product did the invite flow start
	 */
	tracking_source?: string
}
export interface InviteFlowSelectTeamActioned {
	/**
	 * How many teams currently exist for the company
	 */
	current_number_of_teams: number
	is_organization: boolean
	/**
	 * Is the admin inviting users from an integration
	 */
	is_using_hris_integration: boolean
	/**
	 * The role of user performing the action. Admin, BK, Partner
	 */
	role: string
	/**
	 * From which area of the product did the invite flow start
	 */
	tracking_source?: string
}
export interface InvitePeopleLinkClicked {
	/**
	 * The user role
	 */
	actor: string
	/**
	 * The category a connected people app
	 */
	connectedPeopleAppCategory?: string
	/**
	 * The ID of the connected people app
	 */
	connectedPeopleAppId?: string
	/**
	 * The name of the connected people app
	 */
	connectedPeopleAppName?: string
	/**
	 * The type of a connected people app
	 */
	connectedPeopleAppType?: string
	/**
	 * The name of the screen the action is performed
	 */
	screen: string
}
export interface InviteTeamActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
	 */
	action: string
	/**
	 * User clicks the CTA either on the product card or the milestone card of the Get Started Guide
	 */
	cta?: string
}
export interface InviteTeamEmailActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
	 */
	action?: string
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * The added user ID
	 */
	employee_id?: string
	/**
	 * Web language
	 */
	locale?: string
	/**
	 * Number of members invited
	 */
	n_invites?: number
	/**
	 * Number of cardAccess members invited
	 */
	n_invites_card?: number
	/**
	 * Number of cardless members invited
	 */
	n_invites_cardless?: number
	/**
	 * Full URL
	 */
	origin?: string
	/**
	 * Page name (partial URL)
	 */
	path?: string
	/**
	 * Boolean, true if the step was skipped
	 */
	skipped?: boolean
	/**
	 * Location in the app where the event originated
	 */
	source?: string
	/**
	 * The type of user added
	 */
	type?: string
}
export interface InviteUserCancelled {
	/**
	 * Where this action was taken within the app
	 */
	source: string
	/**
	 * The step within the user adding wizard
	 */
	step: string
}
export interface InviteUsers {
	/**
	 * The role of the current user
	 */
	actor: string
	/**
	 * The category a connected people app
	 */
	connectedPeopleAppCategory?: string
	/**
	 * The ID of the connected people app
	 */
	connectedPeopleAppId?: string
	/**
	 * The name of the connected people app
	 */
	connectedPeopleAppName?: string
	/**
	 * The type of a connected people app
	 */
	connectedPeopleAppType?: string
	/**
	 * The number of users being invited
	 */
	count: number
	/**
	 * The number of card and reimbursement users
	 */
	countOfCardAndReimbursementUsers: number
	/**
	 * The number of card users
	 */
	countOfCardUsers: number
	/**
	 * The number of entities in Pleo
	 */
	countOfEntitiesInPleo: number
	/**
	 * The number of new entities outside Pleo
	 */
	countOfNewEntitiesOutsidePleo: number
	/**
	 * The total number of provisioned users
	 */
	countOfProvisionedUsers: number
	/**
	 * The number of reimbursement users
	 */
	countOfReimbursementUsers: number
	/**
	 * The number of users with assigned team
	 */
	countOfUsersWithTeam?: number
	/**
	 * The number of validation errors
	 */
	countOfValidationErrors?: number
	/**
	 * It tells whether reimbursements was enabled during this invite
	 */
	enabledReimbursements?: boolean
	/**
	 * The source of the users being invited
	 */
	source: string
	/**
	 * The current step in the invite flow
	 */
	step: string
}
export interface InvoiceAdoptionPromptActioned {
	action?: string
}
export interface KycContinueActioned {
	/**
	 * Name of the section the Continue button is clicked on
	 */
	value: string
}
export interface KycIndustryActioned {
	/**
	 * What action has the user taken
	 */
	action: string
	/**
	 * Whether or not the selected industry was part of the list of suggested industries
	 */
	is_suggested?: boolean
	/**
	 * The method of user interaction
	 */
	method?: string
}
export interface KycListActioned {
	state: string
	value: string
}
export interface KycPersonActioned {
	/**
	 * What action has the user taken, i.e. "started", "completed", "next" or "close_accordion"
	 */
	action?: string
	/**
	 * Determines if the event takes place in the representative screen or in the person-in-scope screen
	 */
	admin?: boolean
}
export interface KycSectionActioned {
	action: string
	value: string
}
export interface KycVatActioned {
	/**
	 * What action has the user taken
	 */
	action: string
	/**
	 * The VAT validation error message
	 */
	error?: string
	/**
	 * Whether or not the user indicated that they do not have a VAT number
	 */
	no_vat_selected?: boolean
}
export interface LandingPagesEligibleViewed {
	/**
	 * Page in which the component is displayed
	 */
	page?: string
}
export interface LegacyPlanBanner {
	action?: string
	current_plan?: string
}
export interface LogOutClicked {
	/**
	 * Number of active accounts on the device
	 */
	numberOfActiveAccounts: number
}
export interface ManageAutoTopUp {
	action: string
	/**
	 * Selected Auto Top-up amount (for the Low Balance strategy)
	 */
	atu_amount_low_balance?: number
	/**
	 * Selected Auto Top-up amount (for the Scheduled strategy)
	 */
	atu_amount_scheduled?: number
	/**
	 * Selected Auto Top-up frequency (for the Scheduled strategy)
	 */
	atu_frequency_scheduled?: string
	/**
	 * Selected Auto Top-up status
	 */
	atu_status?: string
	/**
	 * Selected Auto Top-up threshold (for the Low Balance strategy)
	 */
	atu_threshold_low_balance?: number
	/**
	 * Selected Auto Top-up type
	 */
	atu_type: string
	/**
	 * Is used together with "switch_strategy" action to know about previous type of Auto Top-up
	 */
	prev_auto_top_up_type?: string
}
export interface ManageWalletViewed {
	is_eligible_for_reserve?: boolean
	tab: string
}
export interface ManualExpenseAdded {
	/**
	 * Id of the account associated with the expense
	 */
	accountId?: string
	/**
	 * Country in which the expense was made
	 */
	country?: string
	/**
	 * Currency in which the expense was made
	 */
	currency?: string
	/**
	 * Id of the expense
	 */
	expense_id?: string
	/**
	 * Whether the user has added a note to the expense or not
	 */
	hasNote?: boolean
	/**
	 * Tags added to the expense
	 */
	tags?: Record<string, any>
}
export interface MemberSettingReviewingComplete {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface MemberSettingReviewingStarted {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface MemberSettingsActioned {
	/**
	 * Specific editing action which triggered the event
	 */
	action?: string
	/**
	 * Which company is committing an action
	 */
	company_id?: string
	/**
	 * Which employee is the action being taken on
	 */
	employee_id?: string | null
	/**
	 * Web language
	 */
	locale?: string
	/**
	 * Full URL
	 */
	origin?: string
	/**
	 * Page name (partial URL)
	 */
	path?: string
	/**
	 * used to determine if admins or bookkeepers are more likely to change the settings
	 */
	role?: string
}
export interface MemberSettingsReviewerRemoved {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface MemberSettingsTeamUpdated {
	/**
	 * Role of actor
	 */
	action_role?: string
	/**
	 * Name of the team
	 */
	value?: string
}
export interface MetaAdsOptimisationSignal {
	/**
	 * Size of the user company
	 */
	company_size: string
	/**
	 * Currency of the Meta Ads conversion event
	 */
	currency: string
	/**
	 * Meta ad fbclid
	 */
	fbc?: string
	/**
	 * Meta Pixel unique identifier
	 */
	fbp?: string
	/**
	 * If the user email domain is gmail.com or not
	 */
	is_generic_email: boolean
	/**
	 * Value given by Pleo to the conversion
	 */
	value: number
}
export interface MileageAccountUpdated {
	/**
	 * Unique system-wide company_id
	 */
	company_id?: string
	/**
	 * New rate value
	 */
	new_value?: string
	/**
	 * Previous rate value
	 */
	previous_value?: string | null
}
export interface MileageDefaultRateUpdated {
	/**
	 * Unique system-wide pleo company_id
	 */
	company_id?: string
	/**
	 * rate currency
	 */
	currency?: string
	/**
	 * new rate value
	 */
	new_value?: number
	/**
	 * previous rate value
	 */
	previous_value?: number
}
export interface MileageDownloadActioned {
	/**
	 * The action which the user performed, these can be 'download_completed', 'modal_opened', download_clicked', 'modal_dismissed'
	 */
	action?: string
	/**
	 * If action is download_completed then this states the end date of the selected date range
	 */
	end_date?: string
	/**
	 * The reason the report creation failed
	 */
	failure_reason?: string
	/**
	 * If action is modal_opened then this shows if there were any mileage expenses available to download.
	 */
	has_mileage_expenses?: boolean
	/**
	 * Number of expenses
	 */
	number_of_expenses?: number
	/**
	 * If action is download_clicked then this shows if the user used a preselected date range or went with a custom date range. Options: ‘this_month’, ‘last_month’, ‘this_quarter’, ‘this_year’, ‘custom’
	 */
	preselected_date_range?: string
	/**
	 * If action is download_completed then this states the start date of the selected date range
	 */
	start_date?: string
}
export interface MileageEmployeeRateUpdated {
	/**
	 * Unique system wide pleo company_id
	 */
	company_id?: string
	/**
	 * currency of the rate
	 */
	currency?: string | null
	/**
	 * Unique system wide pleo employee_id
	 */
	employee_id?: string
	/**
	 * New rate value
	 */
	new_value?: number | null
	/**
	 * page from which the settings were updated (this can currently either be the people page or the mileage page)
	 */
	page?: string
	/**
	 * New rate value
	 */
	previous_value?: number | null
}
export interface MileageListActioned {
	/**
	 * What action has the user taken, i.e. "sort" or "search"
	 */
	action?: string
	/**
	 * What input was used for the action, i.e. "distance_asc"
	 */
	input?: string
}
export interface MileageRateUpdated {
	/**
	 * Unique system-wide company_id
	 */
	company_id?: string
	/**
	 * Rate currency
	 */
	currency?: string
	/**
	 * New rate value
	 */
	new_value?: number
	/**
	 * Previous rate value
	 */
	previous_value?: number | null
}
export interface MissingSettingsExportModal {
	action?: string
	number_exported_card_expenses?: number
	number_exported_pocket_expenses?: number
	number_exported_reimbursements?: number
	type?: string
}
export interface MissingTranslation {
	/**
	 * Translation key that was missing
	 */
	key?: string
	/**
	 * The locale that the user is using when the missing translation was detected
	 */
	locale?: string
}
export interface MyAccountClicked {
	/**
	 * The variant of the switcher user is viewing
	 */
	variant: string
}
export interface MyCardsClicked {
	/**
	 * The variant of the switcher user is viewing
	 */
	variant: string
}
export interface NewPricingBanner {
	action?: string
	current_plan?: string
}
export interface NewPurchaseOrderActioned {
	/**
	 * Action performed by the user
	 */
	action: string
	/**
	 * the role of the user performing the action
	 */
	actor_role: string
	currency?: string
	empty_state?: boolean
	error_name?: string
	/**
	 * Purchase order id when the purchase order is created successfully
	 */
	purchase_order_id?: string
	/**
	 * List of reviewers added to the purchase order
	 */
	reviewer_ids?: any[]
	source: string
	supplier?: string
	total_amount?: string
}
export interface NewVendorCardActioned {
	/**
	 * The action that triggered the event

	 */
	action: string
	/**
	 * the list of allowed vendor ids

	 */
	allowed_vendor_id?: string[] | null
	/**
	 * the list of allowed vendor names
	 */
	allowed_vendor_name?: string[] | null
	/**
	 * The card id
	 */
	card_id?: string
	/**
	 * The card limit amount set by the user

	 */
	card_limit_amount?: number
	/**
	 * The card limit currency

	 */
	card_limit_currency?: string
	/**
	 * The card limit type, it can be either Monthly, Weekly or No limit

	 */
	card_limit_type?: string
	/**
	 * The card name set by the user

	 */
	card_name?: string
	/**
	 * The card owner Id
	 */
	card_owner?: string
	/**
	 * The company Id

	 */
	company_id?: string
	/**
	 * Is the Vendor rule active on the vendor card

	 */
	having_allowed_vendor?: boolean
	/**
	 * The page name in which the event happened

	 */
	source?: string
}
export interface OffboardingModalActioned {
	/**
	 * User clicks one of three options
	 */
	landing_page_action?: string
	/**
	 * The url the user is on
	 */
	url_source?: string
}
export interface OffboardingWizardActioned {
	/**
	 * The action taken by the user
	 */
	action: string
	/**
	 * The current step in the wizard
	 */
	step: string
}
export interface OnboardTeamSpendingLimitActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
	 */
	action?: string
	/**
	 * The company currency
	 */
	currency?: string
	/**
	 * The limit type that is updated
	 */
	limit_type?: string
	/**
	 * The new value being updated
	 */
	new_value?: string
	/**
	 * Page/Url name
	 */
	path?: string
	/**
	 * The previous limit value
	 */
	previous_value?: string
}
export interface OnboardingBannerClicked {
	cta?: string
	export_page_version?: number | null
	location?: string
}
export interface OnboardingWalletBankTransferTopUp {
	action?: string
	/**
	 * Param for action 'copy transfer details'
	 */
	copied_field_name?: string
}
export interface OpenBankingModalActioned {
	action: string
	/**
	 * Amount of money to Top up
	 */
	amount?: number
	/**
	 * VRP settings
	 */
	autoTopUpAmount?: number
	/**
	 * VRP settings
	 */
	autoTopUpThreshold?: number
	/**
	 * User's input on select bank screen at the trigger event time
	 */
	bankSearchQuery?: string
	callbackStatus?: string
	callbackType?: string
	currency?: string
	iban?: string
	/**
	 * Id of selected bank
	 */
	institutionId?: string
	/**
	 * Click on button (when result is empty) to navigate to Bank Transfer Details
	 */
	isEmptyResultsButton?: boolean
	/**
	 * Click on footer link to navigate to Bank Transfer Details
	 */
	isFooterLink?: boolean
	redirectUrl?: string
	/**
	 * For [show screen] event to understand which step of modal is visible now
	 */
	screenName?: string
	/**
	 * Stage of Consent token processing
	 */
	tokenStage?: string
	/**
	 * Number of banks in response after bank search request
	 */
	totalBankItemsCount?: number
}
export interface OrderCardsActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
	 */
	action: string
	/**
	 * Actor's role
	 */
	actor_role?: string
	/**
	 * Id of the employee entry that was changed
	 */
	employee_id?: string | null
	/**
	 * Employee name error displayed to the user
	 */
	error_name?: string
	/**
	 * Was the user manually selected or automatically selected after resolving an error
	 */
	is_manual_ticked?: string
	/**
	 * Number of cards ordered
	 */
	n_cards?: number
	/**
	 * The number of employees selected
	 */
	n_selected_employees?: number | null
	/**
	 * Updated employee name
	 */
	new_employee_name?: string
	/**
	 * Employee's previous name
	 */
	previous_employee_name?: string
	/**
	 * Where the card action is performed
	 */
	view?: string
}
export interface OrderCardsCompleted {
	/**
	 * User's role
	 */
	actor_role?: string
	/**
	 * Number of cards ordered in this placement
	 */
	quantity?: number
}
export interface OrderCardsStarted {
	/**
	 * User role
	 */
	actor_role?: string
	/**
	 * Max number of cards the company can order
	 */
	max_order?: number
	/**
	 * Number of employees invited to Pleo
	 */
	no_of_employees?: number
	/**
	 * Number of cards already in use
	 */
	paired_cards?: number
	/**
	 * Number of card about to be ordered in this placement
	 */
	quantity?: number
}
export interface OrderCompleted {
	/**
	 * The company size in terms of number of emplyoyees. This can be a single number or a range eg: 5-9
	 */
	company_employee_size?: string
	/**
	 * The 2 character alpha-2 country code for the company is registered in
	 */
	country_code?: string
	/**
	 * Currency of the Facebook purchase event
	 */
	currency?: string
	/**
	 * Stage of the funnel where event is fired
	 */
	funnel_stage?: string
	/**
	 * Value of the Facebook purchase event
	 */
	revenue?: number
	/**
	 * Value of the Google purchase event
	 */
	total?: number
	/**
	 * Value given by Pleo to the conversion
	 */
	value?: number
}
export interface OrderPlasticCardsList {
	/**
	 * Number of employees in the card order list
	 */
	n_all_listed_employees?: number
	/**
	 * Number of employees that contain invalid characters
	 */
	n_invalid_char_employees?: number
	/**
	 * Number of employees that have a "Too long name" error
	 */
	n_long_name_employees?: number
	/**
	 * Number of employees missing an employee name
	 */
	n_missing_name_employees?: number
	/**
	 * Number of employees selected by default
	 */
	n_selected_employees?: number
	/**
	 * Number of employees that has an "Invalid character error"
	 */
	n_warning_employees?: number
}
export interface OverdraftBannerActioned {
	action: string
}
export interface OverdraftConfirmationActioned {
	action: string
}
export interface OverdraftEligibilityApplication {
	action: string
}
export interface OverdraftHigherLimit {
	action: string
	/**
	 * It only appear when the action is "increase limit clicked"
	 */
	applicationState?: string
}
export interface OverdraftOnboardingAccordionItem {
	action?: string
	state?: string
}
export interface OverdraftRenewalActioned {
	action: string
}
export interface OverdraftSettings {
	action: string
}
export interface OverdraftStatementsActioned {
	action: string
	statement_date?: string
}
export interface OverdraftSummaryActioned {
	action: string
	isAutoRepaymentsEnabled?: boolean
}
export interface OverdraftTermsActioned {
	action: string
	auto_repayments_enabled?: string
	currency?: string
	field?: string
	limit?: number
	newValue?: string
}
export interface OverdraftUnderwritingApplicationActioned {
	action: string
}
export interface Screen {
	/**
	 * The height of the user's screen
	 */
	height?: number
	/**
	 * The width of the user's screen
	 */
	width?: number
}
export interface PageViewed {
	/**
	 * The url after http://app.pleo.io/[name]
	 */
	name?: string
	/**
	 * The page the user in on when this event was triggered
	 */
	path?: string
	/**
	 * The previous page the user was on before landing on this page
	 */
	previous_path?: string | null
	referrer?: string
	/**
	 * The user's screen size (width and height)
	 */
	screen?: Screen
	search?: string
	title?: string
	url?: string
}
export interface PartnerAcademy {
	/**
	 * Tells us what action the user has performed
	 */
	action?: string
	/**
	 * Tells us what video the user watched
	 */
	video?: string
}
export interface Bookkeeper {
	/**
	 * The id of the bookkeeper.
	 */
	id: string | null
}
export interface Client {
	/**
	 * The id of the partner client.
	 */
	id: string | null
}
export interface PartnerClientBookkeepersChanged {
	/**
	 * Whether the bookkeeper was assigned or removed.
	 */
	action: string
	/**
	 * Object containing information about the bookkeeper.
	 */
	bookkeeper: Bookkeeper
	/**
	 * Object containing information about the partner client.
	 */
	client: Client | null
}
export interface PartnerClientCreated {
	/**
	 * Pleo system-wide company id
	 */
	company_id?: string
	/**
	 * Pleo system-wide company name
	 */
	company_name?: string
}
export interface Client1 {
	/**
	 * The id of the client.
	 */
	id: string | null
}
export interface PartnerClientDetailsDrawerDisconnectClient {
	/**
	 * Object containing information about the onboarding client.
	 */
	client: Client1 | null
}
export interface Client2 {
	/**
	 * The id of the client.
	 */
	id: string | null
}
export interface PartnerClientDetailsDrawerDismissed {
	/**
	 * Object containing information about the onboarding client.
	 */
	client: Client2 | null
	/**
	 * Element used to dismiss the drawer, either the drawer close icon or the drawer overlay area.
	 */
	element: string
}
export interface Client3 {
	/**
	 * The id of the partner client currently being viewed.
	 */
	id: string | null
}
export interface ToClient {
	/**
	 * The id of the client the user is navigating to.
	 */
	id: string | null
}
export interface PartnerClientDetailsDrawerNavigationButtonsActioned {
	/**
	 * Object containing information about the partner client currently being viewed.
	 */
	client: Client3 | null
	/**
	 * Whether the user is navigating up or down the list. As in, navigating to the previous or to the next client of the list.
	 */
	direction: string
	/**
	 * Information about the client the user is navigating to.
	 */
	to_client: ToClient
}
export interface Client4 {
	/**
	 * The id of the client.
	 */
	id: string | null
}
export interface PartnerClientDetailsDrawerPermissionsSettingsActioned {
	/**
	 * The action performed against the Permissions section.
	 */
	action: string
	/**
	 * Object containing information about the onboarding client.
	 */
	client: Client4
	/**
	 * The current Permission Status for the given client.
	 */
	status: string | null
}
export interface Client5 {
	/**
	 * The id of the client.
	 */
	id: string | null
}
export interface PartnerClientDetailsDrawerTabsActioned {
	/**
	 * Object containing information about the onboarding client.
	 */
	client: Client5 | null
	/**
	 * The name of the tab that was clicked.
	 */
	tab: string
}
export interface PartnerClientListFilters {
	/**
	 * Tells us what interaction the user had with the filter/sort option
	 */
	action: string
	/**
	 * Tells us what value the user has filtered or sorted by
	 */
	value: any[]
}
export interface PartnerClientOnboardingSuccessModalActioned {
	/**
	 * The button that has been actioned
	 */
	action: string
}
export interface PartnerEmployeeCsvOffboardActioned {
	action?: string
}
export interface PartnerEmployeeExportActioned {
	action?: string
}
export interface PartnerEmployeeInviteFormActioned {
	/**
	 * The name of the action performed. Enum of the possible actions.
	 */
	action: string
	/**
	 * The number of invited employees of the performed action.
	 */
	number_of_employees?: number
}
export interface PartnerInviteAccepted {
	/**
	 * email of invited user
	 */
	invited_email?: string
	/**
	 * first name of invited user
	 */
	invited_first_name?: string
	/**
	 * boolean, false if the user does not already have a pleo account.
	 */
	new_user?: boolean
	/**
	 * The type indicates if the invitation accepted was an invite to become a partner employee, or an invite to create a new partner (and in process become its owner)
	 */
	type?: string
}
export interface PartnerInviteFlowActioned {
	/**
	 * The action being performed: started or completed.
	 */
	action: string
	/**
	 * Whether the flow is for an existing user or a new one.
	 */
	new_user: boolean
	/**
	 * The type of invite: new-partner or partner-employee
	 */
	type: string
}
export interface Client6 {
	/**
	 * The id of the client.
	 */
	id: string | null
	/**
	 * The onboarding status of the client.
	 */
	status: string
}
export interface PartnerOnboardingClientOptionsMenuActioned {
	/**
	 * Either open_menu (when options menu was opened) or the name of the option button that was clicked
	 */
	action: string
	/**
	 * Object containing information about the onboarding client.
	 */
	client: Client6
}
export interface PartnerPortalFeedback {
	/**
	 * Name of the feature the user is giving feedback
	 */
	feature: string
	/**
	 * Free text message to describe their feedback
	 */
	feedback_message: string
	/**
	 * Partner ID
	 */
	partner_id: string
	/**
	 * User ID
	 */
	user_id: string
}
export interface PartnerPricingCalculator {
	/**
	 * Monthly or Annual billing frequency
	 */
	billing_frequency: string
	/**
	 * Request with cashback (where discounts are disabled). Only available on annual billing frequency with Advanced or Beyond plans
	 */
	cashback: boolean
	currency: string
	/**
	 * Used to calculate cashback
	 */
	expected_monthly_spend: number
	partner_id: string
	/**
	 * Partner tier selected
	 */
	partner_tier: string
	/**
	 * Pricing plan selected
	 */
	pricing_plan: string
	/**
	 * Number of users in quote
	 */
	user_count: number
}
export interface PartnerPromo {
	/**
	 * Tells us what interaction the user had with the Partner Promo
	 */
	action: string
	/**
	 * Tells us which variant of the Partner Promo was displayed to the user
	 */
	variant?: string
}
export interface PartnerSelfOnboardingDrawerActioned {
	/**
	 * The action performed by the user on the drawer
	 */
	action: string
}
export interface Task {
	/**
	 * The name of the task actioned by the user.
	 */
	name: string
	/**
	 * The status of the task at the moment of the action.
	 */
	status: string
}
export interface PartnerSelfOnboardingTaskActioned {
	/**
	 * The action performed by the user on the onboarding task.
	 */
	action: string
	/**
	 * The details of the task actioned by the user.
	 */
	task: Task
}
export interface PartnerSelfSignupEmployeesInvited {
	/**
	 * The number of employees that have been invited. 0 means the user has skipped this step.
	 */
	'invited-employees': number
}
export interface PartnerSelfSignupPartnershipCreated {
	/**
	 * Tells us whether the partner company has been selected from the registri, has been inserted manually or the user already had a pleo company.
	 */
	source: string
}
export interface PartnerSelfSignupUserDetails {
	/**
	 * The user's email entered during the signup flow.
	 */
	email?: string
	/**
	 * The user's first name entered during the signup flow.
	 */
	first_name?: string
	/**
	 * True if the logged in user is trying to signup with an email address that's different from the one already associated with them
	 */
	is_different_user?: boolean
	/**
	 * True if the user is already logged in when landing on the partner signup flow
	 */
	is_logged_in?: boolean
	/**
	 * True if the logged in user is already a partner user and has landed on the signup flow again for whatever reason
	 */
	is_partner_user?: boolean
	/**
	 * The user's last name entered during the signup flow.
	 */
	last_name?: string
	/**
	 * True if the email entered by the user is already associated with a login account.
	 */
	user_exists?: boolean
}
export interface PartnerTeamActioned {
	action?: string
	operation?: string
}
export interface PartnerTeamClientActioned {
	action?: string
	operation?: string
}
export interface PartnerTeamMemberActioned {
	action?: string
	operation?: string
}
export interface PasscodeResetConfirmPasscodeFailed {
	/**
	 * Was the passcode reset initialed by the user themselves. False if triggered by an admin.
	 */
	isSelfInitiated: boolean
}
export interface PasscodeResetEnterVerificationCode {
	/**
	 * Was the passcode reset initialed by the user themselves. False if triggered by an admin.
	 */
	isSelfInitiated: boolean
	/**
	 * Which type of OTP was used
	 */
	method?: string
}
export interface PasscodeResetLinkExpiredScreenViewed {
	/**
	 * Was the passcode reset initialed by the user themselves. False if triggered by an admin.
	 */
	isSelfInitiated: boolean
}
export interface PasscodeResetLogoutToContinueScreenViewed {
	/**
	 * Was the passcode reset initialed by the user themselves. False if triggered by an admin.
	 */
	isSelfInitiated: boolean
	/**
	 * The number of accounts a user has logged in on that browser
	 */
	number_of_logged_in_accounts?: number
}
export interface PasscodeResetSuccess {
	/**
	 * Was the flow self initiated or triggered by an admin
	 */
	isSelfInitiated: boolean
}
export interface PaywallActioned {
	/**
	 * The action taken by the user within the paywall
	 */
	action: string
	/**
	 * Whether or not the modal is dismissible
	 */
	is_dismissible: boolean
	/**
	 * The text of the link clicked by the user, used with action ’link_clicked’
	 */
	link_text?: string
	/**
	 * The URL target if the user clicks a link
	 */
	target_url?: string
	/**
	 * The screen visible to the user within the paywall
	 */
	view: string
}
export interface PaywallFeedbackSubmitted {
	/**
	 * The option selected by the user
	 */
	option: string
	/**
	 * The text submitted by the user when option is 'Other'
	 */
	other_text?: string
}
export interface PendingOverdraftActioned {
	action?: string
}
export interface PeoplePageViewed {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface PhoneNumberStepActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
	 */
	action?: string
	/**
	 * The flow from which the event was triggered
	 */
	flow?: string
	/**
	 * Web language
	 */
	locale?: string
	/**
	 * Full URL
	 */
	origin?: string
	/**
	 * Page name (partial URL)
	 */
	path?: string
}
export interface PleoBankFeed {
	/**
	 * Feed enabled or disabled
	 */
	action: string
}
export interface PleoGuideReserveCardActioned {
	action?: string
	dd_agreement_in_progress?: boolean
	kyc_verification_in_progress?: boolean
	status?: string
}
export interface PleoGuideReserveProductCardActioned {
	action?: string
	dd_agreement_in_progress?: boolean
	kyc_verification_in_progress?: boolean
	status?: string
}
export interface PleoGuideViewed {
	/**
	 * Boolean, true if this step is completed upon the time where the user viewed the pleo guide and triggered this event
	 */
	connect_accounting_completed?: boolean
	/**
	 * Boolean, true if this step is completed upon the time where the user viewed the pleo guide and triggered this event
	 */
	invite_team_completed?: boolean
	/**
	 * Web language
	 */
	locale?: string
	/**
	 * Full URL
	 */
	origin?: string
	/**
	 * Page name (partial URL)
	 */
	path?: string
	/**
	 * Boolean, true if this step is completed upon the time where the user viewed the pleo guide and triggered this event
	 */
	try_card_completed?: boolean
}
export interface PleoReserveCollectionsBannerActioned {
	action?: string
	state?: string
}
export interface PleoReserveCollectionsModalActioned {
	action?: string
	feedback?: string
	state?: string
}
export interface PleoReserveCollectionsTopUpMethodActioned {
	action?: string
	transfer_type?: string
}
export interface PleoReserveOnboardingModalActioned {
	action: string
	screen_number?: number
	source?: string
}
export interface PocketAccountActioned {
	action?: string
	previous_value?: string | null
	value?: string
}
export interface PocketAdoptionPromptActioned {
	action?: string
}
export interface PocketBalanceActioned {
	action?: string
	balance?: string
	direct_reimbursement_permission?: string
	has_employee_card_bank_account?: boolean
	number_expenses?: number
}
export interface PocketDateInExportActioned {
	/**
	 * Describes action type
	 */
	action: string
	/**
	 * Whether the user has checked or unchecked the don't warn checkbox
	 */
	dont_warn?: boolean
	/**
	 * The previous value entered
	 */
	previous_value?: string
	/**
	 * The value entered
	 */
	value?: string
}
export interface PocketEasyStartAssigned {
	/**
	 * Unique system-wide company_id
	 */
	company_id: string
}
export interface PocketEnabled {
	source?: string
}
export interface PocketManualTransferModalActioned {
	action: string
	type: string
}
export interface PocketNotCompatibleWithLexOfficeModal {
	action?: string
	step?: string
	system_name?: string
}
export interface PocketOnboardingActioned {
	/**
	 * User selects an account in the accounting set up guide
	 */
	account_name?: string
	account_type?: string
	/**
	 * Accounting system integrated with Pleo
	 */
	accounting_system?: string
	action?: string
	/**
	 * User submits a review threshold in the accounting setup guide
	 */
	review_amount?: number | null
	source?: any | null
	step?: string
}
export interface PocketPageActioned {
	action?: string
	expense_id?: string
	page?: string
	reimbursement_id?: string
	type?: string
	user_id?: string
}
export interface PocketPageFilterActioned {
	action?: string
	/**
	 * The filter applied
	 */
	filter_name?: string
	/**
	 * The value applied to the filter
	 */
	filter_value?: string
	/**
	 * Whether a user has searched the list of users or not
	 */
	has_search_query?: boolean
}
export interface PocketPageModalActioned {
	action?: string
	name?: string
}
export interface PocketSetupBannerActioned {
	action?: string
	step?: string
}
export interface PocketSetupCancelled {
	/**
	 * false if the cancel was due to an error
	 */
	error?: boolean
	/**
	 * name of the screen which the cancellation happened on
	 */
	step?: string
}
export interface PocketStatementDownloadActioned {
	/**
	 * Describes action type
	 */
	action: string
	/**
	 * Date that admin selected to have statement generated for
	 */
	date?: string
}
export interface PocketTransferActioned {
	action?: string
	amount?: number
	currency?: string
	employee_id?: string | null
	has_direct_reimbursement_enabled?: boolean
	has_employee_card_bank_account?: boolean
	manual_method?: string
	method?: string
	number_expenses?: number
	reimbursement_target?: string
}
export interface PocketTransferAdded {
	/**
	 * Amount transfered
	 */
	amount?: number
	/**
	 * Currency in which the transfer happened
	 */
	currency?: string
	/**
	 * id of the employee involved in the transfer
	 */
	employee?: string
	/**
	 * Transfer method ('received_in_person', 'deduct_from_next_salary', 'bank_transfer', 'other', 'paid_with_next_salary', 'cash_handout')
	 */
	method?: string
	/**
	 * Note added to the transfer
	 */
	note?: string
	remaining_balance?: number
	/**
	 * Type of action ('reimbursement', 'cash_return')
	 */
	type?: string
}
export interface PocketTransferNewDesignModalActioned {
	action?: string
	company_id?: string
	role?: string
	step?: number
}
export interface PocketTransferStepViewed {
	/**
	 * Name of the specific step viewed ('user', 'reimburse', 'receive_cash', 'review')
	 */
	step?: string
}
export interface PocketVideoViewed {
	''?: Record<string, any>
}
export interface PricingEnquiryLinkActioned {
	/**
	 * The outgoing URL of the link
	 */
	destination: string
	/**
	 * Where the link was pressed within the product
	 */
	location: string
	/**
	 * The current URL when the user pressed the link
	 */
	source: string
}
export interface ProductUsageActioned {
	/**
	 * What action has the user taken
	 */
	action: string
	/**
	 * The flow from which the event was triggered
	 */
	flow: string
	has_usage_objectives?: boolean
	num_usage_objectives?: number
	/**
	 * The values submitted by the user
	 */
	usage_objectives: any[]
}
export interface ProfileDetailsUpdated {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Which info has been updated
	 */
	type?: string
}
export interface ProjectedSpendWidget {
	action: string
}
export interface PromoCardActioned {
	/**
	 * One of ["viewed", "abandoned" or "completed"]
	 */
	action: string
	/**
	 * The copy displayed on the banner when this action was taken
	 */
	message: string
	/**
	 * Where the card appears, eg. navigation menu bottom
	 */
	placement: string
}
export interface PurchaseOrderActioned {
	action: string
	actor_role: string
	/**
	 * Invoice that is assigned to PO
	 */
	invoice_id?: string
	purchase_order_id: string
	source: string
}
export interface PurchaseOrderReviewActioned {
	action: string
	actor_role: string
	purchase_order_id: string
	source: string
}
export interface QuestionIconActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
	 */
	action?: string
	/**
	 * Web language
	 */
	locale?: string
	/**
	 * Full URL
	 */
	origin?: string
	/**
	 * Page name (partial URL)
	 */
	path?: string
}
export interface RecardingOrderStepViewed {
	/**
	 * The flow has 5 steps. 1) message, 2) cards, 3) address, 4) confirmation, 5) completed
	 */
	step?: string
}
export interface ReceiptForwarding {
	expense_id: string
	export_page_version?: number | null
	forwarding_address_copied: boolean
}
export interface ReceiptInExportPageActioned {
	/**
	 * Action performed
	 */
	action: string
	/**
	 * Id of the receipt
	 */
	receipt_id: string
}
export interface ReceiptRotation {
	page?: string
	receipt_id: string
	receipt_type?: string | null
	/**
	 * Degrees rotated
	 */
	rotated?: number
}
export interface ReceiptScalingInExportPage {
	/**
	 * action performed by the user
	 */
	action?: string
	/**
	 * Id of the invoice that is being scaled
	 */
	invoice_id?: string | null
	/**
	 * Id of the receipt that is being scaled
	 */
	receipt_id?: string | null
	/**
	 * Integer representing the percentage of zoom
	 */
	value?: number
}
export interface RecommendationPromptActioned {
	/**
	 * action from the user on the prompt
	 */
	action: string
	/**
	 * company id of the user
	 */
	company_id: string
	/**
	 * where the prompt was visible (page)
	 */
	source: string
	/**
	 * type of recommendation prompt either
	 */
	type: string
	/**
	 * role of the user inside the company
	 */
	user_role: string
	/**
	 * name of the vendor on which we get the recommendation prompt
	 */
	vendor_name?: string
}
export interface ReconnectApp {
	/**
	 * The user role
	 */
	actor: string
	/**
	 * The ID of the people app
	 */
	applicationId?: string
	/**
	 * The category of the app
	 */
	category: string
	/**
	 * The state of the error
	 */
	errorState?: string
	/**
	 * The name of the app
	 */
	name: string
	/**
	 * The type of the app
	 */
	type: string
}
export interface RecurringVendorDetailsPageActioned {
	/**
	 * user action details
	 */
	action: string
	/**
	 * the type of error the user is reporting for the vendor description (AI generated)
	 */
	ai_feedback_type?: string
	/**
	 * the user's free text containing the issue description.
	 */
	ai_feedback_value?: string
	/**
	 * user's company_id
	 */
	company_id: string
	/**
	 * id of the expense clicked
	 */
	expense_id?: string
	/**
	 * name of the filter if the event is a filter interaction
	 */
	filter_name?: string
	/**
	 * value of the filter applied
	 */
	value?: string
	/**
	 * vendor id
	 */
	vendor_id?: string
	/**
	 * name of the vendor from where the expense was clicked
	 */
	vendor_name?: string
}
export interface RecurringVendorsPageActioned {
	/**
	 * user action detail
	 */
	action: string
	/**
	 * user's company_id
	 */
	company_id: string
	/**
	 * name of the filter if the event is a filter interaction
	 */
	filter_name?: string | null
	/**
	 * free text of the note user set for a specific vendor contract
	 */
	note?: string
	/**
	 * owner id of a specific vendor (set in management contract tab)
	 */
	owner_user_id?: string
	/**
	 * source page where the recurring vendor details drawer was opened
	 */
	source?: string
	/**
	 * quantity of usage for the defined type  (e.g. 300 licences included in the contract)
	 */
	usage_quantity?: string
	/**
	 * type of vendor usage limitation (e.g. licences, clicks, data volume etc...)
	 */
	usage_type?: string
	/**
	 * value of the filter applied
	 */
	value?: string
	/**
	 * name of the vendor when user click to visit the details drawer
	 */
	vendor_name?: string
}
export interface ReferralEmailActioned {
	action?: string
	origin?: string
}
export interface ReferralInviteActioned {
	origin?: string
}
export interface ReferralLinkActioned {
	action?: string
	origin?: string
}
export interface ReferralsHelpWidgetClicked {
	origin?: string
}
export interface ReimbursementArticleClicked {
	article_name: string
	source: string
}
export interface ReimbursementDeleted {
	expense_id?: string
	expense_type?: string | null
	export_page_version?: number | null
	export_status?: string
	page?: string
	role?: string
}
export interface ReimbursementOnboardingActioned {
	action: string
	permission_type?: string
	plan_name?: string
	source: string
	step: string
}
export interface ReimbursementOnboardingCancelled {
	plan_name: string
	source: string
	step: string
}
export interface ReimbursementPermissionTypeSet {
	company_id: string
	permission_type: string
	previous_permission_type?: string
	source: string
}
export interface ReimbursementSettingsPageActioned {
	action: string
	source: string
}
export interface ReimbursementsProjectionWidget {
	action: string
	currency?: string
	recommended_top_up_amount?: number
}
export interface RepayAccountActioned {
	action?: string
	previous_value?: string
	value?: string
}
export interface RepayActioned {
	action?: string
	amount?: number
	currency?: string
	current_method?: string
	direct_reimbursement_permission?: string
	failure_reason?: string
	has_employee_card_bank_account?: boolean
	method?: string
	new_method?: string
	number_expenses?: number
}
export interface RepayMethodsActioned {
	action?: string
	method?: string
}
export interface RepayOnboardingActioned {
	/**
	 * the action taken by the admin
	 */
	action?: string
	/**
	 * number of employees in the organization that are not currently part of a team with review rules
	 */
	number_of_unreviewed_employees?: number
	/**
	 * where the modal was opened from
	 */
	source?: string
	/**
	 * the step in the flow where the event is triggered
	 */
	step?: string
}
export interface RepayToBankAccountActioned {
	action?: string
	direct_reimbursement_permission?: string
	error_field?: string
	failure_reason?: string
	save_bank_account_details?: boolean
}
export interface RepayToCardActioned {
	action?: string
	direct_reimbursement_permission?: string
	failure_reason?: string
	save_bank_account_details?: boolean
}
export interface ReportsLinkInExportActioned {
	/**
	 * View user was on when Reports link clicked
	 */
	export_view?: string
}
export interface RequestNavigationActioned {
	/**
	 * Requests destination, 'requests panel'
	 */
	destination?: string
	/**
	 * Company Pricing Plan
	 */
	pricing_plan?: string
	/**
	 * Where is user coming from
	 */
	source?: string
}
export interface RequestPasscodeResetLink {
	/**
	 * From which screen did the user request a passcode reset link
	 */
	source?: string
}
export interface ReserveAccordionItemActioned {
	action?: string
	status?: string
}
export interface ReserveTestPreFddPageActioned {
	action?: string
	status?: string
}
export interface ResetPasscodeButtonClicked {
	/**
	 * What is the role of the user performing the action
	 */
	actorRole?: string
}
export interface ReviewActioned {
	/**
	 * id of the expense which have been reviewed
	 */
	expense_id?: string
	/**
	 * Role of the user who engaged with the review (e.g.  "expense_owner", "reviewer", "owner")
	 */
	modifier?: string
	/**
	 * Note which the reviewer made
	 */
	note?: any | null
	/**
	 * Status that the reviewer marked the expense at (e.g. WAITING_FOR_EXPENSE_OWNER, OK, NOT_OK, WAITING_FOR_REVIEWER)
	 */
	status?: string
}
export interface ReviewCompleted {
	/**
	 * Role of the user who engaged with the review (e.g.  "bookkeeper", "owner")
	 */
	actor_role?: string
	/**
	 * id of the expense which have been reviewed
	 */
	expense_id?: string
	/**
	 * Type of expense (e.g. "Pocket", "Card")
	 */
	expense_type?: string
	export_page_version?: number | null
	/**
	 * Review type
	 */
	review_initiator?: string
	/**
	 * Note which the reviewer made
	 */
	review_message?: any | null
	/**
	 * Status that the reviewer marked the expense at (e.g. WAITING_FOR_EXPENSE_OWNER, OK, NOT_OK, WAITING_FOR_REVIEWER)
	 */
	review_status?: string
	/**
	 * Tag which the reviewer added
	 */
	review_tag?: any[]
	/**
	 * Source page
	 */
	source?: string
}
export interface ReviewOverviewClickthrough {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Type or review the user selected
	 */
	review_type?: string
}
export interface ReviewOverviewViewed {
	/**
	 * • Role of actor
	 */
	actor_role?: string
}
export interface ReviewSettingsUpdated {
	/**
	 * Describes which action the user performed that resulted in an update (e.g. 'card_threshold_changed', 'pocket_threshold_changed', 'threshold_changed', 'reviewer_added', 'reviewer_removed')
	 */
	action?: string
	/**
	 * Pleo company_id
	 */
	company_id?: string
	/**
	 * Currency of the threshold
	 */
	currency?: string
	/**
	 * New maximum threshold value
	 */
	new_max_value?: number | null
	/**
	 * New (minimum) threshold value
	 */
	new_value?: number | null
	/**
	 * Previous maximum threshold value
	 */
	previous_max_value?: number | null
	/**
	 * Previous (minimum) threshold value
	 */
	previous_value?: number | null
	/**
	 * Team that got their review rules updated
	 */
	team_id?: string
}
export interface ReviewTabActioned {
	action: string
	actor_role: string
	count: number
	source: string
	tab: string
}
export interface ReviewerThresholdUpdateCompleted {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Expense review type:  ‘Card Expenses’ or ‘Pocket Expenses’
	 */
	review_type?: string
	/**
	 * Threshold value
	 */
	value?: number
}
export interface ReviewerThresholdUpdateStarted {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface ReviewersTabReviewCtaClicked {
	/**
	 * Company ID
	 */
	company_id: string
	/**
	 * The source in the app where the reviewers tab upsell is viewed from.
	 */
	source: string
	/**
	 * User ID
	 */
	user_id: string
}
export interface ReviewersTabReviewCtaViewed {
	/**
	 * Company ID
	 */
	company_id: string
	/**
	 * The source in the app where the reviewers tab upsell is viewed from.
	 */
	source: string
	/**
	 * User ID
	 */
	user_id: string
}
export interface ReviewersTabViewed {
	/**
	 * Company ID
	 */
	company_id: string
	/**
	 * The source in the app where the reviewers tab is viewed from.
	 */
	source: string
	/**
	 * User ID
	 */
	user_id: string
}
export interface RoleFilterCompleted {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Role filtered by
	 */
	value?: string
}
export interface RoleFilterStarted {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface SamlSsoAddExclusionClicked {
	/**
	 * The user role
	 */
	actor: string
	/**
	 * Count of exclusions user wants to add
	 */
	addedExclusionsCount: number
	/**
	 * The ID of the SAML SSO app
	 */
	applicationId?: string
	/**
	 * Count of exclusions that already exists
	 */
	existingExclusionsCount: number
	/**
	 * The name of the app
	 */
	name?: string
}
export interface SamlSsoDeactivateClicked {
	/**
	 * The user role
	 */
	actor: string
	/**
	 * The ID of the SAML SSO app
	 */
	applicationId?: string
	/**
	 * The name of the app
	 */
	name?: string
}
export interface SamlSsoEnableClicked {
	/**
	 * The user role
	 */
	actor: string
	/**
	 * The ID of the SAML SSO app
	 */
	applicationId?: string
	/**
	 * Whether user enabled config
	 */
	enabled: boolean
	/**
	 * The name of the app
	 */
	name?: string
}
export interface SamlSsoIdPClicked {
	/**
	 * The user role
	 */
	actor: string
	/**
	 * The ID of the SAML SSO app
	 */
	applicationId?: string
	/**
	 * Is user entitled to use SAML SSO feature
	 */
	isEntitled: boolean
	/**
	 * The name of the app
	 */
	name?: string
	/**
	 * The ID of the selected SAML SSO app
	 */
	selectedApplicationId?: string
	/**
	 * The name of the selected app
	 */
	selectedName?: string
}
export interface SamlSsoIdPTabVisited {
	/**
	 * The user role
	 */
	actor: string
	/**
	 * The ID of the SAML SSO app
	 */
	applicationId?: string
	/**
	 * Is user entitled to use SAML SSO feature
	 */
	isEntitled: boolean
	/**
	 * The name of the app
	 */
	name?: string
	/**
	 * The ID of the selected SAML SSO app
	 */
	selectedApplicationId?: string
	/**
	 * The name of the selected app
	 */
	selectedName?: string
	/**
	 * The name of the tab
	 */
	tab: string
}
export interface SamlSsoRemoveExclusionClicked {
	/**
	 * The user role
	 */
	actor: string
	/**
	 * The ID of the SAML SSO app
	 */
	applicationId?: string
	/**
	 * Total count of exclusions before removal
	 */
	existingExclusionsCount: number
	/**
	 * The name of the app
	 */
	name?: string
}
export interface SamlSsoSaveConfigClicked {
	/**
	 * Indicates type of user action with SAML SSO config
	 */
	action: string
	/**
	 * The user role
	 */
	actor: string
	/**
	 * The ID of the SAML SSO app
	 */
	applicationId?: string
	/**
	 * The name of the app
	 */
	name?: string
}
export interface SamlSsoUpgradeClicked {
	/**
	 * The user role
	 */
	actor: string
	/**
	 * The ID of the SAML SSO app
	 */
	applicationId?: string
	/**
	 * The name of the app
	 */
	name?: string
	/**
	 * The ID of the selected SAML SSO app
	 */
	selectedApplicationId?: string
	/**
	 * The name of the selected app
	 */
	selectedName?: string
	/**
	 * The source page where user clicked on the button
	 */
	source: string
}
export interface SamlSsoVisited {
	/**
	 * The user role
	 */
	actor: string
	/**
	 * The ID of the configured SAML SSO app
	 */
	applicationId?: string
	/**
	 * Is user entitled to use SAML SSO feature
	 */
	isEntitled: boolean
	/**
	 * The name of the configured app
	 */
	name?: string
}
export interface ScaOnboardingActioned {
	/**
	 * user role
	 */
	role?: string
	/**
	 * this would apply to all steps: 'welcome' | 'passcode' | 'phone' | 'phone_verify' (this is the last screen)
	 */
	step?: string
}
export interface ScaOnboardingCompleted {
	/**
	 * user role
	 */
	role?: string
}
export interface ScaOnboardingSkipped {
	/**
	 * user role
	 */
	role?: string
	/**
	 * this would apply to all steps: 'welcome' | 'passcode' | 'phone' | 'phone_verify' (this is the last screen)
	 */
	step?: Record<string, any>
}
export interface SearchBarActioned {
	/**
	 * the action can be either : opened, closed or searched
	 */
	action: string
	company_id?: string
	/**
	 * the page in which the search bar is actioned
	 */
	page: string
	/**
	 * the value the user typed in the searchbar
	 */
	value?: string
}
export interface SeeAllAvailableIntegrationsClicked {
	/**
	 * The user role
	 */
	actor: string
	/**
	 * The screen where the link was clicked
	 */
	screen: string
}
export interface SelectAllClicked {
	/**
	 * Where this action was taken within the app

	 */
	source: string
	/**
	 * The step within the user adding wizard
	 */
	step: string
}
export interface SelectAllExpensesActioned {
	/**
	 * Action can be either select or deselect all expenses
	 */
	action?: string
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Number of selected expenses
	 */
	expenses?: number
}
export interface SelectApp {
	/**
	 * The user role
	 */
	actor: string
	/**
	 * The ID of the people app
	 */
	applicationId: string
	/**
	 * The category of the app
	 */
	category: string
	/**
	 * The name of the app
	 */
	name: string
	/**
	 * The screen in which the integration was selected
	 */
	screen: string
	/**
	 * The type of the app
	 */
	type: string
}
export interface SelectCompanyBusinessType {
	/**
	 * Business type selected
	 */
	business_type?: string
	/**
	 * Web language
	 */
	locale?: string
	/**
	 * Full URL
	 */
	origin?: string
	/**
	 * Page name (partial URL)
	 */
	path?: string
}
export interface SelfExpansionCountrySelectAbandoned {
	/**
	 * The companyId of the user
	 */
	companyId: string
	/**
	 * Whether user has access to an organization, false if single entity admin
	 */
	hasOrgAccess: boolean
	/**
	 * The userId of the user
	 */
	userId: string
}
export interface SelfExpansionHelpArticleClicked {
	/**
	 * The companyId of the user who clicked the help article
	 */
	companyId: string
	/**
	 * Whether user has access to an organization, false if single entity admin
	 */
	hasOrgAccess: boolean
	/**
	 * The userId of who clicked the help article
	 */
	userId: string
}
export interface SelfExpansionInfoVideoViewed {
	/**
	 * The companyId of the user
	 */
	companyId: string
	/**
	 * Whether user has access to an organization, false if single entity admin
	 */
	hasOrgAccess: boolean
	/**
	 * The userId of the user
	 */
	userId: string
}
export interface SelfExpansionModalClosed {
	/**
	 * Whether user has access to an organization, false if single entity admin
	 */
	hasOrgAccess: boolean
	/**
	 * What step of the modal did the user drop off
	 */
	step: string
}
export interface SelfExpansionModalOpened {
	/**
	 * Whether user has access to an organization, false if single entity admin
	 */
	hasOrgAccess: boolean
	/**
	 * From which button did the user open the modal. Options: add account modal, the plus icon within the entity switcher panel, or new entity card visible to single entity admins
	 */
	source: string
}
export interface SelfExpansionMultiEntityPricingPlansClicked {
	/**
	 * The companyId of the user who clicked the pricing plans
	 */
	companyId: string
	/**
	 * Whether user has access to an organization, false if single entity admin
	 */
	hasOrgAccess: boolean
	/**
	 * The userId of who clicked the pricing plans
	 */
	userId: string
}
export interface SelfExpansionRequestSent {
	/**
	 * True if the user requests for entities not using pleo to be added
	 */
	addNewEntities: boolean
	/**
	 * Country where the new entity is located, if addNewEntities is true
	 */
	country?: any[]
	/**
	 * True if the user requests for entities already using pleo to be grouped
	 */
	groupExistingEntities: boolean
	/**
	 * Whether user has access to an organization, false if single entity admin
	 */
	hasOrgAccess: boolean
}
export interface SelfExpansionSignupAddEntityStepActioned {
	/**
	 * which action is performed on the add entity step
	 */
	action: string
	/**
	 * Country of the company
	 */
	country?: string
	trigger?: string
}
export interface SelfExpansionSignupOrganizationNameStepActioned {
	/**
	 * Which step in setting the organization name is actioned
	 */
	action: string
}
export interface SelfExpansionSignupSuccessButtonClicked {
	/**
	 * which button path was clicked, the pleo_guide or account_activation
	 */
	action: string
}
export interface SelfExpansionViewAllPricingPlansClicked {
	/**
	 * The companyId of the user performing the action
	 */
	companyId: string
	/**
	 * Whether user has access to an organization, false if single entity admin
	 */
	hasOrgAccess: boolean
	/**
	 * The userId of the user performing the action
	 */
	userId: string
}
export interface SequentialReviewSettingToggled {
	/**
	 * Company ID
	 */
	company_id: string
	/**
	 * Value of the sequential review toggle
	 */
	value: boolean
}
export interface SequentialReviewUpsellViewed {
	/**
	 * Company ID
	 */
	company_id: string
	/**
	 * URL path from where the upsell modal is viewed
	 */
	path: string
}
export interface SetLimitsActioned {
	action?: string
	n_per_purchase_limit?: number
	n_total_limit?: number
	source?: string
}
export interface SetupAccountingCategoriesProductTour {
	accounting_system?: string
	action?: string
	step?: string
}
export interface SetupAccountingCompleted {
	/**
	 * cta action of the modal
	 */
	action: string
}
export interface SetupAccountingConfirmationModalActioned {
	accounting_system?: string
	action?: string
	source?: string
	step?: string
}
export interface SetupAccountingPersonaActioned {
	/**
	 * When the action `access_selected` is fired
	 */
	access?: string
	/**
	 * Actions based on the progress of the invite flow
	 */
	action?: string
	/**
	 * When the action `persona_selected` is fired
	 */
	persona?: string
}
export interface SetupAccountingSettingsActioned {
	/**
	 * Name of the selected accounting system
	 */
	accounting_system?: string
	/**
	 * States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
	 */
	action?: string
	/**
	 * Boolean, true if a contra account was added
	 */
	contra_account_no?: boolean
	/**
	 * Web language
	 */
	locale?: string
	/**
	 * Full URL
	 */
	origin?: string
	/**
	 * Page name (partial URL)
	 */
	path?: string
	/**
	 * Boolean, true if a tax code was added
	 */
	tax_code?: boolean
}
export interface SetupAccountingStarted {
	/**
	 * User clicks the CTA either on the product card or the milestone card of the Get Started Guide
	 */
	cta?: string
}
export interface SignInNoAccount {
	action: string
}
export interface SignUpCompanySignUp {
	action: string
}
export interface SignUpNeedAnInvite {
	action: string
	isMultiEntity?: boolean
}
export interface SignupActioned {
	/**
	 * States the action which was performed when the events were triggered
	 */
	action: string
	/**
	 * The flow from which the event was triggered
	 */
	flow?: string
}
export interface SignupBillingCompleted {
	/**
	 * false if an error happens when creating virtual cards and linking them for billing
	 */
	created_card?: boolean
}
export interface SignupCompanyCompleted {
	/**
	 * Abbreviation indicating which country the company is from
	 */
	country_code?: string
}
export interface SignupCountryUpdated {
	/**
	 * Abbreviation indicating which country the company is from
	 */
	country_code?: string
}
export interface SignupFunnelSignalEvent {
	/**
	 * Gtag ad_personalization consent
	 */
	ad_personalization?: string
	/**
	 * Gtag ad_user_data consent
	 */
	ad_storage?: string
	/**
	 * Gtag ad_user_data consent
	 */
	ad_user_data?: string
	/**
	 * Gtag analytics_storage consent
	 */
	analytics_storage?: string
	/**
	 * The size of the company selected during the signup flow
	 */
	company_size?: string
	conversion_flow?: string
	/**
	 * The inferred user's country or the country of the company if the user reached the company search step
	 */
	country?: string
	/**
	 * Currency of the conversion value attributed to the user
	 */
	currency?: string
	/**
	 * The user's email entered during the signup flow
	 */
	email?: string
	/**
	 * Meta ad fbclid
	 */
	fbc?: string
	/**
	 * Meta pixel unique identifier
	 */
	fbp?: string
	/**
	 * The user's first name entered during the signup flow
	 */
	first_name?: string
	/**
	 * The user's first and last name entered during the signup flow
	 */
	full_name?: string
	/**
	 * Google click identifier
	 */
	gclid?: string
	google_optimise?: boolean
	/**
	 * Whether or not the email domain is considered generic based on Pleo's definition
	 */
	is_generic_email?: boolean
	/**
	 * The user's last name entered during the signup flow
	 */
	last_name?: string
	meta_optimise?: boolean
	/**
	 * Furthest signup flow milestone reached by the user
	 */
	milestone?: string
	optimise?: boolean
	optimise_test1?: boolean
	optimise_test2?: boolean
	optimise_test3?: boolean
	/**
	 * The user's phone number entered during the signup flow
	 */
	phone?: string
	/**
	 * TikTok pixel unique identifier
	 */
	ttcid?: string
	/**
	 * Conversion value attributed to the user
	 */
	value?: number
}
export interface SignupStepOpened {
	/**
	 * 'true' when the step is finished on the moment of the event, 'false' when the step is not finished on the moment of event
	 */
	admin_verification_done?: boolean
	/**
	 * 'true' when the step is visible on the moment of the event, 'false' when the step is not visible on the moment of event
	 */
	admin_verification_visible?: boolean
	/**
	 * 'true' when the step is finished on the moment of the event, 'false' when the step is not finished on the moment of event
	 */
	billing_done?: boolean
	/**
	 * 'true' when the step is visible on the moment of the event, 'false' when the step is not visible on the moment of event
	 */
	billing_visible?: boolean
	/**
	 * 'true' when the step is finished on the moment of the event, 'false' when the step is not finished on the moment of event
	 */
	company_done?: boolean
	/**
	 * 'true' when the step is visible on the moment of the event, 'false' when the step is not visible on the moment of event
	 */
	company_visible?: boolean
	/**
	 * Boolean, 'true' when the user finishes the screen by clicking on the button, 'false' when this event is triggered automatically when it was skipped
	 */
	manual?: boolean
	/**
	 * The name of the finished step
	 */
	step_name?: string
	/**
	 * 'true' when the step is finished on the moment of the event, 'false' when the step is not finished on the moment of event
	 */
	terms_done?: boolean
	/**
	 * 'true' when the step is visible on the moment of the event, 'false' when the step is not visible on the moment of event
	 */
	terms_visible?: boolean
	/**
	 * 'true' when the step is finished on the moment of the event, 'false' when the step is not finished on the moment of event
	 */
	user_done?: boolean
	/**
	 * 'true' when the step is finished on the moment of the event, 'false' when the step is not finished on the moment of event
	 */
	user_info_done?: boolean
	/**
	 * 'true' when the step is visible on the moment of the event, 'false' when the step is not visible on the moment of event
	 */
	user_info_visible?: boolean
	/**
	 * 'true' when the step is finished on the moment of the event, 'false' when the step is not finished on the moment of event
	 */
	user_passcode_done?: boolean
	/**
	 * 'true' when the step is visible on the moment of the event, 'false' when the step is not visible on the moment of event
	 */
	user_passcode_visible?: boolean
	/**
	 * 'true' when the step is finished on the moment of the event, 'false' when the step is not finished on the moment of event
	 */
	user_verification_done?: boolean
	/**
	 * 'true' when the step is visible on the moment of the event, 'false' when the step is not visible on the moment of event
	 */
	user_verification_visible?: boolean
	/**
	 * 'true' when the step is visible on the moment of the event, 'false' when the step is not visible on the moment of event
	 */
	user_visible?: boolean
}
export interface SignupSubmitted {
	/**
	 * The billing card is created
	 */
	created_card?: boolean
}
export interface SignupSucceeded {
	/**
	 * used in revamped signup flow
	 */
	admin_verification_successful?: boolean
	/**
	 * used in revamped signup flow
	 */
	auto_sdd?: boolean
}
export interface SignupSuccessButtonClicked {
	/**
	 * shows which option the user chose, pleo guide or account activation flow
	 */
	button?: string
	/**
	 * The flow from which the event was triggered
	 */
	flow?: string
}
export interface SpendActivation {
	action: string
	company_has_vendor_cards?: boolean
	source: string
}
export interface SpendActivationSetup {
	/**
	 * Type of user action (e.g. viewed)
	 */
	action?: string
	/**
	 * Id of the admin
	 */
	admin_id?: string
	/**
	 * What CTA was clicked (e.g. "start_setup")
	 */
	call_to_action?: string
	company_id?: string
	/**
	 * One of the three components in the flow
	 */
	component: string
	/**
	 * Id of the employee who the admin triggers an email for
	 */
	employee_id_emailed?: string
	org_id?: string
	/**
	 * What step in the flow
	 */
	step?: string
}
export interface SpendActivationTemplates {
	/**
	 * What type of action did the user make, e.g. 'viewed', 'clicked'
	 */
	action?: string
	/**
	 * Which CTA was interacted with, e.g. 'set-up-card'
	 */
	call_to_action?: string
	/**
	 * Which component is being interacted with
	 */
	component?: string
	/**
	 * Does the customer have recurring spend?
	 */
	has_recurring_spend: boolean
	/**
	 * Does the customer have vendor cards?
	 */
	has_vendor_cards?: boolean
	/**
	 * Current route of the user, e.g. /vendor-cards
	 */
	route?: string
	/**
	 * Name of the vendor box that was clicked, e.g. 'Amazon'
	 */
	vendor_selected?: string
	/**
	 * Which vendor box was clicked, 'recommended | other'
	 */
	vendor_type?: string
}
export interface DataItem {
	/**
	 * A named identifier for the value, for example the entity reference
	 */
	key: string
	/**
	 * Humanly readable value
	 */
	label?: string
	/**
	 * The actual value of the identifier and often unique
	 */
	value: any | null
}
export interface SpendGuidelinesActioned {
	/**
	 * A user action
	 */
	action: string
	/**
	 * The source of the action (i.e component)
	 */
	action_source?: string
	/**
	 * Object to hold arbitrary data payload for an event
	 */
	data?: DataItem[]
	/**
	 * The source sub-app of the event
	 */
	source: string
	/**
	 * The current view
	 */
	view: string
}
export interface SpendGuidelinesTestQ1 {
	/**
	 * If collaboration checkbox is selected
	 */
	collaboration?: boolean
	/**
	 * If communication checkbox is selected
	 */
	communication?: boolean
	/**
	 * If insight checkbox is selected
	 */
	insight?: boolean
	/**
	 * If writing checkbox is selected
	 */
	writing?: boolean
}
export interface SpendGuidelinesTestQ2Q4 {
	/**
	 * Selected answer for question 2
	 */
	q2?: string | null
	/**
	 * Selected answer for question 3
	 */
	q3?: string | null
	/**
	 * Interested in testing new feature
	 */
	q4?: boolean
}
export interface DataItem1 {
	/**
	 * A named identifier for the value, for example the entity reference
	 */
	key: string
	/**
	 * The actual value of the identifier and often unique
	 */
	value: any | null
}
export interface SpendGuidelinesViewed {
	/**
	 * Object to hold arbitrary data payload for an event
	 */
	data?: DataItem1[]
	/**
	 * The source sub-app of the event
	 */
	source: string
	/**
	 * The current view
	 */
	view: string
}
export interface SpendingLimitSettingsActioned {
	/**
	 * System-wide company_id
	 */
	company_id?: string
	/**
	 * Limit currency
	 */
	currency?: string
	/**
	 * System-wide employee_id of the employee who had their limit settings actioned
	 */
	employee_id?: string
	/**
	 * is true, if the spending limits are updated on the settings/general/invites page. is false, if the spending limits are updated on the specific user /people
	 */
	is_default?: boolean
	/**
	 * MONTHLY, PER_PURCHASE
	 */
	limit_type?: string
	locale?: string
	/**
	 * no_limit, monthly, total
	 */
	new_group?: string
	/**
	 * updated new value
	 */
	new_value?: number
	origin?: string
	path?: string
	/**
	 * no_limit, monthly, total
	 */
	previous_group?: string
	/**
	 * previous limit value
	 */
	previous_value?: number
	/**
	 * spending_group: spending limit, per person (no limit, monthly, total) is updated
spending_value: spending limit, per person value is updated
per_purchase_value : limit per purchase is updated
	 */
	setting?: string
}
export interface SplitExpenseActioned {
	/**
	 * Number of lines the expense have when the action is performed
	 */
	current_line_count?: number | null
	/**
	 * Type of expense where split action is happend
	 */
	expense_type?: string | null
	/**
	 * Field on which the action happened
	 */
	field?: string | null
	/**
	 * Says whether the split is done manual or OCR automated
	 */
	is_split_automated?: boolean | null
}
export interface StartSpendingPageActioned {
	action?: string
	from_page?: string
	status?: string
}
export interface StatementDownloadActioned {
	/**
	 * Whether it was requested, downloaded, or cancelled by the user.
	 */
	action?: string
	/**
	 * ID of the expense
	 */
	expense_id?: string
	/**
	 * Statement type
	 */
	type?: string
}
export interface StatementDownloadError {
	/**
	 * Error message
	 */
	error_message?: string
	/**
	 * Expense ID
	 */
	expense_id?: string
	/**
	 * Statement type
	 */
	type?: string
}
export interface StepCompletedAppcues {
	flowId?: string
	flowName?: string
	flowType?: string
	flowVersion?: number
	stepId?: string
	stepNumber?: number
	stepType?: string
}
export interface ResponseItem1 {
	fieldId?: string
	fieldRequired?: boolean
	fieldType?: any | null
	label?: string
	value?: string
}
export interface Interaction2 {
	destination?: string
	formId?: any | null
	response?: ResponseItem1[]
	text?: string
}
export interface StepInteractedAppcues {
	flowId?: string
	flowName?: string
	flowType?: string
	flowVersion?: number
	interaction?: Interaction2
	interactionType?: string
	stepChildId?: string
	stepChildNumber?: number
	stepId?: string
	stepNumber?: number
	stepType?: string
}
export interface StepStartedAppcues {
	flowId?: string
	flowName?: string
	flowType?: string
	flowVersion?: number
	stepChildId?: string
	stepChildNumber?: string
	stepId?: string
	stepNumber?: number
	stepType?: string
}
export interface StoreboxIntegrationActioned {
	/**
	 * Action details
	 */
	action?: string
	/**
	 * Admin or employee storebox integration
	 */
	entity?: string
	/**
	 * Enable or disable
	 */
	flow?: string
}
export interface StoreboxOnboardingActioned {
	/**
	 * Action
	 */
	action: string
	/**
	 * Modal step on which action take place
	 */
	step: string
}
export interface SubWalletCreation {
	action: string
	/**
	 * Is used for Creation Response action
	 */
	action_status?: string
	/**
	 * List of IDs of all assigned cards during creation
	 */
	assigned_card_ids?: any[]
	/**
	 * Current amount on the Main Wallet
	 */
	available_balance?: number
	currency?: string
	/**
	 * Is used for Creation Response action, in case of "half"-success
	 */
	has_attaching_cards_error?: boolean
	/**
	 * Is used for Creation Response action, in case of "half"-success
	 */
	has_moving_funds_error?: boolean
	/**
	 * The source/page from where the event has been triggered
	 */
	source?: string
	sub_wallet_id?: string
	/**
	 * Initial amount of the sub-wallet to create
	 */
	sub_wallet_initial_amount?: number
	/**
	 * Name of the sub-wallet to create
	 */
	sub_wallet_name?: string
	/**
	 * Number of already created active sub-wallets
	 */
	sub_wallets_count?: number
}
export interface SubWalletActioned {
	action: string
	/**
	 * Status of the Sub-wallet's Auto Top-up settings
	 */
	auto_top_up_status?: string
	has_main_wallet_auto_top_up_enabled?: boolean
	sub_wallet_id: string
}
export interface SubscriptionExpenseClicked {
	/**
	 * The company Id of the user
	 */
	company_id?: string
	/**
	 * The subscription Id clicked
	 */
	subscription_id: string
}
export interface SubscriptionExpenseDetailClicked {
	/**
	 * The company Id of the user
	 */
	company_id?: string
	/**
	 * the specific expense Id belonging to this subscription
	 */
	expense_id: string
	/**
	 * The subscription Id for this expense
	 */
	subscription_id: string
}
export interface SubscriptionFilterActioned {
	/**
	 * action can be either applied or cleared
	 */
	action: string
	/**
	 * The company Id of the user
	 */
	company_id?: string
	/**
	 * filters are : time, team or user
	 */
	filter_name: string
	/**
	 * filter value or null if the filter is cleared
	 */
	value: string | null
}
export interface SubscriptionsEmptyStateActioned {
	/**
	 * the action can be either contact_support or full_history_filter
	 */
	action: string
}
export interface SubscriptionsOnboardingBannerActioned {
	/**
	 * User action on banner, this can be either  dismissal or help_article
	 */
	action: string
}
export interface Bill10 {
	/**
	 * Bill invoice with international payment
	 */
	international_payment?: boolean
	/**
	 * Bill invoice status
	 */
	status?: string
}
export interface SupplierDetailsUpdated {
	/**
	 * Bill invoice information
	 */
	bill?: Bill10
	/**
	 * Name of the field that has been changed
	 */
	field: string
}
export interface Bill11 {
	/**
	 * Bill invoice with international payment
	 */
	international_payment?: boolean
	/**
	 * Bill invoice status
	 */
	status?: string
}
export interface SupplierFormActioned {
	/**
	 * The user has interacted with the form
	 */
	action: string
	/**
	 * Bill invoice information
	 */
	bill?: Bill11
	/**
	 * The user confirms or dismisses canceling the supplier form
	 */
	cancel_confirmed?: boolean
	/**
	 * How does the user exit the form
	 */
	cancel_method?: string
}
export interface SwitchAccount {
	/**
	 * Number of active account on the device
	 */
	numberOfActiveAccounts: number
}
export interface SwitchAccountClicked {
	/**
	 * Number of active accounts on the device
	 */
	numberOfActiveAccounts: number
}
export interface SwitchEntity {
	/**
	 * The user's current entity
	 */
	currentViewingEntityId?: string
	/**
	 * The user's desired entity to switch to
	 */
	entityId?: string
	/**
	 * Is the target entity the user's spending entity
	 */
	isSpendingEntity: boolean
	/**
	 * Is the user trying to switch to the same entity or an entity switch is already in progress
	 */
	isSwitchingEnabled: boolean
	/**
	 * The number of active logged in accounts on the the device
	 */
	numberOfActiveAccounts: number
	/**
	 * The number of entities the user has access to
	 */
	numberOfEntities: number
	/**
	 * The number of expenses in the entity a user wants to switch to
	 */
	numberOfExpensesInQueue?: number
	/**
	 * The number of missing receipts in the entity a user want to switch to
	 */
	numberOfMissingReceipts?: number
	/**
	 * Where is the entity switching coming from
	 */
	source: string
	/**
	 * The type of entity switcher card the user is viewing
	 */
	type: string
}
export interface SystemNotCompatibleWithPocket {
	action?: string
	step?: string
	system_name?: string
}
export interface TagBudgetClosedEarly {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Unique identifier of budget
	 */
	budget_id?: string
	/**
	 * New budget end date
	 */
	end_date?: string
	/**
	 * Where the action was triggered
	 */
	source?: string
}
export interface TagBudgetCreationCompleted {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Upper limit of budget
	 */
	budget_amount?: number
	/**
	 * Unique identifier of budget
	 */
	budget_id?: string
	/**
	 * Name of budget
	 */
	budget_name?: string
	/**
	 * Date when budget closes
	 */
	end_date?: string
	/**
	 *  Id’s of reviewers tied to budget
	 */
	reviewer_ids?: any[]
	/**
	 * Date when budget begins
	 */
	start_date?: string
	/**
	 * Unique identifiers of tag group rows
	 */
	tag_ids?: any[]
	/**
	 * Name of tags tied to budget
	 */
	tag_names?: any[]
}
export interface TagBudgetCreationStarted {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Input in which the user entered data first
	 */
	field_name?: string
}
export interface TagBudgetCreationStepBackward {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Name of current Budget creation step
	 */
	step_name?: string
}
export interface TagBudgetCreationStepForward {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Name of current Budget creation step
	 */
	step_name?: string
}
export interface TagBudgetDiscard {
	/**
	 * Action can be either started or completed
	 */
	action?: string
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface TagBudgetEdited {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Upper limit of budget
	 */
	budget_amount?: number
	/**
	 * Unique identifier of budget
	 */
	budget_id?: string
	/**
	 * Name of budget
	 */
	budget_name?: string
	/**
	 * Date when budget closes
	 */
	end_date?: string
	/**
	 *  Id’s of reviewers tied to budget
	 */
	reviewer_ids?: any[]
	/**
	 * Date when budget begins
	 */
	start_date?: string
}
export interface TagBudgetFilterActioned {
	/**
	 * Action can be either applied or cleared
	 */
	action?: string
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Name of the actioned filter
	 */
	filter_name?: string
	/**
	 * Value of the actioned filter
	 */
	filter_value?: any | null
}
export interface TagBudgetOpened {
	/**
	 * User's role
	 */
	actor_role?: string
	/**
	 * Unique identifier of budget
	 */
	budget_id?: string
}
export interface TagBudgetUnarchivedTag {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Unique identifier of budget
	 */
	budget_id?: string
	/**
	 * Unique identifier of tag
	 */
	tag_id?: string
}
export interface TagBudgetViewed {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Unique identifier of budget
	 */
	budget_id?: string
}
export interface TagBudgetsExpenseDownloaded {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Unique identifier of budget
	 */
	budget_id?: string
}
export interface TagBudgetsOverviewViewed {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface TagImportActioned {
	/**
	 * Action performed
	 */
	action?: string | null
	/**
	 * spreadsheet or any ERP system
	 */
	source?: string | null
	/**
	 * ERP system integrated
	 */
	system?: string | null
	/**
	 * import or re-import
	 */
	type?: string | null
}
export interface TagReviewFeatureDismissed {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface TagReviewFeatureRequested {
	/**
	 * Email of actor
	 */
	actor_email?: string
	/**
	 * ID of actor
	 */
	actor_id?: string
	/**
	 * Name of actor
	 */
	actor_name?: string
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Company ID connected to actor
	 */
	company_id?: string
	/**
	 * Company Name connected to actor
	 */
	company_name?: string
}
export interface TagReviewNavigatedToBudgets {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * ID of selected tag
	 */
	tag_id?: string
}
export interface TagReviewNavigatedToExpenses {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * ID of selected tag
	 */
	tag_id?: string
}
export interface TagReviewOnboarding {
	action: string
	source?: string
}
export interface TagReviewRemoveSingleReviewer {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * ID of selected reviewer
	 */
	reviewer_id?: string
	/**
	 * ID of selected tag
	 */
	tag_id?: string
}
export interface TagReviewSettingsViewed {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface TagReviewTagActioned {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * ID's of selected reviewers
	 */
	reviewer_ids?: any[]
	/**
	 * ID’s of selected tags
	 */
	tag_ids?: any[]
}
export interface TagReviewUnassignedReviewerModal {
	/**
	 * Started/Completed
	 */
	action?: string
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * ID of selected reviewer
	 */
	reviewer_id?: string
	/**
	 * ID of selected tag
	 */
	tag_id?: string
}
export interface TagSourceActioned {
	action: string
	/**
	 * ERP system
	 */
	system?: string | null
}
export interface TaxCodeTableUpdated {
	/**
	 * Column name
	 */
	field?: string
	/**
	 * Accounting system name
	 */
	system?: string
}
export interface TeamAddReviewerCompleted {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface TeamAddReviewerStarted {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface TeamFilterCompleted {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface TeamFilterStarted {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface TeamMemberAdded {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Name of the team
	 */
	value?: string
}
export interface TeamMemberRemoved {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Name of the team
	 */
	value?: string
}
export interface TeamPageViewed {
	/**
	 * Role of actor
	 */
	action_role?: string
}
export interface TeamReviewerRemoved {
	/**
	 * Role of actor
	 */
	actor_role?: string
	/**
	 * Name of the team
	 */
	value?: string
}
export interface TeamSearched {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface TemporaryLimitsActioned {
	/**
	 * Action taken by the user
	 */
	action?: string
	/**
	 * Date that temporary limit should start on
	 */
	activation_date?: string | null
	/**
	 * Which company is performing the action
	 */
	company_id?: string | null
	/**
	 * Date that temporary limit should end on
	 */
	deactivation_date?: string | null
	/**
	 * Which user is the action being performed on
	 */
	employee_id?: string | null
	/**
	 * Amount selected for the temporary limit
	 */
	limit_amount?: string
	/**
	 * Currency of the temporary limit
	 */
	limit_currency?: string
	/**
	 * Which limit is being interacted with
	 */
	limit_id?: string
	/**
	 * Type of temporary limit
	 */
	limit_type?: string
}
export interface TemporaryLimitsPopoverViewed {
	/**
	 * Which company does the user belong to that saw the popover
	 */
	company_id?: string
	/**
	 * Which employee was the popover shown to
	 */
	employee_id?: string | null
}
export interface TikTokAdsOptimisationSignal {
	/**
	 * Size of the user company
	 */
	company_size: string
	/**
	 * Currency of the conversion value
	 */
	currency: string
	/**
	 * If user email is generic
	 */
	is_generic_email: boolean
	/**
	 * TikTok conversion id
	 */
	ttcid?: string
	/**
	 * Value given by Pleo to the conversion
	 */
	value: number
}
export interface TodoListActioned {
	/**
	 * Whether the user clicked the button in the filter bar or the large quick filter button
	 */
	source: string
}
export interface ToggleAccountsMenu {
	/**
	 * The variant of the switcher user is viewing
	 */
	variant: string
}
export interface ToggleEntitySwitcher {
	/**
	 * Is this the user's spending entity
	 */
	isSpendingEntity: boolean
	/**
	 * The source of the toggle action
	 */
	source?: string
	/**
	 * State of entity panel
	 */
	state: string
	/**
	 * The type of entity switcher panel served to the user
	 */
	type?: string
	/**
	 * The variant of the switcher user is viewing
	 */
	variant: string
}
export interface TransferDetailsActioned {
	action: string
	amount?: number
	currency?: string
	domestic?: boolean
	error?: string
	suggested_amount?: boolean
	type?: string
}
export interface TreasuryWaitlist {
	liquidity?: boolean
	overview?: boolean
	treasury?: boolean
}
export interface TrialActivationBannerActioned {
	/**
	 * Action performed by the user
	 */
	action?: string
	plan?: string
}
export interface TryCardDoneActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
	 */
	action?: string
	/**
	 * Page/Url name
	 */
	path?: string
}
export interface TurboGeneratorActionsTriggered {
	/**
	 * Name of the actioned CLI event.
	 */
	actionName: string
	/**
	 * Date time of actioned event, ISO_8601.
	 */
	isoDate: string
	/**
	 * Unix timestamp of actioned event.
	 */
	timestamp: number
}
export interface TurboGeneratorFailed {
	/**
	 * Name of the actioned CLI event.
	 */
	actionName: string
	/**
	 * Date time of actioned event, ISO_8601.
	 */
	isoDate: string
	/**
	 * Unix timestamp of actioned event.
	 */
	timestamp: number
}
export interface TurboGeneratorFinished {
	/**
	 * Name of the actioned CLI event.
	 */
	actionName: string
	/**
	 * Date time of actioned event, ISO_8601.
	 */
	isoDate: string
	/**
	 * Unix timestamp of actioned event.
	 */
	timestamp: number
}
export interface TurboGeneratorStarted {
	/**
	 * Name of the actioned CLI event.
	 */
	actionName: string
	/**
	 * Date time of actioned event, ISO_8601.
	 */
	isoDate: string
	/**
	 * Unix timestamp of actioned event.
	 */
	timestamp: number
}
export interface TwofactorReset {
	/**
	 * What is the role of the user performing the action
	 */
	actor_role?: string
}
export interface UboSelfVerificationActioned {
	action: string
}
export interface UsWalletActioned {
	action: string
	amount?: number
	currency?: string
	institutionId?: string
	/**
	 * The name of the screen of the open Modal
	 */
	screenName?: string
}
export interface UafAddMemberCompleted {
	actor_role?: string
	company_id?: string
	n_invites?: number
	n_invites_card?: number
	n_invites_cardless?: number
	n_invites_failed?: number
	n_invites_per_purchase_limit?: number
	n_invites_total_limit?: number
	source?: string
}
export interface Client7 {
	/**
	 * The id of the client.
	 */
	id: string | null
}
export interface UpdatePartnerClientNotificationsChannel {
	/**
	 * The selected notification channel.
	 */
	channel: string | null
	/**
	 * Object containing information about the partner client.
	 */
	client: Client7 | null
}
export interface Client8 {
	/**
	 * The id of the partner client.
	 */
	id: string | null
}
export interface UpdatePartnerClientNotificationsFrequency {
	/**
	 * Object containing information about the partner client.
	 */
	client: Client8 | null
	/**
	 * The selected notification frequency.
	 */
	interval: string | null
}
export interface UpgradeYourPlanViewed {
	company_id?: string
	/**
	 * the page in which the widget is displayed
	 */
	page?: string
}
export interface UploadDocumentActioned {
	action: string
	method?: string
	value: string
}
export interface UsageTypeActioned {
	/**
	 * The action taken
	 */
	action: string
	/**
	 * Whether or not all users are selected
	 */
	is_select_all?: boolean
	/**
	 * The number of invites the action applies to
	 */
	n_invites_applied?: number
	/**
	 * The previous usage type when changing a single record
	 */
	previous_usage_type?: string
	/**
	 * Where this action was taken within the app

	 */
	source?: string
	/**
	 * The access type
	 */
	usage_type?: string
}
export interface UserExpensesStatus {
	/**
	 * Company id
	 */
	company_id?: string
	/**
	 * User id
	 */
	id?: string
	/**
	 * An array of ids of the teams reviewed by the user
	 */
	reviewer?: any[] | null
	/**
	 * Role of the user
	 */
	roles?: string
}
export interface UserFilterCompleted {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface UserFilterStarted {
	/**
	 * Role of actor
	 */
	actor_role?: string
}
export interface VendorCardActioned {
	/**
	 * default accounting account ID value

	 */
	account?: string | null
	/**
	 * The action that triggered the event

	 */
	action: string
	/**
	 * The id of the card

	 */
	card_id?: string
	/**
	 * The limit amount set on the card

	 */
	card_limit_amount?: number
	/**
	 * The company currency

	 */
	card_limit_currency?: string
	/**
	 * The limit type of the card

	 */
	card_limit_type?: string
	/**
	 * The name of the card

	 */
	card_name?: string
	/**
	 * The id of the employee owning the card

	 */
	card_owner?: string
	/**
	 * The id of the company owning the card

	 */
	company_id?: string
	/**
	 * The list of new allowed vendors ids

	 */
	new_allowed_vendor_id?: string[] | null
	/**
	 * The list of new allowed vendors name
	 */
	new_allowed_vendor_name?: string[] | null
	/**
	 * the new accounting default value set, the action will give the accounting type (team, account, tag or tax-code)

	 */
	new_value?: string | null
	/**
	 * The number of dedicated cards owned by an employee

	 */
	number_of_cards?: number
	/**
	 * the list of previous allowed vendors ids

	 */
	previous_allowed_vendor_id?: string[] | null
	/**
	 * The card limit amount before editing

	 */
	previous_card_limit_amount?: number
	/**
	 * The card limit type before editing

	 */
	previous_card_limit_type?: string
	/**
	 * the card owner Id before editing

	 */
	previous_card_owner?: string
	/**
	 * the previous accounting default value set, the action will give the accounting type (team, account, tag or tax-code)

	 */
	previous_value?: string | null
	/**
	 * The source/page from where the event has been triggered

	 */
	source?: string
	/**
	 * Only used for accounting tags to gives the tag group value set

	 */
	sub_category?: string
	/**
	 * the number of default accounting Tags set on the card

	 */
	tags?: number | null
	/**
	 * default accounting team ID value

	 */
	team?: string | null
}
export interface VendorCardActivation {
	/**
	 * The action taken by the user
	 */
	action?: string
	/**
	 * The user's company size
	 */
	company_segment?: string
	/**
	 * Is the company multi-entity
	 */
	is_multi_entity?: string
	/**
	 * The user's role
	 */
	role?: string
	/**
	 * Source of event
	 */
	source?: string
}
export interface VendorCardCalloutActioned {
	/**
	 * The action taken by the user
	 */
	action: string
	/**
	 * The ID of the vendor card
	 */
	cardId: string
	/**
	 * The employee ID of the card owner
	 */
	cardOwnerEmployeeId: string
	/**
	 * Which type of callout is displayed
	 */
	type: string
	/**
	 * The user ID of the admin who sees the callout
	 */
	userId: string
}
export interface VendorCardError {
	/**
	 * the company ID that own the cards

	 */
	company_id: string
	/**
	 * the reason for the error
	 */
	error_name: string
	/**
	 * where the error happened

	 */
	error_type: string
}
export interface VendorCardModalActioned {
	/**
	 * we have 2 types of action : viewed or CTA button clicked

	 */
	action: string
	/**
	 * the company ID that own the cards

	 */
	company_id: string
}
export interface VendorCardPageActioned {
	/**
	 * we have 3 types of actions: all cards viewed when the page load, CTA button to create a card clicked or direct link with...

	 */
	action: string
	/**
	 * the company ID that own the cards

	 */
	company_id: string
	/**
	 * this property will only be added when the events come from the cards list empty page direct link with suggestions

	 */
	for_instance_text?: string
	/**
	 * the number of actual active cards when the page load

	 */
	number_of_active_cards?: number
	/**
	 * the number of actual cards when the page load

	 */
	number_of_cards?: number
	/**
	 * the number of actual forzen cards when the page load

	 */
	number_of_frozen_cards?: number
	/**
	 * source is the actual page name where the list is displayed

	 */
	source?: string
}
export interface VendorCardPageFilterActioned {
	/**
	 * The action that triggered the event
	 */
	action: string
	/**
	 * The company id
	 */
	company_id?: string
	/**
	 * The name of the filter that triggered the event
	 */
	filter_name?: string
	/**
	 * The value of the filter
	 */
	value?: string
}
export interface VendorCardTransactionClicked {
	/**
	 * The id of the card used for the expense

	 */
	card_id?: string
	/**
	 * The type of the card used for the expense

	 */
	card_type?: string
	/**
	 * The id of the expense

	 */
	expense_id: string
	/**
	 * Is the expense locked or not

	 */
	locked?: boolean
	/**
	 * The review status of the expense

	 */
	review_status?: string
	/**
	 * The page/component from where the event was triggered

	 */
	source?: string
	/**
	 * viewer role
	 */
	viewer?: string | null
}
export interface VerificationStepViewed {
	/**
	 * Specific name of the step that was viewed.
	 */
	step?: string
}
export interface VerifyCompanyActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
	 */
	action: string
	/**
	 * User clicks the CTA either on the product card or the milestone card of the Get Started Guide
	 */
	cta?: string
	/**
	 * Boolean, true if documents where uploaded
	 */
	has_document_upload?: boolean
	/**
	 * Boolean, true if a national id was provided
	 */
	has_national_id?: boolean
}
export interface VerifyIdentityActioned {
	/**
	 * States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
	 */
	action?: string
	/**
	 * optional property in case of `action: returnedWithError`
	 */
	error?: string
	/**
	 * Web language
	 */
	locale?: string
	/**
	 * Full URL
	 */
	origin?: string
	/**
	 * Page name (partial URL)
	 */
	path?: string
	/**
	 * The stage of the Product in which the Admin Verifies themselves. We are running a split test for which this property is required. In the control, the admin will verify themselves normally during the signup flow, stage:signup. For the variant they can do it either in the FDD flow, or the Wallet Flow. Stages fdd/wallet respectively
	 */
	stage?: string
	/**
	 * Type for verification provider
	 */
	type?: string
	/**
	 * User type
	 */
	user_type?: string
}
export interface ViewExpenseDetailsActioned {
	/**
	 * Account/system integrated e.g., Xero
	 */
	integration?: string
	/**
	 * Where the event is triggered
	 */
	source?: string
}
export interface VismaEconomicInvoicesTourAccessed {
	company_id?: string
	user_id?: string
}
export interface VismaEconomicMigrationFlow {
	company_id?: string
	/**
	 * Page source of where the action happened (especially desired for the "accessed" type
	 */
	page_path?: string
	/**
	 * Type of action
	 */
	type: string
	user_id?: string
}
export interface VismaEconomicMigrationInstructionsActioned {
	company_id?: string
	user_id?: string
}
export interface WalletActivitiesHistoryActioned {
	action?: string
}
export interface WalletAutoTopupModalViewed {
	company_id?: string
	is_onboarding_visible?: boolean
	user_email?: string
}
export interface WalletAutoTopupOnboardingBannerActioned {
	action?: string
}
export interface WalletBalanceStatementActioned {
	action?: string
}
export interface WalletBalanceStatementModalViewed {
	company_id?: string
	user_email?: string
}
export interface WalletBankTransferModalActioned {
	action?: string
}
export interface WalletDirectDebitSeActioned {
	action?: string
}
export interface WalletDirectDebitTopupModalViewed {
	company_id?: string
	user_email?: string
}
export interface WalletLowBalanceAlertActioned {
	/**
	 * What action the user is making with the low balance alert modal
	 */
	action: string
	/**
	 * When we chose the manual type of alert configuration here we show the changes in balance.
	 */
	amount?: number
	/**
	 * We have two options currently: "Automatically based on company spending patterns" and "When balance falls below" so we can have two options here: "automatic", "manual"
	 */
	type?: string
}
export interface WalletLowBalanceAlertModalViewed {
	company_id?: string
	user_email?: string
}
export interface WalletManualAutoTopUpDirectDebitMandateEditActioned {
	action?: string
}
export interface WalletManualTopUpDirectDebitActioned {
	action?: string
	amount?: number
}
export interface WalletManualTopUpDirectDebitDeleteActioned {
	action?: string
}
export interface WalletOnboardingTopupViewed {
	company_id?: string
	user_email?: string
}
export interface WalletPageViewed {
	/**
	 * The id of the company associated with the admin user.
	 */
	company_id?: string
	user_email?: string
}
export interface WalletSetupAutoTopupMandateDe {
	action?: string
	bank?: string
}
export interface WalletSetupNetsActioned {
	action?: string
	bank?: string
}
export interface WalletTopUpActioned {
	action?: string
	topUpType?: string
}
export interface WalletTopUpHistoryActioned {
	action?: string
}
export interface WalletTopupHistoryModalViewed {
	company_id?: string
	user_email?: string
}
export interface WalletTopupMethodModalViewed {
	company_id?: string
	user_email?: string
}
export interface WalletTurnOnAutoTopupActioned {
	action?: string
	/**
	 * Company is eligible for Pleo Reserve
	 */
	eligible_for_pleo_reserve?: boolean
	enabled?: boolean
	is_direct_debit_enabled?: boolean
	/**
	 * When wallet balance falls below this amount
	 */
	threshold?: number
	/**
	 * Transfer this amount
	 */
	top_up_amount?: number
}
export interface WalletWithdrawalButtonActioned {
	action?: string
	isDisabled?: boolean
}
export interface WalletWithdrawalPageActioned {
	action?: string
	/**
	 * Withdrawal amount
	 */
	amount?: number
	balanceAfterWithdrawal?: number
	bankCurrency?: string
	/**
	 * Parameter for [Complete] event
	 */
	isSuccessful?: boolean
	/**
	 * For [Show] event to understand which step of form is visible now
	 */
	screenName?: string
	/**
	 * Name of the component that triggered some event
	 */
	triggeredBy?: string
	walletCurrency?: string
}
export interface WelcomePageViewed {
	/**
	 * Web language
	 */
	locale?: string
	/**
	 * Full URL
	 */
	origin?: string
	/**
	 * Page name (partial URL)
	 */
	path?: string
}
export interface XeroAccountingModuleActioned {
	/**
	 * Which button user clicked on
	 */
	action?: string
}
export interface XeroCategoriesBannerActioned {
	action: string
}
export interface XeroCreateTagGroupActioned {
	action: string
}
export interface XeroOnboardingModuleViewed {
	/**
	 * Action the user took on the modal
	 */
	action?: string
	/**
	 * Source of the accounting system setup
	 */
	source?: string
}
export interface XeroSetupPageActioned {
	/**
	 * Whether the Accounting Module was on the page when the tab clicked
	 */
	accountingModuleActive?: boolean
	/**
	 * Which page the user navigated to
	 */
	page: string
}
export interface XeroTagsBannerActioned {
	action: string
}
export interface XeroTagsPageActioned {
	action: string
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: any[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.4.1',
			},
		},
	}
}

/**
 * @typedef AidrActioned
 * @property {string} [action] -
 * @property {number} [amount] -
 * @property {string} [currency] -
 * @property {string} [failure_reason] -
 * @property {string | null} [reimbursement_id] -
 * @property {string | null} [reimbursement_target] -
 */
/**
 * @typedef AisLinkedAccountActioned
 * @property {string} action -
 * @property {string} [institutionId] - The ID of the institution (bank) which a user is working with
 */
/**
 * @typedef AccountSwitchModalActioned
 * @property {string} [action] - When a user interacts with the account switch modal, there are steps taken before a user successfully switches an account
 * @property {string} [actor_role] - What is the role of the user performing the action
 * @property {string} [status] - Was the action performed started, completed, or cancelled
 */
/**
 * @typedef AccountSwitchModalViewed
 * @property {string} [actor_role] - What is the role of the user
 * @property {string} [view] - When a user is switching accounts we track what step of the switch account process they view
 */
/**
 * @typedef AccountSwitchToggleActioned
 * @property {string} [actor_role] - What is the role of the user performing the action
 */
/**
 * @typedef Requested
 * @property {string} companyId - The company id of the employee who is being manually added or removed as a reviewer
 * @property {string} employeeId - The employee id of the employee who is being manually added or removed as a reviewer
 * @property {string} userId - The user id of the employee who is being manually added or removed as a reviewer
 */
/**
 * @typedef Requestor
 * @property {string} companyId - The company id of the employee who is manually adding or removing reviewers
 * @property {string} employeeId - The employee id of the employee who is adding or removing reviewers
 * @property {string} userId - The user id of the employee who is manually adding or removing reviewers
 */
/**
 * @typedef AccountingEntryReviewersManuallyAddedOrRemoved
 * @property {string} accountingEntryId - The accounting entry id of the expense that is being edited
 * @property {string} action - The action being performed by the employee
 * @property {Requested} requested -
 * @property {Requestor} requestor -
 */
/**
 * @typedef AccountingSetupCompleted
 * @property {string} [step] - Indicated the step (ACCOUNTING, CATEGORIES, TAGS)
 */
/**
 * @typedef AccountingSetupStepViewed
 * @property {string} [step] - Name of the specific step which the user is viewing (e.g. 'ACCOUNTING', 'CATEGORIES', 'TAGS', 'FINAL')
 */
/**
 * @typedef AccountingSystemClicked
 * @property {string} [company_id] - User company id
 * @property {string} [event_timestamp] - Time of event
 * @property {string} [search_string] - Search query string
 * @property {string} [system_name] - Name of selected accounting system
 * @property {string} [user_id] - User Id
 */
/**
 * @typedef AccountingSystemConnected
 * @property {string} [type] - The type of accounting system connected
 */
/**
 * @typedef AccountingSystemDisconnected
 * @property {string} [type] - Type of the accounting system connected
 */
/**
 * @typedef AccountingSystemUpdated
 * @property {boolean} [contra_account_no] - Is true if the user has filled in a contra account number
 * @property {string} [system] - Name of the accounting system that's been chosen or updated
 * @property {boolean} [tax_code] - Is true if the user has filled in a tax code
 */
/**
 * @typedef ActiveOverdraftActioned
 * @property {string} action -
 */
/**
 * @typedef ActivityTabActioned
 * @property {string} [action] - User visited activity tab in the expense tab of the export page
 * @property {string} [company_id] - used to determine which company performed the action
 * @property {number | null} [export_page_version] -
 * @property {string} [role] - used to determine if admins or bookkeepers are more likely to change the settings
 */
/**
 * @typedef AddAccountClicked
 * @property {string} context - Where the event was triggered from
 * @property {number} numberOfActiveAccounts - Number of active accounts on the device
 */
/**
 * @typedef AddEntity
 * @property {string} actor - The role of the user
 * @property {string} [connectedPeopleAppCategory] - The category a connected people app
 * @property {string} [connectedPeopleAppId] - The ID of the connected people app
 * @property {string} [connectedPeopleAppName] - The name of the people app
 * @property {string} [connectedPeopleAppType] - The type of a connected people app
 * @property {string} screen - The name of screen where this action lives
 */
/**
 * @typedef AddExpensesActioned
 * @property {string} [action] - States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
 * @property {string} [field] - Element that triggered the action
 * @property {boolean} [is_manual] - States if a user has manually entered a field rather than using the autocomplete
 * @property {any | null} [previous_value] - Previous value of the field updated by a user
 * @property {string} [type] - Type of expense actioned: "purchase", "mileage", "bill, perdiem"
 * @property {any | null} [value] - Field value added by a user
 */
/**
 * @typedef AddFundsActioned
 * @property {string} action -
 * @property {string} [cta] - User clicks the CTA either on the product card or the milestone card of the Get Started Guide
 * @property {string} [type] -
 */
/**
 * @typedef AddFundsAmountActioned
 * @property {string} [action] -
 * @property {number} [amount] -
 * @property {string} [currency] -
 * @property {boolean} [suggested amount] -
 */
/**
 * @typedef AddMemberActioned
 * @property {string} action - The specific action
 * @property {string} [n_invites] - The number of email addresses added
 * @property {string} source - Where this action was taken within the app
 */
/**
 * @typedef AddMemberAddManyActioned
 * @property {string} [actor_role] - What is the role of the user performing the action
 * @property {number} [n_invites_card] - Number of cardAccess members invites
 * @property {number} [n_invites_cardless] - Number of cardless members invites
 */
/**
 * @typedef AddMemberAddManyCompleted
 * @property {string} [actor_role] - What is the role of the user performing the action
 * @property {number} [n_invites_card] - Number of cardAccess members invites
 * @property {number} [n_invites_cardless] - Number of cardless members invites
 */
/**
 * @typedef AddMemberCompleted
 * @property {string} [actor_role] - What is the role of the user performing the action
 * @property {number} [n_invites_card] - Number of cardAccess members invited
 * @property {number} [n_invites_cardless] - Number of cardless members invited
 * @property {number} [no_of_existing_users] - Number of members which could not be invited because the email is already in use in a different company
 * @property {number} [no_of_users] - Number of members invited
 * @property {string} [type] - Type of user invite (e.g. member, admin, external-bookkeeper)
 */
/**
 * @typedef AddMemberStarted
 * @property {string} [actor_role] - What is the role of the user performing the action
 */
/**
 * @typedef AddMemberWarningViewed
 * @property {string} [name] - The warning message identifier
 * @property {string} [source] - Where the message was seen
 */
/**
 * @typedef AddPerDiemActioned
 * @property {string} action - States the action which was performed when the events were triggered
 * @property {number} [amount] - The amount of the expense
 * @property {string} [button] - The button that triggered the event
 * @property {string} [currency] - The currency of the expense
 * @property {string} [expense_id] - The id of the expense for which the event was triggered
 * @property {string} [field] - The form field that triggered the action
 * @property {number} [n_destination] - The number of destination/stop that triggered the event
 * @property {number} [n_tag] - The number of tag that triggered the event
 * @property {string} [type] - Metadata related to the action trigger. Can be the type of field, or action, or trigger, etc.
 * @property {any | null} [value] - Field value added by a user
 */
/**
 * @typedef Edits
 * @property {string} [access] - The expense access type
 */
/**
 * @typedef AdminRequestApprovalActioned
 * @property {string} [action] - The action taken by the user
 * @property {string} [active_for] - For what period will the requested limit be active
 * @property {any[]} [categories] - The spend categories sent
 * @property {string} [currency] - User's limit currency
 * @property {number} [current_value] - Current limit the user is requesting to change
 * @property {Edits} [edits] - Details of changes when request is edited
 * @property {string} [lead] - Source of taking admin to flow
 * @property {number} [n_member_requests] - Number of pending requests or requests actioned
 * @property {string} [new_member_email] - Email address on the request
 * @property {number} [new_value] - New limit value the user is requesting
 * @property {string} [reason_text] - Reason for declined_request
 * @property {any[]} [request_ids] - The request Ids
 * @property {any[]} [source] - Source of every request
 * @property {string} [type] - The type of request
 * @property {boolean} [upgrade_needed] - Whether approving the request require a plan change
 */
/**
 * @typedef AdminRequestApprovalDeclines
 * @property {string} [action] - The action taken by the user
 * @property {string} [new_member_email] - Email address on the request
 * @property {string} [reason_list] - The predefined reason selected
 * @property {string} [reason_text] - Supplied when reason_list is 'other'
 * @property {string} [request_id] - The ID of the request
 * @property {string} [source] - The source of the request
 */
/**
 * @typedef AdoptionEssentialsCentreActioned
 * @property {string} action -
 * @property {string} [guide] -
 * @property {string} [guide_task] -
 * @property {string} [page] -
 * @property {string} [target_url] -
 */
/**
 * @typedef AdoptionEssentialsFeedbackSubmitted
 * @property {string} guide -
 * @property {string} helpful -
 * @property {string} [option] -
 * @property {string} [other_text] -
 */
/**
 * @typedef AdoptionEssentialsPromptActioned
 * @property {string} action -
 * @property {string} guide -
 */
/**
 * @typedef AdoptionPrompt
 * @property {string} action - Adoption prompt viewed
 */
/**
 * @typedef AdoptionPromptActioned
 * @property {string} [action] - Prompt action
 * @property {string} [prompt_name] - Unique name of the prompt
 */
/**
 * @typedef AdvancedSpendControlsActioned
 * @property {string} [action] - The action taken by the user
 * @property {string} [company_id] - company id of the user
 * @property {any[]} [new_value] -
 * @property {any[]} [previous_value] -
 * @property {string} [source] - Advanced spend control level (company/employee)
 * @property {string} [type] - Type of advanced spend control
 * @property {string} [user_id] - User id
 */
/**
 * @typedef AllExpensesInExportActioned
 * @property {string} [action] - User has visited the "All expenses" page for pocket or for wallet
 * @property {string} [company_id] - Which company is performing the action
 * @property {number | null} [export_page_version] -
 * @property {string} [role] - used to determine if admins or bookkeepers are more likely to change the settings
 */
/**
 * @typedef AmlQuestionActioned
 * @property {string} action -
 * @property {string} type -
 */
/**
 * @typedef AmlSectionActioned
 * @property {string} action -
 */
/**
 * @typedef AnalyticsPageActioned
 * @property {string} action - type of action
 * @property {string} company_id - company id of the user making the action
 * @property {string} filter_name - the filter being actioned
 * @property {string} user_role - employee role filtering the analytic dashboard
 */
/**
 * @typedef ApplyFilter
 * @property {string} actor - The role of the user
 * @property {string} [connectedPeopleAppCategory] - The category a connected people app
 * @property {string} [connectedPeopleAppId] - The ID of the connected people app
 * @property {string} [connectedPeopleAppName] - The name of a connected people app
 * @property {string} [connectedPeopleAppType] - The type of a connected people app
 * @property {string} screen - The name of the screen using this filter
 * @property {string} type - The type of the filter
 */
/**
 * @typedef ApplySort
 * @property {string} actor - The user role applying the sort
 * @property {string} [connectedPeopleAppCategory] - The category a connected people app
 * @property {string} [connectedPeopleAppId] - The ID of the connected people app
 * @property {string} [connectedPeopleAppName] - The name of the connected people app
 * @property {string} [connectedPeopleAppType] - The type of a connected people app
 * @property {string} screen - The name of the screen using this sort
 * @property {string} type - The type of sort
 */
/**
 * @typedef ArchiveTeamCompleted
 * @property {string} [actor_role] - Role of actor
 * @property {string} [value] - Name of the archived team
 */
/**
 * @typedef AutoTopUp
 * @property {string} action -
 * @property {number} [amount] -
 * @property {string} auto_top_up_flow -
 * @property {string} [frequency] -
 * @property {number} [prev_amount] -
 * @property {string} [prev_frequency] -
 * @property {number} [prev_threshold] -
 * @property {string} [prev_type] - Previous Auto Top-up type
 * @property {string} [sub_wallet_id] - Is used, when the auto_top_up_flow is equal to SUB_WALLET
 * @property {number} [threshold] -
 * @property {string} [type] - Auto Top-up type
 */
/**
 * @typedef AutoTopupAccordionItemActioned
 * @property {string} action -
 */
/**
 * @typedef AutoTopupSettingsFormChanged
 * @property {string} action -
 * @property {boolean} [creating_agreement] -
 * @property {boolean} [eligible_for_pleo_reserve] -
 * @property {boolean} [new_value_boolean] -
 * @property {number} [new_value_number] -
 */
/**
 * @typedef AutoTopupSettingsFormValidationViewed
 * @property {string} action -
 * @property {boolean} [creating_agreement] -
 * @property {boolean} [eligible_for_pleo_reserve] -
 * @property {boolean} [input_touched] - Whether user sees this validation with/out touching any input field
 */
/**
 * @typedef AutoTopupSettingsSaveChangesButtonClicked
 * @property {number} [auto_top_up_amount] -
 * @property {boolean} [auto_top_up_enabled] -
 * @property {number} [auto_top_up_threshold] -
 * @property {boolean} [eligible_for_pleo_reserve] -
 * @property {boolean} [pleo_reserve_enabled] -
 */
/**
 * @typedef AutoVatSplitControl
 * @property {string | null} [action] - if the checkbox is enabled or disabled
 */
/**
 * @typedef BackClicked
 * @property {string} [locale] - Web language
 * @property {string} [origin] - Full URL
 * @property {string} [path] - Page name (partial URL)
 * @property {string} [step] - Name of the page where the user clicked "back"
 */
/**
 * @typedef BackToPleoGuideClicked
 * @property {string} [locale] - Web language
 * @property {string} [origin] - Full URL
 * @property {string} [path] - Page name (partial URL)
 * @property {string} [step] - Name of the page where the user clicked "back to Guide"
 */
/**
 * @typedef Bill
 * @property {boolean} [international_payment] - Bill invoice with international payment
 * @property {string} [status] - Bill invoice status
 */
/**
 * @typedef BillDetailsApprovalsButtonClicked
 * @property {string} action - Button action (add or edit approvers)
 * @property {Bill} bill - Bill invoice information
 */
/**
 * @typedef BillDetailsApprovalsEmployeeListChanged
 * @property {string} action - Action related to the employee list change (loaded, filtered, error)
 * @property {number} number_of_employees - Number of employees displayed in the list after the action
 */
/**
 * @typedef Bill1
 * @property {boolean} [international_payment] - Bill invoice with international payment
 * @property {string} [status] - Bill invoice status
 */
/**
 * @typedef BillDetailsButtonActioned
 * @property {string} action - Action that was performed
 * @property {Bill1} bill - Bill invoice information
 * @property {string} button - Button name
 * @property {string} [error] - Descriptive error
 */
/**
 * @typedef Bill2
 * @property {boolean} [international_payment] - Bill invoice with international payment
 * @property {string} [status] - Bill invoice status
 */
/**
 * @typedef BillDetailsDatePickerActioned
 * @property {string} action - Specifying whether date picker was applied or dismissed
 * @property {Bill2} bill - Bill invoice information
 * @property {string} picker - Date picker name
 */
/**
 * @typedef Bill3
 * @property {boolean} [international_payment] - Bill invoice with international payment
 * @property {string} [status] - Bill invoice status
 */
/**
 * @typedef BillDetailsEntitlementMissingActioned
 * @property {string} action -
 * @property {Bill3} bill - Bill invoice information
 */
/**
 * @typedef ApproverChanges
 * @property {any[]} [addedApprovers] - approvers added
 * @property {any[]} [initialApprovers] - initial approvers before changes
 * @property {any[]} [removedApprovers] - approvers removed
 */
/**
 * @typedef Bill4
 * @property {boolean} [international_payment] - Bill invoice with international payment
 * @property {string} [status] - Bill invoice status
 */
/**
 * @typedef BillDetailsModalActioned
 * @property {string} action - Action that was performed (confirm or dismiss)
 * @property {ApproverChanges} [approverChanges] - changes to approvers
 * @property {Bill4} bill - Bill invoice information
 * @property {string} modal - Modal name
 */
/**
 * @typedef Bill5
 * @property {boolean} [international_payment] - Bill invoice with international payment
 * @property {string} [status] - Bill invoice status
 */
/**
 * @typedef BillDetailsNavigationActioned
 * @property {string} action - Name of the action performed
 * @property {Bill5} bill - Bill invoice information
 * @property {string} destination - Destination of the navigation
 */
/**
 * @typedef Bill6
 * @property {boolean} [international_payment] - Bill invoice with international payment
 * @property {string} [status] - Bill invoice status
 */
/**
 * @typedef BillDetailsOptionsMenuActioned
 * @property {string} action - Either open_menu (when options menu was opened) or name of the button if one of the menu buttons was clicked
 * @property {Bill6} bill - Bill invoice information
 */
/**
 * @typedef Bill7
 * @property {boolean} [international_payment] - Bill invoice with international payment
 * @property {string} [status] - Bill invoice status
 */
/**
 * @typedef BillDetailsSplitActioned
 * @property {string} action - Action that was performed (click on split, undo, edit amount)
 * @property {Bill7} bill - Bill invoice information
 */
/**
 * @typedef Bill8
 * @property {boolean} [international_payment] - Bill invoice with international payment
 * @property {string} [status] - Bill invoice status
 */
/**
 * @typedef BillDetailsUpdated
 * @property {Bill8} bill - Bill invoice information
 * @property {string} field - Name of the updated field
 * @property {string} section - Section of the Bill details form (payment/export)
 * @property {string} [supplier_match_status] - Status of the supplier match field (only for supplier updates)
 */
/**
 * @typedef Bill9
 * @property {string} [status] - Bill invoice status
 */
/**
 * @typedef BillDetailsWarningBannerInformation
 * @property {string} action - Action that was performed
 * @property {string} banner_type - Type of banner message
 * @property {Bill9} bill - Bill invoice information
 */
/**
 * @typedef BillsDueDateSuggestionActioned
 * @property {string} action - Action taken by the user related to the Due date Suggestions
 * @property {string} [selection] - The user selection from the suggestions (including dismissing suggestions)
 */
/**
 * @typedef BillsEmptyStateActioned
 * @property {string} [action] -
 * @property {string} [source] -
 */
/**
 * @typedef BillsFreePaymentBalance
 * @property {string} action - The action or interaction taken by the user. Note that the action could be a passive action like "Viewed"
 * @property {number} [free_payments_left] - Number of free payment remaining in the current period
 * @property {string} [source] - User can click on the pricing link from different places. The source will inform where the click happened
 */
/**
 * @typedef BillsInboxBulkActions
 * @property {string} [action] -
 * @property {any[]} [bills] -
 */
/**
 * @typedef BillsLearnMoreActioned
 * @property {string} [action] -
 * @property {string} [step] -
 */
/**
 * @typedef BillsOnboardingBannerActioned
 * @property {string} action - Action from the banner. At this stage possible values are "viewed", "dismissed" or "clicked"
 * @property {string} [clicked] - If action is "clicked", this indicate the UI element clicked on the banner
 * @property {number} [step_order] - The user have 3 steps to go through, this tracking will indicate in which order he took them
 */
/**
 * @typedef BillsOnboardingDatevSetup
 * @property {string} action - Action taken by the user. Can be "viewed", "dismissed", "clicked"
 * @property {string} [clicked] - If action is clicked, this indicate the UI element clicked in the current step
 * @property {string} [status] - Indicate the status of the DATEV setup, it can be "Checked" (Completed) or "Unchecked" (Not completed)
 * @property {string} step - The current step in the DATEV modal flow
 */
/**
 * @typedef BillsOnboardingEmailSetup
 * @property {string} action - Action taken by the user. Can be "viewed", "dismissed", "clicked" or "starter"
 * @property {string} [clicked] - If action is clicked, this indicate the UI element clicked in the current step
 * @property {string} [status] - Indicate the status of the email setup, it can be "updated", "completed" (not customised), or "anbandoned"
 * @property {string} step - The current step in the email setup flow
 */
/**
 * @typedef BillsOnboardingModalActioned
 * @property {string} action - Action taken by the user. Can be "viewed", "dismissed", or "clicked". In the video player context, it will log the actions related to the video player (video_played, video_paused, video_ended)
 * @property {string} [clicked] - If action is clicked, this indicate the UI element clicked in the current step
 * @property {string} step - The current step in the onboarding flow
 * @property {number} [video-percent-watched] - Specific to the wistia video player. It indicates the percentage of the video viewed by the user.
 * @property {number} [video_duration] - Specific to the wistia video player. It indicates the video's total duration in seconds.
 * @property {number} [video_seconds_watched] - Specific to the wistia video player. It indicates the number of seconds of the video viewed by the user.
 * @property {number} [video_timestamp] - Specific to the wistia video player. It indicates the timestamp location in seconds.
 */
/**
 * @typedef BillsOnboardingQuestionnaireUpdated
 * @property {string} field - Name of the field
 */
/**
 * @typedef BillsPermission
 * @property {string} action - Action(s) taken by the user in the permission flow (Invited, Accepted, Declined, Requested, Granted, Rejected)
 * @property {string} initiator - The role of the initiator of the permission flow
 */
/**
 * @typedef BillsUploadActioned
 * @property {string} action - Action that was performed
 * @property {string} [action_source] - The source (CTA) of the upload action taken by the user
 * @property {boolean} [is_user_first_upload] - Is first time a user uploads an invoice
 * @property {number} [number_of_files] - Number of files selected or dropped
 */
/**
 * @typedef BulkAction
 * @property {string} actor - The role of the user
 * @property {string} [connectedPeopleAppCategory] - The category a connected people app
 * @property {string} [connectedPeopleAppId] - The ID of the people app
 * @property {string} [connectedPeopleAppName] - The name of the connected people app
 * @property {string} [connectedPeopleAppType] - The type of a connected people app
 * @property {string} name - The name of the action
 * @property {string} screen - The name of the screen with the action
 */
/**
 * @typedef BulkEditActionedInExport
 * @property {string} [action] - User clicks bulk edit after selecting multiple items and changes category
 * @property {string} [company_id] - Which company is committing an action
 * @property {number | null} [export_page_version] -
 * @property {string | null} [new_value] - Value the user updated in the bulk edit
 * @property {string} [role] - used to determine if admins or bookkeepers are more likely to change the settings
 */
/**
 * @typedef BulkEditApplied
 * @property {number | null} [export_page_version] -
 * @property {number} [number_of_items] - number of expenses in the selection
 */
/**
 * @typedef BulkEditOpened
 * @property {number | null} [export_page_version] -
 * @property {number | null} [number_of_items] -
 */
/**
 * @typedef BulkReviewCompleted
 * @property {string} [actor_role] - Role of the user who engaged with the review (e.g.  "bookkeeper", "owner")
 * @property {number} [expenses] - Number of selected expenses
 * @property {string} [filtered_by] - Filters applied to the list of expenses
 * @property {string} [review_status] - Status that the reviewer marked the expense at (e.g. WAITING_FOR_EXPENSE_OWNER, OK, NOT_OK, WAITING_FOR_REVIEWER)
 */
/**
 * @typedef CacpStepActioned
 * @property {string} action - The action that happened when the CACP step was actioned
 */
/**
 * @typedef CaptchaTest
 * @property {string} [action] - action name helping to understand where it was fired
 * @property {number} [result_score] - shows the result score
 * @property {boolean} [result_success] - shows if the result was successful
 */
/**
 * @typedef CardDefaultSettingsActioned
 * @property {string} [action] - Specific editing action which triggered the event
 * @property {string} [company_id] - Which company is committing an action
 * @property {string} [currency] - Currency
 * @property {any | null} [new_value] - New value
 * @property {any | null} [previous_value] - Previous value
 */
/**
 * @typedef CardLimitPermissionsToggled
 * @property {string} companyId -
 * @property {boolean} isEnabled - enabled === true means the card limit permissions is set to "REVIEWERS_AND_ADMINS" meaning both reviewers and admins can set card limits. On the other hand, only admins can change card limits when "ADMINS" is set
 * @property {string} [organizationId] -
 * @property {string} userId -
 */
/**
 * @typedef CardReadyPopoverActioned
 * @property {string} action -
 */
/**
 * @typedef CardComparisonActioned
 * @property {string} [action] - What action was taken on the modal
 * @property {string} [source] - From where was the card comparison modal triggered
 */
/**
 * @typedef CardsPageActioned
 * @property {string} [action] - States the action which was performed when the events with the suffix "Actioned"
 * @property {boolean} [card_access] - True if the user has card access
 * @property {string} [input] - Element that triggered the action
 */
/**
 * @typedef CashbackModal
 * @property {string} [action] -
 */
/**
 * @typedef CategoriesLimitationActioned
 * @property {string} action - Action performed
 * @property {any[]} [team_names] - Team Names added
 */
/**
 * @typedef CategoryCreated
 * @property {string} [category_name] - Name of the category
 */
/**
 * @typedef CategoryGroupActioned
 * @property {string} action - Action performed by user
 */
/**
 * @typedef CategoryGroupExportActioned
 * @property {string} type - Export action type
 * @property {string} view - Location where action is taken
 */
/**
 * @typedef CategoryGroupImportActioned
 * @property {string} view - Location where action is taken
 */
/**
 * @typedef CategoryGroupImportModalActioned
 * @property {string} [method] - Method used to upload a file
 * @property {string} step - File upload steps
 * @property {string} view - Location where action is taken
 */
/**
 * @typedef CategoryGroupImportModalViewed
 * @property {string} view - Location where action is taken
 */
/**
 * @typedef CategoryGroupTypeActioned
 * @property {string} action - Action performed on category group type
 * @property {string} [type] - Category group type selected
 */
/**
 * @typedef CategoryImportExportButtonActioned
 * @property {string} view - Location where action is taken
 */
/**
 * @typedef CategoryPrefillActioned
 * @property {string} [action] - Started, completed, aborted, dismissed
 * @property {string} [chartOfAccounts] - SKR 03 or SKR 04
 * @property {string} [field] - Callout or Onboarding modal
 */
/**
 * @typedef ChangeOverdraftLimitActioned
 * @property {string} action -
 * @property {string} [changeDirection] -
 */
/**
 * @typedef CheckProofOfFundsBankAccounts
 * @property {string} [action] -
 * @property {boolean} [hasBanksWithDetails] -
 * @property {number} [missedInfoBanksCount] - Number of bank accounts with missed info (IBAN & BIC)
 */
/**
 * @typedef ChoosePlanModalActioned
 * @property {string} action - The action taken by the user
 * @property {number} [commitment] - The length of the commitment in months (1 or 12)
 * @property {boolean} [fromLegacyPlan] - Whether the company is updating from a legacy plan or not
 * @property {string} [interval] - The billing interval (paid monthly or yearly)
 * @property {string} [origin] - Place within the app where the modal originated (opened)
 * @property {string} [plan] - The type of plan
 * @property {string} [previousPlan] - The plan the company is on before upgrading
 * @property {string} [section] - Section of the page where the modal was opened from
 * @property {string} [step] - Step for the new plan update modal
 * @property {boolean} [sufficientBalance] - Whether there is enough balance in the wallet to cover the new plan
 * @property {string} [userEmail] - userEmail for better Hubspot support
 */
/**
 * @typedef ClearFilterClicked
 * @property {string} [company_id] -
 * @property {string} page - the page in which the filter are cleared
 */
/**
 * @typedef CompanyAddReviewerCompleted
 * @property {string} [actor_role] - Role of actor
 * @property {string} [company_id] - ID of the company having their review settings updated
 * @property {string} [user_id] - Unique identifier of the user that was added
 */
/**
 * @typedef CompanyAddReviewerStarted
 * @property {string} [actor_role] - Role of actor
 * @property {string} [company_id] - ID of the company having their review settings updated
 */
/**
 * @typedef CompanyCountrySelected
 * @property {string} [company_country] -
 * @property {string} [flow] - The flow from which the event was triggered
 * @property {boolean} [isRecommended] -
 */
/**
 * @typedef CompanyInfoViewed
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef CompanyLanguageChanged
 * @property {string} previous_value -
 * @property {string} value -
 */
/**
 * @typedef CompanyOnboardingBookkeepingChoiceActioned
 * @property {string} [choice] -
 */
/**
 * @typedef CompanyOnboardingFooterCardActioned
 * @property {string} [card] -
 */
/**
 * @typedef CompanyOnboardingMilestoneActioned
 * @property {string} [action] -
 * @property {string} [milestone] -
 * @property {string} [task] -
 */
/**
 * @typedef CompanyOnboardingNextStepsModalActioned
 * @property {string} [cta] -
 */
/**
 * @typedef CompanyReviewDisabledCompleted
 * @property {string} [actor_role] - Role of actor
 * @property {string} [company_id] - ID of the company having their review settings updated
 */
/**
 * @typedef CompanyReviewDisabledStarted
 * @property {string} [actor_role] - Role of actor
 * @property {string} [company_id] - ID of the company having their review settings updated
 */
/**
 * @typedef CompanyReviewEnabled
 * @property {string} [actor_role] - Role of actor
 * @property {string} [company_id] - ID of the company having their review settings updated
 */
/**
 * @typedef CompanyReviewOnboardingModalActioned
 * @property {string} action - Action taken by the user.
 * @property {number} [step] - The current step in the onboarding flow
 */
/**
 * @typedef CompanyReviewPageViewed
 * @property {string} [actor_role] - Role of actor
 * @property {string} [company_id] - ID of the company having their review settings updated
 */
/**
 * @typedef CompanyReviewThresholdUpdated
 * @property {string} [actor_role] - Role of actor
 * @property {string} [company_id] - ID of the company having their review settings updated
 * @property {number | null} [previous_value] - Previous threshold value
 * @property {string} [threshold_type] - Threshold range type
 * @property {number | null} [value] - Threshold value
 */
/**
 * @typedef CompanyReviewerRemoved
 * @property {string} [actor_role] - Role of actor
 * @property {string} [company_id] - ID of the company having their review settings updated
 * @property {string} [user_id] - Unique identifier of the user that was removed
 */
/**
 * @typedef CompanyVerifyCompleted
 * @property {string} [step] - Indicates the step that was completed (Address, ManagementVerify, AMLQuestions)
 */
/**
 * @typedef ConfirmTransferCheckboxActioned
 * @property {string} [action] -
 * @property {string} [type] -
 */
/**
 * @typedef ConnectApp
 * @property {string} actor - The user role
 * @property {string} [applicationId] - The ID of the people app
 * @property {string} category - The category of the app
 * @property {string} name - The name of the app
 * @property {string} [status] - The status of the app connection
 * @property {string} type - The type of the app
 */
/**
 * @typedef CreateAccountActioned
 * @property {string} [action] - States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
 * @property {string} [locale] - Web language
 * @property {string} [origin] - Full URL
 * @property {string} [path] - Page name (partial URL)
 * @property {string} [signin] - States if the user signed in via google SSO or a manual email entering
 */
/**
 * @typedef CreateAccountUserActioned
 * @property {string} [action] - States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
 * @property {string} [flow] - The flow from which the event was triggered
 * @property {string} [locale] - Web language
 * @property {string} [origin] - Full URL
 * @property {string} [path] - Page name (partial URL)
 */
/**
 * @typedef CreateAccountVerificationActioned
 * @property {string} [action] - States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
 * @property {boolean} [captcha_passed] - We pass a captcha upon entering number
 * @property {string} [flow] - The flow from which the event was triggered
 * @property {string} [locale] - Web language
 * @property {string} [origin] - Full URL
 * @property {string} [path] - Page name (partial URL)
 * @property {string} [type] - Type of verification performed (sms or authentication app)
 */
/**
 * @typedef CreateBudgetBanner
 * @property {string} action -
 */
/**
 * @typedef CreateCategoryActioned
 * @property {string} [action] - States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
 * @property {string} [locale] - Web language
 * @property {number} [n_categories] - Number of categories added
 * @property {string} [origin] - Full URL
 * @property {string} [path] - Page name (partial URL)
 */
/**
 * @typedef CreateCompanyActioned
 * @property {string} [action] - The action which was performed
 * @property {boolean} [auto_sdd] - True if company passes auto_sdd on creation
 * @property {string} [business_type] - Business type of the company
 * @property {string} [country] - Country of the company
 * @property {string} [flow] - The flow from which the event was triggered
 * @property {string} [locale] - Web language
 * @property {boolean} [manual] - Boolean, true if the company was manually found and completed
 * @property {string} [origin] - Full URL
 * @property {string} [path] - Page name (partial URL)
 * @property {string} [size] - Size of the company
 * @property {string} [trigger] - Describes what triggered the event
 */
/**
 * @typedef CreateCompanyModalActioned
 * @property {string} [action] - Was the modal cancelled or continued by the user
 * @property {string} [actor_role] - What is the role of the user performing the action
 */
/**
 * @typedef CreateCompanyModalViewed
 * @property {string} [actor_role] - What is the role of the user performing the action
 */
/**
 * @typedef CreatePasscodeActioned
 * @property {string} [action] - States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
 * @property {string} [flow] - The flow from which the event was triggered
 * @property {string} [locale] - Web language
 * @property {string} [origin] - Full URL
 * @property {string} [path] - Page name (partial URL)
 */
/**
 * @typedef CreateTagGroupCompleted
 * @property {string | null} [system] - ERP system
 * @property {string} [tag_group_name] - Tag group name
 */
/**
 * @typedef CreateTeamCompleted
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef CreateTeamStarted
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef CustomFormatSettingsActioned
 * @property {string} [delimiter] - The type of delimiter used
 * @property {string} [file_type] - The type of the file expenses will be exported to
 * @property {string} [type] - Single or multi-line format selected
 */
/**
 * @typedef DatevAccountExampleModalActioned
 * @property {string} [account] -
 * @property {string} [action] -
 */
/**
 * @typedef DatevAccountingSetupGuidedTour
 * @property {string} [action] -
 * @property {string} [step] -
 */
/**
 * @typedef DatevOnboardingModuleViewed
 * @property {string} [action] -
 * @property {string} [source] -
 */
/**
 * @typedef DefaultLanguageUpdateCompleted
 * @property {string} [actor_role] - Role of actor
 * @property {string} [previous_value] - Previous default language
 * @property {string} [value] - New default language
 */
/**
 * @typedef DefaultLanguageUpdateStarted
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef DefaultPerPurchaseLimitUpdated
 * @property {string} [actor_role] - Role of actor
 * @property {string | null} [previous_value] - Previous limit value
 * @property {string | null} [value] -  Limit value
 */
/**
 * @typedef DefaultSpendingLimitUpdated
 * @property {string} [actor_role] - Role of actor
 * @property {string | null} [previous_value] - Previous limit value
 * @property {string | null} [type] - Type of limit
 * @property {number | null} [value] - Limit value
 */
/**
 * @typedef DeleteReimbursementModalViewed
 * @property {string} [expense_id] -
 * @property {string} [expense_type] -
 * @property {number | null} [export_page_version] -
 * @property {string} [export_status] -
 * @property {string} [page] -
 * @property {string} [role] -
 */
/**
 * @typedef DemoBudgetActioned
 * @property {string} action - States the action which was performed when the events were triggered
 * @property {string} source - States the name of the element that triggered the action
 */
/**
 * @typedef DeselectAllClicked
 * @property {string} source - Where this action was taken within the app

 * @property {string} step - The step within the user adding wizard
 */
/**
 * @typedef DirectDebitAgreementCreationActioned
 * @property {string} action -
 * @property {string} [action_status] -
 * @property {number} [amount] -
 * @property {string} [back_to_page] -
 * @property {string} [field_name] -
 * @property {string} [field_value] -
 * @property {boolean} [pleo_reserve_enabled] -
 * @property {string} [step_name] -
 * @property {number} [threshold] -
 */
/**
 * @typedef DirectReimbursementEnabled
 * @property {string} company_id -
 * @property {string} source -
 */
/**
 * @typedef DisconnectApp
 * @property {string} actor - The user role
 * @property {string} [applicationId] - The ID of the people app
 * @property {string} category - The category of the app
 * @property {number} [countOfEntitiesInPleo] - The number of entities in Pleo
 * @property {number} [countOfProvisionedUsers] - The number of provisioned users
 * @property {string} name - The name of the app
 * @property {string} screen - The name of the screen the action is performed
 * @property {string} type - The type of the app
 */
/**
 * @typedef DiscoverFeaturesModalActioned
 * @property {string} [action] -
 * @property {boolean} [isOnTrial] -
 * @property {string} [plan] -
 */
/**
 * @typedef DowngradeBlockedModalActioned
 * @property {string} [action] -
 * @property {string} [origin] -
 * @property {string} [section] -
 * @property {string} [variant] -
 */
/**
 * @typedef Download
 * @property {number} [expense_count] - Count of downloaded expenses
 * @property {number | null} [export_page_version] -
 * @property {string} [tab] - Tab that the download was actioned in ("wallet", "pocket", "all")
 * @property {string} [type] - Type of download ("spreadsheet", "receipts", "export")
 */
/**
 * @typedef DownloadAppModalActioned
 * @property {string} action -
 */
/**
 * @typedef DownloadPeopleClicked
 * @property {boolean} hasOrgAccess - Whether user has access to organization
 * @property {string} role - User role
 * @property {string} type - Type of the file to be downloaded
 */
/**
 * @typedef EmployeeCardSpendingLimitReset
 * @property {string} [actor_role] - Role of actor
 * @property {string | null} [previous_value] - Previous limit value
 * @property {string | null} [value] - New limit value
 */
/**
 * @typedef EmployeeCardSpendingLimitUpdated
 * @property {string} [actor_role] - Role of actor
 * @property {string | null} [previous_value] - Previous limit value
 * @property {string | null} [type] - Limit type
 * @property {string | null} [value] - Limit value
 */
/**
 * @typedef EmployeeCodeUpdated
 * @property {string} actor_role - Role of actor
 * @property {string | null} [previous_value] - Previous code assigned to the employee
 * @property {string | null} [value] - New code assigned to the employee
 */
/**
 * @typedef EmployeeDeleted
 * @property {string} [actor_role] - Role of actor
 * @property {string} [dedicated_cards_action] - The selected option for the dedicated cards owned by the employee (reassign cards to a different employee or delete the cards)
 * @property {string} [deleted_employee_id] - The id of the deleted employee
 * @property {string} [reassigned_employee_id] - The id of the employee the dedicated cards have been reassigned to
 */
/**
 * @typedef EmployeeEmailChangeRequested
 * @property {string} actor_role - Role of actor
 */
/**
 * @typedef EmployeeOnboardingActioned
 * @property {string} [action] - What action has the user taken, i.e. "viewed", "completed" or "app_store_clicked"
 * @property {string} [step] - Indicates the step in the flow where the action was taken
 * @property {string} [store] - Indicates if the Google Play Store or the App store was chosen in the download app step
 */
/**
 * @typedef EmployeePerPurchaseSpendingLimitUpdated
 * @property {string} [actor_role] - Role of actor
 * @property {string | null} [previous_value] - Previous limit value
 * @property {string | null} [value] - Limit value
 */
/**
 * @typedef EmployeeRedirectActioned
 * @property {string} [action] -
 * @property {string} [method] -
 */
/**
 * @typedef EmployeeRoleUpdated
 * @property {string} actor_role - Role of actor
 * @property {string} previous_value - Previous role assigned to the employee
 * @property {string} value - New role assigned to the employee
 */
/**
 * @typedef EmployeeSearchCompleted
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef EmployeesListActioned
 * @property {string} [action] - States the action which was performed when the events with the suffix "Actioned"
 * @property {number} [employeesCount] - Number of employees in the list
 * @property {string} [location] - Section from which the action was fired
 * @property {string | null} [role] - Roles of the employees displayed in the list
 */
/**
 * @typedef EnableCardsActioned
 * @property {string} action - Action taken
 * @property {string} source - Where in the app was the action taken
 * @property {string} [user_id] - The ID of the user/employee for whom the card will be enabled
 */
/**
 * @typedef EvaluateFeatureFlags
 * @property {string} status - The status of the loading the FeatureFlag
 */
/**
 * @typedef ExpenseAttendeesEditClicked
 * @property {string} [expense_id] - Id of the expense
 * @property {string} [viewer] - Viewer role
 */
/**
 * @typedef ExpenseDeleted
 * @property {string} [expense_id] - Id of the expense
 * @property {string} [type] - Type of expense deleted: "purchase", "mileage", "bill, perdiem"
 */
/**
 * @typedef ExpenseDetailCategoryUpdated
 * @property {string | null} [expense_id] - 
 * @property {string | null} [new_account_id] - selected account ID
 * @property {string | null} [old_account_id] - 
 * @property {number | null} [selected_suggestion_index] - Position of the suggestion pressed
 * @property {string | null} [source] - dropdown or suggestion chip or prefill
 * @property {number | null} [suggestions_count] - number of suggestions showed to user
 * @property {string | null} [variation] - control or ai or data

 */
/**
 * @typedef ExpenseDetailsNavigationActioned
 * @property {string} action - action can be either: up_button_clicked, down_button_clicked or closed
 * @property {string} [company_id] -
 * @property {string | null} expense_id - the id of the expense the user will navigate to
 * @property {string} page - The page in which the action is triggered
 */
/**
 * @typedef ExpenseDetailsUpdated
 * @property {string} expense_id - Expense Id
 * @property {string} field - Field that was updated
 * @property {any | null} [new_value] - New value of the expense's property
 * @property {any | null} [previous_value] - Previous value of the expense's property
 * @property {string} purchase_type - Type of the purchase
 * @property {string} review_status - Expense review status
 * @property {string} viewer - Viewer role
 */
/**
 * @typedef ExpenseDetailsViewed
 * @property {string} expense_id - Expense Id
 * @property {boolean} locked - Is expense locked
 * @property {string} [page] - Page on which the user is seeing the expense
 * @property {string} purchase_type - Type of the purchase
 * @property {string} [review_status] - Expense review status
 * @property {string} source - Type of the list that has this expense
 * @property {string} viewer - Viewer role
 */
/**
 * @typedef ExpenseInExportSelected
 * @property {string} [action] - Whether the user selected or deselected the expense
 * @property {number} [all_expenses_count] - How many expenses in the lane at the time the expense was selected/deselected
 * @property {boolean} [already_selected_on_page_load] - Whether the expense was already selected when the user loaded the page (i.e. was selected in a previous session)
 * @property {number} [current_selected_count] - How many expenses were selected when this expense was selected/deselected
 * @property {string} [expense_id] -
 * @property {number | null} [export_page_version] -
 * @property {number} [selected_on_page_load_count] - How many expenses were already selected on page load
 * @property {string} [tab] - Which tab of the export page the select was actioned on
 */
/**
 * @typedef ExpenseNoteUpdated
 * @property {string} [expense_id] - id of the updated expense
 * @property {string} [modifier] - Relation of the modifier of the expense (e.g. expense_owner, member, reviewer, bookkeeper, bookkeeper-extended, bookkeeper-basic)
 * @property {boolean} [needs_details] - The expense has gotten a request for more details
 * @property {any | null} [prev_value] - Previous note text
 * @property {string} [purchase_type] - type of purchase (ATM, ONLINE, IN_STORE, MANUAL). This maps the definition used in deimos_transactions
 * @property {string} [value] - New note text applied
 */
/**
 * @typedef ExpenseReceiptUpdated
 * @property {string} action - Determines the type of the action
 * @property {string} expense_id - Expense Id
 * @property {string} purchase_type - Type of the purchase
 * @property {string} review_status - Expense review status
 * @property {string} viewer - Viewer role
 */
/**
 * @typedef ExpenseSplitStarted
 * @property {string} actor_role - User role
 * @property {string} expense_id - Expense ID
 */
/**
 * @typedef ExpenseTabInExportActioned
 * @property {string} [action] - Expense details edited in the expense tab of the  Export page
 * @property {string} [company_id] - which company is performing the action
 * @property {string | null} [context] - under which context the action happened like split_expense etc
 * @property {string} [expense_id] - Expense ID
 * @property {string | null} [expense_type] - Type of expense where the action happened
 * @property {number | null} [export_page_version] -
 * @property {boolean | null} [is_split_automated] - only on the split expense context
 * @property {string | null} [new_value] - New Value
 * @property {string | null} [previous_value] - Previous Value
 * @property {string} [role] - used to determine if admins or bookkeepers are more likely to change the settings
 */
/**
 * @typedef ExpenseTagSplitUpdated
 * @property {string} actor_role - User role
 * @property {string} expense_id - Expense ID
 */
/**
 * @typedef ExpenseTagUpdated
 * @property {string} [expense_id] - id of the updated expense
 * @property {boolean} [has_suggestions] - The expense has a suggested tag
 * @property {string} [modifier] - Relation of the modifier to the expense (e.g. expense_owner, member, reviewer, bookkeeper, bookkeeper-extended, bookkeeper-basic)
 * @property {boolean} [needs_details] - The expense has gotten a request for more details
 * @property {string} [prev_tag] - Name of the previous tag
 * @property {any | null} [prev_tag_id] - id of the previous tag
 * @property {string} [purchase_type] - type of purchase (ATM, ONLINE, IN_STORE, MANUAL). This maps the definition used in deimos_transactions
 * @property {boolean} [suggested] - indicates if the chosen tag was a suggested tag or not
 * @property {string} [tag] - Name of the tag chosen
 * @property {string | null} [tag_id] - id of the tag chosen
 */
/**
 * @typedef ExpenseTaxSuggestionSaved
 * @property {string} expense_id - ID of the expense
 * @property {boolean} [matches_suggested_tax_rate] - The selected tax rate matches with suggested tax rate
 * @property {string} source - The changes is done via the suggestion pill or the dropdown
 * @property {boolean} suggestion_used - Boolean that says if the suggestion is accepted or not
 */
/**
 * @typedef ExpensesEmptyStateActioned
 * @property {string} [action] - States the action which was performed when the events with the suffix "Actioned"
 * @property {string} [input] - States the element that triggered the action
 */
/**
 * @typedef ExpensesInExportSelected
 * @property {string} [action] - Whether the user selected or deselected all
 * @property {number | null} [export_page_version] -
 * @property {string} [tab] - Which tab of the export page the select was actioned on
 */
/**
 * @typedef ExpensesListFiltered
 * @property {string} filtered_by - Kind of filter applied
 * @property {string} viewer - Role of the user
 */
/**
 * @typedef ExplorePlansClicked
 * @property {string} [featureName] -
 * @property {boolean} isOnTrial -
 * @property {string} origin -
 * @property {string} plan -
 */
/**
 * @typedef ExportAgainModalActioned
 * @property {string} [action] - Whether they continue or cancel on warning modal
 */
/**
 * @typedef ExportBetaBannerActioned
 * @property {string} action - Whether the user enabled or disabled the beta
 * @property {string} beta_group - Whether the user was in the optional (old page by default) or default (new page by default) beta group
 */
/**
 * @typedef ExportDatesBeyondRestrictedRangeSelected
 * @property {string} [end_date] - End date of the range selected by user
 * @property {number} [range_length] - Length of the selected range in days
 * @property {string} [start_date] - Start date of the range selected by user
 */
/**
 * @typedef ExportFiltersActioned
 * @property {string} [action] - Filters actioned in the Export page to give a more filtered overview
 * @property {string} [company_id] - which company is performing the action
 * @property {string | null} [date_range_id] - Quick filter ID
 * @property {number | null} [export_page_version] -
 * @property {boolean} [is_future_date_selected] - If user selects date in future
 * @property {number | null} [num_of_days_queried] - Number of days filters
 * @property {string} [role] - used to determine if admins or bookkeepers are more likely to change the settings
 * @property {string} [tab] - Which tab of the export page the filter was actioned on
 */
/**
 * @typedef ExportHistoryActioned
 * @property {string} [action] - Which action was taken on the filter
 * @property {number | null} [export_page_version] -
 */
/**
 * @typedef ExportLaneActioned
 * @property {string} [action] - The type of action taken in the lane "queued", "removed", "exported", "reexported"
 * @property {number} [delta] - Number of expenses which the expense_count deviates from the suggested "export-ready" expenses.
 * @property {number} [expense_count] - Number of expenses moved in the lane
 * @property {number | null} [export_page_version] -
 */
/**
 * @typedef ExportNavigationActioned
 * @property {number | null} [export_page_version] -
 * @property {string} [previous_view] - The export view which the user navigated from
 * @property {string} [view] - The current export view; "not_exported", "queued", "exported", "settled" (All or Expenses), "pending"
 */
/**
 * @typedef ExportPageActioned
 * @property {string} [action] -
 * @property {string} [expense_id] -
 * @property {string} [expense_type] -
 * @property {string} [export_status] -
 * @property {string} [page] -
 * @property {string} [role] -
 */
/**
 * @typedef ExportRepresentationAttendeesActioned
 * @property {string} [action] - Type of attendee change action: "add", "create", "delete" or "edit"
 * @property {string} [expense_id] - Id of the expense
 */
/**
 * @typedef ExportRepresentationCalculateClicked
 * @property {string} [expense_id] - Id of the expense
 */
/**
 * @typedef ExportRepresentationDoneClicked
 * @property {string} [expense_id] - Id of the expense
 */
/**
 * @typedef ExportRepresentationTaxAmountUpdated
 * @property {number} [amount] - Tax amount
 * @property {string} [expense_id] - Id of the expense
 */
/**
 * @typedef ExportRepresentationTipsAmountUpdated
 * @property {number} [amount] - Tips amount
 * @property {string} [expense_id] - Id of the expense
 */
/**
 * @typedef ExportRepresentationTypeSelected
 * @property {string} [expense_id] - Id of the expense
 * @property {string} [type] - Type of representation
 */
/**
 * @typedef ExportSuggestionSelected
 * @property {string} [company_id] - Which company is performing the action
 * @property {string} [name] - Suggested value field name
 * @property {string} [role] - Used to determine if admins or bookkeepers are more likely to select suggestion
 */
/**
 * @typedef ExportTableError
 * @property {string} [error_code] - The error code
 * @property {string} [error_message] - The error message
 * @property {number} [export_page_version] - Export page version
 * @property {string} [lane] - The lane the user was on when the error occurred
 * @property {string} [page] - Export or reports page
 */
/**
 * @typedef ExportValidationActioned
 * @property {string} [action] - Started, Failed, Passed etc.,
 * @property {string} [system] - integration system name
 * @property {string} [type] - Modal types like confirmation, noAccountingSystem etc.,
 */
/**
 * @typedef FailedToLoadReceipt
 * @property {string | null} [receiptID] - The ID of the PDF document to display
 */
/**
 * @typedef FailedToSourceReceipt
 * @property {string | null} [receiptID] - The ID of the PDF document to display
 */
/**
 * @typedef FeatureClicked
 * @property {string} action -
 * @property {string | null} featureName -
 * @property {boolean} isOnTrial -
 * @property {string} plan -
 */
/**
 * @typedef FetchOffboardingActioned
 * @property {string} [action] - User action
 * @property {string | null} [comment] - Custom input from the user
 * @property {any[]} [reasons] - Reason to disable fetch
 */
/**
 * @typedef FetchOnboardingActioned
 * @property {string} [action] - Action taken in the onboarding flow
 * @property {string} [email_provider] - Indicates whether the user signed up with Gmail or Outlook
 * @property {string} [step] - indicates at which step the event was fired
 */
/**
 * @typedef FetchPromotionBanner
 * @property {string} action - Action that led to firing of this event
 * @property {string} [result] - Result of the action
 */
/**
 * @typedef FindCompany
 * @property {string} [country] - Company country
 * @property {string} [locale] - Web language
 * @property {boolean} [manual] - Boolean, true if the company was manually entered
 * @property {string} [origin] - Full URL
 * @property {string} [path] - Page name (partial URL)
 */
/**
 * @typedef FirstReimburseYourselfFromWeb
 * @property {string} [action] -
 */
/**
 * @typedef FlowCompletedAppcues
 * @property {string} [flowId] -
 * @property {string} [flowName] -
 * @property {string} [flowType] -
 */
/**
 * @typedef FlowStartedAppcues
 * @property {string} [flowId] -
 * @property {string} [flowName] -
 * @property {string} [flowType] -
 */
/**
 * @typedef ForgotPasscodeLinkActioned
 * @property {string} [action] - Define whether the action is performed on the initial login flow or the add account flow
 * @property {string} [actorRole] - What is the role of the user performing the action
 */
/**
 * @typedef Interaction
 * @property {any | null} [formId] -
 * @property {string} [value] -
 */
/**
 * @typedef User
 * @property {string} [email] -
 */
/**
 * @typedef FormFieldSubmittedAppcues
 * @property {string} [email] -
 * @property {string} [flowId] -
 * @property {string} [flowName] -
 * @property {string} [flowType] -
 * @property {number} [flowVersion] -
 * @property {Interaction} [interaction] -
 * @property {string} [interactionType] -
 * @property {string} [stepId] -
 * @property {number} [stepNumber] -
 * @property {string} [stepType] -
 * @property {User} [user] -
 */
/**
 * @typedef ResponseItem
 * @property {string} [fieldId] -
 * @property {boolean} [fieldRequired] -
 * @property {any | null} [fieldType] -
 * @property {string} [label] -
 * @property {string} [value] -
 */
/**
 * @typedef Interaction1
 * @property {any | null} [formId] -
 * @property {ResponseItem[]} [response] -
 */
/**
 * @typedef User1
 * @property {string} [email] -
 */
/**
 * @typedef FormSubmittedAppcues
 * @property {string} [flowId] -
 * @property {string} [flowName] -
 * @property {string} [flowType] -
 * @property {number} [flowVersion] -
 * @property {Interaction1} [interaction] -
 * @property {string} [interactionType] -
 * @property {string} [stepId] -
 * @property {number} [stepNumber] -
 * @property {string} [stepType] -
 * @property {User1} [user] -
 */
/**
 * @typedef FortnoxAccountingSetupGuidedTour
 * @property {string} [action] -
 * @property {string} [step] -
 */
/**
 * @typedef FortnoxOnboardingModuleViewed
 * @property {string} [action] - Action the user took on the modal
 * @property {string} [source] - Source of the accounting system setup
 */
/**
 * @typedef FreezeCardModalActioned
 * @property {string} action - The action that triggered the event
 * @property {string} card_id - The id of the dedicated card
 * @property {number} card_limit_amount - The limit amount set on the card
 * @property {string} [card_limit_currency] - The currency of the company using the card
 * @property {string} card_limit_type - The limit type set on the card
 * @property {string} card_name - The name of the cards
 * @property {string | null} [card_owner] - The id of the employee owning the card
 * @property {string} company_id - The id of the company the card owner is part of
 * @property {string} source - The source/page from where the event was triggered
 */
/**
 * @typedef GetStartedActioned
 * @property {string} [action] - States the action which was performed when the events with the suffix "Actioned"
 * @property {string} [input] - States the name of the element that triggered the action
 * @property {any[]} [milestones] - Visible milestones when the action is viewed.
 * @property {any[]} [tasks] - Visible tasks when the action is viewed.
 */
/**
 * @typedef GettingStartedGuideCtaActioned
 * @property {string} step - Step in which the CTA was clicked
 */
/**
 * @typedef GoogleAdsOptimisationSignal
 * @property {string} company_size - Size of the user company
 * @property {string} currency - Currency of the Google optimisation event
 * @property {string} [gclid] - Google click identifier
 * @property {number} value - Value given by Pleo to the conversion
 */
/**
 * @typedef HrisEarlyAccessBannerActioned
 * @property {string} [companyName] - The name of the active entity when the link was actioned
 * @property {string} [pageSource] - The page where the banner was shown
 */
/**
 * @typedef HrisEarlyAccessBannerDismissed
 * @property {string} [companyName] - The name of the active entity when the link was actioned
 * @property {string} [pageSource] - The page where the banner was shown
 */
/**
 * @typedef HrisEarlyAccessModalDismissed
 * @property {string} [companyName] - The name of the active entity when the link was actioned
 * @property {string} [pageSource] - The page where the banner was shown
 */
/**
 * @typedef HrisEarlyAccessOptInSubmitted
 * @property {string} [companyName] - The name of the active entity when the link was actioned
 * @property {string} [hrSystem] - The HR system the user selected to request early access to
 * @property {string} [pageSource] - The page where the banner was shown
 */
/**
 * @typedef HelpCentreLinkActioned
 * @property {string} [link] - Link to the help centre article/collection
 * @property {string} [source] - Where in the app, the link is actioned
 */
/**
 * @typedef HelpWidgetActioned
 * @property {string} action - Widget action
 * @property {string} [company_id] - What is the company id of the user performing the action
 * @property {string} [organization_id] - If the company is multi-entity, what is the organization id of the user
 * @property {string} [role] - What is the role of the user performing the action
 * @property {string} [source] - The help centre links are being redesigned into the new adoption centre
 */
/**
 * @typedef HelpWidgetViewed
 * @property {string} [action] - Adoption prompt viewed
 */
/**
 * @typedef IntroActioned
 * @property {string} action -
 */
/**
 * @typedef IntroViewed
 * @property {string} [screen] -
 */
/**
 * @typedef InvitationEmailResent
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef InviteBookkeeperActioned
 * @property {string} [action] - States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
 * @property {string} [locale] - Web language
 * @property {string} [origin] - Full URL
 * @property {string} [path] - Page name (partial URL)
 */
/**
 * @typedef InviteFlowBulkAssignTeamActioned
 * @property {number} current_number_of_teams - How many teams already exist at the company
 * @property {boolean} is_using_hris_integration - Is the admin inviting users from an integration
 * @property {number} number_of_users_selected - how many users were checked when a bulk action was completed
 * @property {string} role - role of the user performing the action
 */
/**
 * @typedef InviteFlowCreateTeamActioned
 * @property {number} current_number_of_teams - the number of teams currently at the company
 * @property {boolean} is_organization -
 * @property {boolean} is_using_hris_integration - Is the admin inviting users from an integration
 * @property {number} number_of_added_reviewers - How many reviewers were added to the new team
 * @property {string} role - role of the user performing the action
 * @property {string} [tracking_source] - From which area of the product did the invite flow start
 */
/**
 * @typedef InviteFlowCreateTeamDrawerClosed
 * @property {number} current_number_of_teams -
 * @property {boolean} is_organization -
 * @property {boolean} is_using_hris_integration - Is the admin inviting users from an integration
 * @property {string} role -
 * @property {string} [tracking_source] - From which area of the product did the invite flow start
 */
/**
 * @typedef InviteFlowCreateTeamDrawerOpened
 * @property {number} current_number_of_teams - How many teams currently exist for the company
 * @property {boolean} is_organization -
 * @property {boolean} [is_using_hris_integration] - Is the admin inviting users from an integration
 * @property {string} role - role of the user performing the action
 * @property {string} [tracking_source] - From which area of the product did the invite flow start
 */
/**
 * @typedef InviteFlowSelectTeamActioned
 * @property {number} current_number_of_teams - How many teams currently exist for the company
 * @property {boolean} is_organization -
 * @property {boolean} is_using_hris_integration - Is the admin inviting users from an integration
 * @property {string} role - The role of user performing the action. Admin, BK, Partner
 * @property {string} [tracking_source] - From which area of the product did the invite flow start
 */
/**
 * @typedef InvitePeopleLinkClicked
 * @property {string} actor - The user role
 * @property {string} [connectedPeopleAppCategory] - The category a connected people app
 * @property {string} [connectedPeopleAppId] - The ID of the connected people app
 * @property {string} [connectedPeopleAppName] - The name of the connected people app
 * @property {string} [connectedPeopleAppType] - The type of a connected people app
 * @property {string} screen - The name of the screen the action is performed
 */
/**
 * @typedef InviteTeamActioned
 * @property {string} action - States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
 * @property {string} [cta] - User clicks the CTA either on the product card or the milestone card of the Get Started Guide
 */
/**
 * @typedef InviteTeamEmailActioned
 * @property {string} [action] - States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
 * @property {string} [actor_role] - Role of actor
 * @property {string} [employee_id] - The added user ID
 * @property {string} [locale] - Web language
 * @property {number} [n_invites] - Number of members invited
 * @property {number} [n_invites_card] - Number of cardAccess members invited
 * @property {number} [n_invites_cardless] - Number of cardless members invited
 * @property {string} [origin] - Full URL
 * @property {string} [path] - Page name (partial URL)
 * @property {boolean} [skipped] - Boolean, true if the step was skipped
 * @property {string} [source] - Location in the app where the event originated
 * @property {string} [type] - The type of user added
 */
/**
 * @typedef InviteUserCancelled
 * @property {string} source - Where this action was taken within the app
 * @property {string} step - The step within the user adding wizard
 */
/**
 * @typedef InviteUsers
 * @property {string} actor - The role of the current user
 * @property {string} [connectedPeopleAppCategory] - The category a connected people app
 * @property {string} [connectedPeopleAppId] - The ID of the connected people app
 * @property {string} [connectedPeopleAppName] - The name of the connected people app
 * @property {string} [connectedPeopleAppType] - The type of a connected people app
 * @property {number} count - The number of users being invited
 * @property {number} countOfCardAndReimbursementUsers - The number of card and reimbursement users
 * @property {number} countOfCardUsers - The number of card users
 * @property {number} countOfEntitiesInPleo - The number of entities in Pleo
 * @property {number} countOfNewEntitiesOutsidePleo - The number of new entities outside Pleo
 * @property {number} countOfProvisionedUsers - The total number of provisioned users
 * @property {number} countOfReimbursementUsers - The number of reimbursement users
 * @property {number} [countOfUsersWithTeam] - The number of users with assigned team
 * @property {number} [countOfValidationErrors] - The number of validation errors
 * @property {boolean} [enabledReimbursements] - It tells whether reimbursements was enabled during this invite
 * @property {string} source - The source of the users being invited
 * @property {string} step - The current step in the invite flow
 */
/**
 * @typedef InvoiceAdoptionPromptActioned
 * @property {string} [action] -
 */
/**
 * @typedef KycContinueActioned
 * @property {string} value - Name of the section the Continue button is clicked on
 */
/**
 * @typedef KycIndustryActioned
 * @property {string} action - What action has the user taken
 * @property {boolean} [is_suggested] - Whether or not the selected industry was part of the list of suggested industries
 * @property {string} [method] - The method of user interaction
 */
/**
 * @typedef KycListActioned
 * @property {string} state -
 * @property {string} value -
 */
/**
 * @typedef KycPersonActioned
 * @property {string} [action] - What action has the user taken, i.e. "started", "completed", "next" or "close_accordion"
 * @property {boolean} [admin] - Determines if the event takes place in the representative screen or in the person-in-scope screen
 */
/**
 * @typedef KycSectionActioned
 * @property {string} action -
 * @property {string} value -
 */
/**
 * @typedef KycVatActioned
 * @property {string} action - What action has the user taken
 * @property {string} [error] - The VAT validation error message
 * @property {boolean} [no_vat_selected] - Whether or not the user indicated that they do not have a VAT number
 */
/**
 * @typedef LandingPagesEligibleViewed
 * @property {string} [page] - Page in which the component is displayed
 */
/**
 * @typedef LegacyPlanBanner
 * @property {string} [action] -
 * @property {string} [current_plan] -
 */
/**
 * @typedef LogOutClicked
 * @property {number} numberOfActiveAccounts - Number of active accounts on the device
 */
/**
 * @typedef ManageAutoTopUp
 * @property {string} action -
 * @property {number} [atu_amount_low_balance] - Selected Auto Top-up amount (for the Low Balance strategy)
 * @property {number} [atu_amount_scheduled] - Selected Auto Top-up amount (for the Scheduled strategy)
 * @property {string} [atu_frequency_scheduled] - Selected Auto Top-up frequency (for the Scheduled strategy)
 * @property {string} [atu_status] - Selected Auto Top-up status
 * @property {number} [atu_threshold_low_balance] - Selected Auto Top-up threshold (for the Low Balance strategy)
 * @property {string} atu_type - Selected Auto Top-up type
 * @property {string} [prev_auto_top_up_type] - Is used together with "switch_strategy" action to know about previous type of Auto Top-up
 */
/**
 * @typedef ManageWalletViewed
 * @property {boolean} [is_eligible_for_reserve] -
 * @property {string} tab -
 */
/**
 * @typedef ManualExpenseAdded
 * @property {string} [accountId] - Id of the account associated with the expense
 * @property {string} [country] - Country in which the expense was made
 * @property {string} [currency] - Currency in which the expense was made
 * @property {string} [expense_id] - Id of the expense
 * @property {boolean} [hasNote] - Whether the user has added a note to the expense or not
 * @property {Record<string, any>} [tags] - Tags added to the expense
 */
/**
 * @typedef MemberSettingReviewingComplete
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef MemberSettingReviewingStarted
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef MemberSettingsActioned
 * @property {string} [action] - Specific editing action which triggered the event
 * @property {string} [company_id] - Which company is committing an action
 * @property {string | null} [employee_id] - Which employee is the action being taken on
 * @property {string} [locale] - Web language
 * @property {string} [origin] - Full URL
 * @property {string} [path] - Page name (partial URL)
 * @property {string} [role] - used to determine if admins or bookkeepers are more likely to change the settings
 */
/**
 * @typedef MemberSettingsReviewerRemoved
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef MemberSettingsTeamUpdated
 * @property {string} [action_role] - Role of actor
 * @property {string} [value] - Name of the team
 */
/**
 * @typedef MetaAdsOptimisationSignal
 * @property {string} company_size - Size of the user company
 * @property {string} currency - Currency of the Meta Ads conversion event
 * @property {string} [fbc] - Meta ad fbclid
 * @property {string} [fbp] - Meta Pixel unique identifier
 * @property {boolean} is_generic_email - If the user email domain is gmail.com or not
 * @property {number} value - Value given by Pleo to the conversion
 */
/**
 * @typedef MileageAccountUpdated
 * @property {string} [company_id] - Unique system-wide company_id
 * @property {string} [new_value] - New rate value
 * @property {string | null} [previous_value] - Previous rate value
 */
/**
 * @typedef MileageDefaultRateUpdated
 * @property {string} [company_id] - Unique system-wide pleo company_id
 * @property {string} [currency] - rate currency
 * @property {number} [new_value] - new rate value
 * @property {number} [previous_value] - previous rate value
 */
/**
 * @typedef MileageDownloadActioned
 * @property {string} [action] - The action which the user performed, these can be 'download_completed', 'modal_opened', download_clicked', 'modal_dismissed'
 * @property {string} [end_date] - If action is download_completed then this states the end date of the selected date range
 * @property {string} [failure_reason] - The reason the report creation failed
 * @property {boolean} [has_mileage_expenses] - If action is modal_opened then this shows if there were any mileage expenses available to download.
 * @property {number} [number_of_expenses] - Number of expenses
 * @property {string} [preselected_date_range] - If action is download_clicked then this shows if the user used a preselected date range or went with a custom date range. Options: ‘this_month’, ‘last_month’, ‘this_quarter’, ‘this_year’, ‘custom’
 * @property {string} [start_date] - If action is download_completed then this states the start date of the selected date range
 */
/**
 * @typedef MileageEmployeeRateUpdated
 * @property {string} [company_id] - Unique system wide pleo company_id
 * @property {string | null} [currency] - currency of the rate
 * @property {string} [employee_id] - Unique system wide pleo employee_id
 * @property {number | null} [new_value] - New rate value
 * @property {string} [page] - page from which the settings were updated (this can currently either be the people page or the mileage page)
 * @property {number | null} [previous_value] - New rate value
 */
/**
 * @typedef MileageListActioned
 * @property {string} [action] - What action has the user taken, i.e. "sort" or "search"
 * @property {string} [input] - What input was used for the action, i.e. "distance_asc"
 */
/**
 * @typedef MileageRateUpdated
 * @property {string} [company_id] - Unique system-wide company_id
 * @property {string} [currency] - Rate currency
 * @property {number} [new_value] - New rate value
 * @property {number | null} [previous_value] - Previous rate value
 */
/**
 * @typedef MissingSettingsExportModal
 * @property {string} [action] -
 * @property {number} [number_exported_card_expenses] -
 * @property {number} [number_exported_pocket_expenses] -
 * @property {number} [number_exported_reimbursements] -
 * @property {string} [type] -
 */
/**
 * @typedef MissingTranslation
 * @property {string} [key] - Translation key that was missing
 * @property {string} [locale] - The locale that the user is using when the missing translation was detected
 */
/**
 * @typedef MyAccountClicked
 * @property {string} variant - The variant of the switcher user is viewing
 */
/**
 * @typedef MyCardsClicked
 * @property {string} variant - The variant of the switcher user is viewing
 */
/**
 * @typedef NewPricingBanner
 * @property {string} [action] -
 * @property {string} [current_plan] -
 */
/**
 * @typedef NewPurchaseOrderActioned
 * @property {string} action - Action performed by the user
 * @property {string} actor_role - the role of the user performing the action
 * @property {string} [currency] -
 * @property {boolean} [empty_state] -
 * @property {string} [error_name] -
 * @property {string} [purchase_order_id] - Purchase order id when the purchase order is created successfully
 * @property {any[]} [reviewer_ids] - List of reviewers added to the purchase order
 * @property {string} source -
 * @property {string} [supplier] -
 * @property {string} [total_amount] -
 */
/**
 * @typedef NewVendorCardActioned
 * @property {string} action - The action that triggered the event

 * @property {string[] | null} [allowed_vendor_id] - the list of allowed vendor ids

 * @property {string[] | null} [allowed_vendor_name] - the list of allowed vendor names
 * @property {string} [card_id] - The card id
 * @property {number} [card_limit_amount] - The card limit amount set by the user

 * @property {string} [card_limit_currency] - The card limit currency

 * @property {string} [card_limit_type] - The card limit type, it can be either Monthly, Weekly or No limit

 * @property {string} [card_name] - The card name set by the user

 * @property {string} [card_owner] - The card owner Id
 * @property {string} [company_id] - The company Id

 * @property {boolean} [having_allowed_vendor] - Is the Vendor rule active on the vendor card

 * @property {string} [source] - The page name in which the event happened

 */
/**
 * @typedef OffboardingModalActioned
 * @property {string} [landing_page_action] - User clicks one of three options
 * @property {string} [url_source] - The url the user is on
 */
/**
 * @typedef OffboardingWizardActioned
 * @property {string} action - The action taken by the user
 * @property {string} step - The current step in the wizard
 */
/**
 * @typedef OnboardTeamSpendingLimitActioned
 * @property {string} [action] - States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
 * @property {string} [currency] - The company currency
 * @property {string} [limit_type] - The limit type that is updated
 * @property {string} [new_value] - The new value being updated
 * @property {string} [path] - Page/Url name
 * @property {string} [previous_value] - The previous limit value
 */
/**
 * @typedef OnboardingBannerClicked
 * @property {string} [cta] -
 * @property {number | null} [export_page_version] -
 * @property {string} [location] -
 */
/**
 * @typedef OnboardingWalletBankTransferTopUp
 * @property {string} [action] -
 * @property {string} [copied_field_name] - Param for action 'copy transfer details'
 */
/**
 * @typedef OpenBankingModalActioned
 * @property {string} action -
 * @property {number} [amount] - Amount of money to Top up
 * @property {number} [autoTopUpAmount] - VRP settings
 * @property {number} [autoTopUpThreshold] - VRP settings
 * @property {string} [bankSearchQuery] - User's input on select bank screen at the trigger event time
 * @property {string} [callbackStatus] -
 * @property {string} [callbackType] -
 * @property {string} [currency] -
 * @property {string} [iban] -
 * @property {string} [institutionId] - Id of selected bank
 * @property {boolean} [isEmptyResultsButton] - Click on button (when result is empty) to navigate to Bank Transfer Details
 * @property {boolean} [isFooterLink] - Click on footer link to navigate to Bank Transfer Details
 * @property {string} [redirectUrl] -
 * @property {string} [screenName] - For [show screen] event to understand which step of modal is visible now
 * @property {string} [tokenStage] - Stage of Consent token processing
 * @property {number} [totalBankItemsCount] - Number of banks in response after bank search request
 */
/**
 * @typedef OrderCardsActioned
 * @property {string} action - States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
 * @property {string} [actor_role] - Actor's role
 * @property {string | null} [employee_id] - Id of the employee entry that was changed
 * @property {string} [error_name] - Employee name error displayed to the user
 * @property {string} [is_manual_ticked] - Was the user manually selected or automatically selected after resolving an error
 * @property {number} [n_cards] - Number of cards ordered
 * @property {number | null} [n_selected_employees] - The number of employees selected
 * @property {string} [new_employee_name] - Updated employee name
 * @property {string} [previous_employee_name] - Employee's previous name
 * @property {string} [view] - Where the card action is performed
 */
/**
 * @typedef OrderCardsCompleted
 * @property {string} [actor_role] - User's role
 * @property {number} [quantity] - Number of cards ordered in this placement
 */
/**
 * @typedef OrderCardsStarted
 * @property {string} [actor_role] - User role
 * @property {number} [max_order] - Max number of cards the company can order
 * @property {number} [no_of_employees] - Number of employees invited to Pleo
 * @property {number} [paired_cards] - Number of cards already in use
 * @property {number} [quantity] - Number of card about to be ordered in this placement
 */
/**
 * @typedef OrderCompleted
 * @property {string} [company_employee_size] - The company size in terms of number of emplyoyees. This can be a single number or a range eg: 5-9
 * @property {string} [country_code] - The 2 character alpha-2 country code for the company is registered in
 * @property {string} [currency] - Currency of the Facebook purchase event
 * @property {string} [funnel_stage] - Stage of the funnel where event is fired
 * @property {number} [revenue] - Value of the Facebook purchase event
 * @property {number} [total] - Value of the Google purchase event
 * @property {number} [value] - Value given by Pleo to the conversion
 */
/**
 * @typedef OrderPlasticCardsList
 * @property {number} [n_all_listed_employees] - Number of employees in the card order list
 * @property {number} [n_invalid_char_employees] - Number of employees that contain invalid characters
 * @property {number} [n_long_name_employees] - Number of employees that have a "Too long name" error
 * @property {number} [n_missing_name_employees] - Number of employees missing an employee name
 * @property {number} [n_selected_employees] - Number of employees selected by default
 * @property {number} [n_warning_employees] - Number of employees that has an "Invalid character error"
 */
/**
 * @typedef OverdraftBannerActioned
 * @property {string} action -
 */
/**
 * @typedef OverdraftConfirmationActioned
 * @property {string} action -
 */
/**
 * @typedef OverdraftEligibilityApplication
 * @property {string} action -
 */
/**
 * @typedef OverdraftHigherLimit
 * @property {string} action -
 * @property {string} [applicationState] - It only appear when the action is "increase limit clicked"
 */
/**
 * @typedef OverdraftOnboardingAccordionItem
 * @property {string} [action] -
 * @property {string} [state] -
 */
/**
 * @typedef OverdraftRenewalActioned
 * @property {string} action -
 */
/**
 * @typedef OverdraftSettings
 * @property {string} action -
 */
/**
 * @typedef OverdraftStatementsActioned
 * @property {string} action -
 * @property {string} [statement_date] -
 */
/**
 * @typedef OverdraftSummaryActioned
 * @property {string} action -
 * @property {boolean} [isAutoRepaymentsEnabled] -
 */
/**
 * @typedef OverdraftTermsActioned
 * @property {string} action -
 * @property {string} [auto_repayments_enabled] -
 * @property {string} [currency] -
 * @property {string} [field] -
 * @property {number} [limit] -
 * @property {string} [newValue] -
 */
/**
 * @typedef OverdraftUnderwritingApplicationActioned
 * @property {string} action -
 */
/**
 * @typedef Screen
 * @property {number} [height] - The height of the user's screen
 * @property {number} [width] - The width of the user's screen
 */
/**
 * @typedef PageViewed
 * @property {string} [name] - The url after http://app.pleo.io/[name]
 * @property {string} [path] - The page the user in on when this event was triggered
 * @property {string | null} [previous_path] - The previous page the user was on before landing on this page
 * @property {string} [referrer] -
 * @property {Screen} [screen] - The user's screen size (width and height)
 * @property {string} [search] -
 * @property {string} [title] -
 * @property {string} [url] -
 */
/**
 * @typedef PartnerAcademy
 * @property {string} [action] - Tells us what action the user has performed
 * @property {string} [video] - Tells us what video the user watched
 */
/**
 * @typedef Bookkeeper
 * @property {string | null} id - The id of the bookkeeper.
 */
/**
 * @typedef Client
 * @property {string | null} id - The id of the partner client.
 */
/**
 * @typedef PartnerClientBookkeepersChanged
 * @property {string} action - Whether the bookkeeper was assigned or removed.
 * @property {Bookkeeper} bookkeeper - Object containing information about the bookkeeper.
 * @property {Client | null} client - Object containing information about the partner client.
 */
/**
 * @typedef PartnerClientCreated
 * @property {string} [company_id] - Pleo system-wide company id
 * @property {string} [company_name] - Pleo system-wide company name
 */
/**
 * @typedef Client1
 * @property {string | null} id - The id of the client.
 */
/**
 * @typedef PartnerClientDetailsDrawerDisconnectClient
 * @property {Client1 | null} client - Object containing information about the onboarding client.
 */
/**
 * @typedef Client2
 * @property {string | null} id - The id of the client.
 */
/**
 * @typedef PartnerClientDetailsDrawerDismissed
 * @property {Client2 | null} client - Object containing information about the onboarding client.
 * @property {string} element - Element used to dismiss the drawer, either the drawer close icon or the drawer overlay area.
 */
/**
 * @typedef Client3
 * @property {string | null} id - The id of the partner client currently being viewed.
 */
/**
 * @typedef ToClient
 * @property {string | null} id - The id of the client the user is navigating to.
 */
/**
 * @typedef PartnerClientDetailsDrawerNavigationButtonsActioned
 * @property {Client3 | null} client - Object containing information about the partner client currently being viewed.
 * @property {string} direction - Whether the user is navigating up or down the list. As in, navigating to the previous or to the next client of the list.
 * @property {ToClient} to_client - Information about the client the user is navigating to.
 */
/**
 * @typedef Client4
 * @property {string | null} id - The id of the client.
 */
/**
 * @typedef PartnerClientDetailsDrawerPermissionsSettingsActioned
 * @property {string} action - The action performed against the Permissions section.
 * @property {Client4} client - Object containing information about the onboarding client.
 * @property {string | null} status - The current Permission Status for the given client.
 */
/**
 * @typedef Client5
 * @property {string | null} id - The id of the client.
 */
/**
 * @typedef PartnerClientDetailsDrawerTabsActioned
 * @property {Client5 | null} client - Object containing information about the onboarding client.
 * @property {string} tab - The name of the tab that was clicked.
 */
/**
 * @typedef PartnerClientListFilters
 * @property {string} action - Tells us what interaction the user had with the filter/sort option
 * @property {any[]} value - Tells us what value the user has filtered or sorted by
 */
/**
 * @typedef PartnerClientOnboardingSuccessModalActioned
 * @property {string} action - The button that has been actioned
 */
/**
 * @typedef PartnerEmployeeCsvOffboardActioned
 * @property {string} [action] -
 */
/**
 * @typedef PartnerEmployeeExportActioned
 * @property {string} [action] -
 */
/**
 * @typedef PartnerEmployeeInviteFormActioned
 * @property {string} action - The name of the action performed. Enum of the possible actions.
 * @property {number} [number_of_employees] - The number of invited employees of the performed action.
 */
/**
 * @typedef PartnerInviteAccepted
 * @property {string} [invited_email] - email of invited user
 * @property {string} [invited_first_name] - first name of invited user
 * @property {boolean} [new_user] - boolean, false if the user does not already have a pleo account.
 * @property {string} [type] - The type indicates if the invitation accepted was an invite to become a partner employee, or an invite to create a new partner (and in process become its owner)
 */
/**
 * @typedef PartnerInviteFlowActioned
 * @property {string} action - The action being performed: started or completed.
 * @property {boolean} new_user - Whether the flow is for an existing user or a new one.
 * @property {string} type - The type of invite: new-partner or partner-employee
 */
/**
 * @typedef Client6
 * @property {string | null} id - The id of the client.
 * @property {string} status - The onboarding status of the client.
 */
/**
 * @typedef PartnerOnboardingClientOptionsMenuActioned
 * @property {string} action - Either open_menu (when options menu was opened) or the name of the option button that was clicked
 * @property {Client6} client - Object containing information about the onboarding client.
 */
/**
 * @typedef PartnerPortalFeedback
 * @property {string} feature - Name of the feature the user is giving feedback
 * @property {string} feedback_message - Free text message to describe their feedback
 * @property {string} partner_id - Partner ID
 * @property {string} user_id - User ID
 */
/**
 * @typedef PartnerPricingCalculator
 * @property {string} billing_frequency - Monthly or Annual billing frequency
 * @property {boolean} cashback - Request with cashback (where discounts are disabled). Only available on annual billing frequency with Advanced or Beyond plans
 * @property {string} currency -
 * @property {number} expected_monthly_spend - Used to calculate cashback
 * @property {string} partner_id -
 * @property {string} partner_tier - Partner tier selected
 * @property {string} pricing_plan - Pricing plan selected
 * @property {number} user_count - Number of users in quote
 */
/**
 * @typedef PartnerPromo
 * @property {string} action - Tells us what interaction the user had with the Partner Promo
 * @property {string} [variant] - Tells us which variant of the Partner Promo was displayed to the user
 */
/**
 * @typedef PartnerSelfOnboardingDrawerActioned
 * @property {string} action - The action performed by the user on the drawer
 */
/**
 * @typedef Task
 * @property {string} name - The name of the task actioned by the user.
 * @property {string} status - The status of the task at the moment of the action.
 */
/**
 * @typedef PartnerSelfOnboardingTaskActioned
 * @property {string} action - The action performed by the user on the onboarding task.
 * @property {Task} task - The details of the task actioned by the user.
 */
/**
 * @typedef PartnerSelfSignupEmployeesInvited
 * @property {number} invited-employees - The number of employees that have been invited. 0 means the user has skipped this step.
 */
/**
 * @typedef PartnerSelfSignupPartnershipCreated
 * @property {string} source - Tells us whether the partner company has been selected from the registri, has been inserted manually or the user already had a pleo company.
 */
/**
 * @typedef PartnerSelfSignupUserDetails
 * @property {string} [email] - The user's email entered during the signup flow.
 * @property {string} [first_name] - The user's first name entered during the signup flow.
 * @property {boolean} [is_different_user] - True if the logged in user is trying to signup with an email address that's different from the one already associated with them
 * @property {boolean} [is_logged_in] - True if the user is already logged in when landing on the partner signup flow
 * @property {boolean} [is_partner_user] - True if the logged in user is already a partner user and has landed on the signup flow again for whatever reason
 * @property {string} [last_name] - The user's last name entered during the signup flow.
 * @property {boolean} [user_exists] - True if the email entered by the user is already associated with a login account.
 */
/**
 * @typedef PartnerTeamActioned
 * @property {string} [action] -
 * @property {string} [operation] -
 */
/**
 * @typedef PartnerTeamClientActioned
 * @property {string} [action] -
 * @property {string} [operation] -
 */
/**
 * @typedef PartnerTeamMemberActioned
 * @property {string} [action] -
 * @property {string} [operation] -
 */
/**
 * @typedef PasscodeResetConfirmPasscodeFailed
 * @property {boolean} isSelfInitiated - Was the passcode reset initialed by the user themselves. False if triggered by an admin.
 */
/**
 * @typedef PasscodeResetEnterVerificationCode
 * @property {boolean} isSelfInitiated - Was the passcode reset initialed by the user themselves. False if triggered by an admin.
 * @property {string} [method] - Which type of OTP was used
 */
/**
 * @typedef PasscodeResetLinkExpiredScreenViewed
 * @property {boolean} isSelfInitiated - Was the passcode reset initialed by the user themselves. False if triggered by an admin.
 */
/**
 * @typedef PasscodeResetLogoutToContinueScreenViewed
 * @property {boolean} isSelfInitiated - Was the passcode reset initialed by the user themselves. False if triggered by an admin.
 * @property {number} [number_of_logged_in_accounts] - The number of accounts a user has logged in on that browser
 */
/**
 * @typedef PasscodeResetSuccess
 * @property {boolean} isSelfInitiated - Was the flow self initiated or triggered by an admin
 */
/**
 * @typedef PaywallActioned
 * @property {string} action - The action taken by the user within the paywall
 * @property {boolean} is_dismissible - Whether or not the modal is dismissible
 * @property {string} [link_text] - The text of the link clicked by the user, used with action ’link_clicked’
 * @property {string} [target_url] - The URL target if the user clicks a link
 * @property {string} view - The screen visible to the user within the paywall
 */
/**
 * @typedef PaywallFeedbackSubmitted
 * @property {string} option - The option selected by the user
 * @property {string} [other_text] - The text submitted by the user when option is 'Other'
 */
/**
 * @typedef PendingOverdraftActioned
 * @property {string} [action] -
 */
/**
 * @typedef PeoplePageViewed
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef PhoneNumberStepActioned
 * @property {string} [action] - States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
 * @property {string} [flow] - The flow from which the event was triggered
 * @property {string} [locale] - Web language
 * @property {string} [origin] - Full URL
 * @property {string} [path] - Page name (partial URL)
 */
/**
 * @typedef PleoBankFeed
 * @property {string} action - Feed enabled or disabled
 */
/**
 * @typedef PleoGuideReserveCardActioned
 * @property {string} [action] -
 * @property {boolean} [dd_agreement_in_progress] -
 * @property {boolean} [kyc_verification_in_progress] -
 * @property {string} [status] -
 */
/**
 * @typedef PleoGuideReserveProductCardActioned
 * @property {string} [action] -
 * @property {boolean} [dd_agreement_in_progress] -
 * @property {boolean} [kyc_verification_in_progress] -
 * @property {string} [status] -
 */
/**
 * @typedef PleoGuideViewed
 * @property {boolean} [connect_accounting_completed] - Boolean, true if this step is completed upon the time where the user viewed the pleo guide and triggered this event
 * @property {boolean} [invite_team_completed] - Boolean, true if this step is completed upon the time where the user viewed the pleo guide and triggered this event
 * @property {string} [locale] - Web language
 * @property {string} [origin] - Full URL
 * @property {string} [path] - Page name (partial URL)
 * @property {boolean} [try_card_completed] - Boolean, true if this step is completed upon the time where the user viewed the pleo guide and triggered this event
 */
/**
 * @typedef PleoReserveCollectionsBannerActioned
 * @property {string} [action] -
 * @property {string} [state] -
 */
/**
 * @typedef PleoReserveCollectionsModalActioned
 * @property {string} [action] -
 * @property {string} [feedback] -
 * @property {string} [state] -
 */
/**
 * @typedef PleoReserveCollectionsTopUpMethodActioned
 * @property {string} [action] -
 * @property {string} [transfer_type] -
 */
/**
 * @typedef PleoReserveOnboardingModalActioned
 * @property {string} action -
 * @property {number} [screen_number] -
 * @property {string} [source] -
 */
/**
 * @typedef PocketAccountActioned
 * @property {string} [action] -
 * @property {string | null} [previous_value] -
 * @property {string} [value] -
 */
/**
 * @typedef PocketAdoptionPromptActioned
 * @property {string} [action] -
 */
/**
 * @typedef PocketBalanceActioned
 * @property {string} [action] -
 * @property {string} [balance] -
 * @property {string} [direct_reimbursement_permission] -
 * @property {boolean} [has_employee_card_bank_account] -
 * @property {number} [number_expenses] -
 */
/**
 * @typedef PocketDateInExportActioned
 * @property {string} action - Describes action type
 * @property {boolean} [dont_warn] - Whether the user has checked or unchecked the don't warn checkbox
 * @property {string} [previous_value] - The previous value entered
 * @property {string} [value] - The value entered
 */
/**
 * @typedef PocketEasyStartAssigned
 * @property {string} company_id - Unique system-wide company_id
 */
/**
 * @typedef PocketEnabled
 * @property {string} [source] -
 */
/**
 * @typedef PocketManualTransferModalActioned
 * @property {string} action -
 * @property {string} type -
 */
/**
 * @typedef PocketNotCompatibleWithLexOfficeModal
 * @property {string} [action] -
 * @property {string} [step] -
 * @property {string} [system_name] -
 */
/**
 * @typedef PocketOnboardingActioned
 * @property {string} [account_name] - User selects an account in the accounting set up guide
 * @property {string} [account_type] -
 * @property {string} [accounting_system] - Accounting system integrated with Pleo
 * @property {string} [action] -
 * @property {number | null} [review_amount] - User submits a review threshold in the accounting setup guide
 * @property {any | null} [source] -
 * @property {string} [step] -
 */
/**
 * @typedef PocketPageActioned
 * @property {string} [action] -
 * @property {string} [expense_id] -
 * @property {string} [page] -
 * @property {string} [reimbursement_id] -
 * @property {string} [type] -
 * @property {string} [user_id] -
 */
/**
 * @typedef PocketPageFilterActioned
 * @property {string} [action] -
 * @property {string} [filter_name] - The filter applied
 * @property {string} [filter_value] - The value applied to the filter
 * @property {boolean} [has_search_query] - Whether a user has searched the list of users or not
 */
/**
 * @typedef PocketPageModalActioned
 * @property {string} [action] -
 * @property {string} [name] -
 */
/**
 * @typedef PocketSetupBannerActioned
 * @property {string} [action] -
 * @property {string} [step] -
 */
/**
 * @typedef PocketSetupCancelled
 * @property {boolean} [error] - false if the cancel was due to an error
 * @property {string} [step] - name of the screen which the cancellation happened on
 */
/**
 * @typedef PocketStatementDownloadActioned
 * @property {string} action - Describes action type
 * @property {string} [date] - Date that admin selected to have statement generated for
 */
/**
 * @typedef PocketTransferActioned
 * @property {string} [action] -
 * @property {number} [amount] -
 * @property {string} [currency] -
 * @property {string | null} [employee_id] -
 * @property {boolean} [has_direct_reimbursement_enabled] -
 * @property {boolean} [has_employee_card_bank_account] -
 * @property {string} [manual_method] -
 * @property {string} [method] -
 * @property {number} [number_expenses] -
 * @property {string} [reimbursement_target] -
 */
/**
 * @typedef PocketTransferAdded
 * @property {number} [amount] - Amount transfered
 * @property {string} [currency] - Currency in which the transfer happened
 * @property {string} [employee] - id of the employee involved in the transfer
 * @property {string} [method] - Transfer method ('received_in_person', 'deduct_from_next_salary', 'bank_transfer', 'other', 'paid_with_next_salary', 'cash_handout')
 * @property {string} [note] - Note added to the transfer
 * @property {number} [remaining_balance] -
 * @property {string} [type] - Type of action ('reimbursement', 'cash_return')
 */
/**
 * @typedef PocketTransferNewDesignModalActioned
 * @property {string} [action] -
 * @property {string} [company_id] -
 * @property {string} [role] -
 * @property {number} [step] -
 */
/**
 * @typedef PocketTransferStepViewed
 * @property {string} [step] - Name of the specific step viewed ('user', 'reimburse', 'receive_cash', 'review')
 */
/**
 * @typedef PocketVideoViewed
 * @property {Record<string, any>} [] -
 */
/**
 * @typedef PricingEnquiryLinkActioned
 * @property {string} destination - The outgoing URL of the link
 * @property {string} location - Where the link was pressed within the product
 * @property {string} source - The current URL when the user pressed the link
 */
/**
 * @typedef ProductUsageActioned
 * @property {string} action - What action has the user taken
 * @property {string} flow - The flow from which the event was triggered
 * @property {boolean} [has_usage_objectives] -
 * @property {number} [num_usage_objectives] -
 * @property {any[]} usage_objectives - The values submitted by the user
 */
/**
 * @typedef ProfileDetailsUpdated
 * @property {string} [actor_role] - Role of actor
 * @property {string} [type] - Which info has been updated
 */
/**
 * @typedef ProjectedSpendWidget
 * @property {string} action -
 */
/**
 * @typedef PromoCardActioned
 * @property {string} action - One of ["viewed", "abandoned" or "completed"]
 * @property {string} message - The copy displayed on the banner when this action was taken
 * @property {string} placement - Where the card appears, eg. navigation menu bottom
 */
/**
 * @typedef PurchaseOrderActioned
 * @property {string} action -
 * @property {string} actor_role -
 * @property {string} [invoice_id] - Invoice that is assigned to PO
 * @property {string} purchase_order_id -
 * @property {string} source -
 */
/**
 * @typedef PurchaseOrderReviewActioned
 * @property {string} action -
 * @property {string} actor_role -
 * @property {string} purchase_order_id -
 * @property {string} source -
 */
/**
 * @typedef QuestionIconActioned
 * @property {string} [action] - States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
 * @property {string} [locale] - Web language
 * @property {string} [origin] - Full URL
 * @property {string} [path] - Page name (partial URL)
 */
/**
 * @typedef RecardingOrderStepViewed
 * @property {string} [step] - The flow has 5 steps. 1) message, 2) cards, 3) address, 4) confirmation, 5) completed
 */
/**
 * @typedef ReceiptForwarding
 * @property {string} expense_id -
 * @property {number | null} [export_page_version] -
 * @property {boolean} forwarding_address_copied -
 */
/**
 * @typedef ReceiptInExportPageActioned
 * @property {string} action - Action performed
 * @property {string} receipt_id - Id of the receipt
 */
/**
 * @typedef ReceiptRotation
 * @property {string} [page] -
 * @property {string} receipt_id -
 * @property {string | null} [receipt_type] -
 * @property {number} [rotated] - Degrees rotated
 */
/**
 * @typedef ReceiptScalingInExportPage
 * @property {string} [action] - action performed by the user
 * @property {string | null} [invoice_id] - Id of the invoice that is being scaled
 * @property {string | null} [receipt_id] - Id of the receipt that is being scaled
 * @property {number} [value] - Integer representing the percentage of zoom
 */
/**
 * @typedef RecommendationPromptActioned
 * @property {string} action - action from the user on the prompt
 * @property {string} company_id - company id of the user
 * @property {string} source - where the prompt was visible (page)
 * @property {string} type - type of recommendation prompt either
 * @property {string} user_role - role of the user inside the company
 * @property {string} [vendor_name] - name of the vendor on which we get the recommendation prompt
 */
/**
 * @typedef ReconnectApp
 * @property {string} actor - The user role
 * @property {string} [applicationId] - The ID of the people app
 * @property {string} category - The category of the app
 * @property {string} [errorState] - The state of the error
 * @property {string} name - The name of the app
 * @property {string} type - The type of the app
 */
/**
 * @typedef RecurringVendorDetailsPageActioned
 * @property {string} action - user action details
 * @property {string} [ai_feedback_type] - the type of error the user is reporting for the vendor description (AI generated)
 * @property {string} [ai_feedback_value] - the user's free text containing the issue description.
 * @property {string} company_id - user's company_id
 * @property {string} [expense_id] - id of the expense clicked
 * @property {string} [filter_name] - name of the filter if the event is a filter interaction
 * @property {string} [value] - value of the filter applied
 * @property {string} [vendor_id] - vendor id
 * @property {string} [vendor_name] - name of the vendor from where the expense was clicked
 */
/**
 * @typedef RecurringVendorsPageActioned
 * @property {string} action - user action detail
 * @property {string} company_id - user's company_id
 * @property {string | null} [filter_name] - name of the filter if the event is a filter interaction
 * @property {string} [note] - free text of the note user set for a specific vendor contract
 * @property {string} [owner_user_id] - owner id of a specific vendor (set in management contract tab)
 * @property {string} [source] - source page where the recurring vendor details drawer was opened
 * @property {string} [usage_quantity] - quantity of usage for the defined type  (e.g. 300 licences included in the contract)
 * @property {string} [usage_type] - type of vendor usage limitation (e.g. licences, clicks, data volume etc...)
 * @property {string} [value] - value of the filter applied
 * @property {string} [vendor_name] - name of the vendor when user click to visit the details drawer
 */
/**
 * @typedef ReferralEmailActioned
 * @property {string} [action] -
 * @property {string} [origin] -
 */
/**
 * @typedef ReferralInviteActioned
 * @property {string} [origin] -
 */
/**
 * @typedef ReferralLinkActioned
 * @property {string} [action] -
 * @property {string} [origin] -
 */
/**
 * @typedef ReferralsHelpWidgetClicked
 * @property {string} [origin] -
 */
/**
 * @typedef ReimbursementArticleClicked
 * @property {string} article_name -
 * @property {string} source -
 */
/**
 * @typedef ReimbursementDeleted
 * @property {string} [expense_id] -
 * @property {string | null} [expense_type] -
 * @property {number | null} [export_page_version] -
 * @property {string} [export_status] -
 * @property {string} [page] -
 * @property {string} [role] -
 */
/**
 * @typedef ReimbursementOnboardingActioned
 * @property {string} action -
 * @property {string} [permission_type] -
 * @property {string} [plan_name] -
 * @property {string} source -
 * @property {string} step -
 */
/**
 * @typedef ReimbursementOnboardingCancelled
 * @property {string} plan_name -
 * @property {string} source -
 * @property {string} step -
 */
/**
 * @typedef ReimbursementPermissionTypeSet
 * @property {string} company_id -
 * @property {string} permission_type -
 * @property {string} [previous_permission_type] -
 * @property {string} source -
 */
/**
 * @typedef ReimbursementSettingsPageActioned
 * @property {string} action -
 * @property {string} source -
 */
/**
 * @typedef ReimbursementsProjectionWidget
 * @property {string} action -
 * @property {string} [currency] -
 * @property {number} [recommended_top_up_amount] -
 */
/**
 * @typedef RepayAccountActioned
 * @property {string} [action] -
 * @property {string} [previous_value] -
 * @property {string} [value] -
 */
/**
 * @typedef RepayActioned
 * @property {string} [action] -
 * @property {number} [amount] -
 * @property {string} [currency] -
 * @property {string} [current_method] -
 * @property {string} [direct_reimbursement_permission] -
 * @property {string} [failure_reason] -
 * @property {boolean} [has_employee_card_bank_account] -
 * @property {string} [method] -
 * @property {string} [new_method] -
 * @property {number} [number_expenses] -
 */
/**
 * @typedef RepayMethodsActioned
 * @property {string} [action] -
 * @property {string} [method] -
 */
/**
 * @typedef RepayOnboardingActioned
 * @property {string} [action] - the action taken by the admin
 * @property {number} [number_of_unreviewed_employees] - number of employees in the organization that are not currently part of a team with review rules
 * @property {string} [source] - where the modal was opened from
 * @property {string} [step] - the step in the flow where the event is triggered
 */
/**
 * @typedef RepayToBankAccountActioned
 * @property {string} [action] -
 * @property {string} [direct_reimbursement_permission] -
 * @property {string} [error_field] -
 * @property {string} [failure_reason] -
 * @property {boolean} [save_bank_account_details] -
 */
/**
 * @typedef RepayToCardActioned
 * @property {string} [action] -
 * @property {string} [direct_reimbursement_permission] -
 * @property {string} [failure_reason] -
 * @property {boolean} [save_bank_account_details] -
 */
/**
 * @typedef ReportsLinkInExportActioned
 * @property {string} [export_view] - View user was on when Reports link clicked
 */
/**
 * @typedef RequestNavigationActioned
 * @property {string} [destination] - Requests destination, 'requests panel'
 * @property {string} [pricing_plan] - Company Pricing Plan
 * @property {string} [source] - Where is user coming from
 */
/**
 * @typedef RequestPasscodeResetLink
 * @property {string} [source] - From which screen did the user request a passcode reset link
 */
/**
 * @typedef ReserveAccordionItemActioned
 * @property {string} [action] -
 * @property {string} [status] -
 */
/**
 * @typedef ReserveTestPreFddPageActioned
 * @property {string} [action] -
 * @property {string} [status] -
 */
/**
 * @typedef ResetPasscodeButtonClicked
 * @property {string} [actorRole] - What is the role of the user performing the action
 */
/**
 * @typedef ReviewActioned
 * @property {string} [expense_id] - id of the expense which have been reviewed
 * @property {string} [modifier] - Role of the user who engaged with the review (e.g.  "expense_owner", "reviewer", "owner")
 * @property {any | null} [note] - Note which the reviewer made
 * @property {string} [status] - Status that the reviewer marked the expense at (e.g. WAITING_FOR_EXPENSE_OWNER, OK, NOT_OK, WAITING_FOR_REVIEWER)
 */
/**
 * @typedef ReviewCompleted
 * @property {string} [actor_role] - Role of the user who engaged with the review (e.g.  "bookkeeper", "owner")
 * @property {string} [expense_id] - id of the expense which have been reviewed
 * @property {string} [expense_type] - Type of expense (e.g. "Pocket", "Card")
 * @property {number | null} [export_page_version] -
 * @property {string} [review_initiator] - Review type
 * @property {any | null} [review_message] - Note which the reviewer made
 * @property {string} [review_status] - Status that the reviewer marked the expense at (e.g. WAITING_FOR_EXPENSE_OWNER, OK, NOT_OK, WAITING_FOR_REVIEWER)
 * @property {any[]} [review_tag] - Tag which the reviewer added
 * @property {string} [source] - Source page
 */
/**
 * @typedef ReviewOverviewClickthrough
 * @property {string} [actor_role] - Role of actor
 * @property {string} [review_type] - Type or review the user selected
 */
/**
 * @typedef ReviewOverviewViewed
 * @property {string} [actor_role] - • Role of actor
 */
/**
 * @typedef ReviewSettingsUpdated
 * @property {string} [action] - Describes which action the user performed that resulted in an update (e.g. 'card_threshold_changed', 'pocket_threshold_changed', 'threshold_changed', 'reviewer_added', 'reviewer_removed')
 * @property {string} [company_id] - Pleo company_id
 * @property {string} [currency] - Currency of the threshold
 * @property {number | null} [new_max_value] - New maximum threshold value
 * @property {number | null} [new_value] - New (minimum) threshold value
 * @property {number | null} [previous_max_value] - Previous maximum threshold value
 * @property {number | null} [previous_value] - Previous (minimum) threshold value
 * @property {string} [team_id] - Team that got their review rules updated
 */
/**
 * @typedef ReviewTabActioned
 * @property {string} action -
 * @property {string} actor_role -
 * @property {number} count -
 * @property {string} source -
 * @property {string} tab -
 */
/**
 * @typedef ReviewerThresholdUpdateCompleted
 * @property {string} [actor_role] - Role of actor
 * @property {string} [review_type] - Expense review type:  ‘Card Expenses’ or ‘Pocket Expenses’
 * @property {number} [value] - Threshold value
 */
/**
 * @typedef ReviewerThresholdUpdateStarted
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef ReviewersTabReviewCtaClicked
 * @property {string} company_id - Company ID
 * @property {string} source - The source in the app where the reviewers tab upsell is viewed from.
 * @property {string} user_id - User ID
 */
/**
 * @typedef ReviewersTabReviewCtaViewed
 * @property {string} company_id - Company ID
 * @property {string} source - The source in the app where the reviewers tab upsell is viewed from.
 * @property {string} user_id - User ID
 */
/**
 * @typedef ReviewersTabViewed
 * @property {string} company_id - Company ID
 * @property {string} source - The source in the app where the reviewers tab is viewed from.
 * @property {string} user_id - User ID
 */
/**
 * @typedef RoleFilterCompleted
 * @property {string} [actor_role] - Role of actor
 * @property {string} [value] - Role filtered by
 */
/**
 * @typedef RoleFilterStarted
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef SamlSsoAddExclusionClicked
 * @property {string} actor - The user role
 * @property {number} addedExclusionsCount - Count of exclusions user wants to add
 * @property {string} [applicationId] - The ID of the SAML SSO app
 * @property {number} existingExclusionsCount - Count of exclusions that already exists
 * @property {string} [name] - The name of the app
 */
/**
 * @typedef SamlSsoDeactivateClicked
 * @property {string} actor - The user role
 * @property {string} [applicationId] - The ID of the SAML SSO app
 * @property {string} [name] - The name of the app
 */
/**
 * @typedef SamlSsoEnableClicked
 * @property {string} actor - The user role
 * @property {string} [applicationId] - The ID of the SAML SSO app
 * @property {boolean} enabled - Whether user enabled config
 * @property {string} [name] - The name of the app
 */
/**
 * @typedef SamlSsoIdPClicked
 * @property {string} actor - The user role
 * @property {string} [applicationId] - The ID of the SAML SSO app
 * @property {boolean} isEntitled - Is user entitled to use SAML SSO feature
 * @property {string} [name] - The name of the app
 * @property {string} [selectedApplicationId] - The ID of the selected SAML SSO app
 * @property {string} [selectedName] - The name of the selected app
 */
/**
 * @typedef SamlSsoIdPTabVisited
 * @property {string} actor - The user role
 * @property {string} [applicationId] - The ID of the SAML SSO app
 * @property {boolean} isEntitled - Is user entitled to use SAML SSO feature
 * @property {string} [name] - The name of the app
 * @property {string} [selectedApplicationId] - The ID of the selected SAML SSO app
 * @property {string} [selectedName] - The name of the selected app
 * @property {string} tab - The name of the tab
 */
/**
 * @typedef SamlSsoRemoveExclusionClicked
 * @property {string} actor - The user role
 * @property {string} [applicationId] - The ID of the SAML SSO app
 * @property {number} existingExclusionsCount - Total count of exclusions before removal
 * @property {string} [name] - The name of the app
 */
/**
 * @typedef SamlSsoSaveConfigClicked
 * @property {string} action - Indicates type of user action with SAML SSO config
 * @property {string} actor - The user role
 * @property {string} [applicationId] - The ID of the SAML SSO app
 * @property {string} [name] - The name of the app
 */
/**
 * @typedef SamlSsoUpgradeClicked
 * @property {string} actor - The user role
 * @property {string} [applicationId] - The ID of the SAML SSO app
 * @property {string} [name] - The name of the app
 * @property {string} [selectedApplicationId] - The ID of the selected SAML SSO app
 * @property {string} [selectedName] - The name of the selected app
 * @property {string} source - The source page where user clicked on the button
 */
/**
 * @typedef SamlSsoVisited
 * @property {string} actor - The user role
 * @property {string} [applicationId] - The ID of the configured SAML SSO app
 * @property {boolean} isEntitled - Is user entitled to use SAML SSO feature
 * @property {string} [name] - The name of the configured app
 */
/**
 * @typedef ScaOnboardingActioned
 * @property {string} [role] - user role
 * @property {string} [step] - this would apply to all steps: 'welcome' | 'passcode' | 'phone' | 'phone_verify' (this is the last screen)
 */
/**
 * @typedef ScaOnboardingCompleted
 * @property {string} [role] - user role
 */
/**
 * @typedef ScaOnboardingSkipped
 * @property {string} [role] - user role
 * @property {Record<string, any>} [step] - this would apply to all steps: 'welcome' | 'passcode' | 'phone' | 'phone_verify' (this is the last screen)
 */
/**
 * @typedef SearchBarActioned
 * @property {string} action - the action can be either : opened, closed or searched
 * @property {string} [company_id] -
 * @property {string} page - the page in which the search bar is actioned
 * @property {string} [value] - the value the user typed in the searchbar
 */
/**
 * @typedef SeeAllAvailableIntegrationsClicked
 * @property {string} actor - The user role
 * @property {string} screen - The screen where the link was clicked
 */
/**
 * @typedef SelectAllClicked
 * @property {string} source - Where this action was taken within the app

 * @property {string} step - The step within the user adding wizard
 */
/**
 * @typedef SelectAllExpensesActioned
 * @property {string} [action] - Action can be either select or deselect all expenses
 * @property {string} [actor_role] - Role of actor
 * @property {number} [expenses] - Number of selected expenses
 */
/**
 * @typedef SelectApp
 * @property {string} actor - The user role
 * @property {string} applicationId - The ID of the people app
 * @property {string} category - The category of the app
 * @property {string} name - The name of the app
 * @property {string} screen - The screen in which the integration was selected
 * @property {string} type - The type of the app
 */
/**
 * @typedef SelectCompanyBusinessType
 * @property {string} [business_type] - Business type selected
 * @property {string} [locale] - Web language
 * @property {string} [origin] - Full URL
 * @property {string} [path] - Page name (partial URL)
 */
/**
 * @typedef SelfExpansionCountrySelectAbandoned
 * @property {string} companyId - The companyId of the user
 * @property {boolean} hasOrgAccess - Whether user has access to an organization, false if single entity admin
 * @property {string} userId - The userId of the user
 */
/**
 * @typedef SelfExpansionHelpArticleClicked
 * @property {string} companyId - The companyId of the user who clicked the help article
 * @property {boolean} hasOrgAccess - Whether user has access to an organization, false if single entity admin
 * @property {string} userId - The userId of who clicked the help article
 */
/**
 * @typedef SelfExpansionInfoVideoViewed
 * @property {string} companyId - The companyId of the user
 * @property {boolean} hasOrgAccess - Whether user has access to an organization, false if single entity admin
 * @property {string} userId - The userId of the user
 */
/**
 * @typedef SelfExpansionModalClosed
 * @property {boolean} hasOrgAccess - Whether user has access to an organization, false if single entity admin
 * @property {string} step - What step of the modal did the user drop off
 */
/**
 * @typedef SelfExpansionModalOpened
 * @property {boolean} hasOrgAccess - Whether user has access to an organization, false if single entity admin
 * @property {string} source - From which button did the user open the modal. Options: add account modal, the plus icon within the entity switcher panel, or new entity card visible to single entity admins
 */
/**
 * @typedef SelfExpansionMultiEntityPricingPlansClicked
 * @property {string} companyId - The companyId of the user who clicked the pricing plans
 * @property {boolean} hasOrgAccess - Whether user has access to an organization, false if single entity admin
 * @property {string} userId - The userId of who clicked the pricing plans
 */
/**
 * @typedef SelfExpansionRequestSent
 * @property {boolean} addNewEntities - True if the user requests for entities not using pleo to be added
 * @property {any[]} [country] - Country where the new entity is located, if addNewEntities is true
 * @property {boolean} groupExistingEntities - True if the user requests for entities already using pleo to be grouped
 * @property {boolean} hasOrgAccess - Whether user has access to an organization, false if single entity admin
 */
/**
 * @typedef SelfExpansionSignupAddEntityStepActioned
 * @property {string} action - which action is performed on the add entity step
 * @property {string} [country] - Country of the company
 * @property {string} [trigger] -
 */
/**
 * @typedef SelfExpansionSignupOrganizationNameStepActioned
 * @property {string} action - Which step in setting the organization name is actioned
 */
/**
 * @typedef SelfExpansionSignupSuccessButtonClicked
 * @property {string} action - which button path was clicked, the pleo_guide or account_activation
 */
/**
 * @typedef SelfExpansionViewAllPricingPlansClicked
 * @property {string} companyId - The companyId of the user performing the action
 * @property {boolean} hasOrgAccess - Whether user has access to an organization, false if single entity admin
 * @property {string} userId - The userId of the user performing the action
 */
/**
 * @typedef SequentialReviewSettingToggled
 * @property {string} company_id - Company ID
 * @property {boolean} value - Value of the sequential review toggle
 */
/**
 * @typedef SequentialReviewUpsellViewed
 * @property {string} company_id - Company ID
 * @property {string} path - URL path from where the upsell modal is viewed
 */
/**
 * @typedef SetLimitsActioned
 * @property {string} [action] -
 * @property {number} [n_per_purchase_limit] -
 * @property {number} [n_total_limit] -
 * @property {string} [source] -
 */
/**
 * @typedef SetupAccountingCategoriesProductTour
 * @property {string} [accounting_system] -
 * @property {string} [action] -
 * @property {string} [step] -
 */
/**
 * @typedef SetupAccountingCompleted
 * @property {string} action - cta action of the modal
 */
/**
 * @typedef SetupAccountingConfirmationModalActioned
 * @property {string} [accounting_system] -
 * @property {string} [action] -
 * @property {string} [source] -
 * @property {string} [step] -
 */
/**
 * @typedef SetupAccountingPersonaActioned
 * @property {string} [access] - When the action `access_selected` is fired
 * @property {string} [action] - Actions based on the progress of the invite flow
 * @property {string} [persona] - When the action `persona_selected` is fired
 */
/**
 * @typedef SetupAccountingSettingsActioned
 * @property {string} [accounting_system] - Name of the selected accounting system
 * @property {string} [action] - States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
 * @property {boolean} [contra_account_no] - Boolean, true if a contra account was added
 * @property {string} [locale] - Web language
 * @property {string} [origin] - Full URL
 * @property {string} [path] - Page name (partial URL)
 * @property {boolean} [tax_code] - Boolean, true if a tax code was added
 */
/**
 * @typedef SetupAccountingStarted
 * @property {string} [cta] - User clicks the CTA either on the product card or the milestone card of the Get Started Guide
 */
/**
 * @typedef SignInNoAccount
 * @property {string} action -
 */
/**
 * @typedef SignUpCompanySignUp
 * @property {string} action -
 */
/**
 * @typedef SignUpNeedAnInvite
 * @property {string} action -
 * @property {boolean} [isMultiEntity] -
 */
/**
 * @typedef SignupActioned
 * @property {string} action - States the action which was performed when the events were triggered
 * @property {string} [flow] - The flow from which the event was triggered
 */
/**
 * @typedef SignupBillingCompleted
 * @property {boolean} [created_card] - false if an error happens when creating virtual cards and linking them for billing
 */
/**
 * @typedef SignupCompanyCompleted
 * @property {string} [country_code] - Abbreviation indicating which country the company is from
 */
/**
 * @typedef SignupCountryUpdated
 * @property {string} [country_code] - Abbreviation indicating which country the company is from
 */
/**
 * @typedef SignupFunnelSignalEvent
 * @property {string} [ad_personalization] - Gtag ad_personalization consent
 * @property {string} [ad_storage] - Gtag ad_user_data consent
 * @property {string} [ad_user_data] - Gtag ad_user_data consent
 * @property {string} [analytics_storage] - Gtag analytics_storage consent
 * @property {string} [company_size] - The size of the company selected during the signup flow
 * @property {string} [conversion_flow] -
 * @property {string} [country] - The inferred user's country or the country of the company if the user reached the company search step
 * @property {string} [currency] - Currency of the conversion value attributed to the user
 * @property {string} [email] - The user's email entered during the signup flow
 * @property {string} [fbc] - Meta ad fbclid
 * @property {string} [fbp] - Meta pixel unique identifier
 * @property {string} [first_name] - The user's first name entered during the signup flow
 * @property {string} [full_name] - The user's first and last name entered during the signup flow
 * @property {string} [gclid] - Google click identifier
 * @property {boolean} [google_optimise] -
 * @property {boolean} [is_generic_email] - Whether or not the email domain is considered generic based on Pleo's definition
 * @property {string} [last_name] - The user's last name entered during the signup flow
 * @property {boolean} [meta_optimise] -
 * @property {string} [milestone] - Furthest signup flow milestone reached by the user
 * @property {boolean} [optimise] -
 * @property {boolean} [optimise_test1] -
 * @property {boolean} [optimise_test2] -
 * @property {boolean} [optimise_test3] -
 * @property {string} [phone] - The user's phone number entered during the signup flow
 * @property {string} [ttcid] - TikTok pixel unique identifier
 * @property {number} [value] - Conversion value attributed to the user
 */
/**
 * @typedef SignupStepOpened
 * @property {boolean} [admin_verification_done] - 'true' when the step is finished on the moment of the event, 'false' when the step is not finished on the moment of event
 * @property {boolean} [admin_verification_visible] - 'true' when the step is visible on the moment of the event, 'false' when the step is not visible on the moment of event
 * @property {boolean} [billing_done] - 'true' when the step is finished on the moment of the event, 'false' when the step is not finished on the moment of event
 * @property {boolean} [billing_visible] - 'true' when the step is visible on the moment of the event, 'false' when the step is not visible on the moment of event
 * @property {boolean} [company_done] - 'true' when the step is finished on the moment of the event, 'false' when the step is not finished on the moment of event
 * @property {boolean} [company_visible] - 'true' when the step is visible on the moment of the event, 'false' when the step is not visible on the moment of event
 * @property {boolean} [manual] - Boolean, 'true' when the user finishes the screen by clicking on the button, 'false' when this event is triggered automatically when it was skipped
 * @property {string} [step_name] - The name of the finished step
 * @property {boolean} [terms_done] - 'true' when the step is finished on the moment of the event, 'false' when the step is not finished on the moment of event
 * @property {boolean} [terms_visible] - 'true' when the step is visible on the moment of the event, 'false' when the step is not visible on the moment of event
 * @property {boolean} [user_done] - 'true' when the step is finished on the moment of the event, 'false' when the step is not finished on the moment of event
 * @property {boolean} [user_info_done] - 'true' when the step is finished on the moment of the event, 'false' when the step is not finished on the moment of event
 * @property {boolean} [user_info_visible] - 'true' when the step is visible on the moment of the event, 'false' when the step is not visible on the moment of event
 * @property {boolean} [user_passcode_done] - 'true' when the step is finished on the moment of the event, 'false' when the step is not finished on the moment of event
 * @property {boolean} [user_passcode_visible] - 'true' when the step is visible on the moment of the event, 'false' when the step is not visible on the moment of event
 * @property {boolean} [user_verification_done] - 'true' when the step is finished on the moment of the event, 'false' when the step is not finished on the moment of event
 * @property {boolean} [user_verification_visible] - 'true' when the step is visible on the moment of the event, 'false' when the step is not visible on the moment of event
 * @property {boolean} [user_visible] - 'true' when the step is visible on the moment of the event, 'false' when the step is not visible on the moment of event
 */
/**
 * @typedef SignupSubmitted
 * @property {boolean} [created_card] - The billing card is created
 */
/**
 * @typedef SignupSucceeded
 * @property {boolean} [admin_verification_successful] - used in revamped signup flow
 * @property {boolean} [auto_sdd] - used in revamped signup flow
 */
/**
 * @typedef SignupSuccessButtonClicked
 * @property {string} [button] - shows which option the user chose, pleo guide or account activation flow
 * @property {string} [flow] - The flow from which the event was triggered
 */
/**
 * @typedef SpendActivation
 * @property {string} action -
 * @property {boolean} [company_has_vendor_cards] -
 * @property {string} source -
 */
/**
 * @typedef SpendActivationSetup
 * @property {string} [action] - Type of user action (e.g. viewed)
 * @property {string} [admin_id] - Id of the admin
 * @property {string} [call_to_action] - What CTA was clicked (e.g. "start_setup")
 * @property {string} [company_id] -
 * @property {string} component - One of the three components in the flow
 * @property {string} [employee_id_emailed] - Id of the employee who the admin triggers an email for
 * @property {string} [org_id] -
 * @property {string} [step] - What step in the flow
 */
/**
 * @typedef SpendActivationTemplates
 * @property {string} [action] - What type of action did the user make, e.g. 'viewed', 'clicked'
 * @property {string} [call_to_action] - Which CTA was interacted with, e.g. 'set-up-card'
 * @property {string} [component] - Which component is being interacted with
 * @property {boolean} has_recurring_spend - Does the customer have recurring spend?
 * @property {boolean} [has_vendor_cards] - Does the customer have vendor cards?
 * @property {string} [route] - Current route of the user, e.g. /vendor-cards
 * @property {string} [vendor_selected] - Name of the vendor box that was clicked, e.g. 'Amazon'
 * @property {string} [vendor_type] - Which vendor box was clicked, 'recommended | other'
 */
/**
 * @typedef DataItem
 * @property {string} key - A named identifier for the value, for example the entity reference
 * @property {string} [label] - Humanly readable value
 * @property {any | null} value - The actual value of the identifier and often unique
 */
/**
 * @typedef SpendGuidelinesActioned
 * @property {string} action - A user action
 * @property {string} [action_source] - The source of the action (i.e component)
 * @property {DataItem[]} [data] - Object to hold arbitrary data payload for an event
 * @property {string} source - The source sub-app of the event
 * @property {string} view - The current view
 */
/**
 * @typedef SpendGuidelinesTestQ1
 * @property {boolean} [collaboration] - If collaboration checkbox is selected
 * @property {boolean} [communication] - If communication checkbox is selected
 * @property {boolean} [insight] - If insight checkbox is selected
 * @property {boolean} [writing] - If writing checkbox is selected
 */
/**
 * @typedef SpendGuidelinesTestQ2Q4
 * @property {string | null} [q2] - Selected answer for question 2
 * @property {string | null} [q3] - Selected answer for question 3
 * @property {boolean} [q4] - Interested in testing new feature
 */
/**
 * @typedef DataItem1
 * @property {string} key - A named identifier for the value, for example the entity reference
 * @property {any | null} value - The actual value of the identifier and often unique
 */
/**
 * @typedef SpendGuidelinesViewed
 * @property {DataItem1[]} [data] - Object to hold arbitrary data payload for an event
 * @property {string} source - The source sub-app of the event
 * @property {string} view - The current view
 */
/**
 * @typedef SpendingLimitSettingsActioned
 * @property {string} [company_id] - System-wide company_id
 * @property {string} [currency] - Limit currency
 * @property {string} [employee_id] - System-wide employee_id of the employee who had their limit settings actioned
 * @property {boolean} [is_default] - is true, if the spending limits are updated on the settings/general/invites page. is false, if the spending limits are updated on the specific user /people
 * @property {string} [limit_type] - MONTHLY, PER_PURCHASE 
 * @property {string} [locale] - 
 * @property {string} [new_group] - no_limit, monthly, total
 * @property {number} [new_value] - updated new value
 * @property {string} [origin] - 
 * @property {string} [path] - 
 * @property {string} [previous_group] - no_limit, monthly, total
 * @property {number} [previous_value] - previous limit value
 * @property {string} [setting] - spending_group: spending limit, per person (no limit, monthly, total) is updated
spending_value: spending limit, per person value is updated
per_purchase_value : limit per purchase is updated
 */
/**
 * @typedef SplitExpenseActioned
 * @property {number | null} [current_line_count] - Number of lines the expense have when the action is performed
 * @property {string | null} [expense_type] - Type of expense where split action is happend
 * @property {string | null} [field] - Field on which the action happened
 * @property {boolean | null} [is_split_automated] - Says whether the split is done manual or OCR automated
 */
/**
 * @typedef StartSpendingPageActioned
 * @property {string} [action] -
 * @property {string} [from_page] -
 * @property {string} [status] -
 */
/**
 * @typedef StatementDownloadActioned
 * @property {string} [action] - Whether it was requested, downloaded, or cancelled by the user.
 * @property {string} [expense_id] - ID of the expense
 * @property {string} [type] - Statement type
 */
/**
 * @typedef StatementDownloadError
 * @property {string} [error_message] - Error message
 * @property {string} [expense_id] - Expense ID
 * @property {string} [type] - Statement type
 */
/**
 * @typedef StepCompletedAppcues
 * @property {string} [flowId] -
 * @property {string} [flowName] -
 * @property {string} [flowType] -
 * @property {number} [flowVersion] -
 * @property {string} [stepId] -
 * @property {number} [stepNumber] -
 * @property {string} [stepType] -
 */
/**
 * @typedef ResponseItem1
 * @property {string} [fieldId] -
 * @property {boolean} [fieldRequired] -
 * @property {any | null} [fieldType] -
 * @property {string} [label] -
 * @property {string} [value] -
 */
/**
 * @typedef Interaction2
 * @property {string} [destination] -
 * @property {any | null} [formId] -
 * @property {ResponseItem1[]} [response] -
 * @property {string} [text] -
 */
/**
 * @typedef StepInteractedAppcues
 * @property {string} [flowId] -
 * @property {string} [flowName] -
 * @property {string} [flowType] -
 * @property {number} [flowVersion] -
 * @property {Interaction2} [interaction] -
 * @property {string} [interactionType] -
 * @property {string} [stepChildId] -
 * @property {number} [stepChildNumber] -
 * @property {string} [stepId] -
 * @property {number} [stepNumber] -
 * @property {string} [stepType] -
 */
/**
 * @typedef StepStartedAppcues
 * @property {string} [flowId] -
 * @property {string} [flowName] -
 * @property {string} [flowType] -
 * @property {number} [flowVersion] -
 * @property {string} [stepChildId] -
 * @property {string} [stepChildNumber] -
 * @property {string} [stepId] -
 * @property {number} [stepNumber] -
 * @property {string} [stepType] -
 */
/**
 * @typedef StoreboxIntegrationActioned
 * @property {string} [action] - Action details
 * @property {string} [entity] - Admin or employee storebox integration
 * @property {string} [flow] - Enable or disable
 */
/**
 * @typedef StoreboxOnboardingActioned
 * @property {string} action - Action
 * @property {string} step - Modal step on which action take place
 */
/**
 * @typedef SubWalletCreation
 * @property {string} action -
 * @property {string} [action_status] - Is used for Creation Response action
 * @property {any[]} [assigned_card_ids] - List of IDs of all assigned cards during creation
 * @property {number} [available_balance] - Current amount on the Main Wallet
 * @property {string} [currency] -
 * @property {boolean} [has_attaching_cards_error] - Is used for Creation Response action, in case of "half"-success
 * @property {boolean} [has_moving_funds_error] - Is used for Creation Response action, in case of "half"-success
 * @property {string} [source] - The source/page from where the event has been triggered
 * @property {string} [sub_wallet_id] -
 * @property {number} [sub_wallet_initial_amount] - Initial amount of the sub-wallet to create
 * @property {string} [sub_wallet_name] - Name of the sub-wallet to create
 * @property {number} [sub_wallets_count] - Number of already created active sub-wallets
 */
/**
 * @typedef SubWalletActioned
 * @property {string} action -
 * @property {string} [auto_top_up_status] - Status of the Sub-wallet's Auto Top-up settings
 * @property {boolean} [has_main_wallet_auto_top_up_enabled] -
 * @property {string} sub_wallet_id -
 */
/**
 * @typedef SubscriptionExpenseClicked
 * @property {string} [company_id] - The company Id of the user
 * @property {string} subscription_id - The subscription Id clicked
 */
/**
 * @typedef SubscriptionExpenseDetailClicked
 * @property {string} [company_id] - The company Id of the user
 * @property {string} expense_id - the specific expense Id belonging to this subscription
 * @property {string} subscription_id - The subscription Id for this expense
 */
/**
 * @typedef SubscriptionFilterActioned
 * @property {string} action - action can be either applied or cleared
 * @property {string} [company_id] - The company Id of the user
 * @property {string} filter_name - filters are : time, team or user
 * @property {string | null} value - filter value or null if the filter is cleared
 */
/**
 * @typedef SubscriptionsEmptyStateActioned
 * @property {string} action - the action can be either contact_support or full_history_filter
 */
/**
 * @typedef SubscriptionsOnboardingBannerActioned
 * @property {string} action - User action on banner, this can be either  dismissal or help_article
 */
/**
 * @typedef Bill10
 * @property {boolean} [international_payment] - Bill invoice with international payment
 * @property {string} [status] - Bill invoice status
 */
/**
 * @typedef SupplierDetailsUpdated
 * @property {Bill10} [bill] - Bill invoice information
 * @property {string} field - Name of the field that has been changed
 */
/**
 * @typedef Bill11
 * @property {boolean} [international_payment] - Bill invoice with international payment
 * @property {string} [status] - Bill invoice status
 */
/**
 * @typedef SupplierFormActioned
 * @property {string} action - The user has interacted with the form
 * @property {Bill11} [bill] - Bill invoice information
 * @property {boolean} [cancel_confirmed] - The user confirms or dismisses canceling the supplier form
 * @property {string} [cancel_method] - How does the user exit the form
 */
/**
 * @typedef SwitchAccount
 * @property {number} numberOfActiveAccounts - Number of active account on the device
 */
/**
 * @typedef SwitchAccountClicked
 * @property {number} numberOfActiveAccounts - Number of active accounts on the device
 */
/**
 * @typedef SwitchEntity
 * @property {string} [currentViewingEntityId] - The user's current entity
 * @property {string} [entityId] - The user's desired entity to switch to
 * @property {boolean} isSpendingEntity - Is the target entity the user's spending entity
 * @property {boolean} isSwitchingEnabled - Is the user trying to switch to the same entity or an entity switch is already in progress
 * @property {number} numberOfActiveAccounts - The number of active logged in accounts on the the device
 * @property {number} numberOfEntities - The number of entities the user has access to
 * @property {number} [numberOfExpensesInQueue] - The number of expenses in the entity a user wants to switch to
 * @property {number} [numberOfMissingReceipts] - The number of missing receipts in the entity a user want to switch to
 * @property {string} source - Where is the entity switching coming from
 * @property {string} type - The type of entity switcher card the user is viewing
 */
/**
 * @typedef SystemNotCompatibleWithPocket
 * @property {string} [action] -
 * @property {string} [step] -
 * @property {string} [system_name] -
 */
/**
 * @typedef TagBudgetClosedEarly
 * @property {string} [actor_role] - Role of actor
 * @property {string} [budget_id] - Unique identifier of budget
 * @property {string} [end_date] - New budget end date
 * @property {string} [source] - Where the action was triggered
 */
/**
 * @typedef TagBudgetCreationCompleted
 * @property {string} [actor_role] - Role of actor
 * @property {number} [budget_amount] - Upper limit of budget
 * @property {string} [budget_id] - Unique identifier of budget
 * @property {string} [budget_name] - Name of budget
 * @property {string} [end_date] - Date when budget closes
 * @property {any[]} [reviewer_ids] -  Id’s of reviewers tied to budget
 * @property {string} [start_date] - Date when budget begins
 * @property {any[]} [tag_ids] - Unique identifiers of tag group rows
 * @property {any[]} [tag_names] - Name of tags tied to budget
 */
/**
 * @typedef TagBudgetCreationStarted
 * @property {string} [actor_role] - Role of actor
 * @property {string} [field_name] - Input in which the user entered data first
 */
/**
 * @typedef TagBudgetCreationStepBackward
 * @property {string} [actor_role] - Role of actor
 * @property {string} [step_name] - Name of current Budget creation step
 */
/**
 * @typedef TagBudgetCreationStepForward
 * @property {string} [actor_role] - Role of actor
 * @property {string} [step_name] - Name of current Budget creation step
 */
/**
 * @typedef TagBudgetDiscard
 * @property {string} [action] - Action can be either started or completed
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef TagBudgetEdited
 * @property {string} [actor_role] - Role of actor
 * @property {number} [budget_amount] - Upper limit of budget
 * @property {string} [budget_id] - Unique identifier of budget
 * @property {string} [budget_name] - Name of budget
 * @property {string} [end_date] - Date when budget closes
 * @property {any[]} [reviewer_ids] -  Id’s of reviewers tied to budget
 * @property {string} [start_date] - Date when budget begins
 */
/**
 * @typedef TagBudgetFilterActioned
 * @property {string} [action] - Action can be either applied or cleared
 * @property {string} [actor_role] - Role of actor
 * @property {string} [filter_name] - Name of the actioned filter
 * @property {any | null} [filter_value] - Value of the actioned filter
 */
/**
 * @typedef TagBudgetOpened
 * @property {string} [actor_role] - User's role
 * @property {string} [budget_id] - Unique identifier of budget
 */
/**
 * @typedef TagBudgetUnarchivedTag
 * @property {string} [actor_role] - Role of actor
 * @property {string} [budget_id] - Unique identifier of budget
 * @property {string} [tag_id] - Unique identifier of tag
 */
/**
 * @typedef TagBudgetViewed
 * @property {string} [actor_role] - Role of actor
 * @property {string} [budget_id] - Unique identifier of budget
 */
/**
 * @typedef TagBudgetsExpenseDownloaded
 * @property {string} [actor_role] - Role of actor
 * @property {string} [budget_id] - Unique identifier of budget
 */
/**
 * @typedef TagBudgetsOverviewViewed
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef TagImportActioned
 * @property {string | null} [action] - Action performed
 * @property {string | null} [source] - spreadsheet or any ERP system
 * @property {string | null} [system] - ERP system integrated
 * @property {string | null} [type] - import or re-import
 */
/**
 * @typedef TagReviewFeatureDismissed
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef TagReviewFeatureRequested
 * @property {string} [actor_email] - Email of actor
 * @property {string} [actor_id] - ID of actor
 * @property {string} [actor_name] - Name of actor
 * @property {string} [actor_role] - Role of actor
 * @property {string} [company_id] - Company ID connected to actor
 * @property {string} [company_name] - Company Name connected to actor
 */
/**
 * @typedef TagReviewNavigatedToBudgets
 * @property {string} [actor_role] - Role of actor
 * @property {string} [tag_id] - ID of selected tag
 */
/**
 * @typedef TagReviewNavigatedToExpenses
 * @property {string} [actor_role] - Role of actor
 * @property {string} [tag_id] - ID of selected tag
 */
/**
 * @typedef TagReviewOnboarding
 * @property {string} action -
 * @property {string} [source] -
 */
/**
 * @typedef TagReviewRemoveSingleReviewer
 * @property {string} [actor_role] - Role of actor
 * @property {string} [reviewer_id] - ID of selected reviewer
 * @property {string} [tag_id] - ID of selected tag
 */
/**
 * @typedef TagReviewSettingsViewed
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef TagReviewTagActioned
 * @property {string} [actor_role] - Role of actor
 * @property {any[]} [reviewer_ids] - ID's of selected reviewers
 * @property {any[]} [tag_ids] - ID’s of selected tags
 */
/**
 * @typedef TagReviewUnassignedReviewerModal
 * @property {string} [action] - Started/Completed
 * @property {string} [actor_role] - Role of actor
 * @property {string} [reviewer_id] - ID of selected reviewer
 * @property {string} [tag_id] - ID of selected tag
 */
/**
 * @typedef TagSourceActioned
 * @property {string} action -
 * @property {string | null} [system] - ERP system
 */
/**
 * @typedef TaxCodeTableUpdated
 * @property {string} [field] - Column name
 * @property {string} [system] - Accounting system name
 */
/**
 * @typedef TeamAddReviewerCompleted
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef TeamAddReviewerStarted
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef TeamFilterCompleted
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef TeamFilterStarted
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef TeamMemberAdded
 * @property {string} [actor_role] - Role of actor
 * @property {string} [value] - Name of the team
 */
/**
 * @typedef TeamMemberRemoved
 * @property {string} [actor_role] - Role of actor
 * @property {string} [value] - Name of the team
 */
/**
 * @typedef TeamPageViewed
 * @property {string} [action_role] - Role of actor
 */
/**
 * @typedef TeamReviewerRemoved
 * @property {string} [actor_role] - Role of actor
 * @property {string} [value] - Name of the team
 */
/**
 * @typedef TeamSearched
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef TemporaryLimitsActioned
 * @property {string} [action] - Action taken by the user
 * @property {string | null} [activation_date] - Date that temporary limit should start on
 * @property {string | null} [company_id] - Which company is performing the action
 * @property {string | null} [deactivation_date] - Date that temporary limit should end on
 * @property {string | null} [employee_id] - Which user is the action being performed on
 * @property {string} [limit_amount] - Amount selected for the temporary limit
 * @property {string} [limit_currency] - Currency of the temporary limit
 * @property {string} [limit_id] - Which limit is being interacted with
 * @property {string} [limit_type] - Type of temporary limit
 */
/**
 * @typedef TemporaryLimitsPopoverViewed
 * @property {string} [company_id] - Which company does the user belong to that saw the popover
 * @property {string | null} [employee_id] - Which employee was the popover shown to
 */
/**
 * @typedef TikTokAdsOptimisationSignal
 * @property {string} company_size - Size of the user company
 * @property {string} currency - Currency of the conversion value
 * @property {boolean} is_generic_email - If user email is generic
 * @property {string} [ttcid] - TikTok conversion id
 * @property {number} value - Value given by Pleo to the conversion
 */
/**
 * @typedef TodoListActioned
 * @property {string} source - Whether the user clicked the button in the filter bar or the large quick filter button
 */
/**
 * @typedef ToggleAccountsMenu
 * @property {string} variant - The variant of the switcher user is viewing
 */
/**
 * @typedef ToggleEntitySwitcher
 * @property {boolean} isSpendingEntity - Is this the user's spending entity
 * @property {string} [source] - The source of the toggle action
 * @property {string} state - State of entity panel
 * @property {string} [type] - The type of entity switcher panel served to the user
 * @property {string} variant - The variant of the switcher user is viewing
 */
/**
 * @typedef TransferDetailsActioned
 * @property {string} action -
 * @property {number} [amount] -
 * @property {string} [currency] -
 * @property {boolean} [domestic] -
 * @property {string} [error] -
 * @property {boolean} [suggested_amount] -
 * @property {string} [type] -
 */
/**
 * @typedef TreasuryWaitlist
 * @property {boolean} [liquidity] -
 * @property {boolean} [overview] -
 * @property {boolean} [treasury] -
 */
/**
 * @typedef TrialActivationBannerActioned
 * @property {string} [action] - Action performed by the user
 * @property {string} [plan] -
 */
/**
 * @typedef TryCardDoneActioned
 * @property {string} [action] - States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
 * @property {string} [path] - Page/Url name
 */
/**
 * @typedef TurboGeneratorActionsTriggered
 * @property {string} actionName - Name of the actioned CLI event.
 * @property {string} isoDate - Date time of actioned event, ISO_8601.
 * @property {number} timestamp - Unix timestamp of actioned event.
 */
/**
 * @typedef TurboGeneratorFailed
 * @property {string} actionName - Name of the actioned CLI event.
 * @property {string} isoDate - Date time of actioned event, ISO_8601.
 * @property {number} timestamp - Unix timestamp of actioned event.
 */
/**
 * @typedef TurboGeneratorFinished
 * @property {string} actionName - Name of the actioned CLI event.
 * @property {string} isoDate - Date time of actioned event, ISO_8601.
 * @property {number} timestamp - Unix timestamp of actioned event.
 */
/**
 * @typedef TurboGeneratorStarted
 * @property {string} actionName - Name of the actioned CLI event.
 * @property {string} isoDate - Date time of actioned event, ISO_8601.
 * @property {number} timestamp - Unix timestamp of actioned event.
 */
/**
 * @typedef TwofactorReset
 * @property {string} [actor_role] - What is the role of the user performing the action
 */
/**
 * @typedef UboSelfVerificationActioned
 * @property {string} action -
 */
/**
 * @typedef UsWalletActioned
 * @property {string} action -
 * @property {number} [amount] -
 * @property {string} [currency] -
 * @property {string} [institutionId] -
 * @property {string} [screenName] - The name of the screen of the open Modal
 */
/**
 * @typedef UafAddMemberCompleted
 * @property {string} [actor_role] -
 * @property {string} [company_id] -
 * @property {number} [n_invites] -
 * @property {number} [n_invites_card] -
 * @property {number} [n_invites_cardless] -
 * @property {number} [n_invites_failed] -
 * @property {number} [n_invites_per_purchase_limit] -
 * @property {number} [n_invites_total_limit] -
 * @property {string} [source] -
 */
/**
 * @typedef Client7
 * @property {string | null} id - The id of the client.
 */
/**
 * @typedef UpdatePartnerClientNotificationsChannel
 * @property {string | null} channel - The selected notification channel.
 * @property {Client7 | null} client - Object containing information about the partner client.
 */
/**
 * @typedef Client8
 * @property {string | null} id - The id of the partner client.
 */
/**
 * @typedef UpdatePartnerClientNotificationsFrequency
 * @property {Client8 | null} client - Object containing information about the partner client.
 * @property {string | null} interval - The selected notification frequency.
 */
/**
 * @typedef UpgradeYourPlanViewed
 * @property {string} [company_id] -
 * @property {string} [page] - the page in which the widget is displayed
 */
/**
 * @typedef UploadDocumentActioned
 * @property {string} action -
 * @property {string} [method] -
 * @property {string} value -
 */
/**
 * @typedef UsageTypeActioned
 * @property {string} action - The action taken
 * @property {boolean} [is_select_all] - Whether or not all users are selected
 * @property {number} [n_invites_applied] - The number of invites the action applies to
 * @property {string} [previous_usage_type] - The previous usage type when changing a single record
 * @property {string} [source] - Where this action was taken within the app

 * @property {string} [usage_type] - The access type
 */
/**
 * @typedef UserExpensesStatus
 * @property {string} [company_id] - Company id
 * @property {string} [id] - User id
 * @property {any[] | null} [reviewer] - An array of ids of the teams reviewed by the user
 * @property {string} [roles] - Role of the user
 */
/**
 * @typedef UserFilterCompleted
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef UserFilterStarted
 * @property {string} [actor_role] - Role of actor
 */
/**
 * @typedef VendorCardActioned
 * @property {string | null} [account] - default accounting account ID value

 * @property {string} action - The action that triggered the event

 * @property {string} [card_id] - The id of the card

 * @property {number} [card_limit_amount] - The limit amount set on the card

 * @property {string} [card_limit_currency] - The company currency

 * @property {string} [card_limit_type] - The limit type of the card

 * @property {string} [card_name] - The name of the card

 * @property {string} [card_owner] - The id of the employee owning the card

 * @property {string} [company_id] - The id of the company owning the card

 * @property {string[] | null} [new_allowed_vendor_id] - The list of new allowed vendors ids

 * @property {string[] | null} [new_allowed_vendor_name] - The list of new allowed vendors name
 * @property {string | null} [new_value] - the new accounting default value set, the action will give the accounting type (team, account, tag or tax-code)

 * @property {number} [number_of_cards] - The number of dedicated cards owned by an employee

 * @property {string[] | null} [previous_allowed_vendor_id] - the list of previous allowed vendors ids

 * @property {number} [previous_card_limit_amount] - The card limit amount before editing

 * @property {string} [previous_card_limit_type] - The card limit type before editing

 * @property {string} [previous_card_owner] - the card owner Id before editing

 * @property {string | null} [previous_value] - the previous accounting default value set, the action will give the accounting type (team, account, tag or tax-code)

 * @property {string} [source] - The source/page from where the event has been triggered

 * @property {string} [sub_category] - Only used for accounting tags to gives the tag group value set

 * @property {number | null} [tags] - the number of default accounting Tags set on the card

 * @property {string | null} [team] - default accounting team ID value

 */
/**
 * @typedef VendorCardActivation
 * @property {string} [action] - The action taken by the user
 * @property {string} [company_segment] - The user's company size
 * @property {string} [is_multi_entity] - Is the company multi-entity
 * @property {string} [role] - The user's role
 * @property {string} [source] - Source of event
 */
/**
 * @typedef VendorCardCalloutActioned
 * @property {string} action - The action taken by the user
 * @property {string} cardId - The ID of the vendor card
 * @property {string} cardOwnerEmployeeId - The employee ID of the card owner
 * @property {string} type - Which type of callout is displayed
 * @property {string} userId - The user ID of the admin who sees the callout
 */
/**
 * @typedef VendorCardError
 * @property {string} company_id - the company ID that own the cards

 * @property {string} error_name - the reason for the error
 * @property {string} error_type - where the error happened

 */
/**
 * @typedef VendorCardModalActioned
 * @property {string} action - we have 2 types of action : viewed or CTA button clicked

 * @property {string} company_id - the company ID that own the cards

 */
/**
 * @typedef VendorCardPageActioned
 * @property {string} action - we have 3 types of actions: all cards viewed when the page load, CTA button to create a card clicked or direct link with...

 * @property {string} company_id - the company ID that own the cards

 * @property {string} [for_instance_text] - this property will only be added when the events come from the cards list empty page direct link with suggestions

 * @property {number} [number_of_active_cards] - the number of actual active cards when the page load

 * @property {number} [number_of_cards] - the number of actual cards when the page load

 * @property {number} [number_of_frozen_cards] - the number of actual forzen cards when the page load

 * @property {string} [source] - source is the actual page name where the list is displayed

 */
/**
 * @typedef VendorCardPageFilterActioned
 * @property {string} action - The action that triggered the event
 * @property {string} [company_id] - The company id
 * @property {string} [filter_name] - The name of the filter that triggered the event
 * @property {string} [value] - The value of the filter
 */
/**
 * @typedef VendorCardTransactionClicked
 * @property {string} [card_id] - The id of the card used for the expense

 * @property {string} [card_type] - The type of the card used for the expense

 * @property {string} expense_id - The id of the expense

 * @property {boolean} [locked] - Is the expense locked or not

 * @property {string} [review_status] - The review status of the expense

 * @property {string} [source] - The page/component from where the event was triggered

 * @property {string | null} [viewer] - viewer role
 */
/**
 * @typedef VerificationStepViewed
 * @property {string} [step] - Specific name of the step that was viewed.
 */
/**
 * @typedef VerifyCompanyActioned
 * @property {string} action - States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
 * @property {string} [cta] - User clicks the CTA either on the product card or the milestone card of the Get Started Guide
 * @property {boolean} [has_document_upload] - Boolean, true if documents where uploaded
 * @property {boolean} [has_national_id] - Boolean, true if a national id was provided
 */
/**
 * @typedef VerifyIdentityActioned
 * @property {string} [action] - States the action which was performed when the events with the suffix "Actioned". The property is primarily either "started" or "completed".
 * @property {string} [error] - optional property in case of `action: returnedWithError`
 * @property {string} [locale] - Web language
 * @property {string} [origin] - Full URL
 * @property {string} [path] - Page name (partial URL)
 * @property {string} [stage] - The stage of the Product in which the Admin Verifies themselves. We are running a split test for which this property is required. In the control, the admin will verify themselves normally during the signup flow, stage:signup. For the variant they can do it either in the FDD flow, or the Wallet Flow. Stages fdd/wallet respectively
 * @property {string} [type] - Type for verification provider
 * @property {string} [user_type] - User type
 */
/**
 * @typedef ViewExpenseDetailsActioned
 * @property {string} [integration] - Account/system integrated e.g., Xero
 * @property {string} [source] - Where the event is triggered
 */
/**
 * @typedef VismaEconomicInvoicesTourAccessed
 * @property {string} [company_id] -
 * @property {string} [user_id] -
 */
/**
 * @typedef VismaEconomicMigrationFlow
 * @property {string} [company_id] -
 * @property {string} [page_path] - Page source of where the action happened (especially desired for the "accessed" type
 * @property {string} type - Type of action
 * @property {string} [user_id] -
 */
/**
 * @typedef VismaEconomicMigrationInstructionsActioned
 * @property {string} [company_id] -
 * @property {string} [user_id] -
 */
/**
 * @typedef WalletActivitiesHistoryActioned
 * @property {string} [action] -
 */
/**
 * @typedef WalletAutoTopupModalViewed
 * @property {string} [company_id] -
 * @property {boolean} [is_onboarding_visible] -
 * @property {string} [user_email] -
 */
/**
 * @typedef WalletAutoTopupOnboardingBannerActioned
 * @property {string} [action] -
 */
/**
 * @typedef WalletBalanceStatementActioned
 * @property {string} [action] -
 */
/**
 * @typedef WalletBalanceStatementModalViewed
 * @property {string} [company_id] -
 * @property {string} [user_email] -
 */
/**
 * @typedef WalletBankTransferModalActioned
 * @property {string} [action] -
 */
/**
 * @typedef WalletDirectDebitSeActioned
 * @property {string} [action] -
 */
/**
 * @typedef WalletDirectDebitTopupModalViewed
 * @property {string} [company_id] -
 * @property {string} [user_email] -
 */
/**
 * @typedef WalletLowBalanceAlertActioned
 * @property {string} action - What action the user is making with the low balance alert modal
 * @property {number} [amount] - When we chose the manual type of alert configuration here we show the changes in balance.
 * @property {string} [type] - We have two options currently: "Automatically based on company spending patterns" and "When balance falls below" so we can have two options here: "automatic", "manual"
 */
/**
 * @typedef WalletLowBalanceAlertModalViewed
 * @property {string} [company_id] -
 * @property {string} [user_email] -
 */
/**
 * @typedef WalletManualAutoTopUpDirectDebitMandateEditActioned
 * @property {string} [action] -
 */
/**
 * @typedef WalletManualTopUpDirectDebitActioned
 * @property {string} [action] -
 * @property {number} [amount] -
 */
/**
 * @typedef WalletManualTopUpDirectDebitDeleteActioned
 * @property {string} [action] -
 */
/**
 * @typedef WalletOnboardingTopupViewed
 * @property {string} [company_id] -
 * @property {string} [user_email] -
 */
/**
 * @typedef WalletPageViewed
 * @property {string} [company_id] - The id of the company associated with the admin user.
 * @property {string} [user_email] -
 */
/**
 * @typedef WalletSetupAutoTopupMandateDe
 * @property {string} [action] -
 * @property {string} [bank] -
 */
/**
 * @typedef WalletSetupNetsActioned
 * @property {string} [action] -
 * @property {string} [bank] -
 */
/**
 * @typedef WalletTopUpActioned
 * @property {string} [action] -
 * @property {string} [topUpType] -
 */
/**
 * @typedef WalletTopUpHistoryActioned
 * @property {string} [action] -
 */
/**
 * @typedef WalletTopupHistoryModalViewed
 * @property {string} [company_id] -
 * @property {string} [user_email] -
 */
/**
 * @typedef WalletTopupMethodModalViewed
 * @property {string} [company_id] -
 * @property {string} [user_email] -
 */
/**
 * @typedef WalletTurnOnAutoTopupActioned
 * @property {string} [action] -
 * @property {boolean} [eligible_for_pleo_reserve] - Company is eligible for Pleo Reserve
 * @property {boolean} [enabled] -
 * @property {boolean} [is_direct_debit_enabled] -
 * @property {number} [threshold] - When wallet balance falls below this amount
 * @property {number} [top_up_amount] - Transfer this amount
 */
/**
 * @typedef WalletWithdrawalButtonActioned
 * @property {string} [action] -
 * @property {boolean} [isDisabled] -
 */
/**
 * @typedef WalletWithdrawalPageActioned
 * @property {string} [action] -
 * @property {number} [amount] - Withdrawal amount
 * @property {number} [balanceAfterWithdrawal] -
 * @property {string} [bankCurrency] -
 * @property {boolean} [isSuccessful] - Parameter for [Complete] event
 * @property {string} [screenName] - For [Show] event to understand which step of form is visible now
 * @property {string} [triggeredBy] - Name of the component that triggered some event
 * @property {string} [walletCurrency] -
 */
/**
 * @typedef WelcomePageViewed
 * @property {string} [locale] - Web language
 * @property {string} [origin] - Full URL
 * @property {string} [path] - Page name (partial URL)
 */
/**
 * @typedef XeroAccountingModuleActioned
 * @property {string} [action] - Which button user clicked on
 */
/**
 * @typedef XeroCategoriesBannerActioned
 * @property {string} action -
 */
/**
 * @typedef XeroCreateTagGroupActioned
 * @property {string} action -
 */
/**
 * @typedef XeroOnboardingModuleViewed
 * @property {string} [action] - Action the user took on the modal
 * @property {string} [source] - Source of the accounting system setup
 */
/**
 * @typedef XeroSetupPageActioned
 * @property {boolean} [accountingModuleActive] - Whether the Accounting Module was on the page when the tab clicked
 * @property {string} page - Which page the user navigated to
 */
/**
 * @typedef XeroTagsBannerActioned
 * @property {string} action -
 */
/**
 * @typedef XeroTagsPageActioned
 * @property {string} action -
 */

/**
 * Fires a 'AIDR Actioned' track call.
 *
 * @param {AidrActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function aidrActioned(
	props?: AidrActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'AIDR Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'AIS linked account actioned' track call.
 *
 * @param {AisLinkedAccountActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function aisLinkedAccountActioned(
	props: AisLinkedAccountActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'AIS linked account actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Invited user has entered personal details and pressed 'Continue'
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function acceptInviteSignupSucceeded(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Accept Invite Signup Succeeded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interactions in modal to add account
 *
 * @param {AccountSwitchModalActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountSwitchModalActioned(
	props?: AccountSwitchModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Account Switch Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views modal to add new account
 *
 * @param {AccountSwitchModalViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountSwitchModalViewed(
	props?: AccountSwitchModalViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Account Switch Modal Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User users toggles to another account from the navigation bar
 *
 * @param {AccountSwitchToggleActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountSwitchToggleActioned(
	props?: AccountSwitchToggleActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Account Switch Toggle Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Reviewers for an accounting entry has been manually edited (added or removed a reviewer)
 *
 * @param {AccountingEntryReviewersManuallyAddedOrRemoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountingEntryReviewersManuallyAddedOrRemoved(
	props: AccountingEntryReviewersManuallyAddedOrRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Accounting Entry Reviewers Manually Added or Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user clicks "Next" on one of the steps when configuring the accounting on /pleo-guide/setup-accounting. Track steps completion around accounting setup
 *
 * @param {AccountingSetupCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountingSetupCompleted(
	props?: AccountingSetupCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Accounting Setup Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on the link to invite a bookkeeper on /pleo-guide/setup-accounting
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountingSetupInviteBookkeeperOpened(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Accounting Setup Invite Bookkeeper Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on the See all link on /pleo-guide/setup-accounting. Did the user find the top 4 accounting system that we show or did they need to click.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountingSetupSeeAllActioned(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Accounting Setup See All Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views a step in the Accounting setup wizard on /pleo-guide/setup-accounting
 *
 * @param {AccountingSetupStepViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountingSetupStepViewed(
	props?: AccountingSetupStepViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Accounting Setup Step Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on "Complete" on the last step of setting up accounting on /pleo-guide/setup-accounting. User has completed setting up accounting from the Pleo Guide
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountingSetupSucceeded(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Accounting Setup Succeeded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects a specific accounting system on /settings/accounting/setup
 *
 * @param {AccountingSystemClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountingSystemClicked(
	props?: AccountingSystemClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Accounting System Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User connects an accounting system
 *
 * @param {AccountingSystemConnected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountingSystemConnected(
	props?: AccountingSystemConnected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Accounting System Connected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User disconnects an accounting system
 *
 * @param {AccountingSystemDisconnected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountingSystemDisconnected(
	props?: AccountingSystemDisconnected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Accounting System Disconnected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects or makes any update to a specific accounting system on /pleo-guide/setup-accounting
 *
 * @param {AccountingSystemUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountingSystemUpdated(
	props?: AccountingSystemUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Accounting System Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Active Overdraft Actioned' track call.
 *
 * @param {ActiveOverdraftActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function activeOverdraftActioned(
	props: ActiveOverdraftActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Active Overdraft Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Activity tab in the expense tab of the export page visited
 *
 * @param {ActivityTabActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function activityTabActioned(
	props?: ActivityTabActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Activity Tab Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on the add account menu item
 *
 * @param {AddAccountClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addAccountClicked(
	props: AddAccountClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Add Account Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks to add an entity
 *
 * @param {AddEntity} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addEntity(
	props: AddEntity,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Add Entity', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User clicks on the "Add expense" button on the expense page
 *
 * @param {AddExpensesActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addExpensesActioned(
	props?: AddExpensesActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Add Expenses Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fired on pleo-guide when users click through "add funds" card flow
 *
 * @param {AddFundsActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addFundsActioned(
	props: AddFundsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Add Funds Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user enters their first wallet load flow through the Pleo Guide, this event is triggered whenever they select the amount to load
 *
 * @param {AddFundsAmountActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addFundsAmountActioned(
	props?: AddFundsAmountActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Add Funds Amount Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Actions taken within the user adding flow
 *
 * @param {AddMemberActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMemberActioned(
	props: AddMemberActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Add Member Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user opens the Add Many member modal
 *
 * @param {AddMemberAddManyActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMemberAddManyActioned(
	props?: AddMemberAddManyActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Add Member Add Many Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user adds member through the Add Many member modal
 *
 * @param {AddMemberAddManyCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMemberAddManyCompleted(
	props?: AddMemberAddManyCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Add Member Add Many Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks 'Done' in the Add member pop-up. The user has hereby invited one or more members
 *
 * @param {AddMemberCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMemberCompleted(
	props?: AddMemberCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Add Member Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks 'Add member'
 *
 * @param {AddMemberStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMemberStarted(
	props?: AddMemberStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Add Member Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The warning banner is displayed when adding users
 *
 * @param {AddMemberWarningViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMemberWarningViewed(
	props?: AddMemberWarningViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Add Member Warning Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with the create per diem flow
 *
 * @param {AddPerDiemActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addPerDiemActioned(
	props: AddPerDiemActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Add Per Diem Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Admin Request Approval Actioned' track call.
 *
 * @param {AdminRequestApprovalActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adminRequestApprovalActioned(
	props?: AdminRequestApprovalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Admin Request Approval Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Admin Request Approval Declines' track call.
 *
 * @param {AdminRequestApprovalDeclines} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adminRequestApprovalDeclines(
	props?: AdminRequestApprovalDeclines,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Admin Request Approval Declines',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Adoption Essentials Centre Actioned' track call.
 *
 * @param {AdoptionEssentialsCentreActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adoptionEssentialsCentreActioned(
	props: AdoptionEssentialsCentreActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Adoption Essentials Centre Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Adoption Essentials Feedback Submitted' track call.
 *
 * @param {AdoptionEssentialsFeedbackSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adoptionEssentialsFeedbackSubmitted(
	props: AdoptionEssentialsFeedbackSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Adoption Essentials Feedback Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Adoption Essentials Prompt Actioned' track call.
 *
 * @param {AdoptionEssentialsPromptActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adoptionEssentialsPromptActioned(
	props: AdoptionEssentialsPromptActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Adoption Essentials Prompt Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When user see adoption prompt
 *
 * @param {AdoptionPrompt} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adoptionPrompt(
	props: AdoptionPrompt,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Adoption Prompt',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Reusable prompt shown in the bottom-right corner of the screen.
 *
 * @param {AdoptionPromptActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adoptionPromptActioned(
	props?: AdoptionPromptActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Adoption Prompt Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Track actions in company settings and on employee level
 *
 * @param {AdvancedSpendControlsActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function advancedSpendControlsActioned(
	props?: AdvancedSpendControlsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Advanced Spend Controls Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has visited the "All expenses" page for pocket or for wallet
 *
 * @param {AllExpensesInExportActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function allExpensesInExportActioned(
	props?: AllExpensesInExportActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'All Expenses In Export Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * AML question actioned in KYC flow
 *
 * @param {AmlQuestionActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function amlQuestionActioned(
	props: AmlQuestionActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Aml Question Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * AML section actioned in KYC flow
 *
 * @param {AmlSectionActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function amlSectionActioned(
	props: AmlSectionActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Aml Section Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user action over the analytics page
 *
 * @param {AnalyticsPageActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function analyticsPageActioned(
	props: AnalyticsPageActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Analytics Page Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user filters a list
 *
 * @param {ApplyFilter} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applyFilter(
	props: ApplyFilter,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Apply Filter',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user sorts a list
 *
 * @param {ApplySort} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applySort(
	props: ApplySort,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Apply Sort', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * A team is archived
 *
 * @param {ArchiveTeamCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function archiveTeamCompleted(
	props?: ArchiveTeamCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Archive Team Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event to track the changes of the Auto Top-up settings (for both Main and Sub wallets)
 *
 * @param {AutoTopUp} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function autoTopUp(
	props: AutoTopUp,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auto Top-up',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Auto Topup Accordion Item Actioned' track call.
 *
 * @param {AutoTopupAccordionItemActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function autoTopupAccordionItemActioned(
	props: AutoTopupAccordionItemActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auto Topup Accordion Item Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Track changes made to the Auto Top-up settings form
 *
 * @param {AutoTopupSettingsFormChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function autoTopupSettingsFormChanged(
	props: AutoTopupSettingsFormChanged,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auto Topup Settings Form Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Track showing validation messages in the Auto Top-up settings form
 *
 * @param {AutoTopupSettingsFormValidationViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function autoTopupSettingsFormValidationViewed(
	props: AutoTopupSettingsFormValidationViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auto Topup Settings Form Validation Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Click on the Save Changes button in the Manage Auto Top-up modal
 *
 * @param {AutoTopupSettingsSaveChangesButtonClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function autoTopupSettingsSaveChangesButtonClicked(
	props?: AutoTopupSettingsSaveChangesButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auto Topup Settings Save Changes Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Checkbox for users to enable/disable Auto VAT split in the accounting settings page
 *
 * @param {AutoVatSplitControl} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function autoVatSplitControl(
	props?: AutoVatSplitControl,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Auto Vat Split Control',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks "back" on any of the pages in the setup guide flow
 *
 * @param {BackClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function backClicked(
	props?: BackClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Back Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks "back to pleo guide" on any of the pages in the setup guide flow
 *
 * @param {BackToPleoGuideClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function backToPleoGuideClicked(
	props?: BackToPleoGuideClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Back To Pleo Guide Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User tries to download their balance statement and get an error due to a balance mismatch
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function balanceStatementMismatched(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Balance Statement Mismatched',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Add/Edit approvers button was clicked in Invoice Details View
 *
 * @param {BillDetailsApprovalsButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billDetailsApprovalsButtonClicked(
	props: BillDetailsApprovalsButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bill Details Approvals Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Employee list changed in Bill invoice details approvals modal
 *
 * @param {BillDetailsApprovalsEmployeeListChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billDetailsApprovalsEmployeeListChanged(
	props: BillDetailsApprovalsEmployeeListChanged,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bill Details Approvals Employee List Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Button was actioned in bill details view. This usually results in changing the bill's status.
 *
 * @param {BillDetailsButtonActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billDetailsButtonActioned(
	props: BillDetailsButtonActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bill Details Button Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Date picker for scheduling/suggesting date was actioned in bill details view.
 *
 * @param {BillDetailsDatePickerActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billDetailsDatePickerActioned(
	props: BillDetailsDatePickerActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bill Details Date Picker Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Bill details entitlement missing warning was displayed or actioned
 *
 * @param {BillDetailsEntitlementMissingActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billDetailsEntitlementMissingActioned(
	props: BillDetailsEntitlementMissingActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bill Details Entitlement Missing Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Modal was either confirmed or dismissed in bill details view.
 *
 * @param {BillDetailsModalActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billDetailsModalActioned(
	props: BillDetailsModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bill Details Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Navigation in bill details view was performed. This covers prev/next buttons, tab clicks and other links that change the details view
 *
 * @param {BillDetailsNavigationActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billDetailsNavigationActioned(
	props: BillDetailsNavigationActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bill Details Navigation Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Options menu in bill details view was actioned
 *
 * @param {BillDetailsOptionsMenuActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billDetailsOptionsMenuActioned(
	props: BillDetailsOptionsMenuActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bill Details Options Menu Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Split functionality was used in bill details view
 *
 * @param {BillDetailsSplitActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billDetailsSplitActioned(
	props: BillDetailsSplitActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bill Details Split Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Bill data was updated in the bill details view
 *
 * @param {BillDetailsUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billDetailsUpdated(
	props: BillDetailsUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bill Details Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Closable banner appears in bill details view. This results when we have a potentially duplicate bill, a previously voided bill or other
 *
 * @param {BillDetailsWarningBannerInformation} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billDetailsWarningBannerInformation(
	props: BillDetailsWarningBannerInformation,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bill Details Warning Banner Information',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Track when user is suggested with possible due date when it was not extracted from the OCR
 *
 * @param {BillsDueDateSuggestionActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billsDueDateSuggestionActioned(
	props: BillsDueDateSuggestionActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bills Due Date Suggestion Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user clicks to copy email address
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billsEmailCopied(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bills Email Copied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Invoices Onboarding Experiment Empty States
 *
 * @param {BillsEmptyStateActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billsEmptyStateActioned(
	props?: BillsEmptyStateActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bills Empty State Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User with the new pricing plan will see remaining free payments for the current period inside the wallet widget. This is to track the interaction with that info box
 *
 * @param {BillsFreePaymentBalance} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billsFreePaymentBalance(
	props: BillsFreePaymentBalance,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bills Free Payment Balance',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Bills Inbox Bulk Actions' track call.
 *
 * @param {BillsInboxBulkActions} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billsInboxBulkActions(
	props?: BillsInboxBulkActions,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bills Inbox Bulk Actions',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Invoices Onboarding Experiment Empty States
 *
 * @param {BillsLearnMoreActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billsLearnMoreActioned(
	props?: BillsLearnMoreActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bills Learn More Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Render the banner on the dashboard for new users to be able to trigger the intro modal.
 *
 * @param {BillsOnboardingBannerActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billsOnboardingBannerActioned(
	props: BillsOnboardingBannerActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bills Onboarding Banner Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user see/interacts with Bills Onboarding DATEV setup. This is available only for companies based in Germany and using DATEV as an accounting system
 *
 * @param {BillsOnboardingDatevSetup} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billsOnboardingDatevSetup(
	props: BillsOnboardingDatevSetup,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bills Onboarding Datev Setup',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user see/interacts with Bills Onboarding Email setup. This is available only for new companies
 *
 * @param {BillsOnboardingEmailSetup} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billsOnboardingEmailSetup(
	props: BillsOnboardingEmailSetup,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bills Onboarding Email Setup',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user interacts with Bills Onboarding Modal opened via the banner or via the main menu (for first time user only)
 *
 * @param {BillsOnboardingModalActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billsOnboardingModalActioned(
	props: BillsOnboardingModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bills Onboarding Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A field in bills onboarding questionnaire was updated
 *
 * @param {BillsOnboardingQuestionnaireUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billsOnboardingQuestionnaireUpdated(
	props: BillsOnboardingQuestionnaireUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bills Onboarding Questionnaire Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tracking the actions taken by the users (Admin and/or Bookkeeper) in the permission flow to pay bills
 *
 * @param {BillsPermission} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billsPermission(
	props: BillsPermission,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bills Permission',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has performed an action related to bills upload. Either by clicking the "manual upload" button available from the top, bottom, or dashboard empty state, and then selecting files from the file picker, Or by dropping files into the Dashboard page.
 *
 * @param {BillsUploadActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function billsUploadActioned(
	props: BillsUploadActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bills Upload Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user performs a bulk action
 *
 * @param {BulkAction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function bulkAction(
	props: BulkAction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bulk Action',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks bulk edit after selecting multiple items and changes category
 *
 * @param {BulkEditActionedInExport} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function bulkEditActionedInExport(
	props?: BulkEditActionedInExport,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bulk Edit Actioned In Export',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Admin/bookkeeper selects at least one change of category or tag and hits 'Apply' action in the modal
 *
 * @param {BulkEditApplied} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function bulkEditApplied(
	props?: BulkEditApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bulk Edit Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Admin/bookkeeper on Export page selects 1+ expenses and hits the 'Bulk edit' action which pops up a modal
 *
 * @param {BulkEditOpened} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function bulkEditOpened(
	props?: BulkEditOpened,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bulk Edit Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the review is completed from Bulk Review panel
 *
 * @param {BulkReviewCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function bulkReviewCompleted(
	props?: BulkReviewCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bulk Review Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The CACP step in the KYC flow was actioned by a user
 *
 * @param {CacpStepActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cacpStepActioned(
	props: CacpStepActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'CACP Step Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Before we apply captcha to some of our endpoints, we want to gather some stats on how it is performing.
 *
 * @param {CaptchaTest} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function captchaTest(
	props?: CaptchaTest,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Captcha Test',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * triggered when an admin edits card defaults in general settings
 *
 * @param {CardDefaultSettingsActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cardDefaultSettingsActioned(
	props?: CardDefaultSettingsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Card Default Settings Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The company/organization setting to allow non-admin reviewers to edit card limits is toggled
 *
 * @param {CardLimitPermissionsToggled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cardLimitPermissionsToggled(
	props: CardLimitPermissionsToggled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Card Limit Permissions Toggled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks 'Confirm' on the 'Cancel' card order pop-up
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cardOrderDeleted(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Card Order Deleted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Card Ready Popover Actioned' track call.
 *
 * @param {CardReadyPopoverActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cardReadyPopoverActioned(
	props: CardReadyPopoverActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Card Ready Popover Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Card comparison actioned' track call.
 *
 * @param {CardComparisonActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cardComparisonActioned(
	props?: CardComparisonActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Card comparison actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fired when a user clicks "Learn More" on the empty card page
 *
 * @param {CardsPageActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cardsPageActioned(
	props?: CardsPageActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Cards Page Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Cashback Modal' track call.
 *
 * @param {CashbackModal} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cashbackModal(
	props?: CashbackModal,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Cashback Modal',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs an action related to limiting an accounting category to a team / teams
 *
 * @param {CategoriesLimitationActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoriesLimitationActioned(
	props: CategoriesLimitationActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Categories Limitation Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has added a new category. An event is sent for every new category added and is sent when the user leaves the Categories page
 *
 * @param {CategoryCreated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryCreated(
	props?: CategoryCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs an action related to Category Group
 *
 * @param {CategoryGroupActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryGroupActioned(
	props: CategoryGroupActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Group Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs categories export action
 *
 * @param {CategoryGroupExportActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryGroupExportActioned(
	props: CategoryGroupExportActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Group Export Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with the categories import menu item
 *
 * @param {CategoryGroupImportActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryGroupImportActioned(
	props: CategoryGroupImportActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Group Import Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with the import categories modal
 *
 * @param {CategoryGroupImportModalActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryGroupImportModalActioned(
	props: CategoryGroupImportModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Group Import Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User sees import categories modal
 *
 * @param {CategoryGroupImportModalViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryGroupImportModalViewed(
	props: CategoryGroupImportModalViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Group Import Modal Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects a category group type
 *
 * @param {CategoryGroupTypeActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryGroupTypeActioned(
	props: CategoryGroupTypeActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Group Type Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user interacts with the import / export categories toggle button
 *
 * @param {CategoryImportExportButtonActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryImportExportButtonActioned(
	props: CategoryImportExportButtonActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Import Export Button Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Datev - Prefil categories for SKR 03, SKR 04
 *
 * @param {CategoryPrefillActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function categoryPrefillActioned(
	props?: CategoryPrefillActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Category Prefill Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Actions on the view where users can change Overdraft limit
 *
 * @param {ChangeOverdraftLimitActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function changeOverdraftLimitActioned(
	props: ChangeOverdraftLimitActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Change Overdraft Limit Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Details about received PoF bank accounts
 *
 * @param {CheckProofOfFundsBankAccounts} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkProofOfFundsBankAccounts(
	props?: CheckProofOfFundsBankAccounts,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Check Proof of Funds Bank Accounts',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The user interacts with the choose plan pricing modal
 *
 * @param {ChoosePlanModalActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function choosePlanModalActioned(
	props: ChoosePlanModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Choose Plan Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Users selects open to 'chat with us' from the choose plan modal
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function choosePlanSupportChatOpened(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Choose Plan Support Chat Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicked to clear all his applied filters
 *
 * @param {ClearFilterClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function clearFilterClicked(
	props: ClearFilterClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Clear Filter Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When within company settings the reviewer is removed from the reviewers box
 *
 * @param {CompanyAddReviewerCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyAddReviewerCompleted(
	props?: CompanyAddReviewerCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Add Reviewer Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When within company settings the reviewer drop down list is opened
 *
 * @param {CompanyAddReviewerStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyAddReviewerStarted(
	props?: CompanyAddReviewerStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Add Reviewer Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Company Country Selected' track call.
 *
 * @param {CompanyCountrySelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyCountrySelected(
	props?: CompanyCountrySelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Country Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The company info tab is viewed
 *
 * @param {CompanyInfoViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyInfoViewed(
	props?: CompanyInfoViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Info Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Company Language Changed' track call.
 *
 * @param {CompanyLanguageChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyLanguageChanged(
	props: CompanyLanguageChanged,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Language Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user picks if they want to setup accounting themselves or if they want a bookkeeper to do it
 *
 * @param {CompanyOnboardingBookkeepingChoiceActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyOnboardingBookkeepingChoiceActioned(
	props?: CompanyOnboardingBookkeepingChoiceActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Onboarding Bookkeeping Choice Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Whenever the main error screen is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyOnboardingErrorScreenViewed(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Onboarding Error Screen Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When one of the footer card CTA is clicked
 *
 * @param {CompanyOnboardingFooterCardActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyOnboardingFooterCardActioned(
	props?: CompanyOnboardingFooterCardActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Onboarding Footer Card Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a milestone card’s CTA is clicked
 *
 * @param {CompanyOnboardingMilestoneActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyOnboardingMilestoneActioned(
	props?: CompanyOnboardingMilestoneActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Onboarding Milestone Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks the “Next steps” button after being done with KYC and wallet load, to move on to the EXPLORE milestone group
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyOnboardingNextStepsButtonActioned(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Onboarding Next Steps Button Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Whenever the user actions the next steps modal
 *
 * @param {CompanyOnboardingNextStepsModalActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyOnboardingNextStepsModalActioned(
	props?: CompanyOnboardingNextStepsModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Onboarding Next Steps Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Whenever the user opens the next steps modal
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyOnboardingNextStepsModalViewed(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Onboarding Next Steps Modal Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Disable company review confirmed
 *
 * @param {CompanyReviewDisabledCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyReviewDisabledCompleted(
	props?: CompanyReviewDisabledCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Review Disabled Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Disable company review
 *
 * @param {CompanyReviewDisabledStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyReviewDisabledStarted(
	props?: CompanyReviewDisabledStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Review Disabled Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Enable company review
 *
 * @param {CompanyReviewEnabled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyReviewEnabled(
	props?: CompanyReviewEnabled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Review Enabled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Activation of Company Review onboarding modal on Review settings page
 *
 * @param {CompanyReviewOnboardingModalActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyReviewOnboardingModalActioned(
	props: CompanyReviewOnboardingModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Review Onboarding Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Visited company review settings
 *
 * @param {CompanyReviewPageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyReviewPageViewed(
	props?: CompanyReviewPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Review Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a user updates the review settings
 *
 * @param {CompanyReviewThresholdUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyReviewThresholdUpdated(
	props?: CompanyReviewThresholdUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Review Threshold Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When within company settings the reviewer is selected from drop down
 *
 * @param {CompanyReviewerRemoved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyReviewerRemoved(
	props?: CompanyReviewerRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Reviewer Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Each time the user clicks on "Send" button on /company
 *
 * @param {CompanyVerifyCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyVerifyCompleted(
	props?: CompanyVerifyCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Verify Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has clicked on the Next button on /company
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyVerifyNextActioned(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Verify Next Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Click on the start button on /register/intro. User has clicked on the Start button on the page to start the company verification process
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function companyVerifyStarted(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Company Verify Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Pleo-guide topup step: user checks the "I've completed transfer" checkbox
 *
 * @param {ConfirmTransferCheckboxActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function confirmTransferCheckboxActioned(
	props?: ConfirmTransferCheckboxActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Confirm Transfer Checkbox Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks to connect an app
 *
 * @param {ConnectApp} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function connectApp(
	props: ConnectApp,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Connect App',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs an action in creating their user account
 *
 * @param {CreateAccountActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function createAccountActioned(
	props?: CreateAccountActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Create Account Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs an action in creating their user account information
 *
 * @param {CreateAccountUserActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function createAccountUserActioned(
	props?: CreateAccountUserActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Create Account User Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs an action in verifying a user account
 *
 * @param {CreateAccountVerificationActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function createAccountVerificationActioned(
	props?: CreateAccountVerificationActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Create Account Verification Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user interacts with the create budget banner
 *
 * @param {CreateBudgetBanner} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function createBudgetBanner(
	props: CreateBudgetBanner,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Create Budget Banner',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs an action in creating expense categories
 *
 * @param {CreateCategoryActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function createCategoryActioned(
	props?: CreateCategoryActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Create Category Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs an action in creating a new company
 *
 * @param {CreateCompanyActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function createCompanyActioned(
	props?: CreateCompanyActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Create Company Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interactions in the modal to create company
 *
 * @param {CreateCompanyModalActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function createCompanyModalActioned(
	props?: CreateCompanyModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Create Company Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User opens the modal to create a company
 *
 * @param {CreateCompanyModalViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function createCompanyModalViewed(
	props?: CreateCompanyModalViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Create Company Modal Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs an action in creating a user passcode
 *
 * @param {CreatePasscodeActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function createPasscodeActioned(
	props?: CreatePasscodeActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Create Passcode Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completes creating a tag group
 *
 * @param {CreateTagGroupCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function createTagGroupCompleted(
	props?: CreateTagGroupCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Create Tag Group Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks 'Create team' in the Create team pop-up. This is only possible if the user has given the team a Name
 *
 * @param {CreateTeamCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function createTeamCompleted(
	props?: CreateTeamCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Create Team Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks 'Create team'
 *
 * @param {CreateTeamStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function createTeamStarted(
	props?: CreateTeamStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Create Team Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User sets or updates custom format settings
 *
 * @param {CustomFormatSettingsActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customFormatSettingsActioned(
	props?: CustomFormatSettingsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Custom Format Settings Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Modal showed when clicking "Show example" link in accounts help popover
 *
 * @param {DatevAccountExampleModalActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function datevAccountExampleModalActioned(
	props?: DatevAccountExampleModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Datev Account Example Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Datev Accounting Setup Guided Tour' track call.
 *
 * @param {DatevAccountingSetupGuidedTour} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function datevAccountingSetupGuidedTour(
	props?: DatevAccountingSetupGuidedTour,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Datev Accounting Setup Guided Tour',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user clicks the BU code link to datev site
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function datevBuCodeLinkActioned(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Datev Bu Code Link Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Modal showed when a user lands on the setup page after connection
 *
 * @param {DatevOnboardingModuleViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function datevOnboardingModuleViewed(
	props?: DatevOnboardingModuleViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Datev Onboarding Module Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The default language for new members is updated
 *
 * @param {DefaultLanguageUpdateCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function defaultLanguageUpdateCompleted(
	props?: DefaultLanguageUpdateCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Default Language Update Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The language dropdown box is opened
 *
 * @param {DefaultLanguageUpdateStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function defaultLanguageUpdateStarted(
	props?: DefaultLanguageUpdateStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Default Language Update Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * In the settings page, new members default per purchase limits are set
 *
 * @param {DefaultPerPurchaseLimitUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function defaultPerPurchaseLimitUpdated(
	props?: DefaultPerPurchaseLimitUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Default Per Purchase Limit Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * In the settings page, new members default spending limits are set
 *
 * @param {DefaultSpendingLimitUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function defaultSpendingLimitUpdated(
	props?: DefaultSpendingLimitUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Default Spending Limit Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Registered when the help article link from the member deletion modal is clicked

 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deleteEmployeeVendorCardsHelpArticleClicked(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Delete Employee Vendor Cards Help Article Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Registered when the popover containing the names of the vendor cards appearing in the member deletion modal from the people page is triggered (applies only if the deleted member has vendor cards).
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deleteEmployeeVendorCardsInfoClicked(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Delete Employee Vendor Cards Info Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views modal when deleting a reimbursement
 *
 * @param {DeleteReimbursementModalViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deleteReimbursementModalViewed(
	props?: DeleteReimbursementModalViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Delete Reimbursement Modal Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with Demo Budget
 *
 * @param {DemoBudgetActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function demoBudgetActioned(
	props: DemoBudgetActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Demo Budget Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Deselect All Clicked' track call.
 *
 * @param {DeselectAllClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deselectAllClicked(
	props: DeselectAllClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Deselect All Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Direct Debit Agreement Creation Actioned' track call.
 *
 * @param {DirectDebitAgreementCreationActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function directDebitAgreementCreationActioned(
	props: DirectDebitAgreementCreationActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Direct Debit Agreement Creation Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User enables direct reimbursements
 *
 * @param {DirectReimbursementEnabled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function directReimbursementEnabled(
	props: DirectReimbursementEnabled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Direct Reimbursement Enabled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks to disconnect an app
 *
 * @param {DisconnectApp} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function disconnectApp(
	props: DisconnectApp,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Disconnect App',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The user has opened/closed the feature discovery modal in the billing settings page
 *
 * @param {DiscoverFeaturesModalActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function discoverFeaturesModalActioned(
	props?: DiscoverFeaturesModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Discover Features Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When Plan downgrades are blocked
 *
 * @param {DowngradeBlockedModalActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function downgradeBlockedModalActioned(
	props?: DowngradeBlockedModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Downgrade Blocked Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User makes any download
 *
 * @param {Download} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function download(
	props?: Download,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Download', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * Fires a 'Download App Modal Actioned' track call.
 *
 * @param {DownloadAppModalActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function downloadAppModalActioned(
	props: DownloadAppModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Download App Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on the download people menu
 *
 * @param {DownloadPeopleClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function downloadPeopleClicked(
	props: DownloadPeopleClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Download People Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Reset employee's card spending limit
 *
 * @param {EmployeeCardSpendingLimitReset} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function employeeCardSpendingLimitReset(
	props?: EmployeeCardSpendingLimitReset,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Employee Card Spending Limit Reset',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Change employee's card spending limit
 *
 * @param {EmployeeCardSpendingLimitUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function employeeCardSpendingLimitUpdated(
	props?: EmployeeCardSpendingLimitUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Employee Card Spending Limit Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Change employee code
 *
 * @param {EmployeeCodeUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function employeeCodeUpdated(
	props: EmployeeCodeUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Employee Code Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Member is deleted
 *
 * @param {EmployeeDeleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function employeeDeleted(
	props?: EmployeeDeleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Employee Deleted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Request change of email for employee
 *
 * @param {EmployeeEmailChangeRequested} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function employeeEmailChangeRequested(
	props: EmployeeEmailChangeRequested,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Employee Email Change Requested',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fired when a new employee takes action in the onboarding flow.
 *
 * @param {EmployeeOnboardingActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function employeeOnboardingActioned(
	props?: EmployeeOnboardingActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Employee Onboarding Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Change employee's per purchase spending limit
 *
 * @param {EmployeePerPurchaseSpendingLimitUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function employeePerPurchaseSpendingLimitUpdated(
	props?: EmployeePerPurchaseSpendingLimitUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Employee Per Purchase Spending Limit Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Invited user has entered their email on the website homepage and entered the signup flow instead of following invite link. This event triggers when they get brought to the screen informing them to check their email.
 *
 * @param {EmployeeRedirectActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function employeeRedirectActioned(
	props?: EmployeeRedirectActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Employee Redirect Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Change company role for employee
 *
 * @param {EmployeeRoleUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function employeeRoleUpdated(
	props: EmployeeRoleUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Employee Role Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The search bar in the “Everyone” tab of people page is used
 *
 * @param {EmployeeSearchCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function employeeSearchCompleted(
	props?: EmployeeSearchCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Employee Search Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Employees List Actioned' track call.
 *
 * @param {EmployeesListActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function employeesListActioned(
	props?: EmployeesListActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Employees List Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Admin interacts with card enablement
 *
 * @param {EnableCardsActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function enableCardsActioned(
	props: EnableCardsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Enable Cards Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * An event triggered due to any kind of flag
 *
 * @param {EvaluateFeatureFlags} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function evaluateFeatureFlags(
	props: EvaluateFeatureFlags,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Evaluate Feature Flags',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When user clicks on edit Attendees
 *
 * @param {ExpenseAttendeesEditClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function expenseAttendeesEditClicked(
	props?: ExpenseAttendeesEditClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Expense Attendees Edit Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User deletes a manual expense
 *
 * @param {ExpenseDeleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function expenseDeleted(
	props?: ExpenseDeleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Expense Deleted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Expense Detail Category Updated' track call.
 *
 * @param {ExpenseDetailCategoryUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function expenseDetailCategoryUpdated(
	props?: ExpenseDetailCategoryUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Expense Detail Category Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User Navigate or close the expense details drawer
 *
 * @param {ExpenseDetailsNavigationActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function expenseDetailsNavigationActioned(
	props: ExpenseDetailsNavigationActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Expense Details Navigation Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * One of the expense field updated
 *
 * @param {ExpenseDetailsUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function expenseDetailsUpdated(
	props: ExpenseDetailsUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Expense Details Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User opened expense details
 *
 * @param {ExpenseDetailsViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function expenseDetailsViewed(
	props: ExpenseDetailsViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Expense Details Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User uses the select checkbox on an individual row for an export expense
 *
 * @param {ExpenseInExportSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function expenseInExportSelected(
	props?: ExpenseInExportSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Expense In Export Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User updates the note of an expense
 *
 * @param {ExpenseNoteUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function expenseNoteUpdated(
	props?: ExpenseNoteUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Expense Note Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Expense receipt was uploaded or deleted
 *
 * @param {ExpenseReceiptUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function expenseReceiptUpdated(
	props: ExpenseReceiptUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Expense Receipt Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * (OLD export page) User clicks on the Split button in Expense details on the Export page
 *
 * @param {ExpenseSplitStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function expenseSplitStarted(
	props: ExpenseSplitStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Expense Split Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User engages with the expense tab on the export page
 *
 * @param {ExpenseTabInExportActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function expenseTabInExportActioned(
	props?: ExpenseTabInExportActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Expense Tab In Export Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User updates an expense split, where there are at least two tags selected
 *
 * @param {ExpenseTagSplitUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function expenseTagSplitUpdated(
	props: ExpenseTagSplitUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Expense Tag Split Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User updates the tag of an expense
 *
 * @param {ExpenseTagUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function expenseTagUpdated(
	props?: ExpenseTagUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Expense Tag Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tax code updated on the Expense with tax code suggestion
 *
 * @param {ExpenseTaxSuggestionSaved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function expenseTaxSuggestionSaved(
	props: ExpenseTaxSuggestionSaved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Expense Tax Suggestion Saved',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fired on Expenses page when no expenses have been added yet
 *
 * @param {ExpensesEmptyStateActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function expensesEmptyStateActioned(
	props?: ExpensesEmptyStateActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Expenses Empty State Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User uses "Select all" or "Deselect all" checkboxes for export expenses
 *
 * @param {ExpensesInExportSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function expensesInExportSelected(
	props?: ExpensesInExportSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Expenses In Export Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User filtered expenses list
 *
 * @param {ExpensesListFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function expensesListFiltered(
	props: ExpensesListFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Expenses List Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Button to explore pricing plans is clicked
 *
 * @param {ExplorePlansClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function explorePlansClicked(
	props: ExplorePlansClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Explore Plans Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user clicks "Export again" for Exported expenses and see warning modal
 *
 * @param {ExportAgainModalActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportAgainModalActioned(
	props?: ExportAgainModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Export Again Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user toggles the new export beta on or off using the beta banner
 *
 * @param {ExportBetaBannerActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportBetaBannerActioned(
	props: ExportBetaBannerActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Export Beta Banner Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User, who is allowed to clear the date filter, has cleared it to the maximum range allowed
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportDateFilterClearedToMaxRange(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Export Date Filter Cleared To Max Range',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has attempted to select dates beyond the restricted range
 *
 * @param {ExportDatesBeyondRestrictedRangeSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportDatesBeyondRestrictedRangeSelected(
	props?: ExportDatesBeyondRestrictedRangeSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Export Dates Beyond Restricted Range Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Admin engages with the export filters
 *
 * @param {ExportFiltersActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportFiltersActioned(
	props?: ExportFiltersActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Export Filters Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user uses the Export History filter on the Export Exported page
 *
 * @param {ExportHistoryActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportHistoryActioned(
	props?: ExportHistoryActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Export History Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks any of the bottom panel options on the export-beta page.
 *
 * @param {ExportLaneActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportLaneActioned(
	props?: ExportLaneActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Export Lane Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks any of the top panel options on the export-beta page. That is "Unexported", "Export Queue", "Exported", "All", "Expenses", "Pending"
 *
 * @param {ExportNavigationActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportNavigationActioned(
	props?: ExportNavigationActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Export Navigation Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with elements on the export page
 *
 * @param {ExportPageActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportPageActioned(
	props?: ExportPageActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Export Page Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When bookkeeper is changing representation attendees
 *
 * @param {ExportRepresentationAttendeesActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportRepresentationAttendeesActioned(
	props?: ExportRepresentationAttendeesActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Export Representation Attendees Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When bookkeeper is clicking calculate on representation
 *
 * @param {ExportRepresentationCalculateClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportRepresentationCalculateClicked(
	props?: ExportRepresentationCalculateClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Export Representation Calculate Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When bookkeeper is clicking done on representation
 *
 * @param {ExportRepresentationDoneClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportRepresentationDoneClicked(
	props?: ExportRepresentationDoneClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Export Representation Done Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When bookkeeper is updating tax amount
 *
 * @param {ExportRepresentationTaxAmountUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportRepresentationTaxAmountUpdated(
	props?: ExportRepresentationTaxAmountUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Export Representation Tax Amount Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When bookkeeper is updating tips amount
 *
 * @param {ExportRepresentationTipsAmountUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportRepresentationTipsAmountUpdated(
	props?: ExportRepresentationTipsAmountUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Export Representation Tips Amount Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When bookkeeper is selecting representation type
 *
 * @param {ExportRepresentationTypeSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportRepresentationTypeSelected(
	props?: ExportRepresentationTypeSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Export Representation Type Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Track when user selects a suggested value when exporting
 *
 * @param {ExportSuggestionSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportSuggestionSelected(
	props?: ExportSuggestionSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Export Suggestion Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The user is presented with "Something went wrong" as a result of an error loading the export table
 *
 * @param {ExportTableError} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportTableError(
	props?: ExportTableError,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Export Table Error',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * New export validation events
 *
 * @param {ExportValidationActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportValidationActioned(
	props?: ExportValidationActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Export Validation Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * There was an error while loading an expense receipt
 *
 * @param {FailedToLoadReceipt} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function failedToLoadReceipt(
	props?: FailedToLoadReceipt,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Failed to load receipt',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Error while retrieving receipt document source
 *
 * @param {FailedToSourceReceipt} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function failedToSourceReceipt(
	props?: FailedToSourceReceipt,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Failed to source receipt',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has clicked on the name or the CTA button of the feature in the feature discovery modal
 *
 * @param {FeatureClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function featureClicked(
	props: FeatureClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Feature Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User started disabling the fetch
 *
 * @param {FetchOffboardingActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fetchOffboardingActioned(
	props?: FetchOffboardingActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Fetch Offboarding Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event fired whenever a user takes an action in the Fetch onboarding flow on web (similar event exists on mobile)
 *
 * @param {FetchOnboardingActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fetchOnboardingActioned(
	props?: FetchOnboardingActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Fetch Onboarding Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Actions on the fetch promotion banner in the expense detail page
 *
 * @param {FetchPromotionBanner} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fetchPromotionBanner(
	props: FetchPromotionBanner,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Fetch Promotion Banner',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User tries to find their company in the company account creation in the onboarding flow
 *
 * @param {FindCompany} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function findCompany(
	props?: FindCompany,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Find Company',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user sees the Employee reimbursement intro popover
 *
 * @param {FirstReimburseYourselfFromWeb} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function firstReimburseYourselfFromWeb(
	props?: FirstReimburseYourselfFromWeb,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'First Reimburse Yourself From Web',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Flow Completed (Appcues)' track call.
 *
 * @param {FlowCompletedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function flowCompletedAppcues(
	props?: FlowCompletedAppcues,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Flow Completed (Appcues)',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Flow Started (Appcues)' track call.
 *
 * @param {FlowStartedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function flowStartedAppcues(
	props?: FlowStartedAppcues,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Flow Started (Appcues)',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks "Forgot passcode" while authenticating
 *
 * @param {ForgotPasscodeLinkActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function forgotPasscodeLinkActioned(
	props?: ForgotPasscodeLinkActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Forgot Passcode Link Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Form Field Submitted (Appcues)' track call.
 *
 * @param {FormFieldSubmittedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function formFieldSubmittedAppcues(
	props?: FormFieldSubmittedAppcues,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Form Field Submitted (Appcues)',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Form Submitted (Appcues)' track call.
 *
 * @param {FormSubmittedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function formSubmittedAppcues(
	props?: FormSubmittedAppcues,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Form Submitted (Appcues)',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Fortnox Accounting Setup Guided Tour' track call.
 *
 * @param {FortnoxAccountingSetupGuidedTour} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fortnoxAccountingSetupGuidedTour(
	props?: FortnoxAccountingSetupGuidedTour,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Fortnox Accounting Setup Guided Tour',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views the Fortnox onboarding modal
 *
 * @param {FortnoxOnboardingModuleViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fortnoxOnboardingModuleViewed(
	props?: FortnoxOnboardingModuleViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Fortnox Onboarding Module Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Dedicated card event triggered from the general modal to disable the cards (freeze, mark as stolen, etc)
 *
 * @param {FreezeCardModalActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function freezeCardModalActioned(
	props: FreezeCardModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Freeze Card Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on any of the links in the Get Started Guide
 *
 * @param {GetStartedActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function getStartedActioned(
	props?: GetStartedActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Get Started Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Each step of the Getting Started Guide has a call to action. This event will be fired on click of that CTA
 *
 * @param {GettingStartedGuideCtaActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function gettingStartedGuideCtaActioned(
	props: GettingStartedGuideCtaActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Getting Started Guide Cta Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event used for Google Ads Optimisation Signals. This event can have multiple triggers through the funnel and is owned by Team Performance Marketing
 *
 * @param {GoogleAdsOptimisationSignal} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function googleAdsOptimisationSignal(
	props: GoogleAdsOptimisationSignal,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Google Ads Optimisation Signal',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on the link on the "early access to people integrations" banner
 *
 * @param {HrisEarlyAccessBannerActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function hrisEarlyAccessBannerActioned(
	props?: HrisEarlyAccessBannerActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'HRIS Early Access Banner Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User dismissed the banner offering early access to the People Integrations feature
 *
 * @param {HrisEarlyAccessBannerDismissed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function hrisEarlyAccessBannerDismissed(
	props?: HrisEarlyAccessBannerDismissed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'HRIS Early Access Banner Dismissed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User dismissed the early access modal
 *
 * @param {HrisEarlyAccessModalDismissed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function hrisEarlyAccessModalDismissed(
	props?: HrisEarlyAccessModalDismissed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'HRIS Early Access Modal Dismissed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selected which HR system they use and submitted the early access modal
 *
 * @param {HrisEarlyAccessOptInSubmitted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function hrisEarlyAccessOptInSubmitted(
	props?: HrisEarlyAccessOptInSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'HRIS Early Access Opt-in Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When user clicks on the help centre link
 *
 * @param {HelpCentreLinkActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function helpCentreLinkActioned(
	props?: HelpCentreLinkActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Help Centre Link Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on help widget
 *
 * @param {HelpWidgetActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function helpWidgetActioned(
	props: HelpWidgetActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Help Widget Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When user see adoption prompt
 *
 * @param {HelpWidgetViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function helpWidgetViewed(
	props?: HelpWidgetViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Help Widget Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on Create account or Sign in
 *
 * @param {IntroActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function introActioned(
	props: IntroActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Intro Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Intro Viewed' track call.
 *
 * @param {IntroViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function introViewed(
	props?: IntroViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Intro Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Resent an invitation email
 *
 * @param {InvitationEmailResent} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function invitationEmailResent(
	props?: InvitationEmailResent,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Invitation Email Resent',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs an action in the invite bookkeeper flow
 *
 * @param {InviteBookkeeperActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inviteBookkeeperActioned(
	props?: InviteBookkeeperActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Invite Bookkeeper Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Admin/bk uses the bulk team select to apply a single team to many invitees
 *
 * @param {InviteFlowBulkAssignTeamActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inviteFlowBulkAssignTeamActioned(
	props: InviteFlowBulkAssignTeamActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Invite Flow Bulk Assign Team Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Admin/Bk creates a new team from the create team drawer
 *
 * @param {InviteFlowCreateTeamActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inviteFlowCreateTeamActioned(
	props: InviteFlowCreateTeamActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Invite Flow Create Team Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Admin/Bk closes the create team drawer without creating a team
 *
 * @param {InviteFlowCreateTeamDrawerClosed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inviteFlowCreateTeamDrawerClosed(
	props: InviteFlowCreateTeamDrawerClosed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Invite Flow Create Team Drawer Closed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Admin/Bk opens the create team drawer
 *
 * @param {InviteFlowCreateTeamDrawerOpened} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inviteFlowCreateTeamDrawerOpened(
	props: InviteFlowCreateTeamDrawerOpened,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Invite Flow Create Team Drawer Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Admin/Bk uses the dropdown to select a team during the invite flow
 *
 * @param {InviteFlowSelectTeamActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inviteFlowSelectTeamActioned(
	props: InviteFlowSelectTeamActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Invite Flow Select Team Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on the invite provisioned user link
 *
 * @param {InvitePeopleLinkClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function invitePeopleLinkClicked(
	props: InvitePeopleLinkClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Invite People Link Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs an action in the invite team flow
 *
 * @param {InviteTeamActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inviteTeamActioned(
	props: InviteTeamActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Invite Team Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs an action on the invite team emails page
 *
 * @param {InviteTeamEmailActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inviteTeamEmailActioned(
	props?: InviteTeamEmailActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Invite Team Email Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The user exits the add user flow
 *
 * @param {InviteUserCancelled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inviteUserCancelled(
	props: InviteUserCancelled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Invite User Cancelled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks to send invites
 *
 * @param {InviteUsers} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inviteUsers(
	props: InviteUsers,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Invite Users',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Invoice Adoption Prompt to learn more about how Invoices can benefit the company
 *
 * @param {InvoiceAdoptionPromptActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function invoiceAdoptionPromptActioned(
	props?: InvoiceAdoptionPromptActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Invoice Adoption Prompt Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tracking of the restriction landing page being viewed
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function invoiceRestrictionLandingPageViewed(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Invoice Restriction Landing Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * KYC continue button actioned
 *
 * @param {KycContinueActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kycContinueActioned(
	props: KycContinueActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Kyc Continue Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with KYC industry selector
 *
 * @param {KycIndustryActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kycIndustryActioned(
	props: KycIndustryActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Kyc Industry Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * KYC list actioned
 *
 * @param {KycListActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kycListActioned(
	props: KycListActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Kyc List Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with Kyc person in UBO step of the KYC flow
 *
 * @param {KycPersonActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kycPersonActioned(
	props?: KycPersonActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Kyc Person Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * KYC section has been actioned
 *
 * @param {KycSectionActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kycSectionActioned(
	props: KycSectionActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Kyc Section Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with KYC VAT section
 *
 * @param {KycVatActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function kycVatActioned(
	props: KycVatActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Kyc Vat Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Landing Pages Eligible Viewed' track call.
 *
 * @param {LandingPagesEligibleViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function landingPagesEligibleViewed(
	props?: LandingPagesEligibleViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Landing Pages Eligible Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Banner component showed in the billing page when the company is on a legacy plan
 *
 * @param {LegacyPlanBanner} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function legacyPlanBanner(
	props?: LegacyPlanBanner,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Legacy Plan Banner',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on the logout button in the account menu item
 *
 * @param {LogOutClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function logOutClicked(
	props: LogOutClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Log Out Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event to track the changes of the Auto Top-up settings
 *
 * @param {ManageAutoTopUp} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function manageAutoTopUp(
	props: ManageAutoTopUp,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Manage Auto Top-up',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Manage Wallet Viewed' track call.
 *
 * @param {ManageWalletViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function manageWalletViewed(
	props: ManageWalletViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Manage Wallet Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has successfully added a manual expense
 *
 * @param {ManualExpenseAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function manualExpenseAdded(
	props?: ManualExpenseAdded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Manual Expense Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When within the Teams tab of a user page a team is added in the reviewer box
 *
 * @param {MemberSettingReviewingComplete} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function memberSettingReviewingComplete(
	props?: MemberSettingReviewingComplete,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Member Setting Reviewing Complete',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When within the Teams tab of a user page the reviewer drop down list is open
 *
 * @param {MemberSettingReviewingStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function memberSettingReviewingStarted(
	props?: MemberSettingReviewingStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Member Setting Reviewing Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * triggered when an admin or bookkeeper open and edit an employee setting on the People page
 *
 * @param {MemberSettingsActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function memberSettingsActioned(
	props?: MemberSettingsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Member Settings Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When within the Teams tab of a member  settings a team is removed from the reviewer box
 *
 * @param {MemberSettingsReviewerRemoved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function memberSettingsReviewerRemoved(
	props?: MemberSettingsReviewerRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Member Settings Reviewer Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The team of the employee is updated within the member settings
 *
 * @param {MemberSettingsTeamUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function memberSettingsTeamUpdated(
	props?: MemberSettingsTeamUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Member Settings Team Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event used for Meta Ads Optimisation Signals
 *
 * @param {MetaAdsOptimisationSignal} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function metaAdsOptimisationSignal(
	props: MetaAdsOptimisationSignal,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Meta Ads Optimisation Signal',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The new event for when the company default rate got updated
 *
 * @param {MileageAccountUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function mileageAccountUpdated(
	props?: MileageAccountUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Mileage Account Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event triggered when the admin updated the default mileage compensation rate for the company.
 *
 * @param {MileageDefaultRateUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function mileageDefaultRateUpdated(
	props?: MileageDefaultRateUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Mileage Default Rate Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Admin clicks on the 'Disable' button on the disable mileage modal
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function mileageDisabled(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Mileage Disabled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Sent when the admin interacts with the mileage download option on the Mileage settings page on web.
 *
 * @param {MileageDownloadActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function mileageDownloadActioned(
	props?: MileageDownloadActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Mileage Download Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event triggered when an individual mileage rate for an employee is updated.
(On web, this is an admin/bookkeeper action. We'll have the same event on mobile where employees update their own rate)
 *
 * @param {MileageEmployeeRateUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function mileageEmployeeRateUpdated(
	props?: MileageEmployeeRateUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Mileage Employee Rate Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Admin completed the step that enabled Mileage for employees
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function mileageEnabled(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Mileage Enabled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when an admin takes an action on the list of employees with mileage expenses on web.
 *
 * @param {MileageListActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function mileageListActioned(
	props?: MileageListActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Mileage List Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The new event for when the company default rate got updated
 *
 * @param {MileageRateUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function mileageRateUpdated(
	props?: MileageRateUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Mileage Rate Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks 'Submit' in the 'Missing a receipt?' template
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function missingReceiptSubmitted(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Missing Receipt Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User tries to export an expense but hasn't completed required settings
 *
 * @param {MissingSettingsExportModal} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function missingSettingsExportModal(
	props?: MissingSettingsExportModal,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Missing Settings Export Modal',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Occurs when a missing translation is detected by the i18n library provider.
 *
 * @param {MissingTranslation} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function missingTranslation(
	props?: MissingTranslation,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Missing Translation',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on the my accounts menu item
 *
 * @param {MyAccountClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function myAccountClicked(
	props?: MyAccountClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'My Account Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on the my cards account menu item
 *
 * @param {MyCardsClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function myCardsClicked(
	props?: MyCardsClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'My Cards Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Events for the banner announcing the new pricing migration coming up in November 2024
 *
 * @param {NewPricingBanner} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newPricingBanner(
	props?: NewPricingBanner,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'New Pricing Banner',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When user clicks create purchase form
 *
 * @param {NewPurchaseOrderActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newPurchaseOrderActioned(
	props: NewPurchaseOrderActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'New Purchase Order Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Vendor card creation drawer events

 *
 * @param {NewVendorCardActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newVendorCardActioned(
	props: NewVendorCardActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'New Vendor Card Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with the off-boarding modal
 *
 * @param {OffboardingModalActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offboardingModalActioned(
	props?: OffboardingModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Offboarding Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The user interacts with the offboarding wizard
 *
 * @param {OffboardingWizardActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offboardingWizardActioned(
	props: OffboardingWizardActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Offboarding Wizard Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on Add many link to input multiple emails at once on /pleo-guide/onboard-team
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function onboardTeamAddManyActioned(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Onboard Team Add Many Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User changed the value for default limits on cards on /pleo-guide/onboard-team
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function onboardTeamSetLimitCompleted(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Onboard Team Set Limit Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User changed the value for default limits on cards on /pleo-guide/onboard-team
 *
 * @param {OnboardTeamSpendingLimitActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function onboardTeamSpendingLimitActioned(
	props?: OnboardTeamSpendingLimitActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Onboard Team Spending Limit Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Activation Banner event when button clicked. Keeping track of the location the event took place and the action triggered.
 *
 * @param {OnboardingBannerClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function onboardingBannerClicked(
	props?: OnboardingBannerClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Onboarding Banner Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interactions with Bank Transfer details during Wallet Top up onboarding
 *
 * @param {OnboardingWalletBankTransferTopUp} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function onboardingWalletBankTransferTopUp(
	props?: OnboardingWalletBankTransferTopUp,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Onboarding Wallet Bank Transfer Top Up',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interactions with Toping Up via Open Banking
 *
 * @param {OpenBankingModalActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function openBankingModalActioned(
	props: OpenBankingModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Open Banking Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs an action on the order cards page
 *
 * @param {OrderCardsActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderCardsActioned(
	props: OrderCardsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Order Cards Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has clicked 'Confirm order' and the order has been successfully placed
 *
 * @param {OrderCardsCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderCardsCompleted(
	props?: OrderCardsCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Order Cards Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks 'Continue' on the Plastics cards page for card orders titled 'Select quantity'
 *
 * @param {OrderCardsStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderCardsStarted(
	props?: OrderCardsStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Order Cards Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event used as the Facebook "Purchase"-Event used at multiple stages of the commercial web + product web funnel.
 *
 * @param {OrderCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderCompleted(
	props?: OrderCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Order Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User opens the card order employee selection form
 *
 * @param {OrderPlasticCardsList} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderPlasticCardsList(
	props?: OrderPlasticCardsList,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Order Plastic Cards List',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The user interacts with the outstanding fees banner
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function outstandingFeesBannerActioned(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Outstanding Fees Banner Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Overdraft Banner Actioned' track call.
 *
 * @param {OverdraftBannerActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function overdraftBannerActioned(
	props: OverdraftBannerActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Overdraft Banner Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Overdraft Confirmation Actioned' track call.
 *
 * @param {OverdraftConfirmationActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function overdraftConfirmationActioned(
	props: OverdraftConfirmationActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Overdraft Confirmation Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Overdraft Eligibility Application' track call.
 *
 * @param {OverdraftEligibilityApplication} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function overdraftEligibilityApplication(
	props: OverdraftEligibilityApplication,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Overdraft Eligibility Application',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Overdraft Error Viewed' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function overdraftErrorViewed(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Overdraft Error Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Overdraft Higher Limit' track call.
 *
 * @param {OverdraftHigherLimit} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function overdraftHigherLimit(
	props: OverdraftHigherLimit,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Overdraft Higher Limit',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Overdraft Onboarding Accordion Item' track call.
 *
 * @param {OverdraftOnboardingAccordionItem} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function overdraftOnboardingAccordionItem(
	props?: OverdraftOnboardingAccordionItem,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Overdraft Onboarding Accordion Item',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Overdraft Renewal Actioned' track call.
 *
 * @param {OverdraftRenewalActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function overdraftRenewalActioned(
	props: OverdraftRenewalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Overdraft Renewal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Overdraft Settings' track call.
 *
 * @param {OverdraftSettings} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function overdraftSettings(
	props: OverdraftSettings,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Overdraft Settings',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Overdraft Statements Actioned' track call.
 *
 * @param {OverdraftStatementsActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function overdraftStatementsActioned(
	props: OverdraftStatementsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Overdraft Statements Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Overdraft Summary Actioned' track call.
 *
 * @param {OverdraftSummaryActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function overdraftSummaryActioned(
	props: OverdraftSummaryActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Overdraft Summary Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Overdraft Terms Actioned' track call.
 *
 * @param {OverdraftTermsActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function overdraftTermsActioned(
	props: OverdraftTermsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Overdraft Terms Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Overdraft Underwriting Application Actioned' track call.
 *
 * @param {OverdraftUnderwritingApplicationActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function overdraftUnderwritingApplicationActioned(
	props: OverdraftUnderwritingApplicationActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Overdraft Underwriting Application Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views Plastic Card PIN after successfully having entered their passcode in the 'View PIN' pop-up
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pinViewed(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('PIN Viewed', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User views any page in the product web app. The page will be named after what comes after the first slash in the url. Ex...
 *
 * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(
	props?: PageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event fired when a user watches a video in the Partner academy
 *
 * @param {PartnerAcademy} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerAcademy(
	props?: PartnerAcademy,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Academy',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Partner user changes the bookkeepers assigned to a given client, within the partner client details drawer, general tab.
 *
 * @param {PartnerClientBookkeepersChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerClientBookkeepersChanged(
	props: PartnerClientBookkeepersChanged,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Client Bookkeepers Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Partner creates a company for a new client
 *
 * @param {PartnerClientCreated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerClientCreated(
	props?: PartnerClientCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Client Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The user clicks on Disconnect Client within the Partner Client Details Drawer.
 *
 * @param {PartnerClientDetailsDrawerDisconnectClient} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerClientDetailsDrawerDisconnectClient(
	props: PartnerClientDetailsDrawerDisconnectClient,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Client Details Drawer Disconnect Client',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The user closes the Partner Client Details Drawer.
 *
 * @param {PartnerClientDetailsDrawerDismissed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerClientDetailsDrawerDismissed(
	props: PartnerClientDetailsDrawerDismissed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Client Details Drawer Dismissed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Partner user navigates to the next or previous client using the navigation buttons of the partner client details drawer.
 *
 * @param {PartnerClientDetailsDrawerNavigationButtonsActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerClientDetailsDrawerNavigationButtonsActioned(
	props: PartnerClientDetailsDrawerNavigationButtonsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Client Details Drawer Navigation Buttons Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User takes action on the Partner Client Details Drawer Permission section of the Settings Tab.
 *
 * @param {PartnerClientDetailsDrawerPermissionsSettingsActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerClientDetailsDrawerPermissionsSettingsActioned(
	props: PartnerClientDetailsDrawerPermissionsSettingsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Client Details Drawer Permissions Settings Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tab of the Partner Client Details drawer clicked.
 *
 * @param {PartnerClientDetailsDrawerTabsActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerClientDetailsDrawerTabsActioned(
	props: PartnerClientDetailsDrawerTabsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Client Details Drawer Tabs Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is fired when the user intertacts with the status filter and sort options in the clients list
 *
 * @param {PartnerClientListFilters} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerClientListFilters(
	props: PartnerClientListFilters,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Client List Filters',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Action buttons event of the partner client onboarding success modal.
 *
 * @param {PartnerClientOnboardingSuccessModalActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerClientOnboardingSuccessModalActioned(
	props: PartnerClientOnboardingSuccessModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Client Onboarding Success Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Partner Employee Csv Offboard Actioned' track call.
 *
 * @param {PartnerEmployeeCsvOffboardActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerEmployeeCsvOffboardActioned(
	props?: PartnerEmployeeCsvOffboardActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Employee Csv Offboard Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Partner Employee Export Actioned' track call.
 *
 * @param {PartnerEmployeeExportActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerEmployeeExportActioned(
	props?: PartnerEmployeeExportActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Employee Export Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Action fired on the Partner Employee Invite Form
 *
 * @param {PartnerEmployeeInviteFormActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerEmployeeInviteFormActioned(
	props: PartnerEmployeeInviteFormActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Employee Invite Form Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The event is triggered the moment the invite is accepted, i.e. the partner employee (and potentially partner) has been created in the database
 *
 * @param {PartnerInviteAccepted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerInviteAccepted(
	props?: PartnerInviteAccepted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Invite Accepted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event is fired whenever the a partner invite flow is actioned. See properties for details on actions and types.
 *
 * @param {PartnerInviteFlowActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerInviteFlowActioned(
	props: PartnerInviteFlowActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Invite Flow Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Options menu of the partner KYC HUB Onboarding tab was actioned.
 *
 * @param {PartnerOnboardingClientOptionsMenuActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerOnboardingClientOptionsMenuActioned(
	props: PartnerOnboardingClientOptionsMenuActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Onboarding Client Options Menu Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user gives feedback in the partner portal
 *
 * @param {PartnerPortalFeedback} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerPortalFeedback(
	props: PartnerPortalFeedback,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Portal Feedback',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event fired when a pricing quote request is made. The default quote on page load is ignored
 *
 * @param {PartnerPricingCalculator} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerPricingCalculator(
	props: PartnerPricingCalculator,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Pricing Calculator',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is fired when the user intertacts with the Partner Promo
 *
 * @param {PartnerPromo} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerPromo(
	props: PartnerPromo,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Promo',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event will fire when the user interacts with the Partner Self Onboarding Drawer. The performed actions are described in the event payload.
 *
 * @param {PartnerSelfOnboardingDrawerActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerSelfOnboardingDrawerActioned(
	props: PartnerSelfOnboardingDrawerActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Self Onboarding Drawer Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event will fire when the user interacts with a Partner Self Onboarding Task. The performed actions are described in the event payload.
 *
 * @param {PartnerSelfOnboardingTaskActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerSelfOnboardingTaskActioned(
	props: PartnerSelfOnboardingTaskActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Self Onboarding Task Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tracks the actions the user performs when completing the Invite Employee step of the Partner Self Signup flow.
 *
 * @param {PartnerSelfSignupEmployeesInvited} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerSelfSignupEmployeesInvited(
	props: PartnerSelfSignupEmployeesInvited,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Self Signup - Employees Invited',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is fired when the user completes the partner self signup flow and the partnership is successfully created.
 *
 * @param {PartnerSelfSignupPartnershipCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerSelfSignupPartnershipCreated(
	props: PartnerSelfSignupPartnershipCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Self Signup - Partnership Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event is fired when the user updates their details on the partner self signup flow.
 *
 * @param {PartnerSelfSignupUserDetails} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerSelfSignupUserDetails(
	props?: PartnerSelfSignupUserDetails,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Self Signup - User Details',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Partner Team Actioned' track call.
 *
 * @param {PartnerTeamActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerTeamActioned(
	props?: PartnerTeamActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Team Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Partner Team Client Actioned' track call.
 *
 * @param {PartnerTeamClientActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerTeamClientActioned(
	props?: PartnerTeamClientActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Team Client Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Partner Team Member Actioned' track call.
 *
 * @param {PartnerTeamMemberActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerTeamMemberActioned(
	props?: PartnerTeamMemberActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Partner Team Member Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user fails to confirm their new passcode. A user attempts to repeat their passcode but fails
 *
 * @param {PasscodeResetConfirmPasscodeFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function passcodeResetConfirmPasscodeFailed(
	props: PasscodeResetConfirmPasscodeFailed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Passcode Reset Confirm Passcode Failed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user must enter a OTP to continue their passcode reset
 *
 * @param {PasscodeResetEnterVerificationCode} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function passcodeResetEnterVerificationCode(
	props: PasscodeResetEnterVerificationCode,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Passcode Reset Enter Verification Code',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user tried to reset their passcode from a link in their email but it has expired
 *
 * @param {PasscodeResetLinkExpiredScreenViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function passcodeResetLinkExpiredScreenViewed(
	props: PasscodeResetLinkExpiredScreenViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Passcode Reset Link Expired Screen Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user must logout of their existing accounts to continue the passcode reset flow
 *
 * @param {PasscodeResetLogoutToContinueScreenViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function passcodeResetLogoutToContinueScreenViewed(
	props: PasscodeResetLogoutToContinueScreenViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Passcode Reset Logout To Continue Screen Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user successfully reset their passcode
 *
 * @param {PasscodeResetSuccess} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function passcodeResetSuccess(
	props: PasscodeResetSuccess,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Passcode Reset Success',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Anytime a user successfully enters their passcode
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function passcodeSuccessfullyEntered(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Passcode Successfully Entered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The user views and navigates within the paywall
 *
 * @param {PaywallActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paywallActioned(
	props: PaywallActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Paywall Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The user submits feedback from within the paywall
 *
 * @param {PaywallFeedbackSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paywallFeedbackSubmitted(
	props: PaywallFeedbackSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Paywall Feedback Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Pending Overdraft Actioned' track call.
 *
 * @param {PendingOverdraftActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pendingOverdraftActioned(
	props?: PendingOverdraftActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pending Overdraft Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The People page is viewed
 *
 * @param {PeoplePageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function peoplePageViewed(
	props?: PeoplePageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'People Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs an action in creating a user passcode
 *
 * @param {PhoneNumberStepActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function phoneNumberStepActioned(
	props?: PhoneNumberStepActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Phone Number Step Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has enabled or disabled the Pleo Bank Feed (Xero)
 *
 * @param {PleoBankFeed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pleoBankFeed(
	props: PleoBankFeed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pleo Bank Feed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Small card component on the right in Pleo Guide page
 *
 * @param {PleoGuideReserveCardActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pleoGuideReserveCardActioned(
	props?: PleoGuideReserveCardActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pleo Guide Reserve Card Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Main card component on the left in Pleo Guide page
 *
 * @param {PleoGuideReserveProductCardActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pleoGuideReserveProductCardActioned(
	props?: PleoGuideReserveProductCardActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pleo Guide Reserve Product Card Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has viewed the pleo guide
 *
 * @param {PleoGuideViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pleoGuideViewed(
	props?: PleoGuideViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pleo Guide Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Top banner asking user to pay back owed money
 *
 * @param {PleoReserveCollectionsBannerActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pleoReserveCollectionsBannerActioned(
	props?: PleoReserveCollectionsBannerActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pleo Reserve Collections Banner Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Modal asking user to pay back owed money
 *
 * @param {PleoReserveCollectionsModalActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pleoReserveCollectionsModalActioned(
	props?: PleoReserveCollectionsModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pleo Reserve Collections Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Modal to pick top up method
 *
 * @param {PleoReserveCollectionsTopUpMethodActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pleoReserveCollectionsTopUpMethodActioned(
	props?: PleoReserveCollectionsTopUpMethodActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pleo Reserve Collections Top Up Method Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on How Does It Work link
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pleoReserveHowDoesItWorkClicked(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pleo Reserve How Does It Work Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Activation of Pleo Reserve onboarding modal
 *
 * @param {PleoReserveOnboardingModalActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pleoReserveOnboardingModalActioned(
	props: PleoReserveOnboardingModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pleo Reserve Onboarding Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User updates pocket contra account
 *
 * @param {PocketAccountActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketAccountActioned(
	props?: PocketAccountActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Account Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Pocket Adoption Prompt to learn more about how Pocket can benefit the company
 *
 * @param {PocketAdoptionPromptActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketAdoptionPromptActioned(
	props?: PocketAdoptionPromptActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Adoption Prompt Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Pocket Balance Actioned' track call.
 *
 * @param {PocketBalanceActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketBalanceActioned(
	props?: PocketBalanceActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Balance Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has edited the pocket date on the export page
 *
 * @param {PocketDateInExportActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketDateInExportActioned(
	props: PocketDateInExportActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Date in Export Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This event will be fired once company gets Pocket Easy Start experience (via Launch Darkly) and admin selects integration. At the moment we enable Pocket. We plan to use this event to have the list of companies which got  Pocket Easy Start experience
 *
 * @param {PocketEasyStartAssigned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketEasyStartAssigned(
	props: PocketEasyStartAssigned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Easy Start Assigned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Admin completed the step that enabled the ability to reimburse from wallet
 *
 * @param {PocketEnabled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketEnabled(
	props?: PocketEnabled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Enabled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Pocket Manual Transfer Modal Actioned' track call.
 *
 * @param {PocketManualTransferModalActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketManualTransferModalActioned(
	props: PocketManualTransferModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Manual Transfer Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User sees LexOffice Warning
 *
 * @param {PocketNotCompatibleWithLexOfficeModal} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketNotCompatibleWithLexOfficeModal(
	props?: PocketNotCompatibleWithLexOfficeModal,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Not Compatible With LexOffice Modal',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs an action in Pocket Guide
 *
 * @param {PocketOnboardingActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketOnboardingActioned(
	props?: PocketOnboardingActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Onboarding Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with the pocket page
 *
 * @param {PocketPageActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketPageActioned(
	props?: PocketPageActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Page Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interactions with Pocket page filters
 *
 * @param {PocketPageFilterActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketPageFilterActioned(
	props?: PocketPageFilterActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Page Filter Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with introduction modals
 *
 * @param {PocketPageModalActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketPageModalActioned(
	props?: PocketPageModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Page Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs an action in Pocket Accounting Guide

 *
 * @param {PocketSetupBannerActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketSetupBannerActioned(
	props?: PocketSetupBannerActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Setup Banner Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fired when the pocket setup flow got cancelled
 *
 * @param {PocketSetupCancelled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketSetupCancelled(
	props?: PocketSetupCancelled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Setup Cancelled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Admin started Pocket setup from either the accounting settings, the Pocket page or the Mileage page
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketSetupStarted(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Setup Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Pocket statement download flow triggered by admin/bookkeeper on Pocket page
 *
 * @param {PocketStatementDownloadActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketStatementDownloadActioned(
	props: PocketStatementDownloadActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Statement Download Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User marks pocket expenses as settled
 *
 * @param {PocketTransferActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketTransferActioned(
	props?: PocketTransferActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Transfer Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has successfully submitted a pocket transfer (whether is it is received cash or reimbursed)
 *
 * @param {PocketTransferAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketTransferAdded(
	props?: PocketTransferAdded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Transfer Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with the pocket transfer onboarding guide
 *
 * @param {PocketTransferNewDesignModalActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketTransferNewDesignModalActioned(
	props?: PocketTransferNewDesignModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Transfer New Design Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views a step in the pocket transfer flow
 *
 * @param {PocketTransferStepViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketTransferStepViewed(
	props?: PocketTransferStepViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Transfer Step Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Admin started Pocket video
 *
 * @param {PocketVideoViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pocketVideoViewed(
	props?: PocketVideoViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pocket Video Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The users interacts with any of the links that lead to more details on Pleo's pricing
 *
 * @param {PricingEnquiryLinkActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pricingEnquiryLinkActioned(
	props: PricingEnquiryLinkActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Pricing Enquiry Link Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Product Usage Actioned' track call.
 *
 * @param {ProductUsageActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productUsageActioned(
	props: ProductUsageActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Usage Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Employee’s details like employee first name, last name, job title and phone number are updated
 *
 * @param {ProfileDetailsUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function profileDetailsUpdated(
	props?: ProfileDetailsUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Profile Details Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Projected Spend Widget' track call.
 *
 * @param {ProjectedSpendWidget} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function projectedSpendWidget(
	props: ProjectedSpendWidget,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Projected Spend Widget',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with the upsell/promo card
 *
 * @param {PromoCardActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function promoCardActioned(
	props: PromoCardActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Promo Card Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user edits a already created purchase order
 *
 * @param {PurchaseOrderActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function purchaseOrderActioned(
	props: PurchaseOrderActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Purchase Order Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When purchase order is reviewed by a reviewer
 *
 * @param {PurchaseOrderReviewActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function purchaseOrderReviewActioned(
	props: PurchaseOrderReviewActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Purchase Order Review Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Pleo-guide:: user hovered account number hint
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function questionIconAccountNumberViewed(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Question Icon Account Number Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has clicked the question icon in the bottom right corner of any page in the web app
 *
 * @param {QuestionIconActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function questionIconActioned(
	props?: QuestionIconActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Question Icon Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Pleo-guide:: user hovered bank name hint
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function questionIconBankNameViewed(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Question Icon Bank Name Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User enters into the re-carding flow (app.pleo.io/newcards). Each step in the flow triggers this event. The property will indicate which step was visited.
 *
 * @param {RecardingOrderStepViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function recardingOrderStepViewed(
	props?: RecardingOrderStepViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Recarding Order Step Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has clicked on Copy address in expense page to forward his receipt from his emails
 *
 * @param {ReceiptForwarding} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function receiptForwarding(
	props: ReceiptForwarding,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Receipt Forwarding',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event made on the receipt in Export page
 *
 * @param {ReceiptInExportPageActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function receiptInExportPageActioned(
	props: ReceiptInExportPageActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Receipt In Export Page Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has rotated a receipt image
 *
 * @param {ReceiptRotation} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function receiptRotation(
	props: ReceiptRotation,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Receipt Rotation',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User scaling the receipt in Export page
 *
 * @param {ReceiptScalingInExportPage} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function receiptScalingInExportPage(
	props?: ReceiptScalingInExportPage,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Receipt Scaling In Export Page',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * events for user interactions on recommendation prompt displayed in expense details drawer and review details drawer
 *
 * @param {RecommendationPromptActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function recommendationPromptActioned(
	props: RecommendationPromptActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Recommendation Prompt actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks to reconnect an app
 *
 * @param {ReconnectApp} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reconnectApp(
	props: ReconnectApp,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Reconnect App',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * recurring vendor detail drawer tracking events
 *
 * @param {RecurringVendorDetailsPageActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function recurringVendorDetailsPageActioned(
	props: RecurringVendorDetailsPageActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Recurring Vendor Details Page Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * recurring vendors page tracking events
 *
 * @param {RecurringVendorsPageActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function recurringVendorsPageActioned(
	props: RecurringVendorsPageActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Recurring Vendors Page Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Track actions on sending referral invitations by email
 *
 * @param {ReferralEmailActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralEmailActioned(
	props?: ReferralEmailActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Referral Email Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Track clicks on referrals link in the account screen
 *
 * @param {ReferralInviteActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralInviteActioned(
	props?: ReferralInviteActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Referral Invite Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Track actions on the referral link
 *
 * @param {ReferralLinkActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralLinkActioned(
	props?: ReferralLinkActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Referral Link Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on "How it works" in Referrals dashboard
 *
 * @param {ReferralsHelpWidgetClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralsHelpWidgetClicked(
	props?: ReferralsHelpWidgetClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Referrals Help Widget Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Reimbursement Article Clicked' track call.
 *
 * @param {ReimbursementArticleClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reimbursementArticleClicked(
	props: ReimbursementArticleClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Reimbursement Article Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Reimbursement Deleted' track call.
 *
 * @param {ReimbursementDeleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reimbursementDeleted(
	props?: ReimbursementDeleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Reimbursement Deleted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with the Direct Reimbursement onboarding flow
 *
 * @param {ReimbursementOnboardingActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reimbursementOnboardingActioned(
	props: ReimbursementOnboardingActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Reimbursement Onboarding Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User cancels the Direct Reimbursement onboarding flow
 *
 * @param {ReimbursementOnboardingCancelled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reimbursementOnboardingCancelled(
	props: ReimbursementOnboardingCancelled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Reimbursement Onboarding Cancelled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The user updates the Reimbursement Permissions
 *
 * @param {ReimbursementPermissionTypeSet} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reimbursementPermissionTypeSet(
	props: ReimbursementPermissionTypeSet,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Reimbursement Permission Type Set',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The user interacts with the Reimbursement Settings page
 *
 * @param {ReimbursementSettingsPageActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reimbursementSettingsPageActioned(
	props: ReimbursementSettingsPageActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Reimbursement Settings Page Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Reimbursements Projection Widget' track call.
 *
 * @param {ReimbursementsProjectionWidget} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reimbursementsProjectionWidget(
	props: ReimbursementsProjectionWidget,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Reimbursements Projection Widget',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered in accounting settings after first manual reimbursement has been made
 *
 * @param {RepayAccountActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function repayAccountActioned(
	props?: RepayAccountActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Repay Account Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when an employee performs a reimbursement
 *
 * @param {RepayActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function repayActioned(
	props?: RepayActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Repay Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a user adds a reimbursement method
 *
 * @param {RepayMethodsActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function repayMethodsActioned(
	props?: RepayMethodsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Repay Methods Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when admin interacts on web with the flow to enable the functionality to repay an outstanding Pocket balance.
 *
 * @param {RepayOnboardingActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function repayOnboardingActioned(
	props?: RepayOnboardingActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Repay Onboarding Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when an employee adds a bank account for reimbursement
 *
 * @param {RepayToBankAccountActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function repayToBankAccountActioned(
	props?: RepayToBankAccountActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Repay To Bank Account Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Trigger when an employee adds a personal card for reimbursement
 *
 * @param {RepayToCardActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function repayToCardActioned(
	props?: RepayToCardActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Repay To Card Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks the "Reports" link to see the reports views on the export page
 *
 * @param {ReportsLinkInExportActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reportsLinkInExportActioned(
	props?: ReportsLinkInExportActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Reports Link In Export Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Navigating Action to Requests
 *
 * @param {RequestNavigationActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestNavigationActioned(
	props?: RequestNavigationActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Request Navigation Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user entered their email to request a passcode reset link
 *
 * @param {RequestPasscodeResetLink} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestPasscodeResetLink(
	props?: RequestPasscodeResetLink,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Request Passcode Reset Link',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Accordion item in the right side of the Wallet page
 *
 * @param {ReserveAccordionItemActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reserveAccordionItemActioned(
	props?: ReserveAccordionItemActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Reserve Accordion Item Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Reserve Test Pre Fdd Page Actioned' track call.
 *
 * @param {ReserveTestPreFddPageActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reserveTestPreFddPageActioned(
	props?: ReserveTestPreFddPageActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Reserve Test Pre Fdd Page Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * An admin clicks the reset passcode button on the member detail from the people page
 *
 * @param {ResetPasscodeButtonClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function resetPasscodeButtonClicked(
	props?: ResetPasscodeButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Reset Passcode Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User uses the review function
 *
 * @param {ReviewActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reviewActioned(
	props?: ReviewActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Review Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the review is completed (when any review status is submitted)
 *
 * @param {ReviewCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reviewCompleted(
	props?: ReviewCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Review Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on a Review type
 *
 * @param {ReviewOverviewClickthrough} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reviewOverviewClickthrough(
	props?: ReviewOverviewClickthrough,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Review Overview Clickthrough',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Visit the Review Overview Settings page
 *
 * @param {ReviewOverviewViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reviewOverviewViewed(
	props?: ReviewOverviewViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Review Overview Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a user updates the review settings
 *
 * @param {ReviewSettingsUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reviewSettingsUpdated(
	props?: ReviewSettingsUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Review Settings Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When purchase order is reviewed by a reviewer
 *
 * @param {ReviewTabActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reviewTabActioned(
	props: ReviewTabActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Review Tab Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User adds a reviewer within a team or directly in the member edit module
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reviewerAdded(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Reviewer Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Editing of reviewer threshold is completed
 *
 * @param {ReviewerThresholdUpdateCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reviewerThresholdUpdateCompleted(
	props?: ReviewerThresholdUpdateCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Reviewer Threshold Update Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Editing of reviewer threshold is started
 *
 * @param {ReviewerThresholdUpdateStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reviewerThresholdUpdateStarted(
	props?: ReviewerThresholdUpdateStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Reviewer Threshold Update Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Reviewers tab with call-to-action to configure reviews when there are no reviews configured clicked.
 *
 * @param {ReviewersTabReviewCtaClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reviewersTabReviewCtaClicked(
	props?: ReviewersTabReviewCtaClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Reviewers Tab Review CTA Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Reviewers tab with call-to-action to configure reviews when there are no reviews configured viewed.
 *
 * @param {ReviewersTabReviewCtaViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reviewersTabReviewCtaViewed(
	props: ReviewersTabReviewCtaViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Reviewers Tab Review CTA Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The Reviewers tab is viewed
 *
 * @param {ReviewersTabViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reviewersTabViewed(
	props?: ReviewersTabViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Reviewers Tab Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Apply is clicked in the role filter button in the People page
 *
 * @param {RoleFilterCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function roleFilterCompleted(
	props?: RoleFilterCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Role Filter Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The role filter button in the People page is clicked
 *
 * @param {RoleFilterStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function roleFilterStarted(
	props?: RoleFilterStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Role Filter Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on add exclusion button
 *
 * @param {SamlSsoAddExclusionClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function samlSsoAddExclusionClicked(
	props: SamlSsoAddExclusionClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'SAML SSO Add Exclusion Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on the deactivate SAML SSO menu item
 *
 * @param {SamlSsoDeactivateClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function samlSsoDeactivateClicked(
	props: SamlSsoDeactivateClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'SAML SSO Deactivate Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on the enable SAML SSO checkbox
 *
 * @param {SamlSsoEnableClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function samlSsoEnableClicked(
	props: SamlSsoEnableClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'SAML SSO Enable Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on the SAML SSO IdP integration app
 *
 * @param {SamlSsoIdPClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function samlSsoIdPClicked(
	props: SamlSsoIdPClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'SAML SSO IdP Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on the tab of SAML SSO IdP integration
 *
 * @param {SamlSsoIdPTabVisited} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function samlSsoIdPTabVisited(
	props: SamlSsoIdPTabVisited,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'SAML SSO IdP Tab Visited',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on remove exclusion button
 *
 * @param {SamlSsoRemoveExclusionClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function samlSsoRemoveExclusionClicked(
	props: SamlSsoRemoveExclusionClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'SAML SSO Remove Exclusion Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on the Save SAML SSO config button
 *
 * @param {SamlSsoSaveConfigClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function samlSsoSaveConfigClicked(
	props: SamlSsoSaveConfigClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'SAML SSO Save Config Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on the SAML SSO upgrade button
 *
 * @param {SamlSsoUpgradeClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function samlSsoUpgradeClicked(
	props: SamlSsoUpgradeClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'SAML SSO Upgrade Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user visits SAML SSO home page
 *
 * @param {SamlSsoVisited} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function samlSsoVisited(
	props: SamlSsoVisited,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'SAML SSO Visited',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User is presented an onboarding flow to SCA when they are not yet enabled to go through and verify their phone number. We want to track that they start this journey
 *
 * @param {ScaOnboardingActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function scaOnboardingActioned(
	props?: ScaOnboardingActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'SCA Onboarding Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * On the SCA onboarding flow, we want to see how many complete it by clicking "Done" in the end of the flow and are then switched to SCA automatically
 *
 * @param {ScaOnboardingCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function scaOnboardingCompleted(
	props?: ScaOnboardingCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'SCA Onboarding Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * On the SCA onboarding flow we have a skip -button. We want to follow how many people skip the flow
 *
 * @param {ScaOnboardingSkipped} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function scaOnboardingSkipped(
	props?: ScaOnboardingSkipped,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'SCA Onboarding Skipped',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * the search bar in the subscription page has been actioned
 *
 * @param {SearchBarActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchBarActioned(
	props: SearchBarActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Search Bar Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on "See all available integrations" in the people apps promo
 *
 * @param {SeeAllAvailableIntegrationsClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function seeAllAvailableIntegrationsClicked(
	props: SeeAllAvailableIntegrationsClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'See All Available Integrations clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Select All Clicked' track call.
 *
 * @param {SelectAllClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selectAllClicked(
	props: SelectAllClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Select All Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When user interacts with “Select all” checkbox on Expenses page
 *
 * @param {SelectAllExpensesActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selectAllExpensesActioned(
	props?: SelectAllExpensesActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Select All Expenses Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on an app's tile
 *
 * @param {SelectApp} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selectApp(
	props: SelectApp,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Select App', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * User selects a company type in the company creation flow of a manually signed up company
 *
 * @param {SelectCompanyBusinessType} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selectCompanyBusinessType(
	props?: SelectCompanyBusinessType,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Select Company Business Type',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user closes the country dropdown without selecting a country
 *
 * @param {SelfExpansionCountrySelectAbandoned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selfExpansionCountrySelectAbandoned(
	props: SelfExpansionCountrySelectAbandoned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Self Expansion Country Select Abandoned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks the help article that describes multi entity self expansion
 *
 * @param {SelfExpansionHelpArticleClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selfExpansionHelpArticleClicked(
	props: SelfExpansionHelpArticleClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Self Expansion Help Article Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user views the self expansion video on the modal
 *
 * @param {SelfExpansionInfoVideoViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selfExpansionInfoVideoViewed(
	props: SelfExpansionInfoVideoViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Self Expansion Info Video Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user closes the self expansion modal
 *
 * @param {SelfExpansionModalClosed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selfExpansionModalClosed(
	props: SelfExpansionModalClosed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Self Expansion Modal Closed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user opens the self expansion modal
 *
 * @param {SelfExpansionModalOpened} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selfExpansionModalOpened(
	props: SelfExpansionModalOpened,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Self Expansion Modal Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks the link to explore multi entity pricing plans
 *
 * @param {SelfExpansionMultiEntityPricingPlansClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selfExpansionMultiEntityPricingPlansClicked(
	props: SelfExpansionMultiEntityPricingPlansClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Self Expansion Multi Entity Pricing Plans Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user sends a request for self initiated expansion
 *
 * @param {SelfExpansionRequestSent} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selfExpansionRequestSent(
	props: SelfExpansionRequestSent,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Self Expansion Request Sent',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User sees the add entity step, adds a company, manually creates a company, selects or clears the size of the company, confirms the company details, or completes the step
 *
 * @param {SelfExpansionSignupAddEntityStepActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selfExpansionSignupAddEntityStepActioned(
	props: SelfExpansionSignupAddEntityStepActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Self Expansion Signup Add Entity Step Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User sees the organization name step or completed it
 *
 * @param {SelfExpansionSignupOrganizationNameStepActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selfExpansionSignupOrganizationNameStepActioned(
	props: SelfExpansionSignupOrganizationNameStepActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Self Expansion Signup Organization Name Step Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The user clicks the signup success button
 *
 * @param {SelfExpansionSignupSuccessButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selfExpansionSignupSuccessButtonClicked(
	props: SelfExpansionSignupSuccessButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Self Expansion Signup Success Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks the self expansion signup USA notification button
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selfExpansionSignupUsaNotificationButtonClicked(
	props: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Self Expansion Signup USA Notification Button clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks the link to view and compare all pricing plans from the multi entity pricing plan modal
 *
 * @param {SelfExpansionViewAllPricingPlansClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selfExpansionViewAllPricingPlansClicked(
	props: SelfExpansionViewAllPricingPlansClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Self Expansion View All Pricing Plans Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Sequential Review setting has been toggled by the user
 *
 * @param {SequentialReviewSettingToggled} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sequentialReviewSettingToggled(
	props?: SequentialReviewSettingToggled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Sequential Review Setting Toggled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The "Upgrade your plan modal" is displayed for Sequential Review
 *
 * @param {SequentialReviewUpsellViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sequentialReviewUpsellViewed(
	props: SequentialReviewUpsellViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Sequential Review Upsell Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Set Limits Actioned' track call.
 *
 * @param {SetLimitsActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function setLimitsActioned(
	props?: SetLimitsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Set Limits Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Setup Accounting Categories Product Tour' track call.
 *
 * @param {SetupAccountingCategoriesProductTour} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function setupAccountingCategoriesProductTour(
	props?: SetupAccountingCategoriesProductTour,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Setup Accounting Categories Product Tour',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When setup accounting is completed from Get started guide and a modal is shown
 *
 * @param {SetupAccountingCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function setupAccountingCompleted(
	props: SetupAccountingCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Setup Accounting Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Setup Accounting Confirmation Modal Actioned' track call.
 *
 * @param {SetupAccountingConfirmationModalActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function setupAccountingConfirmationModalActioned(
	props?: SetupAccountingConfirmationModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Setup Accounting Confirmation Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tracking events on our new "Invite Book-keeper" Pop up Modal and the existing Book-keeper invite flow.
 *
 * @param {SetupAccountingPersonaActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function setupAccountingPersonaActioned(
	props?: SetupAccountingPersonaActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Setup Accounting Persona Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs an action on the setup accounting settings page
 *
 * @param {SetupAccountingSettingsActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function setupAccountingSettingsActioned(
	props?: SetupAccountingSettingsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Setup Accounting Settings Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The user has clicked on Setup Accounting from the Pleo Guide (the middle card).
 *
 * @param {SetupAccountingStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function setupAccountingStarted(
	props?: SetupAccountingStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Setup Accounting Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user arrives to Sign up your company screen
 *
 * @param {SignInNoAccount} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signInNoAccount(
	props: SignInNoAccount,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Sign in no account',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user arrives to Sign up your company screen
 *
 * @param {SignUpCompanySignUp} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signUpCompanySignUp(
	props: SignUpCompanySignUp,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Sign up company sign up',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user arrives to Sign up your company screen
 *
 * @param {SignUpNeedAnInvite} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signUpNeedAnInvite(
	props: SignUpNeedAnInvite,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Sign up need an invite',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The register new company signup flow was actioned
 *
 * @param {SignupActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupActioned(
	props: SignupActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Signup Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the request to create the virtual card succeeded on /register/billing. Track if errors happen when creating virtual cards and linking them for billing
 *
 * @param {SignupBillingCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupBillingCompleted(
	props?: SignupBillingCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Signup Billing Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Click on continue after choosing a company on /register/company
 *
 * @param {SignupCompanyCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupCompanyCompleted(
	props?: SignupCompanyCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Signup Company Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Dropdown to choose the country of the company on /register/company. Do we have customers that need to change the country of the company they are registering
 *
 * @param {SignupCountryUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupCountryUpdated(
	props?: SignupCountryUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Signup Country Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User fills the confirmation code and clicks Verify on /register/user/verification. When a user had to input a confirmation code sent by email.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupEmailCompleted(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Signup Email Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Signal used in the signup flow for performance marketing conversion tracking
 *
 * @param {SignupFunnelSignalEvent} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupFunnelSignalEvent(
	props?: SignupFunnelSignalEvent,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Signup Funnel Signal Event',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user enters or moves on the next screen in /register flow
 *
 * @param {SignupStepOpened} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupStepOpened(
	props?: SignupStepOpened,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Signup Step Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks 'Accept and Continue' on the billing page
 *
 * @param {SignupSubmitted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupSubmitted(
	props?: SignupSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Signup Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has clicked 'Accept and Continue' on the billing page and successfully entered Pleo for the first time
 *
 * @param {SignupSucceeded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupSucceeded(
	props?: SignupSucceeded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Signup Succeeded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * After company creation, users are prompted to either continue to the Pleo Guide, or head straight to account activation (FDD flow)
 *
 * @param {SignupSuccessButtonClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupSuccessButtonClicked(
	props?: SignupSuccessButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Signup Success Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event to track all the actions done by the spend activation project
 *
 * @param {SpendActivation} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function spendActivation(
	props: SpendActivation,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Spend Activation',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event to track the progress for the spend activation setup flow
 *
 * @param {SpendActivationSetup} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function spendActivationSetup(
	props: SpendActivationSetup,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Spend Activation Setup',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event to track all the actions done in relation to the Spend Activation Templates project
 *
 * @param {SpendActivationTemplates} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function spendActivationTemplates(
	props: SpendActivationTemplates,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Spend Activation Templates',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Track user actions on core spend guidelines
 *
 * @param {SpendGuidelinesActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function spendGuidelinesActioned(
	props: SpendGuidelinesActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Spend Guidelines Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event for when the spend guidelines test banner was clicked
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function spendGuidelinesTestClicked(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Spend Guidelines Test Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event for question 1 of the spend guidelines testing form
 *
 * @param {SpendGuidelinesTestQ1} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function spendGuidelinesTestQ1(
	props?: SpendGuidelinesTestQ1,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Spend Guidelines Test Q1',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event for question 2 to 4 of the spend guidelines testing form
 *
 * @param {SpendGuidelinesTestQ2Q4} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function spendGuidelinesTestQ2Q4(
	props?: SpendGuidelinesTestQ2Q4,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Spend Guidelines Test Q2 Q4',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Event for when the spend guidelines test banner appears
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function spendGuidelinesTestViewed(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Spend Guidelines Test Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * To track page views
 *
 * @param {SpendGuidelinesViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function spendGuidelinesViewed(
	props: SpendGuidelinesViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Spend Guidelines Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The event is sent every time either of the three settings (spending_group, spending_value, or per_purchase_value) are changed.
 *
 * @param {SpendingLimitSettingsActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function spendingLimitSettingsActioned(
	props?: SpendingLimitSettingsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Spending Limit Settings Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with the split expense in the expense detail page on new export page
 *
 * @param {SplitExpenseActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function splitExpenseActioned(
	props?: SplitExpenseActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Split Expense Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Accessible from the Pleo Guide page or the Wallet, it lets user pick whether they want to go with Credit or Wallet Top-up to start spending
 *
 * @param {StartSpendingPageActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function startSpendingPageActioned(
	props?: StartSpendingPageActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Start Spending Page Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User tries to download a statement as part of a proof of payment
 *
 * @param {StatementDownloadActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function statementDownloadActioned(
	props?: StatementDownloadActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Statement Download Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User tries to download a statement and gets an error from the BFF / API
 *
 * @param {StatementDownloadError} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function statementDownloadError(
	props?: StatementDownloadError,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Statement Download Error',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Step Completed (Appcues)' track call.
 *
 * @param {StepCompletedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function stepCompletedAppcues(
	props?: StepCompletedAppcues,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Step Completed (Appcues)',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Step Interacted (Appcues)' track call.
 *
 * @param {StepInteractedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function stepInteractedAppcues(
	props?: StepInteractedAppcues,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Step Interacted (Appcues)',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Step Started (Appcues)' track call.
 *
 * @param {StepStartedAppcues} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function stepStartedAppcues(
	props?: StepStartedAppcues,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Step Started (Appcues)',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Storebox integration for DE companies
 *
 * @param {StoreboxIntegrationActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function storeboxIntegrationActioned(
	props?: StoreboxIntegrationActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Storebox Integration Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User triggered one of the Storebox actions
 *
 * @param {StoreboxOnboardingActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function storeboxOnboardingActioned(
	props: StoreboxOnboardingActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Storebox Onboarding Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Sub-Wallet Creation' track call.
 *
 * @param {SubWalletCreation} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subWalletCreation(
	props: SubWalletCreation,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Sub-Wallet Creation',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Sub-wallet Actioned' track call.
 *
 * @param {SubWalletActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subWalletActioned(
	props: SubWalletActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Sub-wallet Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicked a subscription expense card from the list of subscription
 *
 * @param {SubscriptionExpenseClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionExpenseClicked(
	props: SubscriptionExpenseClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Subscription Expense Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicked an expense detail card from the subscription expenses list
 *
 * @param {SubscriptionExpenseDetailClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionExpenseDetailClicked(
	props: SubscriptionExpenseDetailClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Subscription Expense Detail Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User actioned the filters in the subscription page
 *
 * @param {SubscriptionFilterActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionFilterActioned(
	props: SubscriptionFilterActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Subscription Filter Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on a subscription empty state action
 *
 * @param {SubscriptionsEmptyStateActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionsEmptyStateActioned(
	props: SubscriptionsEmptyStateActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Subscriptions Empty State Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User without a subscription sees the empty state
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionsEmptyStateViewed(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Subscriptions Empty State Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on a subscriptions onboarding banner action
 *
 * @param {SubscriptionsOnboardingBannerActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionsOnboardingBannerActioned(
	props: SubscriptionsOnboardingBannerActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Subscriptions Onboarding Banner Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User sees the subscriptions onboarding banner
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function subscriptionsOnboardingBannerViewed(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Subscriptions Onboarding Banner Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has changed any of the field values in the supplier edit form
 *
 * @param {SupplierDetailsUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function supplierDetailsUpdated(
	props: SupplierDetailsUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Supplier Details Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with the supplier form
 *
 * @param {SupplierFormActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function supplierFormActioned(
	props: SupplierFormActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Supplier Form Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicked 'Chat with us' in the support menu in the right bottom corner
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function supportChatOpened(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Support Chat Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user switches to a different logged in account
 *
 * @param {SwitchAccount} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function switchAccount(
	props: SwitchAccount,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Switch Account',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on the switch account menu item
 *
 * @param {SwitchAccountClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function switchAccountClicked(
	props: SwitchAccountClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Switch Account Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user switches to a different entity
 *
 * @param {SwitchEntity} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function switchEntity(
	props: SwitchEntity,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Switch Entity',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User sees LexOffice Warning
 *
 * @param {SystemNotCompatibleWithPocket} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function systemNotCompatibleWithPocket(
	props?: SystemNotCompatibleWithPocket,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'System Not Compatible With Pocket',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Change the budget end date
 *
 * @param {TagBudgetClosedEarly} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagBudgetClosedEarly(
	props?: TagBudgetClosedEarly,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Budget Closed Early',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the new budget created
 *
 * @param {TagBudgetCreationCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagBudgetCreationCompleted(
	props?: TagBudgetCreationCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Budget Creation Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When entering information into the budget form
 *
 * @param {TagBudgetCreationStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagBudgetCreationStarted(
	props?: TagBudgetCreationStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Budget Creation Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When going backwards to the previous budget creation step
 *
 * @param {TagBudgetCreationStepBackward} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagBudgetCreationStepBackward(
	props?: TagBudgetCreationStepBackward,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Budget Creation Step Backward',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When going forward to the next budget creation step
 *
 * @param {TagBudgetCreationStepForward} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagBudgetCreationStepForward(
	props?: TagBudgetCreationStepForward,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Budget Creation Step Forward',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Discard tag budget modal
 *
 * @param {TagBudgetDiscard} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagBudgetDiscard(
	props?: TagBudgetDiscard,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Budget Discard',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Update the budget settings
 *
 * @param {TagBudgetEdited} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagBudgetEdited(
	props?: TagBudgetEdited,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Budget Edited',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Interaction with budget filters
 *
 * @param {TagBudgetFilterActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagBudgetFilterActioned(
	props?: TagBudgetFilterActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Budget Filter Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Tag Budget Opened' track call.
 *
 * @param {TagBudgetOpened} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagBudgetOpened(
	props?: TagBudgetOpened,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Budget Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Unarchive tag for an open budget
 *
 * @param {TagBudgetUnarchivedTag} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagBudgetUnarchivedTag(
	props?: TagBudgetUnarchivedTag,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Budget Unarchived Tag',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Visit the budgets overview page
 *
 * @param {TagBudgetViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagBudgetViewed(
	props?: TagBudgetViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Budget Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Download budget expenses
 *
 * @param {TagBudgetsExpenseDownloaded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagBudgetsExpenseDownloaded(
	props?: TagBudgetsExpenseDownloaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Budgets Expense Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Visit the budgets overview page
 *
 * @param {TagBudgetsOverviewViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagBudgetsOverviewViewed(
	props?: TagBudgetsOverviewViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Budgets Overview Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Importing tags in tag page
 *
 * @param {TagImportActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagImportActioned(
	props?: TagImportActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Import Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User dismisses after having opened Early Signup modal
 *
 * @param {TagReviewFeatureDismissed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagReviewFeatureDismissed(
	props?: TagReviewFeatureDismissed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Review Feature Dismissed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User requests Tag Review
 *
 * @param {TagReviewFeatureRequested} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagReviewFeatureRequested(
	props?: TagReviewFeatureRequested,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Review Feature Requested',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Actor navigates to budgets with the selected tag
 *
 * @param {TagReviewNavigatedToBudgets} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagReviewNavigatedToBudgets(
	props?: TagReviewNavigatedToBudgets,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Review Navigated To Budgets',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Actor navigates to the expenses waiting for review from the selected reviewer
 *
 * @param {TagReviewNavigatedToExpenses} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagReviewNavigatedToExpenses(
	props?: TagReviewNavigatedToExpenses,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Review Navigated To Expenses',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user interacts with the Tag Review Onboarding items
 *
 * @param {TagReviewOnboarding} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagReviewOnboarding(
	props: TagReviewOnboarding,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Review Onboarding',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user deletes one reviewer assigned to the tag
 *
 * @param {TagReviewRemoveSingleReviewer} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagReviewRemoveSingleReviewer(
	props?: TagReviewRemoveSingleReviewer,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Review Remove Single Reviewer',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Visits the Tag Review Settings Page
 *
 * @param {TagReviewSettingsViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagReviewSettingsViewed(
	props?: TagReviewSettingsViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Review Settings Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Actor completes flow adding/updating/removing reviewer(s) to tag(s)
 *
 * @param {TagReviewTagActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagReviewTagActioned(
	props?: TagReviewTagActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Review Tag Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Show warning modal when removing reviewer with assigned expenses waiting for review
 *
 * @param {TagReviewUnassignedReviewerModal} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagReviewUnassignedReviewerModal(
	props?: TagReviewUnassignedReviewerModal,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Review Unassigned Reviewer Modal',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interaction on import of tag group items
 *
 * @param {TagSourceActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tagSourceActioned(
	props: TagSourceActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tag Source Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Action to update the tax code in accounting setting page
 *
 * @param {TaxCodeTableUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function taxCodeTableUpdated(
	props?: TaxCodeTableUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tax Code Table Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When within a Team in the Teams tab the reviewer is selected
 *
 * @param {TeamAddReviewerCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function teamAddReviewerCompleted(
	props?: TeamAddReviewerCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Team Add Reviewer Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When within a Team in the Teams tab the reviewer drop down list is open
 *
 * @param {TeamAddReviewerStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function teamAddReviewerStarted(
	props?: TeamAddReviewerStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Team Add Reviewer Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Apply is clicked in the team filter button in the People
 *
 * @param {TeamFilterCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function teamFilterCompleted(
	props?: TeamFilterCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Team Filter Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The team filter button in the People page is clicked
 *
 * @param {TeamFilterStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function teamFilterStarted(
	props?: TeamFilterStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Team Filter Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Added a member to a team
 *
 * @param {TeamMemberAdded} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function teamMemberAdded(
	props?: TeamMemberAdded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Team Member Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Removed a member to a team
 *
 * @param {TeamMemberRemoved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function teamMemberRemoved(
	props?: TeamMemberRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Team Member Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The team page in the people tab is viewed
 *
 * @param {TeamPageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function teamPageViewed(
	props?: TeamPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Team Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When within a Team in the Teams tab the reviewer is removed from the reviewer box
 *
 * @param {TeamReviewerRemoved} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function teamReviewerRemoved(
	props?: TeamReviewerRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Team Reviewer Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The teams search bar is used
 *
 * @param {TeamSearched} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function teamSearched(
	props?: TeamSearched,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Team Searched',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User manages temporary card limits
 *
 * @param {TemporaryLimitsActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function temporaryLimitsActioned(
	props?: TemporaryLimitsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Temporary Limits Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User sees temporary limits for the first time
 *
 * @param {TemporaryLimitsPopoverViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function temporaryLimitsPopoverViewed(
	props?: TemporaryLimitsPopoverViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Temporary Limits Popover Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * This is a test
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function testEvent(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Test Event', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * Event used for Tik Tok Ads Optimisation Signals. This event can have multiple triggers through the funnel and is owned by Team Performance Marketing
 *
 * @param {TikTokAdsOptimisationSignal} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tikTokAdsOptimisationSignal(
	props: TikTokAdsOptimisationSignal,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tik Tok Ads Optimisation Signal',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user clicks the todo filter on the Expenses page to see their todo list
 *
 * @param {TodoListActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function todoListActioned(
	props: TodoListActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Todo List Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on the account menu
 *
 * @param {ToggleAccountsMenu} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function toggleAccountsMenu(
	props: ToggleAccountsMenu,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Toggle Accounts Menu',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A user clicks on the entity switcher panel used for multi-entities
 *
 * @param {ToggleEntitySwitcher} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function toggleEntitySwitcher(
	props: ToggleEntitySwitcher,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Toggle Entity Switcher',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When user sees the modal to create a manual top-up
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function topupModalViewed(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Topup Modal Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fired when user sees the wallet topup flow in pleo-guide
 *
 * @param {TransferDetailsActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function transferDetailsActioned(
	props: TransferDetailsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Transfer Details Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Treasury Waitlist' track call.
 *
 * @param {TreasuryWaitlist} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function treasuryWaitlist(
	props?: TreasuryWaitlist,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Treasury Waitlist',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tracking for user interaction with the CTAs depending on wether is on the Trial Banner or the modals it opens (how-trial-works and choose-plans)
 *
 * @param {TrialActivationBannerActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function trialActivationBannerActioned(
	props?: TrialActivationBannerActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Trial Activation Banner Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on the Done button on /pleo-guide/try-virtual-card
 *
 * @param {TryCardDoneActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tryCardDoneActioned(
	props?: TryCardDoneActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Try Card Done Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on the Topup button on /pleo-guide/try-virtual-card
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tryCardTopupOpened(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Try Card Topup Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on View Card button on /pleo-guide/try-virtual-card
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tryCardViewOpened(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Try Card View Opened',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A Turborepo CLI Generator is ready to generate files.
 *
 * @param {TurboGeneratorActionsTriggered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function turboGeneratorActionsTriggered(
	props: TurboGeneratorActionsTriggered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Turbo Generator Actions Triggered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A Turborepo CLI Generator had a failure when generating files.
 *
 * @param {TurboGeneratorFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function turboGeneratorFailed(
	props: TurboGeneratorFailed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Turbo Generator Failed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A Turborepo CLI Generator has finished generating files.
 *
 * @param {TurboGeneratorFinished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function turboGeneratorFinished(
	props: TurboGeneratorFinished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Turbo Generator Finished',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A Turborepo CLI Generator has been started.
 *
 * @param {TurboGeneratorStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function turboGeneratorStarted(
	props: TurboGeneratorStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Turbo Generator Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Reset 2FA for an employee
 *
 * @param {TwofactorReset} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function twofactorReset(
	props?: TwofactorReset,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Twofactor Reset',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * UBO self verification Actioned
 *
 * @param {UboSelfVerificationActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function uboSelfVerificationActioned(
	props: UboSelfVerificationActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'UBO Self Verification Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Consists all the actions according to the US Wallet flow
 *
 * @param {UsWalletActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function usWalletActioned(
	props: UsWalletActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'US Wallet Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User Adding Flow -
 *
 * @param {UafAddMemberCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function uafAddMemberCompleted(
	props?: UafAddMemberCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Uaf Add Member Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Partner user selects the notification channel on the partner client details drawer section of the partner portal. Today there's only one channel: email.
 *
 * @param {UpdatePartnerClientNotificationsChannel} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function updatePartnerClientNotificationsChannel(
	props: UpdatePartnerClientNotificationsChannel,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Update Partner Client Notifications Channel',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Partner user selects notification frequency on the partner client details drawer section of the partner portal
 *
 * @param {UpdatePartnerClientNotificationsFrequency} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function updatePartnerClientNotificationsFrequency(
	props: UpdatePartnerClientNotificationsFrequency,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Update Partner Client Notifications Frequency',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The Upgrade your plan widget is displayed on the page
 *
 * @param {UpgradeYourPlanViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function upgradeYourPlanViewed(
	props?: UpgradeYourPlanViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Upgrade Your Plan Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Upload KYC document actioned
 *
 * @param {UploadDocumentActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function uploadDocumentActioned(
	props: UploadDocumentActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Upload Document Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The user interacts with the access type select for new users
 *
 * @param {UsageTypeActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function usageTypeActioned(
	props: UsageTypeActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Usage Type Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Track user data when the user visits Expenses page
 *
 * @param {UserExpensesStatus} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userExpensesStatus(
	props?: UserExpensesStatus,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'User Expenses Status',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Apply is clicked in the user filter button in the Expenses page
 *
 * @param {UserFilterCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userFilterCompleted(
	props?: UserFilterCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'User Filter Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The user filter button in the Expenses page is clicked
 *
 * @param {UserFilterStarted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userFilterStarted(
	props?: UserFilterStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'User Filter Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Vendor card interaction events triggered by actions such as "view card details"

 *
 * @param {VendorCardActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vendorCardActioned(
	props: VendorCardActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Vendor Card Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Vendor Card Setup Activation
 *
 * @param {VendorCardActivation} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vendorCardActivation(
	props?: VendorCardActivation,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Vendor Card Activation',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The callout displayed in the vendor card drawer
 *
 * @param {VendorCardCalloutActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vendorCardCalloutActioned(
	props: VendorCardCalloutActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Vendor Card Callout Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Vendor cards list errors events in the vendor cards page

 *
 * @param {VendorCardError} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vendorCardError(
	props: VendorCardError,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Vendor Card Error',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Vendor card action box displayed inside the recurring expenses page

 *
 * @param {VendorCardModalActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vendorCardModalActioned(
	props: VendorCardModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Vendor Card Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Vendor cards list page views

 *
 * @param {VendorCardPageActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vendorCardPageActioned(
	props: VendorCardPageActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Vendor Card Page Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Vendor Card Page Filter Actioned' track call.
 *
 * @param {VendorCardPageFilterActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vendorCardPageFilterActioned(
	props: VendorCardPageFilterActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Vendor Card Page Filter Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a transaction/expense from the vendor card's activity feed is clicked

 *
 * @param {VendorCardTransactionClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vendorCardTransactionClicked(
	props: VendorCardTransactionClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Vendor Card Transaction Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Any step the user has viewed the company verification flow. This can very from country to country.
 *
 * @param {VerificationStepViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function verificationStepViewed(
	props?: VerificationStepViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Verification Step Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has successfully submitted their company verifications.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function verificationSubmitted(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Verification Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks 'I have initiated the transfer' on the ManagementVerify - Company info page
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function verificationTransferInitiated(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Verification Transfer Initiated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has clicked 'Done' on the the ManagementVerify - Company info page, however failed the eKYC and thus is sent back to the ManagementVerify page to upload documents
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function verificationEKycFailed(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Verification eKYC Failed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs an action in the company verification flow
 *
 * @param {VerifyCompanyActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function verifyCompanyActioned(
	props: VerifyCompanyActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Verify Company Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs an action in verifying their identity
 *
 * @param {VerifyIdentityActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function verifyIdentityActioned(
	props?: VerifyIdentityActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Verify Identity Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views expense details
 *
 * @param {ViewExpenseDetailsActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewExpenseDetailsActioned(
	props?: ViewExpenseDetailsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'View Expense Details Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views Virtual Card details after successfully having entered their passcode in the 'View card' pop-up
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function virtualCardPanViewed(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Virtual Card PAN Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Clicked on 'Take a quick tour'
 *
 * @param {VismaEconomicInvoicesTourAccessed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vismaEconomicInvoicesTourAccessed(
	props?: VismaEconomicInvoicesTourAccessed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Visma economic invoices tour accessed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Visma e-conomic migration flow tracking
 *
 * @param {VismaEconomicMigrationFlow} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vismaEconomicMigrationFlow(
	props: VismaEconomicMigrationFlow,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Visma economic migration flow',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Clicked on instructions ('Enabling Invoices for Visma e-conomic')
 *
 * @param {VismaEconomicMigrationInstructionsActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vismaEconomicMigrationInstructionsActioned(
	props?: VismaEconomicMigrationInstructionsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Visma economic migration instructions actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When user is on /wallet/history page
 *
 * @param {WalletActivitiesHistoryActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletActivitiesHistoryActioned(
	props?: WalletActivitiesHistoryActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Activities History Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When user is on the wallet page and clicked on the auto top up button and then if the user hasn't setup a direct debit agreement between the bank and pleo then the user is presented with a modal with a button "Setup Direct Debit"
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletAutoTopUpSetupDirectDebitActioned(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Auto Top Up Setup Direct Debit Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User opens the modal to configure auto top-up settings.
 *
 * @param {WalletAutoTopupModalViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletAutoTopupModalViewed(
	props?: WalletAutoTopupModalViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Auto Topup Modal Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Users see onboarding banner on the Wallet page, when they don't set up Auto top-up
 *
 * @param {WalletAutoTopupOnboardingBannerActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletAutoTopupOnboardingBannerActioned(
	props?: WalletAutoTopupOnboardingBannerActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Auto Topup Onboarding Banner Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * On the Wallet page, when a user clicks "Balance statement" button we see a modal with calendars and download button.
 *
 * @param {WalletBalanceStatementActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletBalanceStatementActioned(
	props?: WalletBalanceStatementActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Balance Statement Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When user sees the modal on the wallet page with the balance statement.
 *
 * @param {WalletBalanceStatementModalViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletBalanceStatementModalViewed(
	props?: WalletBalanceStatementModalViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Balance Statement Modal Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The user interactions with the outstanding invoices link in the wallet balance
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletBalanceUnpaidInvoicesActioned(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Balance Unpaid Invoices Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user is on the wallet page and clicks on manual auto top up and then chooses Bank Transfer. We have a list of bank details to which the user should transfer money to.
 *
 * @param {WalletBankTransferModalActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletBankTransferModalActioned(
	props?: WalletBankTransferModalActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Bank Transfer Modal Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When user sees the modal for bank transfer manual top-up.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletBankTransferModalViewed(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Bank Transfer Modal Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Wallet Direct Debit Se Actioned' track call.
 *
 * @param {WalletDirectDebitSeActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletDirectDebitSeActioned(
	props?: WalletDirectDebitSeActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Direct Debit Se Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When user sees the modal on the wallet page for manual direct debit topup.
 *
 * @param {WalletDirectDebitTopupModalViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletDirectDebitTopupModalViewed(
	props?: WalletDirectDebitTopupModalViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Direct Debit Topup Modal Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Wallet Error Viewed' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletErrorViewed(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Error Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User is on the Wallet page (not onboading flow, but regular, after onboarding wallet page) and clicks on a button which is named "Low balance alert" with a ringbell on it. Clicking on that button shows a modal, which has options for automatically or manually choosing an amount below which we should notify the user to topup wallet.
 *
 * @param {WalletLowBalanceAlertActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletLowBalanceAlertActioned(
	props: WalletLowBalanceAlertActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Low Balance Alert Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When user sees the modal to configure balance threshold for auto top-up
 *
 * @param {WalletLowBalanceAlertModalViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletLowBalanceAlertModalViewed(
	props?: WalletLowBalanceAlertModalViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Low Balance Alert Modal Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user selected the manual top up flow on the wallet page and then clicked Direct Debit and then clicked Edit to edit the template.
 *
 * @param {WalletManualAutoTopUpDirectDebitMandateEditActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletManualAutoTopUpDirectDebitMandateEditActioned(
	props?: WalletManualAutoTopUpDirectDebitMandateEditActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Manual Auto Top Up Direct Debit Mandate Edit Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * On the wallet page, when the user has selected Edit and then clicked on button to Set Up Amount
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletManualAutoTopUpDirectDebitMandateEditSetUpAmountActioned(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Manual Auto Top Up Direct Debit Mandate Edit Set Up Amount Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user is on the wallet page and clicks on manual top up button and then selects Direct Debit and already has it set up.
 *
 * @param {WalletManualTopUpDirectDebitActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletManualTopUpDirectDebitActioned(
	props?: WalletManualTopUpDirectDebitActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Manual Top Up Direct Debit Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user wants to delete the current mandate form
 *
 * @param {WalletManualTopUpDirectDebitDeleteActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletManualTopUpDirectDebitDeleteActioned(
	props?: WalletManualTopUpDirectDebitDeleteActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Manual Top Up Direct Debit Delete Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user does not have any balance loads so far, is redirected to onboarding flow for wallet load.
 *
 * @param {WalletOnboardingTopupViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletOnboardingTopupViewed(
	props?: WalletOnboardingTopupViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Onboarding Topup Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user arrives at the wallet page
 *
 * @param {WalletPageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletPageViewed(
	props?: WalletPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the company is a german one it has to setup a mandate.
 *
 * @param {WalletSetupAutoTopupMandateDe} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletSetupAutoTopupMandateDe(
	props?: WalletSetupAutoTopupMandateDe,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Setup Auto Topup Mandate De',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user selected auto topup and then select direct debit and is from a danish company
 *
 * @param {WalletSetupNetsActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletSetupNetsActioned(
	props?: WalletSetupNetsActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Setup Nets Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Actions with top up methods
 *
 * @param {WalletTopUpActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletTopUpActioned(
	props?: WalletTopUpActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Top Up Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When user is on wallet page and clicks on top up history button
 *
 * @param {WalletTopUpHistoryActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletTopUpHistoryActioned(
	props?: WalletTopUpHistoryActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Top Up History Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When user sees the modal with top-up history.
 *
 * @param {WalletTopupHistoryModalViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletTopupHistoryModalViewed(
	props?: WalletTopupHistoryModalViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Topup History Modal Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When user clicks on the "+ Top up" button on the wallet page and sees modal with two options - Direct debit or bank transfer.
 *
 * @param {WalletTopupMethodModalViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletTopupMethodModalViewed(
	props?: WalletTopupMethodModalViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Topup Method Modal Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user is on the wallet page and clicks on the "Auto topup button" which opens up a modal to configure conditions under which auto topup is run.
 *
 * @param {WalletTurnOnAutoTopupActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletTurnOnAutoTopupActioned(
	props?: WalletTurnOnAutoTopupActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Turn On Auto Topup Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Events with wallet withdrawal button
 *
 * @param {WalletWithdrawalButtonActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletWithdrawalButtonActioned(
	props?: WalletWithdrawalButtonActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Withdrawal Button Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interactions with wallet withdrawal page
 *
 * @param {WalletWithdrawalPageActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function walletWithdrawalPageActioned(
	props?: WalletWithdrawalPageActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wallet Withdrawal Page Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User most come through the bigform-2 or your must be between 1-4 in company size. This is the first page they land on. This corresponds to app.pleo.io/welcome and the page call /welcome
 *
 * @param {WelcomePageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function welcomePageViewed(
	props?: WelcomePageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Welcome Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has clicked a link in the accounting settings side module
 *
 * @param {XeroAccountingModuleActioned} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function xeroAccountingModuleActioned(
	props?: XeroAccountingModuleActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Xero Accounting Module Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interaction with Xero educational banner on tags page
 *
 * @param {XeroCategoriesBannerActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function xeroCategoriesBannerActioned(
	props: XeroCategoriesBannerActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Xero Categories Banner Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interaction on sync or creation of tag groups on create tags page
 *
 * @param {XeroCreateTagGroupActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function xeroCreateTagGroupActioned(
	props: XeroCreateTagGroupActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Xero Create Tag Group Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views the Xero onboarding modal
 *
 * @param {XeroOnboardingModuleViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function xeroOnboardingModuleViewed(
	props?: XeroOnboardingModuleViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Xero Onboarding Module Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User has used tabs at the top of the Accounting Settings page
 *
 * @param {XeroSetupPageActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function xeroSetupPageActioned(
	props: XeroSetupPageActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Xero Setup Page Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interaction with Xero educational banner on tags page
 *
 * @param {XeroTagsBannerActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function xeroTagsBannerActioned(
	props: XeroTagsBannerActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Xero Tags Banner Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interaction on sync or creation of tag groups on tags page
 *
 * @param {XeroTagsPageActioned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function xeroTagsPageActioned(
	props: XeroTagsPageActioned,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Xero Tags Page Actioned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * Fires a 'AIDR Actioned' track call.
	 *
	 * @param {AidrActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	aidrActioned,
	/**
	 * Fires a 'AIS linked account actioned' track call.
	 *
	 * @param {AisLinkedAccountActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	aisLinkedAccountActioned,
	/**
	 * Invited user has entered personal details and pressed 'Continue'
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	acceptInviteSignupSucceeded,
	/**
	 * User interactions in modal to add account
	 *
	 * @param {AccountSwitchModalActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountSwitchModalActioned,
	/**
	 * User views modal to add new account
	 *
	 * @param {AccountSwitchModalViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountSwitchModalViewed,
	/**
	 * User users toggles to another account from the navigation bar
	 *
	 * @param {AccountSwitchToggleActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountSwitchToggleActioned,
	/**
	 * Reviewers for an accounting entry has been manually edited (added or removed a reviewer)
	 *
	 * @param {AccountingEntryReviewersManuallyAddedOrRemoved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountingEntryReviewersManuallyAddedOrRemoved,
	/**
	 * When a user clicks "Next" on one of the steps when configuring the accounting on /pleo-guide/setup-accounting. Track steps completion around accounting setup
	 *
	 * @param {AccountingSetupCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountingSetupCompleted,
	/**
	 * User clicks on the link to invite a bookkeeper on /pleo-guide/setup-accounting
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountingSetupInviteBookkeeperOpened,
	/**
	 * User clicks on the See all link on /pleo-guide/setup-accounting. Did the user find the top 4 accounting system that we show or did they need to click.
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountingSetupSeeAllActioned,
	/**
	 * User views a step in the Accounting setup wizard on /pleo-guide/setup-accounting
	 *
	 * @param {AccountingSetupStepViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountingSetupStepViewed,
	/**
	 * User clicks on "Complete" on the last step of setting up accounting on /pleo-guide/setup-accounting. User has completed setting up accounting from the Pleo Guide
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountingSetupSucceeded,
	/**
	 * User selects a specific accounting system on /settings/accounting/setup
	 *
	 * @param {AccountingSystemClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountingSystemClicked,
	/**
	 * User connects an accounting system
	 *
	 * @param {AccountingSystemConnected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountingSystemConnected,
	/**
	 * User disconnects an accounting system
	 *
	 * @param {AccountingSystemDisconnected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountingSystemDisconnected,
	/**
	 * User selects or makes any update to a specific accounting system on /pleo-guide/setup-accounting
	 *
	 * @param {AccountingSystemUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountingSystemUpdated,
	/**
	 * Fires a 'Active Overdraft Actioned' track call.
	 *
	 * @param {ActiveOverdraftActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	activeOverdraftActioned,
	/**
	 * Activity tab in the expense tab of the export page visited
	 *
	 * @param {ActivityTabActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	activityTabActioned,
	/**
	 * A user clicks on the add account menu item
	 *
	 * @param {AddAccountClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addAccountClicked,
	/**
	 * A user clicks to add an entity
	 *
	 * @param {AddEntity} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addEntity,
	/**
	 * User clicks on the "Add expense" button on the expense page
	 *
	 * @param {AddExpensesActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addExpensesActioned,
	/**
	 * Fired on pleo-guide when users click through "add funds" card flow
	 *
	 * @param {AddFundsActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addFundsActioned,
	/**
	 * When a user enters their first wallet load flow through the Pleo Guide, this event is triggered whenever they select the amount to load
	 *
	 * @param {AddFundsAmountActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addFundsAmountActioned,
	/**
	 * Actions taken within the user adding flow
	 *
	 * @param {AddMemberActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addMemberActioned,
	/**
	 * When a user opens the Add Many member modal
	 *
	 * @param {AddMemberAddManyActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addMemberAddManyActioned,
	/**
	 * When a user adds member through the Add Many member modal
	 *
	 * @param {AddMemberAddManyCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addMemberAddManyCompleted,
	/**
	 * User clicks 'Done' in the Add member pop-up. The user has hereby invited one or more members
	 *
	 * @param {AddMemberCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addMemberCompleted,
	/**
	 * User clicks 'Add member'
	 *
	 * @param {AddMemberStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addMemberStarted,
	/**
	 * The warning banner is displayed when adding users
	 *
	 * @param {AddMemberWarningViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addMemberWarningViewed,
	/**
	 * User interacts with the create per diem flow
	 *
	 * @param {AddPerDiemActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addPerDiemActioned,
	/**
	 * Fires a 'Admin Request Approval Actioned' track call.
	 *
	 * @param {AdminRequestApprovalActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	adminRequestApprovalActioned,
	/**
	 * Fires a 'Admin Request Approval Declines' track call.
	 *
	 * @param {AdminRequestApprovalDeclines} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	adminRequestApprovalDeclines,
	/**
	 * Fires a 'Adoption Essentials Centre Actioned' track call.
	 *
	 * @param {AdoptionEssentialsCentreActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	adoptionEssentialsCentreActioned,
	/**
	 * Fires a 'Adoption Essentials Feedback Submitted' track call.
	 *
	 * @param {AdoptionEssentialsFeedbackSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	adoptionEssentialsFeedbackSubmitted,
	/**
	 * Fires a 'Adoption Essentials Prompt Actioned' track call.
	 *
	 * @param {AdoptionEssentialsPromptActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	adoptionEssentialsPromptActioned,
	/**
	 * When user see adoption prompt
	 *
	 * @param {AdoptionPrompt} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	adoptionPrompt,
	/**
	 * Reusable prompt shown in the bottom-right corner of the screen.
	 *
	 * @param {AdoptionPromptActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	adoptionPromptActioned,
	/**
	 * Track actions in company settings and on employee level
	 *
	 * @param {AdvancedSpendControlsActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	advancedSpendControlsActioned,
	/**
	 * User has visited the "All expenses" page for pocket or for wallet
	 *
	 * @param {AllExpensesInExportActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	allExpensesInExportActioned,
	/**
	 * AML question actioned in KYC flow
	 *
	 * @param {AmlQuestionActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	amlQuestionActioned,
	/**
	 * AML section actioned in KYC flow
	 *
	 * @param {AmlSectionActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	amlSectionActioned,
	/**
	 * user action over the analytics page
	 *
	 * @param {AnalyticsPageActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	analyticsPageActioned,
	/**
	 * A user filters a list
	 *
	 * @param {ApplyFilter} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	applyFilter,
	/**
	 * A user sorts a list
	 *
	 * @param {ApplySort} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	applySort,
	/**
	 * A team is archived
	 *
	 * @param {ArchiveTeamCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	archiveTeamCompleted,
	/**
	 * Event to track the changes of the Auto Top-up settings (for both Main and Sub wallets)
	 *
	 * @param {AutoTopUp} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	autoTopUp,
	/**
	 * Fires a 'Auto Topup Accordion Item Actioned' track call.
	 *
	 * @param {AutoTopupAccordionItemActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	autoTopupAccordionItemActioned,
	/**
	 * Track changes made to the Auto Top-up settings form
	 *
	 * @param {AutoTopupSettingsFormChanged} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	autoTopupSettingsFormChanged,
	/**
	 * Track showing validation messages in the Auto Top-up settings form
	 *
	 * @param {AutoTopupSettingsFormValidationViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	autoTopupSettingsFormValidationViewed,
	/**
	 * Click on the Save Changes button in the Manage Auto Top-up modal
	 *
	 * @param {AutoTopupSettingsSaveChangesButtonClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	autoTopupSettingsSaveChangesButtonClicked,
	/**
	 * Checkbox for users to enable/disable Auto VAT split in the accounting settings page
	 *
	 * @param {AutoVatSplitControl} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	autoVatSplitControl,
	/**
	 * User clicks "back" on any of the pages in the setup guide flow
	 *
	 * @param {BackClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	backClicked,
	/**
	 * User clicks "back to pleo guide" on any of the pages in the setup guide flow
	 *
	 * @param {BackToPleoGuideClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	backToPleoGuideClicked,
	/**
	 * User tries to download their balance statement and get an error due to a balance mismatch
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	balanceStatementMismatched,
	/**
	 * Add/Edit approvers button was clicked in Invoice Details View
	 *
	 * @param {BillDetailsApprovalsButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billDetailsApprovalsButtonClicked,
	/**
	 * Employee list changed in Bill invoice details approvals modal
	 *
	 * @param {BillDetailsApprovalsEmployeeListChanged} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billDetailsApprovalsEmployeeListChanged,
	/**
	 * Button was actioned in bill details view. This usually results in changing the bill's status.
	 *
	 * @param {BillDetailsButtonActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billDetailsButtonActioned,
	/**
	 * Date picker for scheduling/suggesting date was actioned in bill details view.
	 *
	 * @param {BillDetailsDatePickerActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billDetailsDatePickerActioned,
	/**
	 * Bill details entitlement missing warning was displayed or actioned
	 *
	 * @param {BillDetailsEntitlementMissingActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billDetailsEntitlementMissingActioned,
	/**
	 * Modal was either confirmed or dismissed in bill details view.
	 *
	 * @param {BillDetailsModalActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billDetailsModalActioned,
	/**
	 * Navigation in bill details view was performed. This covers prev/next buttons, tab clicks and other links that change the details view
	 *
	 * @param {BillDetailsNavigationActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billDetailsNavigationActioned,
	/**
	 * Options menu in bill details view was actioned
	 *
	 * @param {BillDetailsOptionsMenuActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billDetailsOptionsMenuActioned,
	/**
	 * Split functionality was used in bill details view
	 *
	 * @param {BillDetailsSplitActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billDetailsSplitActioned,
	/**
	 * Bill data was updated in the bill details view
	 *
	 * @param {BillDetailsUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billDetailsUpdated,
	/**
	 * Closable banner appears in bill details view. This results when we have a potentially duplicate bill, a previously voided bill or other
	 *
	 * @param {BillDetailsWarningBannerInformation} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billDetailsWarningBannerInformation,
	/**
	 * Track when user is suggested with possible due date when it was not extracted from the OCR
	 *
	 * @param {BillsDueDateSuggestionActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billsDueDateSuggestionActioned,
	/**
	 * Triggered when user clicks to copy email address
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billsEmailCopied,
	/**
	 * Invoices Onboarding Experiment Empty States
	 *
	 * @param {BillsEmptyStateActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billsEmptyStateActioned,
	/**
	 * User with the new pricing plan will see remaining free payments for the current period inside the wallet widget. This is to track the interaction with that info box
	 *
	 * @param {BillsFreePaymentBalance} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billsFreePaymentBalance,
	/**
	 * Fires a 'Bills Inbox Bulk Actions' track call.
	 *
	 * @param {BillsInboxBulkActions} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billsInboxBulkActions,
	/**
	 * Invoices Onboarding Experiment Empty States
	 *
	 * @param {BillsLearnMoreActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billsLearnMoreActioned,
	/**
	 * Render the banner on the dashboard for new users to be able to trigger the intro modal.
	 *
	 * @param {BillsOnboardingBannerActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billsOnboardingBannerActioned,
	/**
	 * Triggered when user see/interacts with Bills Onboarding DATEV setup. This is available only for companies based in Germany and using DATEV as an accounting system
	 *
	 * @param {BillsOnboardingDatevSetup} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billsOnboardingDatevSetup,
	/**
	 * Triggered when user see/interacts with Bills Onboarding Email setup. This is available only for new companies
	 *
	 * @param {BillsOnboardingEmailSetup} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billsOnboardingEmailSetup,
	/**
	 * Triggered when user interacts with Bills Onboarding Modal opened via the banner or via the main menu (for first time user only)
	 *
	 * @param {BillsOnboardingModalActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billsOnboardingModalActioned,
	/**
	 * A field in bills onboarding questionnaire was updated
	 *
	 * @param {BillsOnboardingQuestionnaireUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billsOnboardingQuestionnaireUpdated,
	/**
	 * Tracking the actions taken by the users (Admin and/or Bookkeeper) in the permission flow to pay bills
	 *
	 * @param {BillsPermission} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billsPermission,
	/**
	 * User has performed an action related to bills upload. Either by clicking the "manual upload" button available from the top, bottom, or dashboard empty state, and then selecting files from the file picker, Or by dropping files into the Dashboard page.
	 *
	 * @param {BillsUploadActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	billsUploadActioned,
	/**
	 * A user performs a bulk action
	 *
	 * @param {BulkAction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	bulkAction,
	/**
	 * User clicks bulk edit after selecting multiple items and changes category
	 *
	 * @param {BulkEditActionedInExport} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	bulkEditActionedInExport,
	/**
	 * Admin/bookkeeper selects at least one change of category or tag and hits 'Apply' action in the modal
	 *
	 * @param {BulkEditApplied} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	bulkEditApplied,
	/**
	 * Admin/bookkeeper on Export page selects 1+ expenses and hits the 'Bulk edit' action which pops up a modal
	 *
	 * @param {BulkEditOpened} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	bulkEditOpened,
	/**
	 * When the review is completed from Bulk Review panel
	 *
	 * @param {BulkReviewCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	bulkReviewCompleted,
	/**
	 * The CACP step in the KYC flow was actioned by a user
	 *
	 * @param {CacpStepActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cacpStepActioned,
	/**
	 * Before we apply captcha to some of our endpoints, we want to gather some stats on how it is performing.
	 *
	 * @param {CaptchaTest} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	captchaTest,
	/**
	 * triggered when an admin edits card defaults in general settings
	 *
	 * @param {CardDefaultSettingsActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cardDefaultSettingsActioned,
	/**
	 * The company/organization setting to allow non-admin reviewers to edit card limits is toggled
	 *
	 * @param {CardLimitPermissionsToggled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cardLimitPermissionsToggled,
	/**
	 * User clicks 'Confirm' on the 'Cancel' card order pop-up
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cardOrderDeleted,
	/**
	 * Fires a 'Card Ready Popover Actioned' track call.
	 *
	 * @param {CardReadyPopoverActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cardReadyPopoverActioned,
	/**
	 * Fires a 'Card comparison actioned' track call.
	 *
	 * @param {CardComparisonActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cardComparisonActioned,
	/**
	 * Fired when a user clicks "Learn More" on the empty card page
	 *
	 * @param {CardsPageActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cardsPageActioned,
	/**
	 * Fires a 'Cashback Modal' track call.
	 *
	 * @param {CashbackModal} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cashbackModal,
	/**
	 * User performs an action related to limiting an accounting category to a team / teams
	 *
	 * @param {CategoriesLimitationActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoriesLimitationActioned,
	/**
	 * User has added a new category. An event is sent for every new category added and is sent when the user leaves the Categories page
	 *
	 * @param {CategoryCreated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryCreated,
	/**
	 * User performs an action related to Category Group
	 *
	 * @param {CategoryGroupActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryGroupActioned,
	/**
	 * User performs categories export action
	 *
	 * @param {CategoryGroupExportActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryGroupExportActioned,
	/**
	 * User interacts with the categories import menu item
	 *
	 * @param {CategoryGroupImportActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryGroupImportActioned,
	/**
	 * User interacts with the import categories modal
	 *
	 * @param {CategoryGroupImportModalActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryGroupImportModalActioned,
	/**
	 * User sees import categories modal
	 *
	 * @param {CategoryGroupImportModalViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryGroupImportModalViewed,
	/**
	 * User selects a category group type
	 *
	 * @param {CategoryGroupTypeActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryGroupTypeActioned,
	/**
	 * A user interacts with the import / export categories toggle button
	 *
	 * @param {CategoryImportExportButtonActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryImportExportButtonActioned,
	/**
	 * Datev - Prefil categories for SKR 03, SKR 04
	 *
	 * @param {CategoryPrefillActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	categoryPrefillActioned,
	/**
	 * Actions on the view where users can change Overdraft limit
	 *
	 * @param {ChangeOverdraftLimitActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	changeOverdraftLimitActioned,
	/**
	 * Details about received PoF bank accounts
	 *
	 * @param {CheckProofOfFundsBankAccounts} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkProofOfFundsBankAccounts,
	/**
	 * The user interacts with the choose plan pricing modal
	 *
	 * @param {ChoosePlanModalActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	choosePlanModalActioned,
	/**
	 * Users selects open to 'chat with us' from the choose plan modal
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	choosePlanSupportChatOpened,
	/**
	 * User clicked to clear all his applied filters
	 *
	 * @param {ClearFilterClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	clearFilterClicked,
	/**
	 * When within company settings the reviewer is removed from the reviewers box
	 *
	 * @param {CompanyAddReviewerCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyAddReviewerCompleted,
	/**
	 * When within company settings the reviewer drop down list is opened
	 *
	 * @param {CompanyAddReviewerStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyAddReviewerStarted,
	/**
	 * Fires a 'Company Country Selected' track call.
	 *
	 * @param {CompanyCountrySelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyCountrySelected,
	/**
	 * The company info tab is viewed
	 *
	 * @param {CompanyInfoViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyInfoViewed,
	/**
	 * Fires a 'Company Language Changed' track call.
	 *
	 * @param {CompanyLanguageChanged} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyLanguageChanged,
	/**
	 * When the user picks if they want to setup accounting themselves or if they want a bookkeeper to do it
	 *
	 * @param {CompanyOnboardingBookkeepingChoiceActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyOnboardingBookkeepingChoiceActioned,
	/**
	 * Whenever the main error screen is shown
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyOnboardingErrorScreenViewed,
	/**
	 * When one of the footer card CTA is clicked
	 *
	 * @param {CompanyOnboardingFooterCardActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyOnboardingFooterCardActioned,
	/**
	 * When a milestone card’s CTA is clicked
	 *
	 * @param {CompanyOnboardingMilestoneActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyOnboardingMilestoneActioned,
	/**
	 * When the user clicks the “Next steps” button after being done with KYC and wallet load, to move on to the EXPLORE milestone group
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyOnboardingNextStepsButtonActioned,
	/**
	 * Whenever the user actions the next steps modal
	 *
	 * @param {CompanyOnboardingNextStepsModalActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyOnboardingNextStepsModalActioned,
	/**
	 * Whenever the user opens the next steps modal
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyOnboardingNextStepsModalViewed,
	/**
	 * Disable company review confirmed
	 *
	 * @param {CompanyReviewDisabledCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyReviewDisabledCompleted,
	/**
	 * Disable company review
	 *
	 * @param {CompanyReviewDisabledStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyReviewDisabledStarted,
	/**
	 * Enable company review
	 *
	 * @param {CompanyReviewEnabled} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyReviewEnabled,
	/**
	 * Activation of Company Review onboarding modal on Review settings page
	 *
	 * @param {CompanyReviewOnboardingModalActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyReviewOnboardingModalActioned,
	/**
	 * Visited company review settings
	 *
	 * @param {CompanyReviewPageViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyReviewPageViewed,
	/**
	 * Triggered when a user updates the review settings
	 *
	 * @param {CompanyReviewThresholdUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyReviewThresholdUpdated,
	/**
	 * When within company settings the reviewer is selected from drop down
	 *
	 * @param {CompanyReviewerRemoved} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyReviewerRemoved,
	/**
	 * Each time the user clicks on "Send" button on /company
	 *
	 * @param {CompanyVerifyCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyVerifyCompleted,
	/**
	 * User has clicked on the Next button on /company
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyVerifyNextActioned,
	/**
	 * Click on the start button on /register/intro. User has clicked on the Start button on the page to start the company verification process
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	companyVerifyStarted,
	/**
	 * Pleo-guide topup step: user checks the "I've completed transfer" checkbox
	 *
	 * @param {ConfirmTransferCheckboxActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	confirmTransferCheckboxActioned,
	/**
	 * A user clicks to connect an app
	 *
	 * @param {ConnectApp} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	connectApp,
	/**
	 * User performs an action in creating their user account
	 *
	 * @param {CreateAccountActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	createAccountActioned,
	/**
	 * User performs an action in creating their user account information
	 *
	 * @param {CreateAccountUserActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	createAccountUserActioned,
	/**
	 * User performs an action in verifying a user account
	 *
	 * @param {CreateAccountVerificationActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	createAccountVerificationActioned,
	/**
	 * Triggered when user interacts with the create budget banner
	 *
	 * @param {CreateBudgetBanner} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	createBudgetBanner,
	/**
	 * User performs an action in creating expense categories
	 *
	 * @param {CreateCategoryActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	createCategoryActioned,
	/**
	 * User performs an action in creating a new company
	 *
	 * @param {CreateCompanyActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	createCompanyActioned,
	/**
	 * User interactions in the modal to create company
	 *
	 * @param {CreateCompanyModalActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	createCompanyModalActioned,
	/**
	 * User opens the modal to create a company
	 *
	 * @param {CreateCompanyModalViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	createCompanyModalViewed,
	/**
	 * User performs an action in creating a user passcode
	 *
	 * @param {CreatePasscodeActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	createPasscodeActioned,
	/**
	 * User completes creating a tag group
	 *
	 * @param {CreateTagGroupCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	createTagGroupCompleted,
	/**
	 * User clicks 'Create team' in the Create team pop-up. This is only possible if the user has given the team a Name
	 *
	 * @param {CreateTeamCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	createTeamCompleted,
	/**
	 * User clicks 'Create team'
	 *
	 * @param {CreateTeamStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	createTeamStarted,
	/**
	 * User sets or updates custom format settings
	 *
	 * @param {CustomFormatSettingsActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customFormatSettingsActioned,
	/**
	 * Modal showed when clicking "Show example" link in accounts help popover
	 *
	 * @param {DatevAccountExampleModalActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	datevAccountExampleModalActioned,
	/**
	 * Fires a 'Datev Accounting Setup Guided Tour' track call.
	 *
	 * @param {DatevAccountingSetupGuidedTour} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	datevAccountingSetupGuidedTour,
	/**
	 * Triggered when user clicks the BU code link to datev site
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	datevBuCodeLinkActioned,
	/**
	 * Modal showed when a user lands on the setup page after connection
	 *
	 * @param {DatevOnboardingModuleViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	datevOnboardingModuleViewed,
	/**
	 * The default language for new members is updated
	 *
	 * @param {DefaultLanguageUpdateCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	defaultLanguageUpdateCompleted,
	/**
	 * The language dropdown box is opened
	 *
	 * @param {DefaultLanguageUpdateStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	defaultLanguageUpdateStarted,
	/**
	 * In the settings page, new members default per purchase limits are set
	 *
	 * @param {DefaultPerPurchaseLimitUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	defaultPerPurchaseLimitUpdated,
	/**
	 * In the settings page, new members default spending limits are set
	 *
	 * @param {DefaultSpendingLimitUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	defaultSpendingLimitUpdated,
	/**
	 * Registered when the help article link from the member deletion modal is clicked
	
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deleteEmployeeVendorCardsHelpArticleClicked,
	/**
	 * Registered when the popover containing the names of the vendor cards appearing in the member deletion modal from the people page is triggered (applies only if the deleted member has vendor cards).
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deleteEmployeeVendorCardsInfoClicked,
	/**
	 * User views modal when deleting a reimbursement
	 *
	 * @param {DeleteReimbursementModalViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deleteReimbursementModalViewed,
	/**
	 * User interacts with Demo Budget
	 *
	 * @param {DemoBudgetActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	demoBudgetActioned,
	/**
	 * Fires a 'Deselect All Clicked' track call.
	 *
	 * @param {DeselectAllClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	deselectAllClicked,
	/**
	 * Fires a 'Direct Debit Agreement Creation Actioned' track call.
	 *
	 * @param {DirectDebitAgreementCreationActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	directDebitAgreementCreationActioned,
	/**
	 * User enables direct reimbursements
	 *
	 * @param {DirectReimbursementEnabled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	directReimbursementEnabled,
	/**
	 * A user clicks to disconnect an app
	 *
	 * @param {DisconnectApp} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	disconnectApp,
	/**
	 * The user has opened/closed the feature discovery modal in the billing settings page
	 *
	 * @param {DiscoverFeaturesModalActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	discoverFeaturesModalActioned,
	/**
	 * When Plan downgrades are blocked
	 *
	 * @param {DowngradeBlockedModalActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	downgradeBlockedModalActioned,
	/**
	 * User makes any download
	 *
	 * @param {Download} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	download,
	/**
	 * Fires a 'Download App Modal Actioned' track call.
	 *
	 * @param {DownloadAppModalActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	downloadAppModalActioned,
	/**
	 * A user clicks on the download people menu
	 *
	 * @param {DownloadPeopleClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	downloadPeopleClicked,
	/**
	 * Reset employee's card spending limit
	 *
	 * @param {EmployeeCardSpendingLimitReset} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	employeeCardSpendingLimitReset,
	/**
	 * Change employee's card spending limit
	 *
	 * @param {EmployeeCardSpendingLimitUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	employeeCardSpendingLimitUpdated,
	/**
	 * Change employee code
	 *
	 * @param {EmployeeCodeUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	employeeCodeUpdated,
	/**
	 * Member is deleted
	 *
	 * @param {EmployeeDeleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	employeeDeleted,
	/**
	 * Request change of email for employee
	 *
	 * @param {EmployeeEmailChangeRequested} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	employeeEmailChangeRequested,
	/**
	 * Fired when a new employee takes action in the onboarding flow.
	 *
	 * @param {EmployeeOnboardingActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	employeeOnboardingActioned,
	/**
	 * Change employee's per purchase spending limit
	 *
	 * @param {EmployeePerPurchaseSpendingLimitUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	employeePerPurchaseSpendingLimitUpdated,
	/**
	 * Invited user has entered their email on the website homepage and entered the signup flow instead of following invite link. This event triggers when they get brought to the screen informing them to check their email.
	 *
	 * @param {EmployeeRedirectActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	employeeRedirectActioned,
	/**
	 * Change company role for employee
	 *
	 * @param {EmployeeRoleUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	employeeRoleUpdated,
	/**
	 * The search bar in the “Everyone” tab of people page is used
	 *
	 * @param {EmployeeSearchCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	employeeSearchCompleted,
	/**
	 * Fires a 'Employees List Actioned' track call.
	 *
	 * @param {EmployeesListActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	employeesListActioned,
	/**
	 * Admin interacts with card enablement
	 *
	 * @param {EnableCardsActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	enableCardsActioned,
	/**
	 * An event triggered due to any kind of flag
	 *
	 * @param {EvaluateFeatureFlags} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	evaluateFeatureFlags,
	/**
	 * When user clicks on edit Attendees
	 *
	 * @param {ExpenseAttendeesEditClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	expenseAttendeesEditClicked,
	/**
	 * User deletes a manual expense
	 *
	 * @param {ExpenseDeleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	expenseDeleted,
	/**
	 * Fires a 'Expense Detail Category Updated' track call.
	 *
	 * @param {ExpenseDetailCategoryUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	expenseDetailCategoryUpdated,
	/**
	 * User Navigate or close the expense details drawer
	 *
	 * @param {ExpenseDetailsNavigationActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	expenseDetailsNavigationActioned,
	/**
	 * One of the expense field updated
	 *
	 * @param {ExpenseDetailsUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	expenseDetailsUpdated,
	/**
	 * User opened expense details
	 *
	 * @param {ExpenseDetailsViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	expenseDetailsViewed,
	/**
	 * User uses the select checkbox on an individual row for an export expense
	 *
	 * @param {ExpenseInExportSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	expenseInExportSelected,
	/**
	 * User updates the note of an expense
	 *
	 * @param {ExpenseNoteUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	expenseNoteUpdated,
	/**
	 * Expense receipt was uploaded or deleted
	 *
	 * @param {ExpenseReceiptUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	expenseReceiptUpdated,
	/**
	 * (OLD export page) User clicks on the Split button in Expense details on the Export page
	 *
	 * @param {ExpenseSplitStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	expenseSplitStarted,
	/**
	 * User engages with the expense tab on the export page
	 *
	 * @param {ExpenseTabInExportActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	expenseTabInExportActioned,
	/**
	 * User updates an expense split, where there are at least two tags selected
	 *
	 * @param {ExpenseTagSplitUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	expenseTagSplitUpdated,
	/**
	 * User updates the tag of an expense
	 *
	 * @param {ExpenseTagUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	expenseTagUpdated,
	/**
	 * Tax code updated on the Expense with tax code suggestion
	 *
	 * @param {ExpenseTaxSuggestionSaved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	expenseTaxSuggestionSaved,
	/**
	 * Fired on Expenses page when no expenses have been added yet
	 *
	 * @param {ExpensesEmptyStateActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	expensesEmptyStateActioned,
	/**
	 * User uses "Select all" or "Deselect all" checkboxes for export expenses
	 *
	 * @param {ExpensesInExportSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	expensesInExportSelected,
	/**
	 * User filtered expenses list
	 *
	 * @param {ExpensesListFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	expensesListFiltered,
	/**
	 * Button to explore pricing plans is clicked
	 *
	 * @param {ExplorePlansClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	explorePlansClicked,
	/**
	 * When a user clicks "Export again" for Exported expenses and see warning modal
	 *
	 * @param {ExportAgainModalActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportAgainModalActioned,
	/**
	 * When a user toggles the new export beta on or off using the beta banner
	 *
	 * @param {ExportBetaBannerActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportBetaBannerActioned,
	/**
	 * User, who is allowed to clear the date filter, has cleared it to the maximum range allowed
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportDateFilterClearedToMaxRange,
	/**
	 * User has attempted to select dates beyond the restricted range
	 *
	 * @param {ExportDatesBeyondRestrictedRangeSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportDatesBeyondRestrictedRangeSelected,
	/**
	 * Admin engages with the export filters
	 *
	 * @param {ExportFiltersActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportFiltersActioned,
	/**
	 * When a user uses the Export History filter on the Export Exported page
	 *
	 * @param {ExportHistoryActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportHistoryActioned,
	/**
	 * User clicks any of the bottom panel options on the export-beta page.
	 *
	 * @param {ExportLaneActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportLaneActioned,
	/**
	 * User clicks any of the top panel options on the export-beta page. That is "Unexported", "Export Queue", "Exported", "All", "Expenses", "Pending"
	 *
	 * @param {ExportNavigationActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportNavigationActioned,
	/**
	 * User interacts with elements on the export page
	 *
	 * @param {ExportPageActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportPageActioned,
	/**
	 * When bookkeeper is changing representation attendees
	 *
	 * @param {ExportRepresentationAttendeesActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportRepresentationAttendeesActioned,
	/**
	 * When bookkeeper is clicking calculate on representation
	 *
	 * @param {ExportRepresentationCalculateClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportRepresentationCalculateClicked,
	/**
	 * When bookkeeper is clicking done on representation
	 *
	 * @param {ExportRepresentationDoneClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportRepresentationDoneClicked,
	/**
	 * When bookkeeper is updating tax amount
	 *
	 * @param {ExportRepresentationTaxAmountUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportRepresentationTaxAmountUpdated,
	/**
	 * When bookkeeper is updating tips amount
	 *
	 * @param {ExportRepresentationTipsAmountUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportRepresentationTipsAmountUpdated,
	/**
	 * When bookkeeper is selecting representation type
	 *
	 * @param {ExportRepresentationTypeSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportRepresentationTypeSelected,
	/**
	 * Track when user selects a suggested value when exporting
	 *
	 * @param {ExportSuggestionSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportSuggestionSelected,
	/**
	 * The user is presented with "Something went wrong" as a result of an error loading the export table
	 *
	 * @param {ExportTableError} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportTableError,
	/**
	 * New export validation events
	 *
	 * @param {ExportValidationActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportValidationActioned,
	/**
	 * There was an error while loading an expense receipt
	 *
	 * @param {FailedToLoadReceipt} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	failedToLoadReceipt,
	/**
	 * Error while retrieving receipt document source
	 *
	 * @param {FailedToSourceReceipt} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	failedToSourceReceipt,
	/**
	 * User has clicked on the name or the CTA button of the feature in the feature discovery modal
	 *
	 * @param {FeatureClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	featureClicked,
	/**
	 * User started disabling the fetch
	 *
	 * @param {FetchOffboardingActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	fetchOffboardingActioned,
	/**
	 * Event fired whenever a user takes an action in the Fetch onboarding flow on web (similar event exists on mobile)
	 *
	 * @param {FetchOnboardingActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	fetchOnboardingActioned,
	/**
	 * Actions on the fetch promotion banner in the expense detail page
	 *
	 * @param {FetchPromotionBanner} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	fetchPromotionBanner,
	/**
	 * User tries to find their company in the company account creation in the onboarding flow
	 *
	 * @param {FindCompany} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	findCompany,
	/**
	 * When a user sees the Employee reimbursement intro popover
	 *
	 * @param {FirstReimburseYourselfFromWeb} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	firstReimburseYourselfFromWeb,
	/**
	 * Fires a 'Flow Completed (Appcues)' track call.
	 *
	 * @param {FlowCompletedAppcues} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	flowCompletedAppcues,
	/**
	 * Fires a 'Flow Started (Appcues)' track call.
	 *
	 * @param {FlowStartedAppcues} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	flowStartedAppcues,
	/**
	 * User clicks "Forgot passcode" while authenticating
	 *
	 * @param {ForgotPasscodeLinkActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	forgotPasscodeLinkActioned,
	/**
	 * Fires a 'Form Field Submitted (Appcues)' track call.
	 *
	 * @param {FormFieldSubmittedAppcues} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	formFieldSubmittedAppcues,
	/**
	 * Fires a 'Form Submitted (Appcues)' track call.
	 *
	 * @param {FormSubmittedAppcues} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	formSubmittedAppcues,
	/**
	 * Fires a 'Fortnox Accounting Setup Guided Tour' track call.
	 *
	 * @param {FortnoxAccountingSetupGuidedTour} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	fortnoxAccountingSetupGuidedTour,
	/**
	 * User views the Fortnox onboarding modal
	 *
	 * @param {FortnoxOnboardingModuleViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	fortnoxOnboardingModuleViewed,
	/**
	 * Dedicated card event triggered from the general modal to disable the cards (freeze, mark as stolen, etc)
	 *
	 * @param {FreezeCardModalActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	freezeCardModalActioned,
	/**
	 * User clicks on any of the links in the Get Started Guide
	 *
	 * @param {GetStartedActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	getStartedActioned,
	/**
	 * Each step of the Getting Started Guide has a call to action. This event will be fired on click of that CTA
	 *
	 * @param {GettingStartedGuideCtaActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	gettingStartedGuideCtaActioned,
	/**
	 * Event used for Google Ads Optimisation Signals. This event can have multiple triggers through the funnel and is owned by Team Performance Marketing
	 *
	 * @param {GoogleAdsOptimisationSignal} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	googleAdsOptimisationSignal,
	/**
	 * User clicks on the link on the "early access to people integrations" banner
	 *
	 * @param {HrisEarlyAccessBannerActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	hrisEarlyAccessBannerActioned,
	/**
	 * User dismissed the banner offering early access to the People Integrations feature
	 *
	 * @param {HrisEarlyAccessBannerDismissed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	hrisEarlyAccessBannerDismissed,
	/**
	 * User dismissed the early access modal
	 *
	 * @param {HrisEarlyAccessModalDismissed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	hrisEarlyAccessModalDismissed,
	/**
	 * User selected which HR system they use and submitted the early access modal
	 *
	 * @param {HrisEarlyAccessOptInSubmitted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	hrisEarlyAccessOptInSubmitted,
	/**
	 * When user clicks on the help centre link
	 *
	 * @param {HelpCentreLinkActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	helpCentreLinkActioned,
	/**
	 * User clicks on help widget
	 *
	 * @param {HelpWidgetActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	helpWidgetActioned,
	/**
	 * When user see adoption prompt
	 *
	 * @param {HelpWidgetViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	helpWidgetViewed,
	/**
	 * A user clicks on Create account or Sign in
	 *
	 * @param {IntroActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	introActioned,
	/**
	 * Fires a 'Intro Viewed' track call.
	 *
	 * @param {IntroViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	introViewed,
	/**
	 * Resent an invitation email
	 *
	 * @param {InvitationEmailResent} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	invitationEmailResent,
	/**
	 * User performs an action in the invite bookkeeper flow
	 *
	 * @param {InviteBookkeeperActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	inviteBookkeeperActioned,
	/**
	 * Admin/bk uses the bulk team select to apply a single team to many invitees
	 *
	 * @param {InviteFlowBulkAssignTeamActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	inviteFlowBulkAssignTeamActioned,
	/**
	 * Admin/Bk creates a new team from the create team drawer
	 *
	 * @param {InviteFlowCreateTeamActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	inviteFlowCreateTeamActioned,
	/**
	 * Admin/Bk closes the create team drawer without creating a team
	 *
	 * @param {InviteFlowCreateTeamDrawerClosed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	inviteFlowCreateTeamDrawerClosed,
	/**
	 * Admin/Bk opens the create team drawer
	 *
	 * @param {InviteFlowCreateTeamDrawerOpened} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	inviteFlowCreateTeamDrawerOpened,
	/**
	 * Admin/Bk uses the dropdown to select a team during the invite flow
	 *
	 * @param {InviteFlowSelectTeamActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	inviteFlowSelectTeamActioned,
	/**
	 * A user clicks on the invite provisioned user link
	 *
	 * @param {InvitePeopleLinkClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	invitePeopleLinkClicked,
	/**
	 * User performs an action in the invite team flow
	 *
	 * @param {InviteTeamActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	inviteTeamActioned,
	/**
	 * User performs an action on the invite team emails page
	 *
	 * @param {InviteTeamEmailActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	inviteTeamEmailActioned,
	/**
	 * The user exits the add user flow
	 *
	 * @param {InviteUserCancelled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	inviteUserCancelled,
	/**
	 * A user clicks to send invites
	 *
	 * @param {InviteUsers} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	inviteUsers,
	/**
	 * Invoice Adoption Prompt to learn more about how Invoices can benefit the company
	 *
	 * @param {InvoiceAdoptionPromptActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	invoiceAdoptionPromptActioned,
	/**
	 * Tracking of the restriction landing page being viewed
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	invoiceRestrictionLandingPageViewed,
	/**
	 * KYC continue button actioned
	 *
	 * @param {KycContinueActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kycContinueActioned,
	/**
	 * User interacts with KYC industry selector
	 *
	 * @param {KycIndustryActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kycIndustryActioned,
	/**
	 * KYC list actioned
	 *
	 * @param {KycListActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kycListActioned,
	/**
	 * User interacts with Kyc person in UBO step of the KYC flow
	 *
	 * @param {KycPersonActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kycPersonActioned,
	/**
	 * KYC section has been actioned
	 *
	 * @param {KycSectionActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kycSectionActioned,
	/**
	 * User interacts with KYC VAT section
	 *
	 * @param {KycVatActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	kycVatActioned,
	/**
	 * Fires a 'Landing Pages Eligible Viewed' track call.
	 *
	 * @param {LandingPagesEligibleViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	landingPagesEligibleViewed,
	/**
	 * Banner component showed in the billing page when the company is on a legacy plan
	 *
	 * @param {LegacyPlanBanner} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	legacyPlanBanner,
	/**
	 * A user clicks on the logout button in the account menu item
	 *
	 * @param {LogOutClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	logOutClicked,
	/**
	 * Event to track the changes of the Auto Top-up settings
	 *
	 * @param {ManageAutoTopUp} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	manageAutoTopUp,
	/**
	 * Fires a 'Manage Wallet Viewed' track call.
	 *
	 * @param {ManageWalletViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	manageWalletViewed,
	/**
	 * User has successfully added a manual expense
	 *
	 * @param {ManualExpenseAdded} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	manualExpenseAdded,
	/**
	 * When within the Teams tab of a user page a team is added in the reviewer box
	 *
	 * @param {MemberSettingReviewingComplete} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	memberSettingReviewingComplete,
	/**
	 * When within the Teams tab of a user page the reviewer drop down list is open
	 *
	 * @param {MemberSettingReviewingStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	memberSettingReviewingStarted,
	/**
	 * triggered when an admin or bookkeeper open and edit an employee setting on the People page
	 *
	 * @param {MemberSettingsActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	memberSettingsActioned,
	/**
	 * When within the Teams tab of a member  settings a team is removed from the reviewer box
	 *
	 * @param {MemberSettingsReviewerRemoved} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	memberSettingsReviewerRemoved,
	/**
	 * The team of the employee is updated within the member settings
	 *
	 * @param {MemberSettingsTeamUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	memberSettingsTeamUpdated,
	/**
	 * Event used for Meta Ads Optimisation Signals
	 *
	 * @param {MetaAdsOptimisationSignal} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	metaAdsOptimisationSignal,
	/**
	 * The new event for when the company default rate got updated
	 *
	 * @param {MileageAccountUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	mileageAccountUpdated,
	/**
	 * Event triggered when the admin updated the default mileage compensation rate for the company.
	 *
	 * @param {MileageDefaultRateUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	mileageDefaultRateUpdated,
	/**
	 * Admin clicks on the 'Disable' button on the disable mileage modal
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	mileageDisabled,
	/**
	 * Sent when the admin interacts with the mileage download option on the Mileage settings page on web.
	 *
	 * @param {MileageDownloadActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	mileageDownloadActioned,
	/**
	 * Event triggered when an individual mileage rate for an employee is updated.
	(On web, this is an admin/bookkeeper action. We'll have the same event on mobile where employees update their own rate)
	 *
	 * @param {MileageEmployeeRateUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	mileageEmployeeRateUpdated,
	/**
	 * Admin completed the step that enabled Mileage for employees
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	mileageEnabled,
	/**
	 * Triggered when an admin takes an action on the list of employees with mileage expenses on web.
	 *
	 * @param {MileageListActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	mileageListActioned,
	/**
	 * The new event for when the company default rate got updated
	 *
	 * @param {MileageRateUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	mileageRateUpdated,
	/**
	 * User clicks 'Submit' in the 'Missing a receipt?' template
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	missingReceiptSubmitted,
	/**
	 * User tries to export an expense but hasn't completed required settings
	 *
	 * @param {MissingSettingsExportModal} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	missingSettingsExportModal,
	/**
	 * Occurs when a missing translation is detected by the i18n library provider.
	 *
	 * @param {MissingTranslation} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	missingTranslation,
	/**
	 * A user clicks on the my accounts menu item
	 *
	 * @param {MyAccountClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	myAccountClicked,
	/**
	 * A user clicks on the my cards account menu item
	 *
	 * @param {MyCardsClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	myCardsClicked,
	/**
	 * Events for the banner announcing the new pricing migration coming up in November 2024
	 *
	 * @param {NewPricingBanner} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newPricingBanner,
	/**
	 * When user clicks create purchase form
	 *
	 * @param {NewPurchaseOrderActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newPurchaseOrderActioned,
	/**
	 * Vendor card creation drawer events
	
	 *
	 * @param {NewVendorCardActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newVendorCardActioned,
	/**
	 * User interacts with the off-boarding modal
	 *
	 * @param {OffboardingModalActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offboardingModalActioned,
	/**
	 * The user interacts with the offboarding wizard
	 *
	 * @param {OffboardingWizardActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offboardingWizardActioned,
	/**
	 * User clicks on Add many link to input multiple emails at once on /pleo-guide/onboard-team
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	onboardTeamAddManyActioned,
	/**
	 * User changed the value for default limits on cards on /pleo-guide/onboard-team
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	onboardTeamSetLimitCompleted,
	/**
	 * User changed the value for default limits on cards on /pleo-guide/onboard-team
	 *
	 * @param {OnboardTeamSpendingLimitActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	onboardTeamSpendingLimitActioned,
	/**
	 * Activation Banner event when button clicked. Keeping track of the location the event took place and the action triggered.
	 *
	 * @param {OnboardingBannerClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	onboardingBannerClicked,
	/**
	 * User interactions with Bank Transfer details during Wallet Top up onboarding
	 *
	 * @param {OnboardingWalletBankTransferTopUp} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	onboardingWalletBankTransferTopUp,
	/**
	 * User interactions with Toping Up via Open Banking
	 *
	 * @param {OpenBankingModalActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	openBankingModalActioned,
	/**
	 * User performs an action on the order cards page
	 *
	 * @param {OrderCardsActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderCardsActioned,
	/**
	 * User has clicked 'Confirm order' and the order has been successfully placed
	 *
	 * @param {OrderCardsCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderCardsCompleted,
	/**
	 * User clicks 'Continue' on the Plastics cards page for card orders titled 'Select quantity'
	 *
	 * @param {OrderCardsStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderCardsStarted,
	/**
	 * Event used as the Facebook "Purchase"-Event used at multiple stages of the commercial web + product web funnel.
	 *
	 * @param {OrderCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderCompleted,
	/**
	 * User opens the card order employee selection form
	 *
	 * @param {OrderPlasticCardsList} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderPlasticCardsList,
	/**
	 * The user interacts with the outstanding fees banner
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	outstandingFeesBannerActioned,
	/**
	 * Fires a 'Overdraft Banner Actioned' track call.
	 *
	 * @param {OverdraftBannerActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	overdraftBannerActioned,
	/**
	 * Fires a 'Overdraft Confirmation Actioned' track call.
	 *
	 * @param {OverdraftConfirmationActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	overdraftConfirmationActioned,
	/**
	 * Fires a 'Overdraft Eligibility Application' track call.
	 *
	 * @param {OverdraftEligibilityApplication} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	overdraftEligibilityApplication,
	/**
	 * Fires a 'Overdraft Error Viewed' track call.
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	overdraftErrorViewed,
	/**
	 * Fires a 'Overdraft Higher Limit' track call.
	 *
	 * @param {OverdraftHigherLimit} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	overdraftHigherLimit,
	/**
	 * Fires a 'Overdraft Onboarding Accordion Item' track call.
	 *
	 * @param {OverdraftOnboardingAccordionItem} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	overdraftOnboardingAccordionItem,
	/**
	 * Fires a 'Overdraft Renewal Actioned' track call.
	 *
	 * @param {OverdraftRenewalActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	overdraftRenewalActioned,
	/**
	 * Fires a 'Overdraft Settings' track call.
	 *
	 * @param {OverdraftSettings} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	overdraftSettings,
	/**
	 * Fires a 'Overdraft Statements Actioned' track call.
	 *
	 * @param {OverdraftStatementsActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	overdraftStatementsActioned,
	/**
	 * Fires a 'Overdraft Summary Actioned' track call.
	 *
	 * @param {OverdraftSummaryActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	overdraftSummaryActioned,
	/**
	 * Fires a 'Overdraft Terms Actioned' track call.
	 *
	 * @param {OverdraftTermsActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	overdraftTermsActioned,
	/**
	 * Fires a 'Overdraft Underwriting Application Actioned' track call.
	 *
	 * @param {OverdraftUnderwritingApplicationActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	overdraftUnderwritingApplicationActioned,
	/**
	 * User views Plastic Card PIN after successfully having entered their passcode in the 'View PIN' pop-up
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pinViewed,
	/**
	 * User views any page in the product web app. The page will be named after what comes after the first slash in the url. Ex...
	 *
	 * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * Event fired when a user watches a video in the Partner academy
	 *
	 * @param {PartnerAcademy} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerAcademy,
	/**
	 * Partner user changes the bookkeepers assigned to a given client, within the partner client details drawer, general tab.
	 *
	 * @param {PartnerClientBookkeepersChanged} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerClientBookkeepersChanged,
	/**
	 * Partner creates a company for a new client
	 *
	 * @param {PartnerClientCreated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerClientCreated,
	/**
	 * The user clicks on Disconnect Client within the Partner Client Details Drawer.
	 *
	 * @param {PartnerClientDetailsDrawerDisconnectClient} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerClientDetailsDrawerDisconnectClient,
	/**
	 * The user closes the Partner Client Details Drawer.
	 *
	 * @param {PartnerClientDetailsDrawerDismissed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerClientDetailsDrawerDismissed,
	/**
	 * Partner user navigates to the next or previous client using the navigation buttons of the partner client details drawer.
	 *
	 * @param {PartnerClientDetailsDrawerNavigationButtonsActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerClientDetailsDrawerNavigationButtonsActioned,
	/**
	 * User takes action on the Partner Client Details Drawer Permission section of the Settings Tab.
	 *
	 * @param {PartnerClientDetailsDrawerPermissionsSettingsActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerClientDetailsDrawerPermissionsSettingsActioned,
	/**
	 * Tab of the Partner Client Details drawer clicked.
	 *
	 * @param {PartnerClientDetailsDrawerTabsActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerClientDetailsDrawerTabsActioned,
	/**
	 * This event is fired when the user intertacts with the status filter and sort options in the clients list
	 *
	 * @param {PartnerClientListFilters} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerClientListFilters,
	/**
	 * Action buttons event of the partner client onboarding success modal.
	 *
	 * @param {PartnerClientOnboardingSuccessModalActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerClientOnboardingSuccessModalActioned,
	/**
	 * Fires a 'Partner Employee Csv Offboard Actioned' track call.
	 *
	 * @param {PartnerEmployeeCsvOffboardActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerEmployeeCsvOffboardActioned,
	/**
	 * Fires a 'Partner Employee Export Actioned' track call.
	 *
	 * @param {PartnerEmployeeExportActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerEmployeeExportActioned,
	/**
	 * Action fired on the Partner Employee Invite Form
	 *
	 * @param {PartnerEmployeeInviteFormActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerEmployeeInviteFormActioned,
	/**
	 * The event is triggered the moment the invite is accepted, i.e. the partner employee (and potentially partner) has been created in the database
	 *
	 * @param {PartnerInviteAccepted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerInviteAccepted,
	/**
	 * Event is fired whenever the a partner invite flow is actioned. See properties for details on actions and types.
	 *
	 * @param {PartnerInviteFlowActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerInviteFlowActioned,
	/**
	 * Options menu of the partner KYC HUB Onboarding tab was actioned.
	 *
	 * @param {PartnerOnboardingClientOptionsMenuActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerOnboardingClientOptionsMenuActioned,
	/**
	 * When a user gives feedback in the partner portal
	 *
	 * @param {PartnerPortalFeedback} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerPortalFeedback,
	/**
	 * Event fired when a pricing quote request is made. The default quote on page load is ignored
	 *
	 * @param {PartnerPricingCalculator} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerPricingCalculator,
	/**
	 * This event is fired when the user intertacts with the Partner Promo
	 *
	 * @param {PartnerPromo} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerPromo,
	/**
	 * This event will fire when the user interacts with the Partner Self Onboarding Drawer. The performed actions are described in the event payload.
	 *
	 * @param {PartnerSelfOnboardingDrawerActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerSelfOnboardingDrawerActioned,
	/**
	 * This event will fire when the user interacts with a Partner Self Onboarding Task. The performed actions are described in the event payload.
	 *
	 * @param {PartnerSelfOnboardingTaskActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerSelfOnboardingTaskActioned,
	/**
	 * Tracks the actions the user performs when completing the Invite Employee step of the Partner Self Signup flow.
	 *
	 * @param {PartnerSelfSignupEmployeesInvited} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerSelfSignupEmployeesInvited,
	/**
	 * This event is fired when the user completes the partner self signup flow and the partnership is successfully created.
	 *
	 * @param {PartnerSelfSignupPartnershipCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerSelfSignupPartnershipCreated,
	/**
	 * This event is fired when the user updates their details on the partner self signup flow.
	 *
	 * @param {PartnerSelfSignupUserDetails} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerSelfSignupUserDetails,
	/**
	 * Fires a 'Partner Team Actioned' track call.
	 *
	 * @param {PartnerTeamActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerTeamActioned,
	/**
	 * Fires a 'Partner Team Client Actioned' track call.
	 *
	 * @param {PartnerTeamClientActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerTeamClientActioned,
	/**
	 * Fires a 'Partner Team Member Actioned' track call.
	 *
	 * @param {PartnerTeamMemberActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	partnerTeamMemberActioned,
	/**
	 * A user fails to confirm their new passcode. A user attempts to repeat their passcode but fails
	 *
	 * @param {PasscodeResetConfirmPasscodeFailed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	passcodeResetConfirmPasscodeFailed,
	/**
	 * A user must enter a OTP to continue their passcode reset
	 *
	 * @param {PasscodeResetEnterVerificationCode} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	passcodeResetEnterVerificationCode,
	/**
	 * A user tried to reset their passcode from a link in their email but it has expired
	 *
	 * @param {PasscodeResetLinkExpiredScreenViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	passcodeResetLinkExpiredScreenViewed,
	/**
	 * A user must logout of their existing accounts to continue the passcode reset flow
	 *
	 * @param {PasscodeResetLogoutToContinueScreenViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	passcodeResetLogoutToContinueScreenViewed,
	/**
	 * A user successfully reset their passcode
	 *
	 * @param {PasscodeResetSuccess} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	passcodeResetSuccess,
	/**
	 * Anytime a user successfully enters their passcode
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	passcodeSuccessfullyEntered,
	/**
	 * The user views and navigates within the paywall
	 *
	 * @param {PaywallActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paywallActioned,
	/**
	 * The user submits feedback from within the paywall
	 *
	 * @param {PaywallFeedbackSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paywallFeedbackSubmitted,
	/**
	 * Fires a 'Pending Overdraft Actioned' track call.
	 *
	 * @param {PendingOverdraftActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pendingOverdraftActioned,
	/**
	 * The People page is viewed
	 *
	 * @param {PeoplePageViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	peoplePageViewed,
	/**
	 * User performs an action in creating a user passcode
	 *
	 * @param {PhoneNumberStepActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	phoneNumberStepActioned,
	/**
	 * User has enabled or disabled the Pleo Bank Feed (Xero)
	 *
	 * @param {PleoBankFeed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pleoBankFeed,
	/**
	 * Small card component on the right in Pleo Guide page
	 *
	 * @param {PleoGuideReserveCardActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pleoGuideReserveCardActioned,
	/**
	 * Main card component on the left in Pleo Guide page
	 *
	 * @param {PleoGuideReserveProductCardActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pleoGuideReserveProductCardActioned,
	/**
	 * User has viewed the pleo guide
	 *
	 * @param {PleoGuideViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pleoGuideViewed,
	/**
	 * Top banner asking user to pay back owed money
	 *
	 * @param {PleoReserveCollectionsBannerActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pleoReserveCollectionsBannerActioned,
	/**
	 * Modal asking user to pay back owed money
	 *
	 * @param {PleoReserveCollectionsModalActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pleoReserveCollectionsModalActioned,
	/**
	 * Modal to pick top up method
	 *
	 * @param {PleoReserveCollectionsTopUpMethodActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pleoReserveCollectionsTopUpMethodActioned,
	/**
	 * User clicks on How Does It Work link
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pleoReserveHowDoesItWorkClicked,
	/**
	 * Activation of Pleo Reserve onboarding modal
	 *
	 * @param {PleoReserveOnboardingModalActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pleoReserveOnboardingModalActioned,
	/**
	 * User updates pocket contra account
	 *
	 * @param {PocketAccountActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketAccountActioned,
	/**
	 * Pocket Adoption Prompt to learn more about how Pocket can benefit the company
	 *
	 * @param {PocketAdoptionPromptActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketAdoptionPromptActioned,
	/**
	 * Fires a 'Pocket Balance Actioned' track call.
	 *
	 * @param {PocketBalanceActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketBalanceActioned,
	/**
	 * User has edited the pocket date on the export page
	 *
	 * @param {PocketDateInExportActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketDateInExportActioned,
	/**
	 * This event will be fired once company gets Pocket Easy Start experience (via Launch Darkly) and admin selects integration. At the moment we enable Pocket. We plan to use this event to have the list of companies which got  Pocket Easy Start experience
	 *
	 * @param {PocketEasyStartAssigned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketEasyStartAssigned,
	/**
	 * Admin completed the step that enabled the ability to reimburse from wallet
	 *
	 * @param {PocketEnabled} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketEnabled,
	/**
	 * Fires a 'Pocket Manual Transfer Modal Actioned' track call.
	 *
	 * @param {PocketManualTransferModalActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketManualTransferModalActioned,
	/**
	 * User sees LexOffice Warning
	 *
	 * @param {PocketNotCompatibleWithLexOfficeModal} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketNotCompatibleWithLexOfficeModal,
	/**
	 * User performs an action in Pocket Guide
	 *
	 * @param {PocketOnboardingActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketOnboardingActioned,
	/**
	 * User interacts with the pocket page
	 *
	 * @param {PocketPageActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketPageActioned,
	/**
	 * User interactions with Pocket page filters
	 *
	 * @param {PocketPageFilterActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketPageFilterActioned,
	/**
	 * User interacts with introduction modals
	 *
	 * @param {PocketPageModalActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketPageModalActioned,
	/**
	 * User performs an action in Pocket Accounting Guide
	
	 *
	 * @param {PocketSetupBannerActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketSetupBannerActioned,
	/**
	 * Fired when the pocket setup flow got cancelled
	 *
	 * @param {PocketSetupCancelled} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketSetupCancelled,
	/**
	 * Admin started Pocket setup from either the accounting settings, the Pocket page or the Mileage page
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketSetupStarted,
	/**
	 * Pocket statement download flow triggered by admin/bookkeeper on Pocket page
	 *
	 * @param {PocketStatementDownloadActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketStatementDownloadActioned,
	/**
	 * User marks pocket expenses as settled
	 *
	 * @param {PocketTransferActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketTransferActioned,
	/**
	 * User has successfully submitted a pocket transfer (whether is it is received cash or reimbursed)
	 *
	 * @param {PocketTransferAdded} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketTransferAdded,
	/**
	 * User interacts with the pocket transfer onboarding guide
	 *
	 * @param {PocketTransferNewDesignModalActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketTransferNewDesignModalActioned,
	/**
	 * User views a step in the pocket transfer flow
	 *
	 * @param {PocketTransferStepViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketTransferStepViewed,
	/**
	 * Admin started Pocket video
	 *
	 * @param {PocketVideoViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pocketVideoViewed,
	/**
	 * The users interacts with any of the links that lead to more details on Pleo's pricing
	 *
	 * @param {PricingEnquiryLinkActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pricingEnquiryLinkActioned,
	/**
	 * Fires a 'Product Usage Actioned' track call.
	 *
	 * @param {ProductUsageActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productUsageActioned,
	/**
	 * Employee’s details like employee first name, last name, job title and phone number are updated
	 *
	 * @param {ProfileDetailsUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	profileDetailsUpdated,
	/**
	 * Fires a 'Projected Spend Widget' track call.
	 *
	 * @param {ProjectedSpendWidget} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	projectedSpendWidget,
	/**
	 * User interacts with the upsell/promo card
	 *
	 * @param {PromoCardActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	promoCardActioned,
	/**
	 * When the user edits a already created purchase order
	 *
	 * @param {PurchaseOrderActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	purchaseOrderActioned,
	/**
	 * When purchase order is reviewed by a reviewer
	 *
	 * @param {PurchaseOrderReviewActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	purchaseOrderReviewActioned,
	/**
	 * Pleo-guide:: user hovered account number hint
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	questionIconAccountNumberViewed,
	/**
	 * User has clicked the question icon in the bottom right corner of any page in the web app
	 *
	 * @param {QuestionIconActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	questionIconActioned,
	/**
	 * Pleo-guide:: user hovered bank name hint
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	questionIconBankNameViewed,
	/**
	 * User enters into the re-carding flow (app.pleo.io/newcards). Each step in the flow triggers this event. The property will indicate which step was visited.
	 *
	 * @param {RecardingOrderStepViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	recardingOrderStepViewed,
	/**
	 * User has clicked on Copy address in expense page to forward his receipt from his emails
	 *
	 * @param {ReceiptForwarding} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	receiptForwarding,
	/**
	 * Event made on the receipt in Export page
	 *
	 * @param {ReceiptInExportPageActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	receiptInExportPageActioned,
	/**
	 * User has rotated a receipt image
	 *
	 * @param {ReceiptRotation} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	receiptRotation,
	/**
	 * User scaling the receipt in Export page
	 *
	 * @param {ReceiptScalingInExportPage} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	receiptScalingInExportPage,
	/**
	 * events for user interactions on recommendation prompt displayed in expense details drawer and review details drawer
	 *
	 * @param {RecommendationPromptActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	recommendationPromptActioned,
	/**
	 * A user clicks to reconnect an app
	 *
	 * @param {ReconnectApp} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reconnectApp,
	/**
	 * recurring vendor detail drawer tracking events
	 *
	 * @param {RecurringVendorDetailsPageActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	recurringVendorDetailsPageActioned,
	/**
	 * recurring vendors page tracking events
	 *
	 * @param {RecurringVendorsPageActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	recurringVendorsPageActioned,
	/**
	 * Track actions on sending referral invitations by email
	 *
	 * @param {ReferralEmailActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	referralEmailActioned,
	/**
	 * Track clicks on referrals link in the account screen
	 *
	 * @param {ReferralInviteActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	referralInviteActioned,
	/**
	 * Track actions on the referral link
	 *
	 * @param {ReferralLinkActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	referralLinkActioned,
	/**
	 * User clicks on "How it works" in Referrals dashboard
	 *
	 * @param {ReferralsHelpWidgetClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	referralsHelpWidgetClicked,
	/**
	 * Fires a 'Reimbursement Article Clicked' track call.
	 *
	 * @param {ReimbursementArticleClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reimbursementArticleClicked,
	/**
	 * Fires a 'Reimbursement Deleted' track call.
	 *
	 * @param {ReimbursementDeleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reimbursementDeleted,
	/**
	 * User interacts with the Direct Reimbursement onboarding flow
	 *
	 * @param {ReimbursementOnboardingActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reimbursementOnboardingActioned,
	/**
	 * User cancels the Direct Reimbursement onboarding flow
	 *
	 * @param {ReimbursementOnboardingCancelled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reimbursementOnboardingCancelled,
	/**
	 * The user updates the Reimbursement Permissions
	 *
	 * @param {ReimbursementPermissionTypeSet} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reimbursementPermissionTypeSet,
	/**
	 * The user interacts with the Reimbursement Settings page
	 *
	 * @param {ReimbursementSettingsPageActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reimbursementSettingsPageActioned,
	/**
	 * Fires a 'Reimbursements Projection Widget' track call.
	 *
	 * @param {ReimbursementsProjectionWidget} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reimbursementsProjectionWidget,
	/**
	 * Triggered in accounting settings after first manual reimbursement has been made
	 *
	 * @param {RepayAccountActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	repayAccountActioned,
	/**
	 * Triggered when an employee performs a reimbursement
	 *
	 * @param {RepayActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	repayActioned,
	/**
	 * Triggered when a user adds a reimbursement method
	 *
	 * @param {RepayMethodsActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	repayMethodsActioned,
	/**
	 * Triggered when admin interacts on web with the flow to enable the functionality to repay an outstanding Pocket balance.
	 *
	 * @param {RepayOnboardingActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	repayOnboardingActioned,
	/**
	 * Triggered when an employee adds a bank account for reimbursement
	 *
	 * @param {RepayToBankAccountActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	repayToBankAccountActioned,
	/**
	 * Trigger when an employee adds a personal card for reimbursement
	 *
	 * @param {RepayToCardActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	repayToCardActioned,
	/**
	 * User clicks the "Reports" link to see the reports views on the export page
	 *
	 * @param {ReportsLinkInExportActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reportsLinkInExportActioned,
	/**
	 * Navigating Action to Requests
	 *
	 * @param {RequestNavigationActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	requestNavigationActioned,
	/**
	 * A user entered their email to request a passcode reset link
	 *
	 * @param {RequestPasscodeResetLink} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	requestPasscodeResetLink,
	/**
	 * Accordion item in the right side of the Wallet page
	 *
	 * @param {ReserveAccordionItemActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reserveAccordionItemActioned,
	/**
	 * Fires a 'Reserve Test Pre Fdd Page Actioned' track call.
	 *
	 * @param {ReserveTestPreFddPageActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reserveTestPreFddPageActioned,
	/**
	 * An admin clicks the reset passcode button on the member detail from the people page
	 *
	 * @param {ResetPasscodeButtonClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	resetPasscodeButtonClicked,
	/**
	 * User uses the review function
	 *
	 * @param {ReviewActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reviewActioned,
	/**
	 * When the review is completed (when any review status is submitted)
	 *
	 * @param {ReviewCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reviewCompleted,
	/**
	 * User clicks on a Review type
	 *
	 * @param {ReviewOverviewClickthrough} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reviewOverviewClickthrough,
	/**
	 * Visit the Review Overview Settings page
	 *
	 * @param {ReviewOverviewViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reviewOverviewViewed,
	/**
	 * Triggered when a user updates the review settings
	 *
	 * @param {ReviewSettingsUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reviewSettingsUpdated,
	/**
	 * When purchase order is reviewed by a reviewer
	 *
	 * @param {ReviewTabActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reviewTabActioned,
	/**
	 * User adds a reviewer within a team or directly in the member edit module
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reviewerAdded,
	/**
	 * Editing of reviewer threshold is completed
	 *
	 * @param {ReviewerThresholdUpdateCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reviewerThresholdUpdateCompleted,
	/**
	 * Editing of reviewer threshold is started
	 *
	 * @param {ReviewerThresholdUpdateStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reviewerThresholdUpdateStarted,
	/**
	 * Reviewers tab with call-to-action to configure reviews when there are no reviews configured clicked.
	 *
	 * @param {ReviewersTabReviewCtaClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reviewersTabReviewCtaClicked,
	/**
	 * Reviewers tab with call-to-action to configure reviews when there are no reviews configured viewed.
	 *
	 * @param {ReviewersTabReviewCtaViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reviewersTabReviewCtaViewed,
	/**
	 * The Reviewers tab is viewed
	 *
	 * @param {ReviewersTabViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reviewersTabViewed,
	/**
	 * Apply is clicked in the role filter button in the People page
	 *
	 * @param {RoleFilterCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	roleFilterCompleted,
	/**
	 * The role filter button in the People page is clicked
	 *
	 * @param {RoleFilterStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	roleFilterStarted,
	/**
	 * A user clicks on add exclusion button
	 *
	 * @param {SamlSsoAddExclusionClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	samlSsoAddExclusionClicked,
	/**
	 * A user clicks on the deactivate SAML SSO menu item
	 *
	 * @param {SamlSsoDeactivateClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	samlSsoDeactivateClicked,
	/**
	 * A user clicks on the enable SAML SSO checkbox
	 *
	 * @param {SamlSsoEnableClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	samlSsoEnableClicked,
	/**
	 * A user clicks on the SAML SSO IdP integration app
	 *
	 * @param {SamlSsoIdPClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	samlSsoIdPClicked,
	/**
	 * A user clicks on the tab of SAML SSO IdP integration
	 *
	 * @param {SamlSsoIdPTabVisited} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	samlSsoIdPTabVisited,
	/**
	 * A user clicks on remove exclusion button
	 *
	 * @param {SamlSsoRemoveExclusionClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	samlSsoRemoveExclusionClicked,
	/**
	 * A user clicks on the Save SAML SSO config button
	 *
	 * @param {SamlSsoSaveConfigClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	samlSsoSaveConfigClicked,
	/**
	 * A user clicks on the SAML SSO upgrade button
	 *
	 * @param {SamlSsoUpgradeClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	samlSsoUpgradeClicked,
	/**
	 * A user visits SAML SSO home page
	 *
	 * @param {SamlSsoVisited} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	samlSsoVisited,
	/**
	 * User is presented an onboarding flow to SCA when they are not yet enabled to go through and verify their phone number. We want to track that they start this journey
	 *
	 * @param {ScaOnboardingActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	scaOnboardingActioned,
	/**
	 * On the SCA onboarding flow, we want to see how many complete it by clicking "Done" in the end of the flow and are then switched to SCA automatically
	 *
	 * @param {ScaOnboardingCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	scaOnboardingCompleted,
	/**
	 * On the SCA onboarding flow we have a skip -button. We want to follow how many people skip the flow
	 *
	 * @param {ScaOnboardingSkipped} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	scaOnboardingSkipped,
	/**
	 * the search bar in the subscription page has been actioned
	 *
	 * @param {SearchBarActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchBarActioned,
	/**
	 * A user clicks on "See all available integrations" in the people apps promo
	 *
	 * @param {SeeAllAvailableIntegrationsClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	seeAllAvailableIntegrationsClicked,
	/**
	 * Fires a 'Select All Clicked' track call.
	 *
	 * @param {SelectAllClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	selectAllClicked,
	/**
	 * When user interacts with “Select all” checkbox on Expenses page
	 *
	 * @param {SelectAllExpensesActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	selectAllExpensesActioned,
	/**
	 * A user clicks on an app's tile
	 *
	 * @param {SelectApp} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	selectApp,
	/**
	 * User selects a company type in the company creation flow of a manually signed up company
	 *
	 * @param {SelectCompanyBusinessType} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	selectCompanyBusinessType,
	/**
	 * A user closes the country dropdown without selecting a country
	 *
	 * @param {SelfExpansionCountrySelectAbandoned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	selfExpansionCountrySelectAbandoned,
	/**
	 * A user clicks the help article that describes multi entity self expansion
	 *
	 * @param {SelfExpansionHelpArticleClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	selfExpansionHelpArticleClicked,
	/**
	 * A user views the self expansion video on the modal
	 *
	 * @param {SelfExpansionInfoVideoViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	selfExpansionInfoVideoViewed,
	/**
	 * A user closes the self expansion modal
	 *
	 * @param {SelfExpansionModalClosed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	selfExpansionModalClosed,
	/**
	 * A user opens the self expansion modal
	 *
	 * @param {SelfExpansionModalOpened} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	selfExpansionModalOpened,
	/**
	 * A user clicks the link to explore multi entity pricing plans
	 *
	 * @param {SelfExpansionMultiEntityPricingPlansClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	selfExpansionMultiEntityPricingPlansClicked,
	/**
	 * A user sends a request for self initiated expansion
	 *
	 * @param {SelfExpansionRequestSent} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	selfExpansionRequestSent,
	/**
	 * User sees the add entity step, adds a company, manually creates a company, selects or clears the size of the company, confirms the company details, or completes the step
	 *
	 * @param {SelfExpansionSignupAddEntityStepActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	selfExpansionSignupAddEntityStepActioned,
	/**
	 * User sees the organization name step or completed it
	 *
	 * @param {SelfExpansionSignupOrganizationNameStepActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	selfExpansionSignupOrganizationNameStepActioned,
	/**
	 * The user clicks the signup success button
	 *
	 * @param {SelfExpansionSignupSuccessButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	selfExpansionSignupSuccessButtonClicked,
	/**
	 * A user clicks the self expansion signup USA notification button
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	selfExpansionSignupUsaNotificationButtonClicked,
	/**
	 * A user clicks the link to view and compare all pricing plans from the multi entity pricing plan modal
	 *
	 * @param {SelfExpansionViewAllPricingPlansClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	selfExpansionViewAllPricingPlansClicked,
	/**
	 * Sequential Review setting has been toggled by the user
	 *
	 * @param {SequentialReviewSettingToggled} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sequentialReviewSettingToggled,
	/**
	 * The "Upgrade your plan modal" is displayed for Sequential Review
	 *
	 * @param {SequentialReviewUpsellViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sequentialReviewUpsellViewed,
	/**
	 * Fires a 'Set Limits Actioned' track call.
	 *
	 * @param {SetLimitsActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	setLimitsActioned,
	/**
	 * Fires a 'Setup Accounting Categories Product Tour' track call.
	 *
	 * @param {SetupAccountingCategoriesProductTour} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	setupAccountingCategoriesProductTour,
	/**
	 * When setup accounting is completed from Get started guide and a modal is shown
	 *
	 * @param {SetupAccountingCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	setupAccountingCompleted,
	/**
	 * Fires a 'Setup Accounting Confirmation Modal Actioned' track call.
	 *
	 * @param {SetupAccountingConfirmationModalActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	setupAccountingConfirmationModalActioned,
	/**
	 * Tracking events on our new "Invite Book-keeper" Pop up Modal and the existing Book-keeper invite flow.
	 *
	 * @param {SetupAccountingPersonaActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	setupAccountingPersonaActioned,
	/**
	 * User performs an action on the setup accounting settings page
	 *
	 * @param {SetupAccountingSettingsActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	setupAccountingSettingsActioned,
	/**
	 * The user has clicked on Setup Accounting from the Pleo Guide (the middle card).
	 *
	 * @param {SetupAccountingStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	setupAccountingStarted,
	/**
	 * A user arrives to Sign up your company screen
	 *
	 * @param {SignInNoAccount} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signInNoAccount,
	/**
	 * A user arrives to Sign up your company screen
	 *
	 * @param {SignUpCompanySignUp} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signUpCompanySignUp,
	/**
	 * A user arrives to Sign up your company screen
	 *
	 * @param {SignUpNeedAnInvite} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signUpNeedAnInvite,
	/**
	 * The register new company signup flow was actioned
	 *
	 * @param {SignupActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signupActioned,
	/**
	 * When the request to create the virtual card succeeded on /register/billing. Track if errors happen when creating virtual cards and linking them for billing
	 *
	 * @param {SignupBillingCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signupBillingCompleted,
	/**
	 * Click on continue after choosing a company on /register/company
	 *
	 * @param {SignupCompanyCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signupCompanyCompleted,
	/**
	 * Dropdown to choose the country of the company on /register/company. Do we have customers that need to change the country of the company they are registering
	 *
	 * @param {SignupCountryUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signupCountryUpdated,
	/**
	 * User fills the confirmation code and clicks Verify on /register/user/verification. When a user had to input a confirmation code sent by email.
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signupEmailCompleted,
	/**
	 * Signal used in the signup flow for performance marketing conversion tracking
	 *
	 * @param {SignupFunnelSignalEvent} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signupFunnelSignalEvent,
	/**
	 * When the user enters or moves on the next screen in /register flow
	 *
	 * @param {SignupStepOpened} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signupStepOpened,
	/**
	 * User clicks 'Accept and Continue' on the billing page
	 *
	 * @param {SignupSubmitted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signupSubmitted,
	/**
	 * User has clicked 'Accept and Continue' on the billing page and successfully entered Pleo for the first time
	 *
	 * @param {SignupSucceeded} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signupSucceeded,
	/**
	 * After company creation, users are prompted to either continue to the Pleo Guide, or head straight to account activation (FDD flow)
	 *
	 * @param {SignupSuccessButtonClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signupSuccessButtonClicked,
	/**
	 * Event to track all the actions done by the spend activation project
	 *
	 * @param {SpendActivation} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	spendActivation,
	/**
	 * Event to track the progress for the spend activation setup flow
	 *
	 * @param {SpendActivationSetup} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	spendActivationSetup,
	/**
	 * Event to track all the actions done in relation to the Spend Activation Templates project
	 *
	 * @param {SpendActivationTemplates} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	spendActivationTemplates,
	/**
	 * Track user actions on core spend guidelines
	 *
	 * @param {SpendGuidelinesActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	spendGuidelinesActioned,
	/**
	 * Event for when the spend guidelines test banner was clicked
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	spendGuidelinesTestClicked,
	/**
	 * Event for question 1 of the spend guidelines testing form
	 *
	 * @param {SpendGuidelinesTestQ1} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	spendGuidelinesTestQ1,
	/**
	 * Event for question 2 to 4 of the spend guidelines testing form
	 *
	 * @param {SpendGuidelinesTestQ2Q4} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	spendGuidelinesTestQ2Q4,
	/**
	 * Event for when the spend guidelines test banner appears
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	spendGuidelinesTestViewed,
	/**
	 * To track page views
	 *
	 * @param {SpendGuidelinesViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	spendGuidelinesViewed,
	/**
	 * The event is sent every time either of the three settings (spending_group, spending_value, or per_purchase_value) are changed.
	 *
	 * @param {SpendingLimitSettingsActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	spendingLimitSettingsActioned,
	/**
	 * User interacts with the split expense in the expense detail page on new export page
	 *
	 * @param {SplitExpenseActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	splitExpenseActioned,
	/**
	 * Accessible from the Pleo Guide page or the Wallet, it lets user pick whether they want to go with Credit or Wallet Top-up to start spending
	 *
	 * @param {StartSpendingPageActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	startSpendingPageActioned,
	/**
	 * User tries to download a statement as part of a proof of payment
	 *
	 * @param {StatementDownloadActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	statementDownloadActioned,
	/**
	 * User tries to download a statement and gets an error from the BFF / API
	 *
	 * @param {StatementDownloadError} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	statementDownloadError,
	/**
	 * Fires a 'Step Completed (Appcues)' track call.
	 *
	 * @param {StepCompletedAppcues} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	stepCompletedAppcues,
	/**
	 * Fires a 'Step Interacted (Appcues)' track call.
	 *
	 * @param {StepInteractedAppcues} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	stepInteractedAppcues,
	/**
	 * Fires a 'Step Started (Appcues)' track call.
	 *
	 * @param {StepStartedAppcues} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	stepStartedAppcues,
	/**
	 * Storebox integration for DE companies
	 *
	 * @param {StoreboxIntegrationActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	storeboxIntegrationActioned,
	/**
	 * User triggered one of the Storebox actions
	 *
	 * @param {StoreboxOnboardingActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	storeboxOnboardingActioned,
	/**
	 * Fires a 'Sub-Wallet Creation' track call.
	 *
	 * @param {SubWalletCreation} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subWalletCreation,
	/**
	 * Fires a 'Sub-wallet Actioned' track call.
	 *
	 * @param {SubWalletActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subWalletActioned,
	/**
	 * User clicked a subscription expense card from the list of subscription
	 *
	 * @param {SubscriptionExpenseClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionExpenseClicked,
	/**
	 * User clicked an expense detail card from the subscription expenses list
	 *
	 * @param {SubscriptionExpenseDetailClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionExpenseDetailClicked,
	/**
	 * User actioned the filters in the subscription page
	 *
	 * @param {SubscriptionFilterActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionFilterActioned,
	/**
	 * User clicks on a subscription empty state action
	 *
	 * @param {SubscriptionsEmptyStateActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionsEmptyStateActioned,
	/**
	 * User without a subscription sees the empty state
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionsEmptyStateViewed,
	/**
	 * User clicks on a subscriptions onboarding banner action
	 *
	 * @param {SubscriptionsOnboardingBannerActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionsOnboardingBannerActioned,
	/**
	 * User sees the subscriptions onboarding banner
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	subscriptionsOnboardingBannerViewed,
	/**
	 * User has changed any of the field values in the supplier edit form
	 *
	 * @param {SupplierDetailsUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	supplierDetailsUpdated,
	/**
	 * User interacts with the supplier form
	 *
	 * @param {SupplierFormActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	supplierFormActioned,
	/**
	 * User clicked 'Chat with us' in the support menu in the right bottom corner
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	supportChatOpened,
	/**
	 * A user switches to a different logged in account
	 *
	 * @param {SwitchAccount} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	switchAccount,
	/**
	 * A user clicks on the switch account menu item
	 *
	 * @param {SwitchAccountClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	switchAccountClicked,
	/**
	 * A user switches to a different entity
	 *
	 * @param {SwitchEntity} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	switchEntity,
	/**
	 * User sees LexOffice Warning
	 *
	 * @param {SystemNotCompatibleWithPocket} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	systemNotCompatibleWithPocket,
	/**
	 * Change the budget end date
	 *
	 * @param {TagBudgetClosedEarly} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagBudgetClosedEarly,
	/**
	 * When the new budget created
	 *
	 * @param {TagBudgetCreationCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagBudgetCreationCompleted,
	/**
	 * When entering information into the budget form
	 *
	 * @param {TagBudgetCreationStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagBudgetCreationStarted,
	/**
	 * When going backwards to the previous budget creation step
	 *
	 * @param {TagBudgetCreationStepBackward} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagBudgetCreationStepBackward,
	/**
	 * When going forward to the next budget creation step
	 *
	 * @param {TagBudgetCreationStepForward} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagBudgetCreationStepForward,
	/**
	 * Discard tag budget modal
	 *
	 * @param {TagBudgetDiscard} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagBudgetDiscard,
	/**
	 * Update the budget settings
	 *
	 * @param {TagBudgetEdited} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagBudgetEdited,
	/**
	 * Interaction with budget filters
	 *
	 * @param {TagBudgetFilterActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagBudgetFilterActioned,
	/**
	 * Fires a 'Tag Budget Opened' track call.
	 *
	 * @param {TagBudgetOpened} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagBudgetOpened,
	/**
	 * Unarchive tag for an open budget
	 *
	 * @param {TagBudgetUnarchivedTag} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagBudgetUnarchivedTag,
	/**
	 * Visit the budgets overview page
	 *
	 * @param {TagBudgetViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagBudgetViewed,
	/**
	 * Download budget expenses
	 *
	 * @param {TagBudgetsExpenseDownloaded} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagBudgetsExpenseDownloaded,
	/**
	 * Visit the budgets overview page
	 *
	 * @param {TagBudgetsOverviewViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagBudgetsOverviewViewed,
	/**
	 * Importing tags in tag page
	 *
	 * @param {TagImportActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagImportActioned,
	/**
	 * User dismisses after having opened Early Signup modal
	 *
	 * @param {TagReviewFeatureDismissed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagReviewFeatureDismissed,
	/**
	 * User requests Tag Review
	 *
	 * @param {TagReviewFeatureRequested} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagReviewFeatureRequested,
	/**
	 * Actor navigates to budgets with the selected tag
	 *
	 * @param {TagReviewNavigatedToBudgets} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagReviewNavigatedToBudgets,
	/**
	 * Actor navigates to the expenses waiting for review from the selected reviewer
	 *
	 * @param {TagReviewNavigatedToExpenses} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagReviewNavigatedToExpenses,
	/**
	 * Triggered when the user interacts with the Tag Review Onboarding items
	 *
	 * @param {TagReviewOnboarding} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagReviewOnboarding,
	/**
	 * Triggered when the user deletes one reviewer assigned to the tag
	 *
	 * @param {TagReviewRemoveSingleReviewer} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagReviewRemoveSingleReviewer,
	/**
	 * Visits the Tag Review Settings Page
	 *
	 * @param {TagReviewSettingsViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagReviewSettingsViewed,
	/**
	 * Actor completes flow adding/updating/removing reviewer(s) to tag(s)
	 *
	 * @param {TagReviewTagActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagReviewTagActioned,
	/**
	 * Show warning modal when removing reviewer with assigned expenses waiting for review
	 *
	 * @param {TagReviewUnassignedReviewerModal} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagReviewUnassignedReviewerModal,
	/**
	 * User interaction on import of tag group items
	 *
	 * @param {TagSourceActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tagSourceActioned,
	/**
	 * Action to update the tax code in accounting setting page
	 *
	 * @param {TaxCodeTableUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	taxCodeTableUpdated,
	/**
	 * When within a Team in the Teams tab the reviewer is selected
	 *
	 * @param {TeamAddReviewerCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	teamAddReviewerCompleted,
	/**
	 * When within a Team in the Teams tab the reviewer drop down list is open
	 *
	 * @param {TeamAddReviewerStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	teamAddReviewerStarted,
	/**
	 * Apply is clicked in the team filter button in the People
	 *
	 * @param {TeamFilterCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	teamFilterCompleted,
	/**
	 * The team filter button in the People page is clicked
	 *
	 * @param {TeamFilterStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	teamFilterStarted,
	/**
	 * Added a member to a team
	 *
	 * @param {TeamMemberAdded} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	teamMemberAdded,
	/**
	 * Removed a member to a team
	 *
	 * @param {TeamMemberRemoved} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	teamMemberRemoved,
	/**
	 * The team page in the people tab is viewed
	 *
	 * @param {TeamPageViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	teamPageViewed,
	/**
	 * When within a Team in the Teams tab the reviewer is removed from the reviewer box
	 *
	 * @param {TeamReviewerRemoved} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	teamReviewerRemoved,
	/**
	 * The teams search bar is used
	 *
	 * @param {TeamSearched} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	teamSearched,
	/**
	 * User manages temporary card limits
	 *
	 * @param {TemporaryLimitsActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	temporaryLimitsActioned,
	/**
	 * User sees temporary limits for the first time
	 *
	 * @param {TemporaryLimitsPopoverViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	temporaryLimitsPopoverViewed,
	/**
	 * This is a test
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	testEvent,
	/**
	 * Event used for Tik Tok Ads Optimisation Signals. This event can have multiple triggers through the funnel and is owned by Team Performance Marketing
	 *
	 * @param {TikTokAdsOptimisationSignal} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tikTokAdsOptimisationSignal,
	/**
	 * When a user clicks the todo filter on the Expenses page to see their todo list
	 *
	 * @param {TodoListActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	todoListActioned,
	/**
	 * A user clicks on the account menu
	 *
	 * @param {ToggleAccountsMenu} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	toggleAccountsMenu,
	/**
	 * A user clicks on the entity switcher panel used for multi-entities
	 *
	 * @param {ToggleEntitySwitcher} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	toggleEntitySwitcher,
	/**
	 * When user sees the modal to create a manual top-up
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	topupModalViewed,
	/**
	 * Fired when user sees the wallet topup flow in pleo-guide
	 *
	 * @param {TransferDetailsActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	transferDetailsActioned,
	/**
	 * Fires a 'Treasury Waitlist' track call.
	 *
	 * @param {TreasuryWaitlist} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	treasuryWaitlist,
	/**
	 * Tracking for user interaction with the CTAs depending on wether is on the Trial Banner or the modals it opens (how-trial-works and choose-plans)
	 *
	 * @param {TrialActivationBannerActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	trialActivationBannerActioned,
	/**
	 * User clicks on the Done button on /pleo-guide/try-virtual-card
	 *
	 * @param {TryCardDoneActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tryCardDoneActioned,
	/**
	 * User clicks on the Topup button on /pleo-guide/try-virtual-card
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tryCardTopupOpened,
	/**
	 * User clicks on View Card button on /pleo-guide/try-virtual-card
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tryCardViewOpened,
	/**
	 * A Turborepo CLI Generator is ready to generate files.
	 *
	 * @param {TurboGeneratorActionsTriggered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	turboGeneratorActionsTriggered,
	/**
	 * A Turborepo CLI Generator had a failure when generating files.
	 *
	 * @param {TurboGeneratorFailed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	turboGeneratorFailed,
	/**
	 * A Turborepo CLI Generator has finished generating files.
	 *
	 * @param {TurboGeneratorFinished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	turboGeneratorFinished,
	/**
	 * A Turborepo CLI Generator has been started.
	 *
	 * @param {TurboGeneratorStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	turboGeneratorStarted,
	/**
	 * Reset 2FA for an employee
	 *
	 * @param {TwofactorReset} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	twofactorReset,
	/**
	 * UBO self verification Actioned
	 *
	 * @param {UboSelfVerificationActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	uboSelfVerificationActioned,
	/**
	 * Consists all the actions according to the US Wallet flow
	 *
	 * @param {UsWalletActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	usWalletActioned,
	/**
	 * User Adding Flow -
	 *
	 * @param {UafAddMemberCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	uafAddMemberCompleted,
	/**
	 * Partner user selects the notification channel on the partner client details drawer section of the partner portal. Today there's only one channel: email.
	 *
	 * @param {UpdatePartnerClientNotificationsChannel} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	updatePartnerClientNotificationsChannel,
	/**
	 * Partner user selects notification frequency on the partner client details drawer section of the partner portal
	 *
	 * @param {UpdatePartnerClientNotificationsFrequency} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	updatePartnerClientNotificationsFrequency,
	/**
	 * The Upgrade your plan widget is displayed on the page
	 *
	 * @param {UpgradeYourPlanViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	upgradeYourPlanViewed,
	/**
	 * Upload KYC document actioned
	 *
	 * @param {UploadDocumentActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	uploadDocumentActioned,
	/**
	 * The user interacts with the access type select for new users
	 *
	 * @param {UsageTypeActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	usageTypeActioned,
	/**
	 * Track user data when the user visits Expenses page
	 *
	 * @param {UserExpensesStatus} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userExpensesStatus,
	/**
	 * Apply is clicked in the user filter button in the Expenses page
	 *
	 * @param {UserFilterCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userFilterCompleted,
	/**
	 * The user filter button in the Expenses page is clicked
	 *
	 * @param {UserFilterStarted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userFilterStarted,
	/**
	 * Vendor card interaction events triggered by actions such as "view card details"
	
	 *
	 * @param {VendorCardActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vendorCardActioned,
	/**
	 * Vendor Card Setup Activation
	 *
	 * @param {VendorCardActivation} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vendorCardActivation,
	/**
	 * The callout displayed in the vendor card drawer
	 *
	 * @param {VendorCardCalloutActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vendorCardCalloutActioned,
	/**
	 * Vendor cards list errors events in the vendor cards page
	
	 *
	 * @param {VendorCardError} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vendorCardError,
	/**
	 * Vendor card action box displayed inside the recurring expenses page
	
	 *
	 * @param {VendorCardModalActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vendorCardModalActioned,
	/**
	 * Vendor cards list page views
	
	 *
	 * @param {VendorCardPageActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vendorCardPageActioned,
	/**
	 * Fires a 'Vendor Card Page Filter Actioned' track call.
	 *
	 * @param {VendorCardPageFilterActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vendorCardPageFilterActioned,
	/**
	 * Triggered when a transaction/expense from the vendor card's activity feed is clicked
	
	 *
	 * @param {VendorCardTransactionClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vendorCardTransactionClicked,
	/**
	 * Any step the user has viewed the company verification flow. This can very from country to country.
	 *
	 * @param {VerificationStepViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	verificationStepViewed,
	/**
	 * User has successfully submitted their company verifications.
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	verificationSubmitted,
	/**
	 * User clicks 'I have initiated the transfer' on the ManagementVerify - Company info page
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	verificationTransferInitiated,
	/**
	 * User has clicked 'Done' on the the ManagementVerify - Company info page, however failed the eKYC and thus is sent back to the ManagementVerify page to upload documents
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	verificationEKycFailed,
	/**
	 * User performs an action in the company verification flow
	 *
	 * @param {VerifyCompanyActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	verifyCompanyActioned,
	/**
	 * User performs an action in verifying their identity
	 *
	 * @param {VerifyIdentityActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	verifyIdentityActioned,
	/**
	 * User views expense details
	 *
	 * @param {ViewExpenseDetailsActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	viewExpenseDetailsActioned,
	/**
	 * User views Virtual Card details after successfully having entered their passcode in the 'View card' pop-up
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	virtualCardPanViewed,
	/**
	 * Clicked on 'Take a quick tour'
	 *
	 * @param {VismaEconomicInvoicesTourAccessed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vismaEconomicInvoicesTourAccessed,
	/**
	 * Visma e-conomic migration flow tracking
	 *
	 * @param {VismaEconomicMigrationFlow} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vismaEconomicMigrationFlow,
	/**
	 * Clicked on instructions ('Enabling Invoices for Visma e-conomic')
	 *
	 * @param {VismaEconomicMigrationInstructionsActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vismaEconomicMigrationInstructionsActioned,
	/**
	 * When user is on /wallet/history page
	 *
	 * @param {WalletActivitiesHistoryActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletActivitiesHistoryActioned,
	/**
	 * When user is on the wallet page and clicked on the auto top up button and then if the user hasn't setup a direct debit agreement between the bank and pleo then the user is presented with a modal with a button "Setup Direct Debit"
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletAutoTopUpSetupDirectDebitActioned,
	/**
	 * User opens the modal to configure auto top-up settings.
	 *
	 * @param {WalletAutoTopupModalViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletAutoTopupModalViewed,
	/**
	 * Users see onboarding banner on the Wallet page, when they don't set up Auto top-up
	 *
	 * @param {WalletAutoTopupOnboardingBannerActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletAutoTopupOnboardingBannerActioned,
	/**
	 * On the Wallet page, when a user clicks "Balance statement" button we see a modal with calendars and download button.
	 *
	 * @param {WalletBalanceStatementActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletBalanceStatementActioned,
	/**
	 * When user sees the modal on the wallet page with the balance statement.
	 *
	 * @param {WalletBalanceStatementModalViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletBalanceStatementModalViewed,
	/**
	 * The user interactions with the outstanding invoices link in the wallet balance
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletBalanceUnpaidInvoicesActioned,
	/**
	 * When the user is on the wallet page and clicks on manual auto top up and then chooses Bank Transfer. We have a list of bank details to which the user should transfer money to.
	 *
	 * @param {WalletBankTransferModalActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletBankTransferModalActioned,
	/**
	 * When user sees the modal for bank transfer manual top-up.
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletBankTransferModalViewed,
	/**
	 * Fires a 'Wallet Direct Debit Se Actioned' track call.
	 *
	 * @param {WalletDirectDebitSeActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletDirectDebitSeActioned,
	/**
	 * When user sees the modal on the wallet page for manual direct debit topup.
	 *
	 * @param {WalletDirectDebitTopupModalViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletDirectDebitTopupModalViewed,
	/**
	 * Fires a 'Wallet Error Viewed' track call.
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletErrorViewed,
	/**
	 * User is on the Wallet page (not onboading flow, but regular, after onboarding wallet page) and clicks on a button which is named "Low balance alert" with a ringbell on it. Clicking on that button shows a modal, which has options for automatically or manually choosing an amount below which we should notify the user to topup wallet.
	 *
	 * @param {WalletLowBalanceAlertActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletLowBalanceAlertActioned,
	/**
	 * When user sees the modal to configure balance threshold for auto top-up
	 *
	 * @param {WalletLowBalanceAlertModalViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletLowBalanceAlertModalViewed,
	/**
	 * When the user selected the manual top up flow on the wallet page and then clicked Direct Debit and then clicked Edit to edit the template.
	 *
	 * @param {WalletManualAutoTopUpDirectDebitMandateEditActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletManualAutoTopUpDirectDebitMandateEditActioned,
	/**
	 * On the wallet page, when the user has selected Edit and then clicked on button to Set Up Amount
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletManualAutoTopUpDirectDebitMandateEditSetUpAmountActioned,
	/**
	 * When the user is on the wallet page and clicks on manual top up button and then selects Direct Debit and already has it set up.
	 *
	 * @param {WalletManualTopUpDirectDebitActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletManualTopUpDirectDebitActioned,
	/**
	 * When the user wants to delete the current mandate form
	 *
	 * @param {WalletManualTopUpDirectDebitDeleteActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletManualTopUpDirectDebitDeleteActioned,
	/**
	 * When the user does not have any balance loads so far, is redirected to onboarding flow for wallet load.
	 *
	 * @param {WalletOnboardingTopupViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletOnboardingTopupViewed,
	/**
	 * When the user arrives at the wallet page
	 *
	 * @param {WalletPageViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletPageViewed,
	/**
	 * When the company is a german one it has to setup a mandate.
	 *
	 * @param {WalletSetupAutoTopupMandateDe} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletSetupAutoTopupMandateDe,
	/**
	 * When the user selected auto topup and then select direct debit and is from a danish company
	 *
	 * @param {WalletSetupNetsActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletSetupNetsActioned,
	/**
	 * Actions with top up methods
	 *
	 * @param {WalletTopUpActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletTopUpActioned,
	/**
	 * When user is on wallet page and clicks on top up history button
	 *
	 * @param {WalletTopUpHistoryActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletTopUpHistoryActioned,
	/**
	 * When user sees the modal with top-up history.
	 *
	 * @param {WalletTopupHistoryModalViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletTopupHistoryModalViewed,
	/**
	 * When user clicks on the "+ Top up" button on the wallet page and sees modal with two options - Direct debit or bank transfer.
	 *
	 * @param {WalletTopupMethodModalViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletTopupMethodModalViewed,
	/**
	 * When the user is on the wallet page and clicks on the "Auto topup button" which opens up a modal to configure conditions under which auto topup is run.
	 *
	 * @param {WalletTurnOnAutoTopupActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletTurnOnAutoTopupActioned,
	/**
	 * Events with wallet withdrawal button
	 *
	 * @param {WalletWithdrawalButtonActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletWithdrawalButtonActioned,
	/**
	 * User interactions with wallet withdrawal page
	 *
	 * @param {WalletWithdrawalPageActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	walletWithdrawalPageActioned,
	/**
	 * User most come through the bigform-2 or your must be between 1-4 in company size. This is the first page they land on. This corresponds to app.pleo.io/welcome and the page call /welcome
	 *
	 * @param {WelcomePageViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	welcomePageViewed,
	/**
	 * User has clicked a link in the accounting settings side module
	 *
	 * @param {XeroAccountingModuleActioned} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	xeroAccountingModuleActioned,
	/**
	 * User interaction with Xero educational banner on tags page
	 *
	 * @param {XeroCategoriesBannerActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	xeroCategoriesBannerActioned,
	/**
	 * User interaction on sync or creation of tag groups on create tags page
	 *
	 * @param {XeroCreateTagGroupActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	xeroCreateTagGroupActioned,
	/**
	 * User views the Xero onboarding modal
	 *
	 * @param {XeroOnboardingModuleViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	xeroOnboardingModuleViewed,
	/**
	 * User has used tabs at the top of the Accounting Settings page
	 *
	 * @param {XeroSetupPageActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	xeroSetupPageActioned,
	/**
	 * User interaction with Xero educational banner on tags page
	 *
	 * @param {XeroTagsBannerActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	xeroTagsBannerActioned,
	/**
	 * User interaction on sync or creation of tag groups on tags page
	 *
	 * @param {XeroTagsPageActioned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	xeroTagsPageActioned,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/pleo/protocols/tracking-plans/rs_1RMy5Zbz5cLu5eIHzoBCfrsB3Yr`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
