import type {
    ComponentType,
    FC,
    ForwardRefExoticComponent,
    HTMLAttributes,
    RefAttributes,
    RefObject,
} from 'react'
import {useMemo} from 'react'
import type {css} from 'styled-components'
import {ThemeProvider, useTheme} from 'styled-components'
import useResizeObserver from 'use-resize-observer/polyfilled'

import type {ContainerQueries, theme} from '@product-web/shared--styles/theme'

export type ThemeWithContainerQuery = {
    containerQueries: ContainerQueries
}

type ComponentWithRef<T, K> =
    | ComponentType<React.PropsWithChildren<T & RefAttributes<K>>>
    | ForwardRefExoticComponent<T>

function useContainerQueryTheme<T extends HTMLElement>(
    name?: string,
): [RefObject<T>, typeof theme] {
    const {ref, width = 0} = useResizeObserver<T>()
    const parentTheme = useTheme()

    const newTheme = useMemo(() => {
        const {containerQueries} = parentTheme

        return {
            ...parentTheme,
            containerQueries: {
                ...containerQueries,
                parent: {
                    width,
                },
                named: {
                    ...containerQueries.named,
                    ...(name ? {[name]: {width}} : {}),
                },
            },
        }
    }, [name, width, parentTheme])

    return [ref, newTheme]
}

export function withContainer<T extends {}, K extends HTMLElement>(
    Component: ComponentWithRef<T, K>,
    name?: string,
) {
    return function WithContainer(props: T) {
        const [ref, newTheme] = useContainerQueryTheme<K>(name)

        return (
            <ThemeProvider theme={newTheme}>
                <Component ref={ref} {...props} />
            </ThemeProvider>
        )
    }
}

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
    name?: string
}

export const Container: FC<React.PropsWithChildren<ContainerProps>> = ({
    name,
    children,
    ...rest
}) => {
    const [ref, newTheme] = useContainerQueryTheme<HTMLDivElement>(name)

    return (
        <ThemeProvider theme={newTheme}>
            <div ref={ref} {...rest}>
                {children}
            </div>
        </ThemeProvider>
    )
}

type ContainerQuery = (
    | {minWidth: number; maxWidth: number}
    | {minWidth: number}
    | {maxWidth: number}
) & {name?: string}

const matchContainerQuery = (query: ContainerQuery, contextTheme: ThemeWithContainerQuery) => {
    const minWidth = 'minWidth' in query ? query.minWidth : 0
    const maxWidth = 'maxWidth' in query ? query.maxWidth : 0
    const name = query.name
    const {containerQueries} = contextTheme
    const containerDimensions = name ? containerQueries.named?.[name] : containerQueries.parent

    if (!containerDimensions) {
        return false
    }

    const {width} = containerDimensions

    const aboveMinWidth = width >= minWidth
    const belowMaxWidth = width <= maxWidth

    if (minWidth && maxWidth) {
        return aboveMinWidth && belowMaxWidth
    }

    if (minWidth) {
        return aboveMinWidth
    }

    if (maxWidth) {
        return belowMaxWidth
    }

    return false
}

export const useContainerQuery = (query: ContainerQuery) => {
    const themeContext = useTheme()

    return matchContainerQuery(query, themeContext)
}

export const containerQuery = (query: ContainerQuery, styles: ReturnType<typeof css>) => {
    return ({theme: themeContext}: {theme: ThemeWithContainerQuery}) => {
        return matchContainerQuery(query, themeContext) ? styles : null
    }
}
