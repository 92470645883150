import {useCallback, useEffect, useState} from 'react'

import * as tracking from '@product-web/shared--analytics'
import {useIsLoggedIn} from '@product-web/shared--auth--session/context'
import {useFeatureEntitlement} from '@product-web/shared--entitlement/use-feature-entitlement'
import {useIsPartnerUser, useUser} from '@product-web/shared--user'

import {freshchatBff} from './bff-hooks'
import {Freshchat} from './freshchat'

export function useSupportChat() {
    const user = useUser()
    const isLoggedIn = useIsLoggedIn()
    const [isConnected, setIsConnected] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const {isEntitled: isCompanyEntitled} = useFeatureEntitlement('LIVE_CHAT_SUPPORT')
    const isPartnerUser = useIsPartnerUser()
    const isFreshchatLoaded = Freshchat.isLoaded()

    const {data, isLoading: isFetchingRestoreId} =
        freshchatBff.restoreId.getRestoreIdForUser.useQuery(undefined, {
            enabled: isLoggedIn,
        })

    const {mutate: setRestoreIdSync} = freshchatBff.restoreId.setRestoreId.useMutation()

    useEffect(() => {
        if (isFreshchatLoaded) {
            setIsConnected(true)
        } else {
            Freshchat.load(() => {
                setIsConnected(true)
            })
        }
    }, [isFreshchatLoaded])

    useEffect(() => {
        if (isConnected && isLoggedIn && user?.id && !isFetchingRestoreId) {
            Freshchat.initialise({
                user,
                restoreId: data?.restoreId,
                onUserCreated: setRestoreIdSync,
            })
        }
    }, [isConnected, isLoggedIn, user, data?.restoreId, setRestoreIdSync, isFetchingRestoreId])

    const hide = useCallback(() => {
        setIsActive(false)
    }, [])

    const onShow = useCallback((handler: () => void) => {
        window.fcWidget?.on('widget:opened', handler)
    }, [])

    const onHide = useCallback((handler: () => void) => {
        window.fcWidget?.on('widget:closed', handler)
    }, [])

    const show = async () => {
        setIsActive(true)
        Freshchat.open()
        tracking.supportChatOpened()
    }

    const canChatWithSupport = isLoggedIn && isConnected && (isCompanyEntitled || isPartnerUser)

    return {
        isActive,
        isEntitled: canChatWithSupport,
        isConnected,
        show,
        hide,
        onShow,
        onHide,
    }
}
