import {reportError} from '@product-web/shared--error/report'
import {showConfirmModal} from '@product-web/shared--telescope-lab/confirm-modal/confirm'
import type {ToastConfig} from '@product-web/shared--toaster'
import {useToaster} from '@product-web/shared--toaster'

type FeedbackLabels<T> = {
    successTitle?: string
    successMessage?: ((input: T, response?: any | Error) => string) | string
    errorTitle?: string
    errorMessage?: ((input: T, response?: any | Error) => string) | string
}

type ConfirmationLabels = {
    confirmTitle: string
    confirmDescription: string | JSX.Element
    confirmLabel: string
    confirmDestructive?: boolean
    onDismiss?: () => void
    dataTestId?: string
}

export type ActionResult<T> = {
    status: 'success' | 'failed' | 'cancelled'
    value?: T
    error?: unknown
}

/**
 * A custom hook to perform an action with user feedback, such as confirmation and toast notifications.
 * The hook provides confirmation modals, success/error messages, and configurable options.
 *
 * @template Arguments - The type of arguments passed to the action function.
 * @template ActionResponse - The type of the response returned by the action function.
 * @param action - The action function to perform.
 * @param getLabels - A function that returns feedback and confirmation labels based on the input arguments.
 * @param [options] - Optional configuration object for the hook.
 * @param [options.skipConfirmation] - If true, skips the confirmation modal.
 * @param [options.toastConfig] - Optional configuration for toast messages.
 * @param [options.throwOnError] - If true, throws an error when the action fails.
 * @returns An async function that performs the action and returns a response or null.
 * @example
const myActionWithFeedback = useActionWithFeedback(someAsyncFunction, () => ({
    confirmTitle: `Doing the thing`,
    confirmDescription: `Are you sure you want to do the thing?`,
    confirmLabel: `Yes`,
    successMessage: `Did it`,
    errorMessage: `An error occurred.`,
}))
*/

export function useActionWithFeedback<Arguments extends unknown[], ActionResponse = void>(
    action: (...args: Arguments) => Promise<ActionResult<ActionResponse> | void>,
    getLabels: (
        ...args: Arguments
    ) => FeedbackLabels<Arguments> | (FeedbackLabels<Arguments> & ConfirmationLabels),
    options?: {
        skipConfirmation?: boolean
        toastConfig?: Partial<ToastConfig>
        throwOnError?: boolean
    },
) {
    const {showToast} = useToaster()
    return async (...args: Arguments): Promise<ActionResult<ActionResponse>> => {
        const labels = getLabels(...args)

        function handleError(error: unknown) {
            if (!error) {
                return
            }
            if (error !== 'cancelled') {
                reportError(error)
            }
            if (labels.errorMessage) {
                const message =
                    typeof labels.errorMessage === 'function'
                        ? labels.errorMessage(args, error)
                        : labels.errorMessage
                showToast(message, {
                    level: 'error',
                    title: labels.errorTitle,
                    ...(options?.toastConfig ?? {}),
                })
            }
            if (options?.throwOnError) {
                throw error
            }
        }

        if (!options?.skipConfirmation && 'confirmTitle' in labels) {
            try {
                const confirm = await showConfirmModal({
                    title: labels.confirmTitle,
                    message: labels.confirmDescription,
                    confirmLabel: labels.confirmLabel,
                    destructive: labels.confirmDestructive,
                    onClose: labels.onDismiss,
                    confirmButtonTestId: labels.dataTestId,
                })

                if (confirm.result === 'cancelled') {
                    labels.onDismiss?.()
                    return {status: 'cancelled'}
                }
            } catch (error) {
                handleError(error)
                return {status: 'failed'}
            }
        }

        try {
            const result = await action(...args)
            if (result?.status === 'cancelled') {
                return {status: 'cancelled'}
            }
            if (result?.error) {
                handleError(result.error)
                return {status: 'cancelled'}
            }
            if (labels.successMessage) {
                const message =
                    typeof labels.successMessage === 'function'
                        ? labels.successMessage(args, result?.value)
                        : labels.successMessage
                showToast(message, {
                    level: 'success',
                    title: labels.successTitle,
                    ...(options?.toastConfig ?? {}),
                })
            }
            return {status: 'success', value: result?.value}
        } catch (error) {
            handleError(error)
            return {status: 'failed'}
        }
    }
}
