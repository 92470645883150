import {sessionStorage} from '@product-web/shared--web-platform/storage/session'

export const OAUTH_ID_STORAGE_KEY = 'oauthId'

export const OAUTH_ADD_ACCOUNT = 'oauthAddAccount'

/**
 * Parses the oauth callback params from the URL's hash part, if available
 * @returns Oauth callback params if available
 */
export function getOAuthCallbackParams(hash: string) {
    if (!hash) {
        return null
    }
    const params = new URLSearchParams(hash.slice(1))

    // If both error and message are set in hash, it's most likely a failed
    // oauth attempt (like invalid password)
    const error = params.get('error')
    const message = params.get('message')
    if (error && message) {
        return {error: true, message} as const
    }
    const email = params.get('email')

    const result = params.get('result')
    if (result === 'verification_email_sent') {
        return {result: 'verification_email_sent', email} as const
    }

    // if `email` is set in hash and is not a verification case, it means that we deal with google signup which return `email`, `firstName` and `lastName`
    const firstName = params.get('firstName')
    const lastName = params.get('lastName')
    const loginToken = params.get('login_token')
    const idToken = params.get('id_token')

    if (email) {
        return {
            email,
            loginToken,
            firstName,
            lastName,
            idToken,
        }
    }

    // If both access_token and state are set in hash, it's most likely
    // a successful oauth attempt. Note that state will be serialized JSON
    // so we try to parse it here as well.
    const accessToken = params.get('access_token')
    const rawState = params.get('state')

    if (accessToken && rawState) {
        try {
            const state = JSON.parse(rawState) ?? {}

            // As a level of protection, we expect the `state.id` from the oauth callback
            // params to equal the oauthId generated on the client when triggering the oauth flow
            // This ensures that we only act on callbacks that were triggered by the same browser
            const oauthId = sessionStorage.getItem(OAUTH_ID_STORAGE_KEY)

            if (oauthId !== state.id) {
                throw new Error('Mismatched oauthId')
            }

            return {
                accessToken,
                state,
            }
        } catch (e) {
            return {
                error: true,
                message: 'invalid_state',
            } as const
        }
    }

    return null
}

// We're setting this flag when trying to add a new account via
// "login with google" button so we can do proper redirect
// when we get back from the google oauth url
export function setOauthAddAccount(addAccount: boolean) {
    if (addAccount) {
        localStorage.setItem(OAUTH_ADD_ACCOUNT, 'true')
    } else {
        localStorage.removeItem(OAUTH_ADD_ACCOUNT)
    }
}
