import {t, Trans} from '@lingui/macro'
import {Formik} from 'formik'
import type React from 'react'

import {
    Button,
    FormikInput as Input,
    Inline,
    Link,
    ModalActions,
    ModalContent,
    ModalTitle,
} from '@pleo-io/telescope'

import {getHelpCentreArticleLinkIntercom} from '@product-web/shared--locale/helpers'
import yup from '@product-web/shared--validation/yup'

import * as s from '../add-member-modal/add-member-modal.styles'

const validationSchema = yup.object().shape({
    firstName: yup.string().required(() => t`First name is required`),
    email: yup
        .string()
        .email(() => t`Please provide a valid email`)
        .required(() => t`Email is required`),
    cardAccess: yup.boolean(),
})

type FormValues = yup.InferType<typeof validationSchema>

interface AddCompanyAdminProps {
    onSubmit: (values: FormValues) => Promise<void>
    onCancel: () => void
}

// Used by a partner employee when they create a new company and the first
// employee from the client needs to be an admin employee
export const AddCompanyAdmin: React.FC<React.PropsWithChildren<AddCompanyAdminProps>> = (props) => {
    const initialValues: FormValues = {
        firstName: '',
        email: '',
        cardAccess: true,
    }

    return (
        <>
            <ModalTitle>
                <Trans>Invite admin to company</Trans>
            </ModalTitle>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    await props.onSubmit(values)
                }}
            >
                {({handleSubmit, isValid, dirty, isSubmitting, setFieldValue}) => (
                    <>
                        <form onSubmit={handleSubmit}>
                            <ModalContent>
                                <s.FormDescription>
                                    <Trans>
                                        They will have administrator access to the Pleo account.{' '}
                                        <Link
                                            href={getHelpCentreArticleLinkIntercom(
                                                '3859460-adding-admin-s-and-team-members',
                                            )}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            Read more
                                        </Link>
                                    </Trans>
                                </s.FormDescription>
                                <Inline space={16}>
                                    <Input
                                        name="firstName"
                                        placeholder={t`First name`}
                                        aria-label={t`First name`}
                                        variant="bordered"
                                    />
                                    <Input
                                        name="email"
                                        placeholder={t`Email`}
                                        aria-label={t`Email`}
                                        onChange={(event) =>
                                            setFieldValue('email', event.target.value.trim())
                                        }
                                        variant="bordered"
                                    />
                                </Inline>
                            </ModalContent>
                            <ModalActions>
                                <Inline space={16}>
                                    <Button
                                        id="submit"
                                        type="submit"
                                        variant="primary"
                                        loading={isSubmitting}
                                        data-testid="invite-button"
                                        disabled={!(isValid && dirty) && !isSubmitting}
                                    >
                                        <Trans>Invite</Trans>
                                    </Button>
                                    <Button
                                        id="cancel"
                                        type="button"
                                        variant="secondary"
                                        disabled={isSubmitting}
                                        onClick={props.onCancel}
                                    >
                                        <Trans>Cancel</Trans>
                                    </Button>
                                </Inline>
                            </ModalActions>
                        </form>
                    </>
                )}
            </Formik>
        </>
    )
}

export default AddCompanyAdmin
