import {useMemo} from 'react'
import {useGate} from 'statsig-react'

import {useVendorCard} from '@product-web/feature--vendor-cards/lib/use-vendor-card'
import {dayjs} from '@product-web/shared--dates/dayjs'
import {useFlags} from '@product-web/shared--flags'
import {useUser} from '@product-web/shared--user'

import {bffHooks} from '../../bff-hooks'

/**
 * @returns {boolean} - Whether the Spend Guide Activation Guide should be visible to the Admin
 *
 * @return {true} if all of the following conditions are met:
 * - if customer is on Advanced plan
 * - if customer has recurring spend on personal cards
 * - if customer has no sub-wallets
 * - if feature-gate is enabled
 */

const oneYearBefore = dayjs().subtract(1, 'year').format('YYYY-MM-DD')
const today = dayjs().startOf('day').format('YYYY-MM-DD')

export const useSpendActivationGuide = () => {
    const {spendActivationGuideFooterLink} = useFlags()

    const {value: featureGateEnabled} = useGate('spend_activation_setup_flow')
    const user = useUser()

    const {canUseVendorCards} = useVendorCard()

    const canCheckForSpendActivationGuide =
        featureGateEnabled && canUseVendorCards && user?.role === 'owner'

    const {data: showSpendActivationGuide} =
        bffHooks.recurringVendors.spendActivationGuide.getShouldShowSpendActivationGuide.useQuery(
            {
                from: [oneYearBefore],
                to: [today],
                featureFlagEnabledForEssential: spendActivationGuideFooterLink,
            },
            {enabled: canCheckForSpendActivationGuide},
        )

    const shouldShowSpendGuideActivationGuide =
        featureGateEnabled && canUseVendorCards && showSpendActivationGuide

    return useMemo(
        () => ({
            isEnabled: shouldShowSpendGuideActivationGuide,
        }),
        [shouldShowSpendGuideActivationGuide],
    )
}
