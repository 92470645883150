import {t} from '@lingui/macro'
import type {FC, ReactNode} from 'react'
import styled, {css} from 'styled-components'

import {NakedButton, Text, tokens} from '@pleo-io/telescope'

import {navigationOffset} from '@product-web/shared--navigation/constants'
import {
    useMobileNavigationVisibility,
    useNavigationWidth,
} from '@product-web/shared--navigation/navigation-context'
import {breakpoints} from '@product-web/shared--styles/theme'
import {getIsPartnerUser, useUser} from '@product-web/shared--user'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

import {bff} from './bff-hooks'
import {useInternalNavigationContext} from './navigation-internal-provider'

interface NavigationSkeleton {
    userMenu?: ReactNode
    main?: ReactNode
}

export const NavigationSkeleton: FC<NavigationSkeleton> = ({userMenu, main}) => {
    const {isMobileNavigationVisible, setIsMobileNavigationVisible} =
        useMobileNavigationVisibility()

    const {width} = useNavigationWidth()

    const isTablet = useMediaQuery(`(max-width: ${breakpoints.tabletMedUp})`)

    const user = useUser()

    const {selectedEntityId} = useInternalNavigationContext()

    const {data: selectedEntityName} = bff.navigation.getEntityName.useQuery(
        {selectedEntityId},
        {enabled: !!selectedEntityId, keepPreviousData: true},
    )

    const isUserPartner = getIsPartnerUser(user)

    return (
        <NavigationBase $width={width}>
            <NavigationStyleWrapper>
                {isTablet && (
                    <NavigationTop>
                        <NavigationBurger
                            isOpen={isMobileNavigationVisible}
                            setIsOpen={setIsMobileNavigationVisible}
                        />
                        {isTablet && (selectedEntityName || isUserPartner) ? (
                            <Subtitle>{isUserPartner ? t`Partner` : selectedEntityName}</Subtitle>
                        ) : null}
                        <UserMenu>{userMenu}</UserMenu>
                    </NavigationTop>
                )}
                <NavigationLeft
                    $isVisibleForTablet={isMobileNavigationVisible}
                    $width={width}
                    data-testid="navigation-left"
                >
                    {main}
                </NavigationLeft>
            </NavigationStyleWrapper>
        </NavigationBase>
    )
}

interface NavigationBurgerProps {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

const NavigationBurger: FC<NavigationBurgerProps> = ({isOpen, setIsOpen}) => {
    return (
        <Burger $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} aria-label={t`Toggle menu`}>
            <BurgerWrapper>
                <BurgerLine />
                <BurgerLine />
            </BurgerWrapper>
        </Burger>
    )
}

const NavigationBase = styled.div<{$width: number}>`
    display: flex;
    flex: 0 0 ${({$width}) => `${$width}px`};

    @media (max-width: ${breakpoints.tabletMedUp}) {
        position: fixed;
        top: 0;
        width: 100%;
        height: ${navigationOffset};
        background: ${tokens.colorBackgroundStatic};
        border-bottom: ${tokens.borderStatic};
        transition: ${`max-height ${tokens.smoothInOut}`};
        transform: translateZ(0);
        backface-visibility: hidden;
        z-index: ${tokens.zIndexNavigation};
    }
`

const NavigationStyleWrapper = styled.div`
    display: flex;
    flex: 1;
`

const NavigationTop = styled.div`
    display: flex;
    margin: auto;
    align-items: center;
    width: 100%;
    height: 100%;
`

const UserMenu = styled.div`
    margin-right: ${tokens.spacing8};
    margin-left: auto;
`

const NavigationLeft = styled.div<{$isVisibleForTablet?: boolean; $width: number}>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    width: ${({$width}) => `${$width}px`};
    height: 100vh;
    box-sizing: border-box;
    font-size: ${tokens.fontSmall};
    flex-direction: column;
    background-color: ${tokens.colorBackgroundStaticLoudest};
    transition: ${`all ${tokens.smoothInOut}`};

    @media (max-width: ${breakpoints.tabletMedUp}) {
        top: ${navigationOffset};
        left: calc(-1 * ${breakpoints.tabletMedUp});
        height: calc(100vh - ${navigationOffset});
        background-color: ${tokens.colorBackgroundStatic};
        border: none;
        width: 100vw;

        ${(props) =>
            props.$isVisibleForTablet &&
            css`
                left: 0;
            `}
    }
`

const BurgerLine = styled.span`
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background: ${tokens.colorContentInteractive};
    opacity: 1;
    transition: ${tokens.smoothInOut};
    transform: rotate(0deg);
`

const Burger = styled(NakedButton)<{$isOpen?: boolean}>`
    display: none;

    ${BurgerLine}:nth-child(1) {
        top: ${tokens.spacing8};
    }

    ${BurgerLine}:nth-child(2) {
        bottom: ${tokens.spacing8};
    }

    ${(props) =>
        props.$isOpen &&
        css`
            ${BurgerLine}:nth-child(1) {
                bottom: 11px;
                top: initial;
                transform: rotate(-135deg);
            }
            ${BurgerLine}:nth-child(2) {
                transform: rotate(-45deg);
                bottom: 11px;
            }
        `}

    @media (max-width: ${breakpoints.tabletMedUp}) {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        width: 24px;
        height: 24px;
        margin: 14px;
        cursor: pointer;
        background: none;
        border: none;
        transition: ${tokens.smoothInOut};
        transform: rotate(0deg);
    }
`

const BurgerWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 18px;
`

const Subtitle = styled(Text).attrs({
    variant: 'small-subtle',
    weight: 'medium',
})`
    position: fixed;
    top: 28px;
    left: 49px;
`
