import {Trans} from '@lingui/macro'

import {Button, Inline, Link} from '@pleo-io/telescope'
import {NewTab, SpeechBubble} from '@pleo-io/telescope-icons'

import {useSupportChat} from '@product-web/shared--freshchat/use-support-chat'

import {usePleoLinks} from './adoption-centre-link-helpers'
import {trackHelpWidgetLinkClick} from './tracking'

interface Props {
    closeAdoptionCentre: () => void
}

const AdoptionCentreFooter = ({closeAdoptionCentre}: Props) => {
    const {getHelpCentreLink} = usePleoLinks()
    const supportChat = useSupportChat()
    const onChatToUs = async () => {
        closeAdoptionCentre()
        await supportChat.show()
        trackHelpWidgetLinkClick('contact_us')
    }
    return (
        <Inline justifyContent="space-between" alignItems="center" css={{width: '100%'}}>
            <Link
                href={getHelpCentreLink()}
                onClick={() => trackHelpWidgetLinkClick('help_centre')}
                target="_blank"
                rel="noopener noreferrer"
                IconRight={NewTab}
            >
                <Trans>Help centre</Trans>
            </Link>
            <Button variant="tertiary" onClick={onChatToUs} IconLeft={SpeechBubble}>
                <Trans>Chat to us</Trans>
            </Button>
        </Inline>
    )
}

export default AdoptionCentreFooter
