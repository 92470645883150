import {t, Trans} from '@lingui/macro'
import {useState} from 'react'
import styled from 'styled-components'

import {Button, Checkbox, Inline, Link, Modal, Stack, Text, tokens} from '@pleo-io/telescope'
import {Download, File} from '@pleo-io/telescope-icons'

import {useToaster} from '@product-web/shared--toaster'

import {bff} from '../../../../bff-hooks'

interface AcceptPartnerAgreementProps {
    isOpen: boolean
    close: () => void
}

export const AcceptPartnerAgreement = ({isOpen, close}: AcceptPartnerAgreementProps) => {
    const {showToast} = useToaster()
    const [checked, setChecked] = useState(false)
    const toggle = () => setChecked(!checked)

    const onSuccess = () => {
        close()
        showToast(t`This information will be updated in your Company page for all admins.`, {
            title: t`Partner agreement accepted`,
            level: 'success',
        })
    }

    const onError = () =>
        showToast(
            t`The partner agreement could not be accepted. Please try again later or reach out to support.`,
            {
                title: t`Something went wrong`,
                level: 'error',
            },
        )

    const {data, isError} = bff.signPartnerAgreement.getLatestAgreement.useQuery()
    const {mutate: sign, isLoading: signing} = bff.signPartnerAgreement.sign.useMutation({
        onSuccess,
        onError,
    })

    return (
        <Modal isOpen={isOpen}>
            <Modal.Close onClick={close} />
            <Modal.Title as="h5">
                <Trans>Sign your partner agreement</Trans>
            </Modal.Title>
            <Modal.Content align="left">
                <Stack align="left" space={24}>
                    <Text as="p" color="colorContentStaticQuiet">
                        <Trans>
                            Before you can refer clients and receive your benefits from the partner
                            program, you first need to agree to the accounting partner agreement.
                        </Trans>
                    </Text>
                    <Stack space={8}>
                        {data?.url && (
                            <Link
                                aria-label={t`Download partner agreement`}
                                tabIndex={0}
                                href={data.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                css={{width: '100%'}}
                            >
                                <DownloadAgreement p={24}>
                                    <File color={tokens.colorContentInteractiveQuiet} />
                                    <Text as="p">
                                        <Trans>Download partner agreement</Trans>
                                    </Text>
                                    <Download
                                        size={24}
                                        color={tokens.colorContentInteractiveQuiet}
                                    />
                                </DownloadAgreement>
                            </Link>
                        )}
                        {isError && (
                            <Text color="colorContentNegative">
                                <Trans>
                                    Your partner agreement cannot be downloaded. Please try again
                                    later or reach out to support.
                                </Trans>
                            </Text>
                        )}
                    </Stack>
                    <Inline space={12}>
                        <Checkbox
                            aria-label={t`I have read and agree to terms and conditions of the Partner Agreement.`}
                            checked={checked}
                            onChange={toggle}
                        />
                        <Text as="p">
                            <Trans>
                                I have read and agree to terms and conditions of the Accounting
                                Partner Agreement.
                            </Trans>
                        </Text>
                    </Inline>
                </Stack>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    disabled={!checked || signing}
                    loading={signing}
                    onClick={() => sign()}
                    variant="primary"
                >
                    <Trans>Confirm</Trans>
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

const DownloadAgreement = styled(Inline).attrs({as: 'a', alignX: 'start', p: 24, space: 12})`
    border: ${tokens.borderPrimary};
    border-radius: ${tokens.arc8};

    &:hover {
        cursor: pointer;
        box-shadow: ${tokens.shadowElevateQuiet};
    }

    &:last-child {
        margin-left: auto;
    }
`
