import countries from 'i18n-iso-countries'

import {SupportedLanguage} from '@product-web/shared--i18n'

const importCountryNames = async (language: SupportedLanguage) => {
    let countryNames

    const defaultLanguage = SupportedLanguage.EN

    try {
        countryNames = await import(
            `../node_modules/i18n-iso-countries/langs/${language.split('-')[0]}.json`
        )
    } catch (e) {
        countryNames = await import(
            `../node_modules/i18n-iso-countries/langs/${defaultLanguage}.json`
        )
    }
    countries.registerLocale(countryNames)
}

const importMessages = async (language: SupportedLanguage) => {
    const isDevMode = process.env.NODE_ENV !== 'production'

    if (language === SupportedLanguage.EN) {
        if (isDevMode) {
            return {messages: {}}
        }
        // NOTE: In production mode (i.e. NODE_ENV === 'production') it is required to have catalog for English, because messages are not compiled on the fly.
        return import(`../../locales/${language}/messages.ts`)
    }

    return import(
        `../../../../node_modules/@pleo-io/product-web-translations/${language}/messages.ts`
    )
}

export const loadMessages = async (language: SupportedLanguage) => {
    const [messages] = await Promise.all([importMessages(language), importCountryNames(language)])
    return messages
}

/**
 * This looks unnecessary but it's here to silence a pesky Vite warning. There
 * seems to be (as of 05.2023) a bug in Vite which prints a warning like this:
    12:23:59 AM [vite] warning: Unexpected token (1:0)
      Plugin: vite:dynamic-import-vars
      File: /Users/maciek/dev/pleo/product-web/app/packages/i18n/import-message.ts:1:0
 * if you use an import like import(\/* @vite-ignore *\/ `https://something.${here}`)
 */
export async function importExternal(url: string) {
    return import(/* @vite-ignore */ url)
}
