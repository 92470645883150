export function isEqual<T extends Record<string, unknown>>(
    leftOperand: T,
    rightOperand: T,
): boolean {
    // Early return for same reference
    if (leftOperand === rightOperand) {
        return true
    }

    try {
        return JSON.stringify(leftOperand) === JSON.stringify(rightOperand)
    } catch (error) {
        return false // Fails gracefully if serialization fails
    }
}
