import {t} from '@lingui/macro'

import type {CurrencyType} from '@pleo-io/deimos'

import type {
    StaticTransferField,
    StaticTransferFieldBase,
} from '@product-web/shared--api-types/static-transfer-field'
import type {CompanyVanResponseBankName} from '@product-web/shared--bff-moons/generated/telesto'
import type {FundingSourceResponseIssuer} from '@product-web/shared--bff-moons/generated/umbriel'

type BankNameKeys = CompanyVanResponseBankName | FundingSourceResponseIssuer
const bankNames: Record<BankNameKeys, string> = {
    DANSKE_BANK: 'Danske Bank',
    JPM: 'J.P. Morgan',
    EVOLVE: 'Evolve Bank & Trust',
    PATHWARD: 'Pathward Financial, Inc.',
    BANKING_CIRCLE: 'Banking Circle',
}

export const getBankName = (bankName: string) => {
    return bankNames[bankName as BankNameKeys] || bankName
}

export const getLocalLabelMap = (currency: CurrencyType) => {
    switch (currency) {
        case 'GBP':
            return {
                beneficiary: t`Beneficiary`,
                bankCode: t`British sort code`,
                accountNumber: t`Your company's unique Pleo account`,
                bankName: t`Bank`,
            }

        case 'SEK':
            return {
                beneficiary: t`Beneficiary`,
                bankCode: t`Swedish clearing and account number`,
                bankName: t`Bank`,
            }

        case 'NOK':
            return {
                beneficiary: t`Beneficiary`,
                bankCode: t`Norwegian clearing and account number`,
                bankName: t`Bank`,
            }

        case 'DKK':
            return {
                beneficiary: t`Beneficiary`,
                bankCode: t`Danish reg. no.`,
                accountNumber: t`Your company's unique Pleo account`,
                bankName: t`Bank`,
            }

        case 'EUR':
            return {
                beneficiary: t`Beneficiary`,
                iban: 'IBAN',
                bankName: t`Bank`,
                bic: 'BIC',
            }

        case 'USD':
            return {
                beneficiary: t`Beneficiary`,
                routingNumber: t`Routing number (ABA)`,
                accountNumber: t`Account number`,
                bankName: t`Bank`,
            }

        default:
            return {}
    }
}

// used for international fields rendered next to local fields
export const getInternationalLabelMap = (currency: CurrencyType) => {
    switch (currency) {
        case 'GBP':
        case 'SEK':
        case 'NOK':
        case 'DKK':
            return {
                iban: 'IBAN',
                bic: 'BIC',
            }
        default:
            return {}
    }
}

// used for standalone international fields
export const getFullInternationalLabelMap = () => {
    return {
        beneficiary: t`Beneficiary`,
        iban: 'IBAN',
        bic: 'BIC (SWIFT)',
    }
}

const defaultTransforms = {
    bankName: (details: StaticTransferField) => {
        return {value: getBankName(details.bankName)}
    },
}

const transformKeys = ['bankName'] as const

type SpecificTransforms = {
    bankCode?: (details: StaticTransferFieldBase) => {
        value: string
        keepRaw?: boolean
    }
}

const getSpecificTranforms = (currency: CurrencyType): SpecificTransforms => {
    switch (currency) {
        case 'GBP':
            return {
                bankCode: (details: StaticTransferFieldBase) => {
                    const {bankCode} = details
                    return {
                        value: bankCode?.replace(/(\d{2})(\d{2})(\d{2})/, '$1-$2-$3'),
                        keepRaw: true,
                    }
                },
            }

        case 'SEK':
        case 'NOK':
            return {
                bankCode: (details: StaticTransferFieldBase) => {
                    return {value: `${details.bankCode} ${details.accountNumber}`}
                },
            }
    }
    return {}
}

export const transformDetails = (currency: CurrencyType, details: StaticTransferField) => {
    const values = {...details}
    const rawValues = {...details}
    const specificTransforms = getSpecificTranforms(currency)

    for (const key of transformKeys) {
        if (Object.prototype.hasOwnProperty.call(defaultTransforms, key)) {
            const transform = defaultTransforms[key](values)
            values[key] = transform.value
            rawValues[key] = transform.value
        }
    }
    for (const key in specificTransforms) {
        if (Object.prototype.hasOwnProperty.call(specificTransforms, key)) {
            // @ts-ignore this deserves a refactor
            const transform = specificTransforms[key](values)
            // @ts-ignore this deserves a refactor
            values[key] = transform.value
            if (!transform.keepRaw) {
                // @ts-ignore this deserves a refactor
                rawValues[key] = transform.value
            }
        }
    }

    return {values, rawValues}
}
