import {dayjs} from '@product-web/shared--dates/dayjs'

export const getFormattedDates = (startDate?: string, endDate?: string) => {
    if (!endDate) {
        return dayjs(startDate).utc().format('DD MMM YYYY')
    }

    const start =
        startDate && new Date(startDate).getFullYear() === new Date(endDate).getFullYear()
            ? dayjs(startDate).utc().format('DD MMM')
            : dayjs(startDate).utc().format('DD MMM YY')

    const end = dayjs(endDate).utc().format('DD MMM YY')
    return `${start} - ${end}`
}
