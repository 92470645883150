import styled from 'styled-components'

import {Box, Inline, Text, tokens} from '@pleo-io/telescope'

import {WistiaVideo} from '@product-web/shared--telescope-lab/wistia-video'

export const Container = styled(Box)`
    text-align: left;
`

export const BackButtonWrapper = styled(Inline)`
    position: sticky;
    top: 0;
    background-color: ${tokens.shade000};
`

export const BackAction = styled(Text).attrs({color: 'colorContentInteractiveLink'})`
    cursor: pointer;
`
export const Title = styled.p`
    font-size: ${tokens.font2XLarge};
    font-weight: ${tokens.fontWeightRegular};
    line-height: ${tokens.lineHeight2};
`

export const StyledWistiaVideo = styled(WistiaVideo)`
    > div {
        width: 100% !important;
    }

    & img {
        width: 100%;
    }
`
