import {t, Trans} from '@lingui/macro'
import React from 'react'
import {useNavigate} from 'react-router-dom'

import {Box, Button, Stack, Text} from '@pleo-io/telescope'

import type {DispatchActions} from '@product-web/feature--ui-guides/guide'
import {
    ActionBanner,
    ActionBannerActionIcon,
    ActionBannerContent,
} from '@product-web/shared--telescope-lab/action-banner/action-banner'

import {PricingInfoModal} from './pricing-info-modal'

import AddEntityIcon from '../../../../images/add-entity-icon.svg'
import GroupEntitiesIcon from '../../../../images/group-entities-icon.svg'

export const AddOrGroupStepContent = ({onNextStep, onCompleted}: DispatchActions) => {
    const navigate = useNavigate()
    const [isPricingInfoModalOpen, setIsPricingInfoModalOpen] = React.useState(false)

    const handleAddEntityClick = () => {
        onCompleted()
        navigate('/register/new-company/add-entity')
    }

    return (
        <Stack space={16} stretch>
            {isPricingInfoModalOpen && (
                <PricingInfoModal onDismiss={() => setIsPricingInfoModalOpen(false)} />
            )}
            <Text>
                <Trans>
                    Adding an entity might require changes in your pricing plan. After you add an
                    entity, your account manager will contact you to get you set up.{' '}
                    {/* @temp-button-migrations: May look off, due to inline use, when tertiary button styling is updated */}
                    <Button variant="tertiary" onClick={() => setIsPricingInfoModalOpen(true)}>
                        See multi-entity pricing
                    </Button>
                </Trans>
            </Text>
            <ActionBanner onClick={handleAddEntityClick}>
                <Box mr={16} mt={6} mb={6}>
                    <img src={AddEntityIcon} alt="" />
                </Box>
                <ActionBannerContent
                    title={t`Add new entity`}
                    subtitle={t`Create new entity and add it to your organisation`}
                />
                <ActionBannerActionIcon />
            </ActionBanner>
            <ActionBanner onClick={onNextStep}>
                <Box mr={16} mt={6} mb={6}>
                    <img src={GroupEntitiesIcon} alt="" />
                </Box>
                <ActionBannerContent
                    title={t`Group existing companies`}
                    subtitle={t`Group companies that are already using Pleo into one organisation`}
                />
                <ActionBannerActionIcon />
            </ActionBanner>
        </Stack>
    )
}
