import {lazy} from 'react'
import {Route} from 'react-router-dom'

import {roleSets} from '@product-web/shared--user'

const GuidelinesRoutes = lazy(async () => import('./screens/guidelines/routes'))

export const settingsRoutes = (
    <Route
        handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeperExtended}}
        index
        path="*"
        element={<GuidelinesRoutes />}
    />
)
