import {DialogContent, DialogOverlay} from '@reach/dialog'
import Portal from '@reach/portal'
import {AnimatePresence, motion} from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

import {Text, tokens} from '@pleo-io/telescope'

import {breakpoints} from '@product-web/shared--styles/theme'

interface DrawerProp {
    /**
     *  Drawer content
     */
    children: React.ReactNode
    /**
     * Drawer state
     * @default false
     */
    isOpen?: boolean
    /**
     * Callback called with the selected value
     * @default noop
     */
    onDismiss: () => void
    /**
     * Defines a string value that labels the current element.
     */
    'aria-label'?: string
    /**
     * Identifies the element (or elements) that labels the current element.
     */
    'aria-labelledby'?: string
    /**
     * Handle zoom/pinch gestures on iOS devices when scroll locking is enabled.
     * Defaults to `false`.
     */
    allowPinchZoom?: boolean
    /**
     * By default the first focusable element will receive focus when the dialog opens but you can provide a ref to focus instead.
     */
    initialFocusRef?: React.RefObject<any>
    /**
     * See [Reach UI docs](https://reach.tech/dialog#dialogoverlay-dangerouslybypassfocuslock)
     */
    dangerouslyBypassFocusLock?: boolean | undefined
    /**
     * See [Reach UI docs](https://reach.tech/dialog#dialogoverlay-dangerouslybypassscrolllock)
     */
    dangerouslyBypassScrollLock?: boolean | undefined
    /**
     *
     */
    size?: 'auto'

    noBackdrop?: boolean | undefined
}

const drawerVariants = {
    initial: {
        x: 0,
        opacity: 0,
        transition: {
            opacity: tokens.slowOut,
        },
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            opacity: tokens.smoothIn,
        },
    },
}

const contentVariants = {
    initial: {
        x: '100%',
        opacity: 0,
        transition: {
            opacity: tokens.slowOut,
        },
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            opacity: tokens.slowOut,
        },
    },
}

type DrawerAliasProps = Omit<
    DrawerProp,
    | 'isOpen'
    | 'onDismiss'
    | 'allowPinchZoom'
    | 'initialFocusRef'
    | 'dangerouslyBypassFocusLock'
    | 'dangerouslyBypassScrollLock'
    | 'noBackdrop'
>
const DrawerAlias = ({children, ...props}: DrawerAliasProps) => (
    <DrawerRoot
        {...props}
        initial="initial"
        animate="animate"
        exit="initial"
        variants={contentVariants}
        aria-label={props['aria-label'] ?? 'drawer'}
        aria-labelledby={props['aria-labelledby']}
        data-drawer
        data-generic-ui="drawer"
    >
        <DrawerContent>{children}</DrawerContent>
    </DrawerRoot>
)

/**
 *
 * @deprecated Use the '@pleo-io/telescope' Drawer component
 */
export function Drawer({
    isOpen,
    onDismiss,
    allowPinchZoom,
    initialFocusRef,
    dangerouslyBypassFocusLock,
    dangerouslyBypassScrollLock,
    noBackdrop,
    ...props
}: DrawerProp) {
    return (
        <AnimatePresence>
            {isOpen && (
                <Portal>
                    {!noBackdrop ? (
                        <DrawerBackDrop
                            initial="initial"
                            animate="animate"
                            exit="initial"
                            variants={drawerVariants}
                            isOpen={isOpen}
                            onDismiss={onDismiss}
                            allowPinchZoom={allowPinchZoom}
                            initialFocusRef={initialFocusRef}
                            dangerouslyBypassFocusLock={dangerouslyBypassFocusLock}
                            dangerouslyBypassScrollLock={dangerouslyBypassScrollLock}
                            data-drawer-backdrop
                        >
                            <DrawerAlias {...props} />
                        </DrawerBackDrop>
                    ) : (
                        <DrawerAlias {...props} />
                    )}
                </Portal>
            )}
        </AnimatePresence>
    )
}

export function useDrawer({
    onOpen,
    onClose,
    ...otherProps
}: {isOpen?: boolean; onOpen?: () => void; onClose?: () => void} = {}) {
    const [isOpen, setIsOpen] = React.useState(otherProps.isOpen ?? false)

    const open = () => {
        setIsOpen(true)
        onOpen?.()
    }

    const close = () => {
        setIsOpen(false)
        onClose?.()
    }

    return {isOpen, open, close}
}

interface ModalProps {
    size?: 'auto'
}

const DrawerRoot = motion(styled(DialogContent)<ModalProps>`
    width: ${({size}) => size ?? '300px'};
    margin: auto;
    background: ${tokens.colorBackgroundStatic};
    outline: none;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0;
    height: 100%;
    padding: 0;
    overflow-y: auto;

    @media (max-width: ${breakpoints.tabletMedUp}) {
        width: 100%;
        max-width: 100%;
        margin-top: auto;
        margin-bottom: 0;
    }
`)

const DrawerBackDrop = motion(styled(DialogOverlay)`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: ${tokens.spacing10};
    background: ${tokens.colorBackgroundStaticBackdrop};
    backface-visibility: hidden;
    scroll-behavior: smooth;
`)

type DrawerContentProps = {
    /**
     * Set the `text-align` property
     */
    align?: 'left' | 'right' | 'center'
    /**
     * Add bottom margin to the text element. Use [`Stack`](/components/layout/develop/#stack) for more flexibility.
     */
    spacing?: 4 | 10 | 20 | 32
}

const DrawerContent = styled(Text).attrs<DrawerContentProps>((props) => ({
    as: 'section',
    variant: 'medium-default',
    align: props.align || 'center',
    color: 'colorContentStatic',
}))`
    background-color: ${tokens.colorBackgroundStaticLouder};
    height: 100%;
    padding: 0;
`
