import {t, Trans} from '@lingui/macro'
import {useNavigate} from 'react-router-dom'

import {Button, LoadingPage, Stack, Text} from '@pleo-io/telescope'

import {useToaster} from '@product-web/shared--toaster'

import {bff} from '../../../../bff-hooks'
import {GenericError} from '../../../../components/generic-error'
import {Layout} from '../../components/layout'
import illustration from '../../images/todo-list.svg'

export const StartOnboarding = () => {
    const navigate = useNavigate()

    const {showToast} = useToaster()

    const {data, isLoading, isError} = bff.startOnboarding.getStartOnboardingInfo.useQuery()

    const {mutateAsync: startOnboarding, isLoading: isStartingMemberOnboarding} =
        bff.startOnboarding.startMemberOnboarding.useMutation({
            onSuccess: () => navigate('/partner'),
            onError: () => {
                showToast(t`Please try again, or contact support.`, {
                    title: t`Something went wrong`,
                    level: 'error',
                })
            },
        })

    if (isLoading) {
        return <LoadingPage />
    }

    if (isError) {
        return <GenericError />
    }

    const partnerName = data.partnerName

    return (
        <Layout>
            <Layout.Content mt={60}>
                <Layout.Left>
                    <Stack mt={24} space={12}>
                        <Text as="h1" variant="3xlarge-accent">
                            <Trans>Welcome {partnerName}</Trans>
                        </Text>
                        <Text space={10}>
                            <Trans>
                                To help you get started, we have created an onboarding checklist for
                                you. It will contain tasks relevant to your role to help you get
                                familiar with the Partner Portal, and to set up your clients
                                quickly.
                            </Trans>
                        </Text>
                        <Text>
                            <Trans>
                                Don't worry if you can't finish it all in one go - you can always
                                come back to them later.
                            </Trans>
                        </Text>
                    </Stack>
                    <Layout.Actions>
                        <Button
                            variant="primary"
                            onClick={async () => await startOnboarding()}
                            loading={isStartingMemberOnboarding}
                        >
                            <Trans>Start onboarding</Trans>
                        </Button>
                    </Layout.Actions>
                </Layout.Left>
                <Layout.Right>
                    <Layout.Illustration src={illustration} />
                </Layout.Right>
            </Layout.Content>
        </Layout>
    )
}
