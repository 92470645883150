import {t} from '@lingui/macro'

import {getRequestErrorPleoMessage} from '@product-web/shared--api-error'
import {hasKey} from '@product-web/shared--utils'

/**
 * Extract error message with fallback message in case error message is not found
 */
export const getErrorMessage = (error: unknown, fallbackMessage = t`Unknown error`) => {
    const errorMessage = getRequestErrorPleoMessage(error)
    if (errorMessage) {
        return errorMessage
    }

    if (hasKey('message', error) && typeof error.message === 'string') {
        return error.message
    }

    return fallbackMessage
}
