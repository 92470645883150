import {t} from '@lingui/macro'

import Locale from '@product-web/shared--locale/locale'

import {Export} from '../features/export/types'
import {defaultLocale, Terms} from '../features/terms/types'
import {TravelInsurance} from '../features/travel-insurance/types'
import type {Configuration} from '../types'
import {Feature} from '../types'

export const legalDocumentsBaseUrl = 'https://pleo.io/legal-documents'

const Default: Configuration = {
    [Feature.Terms]: {
        [Terms.Documents]: {
            masterServiceAgreement: {
                [defaultLocale]: 'pleo-master-service-agreement-eea-eu.pdf',
            },
            masterServiceAgreementB4B: {
                [defaultLocale]: 'pleo-master-service-agreement-uk.pdf',
            },
            masterServiceAgreementPathward: {
                [defaultLocale]: 'pathward-msa.pdf',
            },
            dataProcessingAgreement: {
                [defaultLocale]: 'pleo-data-processing-addendum-en.pdf',
            },
            privacyPolicy: {
                [defaultLocale]: 'pleo-privacy-policy-en.pdf',
            },
            privacyPolicyPathward: {
                [defaultLocale]: 'pathward-privacy-notice.pdf',
            },
            purchaseTerms: {
                [defaultLocale]: `pleo-purchase-agreement-en-v2.pdf`,
                [Locale.de_AT]: `pleo-purchase-agreement-de-at-v1.pdf`,
                [Locale.fr_BE]: `pleo-purchase-agreement-fr-be-v1.pdf`,
                [Locale.nl_BE]: `pleo-purchase-agreement-nl-be-v1.pdf`,
                [Locale.de_DE]: `pleo-purchase-agreement-de-v1.pdf`,
                [Locale.da_DK]: `pleo-purchase-agreement-da-v1.pdf`,
                [Locale.es_ES]: `pleo-purchase-agreement-es-v1.pdf`,
                [Locale.fi_FI]: `pleo-purchase-agreement-fi-v1.pdf`,
                [Locale.fr_FR]: `pleo-purchase-agreement-fr-v1.pdf`,
                [Locale.it_IT]: `pleo-purchase-agreement-it-v1.pdf`,
                [Locale.nl_NL]: `pleo-purchase-agreement-nl-v1.pdf`,
                [Locale.nb_NO]: `pleo-purchase-agreement-no-v1.pdf`,
                [Locale.nn_NO]: `pleo-purchase-agreement-no-v1.pdf`,
                [Locale.pt_PT]: `pleo-purchase-agreement-pt-v1.pdf`,
                [Locale.sv_SE]: `pleo-purchase-agreement-sv-v1.pdf `,
            },
            cardholderAgreement: {
                [defaultLocale]: `pleo_prepaid_CHA_us.pdf`,
            },
            cardholderAgreementPathward: {
                [defaultLocale]: `pathward-cardholder-agreement.pdf`,
            },
            authorisedUserTerms: {
                [defaultLocale]: `pleo_authorized_user_disclosure_us.pdf`,
            },
            authorisedUserTermsPathward: {
                [defaultLocale]: `pathward-user-disclosures.pdf`,
            },
            overdraftTerms: {
                [defaultLocale]: `pleo-overdraft-terms-and-conditions-en.pdf`,
            },
        },
    },
    [Feature.Export]: {
        [Export.CustomFormat]: {
            contraAccountNumber: 1800,
            accountNumber: 4200,
            vatAccountNumber: 2630,
        },
    },
    [Feature.TravelInsurance]: {
        [TravelInsurance.ClaimInformation]: {
            policyDocumentUrl: 'terms-pleo-travel-insurance-uk.pdf',
            phoneNumber: '+44 (0) 203 409 7820',
            notCoveredInfoUrl: 'https://blog.pleo.io/en/travel-insurance',
        },
    },
    [Feature.Paywall]: {
        isCashBackCountry: true,
        invoicesEnabled: false,
        perDiemEnabled: false,
        noVATReclaim: false,
        noMobilePay: false,
    },
    [Feature.Mileage]: {
        canUseMileage: true,
        requirePurposeOfMileage: false,
        canUseFuelRates: false,
        fuelRatesTitle: () => t`Advisory fuel rates`,
        requiresValidation: false,
    },
    [Feature.PerDiem]: {
        requirePurposeOfPerDiem: false,
        canUseWebPerDiem: false,
        canSetOvernightStay: false,
    },
    [Feature.CardOrder]: {
        afterShipTrackingUrl: 'https://getpleo.aftership.com/',
    },
    [Feature.VendorCards]: {
        canUseVendorCards: true,
    },
}

export default Default
