import * as React from 'react'
import {Outlet, Route} from 'react-router-dom'

import {Page} from '@product-web/feature--ui-page/page'

const PartnerCompany = React.lazy(async () => import('./screens/partner-company'))

const CompanyWrapper = () => (
    <Page layout="wide" showNav>
        <Outlet />
    </Page>
)

export const routes = (
    <Route element={<CompanyWrapper />}>
        <Route index element={<PartnerCompany />} />
    </Route>
)
