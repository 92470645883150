import {request} from '@product-web/shared--api'
import {RequestAuth} from '@product-web/shared--api'
import config from '@product-web/shared--config'

const baseUrl = config.endpoints.api

interface UpdateProfilePayload {
    firstName: string
    lastName: string
    phone: string | null
    jobTitle: string | null
}
export type ViewerRole = 'employee' | 'bookkeeper' | 'partner-employee'

export async function updateProfile(viewerRole: ViewerRole, payload: UpdateProfilePayload) {
    return request(`${baseUrl}/rest/v1/user?viewerRole=${viewerRole}`, {
        auth: RequestAuth.USER,
        method: 'PUT',
        body: payload,
    })
}

export async function updateAvatar(file: File, onProgress?: (number: number) => void) {
    return request(`${baseUrl}/rest/v1/user/avatar`, {
        auth: RequestAuth.USER,
        method: 'POST',
        file,
        onProgress,
    })
}
