import tracking from '@product-web/shared--analytics'
import type {
    AutoTopUpStatus,
    AutoTopUpType,
    ScheduledTopupFrequency,
} from '@product-web/shared--api-types/wallet'

export enum ManageAutoTopUpAction {
    ACTIVATE = 'activate',
    CLICK_ACTIVATE = 'click_activate',
    CHANGE_SETTINGS = 'change_settings',
    DISABLE = 'disable',
    SWITCH_STRATEGY = 'switch_strategy',
    CLICK_SWITCH_TO_FASTER_OPTION = 'click_switch_to_faster_option',
}

export type AutoTopUpSettingsParams = {
    atu_type: AutoTopUpType
    atu_status: AutoTopUpStatus
    // ATU details for Low Balance strategy
    atu_amount_low_balance?: number
    atu_threshold_low_balance?: number
    // ATU details for Scheduled strategy
    atu_amount_scheduled?: number
    atu_frequency_scheduled?: ScheduledTopupFrequency
}

type ManageAutoTopUpWithSettingsParams = AutoTopUpSettingsParams & {
    action:
        | ManageAutoTopUpAction.ACTIVATE
        | ManageAutoTopUpAction.CHANGE_SETTINGS
        | ManageAutoTopUpAction.DISABLE
}

type SwitchAutoTopUpParams = AutoTopUpSettingsParams & {
    action: ManageAutoTopUpAction.SWITCH_STRATEGY
    prev_auto_top_up_type: AutoTopUpType
}

type AutoTopUpWithBasicDetailsParams = {
    action:
        | ManageAutoTopUpAction.CLICK_ACTIVATE
        | ManageAutoTopUpAction.CLICK_SWITCH_TO_FASTER_OPTION
    atu_type: AutoTopUpType
}

export type ManageAutoTopUpParams =
    | ManageAutoTopUpWithSettingsParams
    | SwitchAutoTopUpParams
    | AutoTopUpWithBasicDetailsParams

export function trackManageAutoTopUp(params: ManageAutoTopUpParams) {
    tracking.manageAutoTopUp(params)
}
