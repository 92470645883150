/* eslint-disable string-to-lingui/missing-lingui-transformation */

import {t} from '@lingui/macro'

import yup from '@product-web/shared--validation/yup'

const partnerSizes = {
    'Sole trader': 'Sole trader',
    '1 employee': '1 employee',
    '2-4 employees': '2-4 employees',
    '5-9 employees': '5-9 employees',
    '10-49 employees': '10-49 employees',
    '50-99 employees': '50-99 employees',
    '100-249 employees': '100-249 employees',
    '250-499 employees': '250-499 employees',
    '500+ employees': '500+ employees',
} as const

export type PartnerSizeValue = (typeof partnerSizes)[keyof typeof partnerSizes]

export const partnerSizeOptions = () => [
    {label: t`Sole trader`, value: partnerSizes['Sole trader']},
    {label: t`1 employee`, value: partnerSizes['1 employee']},
    {label: t`2 - 4 employees`, value: partnerSizes['2-4 employees']},
    {label: t`5 - 9 employees`, value: partnerSizes['5-9 employees']},
    {label: t`10 - 49 employees`, value: partnerSizes['10-49 employees']},
    {label: t`50 - 99 employees`, value: partnerSizes['50-99 employees']},
    {label: t`100 - 249 employees`, value: partnerSizes['100-249 employees']},
    {label: t`250 - 499 employees`, value: partnerSizes['250-499 employees']},
    {label: t`500+ employees`, value: partnerSizes['500+ employees']},
]

export const partnerSizeValidator = yup
    .string()
    .required(() => t`Please select the total number of employees in your accountancy.`)
