import {useEffect, useState} from 'react'
import {useLocation} from 'react-router'

import {ADOPTION_ESSENTIALS_PATHS} from '@product-web/feature--help-centre/get-is-adoption-centre-visible'
import {useCompanySettings} from '@product-web/shared--api-deimos/company-settings'
import {useFlags} from '@product-web/shared--flags'
import {useHasAllowedRole} from '@product-web/shared--user'
import {localStorage} from '@product-web/shared--web-platform/storage/local'

import {bff} from '../bff-hooks'

export const VISMA_MIGRATION_ADOPTION_PROMPT_KEY =
    '@pleo/adoption/vismaMigrationAdoptionPromptViewed'
const MAX_VIEW_COUNT = 3
const SEVEN_DAYS = 7 * 24 * 60 * 60 * 1000
const THREE_DAYS = 3 * 24 * 60 * 60 * 1000
const ONE_DAY = 1 * 24 * 60 * 60 * 1000

const VISMA_MIGRATION_ADOPTION_PATHS = ADOPTION_ESSENTIALS_PATHS.filter(
    (path) => path !== '/reimbursements' && path !== '/invoices',
)

export const useVismaMigrationAdoption = () => {
    const [shouldShowVismaMigrationAdoptionPrompt, setShouldShowVismaMigrationAdoptionPrompt] =
        useState(false)
    const {newVismaEconomicsMigration} = useFlags()
    const {data: companySettings} = useCompanySettings()
    const shouldShowMigrationFlow = newVismaEconomicsMigration
        ?.split(',')
        .includes('SHOW_MIGRATION_HELPERS')
    const isOwnerOrBookkeeper = useHasAllowedRole([
        'owner',
        'bookkeeper-extended',
        'bookkeeper-basic',
    ])

    const location = useLocation()
    const isVisibleLocation = VISMA_MIGRATION_ADOPTION_PATHS.some((p) =>
        location.pathname.startsWith(p),
    )

    const getVismaMigrationAdoptionPromptState = (): {
        shouldShow: boolean
        retryAfter: number | null
        viewCount: number
    } => {
        const state = localStorage.getItem(VISMA_MIGRATION_ADOPTION_PROMPT_KEY)
        return state ? JSON.parse(state) : {shouldShow: true, retryAfter: null, viewCount: 0}
    }

    const setVismaMigrationAdoptionPromptState = (state: {
        shouldShow: boolean
        retryAfter: number | null
        viewCount: number
    }) => {
        localStorage.setItem(VISMA_MIGRATION_ADOPTION_PROMPT_KEY, JSON.stringify(state))
    }

    const promptState = getVismaMigrationAdoptionPromptState()

    const {data: vismaMigrationAdoptionPromptStatus} =
        bff.adoption.vismaMigrationAdoptionPrompt.getVismaMigrationAdoptionPromptStatus.useQuery(
            {accountingSystem: companySettings?.accounting.system},
            {
                enabled: isOwnerOrBookkeeper && promptState.shouldShow,
            },
        )

    useEffect(() => {
        const shouldShow =
            !!vismaMigrationAdoptionPromptStatus?.isVisible &&
            isOwnerOrBookkeeper &&
            shouldShowMigrationFlow &&
            isVisibleLocation &&
            promptState.viewCount < MAX_VIEW_COUNT &&
            (!promptState.retryAfter || promptState.retryAfter < Date.now())
        setShouldShowVismaMigrationAdoptionPrompt(shouldShow)
    }, [
        vismaMigrationAdoptionPromptStatus,
        isOwnerOrBookkeeper,
        shouldShowMigrationFlow,
        isVisibleLocation,
        promptState.viewCount,
        promptState.retryAfter,
    ])

    const setVismaMigrationAdoptionPromptActioned = () => {
        setVismaMigrationAdoptionPromptState({
            ...promptState,
            shouldShow: promptState.viewCount < MAX_VIEW_COUNT,
            retryAfter: Date.now() + ONE_DAY,
            viewCount: promptState.viewCount,
        })
        setShouldShowVismaMigrationAdoptionPrompt(false)
    }

    const setVismaMigrationAdoptionPromptDismissed = () => {
        setVismaMigrationAdoptionPromptState({
            ...promptState,
            shouldShow: promptState.viewCount + 1 < MAX_VIEW_COUNT,
            retryAfter: promptState.viewCount + 1 < MAX_VIEW_COUNT ? Date.now() + THREE_DAYS : null,
            viewCount: Math.min(promptState.viewCount + 1, MAX_VIEW_COUNT),
        })
        setShouldShowVismaMigrationAdoptionPrompt(false)
    }

    const setVismaMigrationAdoptionPromptRemindLater = () => {
        setVismaMigrationAdoptionPromptState({
            ...promptState,
            shouldShow: promptState.viewCount + 1 < MAX_VIEW_COUNT,
            retryAfter: promptState.viewCount + 1 < MAX_VIEW_COUNT ? Date.now() + SEVEN_DAYS : null,
            viewCount: Math.min(promptState.viewCount + 1, MAX_VIEW_COUNT),
        })
        setShouldShowVismaMigrationAdoptionPrompt(false)
    }

    return {
        shouldShowVismaMigrationAdoptionPrompt,
        setVismaMigrationAdoptionPromptActioned,
        setVismaMigrationAdoptionPromptDismissed,
        setVismaMigrationAdoptionPromptRemindLater,
    }
}
