import {Outlet} from 'react-router-dom'

import {useIsLoggedIn} from '@product-web/shared--auth--session/context'
import {useUser} from '@product-web/shared--user'

const TopLevelComponents = () => {
    const user = useUser()
    const isLoggedIn = useIsLoggedIn()

    return (
        <>
            <Outlet />
            {isLoggedIn && Boolean(user) && (
                <>{/* Add top level components for all routes here */}</>
            )}
        </>
    )
}

export default TopLevelComponents
