import {t} from '@lingui/macro'

import type {ActivePlanType} from '@product-web/shared--paywall/types/plans'
import {PlanTypeName} from '@product-web/shared--paywall/types/plans'
import {PlanType} from '@product-web/shared--paywall/types/plans'

export interface ConditionedPlan {
    type: ActivePlanType
    titleText: string
}

export interface ConditionedPlans {
    starter: ConditionedPlan
    essential: ConditionedPlan
    advanced: ConditionedPlan
    beyond: ConditionedPlan
}

const planTitleText = (planType: ActivePlanType) => {
    const planTypeName = PlanTypeName[planType]
    return t`${planTypeName} Plan`
}

export default function useConditionedPlans(): ConditionedPlans {
    return {
        starter: {type: PlanType.STARTER, titleText: planTitleText(PlanType.STARTER)},
        essential: {type: PlanType.ESSENTIAL, titleText: planTitleText(PlanType.ESSENTIAL)},
        advanced: {type: PlanType.ADVANCED, titleText: planTitleText(PlanType.ADVANCED)},
        beyond: {type: PlanType.BEYOND, titleText: planTitleText(PlanType.BEYOND)},
    }
}
