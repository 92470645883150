import {t, Trans} from '@lingui/macro'
import Helmet from 'react-helmet'
import {Link as RouterLink} from 'react-router-dom'

import {Button, Inline, Link, Stack, Tag, Text, tokens} from '@pleo-io/telescope'
import {ArrowLeft, Clock} from '@pleo-io/telescope-icons'

import {Page} from '@product-web/feature--ui-page'

import {SplitContent} from '../../../../components/split-content'
import {CourseSummary} from '../../components/course-summary'
import illustration from '../../images/introduction-to-pleo.svg'

export const IntroductionToPleo = () => (
    <>
        <Helmet>
            <title>{t`Introduction to Pleo`}</title>
        </Helmet>
        <Page.Header>
            <Page.Backlink>
                <Link to="/partner/academy" as={RouterLink} IconLeft={ArrowLeft}>
                    <Trans>Back</Trans>
                </Link>
            </Page.Backlink>
            <Page.SpaceBetween>
                <Page.Title>
                    <Trans>Introduction to Pleo</Trans>
                </Page.Title>
            </Page.SpaceBetween>
            <Inline space={16}>
                <Tag variant="pink">
                    <Trans>Admins</Trans>
                </Tag>
                <Tag variant="gray">
                    <Trans>Bookkeepers</Trans>
                </Tag>
                <Inline alignItems="center" space={6}>
                    <Clock size={16} color={tokens.colorContentStaticQuiet} />{' '}
                    <Text color="colorContentStaticQuiet">
                        <Trans>7 mins</Trans>
                    </Text>
                </Inline>
            </Inline>
        </Page.Header>
        <SplitContent>
            <SplitContent.Main>
                <Stack mt={24}>
                    <Text as="h2" variant="2xlarge-accent" space={4}>
                        <Trans>What's it about?</Trans>
                    </Text>
                    <Text color="colorContentStaticQuiet">
                        <Trans>Learn about how Pleo works and what we can offer</Trans>
                    </Text>
                    <CourseSummary
                        steps={[
                            t`Introduction (3 mins)`,
                            t`Employee experience (2 mins)`,
                            t`Pleo for your clients (2 mins)`,
                        ]}
                    />
                </Stack>
                <Page.Actions>
                    <Button
                        aria-label={t`Let's go`}
                        variant="primary"
                        as={RouterLink}
                        to="./introduction"
                    >
                        <Trans>Let's go</Trans> &rarr;
                    </Button>
                </Page.Actions>
            </SplitContent.Main>
            <SplitContent.Right trivial>
                <img src={illustration} alt="" />
            </SplitContent.Right>
        </SplitContent>
    </>
)
