import {useLocation} from 'react-router'

import {dayjs} from '@product-web/shared--dates/dayjs'
import {useFlags} from '@product-web/shared--flags'
import {getIsCompanyOwner, getIsPartnerUser, useUser} from '@product-web/shared--user'

import {bff} from './bff-hooks'

const MIN_DAYS_NOT_PAID_TO_SHOW_MODAL = 3
const MAX_DAYS_NOT_PAID_BEFORE_SUSPENSION = 7

const UNBLOCKED_PAGE_PATHS = ['/settings/general/billing', '/export']

const getDaysNotPaid = (oldestOpenInvoiceDate: string | undefined) => {
    const now = dayjs()
    const earliestIssueDate = oldestOpenInvoiceDate || new Date()
    return now.diff(earliestIssueDate, 'days')
}

export default function usePaywallStatus() {
    const user = useUser()
    const {
        data,
        error: dataError,
        isFetching,
    } = bff.paywall.paywallStatus.getData.useQuery(undefined, {
        enabled: Boolean(user?.companyId && getIsCompanyOwner(user) && !getIsPartnerUser(user)),
    })
    const location = useLocation()
    const {paywall: paywallFeatureEnabled} = useFlags()

    /**
     * 200 + value as 0 means there are no OPEN invoices (i.e. they are all paid)
     * 404 means the company has never had any Stripe invoices at all (paid or otherwise).
     */
    const hasNoUnpaidInvoices = data
        ? data.totalUnpaidAmountDue.value === 0
        : dataError?.data?.httpStatus === 404
    const hasPaidInvoice = Boolean(data?.firstPaidInvoiceDate)
    const goingToUnblockedPage = Boolean(
        UNBLOCKED_PAGE_PATHS.find((p) => location.pathname.includes(p)),
    )

    const amount = data && {
        value: data.totalUnpaidAmountDue.value / 100,
        currency: data.totalUnpaidAmountDue.currency,
    }
    const daysNotPaid = getDaysNotPaid(data?.oldestOpenInvoiceDate)
    const daysLeft = Math.max(0, MAX_DAYS_NOT_PAID_BEFORE_SUSPENSION - daysNotPaid + 1)
    const isSuspended = daysLeft === 0
    const firstPaidInvoiceDate = data?.firstPaidInvoiceDate

    const userIsOwnerAndNotPartner = user && getIsCompanyOwner(user) && !getIsPartnerUser(user)
    const userHasUnpaidInvoices = data && !hasNoUnpaidInvoices
    const paywallActive =
        userIsOwnerAndNotPartner &&
        userHasUnpaidInvoices &&
        !goingToUnblockedPage &&
        daysNotPaid >= MIN_DAYS_NOT_PAID_TO_SHOW_MODAL &&
        paywallFeatureEnabled

    return {
        hasPaidInvoice,
        amount,
        isSuspended,
        firstPaidInvoiceDate,
        paywallActive,
        isValidating: isFetching,
        daysLeft,
    }
}
