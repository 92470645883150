import type {FC} from 'react'
import styled from 'styled-components'

import {Text, tokens} from '@pleo-io/telescope'

import {Copyable} from '@product-web/shared--telescope-lab/copyable/copyable'
import {useToaster} from '@product-web/shared--toaster'

import {trackBankTransfer} from '../tracking'

const StyledParagraph = styled(Text).attrs({variant: 'medium-default', as: 'p'})`
    margin-bottom: 0;
`
const ValueWrapper = styled.div`
    padding: ${tokens.spacing4};
`

const Value = styled.span`
    font-size: 18px;
    line-height: ${tokens.lineHeight1};
    color: ${tokens.shade800};
    letter-spacing: 0;
`

const ValueCrossed = styled(Value)`
    text-decoration: line-through;
    text-decoration-color: ${tokens.shade600};
`

const TransferFieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

interface Props {
    label: string
    value: string | number
    rawValue: string | number
    isCrossed?: boolean
}

const TransferField: FC<Props> = ({label, value, rawValue, isCrossed}) => {
    const {showToast} = useToaster()
    return (
        <TransferFieldWrapper data-testid="transfer-field">
            <StyledParagraph color="shade600">{label}</StyledParagraph>
            <ValueWrapper>
                <Copyable
                    valueToCopy={rawValue.toString()}
                    callback={({message, isSuccess}) => {
                        trackBankTransfer('copy')
                        showToast(message, {level: isSuccess ? 'success' : 'error'})
                    }}
                >
                    {isCrossed ? <ValueCrossed>{value}</ValueCrossed> : <Value>{value}</Value>}
                </Copyable>
            </ValueWrapper>
        </TransferFieldWrapper>
    )
}

export default TransferField
