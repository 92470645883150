import {t} from '@lingui/macro'

import type {
    Amount,
    BillingInfoCurrency,
    CpqBillingPeriodType,
    CpqRatePlanType,
} from '@product-web/shared--bff-moons/generated/beyond'
import type {SupportedLanguage} from '@product-web/shared--i18n'
import {exhaustiveCheck} from '@product-web/shared--utils'

import {formatMinorNumberToCurrency} from './currency-formatter'

// Pricing plan names should not be translated
/* eslint-disable string-to-lingui/missing-lingui-transformation */
export const PlanTypeName: Record<CpqRatePlanType, string> = {
    STARTER: 'Starter',
    ESSENTIAL: 'Essential',
    ADVANCED: 'Advanced',
    BEYOND: 'Beyond',
}
/* eslint-enable string-to-lingui/missing-lingui-transformation */

export type PlanConfiguration = {
    planName: string
    description: string
    subTitle: string
    uniqueEntitlements: string[]
    allEntitlements: string[]
    contactSalesEnabled: boolean
    cashback?: number
    overdraft?: {[currency: string]: number}
    tempCards?: {[currency: string]: number}
    reimbursementsPercentage?: number
    reimbursementsCap?: {[currency: string]: number}
    atmWithdrawalFee?: {[currency: string]: number}
}

type PlanEntitlements = {
    planName: CpqRatePlanType
    entitlements: string[]
    inheritedEntitlementsFrom?: CpqRatePlanType
}

const getAllEntitlementsOfPlan = (planType: CpqRatePlanType) => {
    const planMap = new Map(planEntitlements.map((plan) => [plan.planName, plan]))
    const result: string[] = []

    let currentPlanName: CpqRatePlanType | undefined = planType

    while (currentPlanName) {
        const currentPlan = planMap.get(currentPlanName)
        if (currentPlan) {
            result.unshift(...currentPlan.entitlements)
            currentPlanName = currentPlan.inheritedEntitlementsFrom
        } else {
            break
        }
    }

    return result
}

const getUniqueEntitlementsOfPlan = (planType: CpqRatePlanType) => {
    const planMap = new Map(planEntitlements.map((plan) => [plan.planName, plan]))
    const currentPlan = planMap.get(planType)
    return currentPlan?.entitlements ?? []
}

const planEntitlements: PlanEntitlements[] = [
    {
        planName: 'STARTER',
        entitlements: (() => [t`Real-time expense tracking`, t`Automated expense reports`])(),
    },
    {
        planName: 'ESSENTIAL',
        entitlements: (() => [
            t`Reimbursements`,
            t`Mileage & per diem`,
            t`Flexible card limits`,
            t`Automated review flows`,
        ])(),
        inheritedEntitlementsFrom: 'STARTER',
    },
    {
        planName: 'ADVANCED',
        entitlements: (() => [
            t`Multi-entity management`,
            t`Vendor management`,
            t`Advanced controls`,
            t`Advanced roles & permissions`,
            t`HRIS integrations`,
        ])(),
        inheritedEntitlementsFrom: 'ESSENTIAL',
    },
    {
        planName: 'BEYOND',
        entitlements: (() => [
            t`Spending insights`,
            t`Subwallets`,
            t`Phone support`,
            t`Concierge support`,
            t`Customer success manager`,
        ])(),
        inheritedEntitlementsFrom: 'ADVANCED',
    },
]

export const getPlanContentConfiguration = (planType: CpqRatePlanType): PlanConfiguration => {
    switch (planType) {
        case 'STARTER':
            return {
                planName: PlanTypeName.STARTER,
                description: t`Start automating expense management and bookkeeping.`,
                subTitle: t`Includes`,
                uniqueEntitlements: getUniqueEntitlementsOfPlan('STARTER'),
                allEntitlements: getAllEntitlementsOfPlan('STARTER'),
                contactSalesEnabled: false,
                cashback: undefined,
                overdraft: undefined,
                tempCards: undefined,
                reimbursementsPercentage: undefined,
                reimbursementsCap: undefined,
                atmWithdrawalFee: {
                    EUR: 500,
                    GBP: 500,
                    USD: 500,
                    DKK: 4500,
                    SEK: 6000,
                    NOK: 5200,
                },
            }
        case 'ESSENTIAL':
            return {
                planName: PlanTypeName.ESSENTIAL,
                description: t`Streamline all expenses and accounts payable through a single platform.`,
                subTitle: t`Everything in Starter, plus`,
                uniqueEntitlements: getUniqueEntitlementsOfPlan('ESSENTIAL'),
                allEntitlements: getAllEntitlementsOfPlan('ESSENTIAL'),
                contactSalesEnabled: false,
                cashback: undefined,
                overdraft: {
                    EUR: 2000000,
                    GBP: 2000000,
                    USD: 0,
                    DKK: 14800000,
                    NOK: 0,
                    SEK: 20000000,
                },
                tempCards: undefined,
                reimbursementsPercentage: 0.9,
                reimbursementsCap: {
                    EUR: 100,
                    GBP: 100,
                    USD: 100,
                    DKK: 1000,
                    NOK: 1000,
                    SEK: 1000,
                },
                atmWithdrawalFee: {
                    EUR: 0,
                    GBP: 0,
                    USD: 0,
                    DKK: 0,
                    SEK: 0,
                    NOK: 0,
                },
            }
        case 'ADVANCED':
            return {
                planName: PlanTypeName.ADVANCED,
                description: t`Scale spend control and efficiency across systems and entities.`,
                subTitle: t`Everything in Essential, plus`,
                uniqueEntitlements: getUniqueEntitlementsOfPlan('ADVANCED'),
                allEntitlements: getAllEntitlementsOfPlan('ADVANCED'),
                contactSalesEnabled: true,
                cashback: 0.5,
                overdraft: {
                    EUR: 25000000,
                    GBP: 25000000,
                    USD: 0,
                    DKK: 190000000,
                    NOK: 0,
                    SEK: 250000000,
                },
                tempCards: {
                    EUR: 90,
                    GBP: 90,
                    USD: 90,
                    DKK: 700,
                    NOK: 1000,
                    SEK: 1000,
                },
                reimbursementsPercentage: 0.9,
                reimbursementsCap: {
                    EUR: 100,
                    GBP: 100,
                    USD: 100,
                    DKK: 1000,
                    NOK: 1000,
                    SEK: 1000,
                },
                atmWithdrawalFee: {
                    EUR: 0,
                    GBP: 0,
                    USD: 0,
                    DKK: 0,
                    SEK: 0,
                    NOK: 0,
                },
            }
        case 'BEYOND':
            return {
                planName: PlanTypeName.BEYOND,
                description: t`Master business spend with smart insights and premium support.`,
                subTitle: t`Everything in Advanced, plus`,
                uniqueEntitlements: getUniqueEntitlementsOfPlan('BEYOND'),
                allEntitlements: getAllEntitlementsOfPlan('BEYOND'),
                contactSalesEnabled: true,
                cashback: 0.75,
                overdraft: {
                    EUR: 50000000,
                    GBP: 50000000,
                    USD: 0,
                    DKK: 380000000,
                    NOK: 0,
                    SEK: 500000000,
                },
                tempCards: {
                    EUR: 90,
                    GBP: 90,
                    USD: 90,
                    DKK: 700,
                    NOK: 1000,
                    SEK: 1000,
                },
                reimbursementsPercentage: 0,
                reimbursementsCap: undefined,
                atmWithdrawalFee: {
                    EUR: 0,
                    GBP: 0,
                    USD: 0,
                    DKK: 0,
                    SEK: 0,
                    NOK: 0,
                },
            }
        default:
            return exhaustiveCheck(planType)
    }
}

export const renderUpToCreditPrice = (priceText: string) => t`Up to ${priceText} credit`

export const renderUpToCashbackLine = (percentage: number) =>
    percentage ? t`Up to ${percentage}% cashback` : ''

export const renderUpToCreditLine = ({
    planConfiguration,
    currency,
    locale,
}: {
    planConfiguration: PlanConfiguration
    currency: BillingInfoCurrency
    locale: SupportedLanguage
}) => {
    const value = planConfiguration.overdraft?.[currency]

    return value
        ? renderUpToCreditPrice(
              formatMinorNumberToCurrency({
                  value,
                  options: {
                      currency,
                      maximumFractionDigits: 1,
                      notation: 'compact',
                  },
                  locale,
              }),
          )
        : ''
}

export const getArrayDifference = (array1: string[], array2: string[]) => {
    const difference = []
    const longestArray = array1.length > array2.length ? array1 : array2
    const lookupSet = new Set(array1.length < array2.length ? array1 : array2)

    for (let i = 0; i < longestArray.length; i++) {
        if (!lookupSet.has(longestArray[i])) {
            difference.push(longestArray[i])
        }
    }

    return difference
}

export const isOverdraftAvailable = (currency: string): boolean => {
    return (['STARTER', 'ESSENTIAL', 'ADVANCED', 'BEYOND'] as CpqRatePlanType[]).some((plan) => {
        const {overdraft} = getPlanContentConfiguration(plan)

        return overdraft && overdraft?.[currency] > 0
    })
}

// Helper functions so we send only one string for translation on each case
const renderPricePerMonth = (priceText: string, postFix: string) =>
    postFix ? t`${priceText}/month ${postFix}` : t`${priceText}/month `
const renderPricePerInvoice = (priceText: string, postFix: string) =>
    postFix ? t`${priceText}/invoice ${postFix}` : t`${priceText}/invoice `
const renderPricePerUser = (priceText: string, postFix: string) =>
    postFix ? t`${priceText}/user ${postFix}` : t`${priceText}/user `

export const paymentFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}

// Helper functions for not repeating rendering logic
export const renderAdditionalPricePerUnit = ({
    pricePerUnit,
    locale,
    unit = 'month',
    postfix = '',
    billingType = 'MONTH',
}: {
    pricePerUnit?: Amount
    locale: SupportedLanguage
    unit?: 'month' | 'invoice' | 'user'
    postfix?: string
    billingType?: CpqBillingPeriodType
}) => {
    const renderPricePerUnit =
        unit === 'month'
            ? renderPricePerMonth
            : unit === 'invoice'
              ? renderPricePerInvoice
              : renderPricePerUser

    if (!pricePerUnit) {
        return ''
    }

    const totalPrice = billingType === 'YEAR' ? pricePerUnit?.value / 12 : pricePerUnit?.value

    return renderPricePerUnit(
        formatMinorNumberToCurrency({
            value: totalPrice,
            locale,
            options: {
                ...paymentFormatOptions,
                currency: pricePerUnit?.currency,
            },
        }),
        postfix,
    )
}
