import * as React from 'react'
import {Route} from 'react-router-dom'

const RequestsPage = React.lazy(async () => import('./screens/requests'))

export const REQUESTS_ROUTE = 'requests'

export const routes = (
    <Route handle={{auth: true, allowedRoles: ['owner']}}>
        <Route index element={<RequestsPage />} />
        <Route path=":requestId" element={<RequestsPage />} />
    </Route>
)
