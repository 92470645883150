import {useCallback} from 'react'
import {useNavigate} from 'react-router'

import tracking from '@product-web/shared--analytics'

import {featureFundsManagementBff} from './bff-hooks'
import {trackWalletTopUp, TrackWalletTopUpAction} from './tracking'
import {useBankTransferModal} from './use-bank-transfer-modal'
import {useWalletModalContext} from './wallet-modal-context'

export const useHandleTopUp = () => {
    const navigate = useNavigate()

    const {openWalletModal} = useWalletModalContext()
    const {openBankTransferModal} = useBankTransferModal()

    const {data: topUpStrategy} = featureFundsManagementBff.topUp.getTopUpButtonStrategy.useQuery()

    const handleTopUp = useCallback(() => {
        trackWalletTopUp({action: TrackWalletTopUpAction.CLICK_TOP_UP_BUTTON})

        if (topUpStrategy === 'top_up_onboarding') {
            // Pleo Guide only allows users with the 'owner' role
            navigate('/onboarding/topup-wallet')
            tracking.walletOnboardingTopupViewed()

            return
        }

        if (topUpStrategy === 'choose_top_up_method') {
            tracking.walletTopupMethodModalViewed()
            openWalletModal('topUpMethod')
        } else if (topUpStrategy === 'open_banking_method') {
            openWalletModal('openBanking')
        } else {
            openBankTransferModal()
        }
    }, [navigate, openBankTransferModal, openWalletModal, topUpStrategy])

    return {
        handleTopUp,
    }
}
