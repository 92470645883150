import {useLoggedInUser} from '@product-web/shared--user'
import {createPersistedState} from '@product-web/shared--web-platform/persisted-state'

// A specific persisted storage related to a one particular user from a one particular company
export function useCompanyUserLocalStorage(storageKeyPrefix: string) {
    const {id: userId, companyId} = useLoggedInUser()
    const STORAGE_KEY = `${storageKeyPrefix}_${companyId}_${userId}`
    const [useLocalStorage] = createPersistedState(STORAGE_KEY, localStorage)
    return useLocalStorage
}
