import {Plural, t, Trans} from '@lingui/macro'
import type {FormikProps} from 'formik'
import {withFormik} from 'formik'
import {path, pathOr} from 'ramda'
import type {ChangeEventHandler, KeyboardEventHandler, ReactNode} from 'react'
import {Component} from 'react'
import type {ReactTimeoutProps} from 'react-timeout'
import ReactTimeout from 'react-timeout'
import styled from 'styled-components'

import {Button, ModalActions, ModalContent, ModalTitle, tokens} from '@pleo-io/telescope'

import * as tracking from '@product-web/shared--analytics'
import type {RequestScope} from '@product-web/shared--api'
import * as apiAuthPin from '@product-web/shared--api-auth/pin'
import {getClientSessionId} from '@product-web/shared--auth--client-session'
import incorrectPinImage from '@product-web/shared--images/pin/incorrect.png'
import type {IModal} from '@product-web/shared--shell/legacy/legacy-modals-bridge'
import {__legacyShowModal} from '@product-web/shared--shell/legacy/legacy-modals-bridge'
import {Keys} from '@product-web/shared--web-platform/keyboard'

import {PIN_CANCELLED_BY_USER_ERROR_STATUS} from './constants'
import {Description, InputContainer, InputPlaceholder, PinInputField} from './pin-modal.styles'

export interface OwnProps {
    publicKey?: string | null
    passPin: boolean
    scope?: RequestScope
    message: ReactNode | string
    description?: ReactNode | string
    elevatedResourceIds?: string[]
}

interface FormValues {
    pin: string
}

type ResolvedValue = {accessToken: string; pin?: string}

type Props = OwnProps & IModal<ResolvedValue, string> & FormikProps<FormValues> & ReactTimeoutProps

interface State {
    error: boolean
    focused: boolean
    throttled: number
}

class ModalPinLogin extends Component<Props, State> {
    state: State = {
        error: false,
        focused: false,
        throttled: 0,
    }

    onFocus = () => this.setState({focused: true})
    onBlur = () => this.setState({focused: false})

    ignoreArrows: KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === Keys.ARROW_UP || e.key === Keys.ARROW_DOWN) {
            e.preventDefault()
        }
    }

    onPinChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const {values, setFieldValue} = this.props
        const prevValue = values.pin.toString()
        const value = e.target.value.toString()

        if (prevValue === value || !value.match(/^\d{0,4}$/)) {
            return
        }

        setFieldValue('pin', value, true)

        if (value.length === 4) {
            e.target.blur()
            this.login(value)
        }
    }

    login = async (pin: string) => {
        const {_modal, setFieldValue, scope, publicKey, setTimeout, passPin, elevatedResourceIds} =
            this.props

        try {
            const companyId = getClientSessionId()
            const response = await apiAuthPin.login(
                pin,
                companyId,
                scope,
                publicKey ? {publicKey} : null,
                elevatedResourceIds,
            )
            const result = passPin
                ? {accessToken: response.accessToken, pin}
                : {accessToken: response.accessToken}
            tracking.passcodeSuccessfullyEntered()
            _modal && _modal.promise.resolve(result)
        } catch (error) {
            const isThrottled = path(['response', 'status'], error) === 429
            const throttled = isThrottled
                ? pathOr(1000 * 60 * 5, ['response', 'data', 'wait'], error)
                : 0

            this.setState({error: true, throttled})

            if (!isThrottled) {
                setTimeout &&
                    setTimeout(() => {
                        setFieldValue('pin', '', false)
                        this.setState({error: false, throttled})
                    }, 1500)
            }
        }
    }

    render() {
        const {values, message, description} = this.props
        const pin = values.pin
        const {error, throttled, focused} = this.state

        if (error) {
            return throttled ? <Throttled throttled={throttled} /> : <IncorrectPasscode />
        }

        return (
            <ForceMinHeight>
                <ModalTitle>{message || <Trans>Enter your passcode</Trans>}</ModalTitle>
                <ModalContent>
                    {description && <Description>{description}</Description>}
                    <InputContainer>
                        <PinInputField
                            autoFocus
                            name="pin"
                            type="password"
                            pattern="[0-9]*"
                            inputMode="numeric"
                            data-testid="pin-input"
                            autoComplete="off"
                            onKeyDown={this.ignoreArrows}
                            onChange={this.onPinChange}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                        />
                        <InputPlaceholder
                            $inputPlaceholderValue={Boolean(pin.slice(0, 1))}
                            $inputPlaceholderNext={pin.length === 0}
                            $focused={focused}
                        />
                        <InputPlaceholder
                            $inputPlaceholderValue={Boolean(pin.slice(1, 2))}
                            $inputPlaceholderNext={pin.length === 1}
                            $focused={focused}
                        />
                        <InputPlaceholder
                            $inputPlaceholderValue={Boolean(pin.slice(2, 3))}
                            $inputPlaceholderNext={pin.length === 2}
                            $focused={focused}
                        />
                        <InputPlaceholder
                            $inputPlaceholderValue={Boolean(pin.slice(3, 4))}
                            $inputPlaceholderNext={pin.length === 3}
                            $focused={focused}
                        />
                    </InputContainer>
                </ModalContent>
                <ModalActions>
                    <Button
                        variant="secondary"
                        onClick={() =>
                            this.props._modal?.promise.reject(PIN_CANCELLED_BY_USER_ERROR_STATUS)
                        }
                    >
                        <Trans>Cancel</Trans>
                    </Button>
                </ModalActions>
            </ForceMinHeight>
        )
    }
}

export const IncorrectPasscode = () => (
    <Error>
        <Trans>Passcode is incorrect</Trans>
    </Error>
)

export const Throttled = ({throttled}: {throttled: number}) => {
    const minutesThrottled = Math.ceil(throttled / 1000 / 60)
    return (
        <Error>
            <Trans>
                Too many wrong attempts.
                <br />
                <Plural
                    value={minutesThrottled}
                    _0=""
                    one="You can try again in # minute"
                    other="You can try again in # minutes"
                />
            </Trans>
        </Error>
    )
}

const Error = ({children}: {children: ReactNode}) => (
    <ForceMinHeight>
        <ModalTitle>{children}</ModalTitle>
        <ModalContent>
            <img
                css={{height: 120, padding: tokens.spacing20}}
                src={incorrectPinImage}
                alt={t`Incorrect Pin`}
            />
        </ModalContent>
    </ForceMinHeight>
)

export const ModalPinLoginForm = withFormik<OwnProps, FormValues>({
    handleSubmit: () => null,
    mapPropsToValues: () => ({
        pin: '',
    }),
})(ReactTimeout(ModalPinLogin))

export default {
    show: async (message: ReactNode | string, passPin: boolean, scope: RequestScope) =>
        __legacyShowModal<ResolvedValue, string>(
            <ModalPinLoginForm scope={scope} passPin={passPin} message={message} />,
            {hideClose: true},
        ),
    showScoped: async (
        scope: RequestScope,
        publicKey: string | null,
        passPin = false,
        message = '',
        description: string | undefined = undefined,
        elevatedResourceIds?: string[],
    ) =>
        __legacyShowModal<ResolvedValue, string>(
            <ModalPinLoginForm
                scope={scope}
                passPin={passPin}
                publicKey={publicKey}
                message={message ?? ''}
                description={description}
                elevatedResourceIds={elevatedResourceIds}
            />,
            {hideClose: true},
        ),
}

const ForceMinHeight = styled.div`
    min-height: 250px;
`
