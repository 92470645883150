import axios from 'axios'

import {hasKey} from '@product-web/shared--utils'

export enum PleoErrorMessages {
    TOKEN_EXPIRED = 'token_expired',
    UNAUTHORIZED = 'unauthorized',
}

/**
 * Checks if the passed value is an instance of an Axios Request Error
 * Implemented as a custom type guard
 * @param error Value to be checked
 * @returns a boolean indicating if the passed value is an Axios Error
 */
export const isRequestError = axios.isAxiosError

/**
 * Allows type-safe access to `error.response.status` property on Axios errors
 * This is numeric HTTP status code set by Axios
 * @param error Value to extract the status from
 * @returns The numeric status code or null if the passed value is not an Axios error
 */
export function getRequestErrorStatus(error: unknown) {
    if (!isRequestError(error)) {
        return null
    }
    return error?.response?.status ?? null
}

/**
 * Allows type-safe access to `error.response.data.type` property on request errors
 * This is a custom property set by some of Pleo API responses
 * @param error Value to extract the type from
 * @returns The string representing the error type or null if the passed value is not a
 * request error or the response payload does not have the `type` property
 */
export function getRequestErrorPleoType(error: unknown) {
    if (!isRequestError(error)) {
        return null
    }
    const responseData = error?.response?.data
    if (hasKey('type', responseData) && typeof responseData.type === 'string') {
        return responseData.type.toString()
    }
    return null
}

/**
 * Allows type-safe access to `error.response.data.meta.type` property on request errors
 * This is a custom property set by some of Pleo API responses
 * @param error Value to extract the type from
 * @returns The string representing the error type or null if the passed value is not a
 * request error or the response payload does not have the `meta.type` property
 */
export function getRequestErrorPleoMetaType(error: unknown) {
    if (!isRequestError(error)) {
        return null
    }
    const responseData = error?.response?.data
    if (
        hasKey('meta', responseData) &&
        hasKey('type', responseData.meta) &&
        typeof responseData.meta.type === 'string'
    ) {
        return responseData.meta.type
    }
    return null
}

/**
 * Allows type-safe access to `error.response.data.status` property on request errors
 * This is a custom property set by some of Pleo API responses
 * @param error Value to extract the status from
 * @returns The numeric status code or null if the passed value is not a
 * request error or the response payload does not have the `status` property
 */
export function getRequestErrorPleoStatus(error: unknown) {
    if (!isRequestError(error)) {
        return null
    }
    const responseData = error?.response?.data
    if (hasKey('status', responseData) && typeof responseData.status === 'number') {
        return responseData.status
    }
    return null
}

/**
 * Allows type-safe access to `error.response.data.message` property on request errors
 * This is a custom property set by some of Pleo API responses
 * @param error Value to extract the message from
 * @returns The error message string or null if the passed value is not a
 * request error or the response payload does not have the `message` property
 */
export function getRequestErrorPleoMessage(error: unknown) {
    if (!isRequestError(error)) {
        return null
    }

    const responseData = error?.response?.data
    if (hasKey('message', responseData) && typeof responseData.message === 'string') {
        return responseData.message
    }
    return null
}
