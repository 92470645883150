import type {ComponentProps} from 'react'
import type {To} from 'react-router'
import type {Link as RouteLink} from 'react-router-dom'
import styled from 'styled-components'

import type {Link, StackProps} from '@pleo-io/telescope'
import {NakedButton, Stack, tokens} from '@pleo-io/telescope'

const Root = styled(NakedButton)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    text-align: left;
    border: ${tokens.borderPrimary};
    border-radius: ${tokens.arc8};
    transition: ${tokens.fastInOut};
    align-items: center;
    background-color: ${tokens.shade000};
    color: ${tokens.shade800};

    &:hover {
        box-shadow: ${tokens.shadowElevateQuiet};
        transform: scale(1.02);
        color: ${tokens.shade800};
    }
`

const Content = styled(Stack).attrs<StackProps>(({padding}) => ({padding: padding || 24}))``

const LeftIcon = styled.div`
    margin-left: ${tokens.spacing24};
`

const RightIcon = styled.div`
    margin-right: ${tokens.spacing24};
`

export const ActionItem = (
    props: ComponentProps<typeof NakedButton> &
        (
            | {
                  as: typeof Link
                  href: string
                  target: string
                  rel: string
              }
            | {
                  as: typeof RouteLink
                  to: To
              }
        ),
) => <Root {...props} />

const Divider = styled.div`
    width: 100%;
    height: ${tokens.sizeBorderDefault};
    background-color: ${tokens.colorBorderStatic};
`

ActionItem.Content = Content
ActionItem.LeftIcon = LeftIcon
ActionItem.RightIcon = RightIcon
ActionItem.Divider = Divider
