import {i18n} from '@lingui/core'
import type {BigSource} from 'big.js'
import Big from 'big.js'
import {data} from 'currency-codes'
import {code as currencyCodes} from 'currency-codes'

import type {CurrencyType} from '@pleo-io/deimos'
import {CURRENCIES} from '@pleo-io/deimos'

import type {Brand} from '@product-web/shared--utils'
import {invariant} from '@product-web/shared--utils'

type FormatCurrencyOptions = {
    showPlus?: boolean
    format?: Intl.NumberFormatOptions
}

/**
 * Function for rendering currencies using the i18n library, provides a fallback if no number is provided for loading states.
 * @param amount number, to be formatted
 * @param currency string, The 3-letter ISO 4217 code of the currency that the amount will be formatted in (e.g. 'DKK')
 * @param options object, additional options to allow for further formatting of currency
 * @returns Formatted string, formatted currency using format provided, or - if no amount provided.
 * @example.
 * const currencyFormat = getCurrencyFormat()
 * const formattedCurrency = formatCurrency(10000, currencyFormat)
 */

export const formatCurrency = (
    amount: number | null | undefined,
    currency?: CurrencyType | string | null,
    options?: FormatCurrencyOptions,
): string => {
    if (!amount && amount !== 0) {
        return '-'
    }

    const shouldShowPlus = options?.showPlus && amount > 0
    const currencyFormat = getCurrencyFormat(currency, {
        ...options?.format,
        ...(shouldShowPlus && {
            signDisplay: 'always',
        }),
    })

    // eslint-disable-next-line string-to-lingui/forbid-i18n-calls
    const formattedValue = i18n.number(amount, currencyFormat)

    if (currencyFormat.currencyDisplay === 'code' && currency) {
        return appendCurrencyCode(formattedValue, currency)
    }

    return formattedValue
}

export const appendCurrencyCode = (formattedValue: string, currencyCode: CurrencyType | string) => {
    if (!formattedValue.includes(currencyCode)) {
        return ''
    }
    const value = formattedValue
        .split(currencyCode)
        .join('')
        .trim()
        .replace(/([+-])\s+/g, '$1')
    return `${value} ${currencyCode}`
}

export const formatCurrencyParts = (
    amount: number | null | undefined,
    currency?: CurrencyType | string | null,
    options?: FormatCurrencyOptions,
) => {
    const formattedCurrency = formatCurrency(amount, currency, options)
    const [value, code] = formattedCurrency.split(' ')

    return {
        value: value ?? '',
        currency: code ?? '',
    }
}

export const CurrencyFormat = {
    FixedFractionDigits(digit = 2) {
        return {
            minimumFractionDigits: digit,
            maximumFractionDigits: digit,
        }
    },
}

export const isCurrency = (currency: string): currency is CurrencyType =>
    CURRENCIES.includes(currency as CurrencyType)

export const getCurrencyFormat = (
    currency?: CurrencyType | string | null | undefined,
    options?: Intl.NumberFormatOptions,
): Intl.NumberFormatOptions => ({
    style: 'decimal',
    ...(currency && {
        style: 'currency',
        currency,
        currencyDisplay: 'code',
    }),
    ...options,
})

export type MajorCurrency = Brand<number, 'MajorCurrency'>

export type MinorCurrency = Brand<number, 'MinorCurrency'>

type Currency = MajorCurrency | MinorCurrency

const isValidCurrency = (value: number): value is Currency => {
    return Number.isInteger(value)
}

/**
 * Function to format minor currencies (e.g. not decimalised integer values) to localised currency format
 * @param options.value The monetary value as a minor currency (must be an integer)
 * @param options.currency The ISO currency code
 * @returns Localised formatted currency and code (e.g. 4.99 EUR)
 * @example
 * const formattedCurrency = formatMinorCurrency({value: 10000, currency: 'EUR'}) // 100.00 EUR
 */
export const formatMinorCurrency = ({
    value,
    currency,
    displaySign,
}: {
    value: MinorCurrency | null | undefined
    currency: string
    displaySign?: boolean
}) => {
    if (!value && value !== 0) {
        return '-'
    }

    const {formattedValue, literal, currencyCode} = formatMinorCurrencyParts({
        value,
        currency,
        displaySign,
    })

    // We are moving the currency code to the end of the string for now to
    // be consistent with previously formatted currencies. This could be
    // passed in as an option in the future.
    const formattedCurrency = `${formattedValue}${literal}${currencyCode}`

    return formattedCurrency
}

export const formatMinorCurrencyParts = ({
    value,
    currency,
    displaySign,
}: {
    value: MinorCurrency
    currency: string
    displaySign?: boolean
}) => {
    invariant(isValidCurrency(value), 'Value must be currency in minors')

    const formatter = Intl.NumberFormat(i18n.locale, {
        style: 'currency',
        currency,
        currencyDisplay: 'code',
        ...(displaySign && {
            signDisplay: 'exceptZero',
        }),
    })

    const options = formatter.resolvedOptions()

    const formattedToParts = formatter.formatToParts(value / 10 ** options.maximumFractionDigits)

    let literal = ''
    let currencyCode = ''

    const formattedValue = formattedToParts.reduce((accumulator, nextValue) => {
        if (nextValue.type === 'literal') {
            literal = nextValue.value
            return accumulator
        }

        if (nextValue.type === 'currency') {
            currencyCode = nextValue.value
            return accumulator
        }

        return `${accumulator}${nextValue.value}`
    }, '')

    return {
        formattedValue,
        literal,
        currencyCode,
    }
}

const DEFAULT_MINORS = 2

/**
 * Function to convert an amount in minor units (e.g., cents) to major units (e.g., dollars) based on the currency's minor unit configuration, preserving decimals.
 * @param minors The monetary value in minor units (must be a BigSource type, such as a string, number, or Big instance)
 * @param currency The ISO currency code (must be a string)
 * @param round Round to nearest integer (optional)
 * @returns The converted amount in major units as a number with decimals preserved, or null if the input is invalid or the currency is not recognized
 * @example
 * const amountInMajors = convertToMajorCurrency({value: 164218, currency: 'EUR'}) // 1642.18 EUR
 */
export function convertToMajorCurrency(
    minors: BigSource | null,
    currency: string | null,
    round?: boolean,
): number | null {
    if (minors === null || currency === null) {
        return null
    }

    const minorDigits = currencyCodes(currency)?.digits ?? null

    if (minorDigits === null) {
        return null
    }

    const bigAmount = Big(minors)

    // Moving the decimal point using a nifty Big.js feature
    bigAmount.e -= minorDigits ?? DEFAULT_MINORS

    const majors = parseFloat(bigAmount.toString())
    return round ? Math.round(majors) : majors
}

export function convertToMinorCurrency({value, currency}: {value: number; currency: string}) {
    const formatter = Intl.NumberFormat(i18n.locale, {
        style: 'currency',
        currency,
        maximumFractionDigits: 2,
    })

    const options = formatter.resolvedOptions()

    const minorValue = Math.round(value * 10 ** options.maximumFractionDigits)
    return minorValue
}

// We're filtering out precious metals currencies as they cause the expense creation to fail
const excludedCodes = ['XAG', 'XAU', 'XPD', 'XPT']

export const currencies = data
    .filter((it) => !excludedCodes.includes(it.code))
    .map((it) => ({
        code: it.code,
        number: it.number,
        digits: it.digits,
        name: it.currency,
    }))
