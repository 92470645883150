import {useCallback, useEffect} from 'react'

import {dayjs} from '@product-web/shared--dates/dayjs'

import {useCompanyUserLocalStorage} from '../../use-company-user-local-storage'

export enum PromptName {
    DECLINED_TRANSACTIONS = 'auto-top-up-prompt-declined-transactions',
    LOW_BALANCE = 'auto-top-up-prompt-low-balance',
}

const DATE_FORMAT = 'YYYY-MM-DD'
const ADOPTION_PROMPTS_DISMISSING_INTERVAL_DAYS = 7

type PromptsPersistedState = {
    dismissedDate: string | null
}

export const usePromptsPersistedState = () => {
    const useCompanyUserStorage = useCompanyUserLocalStorage('autoTopUpPrompts')
    const [dismissedPrompts, setDismissedPrompts] = useCompanyUserStorage<PromptsPersistedState>(
        'adoptionPrompts',
        {
            dismissedDate: null,
        },
    )

    const dismissPrompt = useCallback(() => {
        setDismissedPrompts({
            dismissedDate: dayjs().format(DATE_FORMAT),
        })
    }, [setDismissedPrompts])

    const resetPrompt = useCallback(() => {
        setDismissedPrompts({
            dismissedDate: null,
        })
    }, [setDismissedPrompts])

    useEffect(() => {
        const {dismissedDate} = dismissedPrompts ?? {}
        if (!dismissedDate) {
            return
        }

        if (
            dayjs().diff(dayjs(dismissedDate, DATE_FORMAT), 'days') >
            ADOPTION_PROMPTS_DISMISSING_INTERVAL_DAYS
        ) {
            resetPrompt()
        }
    }, [dismissedPrompts, resetPrompt])

    return {
        dismissedPrompts,
        dismissPrompt,
        resetPrompt,
    }
}
