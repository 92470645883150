import {t, Trans} from '@lingui/macro'
import type React from 'react'
import {useNavigate} from 'react-router-dom'

import {Button, Text} from '@pleo-io/telescope'
import {ArrowRight, ShoppingBag} from '@pleo-io/telescope-icons'

import * as tracking from '@product-web/shared--analytics'
import {reportError} from '@product-web/shared--error/report'
import {isNonNullable} from '@product-web/shared--utils'

import {MilestoneAccordion} from './milestone-accordion'

import {bff} from '../bff-hooks'
import type {MilestoneName, MilestoneStatus, Task} from '../universal/types'

type SchedulePaymentsMilestoneAccordionItemProps = {
    status: MilestoneStatus
    tasks: Task[]
    milestoneName: MilestoneName
}

export const SchedulePaymentsMilestoneAccordionItem = ({
    status,
    tasks,
    milestoneName,
}: SchedulePaymentsMilestoneAccordionItemProps) => {
    const navigate = useNavigate()
    const {mutate: startTask} =
        bff.companyOnboarding.getStarted.startCompanyOnboardingTask.useMutation()

    const schedulePaymentsTasks: React.ComponentProps<typeof MilestoneAccordion.Tasks>['tasks'] =
        tasks
            .map((task) => {
                switch (task.name) {
                    case 'SET_UP_INVOICES':
                        return {
                            heading: t`Add invoices`,
                            children: (
                                <Text>
                                    <Trans>
                                        Get a <strong>free</strong> Pleo email address for receiving
                                        invoices.
                                    </Trans>
                                </Text>
                            ),
                            status: task.status,
                            cta: (
                                <Button
                                    variant="primary"
                                    IconRight={ArrowRight}
                                    onClick={() => {
                                        startTask(task.name)
                                        tracking.companyOnboardingMilestoneActioned({
                                            action: 'started',
                                            milestone: milestoneName,
                                            task: task.name,
                                        })
                                        navigate('/invoices/inbox')
                                    }}
                                >
                                    <Trans>Continue</Trans>
                                </Button>
                            ),
                        }

                    default: {
                        reportError('Invalid milestone task name', null, {
                            milestoneName,
                            taskName: task.name,
                        })

                        return null
                    }
                }
            })
            .filter(isNonNullable)

    return (
        <MilestoneAccordion.Item value={milestoneName} status={status}>
            <MilestoneAccordion.Header
                headingLevel="h2"
                heading={t`Pay suppliers`}
                Icon={ShoppingBag}
                progress={getMilestoneProgress(tasks)}
            />

            <MilestoneAccordion.Content>
                <MilestoneAccordion.Tasks tasks={schedulePaymentsTasks} />
            </MilestoneAccordion.Content>
        </MilestoneAccordion.Item>
    )
}

const getMilestoneProgress = <T extends Pick<Task, 'status'>>(tasks: T[]) => ({
    total: tasks.length,
    completed: tasks.filter((task) => task.status === 'COMPLETE').length,
})
