import {reportError} from '@product-web/shared--error/report'
import cookieStorage from '@product-web/shared--web-platform/cookie'

import type {ConsentEntry, ConsentParams, GtagConsentValue} from './analytics.types'

export interface BaseOptions {
    cookiePrefix: 'managed' | 'unmanaged'
}

export const getPrefixedCookieName = (entryName: string, {cookiePrefix}: BaseOptions): string => {
    return cookiePrefix === 'managed' ? `managed-${entryName}` : entryName
}

/**
 * Validates if a value is a valid consent value.
 *
 * @param value - The value to validate
 */
export const isValidConsentValue = (value: unknown): value is GtagConsentValue => {
    return ['granted', 'denied'].includes(value as string)
}

/**
 * Updates Google Tag consent preferences and sets corresponding cookies.
 *
 * @deprecated
 * This function is deprecated and will be removed in the near future.
 * Use Hubspot consent management instead.
 */
export const updateGtagConsent = (consentParams: Partial<ConsentParams>, options: BaseOptions) => {
    try {
        window.gtag?.('consent', 'update', consentParams)

        for (const [entryName, consentValue] of Object.entries(consentParams)) {
            const cookieName = getPrefixedCookieName(entryName, options)
            cookieStorage.set(cookieName, consentValue)
        }
    } catch (error) {
        reportError(error)
    }
}

/**
 * Retrieves a consent value from a cookie.
 */
export const getGtagConsentValueFromCookie = (
    entryName: ConsentEntry,
    options: BaseOptions,
): GtagConsentValue | null => {
    try {
        const cookieName = getPrefixedCookieName(entryName, options)
        const cookieValue = cookieStorage.get(cookieName)
        return isValidConsentValue(cookieValue) ? cookieValue : null
    } catch (error) {
        reportError(error)
        return null
    }
}

/**
 * Sets a consent value to a cookie.
 */
export const setGtagConsentValueToCookie = (
    entryName: ConsentEntry,
    value: GtagConsentValue,
    options: BaseOptions,
) => {
    try {
        const cookieName = getPrefixedCookieName(entryName, options)
        cookieStorage.set(cookieName, value)
    } catch (error) {
        reportError(error)
    }
}

/**
 * Retrieves all Gtag consent preferences from cookies.
 *
 * @deprecated
 * This function is deprecated. Use useManageConsent instead.
 */
export const getGtagConsentFromCookies = (options: BaseOptions): ConsentParams => {
    const getCookieValue = (entryName: ConsentEntry) =>
        getGtagConsentValueFromCookie(entryName, options) ?? 'denied'

    return {
        ad_storage: getCookieValue('ad_storage'),
        ad_user_data: getCookieValue('ad_user_data'),
        ad_personalization: getCookieValue('ad_personalization'),
        analytics_storage: getCookieValue('analytics_storage'),
        functionality_storage: getCookieValue('functionality_storage'),
        personalization_storage: getCookieValue('personalization_storage'),
        security_storage: getCookieValue('security_storage'),
    }
}

// Wait for analytics to be ready triggering the callback
export const onAnalyticsReady = (callback: () => void) => {
    window.analytics?.ready(callback)
}

// Identify user anonymously for analytics tracking
// https://segment.com/docs/connections/spec/identify/#anonymous-id
export function identifyAnonymous(traits?: Record<string, any>, options?: any) {
    window.analytics?.identify(traits, options)
}

// Identify user for analytics tracking
export function identify(id: string, traits?: Record<string, any>, options?: any) {
    window.analytics?.identify(id, traits, options)
}

// Group user for analytics tracking
export function group(groupId: string, properties?: Record<string, any>) {
    window.analytics?.group(groupId, properties)
}

// Track page view for analytics tracking
export function page(
    path: string,
    properties?: Record<string, any>,
    options?: Record<string, any>,
) {
    window.analytics?.page(path, properties, options)
}
