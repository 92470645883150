import * as React from 'react'
import {Route} from 'react-router-dom'

import {roleSets} from '@product-web/shared--user'

import CashManagementScreenWrapper from './screens/cash-managment-screen-wrapper'

const CashManagementScreen = React.lazy(
    async () => import('./screens/cash-management-screen/cash-management-screen'),
)

const AlertTab = React.lazy(async () => import('./components/tabs/alert/alert-tab'))
const WalletTab = React.lazy(async () => import('./components/tabs/wallet/wallet-tab'))
const OverdraftTab = React.lazy(async () => import('./components/tabs/overdraft/overdraft-tab'))
const OverdraftStatements = React.lazy(
    async () =>
        import(
            '@product-web/feature--cash-management/components/overdraft/overdraft-statements/overdraft-statements'
        ),
)

// todo: the rest of the Cash Management route will be nested in the CashManagementScreen
//  https://linear.app/pleo/issue/WALLE-5900
export const routes = (
    <Route
        handle={{
            auth: true,
            minCompanyStatus: 'fdd',
            allowedRoles: roleSets.ownerAndBookkeeper,
        }}
        element={<CashManagementScreenWrapper />}
    >
        <Route element={<CashManagementScreen />}>
            <Route index element={<WalletTab />} />
            <Route path="alert" element={<AlertTab />} />
            <Route path="overdraft" element={<OverdraftTab />} />
        </Route>
        <Route path="overdraft/statements" element={<OverdraftStatements />} />
    </Route>
)
