import styled from 'styled-components'

import {NakedButton, theme, tokens} from '@pleo-io/telescope'
import {Play} from '@pleo-io/telescope-icons'

import * as s from '@product-web/feature--ui-guides/guide.styles'
import tracking from '@product-web/shared--analytics'
import {stringToSupportedLanguage, useSupportedLanguageList} from '@product-web/shared--i18n'
import {WistiaVideo} from '@product-web/shared--telescope-lab/wistia-video'

import {bff} from '../../../bff'
import {getVideoIdByLanguage} from '../../lib/helpers'

export const Video = styled(WistiaVideo)`
    ${s.LinkButton} {
        font-weight: ${tokens.fontWeightSemibold};
    }
`

export interface VideoProps {
    onClick: () => void
    videoLink: string
}

const WhiteIconPlay = styled(Play).attrs({size: 28})`
    &&& {
        margin-right: 0;
        color: ${tokens.shade000};
    }
`

const RoundPlayButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: ${tokens.circle};
    background-color: ${tokens.shade900};
    color: ${tokens.shade000};
`

const RoundPlayButton = () => (
    <RoundPlayButtonWrapper>
        <WhiteIconPlay />
    </RoundPlayButtonWrapper>
)

const PlayVideoWithIconWrapper = styled(NakedButton).attrs({
    color: theme.colors.shade000,
})`
    margin-left: auto;
    position: absolute;
    margin-top: ${tokens.spacing32};
    display: flex;
    width: 120px;
    align-items: center;
    justify-content: center;

    svg {
        height: 60px;
        width: 100%;
    }

    ${Video} {
        width: 100%;
        justify-content: center;
        display: flex;

        .wistia_click_to_play {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
`
export const PlayVideoWithIcon = () => {
    const {data} = bff.featureMultiEntity.requestEntitiesIntro.introData.useQuery()
    const supportedLanguageList = useSupportedLanguageList()
    const userLanguage = data?.userLanguage
        ? stringToSupportedLanguage(data?.userLanguage, supportedLanguageList)
        : null

    const videoLink = getVideoIdByLanguage(userLanguage)

    const handleClick = () => {
        if (!data?.companyId || !data?.userId) {
            return
        }
        tracking.selfExpansionInfoVideoViewed({
            companyId: data.companyId,
            userId: data.userId,
            hasOrgAccess: false,
        })
    }

    return (
        <PlayVideoWithIconWrapper onClick={handleClick}>
            <Video video={videoLink}>
                <RoundPlayButton />
            </Video>
        </PlayVideoWithIconWrapper>
    )
}
