import {Trans} from '@lingui/macro'

import {Callout} from '@pleo-io/telescope'

export const CashbackCallout = () => {
    return (
        <Callout variant="warning">
            <Callout.Text>
                <Trans>Cashback is only available with annual billing</Trans>
            </Callout.Text>
        </Callout>
    )
}
