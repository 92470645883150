import {Trans} from '@lingui/macro'
import {ErrorBoundary} from '@sentry/react'
import {Outlet} from 'react-router-dom'

import {Box, Button, Stack, Text} from '@pleo-io/telescope'

import yarncatIllustration from '@product-web/shared--images/cats/yarncat.svg'

export const AnalyticsErrorBoundary = () => (
    <ErrorBoundary
        beforeCapture={(scope) => scope.setLevel('fatal')}
        fallback={() => <ErrorView />}
    >
        <Outlet />
    </ErrorBoundary>
)

export const ErrorView = () => {
    return (
        <Stack
            space={4}
            justifyContent="center"
            justifyItems="center"
            css={{margin: 'auto', position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}
        >
            <img src={yarncatIllustration} alt="" width={128} />
            <Text as="p" variant="xlarge-accent" color="colorContentStatic" weight="medium">
                <Trans>Something went wrong</Trans>
            </Text>
            <Text as="p" color="colorContentStatic" align="center">
                <Trans>Please reload the page to try again.</Trans>
            </Text>
            <Box mt={16}>
                <Button
                    variant="secondary"
                    onClick={() => {
                        window.location.href = window.location.pathname
                    }}
                >
                    <Trans>Reload</Trans>
                </Button>
            </Box>
        </Stack>
    )
}
