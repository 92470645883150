/**
 * @overview Popover (lower level component)
 * @stage Proposed
 * @author Maciek (@pekala)
 * @designer Annika
 * @spec https://www.figma.com/file/w3PQGgCdm7wr9pOT3EdQa2/Bills-web-components?node-id=1%3A5057
 */

import type {Position} from '@reach/popover'
import ReachPopover, {getCollisions, positionDefault} from '@reach/popover'
import React from 'react'
import styled, {css, keyframes} from 'styled-components'
import type {SpaceProps} from 'styled-system'
import {space} from 'styled-system'

import {focusRing, tokens} from '@pleo-io/telescope'

import {useOnClickOutside} from '@product-web/shared--web-platform/use-on-click-outside'

import {OverlayTriangle} from '../common/overlay-triangle'
import {usePopupAnimation} from '../common/popup-animation'

interface PopoverProps extends SpaceProps {
    /**
     * Function that returns the content of the popover
     */
    renderContent: () => React.ReactNode

    /**
     * Class, passed to popover body element
     */
    className?: string

    /**
     * Show triangle above popup (default: true)
     */
    showTriangle?: boolean

    /**
     * Position to render popover content (default: positionDefault)
     */
    position?: Position

    /**
     * Current open state of the popover (passed from the usePopover hook popoverProps)
     */
    isOpen: boolean

    /**
     * React ref to trigger element (passed from the usePopover hook popoverProps)
     */
    triggerRef: React.RefObject<HTMLElement>

    /**
     * React ref to popover element (passed from the usePopover hook popoverProps)
     */
    popoverRef: React.RefObject<HTMLDivElement>
}

/**
 * Low lever component to display a popover element, based on @reach/popover
 * Takes care of animation, popover body styling and triangle
 * Used to build other specialized popover elements (like PopoverControl), but can also be used directly.
 *
 * @param props Component props
 * @param props.renderContent Function that returns the content of the popover
 * @param props.className Class, passed to popover body element
 * @param props.showTriangle Show triangle above popup (default: true)
 * @param props.position Position to render popover content (default: positionDefault)
 * @param props.isOpen Current open state of the popover (passed from the usePopover hook popoverProps)
 * @param props.triggerRef React ref to trigger element (passed from the usePopover hook popoverProps)
 * @param props.popoverRef React ref to popover element (passed from the usePopover hook popoverProps)
 */

/**
 * @deprecated Use '@pleo-io/telescope' Popover
 */
export const Popover: React.FC<React.PropsWithChildren<PopoverProps>> = ({
    renderContent,
    children,
    isOpen,
    triggerRef,
    popoverRef,
    className,
    showTriangle = true,
    position = positionDefault,
    ...sizeProps
}) => {
    // Get the arrow element, with the correct animation, direction and color
    const {isAbove, shouldAnimate, triggerRect} = usePopupAnimation({
        isOpen,
        triggerRef,
        popoverRef,
    })

    let trigger = children
    if (React.isValidElement(trigger)) {
        trigger = React.cloneElement(trigger, {
            // @ts-ignore React typings are wrong here and do not recognize ref as a valid prop
            ref: triggerRef,
            'data-generic-ui': 'overlays-popover',
        })
    }

    return (
        <>
            {trigger}
            {isOpen && (
                <>
                    <StyledReachPopover targetRef={triggerRef} position={position} ref={popoverRef}>
                        <PopoverBody
                            $isAbove={isAbove}
                            $shouldAnimate={shouldAnimate}
                            className={className}
                            {...sizeProps}
                        >
                            {renderContent()}
                        </PopoverBody>
                    </StyledReachPopover>
                    {showTriangle && (
                        <StyledTriangle
                            isAbove={isAbove}
                            shouldAnimate={shouldAnimate}
                            targetRect={triggerRect ?? null}
                            bgColor={tokens.colorBackgroundStatic}
                            borderColor={tokens.colorBorderStatic}
                        />
                    )}
                </>
            )}
        </>
    )
}

interface PopoverHookResult {
    /**
     * Current open state of the popover
     */
    isOpen: boolean

    /**
     * Function that is closes the popover
     */
    close: () => void

    /**
     * Function that is opens the popover
     */
    open: () => void

    /**
     * Set of props to spread on the Popover element
     */
    popoverProps: {
        isOpen: boolean
        triggerRef: React.RefObject<HTMLElement>
        popoverRef: React.RefObject<HTMLDivElement>
    }

    /**
     * Set of props to spread on the trigger element
     */
    triggerProps: {
        onClick: () => void
    }
}

interface PopoverHookArgs {
    /**
     * Callback called when the popover is closed by clicking outside of it
     */
    onClickOutside?: () => void
    /**
     * A condition to close the popover by clicking outside of it.
     */
    shouldCloseOnClickside?: boolean
    /**
     * Initial state of the popover
     */
    initialState?: boolean
}

/**
 * State hook for use with Popover component. Use one hook call per popover in component.
 * @param args Hooks arguments
 * @param args.onClickOutside Callback called when the popover is closed by clicking outside of it
 * @returns {PopoverHookResult} result object including sets of props to spread on popover and trigger elements
 * @example
 * const popover = usePopover()
 * <Popover {...popover.popoverProps} renderContent={() => <div>Hello World!</div>}>
 *   <button {...popover.triggerProps}>Click to open</button>
 * </Popover>
 */
export const usePopover = ({
    onClickOutside = () => {},
    shouldCloseOnClickside = true,
    initialState = false,
}: PopoverHookArgs = {}): PopoverHookResult => {
    const triggerRef = React.useRef<HTMLElement>(null)
    const popoverRef = React.useRef<HTMLDivElement>(null)

    const [isOpen, setIsOpen] = React.useState(initialState)

    const close = () => setIsOpen(false)
    const open = () => setIsOpen(true)

    // Close the widget when clicking outside of the popover or trigger button
    // (trigger button has separate logic for toggling the widget)
    useOnClickOutside([triggerRef, popoverRef], () => {
        if (shouldCloseOnClickside) {
            close()
        }
        onClickOutside()
    })

    const popoverProps = {isOpen, triggerRef, popoverRef}
    const triggerProps = {ref: triggerRef, onClick: () => setIsOpen((current) => !current)}

    return {isOpen, popoverProps, triggerProps, close, open}
}

////////////////////////////////////////////////////////////////////////////////

// we use a different animation depending on whether the popover appears over
// the trigger (drop down) or above it (raise)
const fadeInFromBottom = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`
const fadeInFromTop = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

type PopoverBodyProps = {$isAbove: boolean; $shouldAnimate: boolean} & SpaceProps

const animationStyles = (props: PopoverBodyProps) => css`
    animation: ${fadeInFromBottom} ${tokens.smoothInOut};
    animation-name: ${props.$isAbove ? fadeInFromTop : fadeInFromBottom};
    animation-duration: ${props.$shouldAnimate ? tokens.smooth : '0ms'};
`

const StyledReachPopover = styled(ReachPopover)`
    padding: ${tokens.spacing8} 0;
`

export const PopoverBody = styled.div<PopoverBodyProps>`
    box-shadow: ${tokens.shadowElevateQuiet};
    background-color: ${tokens.colorBackgroundStatic};
    border: ${tokens.borderStatic};
    border-radius: ${tokens.arc8};
    ${focusRing('regular')};
    ${animationStyles};
    ${space}

    & * {
        box-sizing: border-box;
    }
`

const StyledTriangle = styled(OverlayTriangle)`
    ${(props) => animationStyles({$isAbove: props.isAbove, $shouldAnimate: props.shouldAnimate})}
`

export const popoverPositionMiddleLeft: Position = (targetRect, popoverRect) => {
    const positionMiddleLeft = positionDefault(targetRect, popoverRect)

    if (targetRect && popoverRect) {
        const collisions = getCollisions(targetRect, popoverRect)

        const isColliding = Object.values(collisions).some((v) => v)

        if (isColliding) {
            return positionMiddleLeft
        }

        return {
            top: `${targetRect.top + window.pageYOffset}px`,
            left: `${targetRect.left - popoverRect.width + window.pageXOffset}px`,
        }
    }

    return positionMiddleLeft
}
