import type {ComponentProps, MouseEventHandler} from 'react'
import type {Link as RouterLink} from 'react-router-dom'
import type {KnownTarget} from 'styled-components/dist/types'

import {Link} from '@pleo-io/telescope'

import {paywallActioned} from '@product-web/shared--analytics'

import {getPaywallTrackingView} from './paywall'
import {usePaywallContext} from './paywall-context'

const PaywallLinkWithTracking = ({
    onClick,
    to,
    trackingLabel,
    isDismissible,
    ...linkProps
}: ComponentProps<typeof Link> &
    ComponentProps<typeof RouterLink> & {
        as?: KnownTarget
        isDismissible: boolean
        trackingLabel: string
    }) => {
    const {view} = usePaywallContext()
    const onClickProxy: MouseEventHandler<HTMLAnchorElement> = (event) => {
        const trackingView = getPaywallTrackingView(view, !isDismissible)
        if (trackingView) {
            paywallActioned({
                action: 'link_clicked',
                is_dismissible: isDismissible,
                view: trackingView,
                link_text: trackingLabel,
                target_url: to.toString(),
            })
        }
        if (onClick) {
            onClick(event)
        }
    }
    return <Link to={to} onClick={onClickProxy} {...linkProps} />
}

export default PaywallLinkWithTracking
