import useSWRImmutable from 'swr/immutable'

import type {Amount, BankAccountInfo, CurrencyType} from '@pleo-io/deimos'

import {callApi} from '@product-web/shared--api'
import type {WalletSettingsFeature} from '@product-web/shared--api-types/wallet'
import {useLoggedInUser} from '@product-web/shared--user'

import {getDeimos} from './helpers'

export interface CompletedWalletLoad {
    amount: Amount
    bill: Amount
    performed: string
    type: 'LOAD' | 'UNLOAD'
    status: 'COMPLETED'
}

export interface WalletFeaturesResponse {
    features: WalletSettingsFeature[]
    openBankingTransactionLimit?: number
    autoTopupMinimumAmount?: number
}

export function useWalletFeatures() {
    const user = useLoggedInUser()
    const result = useSWRImmutable<WalletFeaturesResponse, Error>(
        user?.companyId
            ? `/wallet-management/rest/v4/companies/${user.companyId}/wallet-features`
            : null,
        getDeimos,
    )

    return {
        ...result,
        isLoading: !result.error && !result.data,
    }
}

export function useHasWalletFeature(featureName: WalletSettingsFeature) {
    const {data: walletSettings, isLoading} = useWalletFeatures()
    return {
        isLoading,
        hasWalletFeature: Boolean(walletSettings?.features?.includes(featureName)),
    }
}

export interface ActiveBankAccountDetails {
    id: string
    companyId: string
    currency: CurrencyType
    bankName?: string // human-readable string
    senderBank?: string // system-readable string
    bankAccountInfo: BankAccountInfo
}

type ActiveBankAccountsResponse = ActiveBankAccountDetails[]

export function useActiveBankAccounts() {
    const user = useLoggedInUser()

    const result = useSWRImmutable<ActiveBankAccountsResponse, Error>(
        user?.companyId ? `/rest/v1/styx/companies/${user.companyId}/proof-of-funds/basic` : null,
        getDeimos,
    )
    return {
        ...result,
        isLoading: user?.companyId && !result.data && !result.error,
    }
}

// todo: remove this method when BE is ready with the new flow - https://linear.app/pleo/issue/WALLE-5059
export async function createOpenBankingPayment(companyId: string, consentToken: string) {
    return callApi(`/wallet-management/rest/v4/companies/${companyId}/payments`, {
        auth: 'user',
        method: 'POST',
        body: {consentToken},
    })
}
