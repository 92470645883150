import {useFeatureCountryConfig} from '@product-web/shared--country-configuration/configuration'
import {Feature} from '@product-web/shared--country-configuration/types'
import {useCompanyEntitlementsLimits} from '@product-web/shared--entitlement/use-entitlement-limits'
import {useCompanyFeatureEntitlement} from '@product-web/shared--entitlement/use-feature-entitlement'
import {useFlags, useFlagsLoaded} from '@product-web/shared--flags'

export const useVendorCard = () => {
    const {subsPricing30} = useFlags()
    const isFlagLoaded = useFlagsLoaded()

    const vendorCardEntitlement = subsPricing30 ? 'VENDOR_CARDS' : 'DEDICATED_CARDS'
    const {isEntitled, isLoading: isEntitlementLoading} =
        useCompanyFeatureEntitlement(vendorCardEntitlement)

    const {limits: {maxAllowedVendorCards} = {}} = useCompanyEntitlementsLimits()

    // Check the country config to see if the feature is enabled for the country of the company
    // Vendor cards is not available for the USA
    const {canUseVendorCards: canUseVendorCardInUserCountry} = useFeatureCountryConfig(
        Feature.VendorCards,
    )

    const {isEntitled: isVendorLockEnabled, isLoading: isVendorLockLoading} =
        useCompanyFeatureEntitlement('VENDOR_CARD_VENDOR_LOCK')

    const {
        isEntitled: isVendorCardDefaultAccountingEnabled,
        isLoading: isVendorCardDefaultAccountingLoading,
    } = useCompanyFeatureEntitlement('VENDOR_CARD_DEFAULT_ACCOUNTING_VALUES')

    const isEntitledToVendorLockFeature = subsPricing30 ? isVendorLockEnabled : true
    const isEntitledToVendorCardDefaultAccountingFeature = subsPricing30
        ? isVendorCardDefaultAccountingEnabled
        : true

    const isLoading =
        isEntitlementLoading ||
        !isFlagLoaded ||
        isVendorCardDefaultAccountingLoading ||
        isVendorLockLoading

    const canUseVendorCards = isEntitled && canUseVendorCardInUserCountry && !isLoading

    return {
        isLoading,
        isEntitled,
        canUseVendorCards,
        maxAllowedVendorCards,
        canUseVendorCardInUserCountry,
        isEntitledToVendorLockFeature,
        isEntitledToVendorCardDefaultAccountingFeature,
    }
}
