import {t, Trans} from '@lingui/macro'
import {useEffect, useState} from 'react'

import {Button, Card, Inline, Link, Skeleton, Stack, Text, tokens} from '@pleo-io/telescope'

import {ContactSupport} from '@product-web/feature--ui-contact-support'
import type {
    Amount,
    CpqBillingPeriodType,
    CpqRatePlanType,
} from '@product-web/shared--bff-moons/generated/beyond'
import {useTermsDocuments} from '@product-web/shared--country-configuration/features/terms/terms'
import {reportError} from '@product-web/shared--error/report'
import {
    formatMinorNumberToCurrency,
    getAmountFromMinorCurrencyUnits,
} from '@product-web/shared--plan-presentation/currency-formatter'
import {breakpoints} from '@product-web/shared--styles/theme'
import {getCountry, useUser} from '@product-web/shared--user'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

import {CashbackModal} from './components/cashback-modal'
import type {PlanSelectedType} from './components/regular-flow-wizard'
import type {PricingPlansModalTrackingStep} from './tracking'
import {trackPricingPlansModalActioned} from './tracking'

import {bff} from '../bff-hooks'
import type {PageOrigin, PageSection} from '../index.bff'
import type {PlanUpgradeSource} from '../types/plans'
import {PlanTypeName} from '../types/plans'

export type PaymentConfirmationBreakdownProps = {
    targetBillingType: CpqBillingPeriodType
    targetPlan: PlanSelectedType
    onConfirmation: ({isWalletSufficient}: {isWalletSufficient: boolean}) => void
    currentPlan: CpqRatePlanType
    currentPlanEstimatedTotal: Amount | null
    currentBillingType: CpqBillingPeriodType
    onError: () => void
    isLegacyPlan: boolean
    planUpgradeSource: PlanUpgradeSource
    pageOrigin: PageOrigin
    pageSection?: PageSection
    trackingStep: Extract<PricingPlansModalTrackingStep, 'payment_plan' | 'recommended_plan'>
    hasCashbackRecommendationWarning?: boolean
}

const paymentFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
}

export const PaymentConfirmationBreakdown = ({
    targetBillingType,
    onConfirmation,
    onError,
    targetPlan,
    currentPlan,
    currentPlanEstimatedTotal,
    currentBillingType,
    isLegacyPlan,
    planUpgradeSource,
    pageOrigin,
    pageSection,
    trackingStep,
    hasCashbackRecommendationWarning,
}: PaymentConfirmationBreakdownProps) => {
    const user = useUser()
    const numberOfUsers = user?.company?.numberOfUsers ?? 0
    const {
        data,
        isFetching,
        error: isPaymentSummaryError,
    } = bff.paywall.planFeatures.getPaymentSummary.useQuery({
        ratePlanType: targetPlan.planType,
        additional_users_quantity: numberOfUsers,
    })

    const [isCashbackModalOpen, setIsCashbackModalOpen] = useState(false)

    const isTargetYearly = targetBillingType === 'YEAR'
    const targetPayment = data && (isTargetYearly ? data.YEAR : data.MONTH)
    const targetPaymentValue = targetPayment?.totalIncludingTax.value ?? 0
    const isWalletSufficient =
        getAmountFromMinorCurrencyUnits(data?.availableWalletBalance?.amount ?? 0) >
        getAmountFromMinorCurrencyUnits(targetPaymentValue)

    const {mutate: sendEmail} = bff.paywall.planFeatures.sendBillingUpdateEmail.useMutation({
        onError(error) {
            reportError(error)
        },
    })

    const {mutate: updateCompanySubscription, isLoading: isUpdateCompanySubscriptionLoading} =
        bff.paywall.planFeatures.updateCompanySubscription.useMutation({
            onSuccess: ({data: subscription}) => {
                trackPricingPlansModalActioned({
                    action: 'completed',
                    step: trackingStep,
                    origin: pageOrigin,
                    section: pageSection,
                    plan: targetPlan.planType,
                    previousPlan: currentPlan,
                    interval: targetBillingType,
                    sufficientBalance: isWalletSufficient,
                    fromLegacyPlan: isLegacyPlan,
                })

                onConfirmation({isWalletSufficient})
                if (targetPlan.isUpgrade) {
                    sendEmail({subscriptionId: subscription.id, numberOfUsers})
                }
            },
            onError: (error) => {
                reportError(error)
                onError()
            },
        })

    const country = getCountry(user)
    const {masterServiceAgreement, dataProcessingAgreement, privacyPolicy} =
        useTermsDocuments(country)
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.tabletUp})`)

    useEffect(() => {
        trackPricingPlansModalActioned({
            action: 'viewed',
            step: trackingStep,
            origin: pageOrigin,
            section: pageSection,
            plan: targetPlan.planType,
        })
    }, [])

    if (isPaymentSummaryError) {
        return <ErrorCard />
    }
    const locale = user?.language!

    const targetPaymentPerMonth = isTargetYearly ? targetPaymentValue / 12 : targetPaymentValue
    const targetPaymentCurrency = targetPayment?.totalIncludingTax.currency

    const formattedTargetSubtotal = formatMinorNumberToCurrency({
        value: targetPaymentPerMonth,
        locale,
        options: {...paymentFormatOptions, currency: targetPaymentCurrency},
    })

    // Current billing is calculated as monthly
    const currentBilling =
        currentBillingType === 'YEAR'
            ? (currentPlanEstimatedTotal?.value ?? 0) / 12
            : currentPlanEstimatedTotal?.value ?? 0

    // Price difference is calculated as monthly
    const priceDifference = formatMinorNumberToCurrency({
        value: targetPaymentPerMonth - currentBilling,
        locale,
        options: {...paymentFormatOptions, currency: targetPaymentCurrency, signDisplay: 'always'},
    })

    // Due today is calculated as monthly or yearly depending on the target billing type
    const dueToday = formatMinorNumberToCurrency({
        value: targetPaymentValue,
        locale,
        options: {...paymentFormatOptions, currency: targetPaymentCurrency},
    })

    const handleSubmit = () => {
        if (hasCashbackRecommendationWarning) {
            setIsCashbackModalOpen(true)
        } else {
            handleUpdateCompanySubscription()
        }
    }

    const handleUpdateCompanySubscription = () => {
        updateCompanySubscription({
            billingPeriodType: targetBillingType,
            ratePlanType: targetPlan.planType,
            subscriptionUpdateSource: planUpgradeSource,
        })
    }

    const additionalUsersValue = targetPayment?.usersIncludingTax?.value ?? 0
    const hasAdditionalUsers = !!additionalUsersValue

    const additionalUsersEstimationCost = isTargetYearly
        ? additionalUsersValue / 12
        : additionalUsersValue

    const additionalUsersCount = numberOfUsers - (targetPlan.freeUserLimit ?? 0)

    const additionalCostPerUser = formatMinorNumberToCurrency({
        value: additionalUsersEstimationCost / additionalUsersCount,
        locale,
        options: {
            ...paymentFormatOptions,
            currency: targetPayment?.platformIncludingTax?.currency,
        },
    })

    const agreementText = targetPlan.isUpgrade
        ? t`By clicking Upgrade you agree to Pleo's`
        : t`By clicking Downgrade you agree to Pleo's`

    return (
        <Card padding={32} css={{maxWidth: isMobile ? 'auto' : '360px'}}>
            <Stack space={16} css={{width: '100%', margin: '0 auto'}} stretch>
                <Skeleton loading={isFetching}>
                    <Text variant="xlarge-accent" weight="medium">
                        <Trans>Price breakdown</Trans>
                    </Text>
                </Skeleton>
                <Stack space={12} stretch>
                    {targetPayment?.platformIncludingTax !== undefined && (
                        <Skeleton loading={isFetching}>
                            <div>
                                <Inline space={16} justifyContent="space-between">
                                    <Text variant="medium-default">
                                        <Trans>{PlanTypeName[targetPlan.planType]} plan</Trans>
                                    </Text>
                                    <Text variant="medium-default" data-testid="payment-plan">
                                        <Trans>
                                            {formatMinorNumberToCurrency({
                                                value: isTargetYearly
                                                    ? targetPayment?.platformIncludingTax.value / 12
                                                    : targetPayment?.platformIncludingTax.value,
                                                locale,
                                                options: {
                                                    ...paymentFormatOptions,
                                                    currency:
                                                        targetPayment?.platformIncludingTax
                                                            .currency,
                                                },
                                            })}
                                            /month
                                        </Trans>
                                    </Text>
                                </Inline>
                                <Text variant="small-subtle" color="colorContentStaticQuiet">
                                    <Trans>{targetPlan.freeUserLimit} users included</Trans>
                                </Text>
                            </div>
                        </Skeleton>
                    )}
                    {hasAdditionalUsers && (
                        <Skeleton loading={isFetching}>
                            <div>
                                <Inline space={16} justifyContent="space-between">
                                    <Text variant="medium-default">
                                        <Trans>Additional users</Trans>
                                    </Text>
                                    <Text
                                        variant="medium-default"
                                        data-testid="payment-additional-users"
                                    >
                                        <Trans>
                                            {formatMinorNumberToCurrency({
                                                value: additionalUsersEstimationCost,
                                                locale,
                                                options: {
                                                    ...paymentFormatOptions,
                                                    currency:
                                                        targetPayment?.platformIncludingTax
                                                            ?.currency,
                                                },
                                            })}
                                            /month
                                        </Trans>
                                    </Text>
                                </Inline>
                                <Text variant="small-subtle" color="colorContentStaticQuiet">
                                    <Trans>
                                        {additionalUsersCount} at {additionalCostPerUser} per user
                                    </Trans>
                                </Text>
                            </div>
                        </Skeleton>
                    )}
                </Stack>
                <Card.Divider />
                <Skeleton loading={isFetching}>
                    <div>
                        <Inline space={16} justifyContent="space-between">
                            <Text variant="large-accent" weight="medium">
                                <Trans>New price</Trans>
                            </Text>
                            <Text
                                variant="large-accent"
                                weight="medium"
                                data-testid="payment-new-price"
                            >
                                <Trans>{formattedTargetSubtotal}/month</Trans>
                            </Text>
                        </Inline>
                        <Inline justifyContent="space-between">
                            <Text variant="small-subtle" color="colorContentStaticQuiet">
                                <Trans>Compared to {PlanTypeName[currentPlan]}</Trans>
                                {isLegacyPlan && (
                                    <>
                                        <br />(<Trans>Legacy</Trans>)
                                    </>
                                )}
                            </Text>
                            <Text
                                variant="small-subtle"
                                color="colorContentStaticQuiet"
                                data-testid="payment-difference"
                            >
                                <Trans>{priceDifference}/month</Trans>
                            </Text>
                        </Inline>
                    </div>
                </Skeleton>
                <Card.Divider />
                <Skeleton loading={isFetching}>
                    <div>
                        <Inline space={16} justifyContent="space-between">
                            <Text variant="large-accent" weight="medium">
                                <Trans>To pay now</Trans>
                            </Text>
                            <Text
                                variant="large-accent"
                                weight="medium"
                                data-testid="payment-pay-now"
                            >
                                {dueToday}
                            </Text>
                        </Inline>
                        {targetPlan.isUpgrade ? (
                            <Text variant="small-subtle" color="colorContentStaticQuiet">
                                {isWalletSufficient ? (
                                    <Trans>From your Pleo Wallet</Trans>
                                ) : (
                                    <Trans>An invoice will be sent for the full amount</Trans>
                                )}
                            </Text>
                        ) : (
                            <Text variant="small-subtle" color="colorContentStaticQuiet">
                                <Trans>Any unused time will be automatically refunded.</Trans>
                            </Text>
                        )}
                    </div>
                </Skeleton>
                <Skeleton loading={isFetching}>
                    <Button
                        variant="primary"
                        destructive={!targetPlan.isUpgrade ? true : false}
                        fullWidth
                        onClick={handleSubmit}
                        data-testid="payment-confirm-button"
                        loading={isUpdateCompanySubscriptionLoading}
                    >
                        {targetPlan.isUpgrade
                            ? t`Upgrade to ${PlanTypeName[targetPlan.planType]}`
                            : t`Downgrade to ${PlanTypeName[targetPlan.planType]}`}
                        &nbsp;
                    </Button>
                </Skeleton>
                <Text
                    variant="small-subtle"
                    color="colorContentStaticQuiet"
                    aria-label={t`${agreementText} Master Service Agreement, Data Processing Agreement and Privacy Policy`}
                >
                    <Trans>
                        {agreementText}{' '}
                        <Link
                            href={masterServiceAgreement}
                            target="_blank"
                            rel="noopener noreferrer"
                            css={{fontSize: tokens.fontSmall}}
                            onClick={() => {
                                trackPricingPlansModalActioned({
                                    action: 'master_service_agreement_link',
                                    step: trackingStep,
                                    origin: pageOrigin,
                                    section: pageSection,
                                    plan: targetPlan.planType,
                                })
                            }}
                        >
                            Master Service Agreement
                        </Link>
                        ,{' '}
                        <Link
                            href={dataProcessingAgreement}
                            target="_blank"
                            rel="noopener noreferrer"
                            css={{fontSize: tokens.fontSmall}}
                            onClick={() => {
                                trackPricingPlansModalActioned({
                                    action: 'data_processing_agreement_link',
                                    step: trackingStep,
                                    origin: pageOrigin,
                                    section: pageSection,
                                    plan: targetPlan.planType,
                                })
                            }}
                        >
                            Data Processing Agreement
                        </Link>{' '}
                        and{' '}
                        <Link
                            href={privacyPolicy}
                            target="_blank"
                            rel="noopener noreferrer"
                            css={{fontSize: tokens.fontSmall}}
                            onClick={() => {
                                trackPricingPlansModalActioned({
                                    action: 'privacy_policy_link',
                                    step: trackingStep,
                                    origin: pageOrigin,
                                    section: pageSection,
                                    plan: targetPlan.planType,
                                })
                            }}
                        >
                            Privacy Policy
                        </Link>
                        .
                    </Trans>
                </Text>
            </Stack>
            <CashbackModal
                dangerouslySetZIndexValue={tokens.zIndexModal}
                isOpen={isCashbackModalOpen}
                onDismiss={() => {
                    setIsCashbackModalOpen(false)
                }}
                onContinue={() => {
                    setIsCashbackModalOpen(false)
                    handleUpdateCompanySubscription()
                }}
            />
        </Card>
    )
}

const ErrorCard = () => {
    return (
        <Card padding={32} css={{maxWidth: '360px'}}>
            <Stack space={16}>
                <Text variant="xlarge-accent" weight="medium">
                    <Trans>Price breakdown</Trans>
                </Text>

                <Card.Divider />
                <Text variant="large-accent" color="colorContentStaticQuiet">
                    <Trans>
                        Sorry, something went wrong when loading the breakdown. Please go back and
                        try again, or reach out to us for help.
                    </Trans>
                </Text>
                <ContactSupport
                    chatLabel={t`Chat with us`}
                    css={{whiteSpace: 'nowrap'}}
                    buttonVariant="secondary"
                />
            </Stack>
        </Card>
    )
}
