import {t, Trans} from '@lingui/macro'
import styled from 'styled-components'

import {Box, Inline, NakedButton, Stack, Text, tokens, Tooltip} from '@pleo-io/telescope'
import {Check, Help} from '@pleo-io/telescope-icons'

import type {
    BillingInfoCurrency,
    CpqRatePlanType,
} from '@product-web/shared--bff-moons/generated/beyond'
import {SupportedLanguage} from '@product-web/shared--i18n'
import {formatMinorNumberToCurrency} from '@product-web/shared--plan-presentation/currency-formatter'
import {
    getPlanContentConfiguration,
    renderUpToCashbackLine,
    renderUpToCreditPrice,
} from '@product-web/shared--plan-presentation/pricing-plans.helper'
import {useUser} from '@product-web/shared--user'
import {exhaustiveCheck} from '@product-web/shared--utils'

import {PlanTypeName} from '../../types/plans'

// Types
export type EligibleEntitlementsSectionProps = {
    targetPlanType: CpqRatePlanType
    currency: BillingInfoCurrency
    isBillingAnnual: boolean
    hasCurrentSubscriptionCashback: boolean
}

type GetTooltipTextArgs = {
    eligibleEntitlementsLimits: EligibleEntitlementsLimits
    planType: CpqRatePlanType
}

type EligibleEntitlementsLimits = Map<'CASHBACK', number> & Map<'CREDIT_LINE', string>

type GetEligibleEntitlementTextArgs = {
    eligibleEntitlement: EligibleEntitlements
    eligibleEntitlementsLimits: EligibleEntitlementsLimits
}

export type EligibleEntitlements = 'CASHBACK' | 'CREDIT_LINE'

// Styled components
const Wrapper = styled(Box)`
    padding-top: ${tokens.spacing20};
    padding-bottom: ${tokens.spacing24};
`

export const EligibleEntitlementsSection = ({
    targetPlanType,
    currency,
    isBillingAnnual,
    hasCurrentSubscriptionCashback,
}: EligibleEntitlementsSectionProps) => {
    const user = useUser()
    const locale = user?.language ?? SupportedLanguage.EN

    // Get the eligible entitlements limits for the target plan as a number or a string
    const eligibleEntitlementsLimits: EligibleEntitlementsLimits = getEligibleEntitlementLimits({
        targetPlanType,
        currency,
        locale,
        isBillingAnnual,
        hasCurrentSubscriptionCashback,
    })

    // Get the tooltip text
    const tooltipText = getTooltipText({
        eligibleEntitlementsLimits,
        planType: targetPlanType,
    })

    // Get the eligible entitlements keys to itarate over them
    const eligibleEntitlements: EligibleEntitlements[] = [...eligibleEntitlementsLimits.keys()]
    // Render the eligible entitlements
    const renderEligibleEntitlements = () => {
        return eligibleEntitlements.map((eligibleEntitlement, index) => {
            const eligibleEntitlementText = getEligibleEntitlementText({
                eligibleEntitlement,
                eligibleEntitlementsLimits,
            })

            return (
                <Inline
                    key={`${index}-${eligibleEntitlement}`}
                    space={4}
                    alignItems="center"
                    css={{width: '280px'}}
                >
                    <Check size={16} color={tokens.colorContentPositive} />
                    <Text>{eligibleEntitlementText}</Text>
                </Inline>
            )
        })
    }

    // If there are no eligible entitlements, don't render the section
    if (eligibleEntitlementsLimits.size === 0) {
        return null
    }

    return (
        <Wrapper data-testid="eligible-entitlements">
            <Stack space={12}>
                <Inline space={6} alignItems="center">
                    <Text variant="large-accent" weight="medium">
                        <Trans>For eligible customers</Trans>
                    </Text>
                    <Tooltip content={tooltipText}>
                        <NakedButton aria-label={t`Feature eligibility explanation`}>
                            <Help size={16} color={tokens.colorContentStaticQuiet} />
                        </NakedButton>
                    </Tooltip>
                </Inline>
                {renderEligibleEntitlements()}
            </Stack>
        </Wrapper>
    )
}

const getEligibleEntitlementLimits = ({
    targetPlanType,
    currency,
    locale,
    isBillingAnnual,
    hasCurrentSubscriptionCashback,
}: {
    targetPlanType: CpqRatePlanType
    currency: BillingInfoCurrency
    locale: SupportedLanguage
    isBillingAnnual: boolean
    hasCurrentSubscriptionCashback: boolean
}) => {
    const targetPlanConfiguration = getPlanContentConfiguration(targetPlanType)
    const cashbackPercentage = (isBillingAnnual && targetPlanConfiguration.cashback) ?? 0
    const creditLineAmount = targetPlanConfiguration.overdraft?.[currency] ?? 0
    const formattedCreditLineAmount = creditLineAmount
        ? formatMinorNumberToCurrency({
              value: creditLineAmount,
              options: {
                  currency,
                  maximumFractionDigits: 1,
                  notation: 'compact',
              },
              locale,
          })
        : ''
    const eligibleEntitlementsLimits: EligibleEntitlementsLimits = new Map()

    if (cashbackPercentage && !hasCurrentSubscriptionCashback) {
        eligibleEntitlementsLimits.set('CASHBACK', cashbackPercentage)
    }

    if (formattedCreditLineAmount) {
        eligibleEntitlementsLimits.set('CREDIT_LINE', formattedCreditLineAmount)
    }

    return eligibleEntitlementsLimits
}

const getTooltipText = ({eligibleEntitlementsLimits, planType}: GetTooltipTextArgs) => {
    const cashback = eligibleEntitlementsLimits.get('CASHBACK')
    const creditLine = eligibleEntitlementsLimits.get('CREDIT_LINE')

    const targetPlan = PlanTypeName[planType]

    if (eligibleEntitlementsLimits.size < 2) {
        if (eligibleEntitlementsLimits.has('CASHBACK')) {
            return t`Upgrade to the ${targetPlan} plan and chat with our support team to check your eligibility. If approved, you'll enjoy up to ${cashback}% cashback.`
        }

        if (eligibleEntitlementsLimits.has('CREDIT_LINE')) {
            return t`Upgrade to the ${targetPlan} plan and chat with our support team to check your eligibility. If approved, you'll access a credit line of up to ${creditLine}.`
        }
    }

    return t`Upgrade to the ${targetPlan} plan and chat with our support team to check your eligibility. If approved, you'll enjoy up to ${cashback}% cashback and access a credit line of up to ${creditLine}.`
}

const getEligibleEntitlementText = ({
    eligibleEntitlement,
    eligibleEntitlementsLimits,
}: GetEligibleEntitlementTextArgs) => {
    switch (eligibleEntitlement) {
        case 'CASHBACK':
            return renderUpToCashbackLine(eligibleEntitlementsLimits.get('CASHBACK') ?? 0)
        case 'CREDIT_LINE':
            return renderUpToCreditPrice(eligibleEntitlementsLimits.get('CREDIT_LINE') ?? '')
        default:
            return exhaustiveCheck(eligibleEntitlement)
    }
}
