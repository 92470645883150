import {RequestScope} from '@product-web/shared--api'
import {defineClientContext} from '@product-web/shared--bff-client-context'
import type {BaseSamlConfiguration} from '@product-web/shared--bff-moons/static/kerberos-v1'

import {bffHooks} from './bff-hooks'
import type {SamlConfigContext, SamlConfigMutationsContext} from './contexts/saml-config'
import type {SamlEntitlementContext} from './contexts/saml-entitlement-context'
import {getSamlSsoLocalisedProviderInfo} from './lib/get-saml-sso-localised-provider-info'

const bff = bffHooks.settingsIntegrations.samlConfig

export function useSamlConfig(): SamlConfigContext {
    const {data: samlConfig = null, isLoading, isError} = bff.getSamlConfig.useQuery()

    const localisedSamlConfig = samlConfig
        ? {
              ...samlConfig,
              provider: {
                  ...samlConfig.provider,
                  ...getSamlSsoLocalisedProviderInfo(samlConfig.provider.id),
              },
          }
        : null

    return {
        samlConfig: localisedSamlConfig,
        isLoading,
        isError,
    }
}

const elevatedContext = {
    trpc: {
        context: defineClientContext({
            auth: {
                type: 'elevated',
                scope: RequestScope.CARD,
            },
        }),
    },
}

export function useSamlConfigMutations(): SamlConfigMutationsContext {
    const {mutateAsync: createConfig, isLoading: isCreating} =
        bff.createSamlConfig.useMutation(elevatedContext)
    const {mutateAsync: updateConfig, isLoading: isUpdating} =
        bff.updateSamlConfig.useMutation(elevatedContext)
    const {mutateAsync: disconnectConfig, isLoading: isDisconnecting} =
        bff.disconnectSamlConfig.useMutation(elevatedContext)

    return {
        isCreating,
        isUpdating,
        isDisconnecting,
        create: async (props: Omit<BaseSamlConfiguration, 'enabled'>) => {
            await createConfig(props)
            return
        },
        update: async (props: BaseSamlConfiguration) => {
            await updateConfig(props)
            return
        },
        disconnect: async () => {
            await disconnectConfig()
            return
        },
    }
}

export function useSamlFeatureEntitlement(): SamlEntitlementContext {
    const {data, isLoading} = bff.isEntitledToSamlSso.useQuery()

    return {
        isEntitled: data?.isEntitled ?? false,
        isLoading,
    }
}
