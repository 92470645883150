import React from 'react'

import {invariant} from '@product-web/shared--utils'

import type {APIKey, APIKeyInput} from '../models'

export type APIKeysContext = {
    readonly apiKeys: APIKey[]
    readonly isLoading: boolean
    readonly isSaving: boolean
    readonly isDeleting: boolean
}

const DEFAULT_CONTEXT: APIKeysContext = {
    apiKeys: [],
    isLoading: false,
    isSaving: false,
    isDeleting: false,
}

export const APIKeysContext = React.createContext<APIKeysContext>(DEFAULT_CONTEXT)

export const useAPIKeysContext = () => {
    const context = React.useContext(APIKeysContext)
    invariant(context, '`useAPIKeysContext` can only be used within `APIKeysContext`')
    return context
}

export type APIKeysAPIContext = {
    /** Performs a request to create an API key */
    create: (apiKey: APIKeyInput) => Promise<APIKey>
    /** Performs a request to delete an API key */
    deleteKey: (id: string) => Promise<void>
}

export const APIKeysAPIContext = React.createContext<APIKeysAPIContext>(undefined as any)

/** Returns the API to perform actions for API keys */
export const useAPIKeysAPIContext = () => {
    const context = React.useContext(APIKeysAPIContext)
    invariant(context, '`useAPIKeysAPIContext` must be used within a `APIKeysAPIContext`')
    return context
}
