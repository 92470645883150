import {t, Trans} from '@lingui/macro'

import {ArrowLeft} from '@pleo-io/telescope-icons'

import {ContactSupport} from '@product-web/feature--ui-contact-support'
import * as s from '@product-web/feature--ui-guides/guide.styles'

import type {FetchStepActionProps} from './setup-modal'
import {FetchGuideStep} from './steps'

export const BackButton = ({onPreviousStep}: FetchStepActionProps) => (
    <s.LinkButton variant="tertiary" onClick={onPreviousStep} IconLeft={ArrowLeft}>
        <Trans>Back</Trans>
    </s.LinkButton>
)

export const NextButton = ({onNextStep}: FetchStepActionProps) => (
    <s.Button variant="primary" onClick={onNextStep}>
        <Trans>Next</Trans>
    </s.Button>
)

export const DoneButton = ({onCompleted}: FetchStepActionProps) => {
    return (
        <s.Button variant="primary" data-testid="fetch-done-button" onClick={onCompleted}>
            <Trans>All done</Trans>
        </s.Button>
    )
}

export const TryAgainButton = ({onSetActiveStep}: FetchStepActionProps) => {
    return (
        <s.Button
            variant="primary"
            data-testid="fetch-try-again-button"
            onClick={() => onSetActiveStep?.(FetchGuideStep.PROVIDER)}
        >
            <Trans>Try again</Trans>
        </s.Button>
    )
}

export const ContactSupportButton = () => {
    return <ContactSupport chatLabel={t`Contact support`} />
}
