import {t, Trans} from '@lingui/macro'
import Helmet from 'react-helmet'
import {Link as RouterLink} from 'react-router-dom'

import {Button, Inline, Link, Stack, Tag, Text, tokens} from '@pleo-io/telescope'
import {ArrowLeft, Clock} from '@pleo-io/telescope-icons'

import {Page} from '@product-web/feature--ui-page'

import {SplitContent} from '../../../../components/split-content'
import {useAcademyContext} from '../../components/academy-context/academy-context'
import {CourseSummary} from '../../components/course-summary'
import illustration from '../../images/the-pleo-product.svg'

export const ThePleoProduct = () => {
    const {hideInvoices} = useAcademyContext()
    return (
        <>
            <Helmet>
                <title>{t`The Pleo Product`}</title>
            </Helmet>
            <Page.Header>
                <Page.Backlink>
                    <Link IconLeft={ArrowLeft} to="/partner/academy" as={RouterLink}>
                        <Trans>Back</Trans>
                    </Link>
                </Page.Backlink>
                <Page.SpaceBetween>
                    <Page.Title>
                        <Trans>The Pleo Product</Trans>
                    </Page.Title>
                </Page.SpaceBetween>
                <Inline space={16}>
                    <Tag variant="pink">
                        <Trans>Admins</Trans>
                    </Tag>
                    <Tag variant="gray">
                        <Trans>Bookkeepers</Trans>
                    </Tag>
                    <Inline alignItems="center" space={6}>
                        <Clock size={16} color={tokens.colorContentStaticQuiet} />{' '}
                        <Text color="colorContentStaticQuiet">
                            {hideInvoices ? <Trans>6 mins</Trans> : <Trans>8 mins</Trans>}
                        </Text>
                    </Inline>
                </Inline>
            </Page.Header>
            <SplitContent>
                <SplitContent.Main>
                    <Stack mt={24}>
                        <Text as="h2" variant="2xlarge-accent" space={4}>
                            <Trans>What's it about?</Trans>
                        </Text>
                        <Text color="colorContentStaticQuiet">
                            <Trans>
                                We'd love to show you some handy features which make the us Europe's
                                #1 business spend management solution.
                            </Trans>
                        </Text>
                        <CourseSummary
                            steps={[
                                t`Reimbursements & Mileage (2 mins)`,
                                t`Invoices (2 mins)`,
                                t`Vendor cards (2 mins)`,
                                t`Multi-entities (2 mins)`,
                            ].filter((_, index) => (index === 1 && hideInvoices ? false : true))}
                        />
                    </Stack>
                    <Page.Actions>
                        <Button
                            aria-label={t`Let's go`}
                            variant="primary"
                            as={RouterLink}
                            to="./reimbursements-and-mileage"
                        >
                            <Trans>Let's go</Trans> &rarr;
                        </Button>
                    </Page.Actions>
                </SplitContent.Main>
                <SplitContent.Right trivial>
                    <img src={illustration} alt="" />
                </SplitContent.Right>
            </SplitContent>
        </>
    )
}
