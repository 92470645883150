import {useCompanySubscription} from '@product-web/shared--paywall/lib/company-subscription'
import {getIsCompanyOwner, useUser} from '@product-web/shared--user'

export default function useShouldPromoCardBeVisible(): boolean {
    const user = useUser()
    const isCompanyOwner = getIsCompanyOwner(user)
    const {data: subscription} = useCompanySubscription()

    const planType = subscription?.platformRatePlan.type
    const isStarterPlan = planType === 'STARTER' || planType === 'FREE'

    return isCompanyOwner && isStarterPlan && !subscription?.isInTrialPeriod
}
