import * as React from 'react'
import {Navigate, Route} from 'react-router'

import {roleSets} from '@product-web/shared--user'

const MileageSettingsPage = React.lazy(async () => import('./screens/mileage-settings/mileage'))
const MileageReportDownloadPage = React.lazy(
    async () => import('./screens/mileage-settings/mileage-report-download'),
)

export const settingsRoutes = (
    <Route handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}>
        <Route index element={<Navigate to="settings" replace />} />
        <Route path="settings" element={<MileageSettingsPage />} />
        <Route path="claims" element={<MileageSettingsPage />} />
        <Route path="exports/:exportId/download" element={<MileageReportDownloadPage />} />
    </Route>
)
