import type {RequestOptions} from '@product-web/shared--api'
import {callApi} from '@product-web/shared--api'
import {RequestAuth} from '@product-web/shared--api'

export async function getDeimos<Response = any>(route: string, options?: Partial<RequestOptions>) {
    return callApi<Response>(route, {
        ...options,
        auth: RequestAuth.USER,
        method: 'GET',
    })
}

export async function deleteDeimos<Response = any>(route: string) {
    return callApi<Response>(route, {
        auth: RequestAuth.USER,
        method: 'DELETE',
    })
}

export async function postDeimos<Response = any>(route: string, body?: Record<string, any>) {
    return callApi<Response>(route, {
        auth: RequestAuth.USER,
        method: 'POST',
        body,
    })
}

export async function putDeimos<Response = any>(route: string, body?: Record<string, any>) {
    return callApi<Response>(route, {
        auth: RequestAuth.USER,
        method: 'PUT',
        body,
    })
}

export async function patchDeimos<Response = any>(route: string, body?: Record<string, any>) {
    return callApi<Response>(route, {
        auth: RequestAuth.USER,
        method: 'PATCH',
        body,
    })
}
