import * as React from 'react'
import {Navigate, Outlet, Route} from 'react-router-dom'

import {Page} from '@product-web/feature--ui-page/page'

import {ClientTab} from './components/clients-tabs/client-tab'

const Invites = React.lazy(async () => import('./screens/invites/invites'))
const OnboardingClients = React.lazy(
    async () => import('./screens/onboarding-clients/onboarding-clients'),
)
const PartnerClients = React.lazy(async () => import('./screens/partner-clients/partner-clients'))

const Wrapper = () => {
    return (
        <Page layout="wide" showNav>
            <Outlet />
        </Page>
    )
}

export const routes = (
    <>
        <Route index element={<Navigate to={`./${ClientTab.MY_CLIENTS}`} replace />} />
        <Route element={<Wrapper />}>
            <Route
                path={`${ClientTab.MY_CLIENTS}`}
                element={<PartnerClients tab={ClientTab.MY_CLIENTS} />}
            />
            <Route
                path={`${ClientTab.MY_CLIENTS}/:companyId`}
                element={<PartnerClients tab={ClientTab.MY_CLIENTS} />}
            />
            <Route
                path={`${ClientTab.ALL_CLIENTS}`}
                element={<PartnerClients tab={ClientTab.ALL_CLIENTS} />}
            />
            <Route
                path={`${ClientTab.ALL_CLIENTS}/:companyId`}
                element={<PartnerClients tab={ClientTab.ALL_CLIENTS} />}
            />
            <Route path={`${ClientTab.INVITES}`} element={<Invites />} />
            <Route path={`${ClientTab.INVITES}/:token`} element={<Invites />} />
            <Route path={`${ClientTab.ONBOARDING}`} element={<OnboardingClients />} />
            <Route path={`${ClientTab.ONBOARDING}/:companyId`} element={<OnboardingClients />} />
        </Route>
    </>
)
