import {t} from '@lingui/macro'
import {AnimatePresence, motion} from 'framer-motion'
import type {ReactNode} from 'react'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

import {ModalContent, Stack} from '@pleo-io/telescope'

import type {
    Direction,
    Step,
    StepSection,
    StepWithDispatch,
} from '@product-web/feature--ui-guides/guide'
import {Screen, useGuide, variants} from '@product-web/feature--ui-guides/guide'
import * as s from '@product-web/feature--ui-guides/guide.styles'
import {Indicator} from '@product-web/feature--ui-guides/indicator'
import {breakpoints} from '@product-web/shared--styles/theme'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

import {trackGuideCompleted, trackGuideDismissed, trackStepViewed} from './setup-modal-tracking'
import {
    ConnectedStep,
    ErrorStep,
    FetchGuideStep,
    IntroSteps,
    MissingReceiptStep,
    OtherProviderStep,
    ProviderConnectingStep,
    ProvidersListStep,
} from './steps'

import type {FetchInboxProviders} from '../fetch'
import {useFetchProvidersBasics} from '../fetch'

interface Props {
    isOpen: boolean
    isConnecting?: boolean
    hasMissingReceipts?: boolean
    hasError?: boolean
    activeStep?: FetchGuideStep
    onClose: () => void
    onDismiss: () => void
}

export default ({
    isOpen,
    isConnecting = false,
    activeStep = FetchGuideStep.INTRO,
    hasMissingReceipts = false,
    hasError = false,
    onClose,
    onDismiss,
    ...otherProps
}: Props) => {
    const [direction, setDirection] = useState<Direction>('right')
    const [showOtherProvider, setShowOtherProvider] = useState<boolean>(false)
    const providersBasics = useFetchProvidersBasics()
    const isMobileScreen = useMediaQuery(`(max-width: ${breakpoints.mobileLrgUp})`)

    const providers = {
        ...providersBasics,
        otherProvider: {
            title: t`Other email provider`,
            onClick: () => setShowOtherProvider(true),
        },
    }

    const getProviderStep = () => {
        if (isConnecting) {
            return ProviderConnectingStep
        } else if (showOtherProvider) {
            return OtherProviderStep(isMobileScreen)
        }
        return ProvidersListStep(isMobileScreen)
    }

    const getStatusStep = () => {
        if (hasError) {
            return ErrorStep
        } else if (hasMissingReceipts) {
            return MissingReceiptStep(isMobileScreen)
        }
        return ConnectedStep(isMobileScreen)
    }

    const steps = [
        ...IntroSteps(isMobileScreen),
        getProviderStep(),
        {...getStatusStep(), indicatorNavigationDisabled: true},
    ] as Step[]

    const {
        active,
        onSetActiveStep: onSetActiveGuideStep,
        onNextStep: onNextGuideStep,
        onPreviousStep: onPreviousGuideStep,
        onStart: onGuideStart,
        onCompleted: onCompletedGuide,
    } = useGuide({
        steps,
        onClose,
    })

    const currentStep = steps[active]

    const {
        key,
        name: stepName,
        color,
        illustrationBgColor,
        title,
        sections,
        illustration,
        actions,
    } = currentStep

    useEffect(() => {
        onSetActiveGuideStep(activeStep)
        if (isOpen) {
            trackStepViewed(steps[activeStep].name)
        }
    }, [isOpen, activeStep])

    const onStart = () => {
        trackStepViewed(stepName)
        onGuideStart()
    }

    const onCompleted = async () => {
        trackGuideCompleted(stepName)
        setShowOtherProvider(false)
        onCompletedGuide()
    }

    const onGuideDismissed = () => {
        trackGuideDismissed(stepName)
        setShowOtherProvider(false)
        onDismiss()
    }

    const onSetActiveStep = (step: number) => {
        setDirection(step > active ? 'right' : 'left')
        onSetActiveGuideStep(step)
        trackStepViewed(stepName)
    }

    const onNextStep = () => {
        direction !== 'right' && setDirection('right')
        trackGuideCompleted(stepName)
        trackStepViewed(steps[active + 1].name)
        onNextGuideStep()
    }

    const onPreviousStep = () => {
        direction !== 'left' && setDirection('left')
        if (showOtherProvider) {
            setShowOtherProvider(false)
        } else {
            onPreviousGuideStep()
            trackStepViewed(steps[active - 1].name)
        }
    }

    if (!providersBasics) {
        return null
    }

    return (
        <>
            <s.Modal
                isOpen={isOpen}
                onDismiss={onGuideDismissed}
                aria-label={t`Reimbursements Setup Guide`}
            >
                <ModalContent as="section">
                    <AnimatePresence initial={false} exitBeforeEnter>
                        <motion.div
                            key={key}
                            custom={direction}
                            variants={variants}
                            initial="enter"
                            animate="center"
                            exit="exit"
                            data-testid={`fetch-setup-screen-${key}`}
                            /* eslint-disable-next-line no-inline-styles/no-inline-styles */
                            style={{height: '100%'}}
                        >
                            <Screen>
                                <s.ScreenContentWrapper isImageOnTop>
                                    {illustration && isMobileScreen && (
                                        <s.IllustrationWrapper
                                            $color={illustrationBgColor ?? color}
                                        >
                                            {illustration}
                                        </s.IllustrationWrapper>
                                    )}
                                    <s.ScreenContent>
                                        <s.ContentWrapper>
                                            {!isConnecting ? (
                                                <Indicator
                                                    steps={steps}
                                                    active={active}
                                                    onClickStepIndicator={onSetActiveStep}
                                                />
                                            ) : null}
                                            <FetchGuideScreenContent>
                                                {title}
                                                {sections?.length && (
                                                    <s.ScreenSectionsWrapper>
                                                        <ScreenSections
                                                            {...otherProps}
                                                            receiptsProviders={providers}
                                                            sections={sections}
                                                            onNextStep={onNextStep}
                                                            onPreviousStep={onPreviousStep}
                                                            onCompleted={onCompleted}
                                                            onStart={onStart}
                                                            onSetActiveStep={onSetActiveStep}
                                                        />
                                                    </s.ScreenSectionsWrapper>
                                                )}
                                            </FetchGuideScreenContent>
                                        </s.ContentWrapper>
                                    </s.ScreenContent>
                                    {illustration && !isMobileScreen && (
                                        <s.IllustrationWrapper
                                            $color={illustrationBgColor ?? color}
                                        >
                                            {illustration}
                                        </s.IllustrationWrapper>
                                    )}
                                </s.ScreenContentWrapper>
                                {actions?.length && (
                                    <s.ScreenActionWrapper>
                                        {actions.map(
                                            (
                                                {action, key: actionKey}: FetchStepAction,
                                                idx: number,
                                            ) => (
                                                <React.Fragment
                                                    key={`guide-step-action-${actionKey}-${idx}`}
                                                >
                                                    {action && typeof action === 'function'
                                                        ? action({
                                                              ...otherProps,
                                                              onNextStep,
                                                              onPreviousStep,
                                                              onCompleted,
                                                              onStart,
                                                              onSetActiveStep,
                                                          })
                                                        : action}
                                                </React.Fragment>
                                            ),
                                        )}
                                    </s.ScreenActionWrapper>
                                )}
                            </Screen>
                        </motion.div>
                    </AnimatePresence>
                </ModalContent>
            </s.Modal>
        </>
    )
}

const FetchGuideScreenContent = styled(s.Content)`
    overflow: scroll;
`

export type FetchStepActionProps = Partial<StepWithDispatch>

interface FetchStepAction {
    key: string
    action: ((props: FetchStepActionProps) => ReactNode) | ReactNode
}

export type FetchScreenSectionsProps = {
    sections: StepSection[]
    receiptsProviders: FetchInboxProviders
} & Partial<StepWithDispatch>

const ScreenSections = ({sections, ...otherProps}: FetchScreenSectionsProps) => (
    <>
        {sections.map((section: StepSection) => (
            <s.ScreenSectionWrapper key={section.key}>
                <Stack space={8}>
                    {section.title}
                    {section.content && typeof section.content === 'function'
                        ? section.content({
                              ...otherProps,
                          })
                        : section.content}
                </Stack>
            </s.ScreenSectionWrapper>
        ))}
    </>
)
