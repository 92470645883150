import {Trans} from '@lingui/macro'
import React from 'react'

import {Button, Callout, Link, Stack, Text, theme} from '@pleo-io/telescope'
import {ArrowLeft} from '@pleo-io/telescope-icons'

import type {DispatchActions, Step} from '@product-web/feature--ui-guides/guide'
import {getHelpCentreArticleLinkIntercom} from '@product-web/shared--locale/helpers'

import {SendButton} from './send-button'

import MultientityBuildingsConnectedIllustration from '../../../../images/multi-entity-buildings-connected2.svg'

export const useGroupStep = ({isOrganizationAdmin}: {isOrganizationAdmin: boolean}): Step => {
    const [hasError, setHasError] = React.useState(false)

    return {
        key: 'group',
        illustrationBgColor: theme.colors.pink200,
        title: (
            <Text variant="4xlarge-accent" as="h3" align="left">
                <Trans>Request to group companies</Trans>
            </Text>
        ),
        sections: [
            {
                key: 'description',
                content: (
                    <Stack stretch space={24}>
                        <Text as="p" align="left">
                            <Trans>
                                Grouping of the companies that are already using Pleo requires the
                                support of your account manager.
                            </Trans>
                        </Text>
                        <Text as="p" align="left">
                            <Trans>
                                Send us a request, and they will contact you to get you set up.{' '}
                                <Link
                                    href={getHelpCentreArticleLinkIntercom(
                                        '6394286-what-is-multi-entity-management#h_651d9799c9',
                                    )}
                                    target="_blank"
                                >
                                    Learn more
                                </Link>
                            </Trans>
                        </Text>
                        {hasError && (
                            <Callout variant="negative">
                                <Callout.Text align="left">
                                    <Trans>
                                        There was a problem with sending your request. Please try
                                        again. If the problem continues, contact our support.
                                    </Trans>
                                </Callout.Text>
                            </Callout>
                        )}
                    </Stack>
                ),
            },
        ],
        illustration: <img src={MultientityBuildingsConnectedIllustration} alt="" />,
        actions: [
            {
                key: 'back',
                action: ({onPreviousStep}: DispatchActions) => (
                    <Button variant="secondary" IconLeft={ArrowLeft} onClick={onPreviousStep}>
                        <Trans>Back</Trans>
                    </Button>
                ),
            },
            {
                key: 'send',
                action: ({onNextStep}: DispatchActions) => (
                    <SendButton
                        isOrganizationAdmin={isOrganizationAdmin}
                        onNextStep={onNextStep}
                        onChangeErrorStatus={setHasError}
                    />
                ),
            },
        ],
    }
}
