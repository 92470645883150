import Locale from '@product-web/shared--locale/locale'

import type {ICountryConfiguration} from './types'

import {Terms} from '../features/terms/types'
import {TravelInsurance} from '../features/travel-insurance/types'
import {Feature} from '../types'

const DE: ICountryConfiguration = {
    [Feature.Terms]: {
        [Terms.Documents]: {
            privacyPolicy: {
                [Locale.de_DE]: 'pleo-privacy-policy-de.pdf',
            },
        },
    },
    [Feature.TravelInsurance]: {
        [TravelInsurance.ClaimInformation]: {
            policyDocumentUrl: 'terms-pleo-travel-insurance-de.pdf',
            phoneNumber: '+49 3355 6223 008',
        },
    },
    [Feature.Paywall]: {
        isCashBackCountry: true,
        perDiemEnabled: true,
    },
    [Feature.Mileage]: {
        requirePurposeOfMileage: true,
    },
    [Feature.PerDiem]: {
        requirePurposeOfPerDiem: true,
        canUseWebPerDiem: true,
        canSetOvernightStay: true,
    },
    [Feature.CardOrder]: {
        afterShipTrackingUrl: 'https://getpleode.aftership.com/',
    },
}

export default DE
