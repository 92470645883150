import type {LoggedInAccount} from '@product-web/shared--auth--accounts'

export function getUniqueConnectedAccountEmails(items: LoggedInAccount[] = []) {
    const connectedAccountEmailsObject = items.reduce(
        (acc, item) => {
            if (!item.connectedAccountEmails) {
                return acc
            }

            return item.connectedAccountEmails.reduce((a, conectedAccount) => {
                if (!a[conectedAccount.email]) {
                    a[conectedAccount.email] = conectedAccount
                }

                return a
            }, acc)
        },
        {} as {[key: string]: {email: string; trusted?: boolean}},
    )

    return Object.values(connectedAccountEmailsObject).sort((a, b) =>
        a.email.localeCompare(b.email),
    )
}

export function getExportUrlWithoutExpenseId(url: string) {
    const expenseIdPattern = /\/(?=.*\d)[\w-]+(?:\/activity)?$/
    return url.replace(expenseIdPattern, '')
}
