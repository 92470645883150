import type {ActiveBankAccountDetails} from '@product-web/shared--api-deimos/wallet'
import {useActiveBankAccounts} from '@product-web/shared--api-deimos/wallet'

import {useWalletBalance} from './use-wallet-balance'

// Todo: move the logic to the BFF rails - https://linear.app/pleo/issue/WALLE-4544
export const useActiveBankAccountsWithSameCurrency = () => {
    const {walletBalance} = useWalletBalance()
    const activeBankAccountsResult = useActiveBankAccounts()
    const walletCurrency = walletBalance?.currency

    return {
        ...activeBankAccountsResult,
        data: filterBankAccountsByCurrency(activeBankAccountsResult.data, walletCurrency),
    }
}

function filterBankAccountsByCurrency(
    bankAccounts?: ActiveBankAccountDetails[],
    selectedCurrency?: string,
): ActiveBankAccountDetails[] | undefined {
    if (!bankAccounts) {
        return
    }

    // eslint-disable-next-line consistent-return
    return bankAccounts.filter(({currency}) => selectedCurrency === currency)
}
