import {lazy, Suspense} from 'react'
import {Navigate, Route} from 'react-router-dom'

import {LoadingPage} from '@pleo-io/telescope'

import {roleSets} from '@product-web/shared--user'

import {useSpendActivationTemplates} from './hooks/use-spend-activation-templates'

const VendorCardPage = lazy(async () => import('./screens/vendor-cards'))
const VendorCardDetailsDrawer = lazy(
    async () => import('@product-web/feature--vendor-cards/vendor-card-details-drawer'),
)

const PopularVendorsPage = lazy(
    async () => import('./screens/spend-activation-templates/popular-vendors-page'),
)

const ShowPopularVendorsPage = () => {
    const {
        isEnabled: showPopularVendors = false,
        hasRecurringSpend,
        hasVendorCards,
    } = useSpendActivationTemplates()

    if (!showPopularVendors) {
        return <Navigate to="/vendor-cards" replace />
    }

    return (
        <Suspense fallback={<LoadingPage />}>
            <PopularVendorsPage
                hasRecurringSpend={hasRecurringSpend}
                hasVendorCards={hasVendorCards}
            />
        </Suspense>
    )
}

export const routes = (
    <>
        <Route
            path="/vendor-cards"
            handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}
            element={<VendorCardPage />}
        >
            <Route
                path=":cardId"
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <VendorCardDetailsDrawer />
                    </Suspense>
                }
            >
                <Route
                    path=":expenseId"
                    element={
                        <Suspense fallback={<LoadingPage />}>
                            <VendorCardDetailsDrawer />
                        </Suspense>
                    }
                />
            </Route>
        </Route>
        <Route
            path="create"
            element={<VendorCardPage />}
            handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}
        />
        <Route
            path="popular"
            element={<ShowPopularVendorsPage />}
            handle={{auth: true, allowedRoles: roleSets.ownerAndBookkeeper}}
        />
    </>
)
