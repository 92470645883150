import React, {useContext, useEffect, useState} from 'react'

import {useNavigationWidth} from '@product-web/shared--navigation/navigation-context'

export type NavigationInternalAPI = {
    // We have to optimistically update UI when user switches between entities
    // So we use this property as a single source of truth of companyId
    // for all navigation components
    selectedEntityId: string | null
    setSelectedEntityId: (companyId: string | null) => void
    canChangeEntity: boolean
    setCanChangeEntity: (canChangeCompany: boolean) => void

    navItemsWidth: number
    setNavItemsWidth: (width: number) => void
    entitySwitcherWidth: number
    setEntitySwitcherWidth: (width: number) => void
    isEntitySwitcherVisible: boolean
    setIsEntitySwitcherVisible: (visible: boolean) => void
}

export const NavigationInternalContext = React.createContext<NavigationInternalAPI>({
    canChangeEntity: true,
    setCanChangeEntity: () => {},
    selectedEntityId: null,
    setSelectedEntityId: () => {},
    navItemsWidth: 0,
    setNavItemsWidth: () => {},
    entitySwitcherWidth: 0,
    setEntitySwitcherWidth: () => {},
    isEntitySwitcherVisible: false,
    setIsEntitySwitcherVisible: () => {},
})

const NAVIGATION_ITEMS_WIDTH = 260
const ENTITY_SWITCHER_WIDTH = 260

// this provider handles width of navigation items and entity switcher panels
// and visibility of entity switcher panel
export function InternalNavigationProvider({
    children,
    ...props
}: Partial<NavigationInternalAPI & {children: React.ReactNode}>) {
    const [canChangeEntity, setCanChangeEntity] = useState<boolean>(props.canChangeEntity ?? true)
    const [selectedEntityId, setSelectedEntityId] = useState<string | null>(
        props.selectedEntityId ?? null,
    )
    const [navItemsWidth, setNavItemsWidth] = useState(
        props.navItemsWidth ?? NAVIGATION_ITEMS_WIDTH,
    )
    const [entitySwitcherWidth, setEntitySwitcherWidth] = useState(
        props.entitySwitcherWidth ?? ENTITY_SWITCHER_WIDTH,
    )
    const [isEntitySwitcherVisible, setIsEntitySwitcherVisible] = useState(
        props.isEntitySwitcherVisible ?? false,
    )
    const {setWidth} = useNavigationWidth()

    useEffect(() => {
        setWidth(navItemsWidth + entitySwitcherWidth)
    }, [navItemsWidth, entitySwitcherWidth, setWidth])

    useEffect(() => {
        setWidth(navItemsWidth + (isEntitySwitcherVisible ? entitySwitcherWidth : 0))
    }, [isEntitySwitcherVisible, navItemsWidth, entitySwitcherWidth, setWidth])

    const contextValue: NavigationInternalAPI = {
        canChangeEntity,
        setCanChangeEntity,
        selectedEntityId,
        setSelectedEntityId,
        navItemsWidth,
        setNavItemsWidth,
        entitySwitcherWidth,
        setEntitySwitcherWidth,
        isEntitySwitcherVisible,
        setIsEntitySwitcherVisible,
    }

    return (
        <NavigationInternalContext.Provider value={contextValue}>
            {children}
        </NavigationInternalContext.Provider>
    )
}

export const useInternalNavigationContext = () => {
    return useContext(NavigationInternalContext)
}
