import {SupportedLanguage} from '@product-web/shared--i18n'
import type {HelpCentreArticle} from '@product-web/shared--locale/helpers'
import {
    getHelpCentreArticleLink,
    getLanguageByLocale,
    getLocaleForHelpCentre,
} from '@product-web/shared--locale/helpers'
import Locale from '@product-web/shared--locale/locale'
import {getLocale, useUser} from '@product-web/shared--user'

const COMMERCIAL_SUPPORTED_LANGUAGES = [
    SupportedLanguage.EN,
    SupportedLanguage.DA,
    SupportedLanguage.DE,
    SupportedLanguage.ES,
    SupportedLanguage.SV,
]

const HELP_CENTRE_SUPPORTED_LANGUAGES = [
    SupportedLanguage.DA,
    SupportedLanguage.DE,
    SupportedLanguage.EN,
    SupportedLanguage.ES,
    SupportedLanguage.FR,
    SupportedLanguage.SV,
    SupportedLanguage.FI,
    SupportedLanguage.IT,
    SupportedLanguage.NL,
    SupportedLanguage.PT,
    SupportedLanguage.NO,
]

const getHelpCentreLinkLanguage = (locale: Locale) => {
    let language = getLanguageByLocale(getLocaleForHelpCentre(locale))
    if (!HELP_CENTRE_SUPPORTED_LANGUAGES.includes(language)) {
        language = SupportedLanguage.EN
    }
    return language
}

// TODO: Get rid of this when we have updated the commercial website to use the same locale code. See slack conversation https://getpleo.slack.com/archives/CQBEP9295/p1641215877179400
// and halp ticket https://getpleo.halp.com/tickets/3358
const getCommercialLinkLanguage = (locale: Locale) => {
    const language = getLanguageByLocale(locale)
    if (language === SupportedLanguage.SV) {
        return 'se'
    }

    if (!COMMERCIAL_SUPPORTED_LANGUAGES.includes(language)) {
        return SupportedLanguage.EN
    }

    return language
}

export const usePleoLinks = () => {
    const user = useUser()
    const locale = user ? getLocale(user) : Locale.en_GB
    const commercialLinkLanguage = getCommercialLinkLanguage(locale)
    const helpCentreLanguage = getHelpCentreLinkLanguage(locale)
    const stripLeadingSlash = (path?: string) => (path && path.replace(/^\//, '')) || ''
    return {
        getHelpCentreLink: (article?: HelpCentreArticle) =>
            getHelpCentreArticleLink(article, helpCentreLanguage),
        getCommercialLink: (path?: string) =>
            `https://www.pleo.io/${commercialLinkLanguage}/${stripLeadingSlash(path)}`,
        getBlogLink: (path?: string) =>
            `https://blog.pleo.io/${commercialLinkLanguage}/${stripLeadingSlash(path)}`,
    }
}
