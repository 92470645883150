import type React from 'react'
import useSWR from 'swr'

import {LoadingPage} from '@pleo-io/telescope'

import {request, RequestAuth} from '@product-web/shared--api'
import {getClientSessionId} from '@product-web/shared--auth--client-session'
import {
    useIsLoggedIn,
    useIsLoggedOut,
    useLogout,
    useShouldRefreshSession,
} from '@product-web/shared--auth--session/context'
import config from '@product-web/shared--config'
import {reportError} from '@product-web/shared--error/report'
import type {UserData} from '@product-web/shared--user-types'

import {UserContext} from './user-context'
import {getMutations, UserMutationsContext} from './user-mutations-context'

// This provider wraps the entire app. It's responsible for fetching user's data from the API
// and exposing it to all pages via context.
export const UserProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
    // We only want to fire off the request for user when the user is logged in
    const isLoggedIn = useIsLoggedIn()
    const isLoggedOut = useIsLoggedOut()
    const shouldRefreshSession = useShouldRefreshSession()
    const logout = useLogout()

    // If client session exists, it determines which company the user is currently "working for".
    // This applies only to bookkeepers (independent or via a partner)
    const companyId = getClientSessionId()
    const query = companyId ? {companyId} : {}

    // We're not caching by the companyId query parameter on purpose
    // we don't need to keep cache of multiple user info sets
    const {
        data: user,
        error,
        isValidating,
        mutate,
    } = useSWR<UserData, Error>(
        isLoggedIn ? '/rest/v1/user' : null,
        async (route: string) => {
            const [response, authResponse] = await Promise.all([
                request(`${config.endpoints.api}${route}`, {
                    auth: RequestAuth.USER,
                    method: 'GET',
                    query: {
                        ...query,
                        skipEmployeeCreation: true,
                    },
                }),
                request(`${config.endpoints.auth}${route}`, {
                    auth: RequestAuth.USER,
                    method: 'GET',
                }),
            ])

            return {
                ...response,
                status: {...response.status, isSignUpUser: authResponse?.state?.isSignUpUser},
            }
        },
        {shouldRetryOnError: false, revalidateOnFocus: false},
    )

    // Only render the app once we have user data available
    // or the user is not logged in
    // or not session refresh. This last condition is for new otp session handling
    if (!user && !error && !isLoggedOut && !shouldRefreshSession) {
        return <LoadingPage data-testid="loading-indicator" />
    }

    if (!user && error) {
        logout().catch(reportError)
        return null
    }

    const contextValue = {
        isValidating,
        user,
    }

    return (
        <UserContext.Provider value={contextValue}>
            <UserMutationsContext.Provider value={getMutations(query, mutate)}>
                {children}
            </UserMutationsContext.Provider>
        </UserContext.Provider>
    )
}
