import type React from 'react'
import {useEffect, useRef} from 'react'
import {createPortal} from 'react-dom'
import styled from 'styled-components'
import {useDebounce} from 'use-debounce'

import {Button, ButtonGroup, Card, NakedButton, Stack, Text, tokens} from '@pleo-io/telescope'

import * as tracking from '@product-web/shared--analytics'
import {reportError} from '@product-web/shared--error/report'
import {breakpoints} from '@product-web/shared--styles/theme'
import {useMediaQuery} from '@product-web/shared--web-platform/use-media-query'

import {useAdoptionPrompt} from './adoption-prompt-context'
import {useHelpCentre} from './help-centre-context'

export type PromptSize = 'small' | 'medium'

export type AdoptionPromptProps = {
    /**
     * `name` is a unique identifier which is used to control
     * which one of active prompts is currently visible
     */
    name: string
    title: string | React.ReactElement
    text: string | React.ReactElement
    size: PromptSize
    imageSrc?: string
} & (
    | {
          dismissText: string
          onDismiss: () => void
      }
    | {
          dismissText?: undefined
          onDismiss?: undefined
      }
) &
    (
        | {
              completeText: string
              onComplete: () => void
          }
        | {
              completeText?: undefined
              onComplete?: undefined
          }
    )

/**
 * A reusable adoption prompt component that shows a popup
 * in the bottom right corner of the screen above the ? widget
 */
export const AdoptionPrompt: React.FC<AdoptionPromptProps> = ({
    name,
    title,
    text,
    completeText,
    dismissText,
    imageSrc,
    onComplete,
    onDismiss,
    size,
}) => {
    const {activePrompt, addActivePrompt, removeActivePrompt} = useAdoptionPrompt()

    const isTabletMedUp = useMediaQuery(`(min-width: ${breakpoints.tabletMedUp})`)
    const {isHelpWidgetOpen} = useHelpCentre()

    const isActive = activePrompt?.name === name

    useEffect(() => {
        addActivePrompt({name})
        return () => {
            removeActivePrompt({name})
        }
    }, [addActivePrompt, name, removeActivePrompt])

    useEffect(() => {
        if (isActive) {
            tracking.adoptionPromptActioned({
                prompt_name: name,
                action: 'viewed',
            })
        }
    }, [isActive, name])

    const handleComplete = () => {
        tracking.adoptionPromptActioned({
            prompt_name: name,
            action: 'completed',
        })
        onComplete!()
    }

    const handleDismiss = () => {
        tracking.adoptionPromptActioned({
            prompt_name: name,
            action: 'dismissed',
        })
        onDismiss!()
    }

    // we debounce for 1 second in order to make sure that the portalElement is mounted
    // in case both portalElement and AdoptionPrompt are to be rendered in the same render "loop"
    const [isVisible] = useDebounce(isActive && isTabletMedUp, 1000)

    const portalElement = document.getElementById(PORTAL_ELEMENT_ID)

    useReportMissingPortal({promptName: name, isVisible, portalElement})

    if (!isVisible || !portalElement || isHelpWidgetOpen) {
        return null
    }

    const hasAnyAction = Boolean(completeText || dismissText)

    return createPortal(
        <StyledCard size={size} data-testid="adoption-prompt" space={12} p={16}>
            {imageSrc && <HeadingImage src={imageSrc} size={size} />}

            <Stack space={4}>
                <Text as="h5" color="shade800" variant="large-accent">
                    {title}
                </Text>

                <Text color="shade600" variant="small-subtle">
                    {text}
                </Text>
            </Stack>

            {hasAnyAction && (
                <ButtonGroup>
                    {completeText && (
                        <Button variant="primary" onClick={handleComplete}>
                            {completeText}
                        </Button>
                    )}
                    {dismissText && (
                        <DismissActionButton onClick={handleDismiss}>
                            {dismissText}
                        </DismissActionButton>
                    )}
                </ButtonGroup>
            )}
        </StyledCard>,
        portalElement,
    )
}

const useReportMissingPortal = ({
    promptName,
    isVisible,
    portalElement,
}: {
    promptName: string
    isVisible: boolean
    portalElement: HTMLElement | null
}) => {
    const reportedWarningRef = useRef(false)
    useEffect(() => {
        if (isVisible && !portalElement && !reportedWarningRef.current) {
            reportedWarningRef.current = true
            reportError(
                `Failed to render AdoptionPrompt component. Target Portal node with id "${PORTAL_ELEMENT_ID}" not found.`,
                null,
                {
                    promptName,
                },
            )
        }
    }, [isVisible, promptName, portalElement])
}

const getPromptSize = ({size}: {size: PromptSize}) =>
    ({
        small: 230,
        medium: 290,
    })[size]

const StyledCard = styled(Card)<{size: PromptSize}>`
    box-shadow: ${tokens.shadowElevate};
    max-width: ${getPromptSize}px;
    animation: fadeInLeft ${tokens.slowOut};

    @keyframes fadeInLeft {
        from {
            transform: translateX(100%);
        }

        to {
            transform: translateX(0%);
        }
    }
`

const getImageSize = ({size}: {size: PromptSize}) =>
    ({
        small: 63,
        medium: 82,
    })[size]

const HeadingImage = styled.img<{size: PromptSize}>`
    width: 100%;
    height: ${getImageSize}px;
`

const DismissActionButton = styled(NakedButton)`
    font-size: ${tokens.fontMedium};
    color: ${tokens.shade600};
`

const PORTAL_ELEMENT_ID = 'adoption-prompt-portal'

export const AdoptionPromptPortal = () => {
    return <div id={PORTAL_ELEMENT_ID} />
}
