import {useMemo, useState} from 'react'
import {useNavigate} from 'react-router'

import {useHasAWalletLoad} from '@product-web/feature--funds-management/use-has-a-wallet-load'
import {useFlags} from '@product-web/shared--flags'
import {useCompanySubscription} from '@product-web/shared--paywall/lib/company-subscription'
import {PricingPlanModalWrapper} from '@product-web/shared--paywall/pricing-plan-modal/pricing-plan-modal-wrapper'
import {PlanUpgradeSource} from '@product-web/shared--paywall/types/plans'
import {getIsCompanyOwner, getIsFdd, useUser} from '@product-web/shared--user'

import {TrialBanner} from './banner'
import {DEFAULT_TRIAL_LENGTH, getDatesDiffInDays} from './banner.helper'

import {bff} from '../bff-hooks'
import {HowTrialWorksGuide} from '../how-trial-works/how-trial-works'

type TrialBannerFlagContainerProps = {
    hidePreTrialBanners?: boolean
}

export const TrialBannerFlagContainer = ({hidePreTrialBanners}: TrialBannerFlagContainerProps) => {
    const {trialBannerModals} = useFlags()
    const user = useUser()
    const isCompanyOwner = getIsCompanyOwner(user)

    if (!trialBannerModals || !isCompanyOwner) {
        return null
    }

    return <TrialBannerContainer hidePreTrialBanners={hidePreTrialBanners} />
}

type TrialBannerContainerProps = {
    hidePreTrialBanners?: boolean
}

const TrialBannerContainer = ({hidePreTrialBanners}: TrialBannerContainerProps) => {
    const navigate = useNavigate()
    const user = useUser()
    const {data: subscription} = useCompanySubscription()
    const {hasAWalletLoad} = useHasAWalletLoad()
    const [isHowTrialWorksGuideOpen, setIsHowTrialWorksGuideOpen] = useState(false)
    const [isChoosePlansModalOpen, setIsChoosePlansModalOpen] = useState(false)

    const trialLength = useMemo(
        () =>
            getDatesDiffInDays(
                subscription?.trialPeriod?.inclusiveStartDate,
                subscription?.trialPeriod?.exclusiveEndDate,
            ) || DEFAULT_TRIAL_LENGTH,
        [subscription?.trialPeriod],
    )
    const isFdd = getIsFdd(user)

    const isDuringTrial = subscription?.isInTrialPeriod && hasAWalletLoad
    const isBeforeTrial = !isFdd || (subscription?.isInTrialPeriod && !hasAWalletLoad)
    const shouldShowTrialBanner = Boolean((isBeforeTrial && !hidePreTrialBanners) || isDuringTrial)

    const {data: trialBannerPricingPlan} =
        bff.featureOnboarding.trialBanner.getTrialBannerPricingPlan.useQuery(undefined, {
            enabled: shouldShowTrialBanner,
        })

    return (
        <div data-testid="trial-banner-container">
            {shouldShowTrialBanner && (
                <TrialBanner
                    pricingPlan={trialBannerPricingPlan}
                    isBeforeTrial={isBeforeTrial}
                    isDuringTrial={isDuringTrial}
                    trialEndDate={subscription?.trialPeriod?.exclusiveEndDate}
                    handleCta={(modal) => {
                        if (modal === 'how-trial-works') {
                            setIsHowTrialWorksGuideOpen(true)
                        }
                        if (modal === 'choose-plans') {
                            setIsChoosePlansModalOpen(true)
                        }
                    }}
                />
            )}
            {isHowTrialWorksGuideOpen && (
                <HowTrialWorksGuide
                    onDone={() => {
                        setIsHowTrialWorksGuideOpen(false)
                        navigate('/wallet')
                    }}
                    onDismiss={() => {
                        setIsHowTrialWorksGuideOpen(false)
                    }}
                    trialLength={trialLength}
                />
            )}
            {isChoosePlansModalOpen && (
                <PricingPlanModalWrapper
                    isOpen={isChoosePlansModalOpen}
                    onDismiss={() => setIsChoosePlansModalOpen(false)}
                    planUpgradeSource={PlanUpgradeSource.TRIAL_BANNER}
                    pageOrigin="trial"
                    pageSection="trial-banner"
                />
            )}
        </div>
    )
}
