import {useCallback} from 'react'
import useSWR from 'swr'

import {request} from '@product-web/shared--api'
import config from '@product-web/shared--config'
import {useUser} from '@product-web/shared--user'

import {getDeimos} from './helpers'

const baseUrl = config.endpoints.api
export interface StorageItemResponse<T = string> {
    key: string
    value: T | null
}

export async function getStorageItem({
    key,
    companyId,
}: {
    key: string
    companyId: string
}): Promise<StorageItemResponse> {
    return request(`${baseUrl}/rest/v1/storage/${key}`, {
        auth: 'user',
        method: 'GET',
        query: {companyId},
    })
}

/**
 * @deprecated
 * As the exploration to migrate Deimo’s storage DB is underway, it is recommended not to add new keys to the storage.
 * If you have a requirement to add new keys, please reach out to the #killing-deimos-general channel.
 */
export async function setStorageItem({
    key,
    value,
    companyId,
    membership,
    sendNotification,
}: {
    key: string
    value: string | null
    companyId: string
    membership: string
    sendNotification: boolean
}) {
    return request(`${baseUrl}/rest/v1/storage/${key}`, {
        auth: 'user',
        method: 'PUT',
        body: {value, membership},
        query: {companyId, sendNotification},
    })
}

async function removeStorageItem(key: string, companyId?: string) {
    return request(`${baseUrl}/rest/v1/storage/${key}`, {
        auth: 'user',
        method: 'PUT',
        body: {value: null},
        query: {companyId},
    })
}

export function useDeimosStorage(key: string) {
    const user = useUser()
    const companyId = user?.companyId
    const membership = 'member'
    const result = useSWR<StorageItemResponse<typeof key> | null, Error>(
        companyId ? `/rest/v1/storage/${key}?companyId=${companyId}` : null,
        getDeimos,
    )

    /**
     * @deprecated
     * As the exploration to migrate Deimo’s storage DB is underway, it is recommended not to add new keys to the storage.
     * If you have a requirement to add new keys, please reach out to the #killing-deimos-general channel.
     */
    const setItem = useCallback(
        async (value: string | null) => {
            if (!companyId) {
                return
            }
            await setStorageItem({
                key,
                value,
                companyId,
                membership,
                sendNotification: false,
            })
            result.mutate()
        },
        [companyId, key, result],
    )

    const removeItem = useCallback(async () => {
        await removeStorageItem(key, companyId)
        result.mutate()
    }, [companyId, key, result])
    const {data, error} = result
    const isLoading = !data && !error

    return {result, isLoading, mutations: {setItem, removeItem}}
}

export function useDeimosStorageWithCompanyId<T = string>(key: string, companyId = '') {
    const membership = 'member'
    const result = useSWR<StorageItemResponse<T> | null, Error>(
        companyId ? `/rest/v1/storage/${key}?companyId=${companyId}` : null,
        getDeimos,
    )

    /**
     * @deprecated
     * As the exploration to migrate Deimo’s storage DB is underway, it is recommended not to add new keys to the storage.
     * If you have a requirement to add new keys, please reach out to the #killing-deimos-general channel.
     */
    async function setItem(value: string) {
        await setStorageItem({
            key,
            value,
            companyId,
            membership,
            sendNotification: false,
        })
        result.mutate()
    }

    async function removeItem() {
        await removeStorageItem(key, companyId)
        result.mutate()
    }

    return {result, mutations: {setItem, removeItem}}
}
