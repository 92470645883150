import {useEffect} from 'react'
import {Outlet, useNavigate} from 'react-router'

import {LoadingPage} from '@pleo-io/telescope'

import {useFlags, useFlagsLoaded} from '@product-web/shared--flags'

export default function CashManagementScreenWrapper() {
    const navigate = useNavigate()
    const featureFlagsLoaded = useFlagsLoaded()
    const {cashManagementFlow} = useFlags()

    useEffect(() => {
        // todo: switch to the BE calculated Wallet feature flag
        if (featureFlagsLoaded && !cashManagementFlow) {
            // FYI: there is a stupid behaviour with Launch Darkly flags initialisation
            // I didn't spend time trying to dig deeper and fix it,
            // because we are going to switch to the BE Wallet feature flag solution
            // In order to open the Cash Management page, we need to load the Pleo app (any page)
            // and then open the Cash Management via Navigation bar.
            // Or simply disable the next line ;)
            navigate('/wallet')
            return
        }
    }, [featureFlagsLoaded, cashManagementFlow, navigate])

    if (!featureFlagsLoaded) {
        return <LoadingPage />
    }

    return <Outlet />
}
