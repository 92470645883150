import {t, Trans} from '@lingui/macro'
import type React from 'react'
import styled from 'styled-components'
import {useClipboard} from 'use-clipboard-copy'

import {
    Avatar,
    Inline,
    Loading,
    NakedButton,
    Popover,
    Stack,
    Text,
    tokens,
} from '@pleo-io/telescope'
import {Copy, Warning} from '@pleo-io/telescope-icons'

import {PeopleChip} from '@product-web/shared--telescope-lab/people-chip/people-chip'
import {useToaster} from '@product-web/shared--toaster'
import {getFullName} from '@product-web/shared--utils'

import {StyledIconText} from './tag-budget-details-drawer'

import {bff} from '../../bff'
import type {TagBudgetReviewer} from '../../index.bff'

interface TagBudgetReviewerItemProps {
    reviewer: TagBudgetReviewer
    isMultiEntity: boolean
}

const TagBudgetReviewerItem: React.FC<React.PropsWithChildren<TagBudgetReviewerItemProps>> = ({
    reviewer,
    isMultiEntity,
}) => {
    const {showToast} = useToaster()

    const {copy: copyToClipboard} = useClipboard({
        onSuccess: () => showToast(t`Copied to clipboard`, {level: 'success'}),
        onError: () => showToast(t`Copy failed`, {level: 'error'}),
    })

    const reviewerName = getFullName(reviewer)
    const handleCopyEmail = () => copyToClipboard(reviewer.email)

    return (
        <Stack space={4} stretch css={{maxWidth: '90vw'}}>
            <Inline space={12} paddingRight={24} alignItems={'center'} stretchChildren>
                <Avatar size={40} src={reviewer.avatar?.url} name={reviewerName} />
                <Stack stretch>
                    <Text variant="medium-default" weight="regular" color={'shade700'}>
                        {reviewerName}
                    </Text>
                    <Inline space={6} alignItems={'baseline'}>
                        {isMultiEntity && (
                            <Text variant="small-subtle" weight="regular" color="shade600">
                                {reviewer.companyName} ·
                            </Text>
                        )}
                        <NakedButton onClick={handleCopyEmail}>
                            <Inline alignItems={'center'} space={6}>
                                <Text variant="small-subtle" weight="regular" color="shade600">
                                    <Trans>Email</Trans>
                                </Text>
                                <Copy cursor={'pointer'} color={tokens.shade700} size={16} />
                            </Inline>
                        </NakedButton>
                    </Inline>
                </Stack>
            </Inline>
        </Stack>
    )
}

interface TagBudgetReviewersPopoverProps {
    reviewers: string[]
    isMultiEntity: boolean
}

export const TagBudgetReviewersPopover: React.FC<
    React.PropsWithChildren<TagBudgetReviewersPopoverProps>
> = ({reviewers, isMultiEntity}) => {
    const {showToast} = useToaster()

    const hasBudgetReviewers = reviewers.length !== 0

    const {data: tagBudgetReviewers, isLoading} = bff.tagBudgetDetails.getReviewersDetails.useQuery(
        {reviewersIds: reviewers, isMultiEntity},
        {
            enabled: hasBudgetReviewers,
            onError() {
                showToast(t`Failed to fetch data`, {level: 'error'})
            },
        },
    )

    if (!hasBudgetReviewers) {
        return (
            <ReviewersWrapper padding={32}>
                <StyledIconText
                    label={t`No reviewers`}
                    icon={<Warning size={16} color={tokens.shade900} mr={4} />}
                />
            </ReviewersWrapper>
        )
    }

    if (isLoading) {
        return (
            <ReviewersWrapper padding={32}>
                <Loading />
            </ReviewersWrapper>
        )
    }

    if (tagBudgetReviewers?.length === 0) {
        return null
    }

    const people = tagBudgetReviewers?.map((reviewer) => {
        return {
            firstName: reviewer?.firstName,
            lastName: reviewer?.lastName,
            src: reviewer?.avatar?.url ?? '',
        }
    })

    return (
        <ReviewersWrapper padding={32}>
            <Popover>
                <Popover.Trigger>
                    <PeopleChip people={people ?? []} label={t`Owners:`} />
                </Popover.Trigger>
                <Popover.Content sideOffset={10} side="top">
                    <Stack margin={16} space={20} stretch>
                        {tagBudgetReviewers?.map((reviewer, index) => (
                            <TagBudgetReviewerItem
                                key={`${reviewer.id}${index}`}
                                reviewer={reviewer}
                                isMultiEntity={isMultiEntity}
                            />
                        ))}
                    </Stack>
                </Popover.Content>
            </Popover>
        </ReviewersWrapper>
    )
}

const ReviewersWrapper = styled(Stack)`
    position: absolute;
    bottom: 0;
    height: 104px;
    background-color: ${tokens.shade100};
    align-content: center;
`
