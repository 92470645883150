import {useEffect} from 'react'

import config from '@product-web/shared--config'
import {useHubspotConsent} from '@product-web/shared--consent/hubspot'
import {loadScript} from '@product-web/shared--web-platform/load-script'

/**
 * @file Handles loading the Segment analytics script.
 *
 * @overview
 * Initializes `window.analytics` and dynamically loads `analytics.js`, ensuring it runs only with user consent.
 * The `analytics.load()` method has been stripped from `index.html` and is now invoked dynamically.
 *
 * @notes
 * - Skips loading in Datadog Synthetic Browser environments (feature parity with Segment snippet).
 * - Prevents multiple loads using an internal flag.
 */

type SegmentAnalytics = typeof window.analytics

type SegmentOpts = Parameters<SegmentAnalytics['load']>[1]

type SegmentAnalyticsConfig = SegmentAnalytics & {
    _loadOptions: SegmentOpts | undefined
    _writeKey: string
    _cdn: string
    SNIPPET_VERSION: string

    // [internal] Flag to prevent multiple loads
    ___hasSegmentLoadBeenInvoked: boolean
}

/**
 * Asynchronously loads and initializes the Segment analytics script.
 * We intentionally replicate the loading logic here from the Segment snippet.
 */
const loadSegmentAnalytics = () => {
    // Initialize the analytics object
    const analytics = (window.analytics = window.analytics || []) as SegmentAnalyticsConfig

    // If the script has already been loaded, do not load it again
    if (analytics.___hasSegmentLoadBeenInvoked) {
        return
    }

    // Set our internal flag to prevent multiple loads
    analytics.___hasSegmentLoadBeenInvoked = true

    // Define the load method
    analytics.load = (writeKey: string, options?: SegmentOpts): void => {
        analytics._loadOptions = options
        loadScript({
            src: `https://${config.segment.host}/analytics.js/v1/${writeKey}/analytics.min.js`,
            asynchronous: true,
            prependScript: true,
        })
    }

    // Replicate the Segment snippet
    analytics._writeKey = config.segment.key
    analytics._cdn = `https://${config.segment.host}`
    analytics.SNIPPET_VERSION = '4.15.3'

    // Load analytics script if not running in Datadog Synthetic Browser
    if (window._DATADOG_SYNTHETICS_BROWSER === undefined) {
        analytics.load(config.segment.key)
    }
}

/**
 * Hook to determine if Segment analytics should be enabled based on user consent.
 *
 * If the user has not consented to marketing analytics, the script will not be loaded.
 */
const useIsAnalyticsEnabled = () => {
    // Get user consent preferences & initialisation status
    const {isInitialised, consentPreferences} = useHubspotConsent()

    // Determine if user has consented to marketing analytics
    const userHasConsentedToMarketing = consentPreferences.categories.advertisement

    // Determine if analytics is enabled
    const isAnalyticsEnabled = isInitialised && userHasConsentedToMarketing

    return {isAnalyticsEnabled}
}

/**
 * Hook to load the Segment analytics script only after user consent is granted.
 * Prevents tracking until consent is obtained.
 */
export const useLoadAnalyticsWithConsent = () => {
    const {isAnalyticsEnabled} = useIsAnalyticsEnabled()

    useEffect(() => {
        if (!isAnalyticsEnabled) {
            return
        }

        loadSegmentAnalytics()
    }, [isAnalyticsEnabled])
}
