/* eslint-disable string-to-lingui/missing-lingui-transformation */
import type {ApiKeyModel} from '@product-web/shared--bff-moons/generated/external-auth-service'

export type APIKey = {
    id: string
    /** The actual content of the key, visible only after creation */
    key?: string
    /** Human-readable label */
    name: string
    /** Date when the key was created */
    createdAt: Date
    /** Date when the key will expire */
    expiresAt?: Date
    /** List of OAuth scopes this key has */
    scopes: string[]
    /** URN of an entity this key belongs to: company or organisation */
    owner: string
    /** URN of a entity this key grants access to: company or organisation */
    subject: string
}

export const NULL_API_KEY: APIKey = {
    id: '',
    name: '',
    createdAt: new Date(),
    scopes: [],
    owner: '',
    subject: '',
}

export function fromApiKeyModel(model: ApiKeyModel): APIKey {
    return {
        id: model.id,
        key: model.apiKey,
        name: model.name,
        createdAt: new Date(model.createdAt),
        expiresAt: model.expiresAt ? new Date(model.expiresAt) : undefined,
        scopes: model.scope.split(' '),
        owner: model.owner,
        subject: model.resource,
    }
}
