import tracking from '@product-web/shared--analytics'

export enum FundsManagementTrackAction {
    STARTED = 'started',
    CHANGED = 'changed',
    ABANDONED = 'abandoned',
    COMPLETED = 'completed',
    ERROR = 'error',
    STEP_FORWARD = 'step forward',
}

// specifically actions for wallet history
export enum TrackHistoryAction {
    CLICK_TO_NAVIGATE = 'click to navigate',
}

export enum TrackWithdrawalButtonAction {
    SHOW = 'show',
    CLICK = 'click',
}

export const trackAutoTopup = (
    action: FundsManagementTrackAction,
    threshold?: number,
    topUpAmount?: number,
    autoTopUpEnabled?: boolean,
    eligibleForPleoReserve?: boolean,
    isDirectDebitEnabled?: boolean,
) =>
    tracking.walletTurnOnAutoTopupActioned({
        action,
        threshold,
        top_up_amount: topUpAmount,
        enabled: autoTopUpEnabled,
        eligible_for_pleo_reserve: eligibleForPleoReserve,
        is_direct_debit_enabled: isDirectDebitEnabled,
    })

export const trackLowBalanceAlert = (
    action: FundsManagementTrackAction,
    amount?: number,
    type?: string,
) =>
    tracking.walletLowBalanceAlertActioned({
        action,
        amount,
        type,
    })

export const trackBalanceStatement = (action: FundsManagementTrackAction) =>
    tracking.walletBalanceStatementActioned({action})

export const trackWalletActivitiesHistory = (
    action: FundsManagementTrackAction | TrackHistoryAction,
) => tracking.walletActivitiesHistoryActioned({action})

export const trackSetupDirectDebit = () => tracking.walletAutoTopUpSetupDirectDebitActioned()

export const trackManualTopUpDirectDebit = (action: FundsManagementTrackAction, amount?: number) =>
    tracking.walletManualTopUpDirectDebitActioned({action, amount})

export const trackManualTopUpDirectDebitEdit = (action: FundsManagementTrackAction) =>
    tracking.walletManualAutoTopUpDirectDebitMandateEditActioned({action})

export const trackManualTopUpDirectDebitDelete = (action: FundsManagementTrackAction) =>
    tracking.walletManualTopUpDirectDebitDeleteActioned({action})

export const trackBankTransfer = (action: string) =>
    tracking.walletBankTransferModalActioned({action})

export enum TrackWalletTopUpAction {
    CLICK_TOP_UP_BUTTON = 'click-top-up-button',
    SELECT_TOP_UP_METHOD = 'select-top-up-method',
}

type TrackWalletTopUpActionParams = {
    action: TrackWalletTopUpAction.CLICK_TOP_UP_BUTTON
}

type TrackWalletTopUpSelectParams = {
    action: TrackWalletTopUpAction.SELECT_TOP_UP_METHOD
    topUpType: 'direct-debit' | 'bank-transfer'
}

type TrackWalletTopUpParams = TrackWalletTopUpActionParams | TrackWalletTopUpSelectParams

export const trackWalletTopUp = (params: TrackWalletTopUpParams) =>
    tracking.walletTopUpActioned(params)
