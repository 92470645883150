import {t, Trans} from '@lingui/macro'
import cn from 'classnames'
import React from 'react'
import styled from 'styled-components'

import type {CurrencyType} from '@pleo-io/deimos'
import {
    Button,
    Callout,
    Inline,
    Link,
    Link as TelescopeLink,
    Loading,
    Modal,
    ModalClose,
    ModalContent,
    ModalFooter,
    ModalTitle,
    Stack,
    Text,
    tokens,
} from '@pleo-io/telescope'
import {ArrowLeft, ChevronDown, Clock} from '@pleo-io/telescope-icons'

import tracking from '@product-web/shared--analytics'
import type {StaticTransferField} from '@product-web/shared--api-types/static-transfer-field'
import {useFlags} from '@product-web/shared--flags'
import {formatCurrency} from '@product-web/shared--locale/currency'
import {getHelpCentreArticleLinkIntercom} from '@product-web/shared--locale/helpers'
import {Copyable} from '@product-web/shared--telescope-lab/copyable/copyable'
import {useToaster} from '@product-web/shared--toaster'

import {LuxembourgAccountDisclaimer} from './luxembourg-account-disclaimer'
import styles from './static-transfer-details.module.css'
import TransferField from './transfer-field'

import {featureFundsManagementBff} from '../bff-hooks'
import {ContactSupportLink} from '../components/contact-support-link'
import type {ReserveAmountOwed} from '../credit/bff/pleo-reserve.helpers.bff'
import {usePleoReserveCollections} from '../credit/pleo-reserve-collections'
import {FundsManagementTrackAction} from '../tracking'
import {
    getInternationalLabelMap,
    getLocalLabelMap,
    transformDetails,
} from '../transfer-field-mapper'

const USD_DAILY_LIMIT = 40000
const USD_MONTHLY_LIMIT = 100000

const BackButton = styled(Button)`
    position: absolute;
    top: ${tokens.spacing10};
    left: ${tokens.spacing10};
`

interface TransferFieldsProps {
    labels: {[key: string]: string | undefined}
    values: {[key: string]: string | null | undefined}
    rawValues: {[key: string]: string | null | undefined}
    crossedFields?: string[]
}

const TransferFields = ({labels, values, rawValues, crossedFields}: TransferFieldsProps) => {
    return (
        <Stack space={20} align="center">
            {Object.keys(labels).map((key: string) =>
                values[key] && rawValues[key] ? (
                    <TransferField
                        key={key}
                        label={labels[key] as string}
                        value={values[key] as string}
                        rawValue={rawValues[key] as string}
                        isCrossed={Boolean(crossedFields?.includes(key))}
                    />
                ) : null,
            )}
        </Stack>
    )
}

interface InternationalToggleProps {
    onClick: () => void
}

const InternationalToggle: React.FC<InternationalToggleProps> = ({onClick}) => {
    return (
        <Text
            as="p"
            space={10}
            className={cn(styles.toggle)}
            data-testid="show-international-toggle"
            onClick={onClick}
        >
            <span>
                <Trans>Show international transfer details</Trans>
            </span>
            &nbsp;
            <ChevronDown className={cn(styles.toggleIcon)} />
        </Text>
    )
}

export const StaticTransferDetails = ({
    currency,
    transferDetails,
    reserveAmountOwed,
    isReserveInCollections,
    crossedDetailsFields,
}: {
    currency: CurrencyType
    transferDetails?: StaticTransferField
    reserveAmountOwed: ReserveAmountOwed
    isReserveInCollections?: boolean
    crossedDetailsFields?: string[]
}) => {
    const [showInternational, setShowInternational] = React.useState(false)
    const toggleShowInternational = () => setShowInternational(!showInternational)

    if (!transferDetails) {
        return null
    }

    const {values, rawValues} = transformDetails(currency, transferDetails)
    const localLabels = getLocalLabelMap(currency)
    const internationalLabels = getInternationalLabelMap(currency)

    return (
        <Stack space={20} align="center">
            {isReserveInCollections && (
                <TransferField
                    label={t`Amount`}
                    value={formatCurrency(reserveAmountOwed.value, reserveAmountOwed.currency)}
                    rawValue={reserveAmountOwed.value.toString()}
                />
            )}
            <TransferFields
                labels={localLabels}
                values={values}
                rawValues={rawValues}
                crossedFields={crossedDetailsFields}
            />
            {Boolean(Object.keys(internationalLabels).length && !showInternational) && (
                <InternationalToggle onClick={toggleShowInternational} />
            )}
            {showInternational && (
                <TransferFields
                    labels={internationalLabels}
                    values={values}
                    rawValues={rawValues}
                />
            )}
        </Stack>
    )
}

const TOP_UP_HELP_LINK = getHelpCentreArticleLinkIntercom('3876873-how-to-top-up-your-pleo-wallet')

const WORKAROUND_DE_VIBAN = 'DE93501108006161618027'
const WORKAROUND_DE_BIC = 'CHASDEFX'
const WRONG_DETAILS_FIELDS_TO_CROSS = ['iban', 'bic']

export const GermanVibanDisclaimer: React.FC = () => {
    const {showToast} = useToaster()

    return (
        <Callout variant="warning">
            <Stack css={{textAlign: 'left'}} space={8}>
                <div>
                    <Text weight="bold">
                        <Trans>Temporary change to bank details</Trans>
                    </Text>
                    <Text align="left">
                        <Trans>
                            Due to an issue with our partner bank, we cannot accept top-ups using
                            your regular method.
                        </Trans>
                    </Text>
                </div>
                <Text align="left">
                    <Trans>To top-up, use the following details:</Trans>
                </Text>
                <div>
                    <Text weight="bold">
                        <Trans>IBAN:</Trans>
                    </Text>
                    <Copyable
                        valueToCopy={WORKAROUND_DE_VIBAN}
                        callback={({message, isSuccess}) => {
                            showToast(message, {level: isSuccess ? 'success' : 'error'})
                        }}
                    >
                        <Text>{WORKAROUND_DE_VIBAN}</Text>
                    </Copyable>
                </div>
                <div>
                    <Text weight="bold">
                        <Trans>BIC:</Trans>
                    </Text>
                    <Copyable
                        valueToCopy={WORKAROUND_DE_BIC}
                        callback={({message, isSuccess}) => {
                            showToast(message, {level: isSuccess ? 'success' : 'error'})
                        }}
                    >
                        <Text>{WORKAROUND_DE_BIC}</Text>
                    </Copyable>
                </div>
                <div>
                    <Text weight="bold">
                        <Trans>Reference</Trans>
                    </Text>
                    <Text>
                        <Trans>Your regular IBAN and BIC (found below)</Trans>
                    </Text>
                </div>
                <div>
                    <Text weight="bold">
                        <Trans>Once transferred</Trans>
                    </Text>
                    <Text>
                        <Trans>
                            <ContactSupportLink /> with a screenshot of the transfer.
                        </Trans>
                    </Text>
                </div>
                <TelescopeLink inherit target="_blank" href="https://status.pleo.io">
                    <Trans>Check status of outage</Trans>
                </TelescopeLink>
            </Stack>
        </Callout>
    )
}

const DESCRIPTION_BY_CURRENCY_CODE: Record<CurrencyType, () => string | undefined> = {
    GBP: () => t`Use Faster Payments for the quickest transfer, supported 24/7.`,
    SEK: () => t`Transfers sent using fastest transfer method usually arrive by next business day.`,
    NOK: () => t`Transfers sent using fastest transfer method usually arrive by next business day.`,
    DKK: () => t`Use Straksoverførsel for the quickest transfer.`,
    EUR: () => t`Use SEPA Credit Transfer or SEPA Instant to top up.`,
    USD: () => '',
}

export const StaticTransferDetailsModalContent: React.FC<{
    closeModal: () => void
    children?: React.ReactNode
    hideFirstTransferHelp?: boolean
}> = ({closeModal, children, hideFirstTransferHelp}) => {
    const {vibanDe: isGermanVibanDisclamerEnabled} = useFlags()

    const {data, isInitialLoading} =
        featureFundsManagementBff.topUp.getStaticTransferDetails.useQuery()

    const {finishTopUp} = usePleoReserveCollections()

    if (!data || isInitialLoading) {
        return (
            <Stack align="center" padding={28}>
                <Loading />
            </Stack>
        )
    }

    const currency = data.currency as CurrencyType
    const {
        companyCountry,
        transferDetails,
        hasWalletActivities,
        isReserveInCollections,
        reserveAmountOwed,
    } = data

    const shouldShowGermanVibanDisclaimer = Boolean(
        isGermanVibanDisclamerEnabled && transferDetails?.iban?.startsWith('DE'),
    )

    const crossedDetailsFields = shouldShowGermanVibanDisclaimer
        ? WRONG_DETAILS_FIELDS_TO_CROSS
        : undefined

    const description = DESCRIPTION_BY_CURRENCY_CODE[currency]?.() ?? ''

    return (
        <>
            <ModalTitle>
                {currency === 'USD' ? (
                    <Trans>ACH Bank transfer</Trans>
                ) : hasWalletActivities || hideFirstTransferHelp ? (
                    <Trans>Bank transfer</Trans>
                ) : (
                    <Trans>Top up your wallet with a bank transfer</Trans>
                )}
            </ModalTitle>
            <ModalContent>
                <Stack space={20} align="center">
                    {shouldShowGermanVibanDisclaimer && <GermanVibanDisclaimer />}

                    <Text as="p" space={20} color="shade600">
                        {currency === 'USD' ? (
                            <Trans>
                                Please, note that Pleo observes single transfer limit and daily
                                limit for {formatCurrency(USD_DAILY_LIMIT, currency)} and monthly
                                limit for {formatCurrency(USD_MONTHLY_LIMIT, currency)}
                            </Trans>
                        ) : hasWalletActivities || hideFirstTransferHelp ? (
                            <Trans>
                                Funds must come from your company's business bank account and not
                                from a private account.
                            </Trans>
                        ) : (
                            <Trans>
                                The first transfer to your Pleo account must be made from your
                                company's business bank account. You can transfer any amount, but we
                                recommend adding what your company usually spends in a month.
                            </Trans>
                        )}
                    </Text>

                    <LuxembourgAccountDisclaimer onNavigate={closeModal} />

                    <StaticTransferDetails
                        currency={currency}
                        transferDetails={transferDetails}
                        crossedDetailsFields={crossedDetailsFields}
                        isReserveInCollections={isReserveInCollections}
                        reserveAmountOwed={reserveAmountOwed}
                    />

                    {companyCountry !== 'US' && (
                        <Text variant="medium-default" color="shade600">
                            <Trans>
                                Additional bank information can be found{' '}
                                <Link
                                    data-testid="additional-information-link"
                                    inherit
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={TOP_UP_HELP_LINK}
                                >
                                    here
                                </Link>
                                .
                            </Trans>
                        </Text>
                    )}

                    {isReserveInCollections && (
                        <Inline space={20}>
                            <Button variant="secondary" onClick={closeModal}>
                                <Trans>Cancel</Trans>
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    finishTopUp()
                                    closeModal()
                                }}
                            >
                                <Trans>I've transferred it</Trans>
                            </Button>
                        </Inline>
                    )}

                    {children}
                </Stack>
            </ModalContent>

            <ModalFooter align="center" css={{padding: tokens.spacing20}}>
                <Stack align="center">
                    <Text variant="small-subtle" color="shade700">
                        {description}
                    </Text>

                    <Text variant="small-subtle" color="shade700">
                        <Trans>
                            These are your permanent transfer details and can be saved in your bank
                            for future reference.
                        </Trans>
                    </Text>

                    {!hasWalletActivities && (
                        <Inline space={8} align="center center">
                            <Clock size={16} />
                            <Text variant="small-subtle" color="shade700">
                                <Trans>
                                    Your first transfer should show in your wallet within a day
                                </Trans>
                            </Text>
                        </Inline>
                    )}
                </Stack>
            </ModalFooter>
        </>
    )
}

interface ModalConnectedProps {
    isOpen: boolean
    onClose: () => void
    options?: {onBackClick?: () => void}
}

export const StaticTransferDetailsModal: React.FC<ModalConnectedProps> = ({
    isOpen,
    onClose,
    options,
}) => {
    React.useEffect(() => {
        if (isOpen) {
            tracking.walletBankTransferModalActioned({action: FundsManagementTrackAction.STARTED})
        }
    }, [isOpen])

    const closeModal = () => {
        tracking.walletBankTransferModalActioned({action: FundsManagementTrackAction.COMPLETED})
        onClose()
    }

    return (
        <Modal isOpen={isOpen} onDismiss={closeModal}>
            <ModalClose onClick={closeModal} />

            {options && options.onBackClick && (
                <BackButton
                    IconLeft={ArrowLeft}
                    variant="tertiary"
                    onClick={() => {
                        closeModal()
                        options.onBackClick!()
                    }}
                >
                    <Trans>back</Trans>
                </BackButton>
            )}

            <StaticTransferDetailsModalContent closeModal={closeModal} />
        </Modal>
    )
}

export default StaticTransferDetailsModal
