import {Navigate, useLocation} from 'react-router-dom'

import {useFlags, useFlagsLoaded} from '@product-web/shared--flags'
import {useLastHandleParam} from '@product-web/shared--routes/last-handle-param'
import type {CompanyStatus} from '@product-web/shared--routes/types'
import {useHasAllowedRole, useIsCompanyOnboardedViaB4B, useUser} from '@product-web/shared--user'

import {useHasIncompleteSignup} from './use-has-incomplete-signup'
/**
 * Component wrapping all behind-the-login-screen routes, only to be used ONCE in core/routes.
 * Takes care of:
 * 1. redirecting:
 * - anonymous users to the login screen (setting a redirect back to the requested page)
 * - previously logged in user with the Otp session to login with passcode
 * - users who have not completed the onboarding (e.g. no ToS acceptance)
 * 2. disallowing access based on user role or company status. If the conditions are not met, the user will be shown Access Denied screen.
 */
export const AuthRouteProvider = ({children}: {children?: React.ReactNode}) => {
    const auth = useLastHandleParam('auth')

    if (!auth) {
        return <>{children}</>
    }
    return <AuthRoute>{children}</AuthRoute>
}

function useHasCompanyRequiredStatus(minCompanyStatus?: CompanyStatus) {
    const user = useUser()

    if (!minCompanyStatus) {
        return true
    }

    if (minCompanyStatus === 'sdd') {
        return !!user?.company?.status.sdd
    }

    if (minCompanyStatus === 'fdd') {
        return !!user?.company?.status.fdd
    }

    return false
}

export const AuthRoute = ({children}: {children: React.ReactNode}) => {
    const location = useLocation()
    const user = useUser()
    const featureFlags = useFlags()
    const featureFlagsLoaded = useFlagsLoaded()
    const isOnboardedViaB4B = useIsCompanyOnboardedViaB4B()

    const allowedRoles = useLastHandleParam('allowedRoles')
    const minCompanyStatus = useLastHandleParam('minCompanyStatus')

    const hasIncompleteSignup = useHasIncompleteSignup()

    const hasAllowedRole = useHasAllowedRole(allowedRoles)
    // TODO: change this check to `isCompanyFDDd`.
    const hasCompanyRequiredStatus = useHasCompanyRequiredStatus(minCompanyStatus)

    // if in an active registration flow
    if (hasIncompleteSignup) {
        return <Navigate to="/register" />
    }

    if (!user) {
        const searchParams = new URLSearchParams(location.search)

        // "userAccountEmail" is a standard key from send-notification space rock
        // https://github.com/pleo-io/space-rocks/blob/main/src/rocks/send-notification/index.ts#L197
        const email = searchParams.get('userAccountEmail') || null
        const redirect = location.pathname + location.search + location.hash

        return <Navigate to="/login" state={{email, redirect}} />
    }

    // Make sure every user has accepted terms, except for admins still creating the company
    if (!user?.status?.acceptedTerms && user?.companyId) {
        return <Navigate to="/register/terms" />
    }

    // We navigate to the company-verification page if the company is onboarded via B4B
    if (
        featureFlagsLoaded &&
        featureFlags.b4BNav &&
        isOnboardedViaB4B &&
        !location.pathname.match(/^\/company-verification/)
    ) {
        return <Navigate to="/company-verification" replace />
    }

    if (!hasAllowedRole || !hasCompanyRequiredStatus) {
        return <Navigate to="/" />
    }

    return <>{children}</>
}
