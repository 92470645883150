import {Inline, tokens} from '@pleo-io/telescope'

import {pxBreakpoints} from '@product-web/shared--styles/theme'
import {useContainerQuery} from '@product-web/shared--telescope-lab/container-queries/container'

import {LandingPageKeyCard, type LandingPageKeyCardProps} from './landing-page-key-card'

export type LandingPageKeyCardsProps = {
    cards: LandingPageKeyCardProps[]
}

export const LandingPageKeyCards = ({cards}: LandingPageKeyCardsProps) => {
    const isDesktop = useContainerQuery({
        name: 'main',
        minWidth: pxBreakpoints.tabletMedUp,
    })

    return (
        <Inline css={{gap: tokens.spacing32}} flexDirection={isDesktop ? 'row' : 'column'}>
            {cards.map((keyCardProps) => (
                <LandingPageKeyCard {...keyCardProps} key={keyCardProps.getTitle()} />
            ))}
        </Inline>
    )
}
