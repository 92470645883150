import type {ReactNode} from 'react'
import styled, {css} from 'styled-components'
import type {SpaceProps} from 'styled-system'
import {space} from 'styled-system'

import {tokens} from '@pleo-io/telescope'

import {pxBreakpoints} from '@product-web/shared--styles/theme'
import {
    containerQuery,
    useContainerQuery,
} from '@product-web/shared--telescope-lab/container-queries/container'

export const useSplitContentMediaQuery = () =>
    useContainerQuery({maxWidth: pxBreakpoints.desktopMedUp})

const Content = styled.div<SpaceProps & {reverse?: boolean}>`
    display: flex;
    flex-direction: ${({reverse}) => (reverse ? 'column-reverse' : 'column')};
    ${containerQuery(
        {minWidth: pxBreakpoints.desktopMedUp},
        css`
            flex-direction: row;
        `,
    )}
    ${space}
`
const Main = styled.div<{flex?: number}>`
    flex: ${({flex}) => (flex ? flex : 3)};
    margin-bottom: ${tokens.spacing40};
    ${containerQuery(
        {minWidth: pxBreakpoints.desktopMedUp},
        css`
            margin-bottom: 0;
            margin-right: ${tokens.spacing20};
        `,
    )}
`
/**
 * `trivial` can be used to hide the right column on smaller screens.
 * eg. when it only contains an illustration
 */
const Right = styled.div<{trivial?: boolean}>`
    flex: 2;
    margin-bottom: ${tokens.spacing40};
    display: ${({trivial}) => (trivial ? 'none' : 'flex')};
    flex-direction: column;
    ${containerQuery(
        {minWidth: pxBreakpoints.desktopMedUp},
        css`
            display: flex;
            margin-bottom: 0;
            margin-left: ${tokens.spacing20};
        `,
    )}
`
const Sticky = styled.div`
    position: initial;
    margin-bottom: ${tokens.spacing24};
    ${containerQuery(
        {minWidth: pxBreakpoints.desktopMedUp},
        css`
            position: sticky;
            top: ${tokens.spacing20};
        `,
    )}
`

const Image = styled.img.attrs({alt: ''})``

interface SplitContentProps extends SpaceProps {
    reverse?: boolean
    children: ReactNode
}

export const SplitContent = (props: SplitContentProps) => <Content {...props} />

SplitContent.Main = Main
SplitContent.Right = Right
SplitContent.Sticky = Sticky
SplitContent.Image = Image
