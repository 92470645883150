import axios from 'axios'
import {v4 as uuid} from 'uuid'

import {OtpType} from '@product-web/shared--api-types/otp'
import {
    clearClientSession,
    getClientSessionId,
    openClientSession,
} from '@product-web/shared--auth--client-session'
import {getTokenPayload} from '@product-web/shared--auth--jwt/payload'
import {getJwtExpiryMin} from '@product-web/shared--auth--jwt/utils'
import config from '@product-web/shared--config'
import {reportError} from '@product-web/shared--error/report'
import cookie from '@product-web/shared--web-platform/cookie'

import {
    findPrimaryAccountToken,
    getPrimaryAccountEmail,
    setPrimaryAccountEmail,
} from './primary-account'
import {removeUserParams as removeParamsFromRegistration} from './register-state'
import {clearScopedTokens} from './scoped-tokens'
import type {SessionData} from './store'
import {sessionStore} from './store'

// This key is for session storage.
// It is used to store the session type of a user when logging in since a user can have either "Otp" or "Legacy" session.
export const SESSION_TYPE_KEY = 'sessionType'

export const SESSION_LOGIN_ROUTE = `${config.endpoints.auth}/sca/login`
export const SESSION_VERIFIED_LOGIN_ROUTE = `${config.endpoints.auth}/sca/verified-login`
export const SESSION_SET_TRUSTED_ROUTE = `${config.endpoints.auth}/sca/session/set-trusted`
export const SESSION_REFRESH_ROUTE = `${config.endpoints.auth}/sca/refresh`
export const VERIFY_LOGIN_ROUTE = `${config.endpoints.auth}/sca/verify-login-attempt`
export const SESSION_ROUTE = `${config.endpoints.auth}/sca/session`
export const LEGACY_SESSION_ROUTE = `${config.endpoints.auth}/session/refresh`
export const SESSION_LOGOUT_ROUTE = `${config.endpoints.auth}/sca/logout`
export const LEGACY_SESSION_LOGOUT_ROUTE = `${config.endpoints.auth}/session/logout`

type LoginSession = {
    email: string
    passcode: string | null
    otp: string
    trust: boolean
    loginToken: string | null
    otpType: OtpType
}

/**
 * Method to request access token to log user in for a fresh session
 * @param email User's email
 * @param passcode  User's passcode
 * @param otp One-Time-Password (OTP) sent to user's verified phone
 * @param trust Indicates whether the current browser is a trusted session
 * @param loginToken A token derived from a redirect from Single-Sign-On (SSO). This usually indicates an external provider login
 * @returns an accessToken or null
 */

export async function loginSession({
    email,
    passcode,
    otp,
    trust,
    loginToken,
    otpType,
}: LoginSession) {
    const result = await axios.request<SessionData | void>({
        url: SESSION_LOGIN_ROUTE,
        method: 'post',
        withCredentials: true,
        data: {
            email,
            otp,
            trust,
            ...(passcode && {passcode}),
            ...(loginToken && {loginToken}),
            ...(otpType === OtpType.GOOGLE_AUTH && {otpType}),
        },
    })
    return result.data ? result.data : {accessToken: null}
}

type VerifyLoginAuthData = {
    accessToken: string
    companyId: string | undefined
    partnerId: string | undefined
    accessTokens: string[]
    activeAccount: {
        accessToken: string
        email: string
    }
}

export type VerifiedLoginResponse =
    | {
          result: 'authenticated'
          authenticationData: VerifyLoginAuthData
      }
    | {
          result: 'email_challenge_required'
          authenticationData: null
      }

export async function verifiedLoginSession({
    email,
    passcode,
    otp,
    trust,
    loginToken,
    codeChallenge,
    otpType,
    codeChallengeMethod,
}: {
    email: string
    passcode?: string | null
    loginToken?: string | null
    otp: OtpType | string
    otpType?: OtpType | string
    trust: boolean
    codeChallenge: string
    codeChallengeMethod: 'plain' | 'S256'
}) {
    const result = await axios.request<VerifiedLoginResponse>({
        url: SESSION_VERIFIED_LOGIN_ROUTE,
        method: 'post',
        withCredentials: true,
        data: {
            email,
            otp,
            codeChallenge,
            codeChallengeMethod,
            trust,
            clientType: 'web',
            ...(passcode && {passcode}),
            ...(loginToken && {loginToken}),
            ...(otpType === OtpType.GOOGLE_AUTH && {otpType}),
        },
    })

    if (!result.data) {
        throw new Error('Failed to login session')
    }

    return result.data
}

/**
 * Method to request access token to log user in for a fresh session
 * @param email logged-in email
 */
export async function setTrusted(email: string) {
    await axios.request<void>({
        url: SESSION_SET_TRUSTED_ROUTE,
        method: 'post',
        withCredentials: true,
        data: {
            email,
        },
    })
}

/**
 * Method to request access token to log user in.
 * This is for a return log in. What this means is that a user has trusted the device. On a trusted device,
 * a trusted session is kept active so that they only thing the user requires to log-in is their passcode
 * @param param.passcode User's passcode
 * @param param.email Email to authenticate account towards
 * @returns an accessToken or null
 */
export async function loginSessionWithPasscode(payload: {passcode: string; email: string}) {
    const authCheck = uuid()
    cookie.set('authCheck', authCheck, config.auth.authCheckExpire)

    const result = await axios.request<SessionData>({
        url: SESSION_REFRESH_ROUTE,
        method: 'post',
        withCredentials: true,
        data: payload,
    })
    return result.data ? result.data : {accessToken: null}
}

/**
 * Method to request login with token
 * @param code Token to authenticate with (sent to email)
 * @param codeVerifier Client side generated code (stored in local storage)
 * @returns {accessToken: string; accessTokens: string[]} | null
 */
export async function verifyLoginAttempt(code: string, codeVerifier: string): Promise<SessionData> {
    const result = await axios.request<VerifyLoginAuthData>({
        url: VERIFY_LOGIN_ROUTE,
        method: 'post',
        withCredentials: true,
        data: {code, codeVerifier},
    })
    return result.data ? {...result.data, email: result.data?.activeAccount?.email} : {}
}

/**
 * Method to request session data
 * @param companyId The Company ID to fetch session data for
 * @param idToken Optional ID token to use for fetching session data
 */
export async function getSessionData({
    companyId,
    idToken,
}: {
    companyId: string | null
    idToken?: string
}) {
    return await axios.request<SessionData>({
        url: SESSION_ROUTE,
        method: 'get',
        withCredentials: true,
        params: {companyId, idToken},
    })
}

// Indicates the type of session the user is on.
// - Otp is the 2FA way of session handling that requires a One-Time-Password (OTP) sent via SMS, a valid email, and a valid passcode.
//   Subsequent logins after an initial login on a trusted device will only require the passcode.
// - Legacy is login that requires email an password to authenticate.
export enum SessionType {
    Otp = 'otp',
    Legacy = 'legacy',
}

// Starts or refreshes the session in Kerberos and fetches and returns the access token.
// Session data set to sessionStorage to stay accessible for all kinds of request/data fetching
// methods outside React.Context
export async function fetchSession(options?: {
    idToken?: string
    companyId?: string
    force?: boolean
}) {
    // If we already have a valid JWT token stored let's use that.
    const sessionData = sessionStore.get()
    const prevAccessToken = sessionData?.accessToken ?? null
    const prevAccessTokens = sessionData?.accessTokens ?? null
    const previousEmail = sessionData?.email
    const tokenPayload = prevAccessToken ? getTokenPayload(prevAccessToken) : null
    if (prevAccessToken && tokenPayload && !options?.force) {
        if (getJwtExpiryMin(tokenPayload) > 10) {
            return {
                email: previousEmail,
                accessToken: prevAccessToken,
                accessTokens: prevAccessTokens?.length ? prevAccessTokens : null,
            }
        }
    }

    const authCheck = uuid()
    cookie.set('authCheck', authCheck, config.auth.authCheckExpire)

    // We are deliberately using axios directly here instead of the usual request helper
    // We don't need all the additional niceties that request provides (like injecting the token)
    // and thus avoid a circular dependency as well

    // Starts or refreshes the session in Kerberos and fetches and returns the access token
    // We also support two methods of handling session refreshes, the legacy and the OTP session with trusted devices
    // A session on a trusted device can be refreshed using a refresh token and passcode.
    const companyId = options?.companyId || getClientSessionId()
    const params = companyId ? {companyId} : {}
    const [response, legacyResponse] = await Promise.all([
        getSessionData({companyId, idToken: options?.idToken}),
        axios.request<SessionData>({
            url: LEGACY_SESSION_ROUTE,
            method: 'post',
            headers: {'X-Auth-Check': authCheck},
            withCredentials: true,
            params: options?.force ? {force: true, ...params} : {},
        }),
    ])

    // We wait until both requests are resolved and then we have a preference for the new session route.
    // We only decide to use legacy response if new session route does not return any useful info
    // while legacy route returns acces token
    if (
        legacyResponse.data?.accessToken &&
        !response.data?.accessToken &&
        !response.data?.email &&
        !response.data?.trustedEmails?.length
    ) {
        sessionStorage.setItem(SESSION_TYPE_KEY, SessionType.Legacy)
        sessionStore.set({accessToken: legacyResponse.data.accessToken})
        return legacyResponse.data
    }

    sessionStorage.setItem(SESSION_TYPE_KEY, SessionType.Otp)

    const {
        accessToken = null,
        accessTokens = null,
        email = null,
        trustedEmails = null,
    } = response.data

    let res = {}

    if (accessToken) {
        res = {accessToken, accessTokens}
    }

    // The access token returned for a user with mutiple active sessions is the one that was added last
    // Here we check to see if the return access token matches one that was selected by the user when switching accounts.
    // If it does, we use the access token matching that account instead of the one returned by the server.
    if (accessTokens) {
        const primaryAccountEmail = getPrimaryAccountEmail()

        if (primaryAccountEmail) {
            const primaryAccountAccessToken = findPrimaryAccountToken(
                primaryAccountEmail,
                accessTokens,
            )

            if (primaryAccountAccessToken) {
                res = {
                    accessToken: primaryAccountAccessToken,
                    accessTokens,
                    email: primaryAccountEmail,
                }
            } else {
                res = {...res, accessTokens}
            }
        }
    }

    // If no access token present but there are trusted emails and preselected email
    // we return these so that the session can be refreshed with a passcode
    if (!accessToken && trustedEmails?.length && email) {
        res = {
            trustedEmails,
            email,
        }
    }

    if (trustedEmails) {
        res = {
            ...res,
            trustedEmails,
        }
    }

    // There is no access token and no info to refresh the session
    if (!accessToken && !email && !trustedEmails?.length) {
        res = {accessToken: null}
    }

    sessionStore.set(res)

    return res
}

/**
 * Open a new session either through login or reauthenticating an existing session
 * It sets the session type to Otp
 * It sets a primary email account to help with the switch account flow
 * It starts a new bookkeeper session if a companyId is present
 * It force fetches the session to get the new access token
 * @param param.companyId // the primary companyId associated with the user
 * @param param.accessToken // the accesss token for the current session
 * @param param.accessTokens // the access tokens for all accounts associated with a device (or browser)
 * @param param.email // the email associated with the current session
 */
export async function openSession({companyId, accessToken, accessTokens, email}: SessionData) {
    //we should open bookkeeper session before setting anything to the sessionStore
    //because sessionStore.set will cause /user API re-fetch (see UserProvider)
    //and bookkeeper companyID should be already available in the local storage at that moment.
    //this is OK since we are not immediately redirecting (skipRedirect: true)
    if (companyId) {
        openClientSession(companyId)
    } else {
        clearClientSession()
    }

    sessionStorage.setItem(SESSION_TYPE_KEY, SessionType.Otp)
    sessionStore.set({accessToken, accessTokens, email})

    setPrimaryAccountEmail(email)

    // Clear localstorage params needed for registration
    removeParamsFromRegistration()

    // Here we force fetch the session, bypassing the check for jwt expiry
    // Without this, the old access tokens which are still valid will be used
    await fetchSession({force: true})
}

/**
 * Allows to force refresh the session - it will invalidate the currently used
 * token across all of the Pleo systems, and fetch a new one
 * @returns A promise that resolves when the session has been refreshed
 */
export async function forceSessionRefresh(idToken?: string) {
    await fetchSession({force: true, idToken})
}

/**
 * Handles forcing session log out propagation across browser tabs
 * Since introducing jwts, we don't request a new token until the previous one expires. The expiration time for a token today is 10 minutes.
 * So when a user logs out on an active browser tab, we need to force the log out of all other tabs.
 */

let refreshPromise: Promise<void> | null = null
/**
 * Force refresh the session, but only allow a single refresh at any given moment
 */
export async function memoForceSessionRefresh() {
    if (!refreshPromise) {
        refreshPromise = forceSessionRefresh()
    }
    await refreshPromise
    refreshPromise = null
}

/**
 * Allows to force refresh the session with a companyId to allow Partner Member's
 * to perform actions on thier clients from within the Partner Portal, i.e. without
 * necessitating app refresh with openClientSession. It will clear the
 * current bookkeeper session and invalidate the currently used token across all
 * of the Pleo systems, and fetch a new one
 * @param companyId
 * @returns A promise that resolves when the session has been refreshed
 */
export async function getPartnerClientSession(companyId: string) {
    clearClientSession()
    return await fetchSession({companyId, force: true})
}

async function logoutFreshchat() {
    if (window.fcWidget?.isLoaded()) {
        try {
            await window.fcWidget.user.clear()
        } catch (error) {
            reportError(error, 'Failed to logout Freshchat')
        }
    }
}

// Ends the session in Kerberos
export async function logoutSession(removeTrustedDevice = true) {
    const authCheck = uuid()
    cookie.set('authCheck', authCheck, config.auth.authCheckExpire)
    const sessionType = sessionStorage.getItem(SESSION_TYPE_KEY)
    setPrimaryAccountEmail(null)

    // Using axios deliberately (see fetchSession for explanation)

    // During the transition period for 2FA, we will support all legacy routes
    // So based on the session type, we can log the user out appropriately.
    // We also invalidate the trusted device session when the user logs out.
    await axios.request<void>({
        url: sessionType === SessionType.Otp ? SESSION_LOGOUT_ROUTE : LEGACY_SESSION_LOGOUT_ROUTE,
        method: 'post',
        headers: sessionType === SessionType.Otp ? {} : {'X-Auth-Check': authCheck},
        withCredentials: true,
        // Here we remove the trusted session if the option is passed on logout and if the session is not the legacy type
        params: sessionType === SessionType.Otp ? {removeTrustedDevice} : undefined,
    })
}

export type LogoutOptions = {afterLogoutPath?: string; onLogout?: () => void}

// Cleanup all session-related storage and saved data, called when the session status
// changes from logged in to logged out
export async function cleanupSession(options?: LogoutOptions) {
    sessionStore.set({accessToken: null, accessTokens: null, email: null})
    await logoutFreshchat()
    options?.onLogout?.()
    sessionStorage.clear()
    clearScopedTokens()
    clearClientSession()
    // we want full page reload, so it's not react-router
    window.location.assign(options?.afterLogoutPath ?? `/login`)
}

/**
 * **DISCOURAGED** Use `useAccessToken` instead!
 *
 * Retrieves the access token for the currently logged in user, bypassing the React context
 * Use the `useAccessToken` instead, unless you have a good reason to rely on this behaviour.
 * The downside of this method is that the consumer of this method will not be notified when
 * the value of the access token changes for whatever reason
 * @returns The jwt access token, if available
 */
export function __getAccessToken(): string | null {
    const data = sessionStore.get()
    return data?.accessToken ?? null
}

/**
 * **DISCOURAGED** Use `useLogout` instead!
 *
 * Closes the session in Kerberos and clears session cache that in terms logs the user out.
 * Use the useLogout hooks instead when using from within React
 * @returns A promise that resolves when the user is logged out
 */
export async function __logout() {
    await logoutSession(true)
    sessionStorage.removeItem(SESSION_TYPE_KEY)
    sessionStore.set({accessToken: null, accessTokens: null, email: null})
}
