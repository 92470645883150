import {Outlet} from 'react-router-dom'

import {useIsLoggedIn} from '@product-web/shared--auth--session/context'
import {getIsCompanyUser, useUser} from '@product-web/shared--user'

/**
 * Grouping top level components that are only mounted for company users.
 * [Read more](https://www.notion.so/pleo/Top-level-business-logic-for-product-web-a9d2286b244d407e957e0b85a1e6f8f8?pvs=4)
 */
const TopLevelComponentsCompany = () => {
    const user = useUser()
    const isLoggedIn = useIsLoggedIn()

    return (
        <>
            <Outlet />
            {isLoggedIn && Boolean(user) && getIsCompanyUser(user) && (
                <>{/* Insert top level company components here */}</>
            )}
        </>
    )
}

export default TopLevelComponentsCompany
