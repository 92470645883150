import {t, Trans} from '@lingui/macro'
import type {MouseEventHandler} from 'react'
import type React from 'react'
import styled from 'styled-components'

import {Link, Text, tokens} from '@pleo-io/telescope'

import diamondSvg from '@product-web/shared--images/icons/diamond.svg'

const Card = styled.div`
    background-color: ${tokens.colorBackgroundStaticLoud};
    border-radius: ${tokens.arc8};
    padding: ${tokens.spacing16} ${tokens.spacing20};
    cursor: pointer;
`

const Icon = styled.img`
    margin-bottom: ${tokens.spacing4};
    width: 24px;
`

const PromoCard = ({
    onCardClicked,
    children,
}: {
    children: React.ReactNode
    onCardClicked: MouseEventHandler<HTMLAnchorElement | HTMLDivElement>
}) => {
    return (
        <Card onClick={onCardClicked}>
            <Icon src={diamondSvg} alt={t`Diamond icon`} />
            <Text as="p" variant="small-subtle" color="shade800" space={4}>
                {children}
            </Text>
            <Link onClick={onCardClicked}>
                <Trans>Upgrade</Trans>
            </Link>
        </Card>
    )
}

export default PromoCard
