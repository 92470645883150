import {t, Trans} from '@lingui/macro'
import styled, {css} from 'styled-components'

import {Inline, ProgressBar, Stack, Text, tokens} from '@pleo-io/telescope'

import type {MinorCurrency} from '@product-web/shared--locale/currency'
import {formatMinorCurrency, formatMinorCurrencyParts} from '@product-web/shared--locale/currency'
import {pxBreakpoints} from '@product-web/shared--styles/theme'
import {
    containerQuery,
    useContainerQuery,
} from '@product-web/shared--telescope-lab/container-queries/container'

import type {TagBudget} from '../index.bff'

const StyledTotals: React.FC<React.PropsWithChildren<{total: string; currency: string}>> = ({
    total,
    currency,
}) => (
    <Inline space={4} alignY="baseline">
        <Text variant="2xlarge-accent" weight="regular" color="shade800">
            {total}
        </Text>
        <Text variant="medium-default" weight="regular" color="shade800">
            {currency}
        </Text>
    </Inline>
)

const TagBudgetSpend: React.FC<
    React.PropsWithChildren<{
        budget: TagBudget
        isDrawer?: boolean
    }>
> = ({budget, isDrawer}) => {
    const {sumPending, sumSettled, currency, percentageSpent, isWithinBudget} = budget

    const pendingSpend = formatMinorCurrency({
        value: sumPending as MinorCurrency,
        currency,
    })

    const settledSpend = formatMinorCurrency({
        value: sumSettled as MinorCurrency,
        currency,
    })

    return (
        <>
            <Inline space={8} alignItems="center">
                <StyledDot $background={isWithinBudget ? tokens.green500 : tokens.red600} />
                <Text variant="small-subtle" weight="regular" color="shade800">
                    {t`Settled ${settledSpend}`}
                </Text>
            </Inline>
            {percentageSpent > 0 && (
                <Inline space={8} alignItems="center">
                    {isDrawer && <StyledDot $background={tokens.shade000} />}
                    <Text variant="small-subtle" weight="regular" color="shade800">
                        {t`Pending ${pendingSpend}`}
                    </Text>
                </Inline>
            )}
        </>
    )
}

export const TagBudgetTracker: React.FC<
    React.PropsWithChildren<{
        budget: TagBudget
        isDrawer?: boolean
    }>
> = ({budget, isDrawer}) => {
    const isSmallScreen = useContainerQuery({
        name: 'main',
        maxWidth: pxBreakpoints.smallTabletUp,
    })

    const {remainingAmount, sumSpent, amount, currency, percentageSpent, isWithinBudget} = budget

    const budgetRemaining = formatMinorCurrency({
        value: remainingAmount as MinorCurrency,
        currency,
    })

    const totalSpent = formatMinorCurrencyParts({
        value: sumSpent as MinorCurrency,
        currency,
    })

    const budgetAmount = formatMinorCurrencyParts({
        value: amount as MinorCurrency,
        currency,
    })

    return (
        <Stack stretch>
            <StyledInline marginBottom={16}>
                <Stack space={2} marginBottom={isSmallScreen ? 16 : 0}>
                    <Text variant="small-subtle" weight="regular" color="shade600">
                        <Trans>Expenses total</Trans>
                    </Text>
                    <StyledTotals total={totalSpent.formattedValue} currency={currency} />
                </Stack>
                <Stack space={2} align={isSmallScreen ? 'left' : 'right'}>
                    <Text variant="small-subtle" weight="regular" color="shade600">
                        <Trans>Budget total</Trans>
                    </Text>
                    <StyledTotals total={budgetAmount.formattedValue} currency={currency} />
                </Stack>
            </StyledInline>
            <ProgressBar
                aria-label={t`budget amount progress bar in budget details `}
                variant="tall"
                percent={percentageSpent}
                fillColor={isWithinBudget ? 'green500' : 'red600'}
                bgColor="shade300"
            />
            <StyledInline marginTop={12} stretchChildren>
                {isDrawer ? (
                    <Stack space={8} marginBottom={isSmallScreen ? 8 : 0}>
                        <TagBudgetSpend budget={budget as TagBudget} isDrawer={isDrawer} />
                    </Stack>
                ) : (
                    <Inline space={20}>
                        <TagBudgetSpend budget={budget as TagBudget} isDrawer={isDrawer} />
                    </Inline>
                )}
                <Inline
                    space={8}
                    justifyContent={isSmallScreen ? 'left' : 'right'}
                    alignItems="center"
                    css={{whiteSpace: 'nowrap'}}
                >
                    <StyledDot $background={tokens.shade300} />
                    <Text variant="small-subtle" weight="regular" color="shade800">
                        {t`Remaining ${budgetRemaining}`}
                    </Text>
                </Inline>
            </StyledInline>
        </Stack>
    )
}

const StyledInline = styled(Inline)`
    justify-content: space-between;
    ${containerQuery(
        {maxWidth: pxBreakpoints.smallTabletUp},
        css`
            flex-direction: column;
        `,
    )}
`

const StyledDot = styled.div<{$background: string}>`
    width: ${tokens.spacing16};
    height: ${tokens.spacing16};
    background: ${(props) => `${props.$background}`};
    border-radius: ${tokens.spacing8};
`
