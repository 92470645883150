import {t, Trans} from '@lingui/macro'
import type {FC} from 'react'
import {Link as RouterLink, NavLink, Outlet, useLocation} from 'react-router-dom'

import {Link, Tabs} from '@pleo-io/telescope'
import {ArrowLeft} from '@pleo-io/telescope-icons'

import {Page} from '@product-web/feature--ui-page/page'
import {getIsFdd, useLoggedInUser} from '@product-web/shared--user'

const generalSettingsPaths: Record<string, string> = {
    billing: '/settings/general/billing',
    'card-defaults': '/settings/general/card-defaults',
    'company-info': '/settings/general/company',
}

const GeneralSettingsWrapper: FC = () => {
    const user = useLoggedInUser()
    const isFdd = getIsFdd(user)
    const ownerAccess = user.role === 'owner' || user.role === 'bookkeeper-extended'
    const location = useLocation()

    const value = Object.keys(generalSettingsPaths).find((key) =>
        location.pathname.includes(generalSettingsPaths[key]),
    )

    return (
        <Page layout="wide" showNav>
            <Tabs value={value}>
                <Page.Header>
                    <Page.Backlink>
                        <Link IconLeft={ArrowLeft} to="/settings" as={RouterLink}>
                            <Trans>Back to Settings</Trans>
                        </Link>
                    </Page.Backlink>
                    <Page.Title>
                        <Trans>General</Trans>
                    </Page.Title>
                    <Page.Tabs>
                        <Tabs.TriggerList>
                            {ownerAccess && (
                                <Tabs.Trigger value="billing" data-testid="billing-tab" asChild>
                                    <NavLink to={generalSettingsPaths.billing}>
                                        <Tabs.TriggerText text={t`Billing`} />
                                    </NavLink>
                                </Tabs.Trigger>
                            )}
                            {isFdd && (
                                <Tabs.Trigger
                                    value="card-defaults"
                                    data-testid="card-defaults-tab"
                                    asChild
                                >
                                    <NavLink to={generalSettingsPaths['card-defaults']}>
                                        <Tabs.TriggerText text={t`Card defaults`} />
                                    </NavLink>
                                </Tabs.Trigger>
                            )}
                            <Tabs.Trigger value="company-info" data-testid="company-tab" asChild>
                                <NavLink to={generalSettingsPaths['company-info']}>
                                    <Tabs.TriggerText text={t`Company info`} />
                                </NavLink>
                            </Tabs.Trigger>
                        </Tabs.TriggerList>
                    </Page.Tabs>
                </Page.Header>
                <Outlet />
            </Tabs>
        </Page>
    )
}

export default GeneralSettingsWrapper
