import {Plural, t, Trans} from '@lingui/macro'
import {useEffect, useState} from 'react'
import Helmet from 'react-helmet'
import {useNavigate, useSearchParams} from 'react-router-dom'

import {Button, Card, Inline, Skeleton, Stack, Tabs, Text} from '@pleo-io/telescope'

import {Page} from '@product-web/feature--ui-page/page'
import {useFlags} from '@product-web/shared--flags'

import {bff} from '../../../bff-hooks'
import * as EmptySection from '../../../components/empty-section/empty-section'
import {GenericError} from '../../../components/generic-error'
import {SplitContent} from '../../../components/split-content'
import cat from '../../../images/no-clients.png'
import {AcceptPartnerAgreement} from '../components/accept-partner-agreement/accept-partner-agreement'
import {PartnerAgreement} from '../components/partner-agreement/partner-agreement'
import {PartnerTierCard} from '../components/partner-tier-card/partner-tier-card'
import {Referrals} from '../components/referrals/referrals'
import {ReferredUsers} from '../components/referred-users/referred-users'

export const PartnershipTab = {
    CURRENT_YEAR: 'current-year',
    PREVIOUS_YEARS: 'previous-years',
} as const

interface PartnershipProps {
    tab: (typeof PartnershipTab)[keyof typeof PartnershipTab]
}

export const Partnership = ({tab}: PartnershipProps) => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const acceptTerms = searchParams.get('acceptTerms')
    const [isModalOpen, setIsModalOpen] = useState(acceptTerms === 'true')
    const open = () => setIsModalOpen(true)
    const close = () => {
        setSearchParams({})
        setIsModalOpen(false)
    }

    useEffect(() => {
        if (acceptTerms === 'true') {
            open()
        }
    }, [acceptTerms])

    const {partnerAgreementCountries} = useFlags()
    const {data, isLoading, isError} =
        bff.partnership.getPartnershipInfo.useQuery(partnerAgreementCountries)

    if (isError) {
        return <GenericError />
    }

    return (
        <>
            <Tabs value={tab} onValueChange={(newTab) => navigate(`../${newTab}`, {replace: true})}>
                <Page.Header>
                    <Helmet>
                        <title>{t`Company`}</title>
                    </Helmet>
                    <Page.SpaceBetween>
                        <Page.Title>
                            <Trans>Partnership</Trans>
                        </Page.Title>
                        <Page.Actions>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    // can't render this as RouterLink because `state` is not supported in the Button types
                                    navigate('/partner/onboard', {
                                        state: {fromPath: '/partner/partnership'},
                                    })
                                }}
                            >
                                <Trans>Invite client</Trans>
                            </Button>
                        </Page.Actions>
                    </Page.SpaceBetween>
                </Page.Header>
                {!isLoading && !data?.isFirstPartnershipYear && (
                    <Tabs.TriggerList>
                        <Tabs.Trigger
                            key={PartnershipTab.CURRENT_YEAR}
                            aria-label={t`This year`}
                            value={PartnershipTab.CURRENT_YEAR}
                        >
                            <Trans>This year</Trans>
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            key={PartnershipTab.PREVIOUS_YEARS}
                            aria-label={t`Previous years`}
                            value={PartnershipTab.PREVIOUS_YEARS}
                        >
                            <Trans>Previous years</Trans>
                        </Tabs.Trigger>
                    </Tabs.TriggerList>
                )}
                <Tabs.Content value={PartnershipTab.CURRENT_YEAR}>
                    <Stack mt={32} stretch>
                        <SplitContent reverse>
                            <SplitContent.Main>
                                {data?.promptForPartnerAgreement ? (
                                    <NotSignedAgreementSection />
                                ) : (
                                    <>
                                        <Referrals
                                            title={t`Pending referrals`}
                                            description={t`These will become referred clients once they complete onboarding.`}
                                            status="pending"
                                            year="current"
                                            optional
                                        />
                                        <Referrals
                                            title={t`Active referrals`}
                                            description={t`Clients that were referred and onboarded this partnership year.`}
                                            status="active"
                                            year="current"
                                        />
                                        <ReferredUsers
                                            title={t`Referred users`}
                                            description={t`Users created during this partnership year, whether by clients referred this year or previously.`}
                                            year="current"
                                        />
                                    </>
                                )}
                            </SplitContent.Main>
                            <SplitContent.Right>
                                <SplitContent.Sticky>
                                    <Stack space={32} stretch>
                                        <PartnerTierCard />
                                        <PartnerOverviewCard
                                            partnershipYear={data?.partnershipYear}
                                        />
                                        {data?.showPartnerAgreement && <PartnerAgreement />}
                                    </Stack>
                                </SplitContent.Sticky>
                            </SplitContent.Right>
                        </SplitContent>
                    </Stack>
                </Tabs.Content>
                <Tabs.Content value={PartnershipTab.PREVIOUS_YEARS}>
                    <Stack mt={32} stretch>
                        <SplitContent reverse>
                            <SplitContent.Main>
                                <Referrals
                                    title={t`Previously referred clients`}
                                    description={t`Clients referred before this partnership year. They don't contribute to your tier activity, but any new user they create will.`}
                                    status="active"
                                    year="previous"
                                />
                                <ReferredUsers
                                    title={t`Previously referred users`}
                                    description={t`Users referred before this partnership year. They don't contribute to your tier activity.`}
                                    year="previous"
                                />
                            </SplitContent.Main>
                            <SplitContent.Right trivial>
                                <div />
                            </SplitContent.Right>
                        </SplitContent>
                    </Stack>
                </Tabs.Content>
            </Tabs>
            {data?.promptForPartnerAgreement && (
                <AcceptPartnerAgreement isOpen={isModalOpen} close={close} />
            )}
        </>
    )
}

export default Partnership

function NotSignedAgreementSection() {
    const [searchParams, setSearchParams] = useSearchParams()
    const openAcceptPartnerAgreement = () => {
        searchParams.set('acceptTerms', 'true')
        setSearchParams(searchParams)
    }

    return (
        <EmptySection.Root>
            <EmptySection.Content>
                <EmptySection.Image src={cat} width="95px" />
                <EmptySection.Title>
                    <Trans>Don't miss out on your benefits</Trans>
                </EmptySection.Title>
                <EmptySection.Subtitle>
                    <Trans>
                        Accept your partner agreement to start onboarding new clients to the portal
                        right away.
                    </Trans>
                </EmptySection.Subtitle>
                <Button variant="primary" onClick={openAcceptPartnerAgreement}>
                    <Trans>Accept agreement</Trans>
                </Button>
            </EmptySection.Content>
        </EmptySection.Root>
    )
}

type PartnerOverviewCardProps = {
    partnershipYear?: {
        paidUsers: number
        freeUsers: number
        newClients: number
    }
}
function PartnerOverviewCard({partnershipYear}: PartnerOverviewCardProps) {
    return (
        <Card>
            <Card.Header>
                <Skeleton loading={!partnershipYear} borderRadius={8}>
                    <Text variant="xlarge-accent" as="h3">
                        <Trans>Overview</Trans>
                    </Text>
                </Skeleton>
            </Card.Header>
            <Card.Body>
                <Skeleton loading={!partnershipYear} borderRadius={8} height="50px">
                    <Inline as="tr" justifyContent="space-between">
                        <Stack as="td">
                            <Text variant="xlarge-accent">{partnershipYear?.newClients}</Text>
                            <Text variant="small-subtle">
                                <Plural
                                    value={partnershipYear?.newClients}
                                    one="Referred client"
                                    other="Referred clients"
                                />
                            </Text>
                        </Stack>
                        <Stack as="td">
                            <Text variant="xlarge-accent">{partnershipYear?.paidUsers}</Text>
                            <Text variant="small-subtle">
                                <Plural
                                    value={partnershipYear?.paidUsers}
                                    one="Paid user"
                                    other="Paid users"
                                />
                            </Text>
                        </Stack>
                        <Stack as="td">
                            <Text variant="xlarge-accent">{partnershipYear?.freeUsers}</Text>
                            <Text variant="small-subtle">
                                <Plural
                                    value={partnershipYear?.freeUsers}
                                    one="Free user"
                                    other="Free users"
                                />
                            </Text>
                        </Stack>
                    </Inline>
                </Skeleton>
            </Card.Body>
        </Card>
    )
}
