import {t, Trans} from '@lingui/macro'
import {useState} from 'react'
import Helmet from 'react-helmet'
import {Link as RouterLink} from 'react-router-dom'

import {Button, Inline, Link, Stack, Text, tokens} from '@pleo-io/telescope'
import {ArrowLeft, Clock} from '@pleo-io/telescope-icons'

import {Page} from '@product-web/feature--ui-page'
import * as tracking from '@product-web/shared--analytics'
import {InlineWistiaVideo} from '@product-web/shared--telescope-lab/wistia-video/inline-wistia-video'

import {useSideOverview} from './use-side-overview'

import {SplitContent} from '../../../../components/split-content'
import {useAcademyContext} from '../../components/academy-context/academy-context'
import {CourseCompleteModal} from '../../components/course-complete-modal'
import {SideOverview} from '../../components/side-overview'

export const PleoForYourClients = () => {
    const {country} = useAcademyContext()
    const {overviewSteps, usefulMaterials} = useSideOverview()
    const [isOpen, setIsOpen] = useState(false)
    const openModal = () => setIsOpen(true)
    const getVideo = () => {
        switch (country) {
            case 'AT':
            case 'DE':
                return {
                    forFinanceTeams: {
                        id: '3rxtoy72ik',
                        thumbnail:
                            'https://embed-ssl.wistia.com/deliveries/a4343b0051dc8cb6251ba6da4301cb5834049947.bin',
                    },
                    forSmes: {
                        id: '8d2rn11kl7',
                        thumbnail:
                            'https://embed-ssl.wistia.com/deliveries/cf94a55f4d6511b38c109fadd615705af037f5b3.jpg?video_still_time=19',
                    },
                }
            case 'DK':
            case 'GL':
                return {
                    forFinanceTeams: {
                        id: 'a06ocq57f7',
                        thumbnail:
                            'https://embed-ssl.wistia.com/deliveries/43ec728ed6bd8151b81dd2f531b163a1ce006cd8.bin',
                    },
                    forSmes: {
                        id: 'eul06it056',
                        thumbnail:
                            'https://embed-ssl.wistia.com/deliveries/c8fcf577afe9180a0a08acb510c45d5c3154214b.bin',
                    },
                }
            case 'SE':
                return {
                    forFinanceTeams: {
                        id: 'qwgs8um0oq',
                        thumbnail:
                            'https://embed-ssl.wistia.com/deliveries/f1d512e0b3f35bca8939307e61ff3bbdf85c19d6.bin',
                    },
                    forSmes: {
                        id: 'rrx8xc43fl',
                        thumbnail:
                            'https://embed-ssl.wistia.com/deliveries/4baa76c6322b692407e578fabe7d02be462d56f7.jpg?video_still_time=19',
                    },
                }
            case 'ES':
                return {
                    forFinanceTeams: {
                        id: '0eb0t6mpvh',
                        thumbnail:
                            'https://embed-ssl.wistia.com/deliveries/353a1195a0c7c5cf1d33d35177899d450c44ef4b.jpg?video_still_time=18.5',
                    },
                    forSmes: {
                        id: 'uzfznm57qw',
                        thumbnail:
                            'https://embed-ssl.wistia.com/deliveries/944105f3b412b57583205d64ee7d73c5132b83bf.jpg?video_still_time=19',
                    },
                }
            case 'FR':
                return {
                    forFinanceTeams: {
                        id: 'i4x200t1pl',
                        thumbnail:
                            'https://embed-ssl.wistia.com/deliveries/288c0a68278365381e5d4c354ab28bcf900631ef.jpg?video_still_time=18.5',
                    },
                    forSmes: {
                        id: 'rwh0jdrxqw',
                        thumbnail:
                            'https://embed-ssl.wistia.com/deliveries/3be9264c3faa22be6229ba16e2279a8a9c75dcb6.jpg?video_still_time=19',
                    },
                }
            case 'NL':
                return {
                    forFinanceTeams: {
                        id: 'qagpkrcuja',
                        thumbnail:
                            'https://embed-ssl.wistia.com/deliveries/ca4c9d7f221577f799fcdacd1f75b97a539cfc12.jpg?video_still_time=18.5',
                    },
                    forSmes: {
                        id: 'tp1brxvfgr',
                        thumbnail:
                            'https://embed-ssl.wistia.com/deliveries/1893bbf7e80870c32113c5a04c9d97aa6dab9062.jpg?video_still_time=19',
                    },
                }
            default:
                // default is GB, IE, US
                return {
                    forFinanceTeams: {
                        id: '8b4nao3wmu',
                        thumbnail:
                            'https://embed-ssl.wistia.com/deliveries/246b6c46eb0073a8037c7c8849968c39ccd1a509.bin',
                    },
                    forSmes: {
                        id: 'r2602ta7gi',
                        thumbnail:
                            'https://embed-ssl.wistia.com/deliveries/94fd3f18d59acfb48becb9f10d05f24e8a4cebf4.jpg?video_still_time=19',
                    },
                }
        }
    }

    return (
        <>
            <Helmet>
                <title>{t`Introduction to Pleo`}</title>
            </Helmet>
            <Page.Header>
                <Page.SpaceBetween>
                    <Page.Title>
                        <Trans>Introduction to Pleo</Trans>
                    </Page.Title>
                </Page.SpaceBetween>
                <Inline alignItems="center" space={6} mb={8}>
                    <Clock size={16} color={tokens.colorContentStaticQuiet} />{' '}
                    <Text color="colorContentStaticQuiet">
                        <Trans>2 mins</Trans>
                    </Text>
                </Inline>
                <SplitContent>
                    <SplitContent.Main flex={4}>
                        <Text>
                            <Trans>What can Pleo do for your clients?</Trans>
                        </Text>
                    </SplitContent.Main>
                    <SplitContent.Right></SplitContent.Right>
                </SplitContent>
            </Page.Header>
            <SplitContent>
                <SplitContent.Main flex={4}>
                    <Stack space={16}>
                        <Text as="h2" variant="2xlarge-accent" space={4}>
                            <Trans>Pleo for your clients</Trans>
                        </Text>
                        <Text as="h3" variant="large-accent">
                            <Trans>Discover what Pleo can do for your finance teams.</Trans>
                        </Text>
                        <InlineWistiaVideo
                            ariaLabel={t`Play video: Pleo for finance teams`}
                            thumbnail={{src: getVideo().forFinanceTeams.thumbnail}}
                            videoId={getVideo().forFinanceTeams.id}
                            trackingCallback={(action) =>
                                tracking.partnerAcademy({
                                    action,
                                    video: 'pleo_for_finance_teams',
                                })
                            }
                            autoPlay={false}
                            imgMinHeight={348}
                        />
                        <Text as="h3" variant="large-accent">
                            <Trans>Pleo is also great for small and medium enterprises.</Trans>
                        </Text>
                        <InlineWistiaVideo
                            ariaLabel={t`Play video: Pleo for SMEs`}
                            thumbnail={{src: getVideo().forSmes.thumbnail}}
                            videoId={getVideo().forSmes.id}
                            trackingCallback={(action) =>
                                tracking.partnerAcademy({action, video: 'pleo_for_smes'})
                            }
                            autoPlay={false}
                            imgMinHeight={348}
                        />
                    </Stack>
                    <Inline alignItems="center" justifyContent="space-between" mt={24}>
                        <Link as={RouterLink} to="../employee-experience" IconLeft={ArrowLeft}>
                            <Trans>Back</Trans>
                        </Link>
                        <Button aria-label={t`Complete`} variant="primary" onClick={openModal}>
                            <Trans>Complete</Trans>
                        </Button>
                    </Inline>
                </SplitContent.Main>
                <SplitContent.Right>
                    <SideOverview overviewSteps={overviewSteps} usefulMaterials={usefulMaterials} />
                </SplitContent.Right>
            </SplitContent>
            <CourseCompleteModal
                isOpen={isOpen}
                nextCoursePath="the-pleo-product"
                upNext={t`Up next: discover some of Pleo's core features`}
            />
        </>
    )
}
